import React, { useEffect } from 'react';
import { useNavigate, useLocation, matchPath, Outlet } from 'react-router-dom';

import {
  LOGOUT_PAGE_PATH,
  CONTRACT_MIGRATION_PAGE_PATH,
  WELCOME_PAGE_PATH,
  ACTIVATION_PAGE_PATH,
  INVITE_PAGE_PATH,
  LOCK_PAGE_PATH,
} from 'application/consts';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useAuthContext } from 'application/providers/AuthProvider';
import ActivationService from 'application/services/ActivationService';
import CompanyUtils from 'application/utils/CompanyUtils';

import { CompanyContractKind } from 'domain/api/graphql/generated';

import RouterUtils from '../utils';
import { AVAILABLE_TO_REDIRECT_ACTIVATION_FLOW_PATHS } from './consts';

const RedirectRoute = () => {
  const { hasAccess, userData } = useAuthContext();
  const { isReadyToUseLK } = useAccountContext();

  const navigate = useNavigate();

  const location = useLocation();

  const targetCurrentPathname = RouterUtils.readTargetPathname();

  const hasCompanies = !!userData?.companies;

  const isNeedRedirectToLockPage = !!userData?.companies && userData.companies.length === 0;
  const isLogoutPage = matchPath({ path: LOGOUT_PAGE_PATH, end: true }, location.pathname);
  const isContractMigrationPage = matchPath({ path: CONTRACT_MIGRATION_PAGE_PATH, end: true }, location.pathname);
  const isWelcomePage = matchPath({ path: WELCOME_PAGE_PATH, end: true }, location.pathname);

  useEffect(() => {
    if (!userData || isLogoutPage || isContractMigrationPage || isWelcomePage) {
      return;
    }

    if (hasAccess && isNeedRedirectToLockPage) {
      navigate(LOCK_PAGE_PATH, { replace: true });
      return;
    }

    if (hasCompanies) {
      const companyWithActiveContractMigration = CompanyUtils.getCompanyWithActiveContractMigration(
        userData.companies,
        CompanyContractKind.Offerta
      );

      const hasActiveContractMigration =
        !!companyWithActiveContractMigration &&
        !!CompanyUtils.getActiveContractMigration(companyWithActiveContractMigration, CompanyContractKind.Offerta);

      if (hasActiveContractMigration) {
        navigate(CONTRACT_MIGRATION_PAGE_PATH, { replace: true });
        return;
      }

      if (!isReadyToUseLK && location.pathname !== INVITE_PAGE_PATH) {
        navigate(WELCOME_PAGE_PATH, { replace: true });
        return;
      }
    }

    if (hasAccess && targetCurrentPathname) {
      RouterUtils.clearTargetPathname();
      navigate(targetCurrentPathname, { replace: true });
      return;
    }

    const isActivationFlow = ActivationService.isActivationFlow();

    if (isActivationFlow) {
      const isActivationMatch = AVAILABLE_TO_REDIRECT_ACTIVATION_FLOW_PATHS.find((path) =>
        matchPath({ path, end: false }, location.pathname)
      );

      navigate(isActivationMatch ? location.pathname : ACTIVATION_PAGE_PATH, { replace: true });
    }
  }, [hasAccess, location.pathname, targetCurrentPathname, hasCompanies, isNeedRedirectToLockPage]);

  return <Outlet />;
};

export default RedirectRoute;
