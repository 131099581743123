export const TEXTS = {
  MODAL_TITLE: 'Мобильное приложение',
  DESCRIPTION: 'Наведите камеру на QR-код для загрузки приложения',

  DOWNLOAD_LINK: 'Копировать ссылку на скачивание',
  LINK_WAS_COPIED: 'Ссылка скопирована',

  SUCCESS_BUTTON: 'Дальше',
  CANCEL_BUTTON: 'Назад',
};
