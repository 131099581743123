export const TEXTS = {
  TRACK_REMOVED: 'Трек исключён из волны',
  NO_DISLIKE_PERMISSION: `Нет прав поставить дизлайк — волна принадлежит другой организации.
  Проверьте, что вы правильно авторизованы`,
  PLAY_FOR_REMOVE: 'Для исключения трека необходимо запустить плеер',
  PLAY_FOR_SKIP: 'Для пропуска трека необходимо запустить плеер',
  SKIP_DISABLED_OFFILE: 'Для пропуска трека необходимо интернет-соединение',
  REMOVE_DISABLED_OFFILE: 'Для исключения трека необходимо интернет-соединение',
  WEB_PLAYER: 'Веб плеер',
  PLAYER_PROCESSING: 'Плеер переключает трек',
  LINK_COPIED: 'Ссылка скопирована',
  SKIP_TRACK: 'Пропустить трек',
  CLOSE_AND_STOP: 'Закрыть, остановить вещание',
  SHARE_LINK: 'Поделиться ссылкой на веб плеер',
  LOGIN_REQUIRED_DISLIKE_CONTENT:
    'Сейчас вы не авторизованы, и мы не можем сохранить ваши действия. Войдите в аккаунт и отмечайте треки, которые вам не нравятся.',

  REQUEST_EXTEND_SUBSCRIPTION_MODAL_TITLE: 'Запрос отправлен',
  REQUEST_EXTEND_SUBSCRIPTION_MODAL_DESC:
    'Запрос на выставление счета отправлен. Менеджер свяжется с вами в течение рабочего дня.',
  REQUEST_EXTEND_SUBSCRIPTION_MODAL_CONTACTS: 'По вопросам обращайтесь 8 800 550 59 94, ',
  REQUEST_EXTEND_SUBSCRIPTION_MODAL_MANAGER_MAIL_TEXT: 'clients-b2b@zvuk.com',
  REQUEST_EXTEND_SUBSCRIPTION_MODAL_MANAGER_MAIL_URL: 'mailto: clients-b2b@zvuk.com',
  REQUEST_EXTEND_SUBSCRIPTION_MODAL_BUTTON_OK: 'Хорошо',
};
