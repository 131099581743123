import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import IconCheck from 'ui/icons/Check';

import Spinner from 'ui/Spinner';
import { Colors } from 'ui/types';
import styles from './index.module.scss';

export enum LabelPlacement {
  LEFT = 'left',
  RIGHT = 'right',
}

export type CheckboxProps = React.PropsWithChildren<{
  isChecked?: boolean;
  isDisabled?: boolean;
  isStretch?: boolean;
  ignoreInternalState?: boolean;
  className?: string;
  buttonClassName?: string;
  labelClassName?: string;
  elementId: string;
  inputName?: string;
  onChange?: (value: boolean, event?: React.ChangeEvent) => void;
  labelPlacement?: LabelPlacement;
  isLoading?: boolean;
}>;

const Checkbox = React.forwardRef<HTMLElement, CheckboxProps>(
  (props: CheckboxProps, ref: React.Ref<HTMLElement> | undefined) => {
    const {
      isChecked = false,
      isDisabled = false,
      className,
      buttonClassName,
      labelClassName,
      children,
      elementId,
      inputName,
      onChange,
      labelPlacement = LabelPlacement.RIGHT,
      isStretch = false,
      isLoading = false,
      ignoreInternalState = false,
    } = props;

    const [checked, setChecked] = useState<boolean>(isChecked);

    const handleChange = useCallback(
      (value: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
        if (!ignoreInternalState) {
          setChecked(value);
          onChange?.(value, event);
        }
      },
      [onChange, ignoreInternalState]
    );

    useEffect(() => {
      setChecked(isChecked);
    }, [isChecked]);

    return (
      <div
        ref={ref as React.Ref<HTMLDivElement>}
        className={classNames(styles.CheckboxContainer, className, {
          [styles.CheckboxContainerStretched]: isStretch,
        })}>
        {labelPlacement === LabelPlacement.LEFT && (
          <label
            htmlFor={elementId}
            className={classNames(styles.CheckboxLabel, styles.CheckboxLabelLeft, labelClassName)}>
            {children}
          </label>
        )}
        <input
          id={elementId}
          data-testid={elementId}
          name={inputName}
          type="checkbox"
          className={classNames(styles.Checkbox, {
            [styles.CheckboxRight]: labelPlacement === LabelPlacement.LEFT,
          })}
          checked={checked}
          data-enabled={checked}
          disabled={isDisabled}
          onChange={(event) => handleChange(event.target.checked, event)}
        />

        {!isLoading && (
          <button
            type="button"
            id={`checkbox_button_${elementId}`}
            data-testid={`checkbox_button_${elementId}`}
            className={classNames(styles.CheckboxButton, buttonClassName, {
              [styles.CheckboxButtonChecked]: checked,
            })}
            disabled={isDisabled}>
            {checked && (
              <IconCheck className={classNames(styles.CheckboxIcon, { [styles.CheckboxIconDisabled]: isDisabled })} />
            )}
          </button>
        )}

        {isLoading && <Spinner color={Colors.COLOR_NEUTRAL_600} />}

        {labelPlacement === LabelPlacement.RIGHT && (
          <label
            htmlFor={elementId}
            className={classNames(styles.CheckboxLabel, styles.CheckboxLabelRight, labelClassName)}>
            {children}
          </label>
        )}
      </div>
    );
  }
);

export default Checkbox;
