import { useApolloClient } from 'application/providers/DataProvider';

import usePaymentMethod from 'domain/billing/usePaymentMethod';
import useBindCard from 'domain/billing/useBindCard';
import useBindCardAndPay from 'domain/billing/useBindCardAndPay';
import useRemoveCard from 'domain/billing/useRemoveCard';
import { PaymentMethodType } from 'domain/billing/types';
import { BillingPaymentMethodDocument } from 'domain/api/graphql/generated';

import { UsePaymentMethodRepositoryResultType } from './types';

const usePaymentMethodRepository = (): UsePaymentMethodRepositoryResultType => {
  const client = useApolloClient();

  const { request: paymentMethodRequest, result: paymentMethodResult } = usePaymentMethod();
  const { request: bindCard, result: bindCardResult } = useBindCard();
  const { request: bindCardAndPayRequest, result: bindCardAndPayResult } = useBindCardAndPay();
  const { request: removeCardRequest, result: removeCardResult } = useRemoveCard();

  const getPaymentMethod = async (companyId: string) => {
    const result = await paymentMethodRequest(companyId);

    return result;
  };

  const readPaymentMethod = (companyId: string) => {
    const result = client.readQuery({
      query: BillingPaymentMethodDocument,
      variables: {
        companyId,
      },
    });

    return (result?.paymentMethodPureQuery || {}) as PaymentMethodType;
  };

  const getLinkForBindingCard = async (uriForReturn: string, companyId: string) => {
    const link = await bindCard(uriForReturn, companyId);

    return link;
  };

  const getLinkForPayment = async (uriForReturn: string, licensesIds: string[], companyId: string) => {
    const link = await bindCardAndPayRequest(uriForReturn, companyId, licensesIds);

    return link;
  };

  const removeCard = async (companyId: string) => {
    const ok = await removeCardRequest(companyId);

    return ok;
  };

  return {
    getPaymentMethod,
    readPaymentMethod,
    paymentMethodLoading: paymentMethodResult.loading,
    paymentMethodError: paymentMethodResult.error,
    bindCardLoading: bindCardResult.loading || bindCardAndPayResult.loading,
    removeCardLoading: removeCardResult.loading,
    getLinkForBindingCard,
    getLinkForPayment,
    removeCard,
  };
};

export default usePaymentMethodRepository;
