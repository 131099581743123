import { useApolloClient } from 'application/providers/DataProvider';

import useUserBillingInfo from 'domain/billing/useUserBillingInfo';

import useSubscriptionCost from 'domain/billing/useSubscriptionCost';
import useExtendSubscription from 'domain/billing/useExtendSubscription';
import useLaunchSubscription from 'domain/billing/useLaunchSubscription';
import useLicensesSuspendAutoRenewal from 'domain/billing/useLicensesSuspendAutoRenewal';
import useLicensesResumeAutoRenewal from 'domain/billing/useLicensesResumeAutoRenewal';
import useExtendSubscriptionRequest from 'domain/billing/useExtendSubscriptionRequest';
import {
  BillingCompanyBillingInfoDocument,
  BillingCompanyBillingInfoQuery,
  BillingSubscriptionCostByPricePlanPeriodDocument,
  BillingSubscriptionCostByPricePlanPeriodQuery,
} from 'domain/api/graphql/generated';
import { BillingInfoType } from 'domain/billing/types';

import { UseBillingRepositoryResultType } from './types';

const useBillingRepository = (): UseBillingRepositoryResultType => {
  const client = useApolloClient();

  const { request: billingInfoRequest, result: billingInfoResult } = useUserBillingInfo();
  const [requestCost, subscriptionCostResult] = useSubscriptionCost();
  const { request: extendSubscriptionRequest, result: extendSubscriptionResult } = useExtendSubscription();
  const { request: launchSubscriptionRequest, result: launchSubscriptionResult } = useLaunchSubscription();
  const { request: suspendAutoRenewalRequest, result: suspendAutoRenewalResult } = useLicensesSuspendAutoRenewal();
  const { request: resumeAutoRenewalRequest, result: resumeAutoRenewalResult } = useLicensesResumeAutoRenewal();
  const { request: extendSubscriptionPrompt, result: extendSubscriptionPromptResult } = useExtendSubscriptionRequest();

  const getBillingInfo = async (companyId: string) => {
    const result = await billingInfoRequest(companyId);

    return result;
  };

  const getResumeSubscriptionCost = async (companyId: string, count: number) => {
    const result = await requestCost.resumeCost(companyId, count);

    return result;
  };

  const getExtendSubscriptionCost = async (companyId: string, count: number) => {
    const result = await requestCost.extendCost(companyId, count);

    return result || undefined;
  };

  const getSubscriptionCostByPricePlanPeriod = async (companyId: string, pricePlanPeriodId: string) => {
    const result = await requestCost.subscriptionCostByPricePlanPeriod(companyId, pricePlanPeriodId);

    return result || undefined;
  };

  const extendOneShotSubscription = async (companyId: string, licensesIds: string[]) => {
    const isOk = await extendSubscriptionRequest(companyId, licensesIds);

    return isOk;
  };

  const extendSubscription = async (companyId: string, toExtendSubscriptionLicenseIds: string[]) => {
    const result = await extendSubscriptionRequest(companyId, toExtendSubscriptionLicenseIds!);

    return result;
  };

  const launchSubscription = async (companyId: string, toExtendSubscriptionLicenseIds: string[]) => {
    const result = await launchSubscriptionRequest(companyId, toExtendSubscriptionLicenseIds!);

    return result;
  };

  const suspendAutoRenewal = async (companyId: string, licensesIds: string[]) => {
    const result = await suspendAutoRenewalRequest(companyId, licensesIds);

    return result;
  };

  const resumeAutoRenewal = async (companyId: string, licensesIds: string[]) => {
    const result = await resumeAutoRenewalRequest(companyId, licensesIds);

    return result;
  };

  const extendSubscriptionCost = async (companyId: string, count: number) => {
    const result = await requestCost.extendCost(companyId, count);

    return result;
  };

  const resumeSubscriptionCost = async (companyId: string, count: number) => {
    const result = await requestCost.resumeCost(companyId, count);

    return result;
  };

  const requestExtendSubscription = async (pointId: string) => {
    const result = await extendSubscriptionPrompt({ pointId });

    return result;
  };

  const readBillingInfo = (companyId: string) => {
    const result = client.readQuery<BillingCompanyBillingInfoQuery | undefined>({
      query: BillingCompanyBillingInfoDocument,
      variables: {
        companyId,
      },
    });

    return (result?.result || {}) as BillingInfoType;
  };

  const watchBillingInfo = (companyId: string) => {
    const observableQuery = client.watchQuery<BillingCompanyBillingInfoQuery | undefined>({
      query: BillingCompanyBillingInfoDocument,
      variables: {
        companyId,
      },
    });

    const result = observableQuery.getCurrentResult();

    return (result.data?.result || {}) as BillingInfoType;
  };

  const readUnpaidLicenses = (companyId: string) => {
    const result = client.readQuery<BillingCompanyBillingInfoQuery | undefined>({
      query: BillingCompanyBillingInfoDocument,
      variables: {
        companyId,
      },
    });

    return result?.result?.unpaidLicenses || [];
  };

  const watchSubscriptionCostByPricePlan = (organizationId: string, pricePlanPeriodId: string) => {
    const observableQuery = client.watchQuery<BillingSubscriptionCostByPricePlanPeriodQuery | undefined>({
      query: BillingSubscriptionCostByPricePlanPeriodDocument,
      variables: {
        organizationId,
        pricePlanPeriodId,
      },
    });

    const result = observableQuery.getCurrentResult();

    return result.data?.result || 0;
  };

  const SBER_PRIME_SLUG = 'sber_prime';

  return {
    isBillingInfoLoading: billingInfoResult.loading,
    subscriptionCostResult,
    getBillingInfo,
    getResumeSubscriptionCost,
    getExtendSubscriptionCost,
    getSubscriptionCostByPricePlanPeriod,
    isExtendSubscriptionLoading: extendSubscriptionResult.loading,
    extendSubscriptionError: extendSubscriptionResult.error,
    extendOneShotSubscription,
    extendSubscription,
    launchSubscription,
    suspendAutoRenewal,
    resumeAutoRenewal,
    extendSubscriptionCost,
    resumeSubscriptionCost,
    requestExtendSubscription,
    licenseSubscriptionLoading: extendSubscriptionResult.loading || launchSubscriptionResult.loading,
    licenseCostLoading:
      suspendAutoRenewalResult.loading || resumeAutoRenewalResult.loading || subscriptionCostResult.loading,
    extendSubscriptionPromptLoading: extendSubscriptionPromptResult.loading,
    extendSubscriptionPromptError: extendSubscriptionPromptResult.error,
    readBillingInfo,
    watchBillingInfo,
    readUnpaidLicenses,
    watchSubscriptionCostByPricePlan,
    SBER_PRIME_SLUG,
  };
};

export default useBillingRepository;
