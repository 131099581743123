import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type TextProps = React.PropsWithChildren<{
  elementId: string;
  className?: string;
  isLoading?: boolean;
  skeletonLines?: number;
  as?: React.ElementType;
}>;

const Text = ({
  children,
  elementId,
  className,
  skeletonLines = 1,
  isLoading = false,
  as: Renderer = 'span',
}: TextProps) =>
  isLoading ? (
    <Renderer className={classNames(styles.TextSkeleton, className)}>
      {new Array(skeletonLines).fill('\xa0').map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`text-skeleton-item-${index}`}>
          {index !== skeletonLines - 1 && <br />}
          {item}
        </React.Fragment>
      ))}
    </Renderer>
  ) : (
    <Renderer
      id={elementId}
      data-testid={elementId}
      className={className}>
      {children}
    </Renderer>
  );

export default Text;
