/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react';
import { useUploadController } from './useUploadController';
import { FilesUploadContext } from './FileUploadContext';

const FilesUploadProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { loading, uploadingFiles, onClickUpload, initQue, setDropProps, setQuId, dropzone, isInputActive } =
    useUploadController();

  const value = useMemo(
    () => ({ onClickUpload, setDropProps, setQuId, initQue, loading, uploadingFiles }),
    [onClickUpload, setDropProps, setQuId, initQue, loading, uploadingFiles]
  );

  return (
    <FilesUploadContext.Provider value={value}>
      {isInputActive && (
        <div {...dropzone.getRootProps({ style: { display: 'none' } })}>
          <input
            data-testid="media_uploader_input"
            {...dropzone.getInputProps()}
          />
        </div>
      )}
      {children}
    </FilesUploadContext.Provider>
  );
};

export default FilesUploadProvider;
