import { useStreamLibrarySubcollectionTitleLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { useMemo } from 'react';

const useGetSubCollectionTitle = () => {
  const [query, queryResult] = useStreamLibrarySubcollectionTitleLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const loading = queryResult?.loading;
  const subCollectionTitle = queryResult.data?.result?.title ?? undefined;

  const request = (subcollectionId: string, companyId: string) => {
    query({
      variables: {
        companyId,
        subcollectionId,
      },
    });
  };

  return useMemo(
    () => ({
      request,
      loading,
      title: subCollectionTitle,
    }),
    [loading, subCollectionTitle]
  );
};

export default useGetSubCollectionTitle;
