import {
  Country,
  DeviceTypes,
  Exact,
  UserFeaturesOnboardingFilter,
  UserFeaturesOnboardingPassMutationInput,
  UserFeaturesOnboardingQueryQuery,
} from 'domain/api/graphql/generated';
import { ApolloQueryResult } from '@apollo/client';
import { PointWithDevice } from 'application/presenters/point/types';
import { TEXTS } from './texts';
import { SelectPlayerFlowType } from '../types';

export type PlayerRadioType = {
  title: string;
  description: string;
  type: PlayerTypesInFlow;
  ruByOnly?: boolean;
};

export enum PlayerTypesInFlow {
  WEBPLAYER = 'WEBPLAYER',
  MOBILE = 'MOBILE',
  VIDEOPLAYER = 'VIDEOPLAYER',
  DESKTOP = 'DESKTOP',
  BOOM_SPEAKER = 'BOOM_SPEAKER',
  ANDROIDBOX = 'ANDROIDBOX',
}

export enum DeviceTypesInFlow {
  ANDROID = DeviceTypes.Android,
  ANDROIDBOX = DeviceTypes.Androidbox,
  BOOM_SPEAKER = DeviceTypes.BoomSpeaker,
  DESKTOP = DeviceTypes.Desktop,
  IOS = DeviceTypes.Ios,
  WEBPLAYER = DeviceTypes.Webplayer,
}

export const DEVICE_TO_PLAYER_TYPE: Record<DeviceTypesInFlow, PlayerTypesInFlow> = {
  [DeviceTypes.Android]: PlayerTypesInFlow.MOBILE,
  [DeviceTypes.Androidbox]: PlayerTypesInFlow.ANDROIDBOX,
  [DeviceTypes.BoomSpeaker]: PlayerTypesInFlow.BOOM_SPEAKER,
  [DeviceTypes.Desktop]: PlayerTypesInFlow.DESKTOP,
  [DeviceTypes.Ios]: PlayerTypesInFlow.MOBILE,
  [DeviceTypes.Webplayer]: PlayerTypesInFlow.WEBPLAYER,
};

enum VideoDeviceType {
  Video = 'VIDEO_PLAYER',
}

export const PLAYER_TO_DEVICE_TYPE: Record<PlayerTypesInFlow, DeviceTypes | VideoDeviceType> = {
  [PlayerTypesInFlow.MOBILE]: DeviceTypes.Android,
  [PlayerTypesInFlow.ANDROIDBOX]: DeviceTypes.Androidbox,
  [PlayerTypesInFlow.BOOM_SPEAKER]: DeviceTypes.BoomSpeaker,
  [PlayerTypesInFlow.DESKTOP]: DeviceTypes.Desktop,
  [PlayerTypesInFlow.WEBPLAYER]: DeviceTypes.Webplayer,
  [PlayerTypesInFlow.VIDEOPLAYER]: VideoDeviceType.Video,
};

export const PLAYER_TEXT_TYPE: Record<PlayerTypesInFlow, Omit<PlayerRadioType, 'type'>> = {
  [PlayerTypesInFlow.WEBPLAYER]: {
    title: TEXTS.WEBPLAYER_TITLE,
    description: TEXTS.WEBPLAYER_DESCRIPTION,
  },
  [PlayerTypesInFlow.MOBILE]: {
    title: TEXTS.MOBILE_TITLE,
    description: TEXTS.MOBILE_DESCRIPTION,
  },
  [PlayerTypesInFlow.DESKTOP]: {
    title: TEXTS.DESKTOP_TITLE,
    description: TEXTS.DESKTOP_DESCRIPTION,
  },
  [PlayerTypesInFlow.BOOM_SPEAKER]: {
    title: TEXTS.BOOM_SPEAKER_TITLE,
    description: TEXTS.BOOM_SPEAKER_DESCRIPTION,
    ruByOnly: true,
  },
  [PlayerTypesInFlow.ANDROIDBOX]: {
    title: TEXTS.SBER_BUSINESS_PLAYER_TITLE,
    description: TEXTS.SBER_BUSINESS_PLAYER_DESCRIPTION,
    ruByOnly: true,
  },
  [PlayerTypesInFlow.VIDEOPLAYER]: {
    title: TEXTS.VIDEO_PLAYER_TITLE,
    description: TEXTS.VIDEO_PLAYER_DESCRIPTION,
  },
};

export const PLAYER_NAME_TYPE: Record<DeviceTypes | VideoDeviceType, string> = {
  [DeviceTypes.AliceSpeaker]: TEXTS.ALICE_TITLE,
  [DeviceTypes.Android]: TEXTS.MOBILE_TITLE,
  [DeviceTypes.Androidbox]: TEXTS.SBER_BUSINESS_PLAYER_TITLE,
  [DeviceTypes.BoomSpeaker]: TEXTS.BOOM_SPEAKER_TITLE,
  [DeviceTypes.Desktop]: TEXTS.DESKTOP_TITLE,
  [DeviceTypes.Desktopbox]: TEXTS.RPI_TITLE,
  [DeviceTypes.Ios]: TEXTS.MOBILE_TITLE,
  [DeviceTypes.Musicbox]: TEXTS.MUSICBOX_TITLE,
  [DeviceTypes.Netcore]: TEXTS.NETCORE_TITLE,
  [DeviceTypes.Webplayer]: TEXTS.WEBPLAYER_TITLE,
  [VideoDeviceType.Video]: TEXTS.VIDEO_PLAYER_TITLE,
};

export type SelectPlayerModalProps = {
  targetStreamId?: string;
  isTemplateMode: boolean;
  point?: PointWithDevice;
  flowType?: SelectPlayerFlowType;
  onNext?: (params?: object | undefined) => Promise<void>;
};

export type UseSelectPlayerModalControllerProps = {
  targetStreamId?: string;
  isTemplateMode: boolean;
  onboardingPassedRequest: (input: UserFeaturesOnboardingPassMutationInput) => Promise<true | undefined>;
  refetchFeaturesOnboarding: (
    variables?:
      | Partial<
          Exact<{
            filters: UserFeaturesOnboardingFilter;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<UserFeaturesOnboardingQueryQuery>>;
  onNext?: (params?: object | undefined) => Promise<void>;
  country?: Country;
};
