import useSelectPlayerModalAdapter from 'application/components/common/SelectPlayerFlow/SelectPlayerModal/useSelectPlayerModalAdapter';
import { useAccountContext } from 'application/providers/AccountProvider';
import {
  usePointRepositoryInjector,
  useBillingRepositoryInjector,
  useAccountRepositoryInjector,
  usePaymentMethodRepositoryInjector,
} from 'application/providers/DIContainerProvider';
import { Country } from 'domain/api/graphql/generated';

const usePointCreatePresenter = () => {
  const { currentCompany } = useAccountContext();

  const { userOnboardingFeatures, userOnboardingFeaturesLoading } = useAccountRepositoryInjector();
  const { getPaymentMethod, readPaymentMethod } = usePaymentMethodRepositoryInjector();

  const { paymentMethod } = readPaymentMethod(currentCompany!.id);

  const {
    createPoint,
    createPointLoading,
    createPointError,
    getStandardizedPoints,
    getStandardizedPointsLoading,
    setCurrentPoint,
    watchCurrentPoint,
    readCurrentPoint,
  } = usePointRepositoryInjector();

  const {
    getExtendSubscriptionCost,
    subscriptionCostResult,
    extendOneShotSubscription,
    isExtendSubscriptionLoading,
    getBillingInfo,
    watchBillingInfo,
    licenseCostLoading,
  } = useBillingRepositoryInjector();

  const billingInfo = watchBillingInfo(currentCompany!.id);

  const { hasTrialLicenses, hasActiveLicenses } = billingInfo;

  const {
    changeStream,
    isChangeStreamLoading,
    copyTemplateStream,
    isCopyStreamTemplateLoading,
    onboardingPassedRequest,
    isUserFeaturesOnboardingPassLoading,
    getSavedStreams,
    isSavedStreamsLoading,
  } = useSelectPlayerModalAdapter();

  const isButtonLoading =
    getStandardizedPointsLoading ||
    createPointLoading ||
    isExtendSubscriptionLoading ||
    isChangeStreamLoading ||
    isCopyStreamTemplateLoading ||
    isUserFeaturesOnboardingPassLoading ||
    isSavedStreamsLoading;

  const isButtonWithCostLoading =
    isButtonLoading || licenseCostLoading || subscriptionCostResult.data.extendCost === undefined;

  return {
    createPoint,
    getExtendSubscriptionCost,
    extendSubscription: extendOneShotSubscription,
    getStandardizedPoints,
    subscriptionCostResult,
    createPointError,
    userOnboardingFeatures,
    userOnboardingFeaturesLoading,
    isButtonLoading,
    getBillingInfo,
    hasTrialLicenses,
    hasActiveLicenses,
    getPaymentMethod,
    paymentMethod,
    changeStream,
    copyTemplateStream,
    onboardingPassedRequest,
    getSavedStreams,
    setCurrentPoint,
    watchCurrentPoint,
    readCurrentPoint,
    companyCountry: currentCompany?.country || Country.Ru,
    isButtonWithCostLoading,
  };
};

export default usePointCreatePresenter;
