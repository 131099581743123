import React from 'react';

import Checkbox, { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';
import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';

import styles from './index.module.scss';

type PointListCheckboxItemProps = {
  onChange: (value: boolean, currentItem: PointsListCheckboxGroupPointType) => void;
  currentItem: PointsListCheckboxGroupPointType;
  isChecked: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  labelPlacement?: LabelPlacement;
};

const PointListCheckboxItem = ({
  currentItem,
  onChange,
  isChecked,
  children,
  disabled = false,
  isLoading = false,
  labelPlacement = LabelPlacement.LEFT,
}: PointListCheckboxItemProps) => (
  <div
    className={styles.PointListCheckboxItem}
    key={`item-list_item_${currentItem.id}`}>
    <Checkbox
      elementId={`checkbox_item_bind_${currentItem.id}`}
      isChecked={isChecked}
      isDisabled={currentItem.isDisabled || disabled}
      labelPlacement={labelPlacement}
      isStretch
      className={styles.PointListCheckboxItemCheckbox}
      onChange={(value) => {
        onChange(value, currentItem);
      }}
      isLoading={isLoading}>
      {children}
    </Checkbox>
  </div>
);

export default PointListCheckboxItem;
