import { ImageFile, ImageFileErrorCode, UseImageUploaderControllerProps } from './types';
import ImageUploaderUtils from './utils';
import useFilesActionsHelper from '../useFilesActionsHelper';

const useImageUploaderController = ({
  width,
  height,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  widthErrorText,
  heightErrorText,
  minWidthErrorText,
  maxWidthErrorText,
  minHeightErrorText,
  maxHeightErrorText,
  size,
  minSize,
  maxSize,
  sizeErrorText,
  minSizeErrorText,
  maxSizeErrorText,
  value,
  onChange,
}: UseImageUploaderControllerProps) => {
  const getImageFileRejection = (imageFile: ImageFile) =>
    ImageUploaderUtils.getImageRejection(imageFile, {
      width,
      height,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      widthErrorText,
      heightErrorText,
      minWidthErrorText,
      maxWidthErrorText,
      minHeightErrorText,
      maxHeightErrorText,
      size,
      minSize,
      maxSize,
      sizeErrorText,
      minSizeErrorText,
      maxSizeErrorText,
    });

  const {
    fileInputRef,
    previewFiles,
    fileRejections,
    currentFileForEditing,
    isUploadButtonVisible,
    handleFilesChange,
    handleFileUploaderOpen,
    handleFileUploaderCancel,
    handleFileRemove,
    handleRejectedFileRemove,
  } = useFilesActionsHelper<ImageFile, ImageFileErrorCode>({
    value,
    onChange,
    getFileRejection: getImageFileRejection,
    getFilesFromEvent: ImageUploaderUtils.getImageFilesFromEvent,
  });

  return {
    imageFileInputRef: fileInputRef,
    previewImages: previewFiles,
    imageFileRejections: fileRejections,
    currentFileForEditing,
    isUploadButtonVisible,
    handleImageFilesChange: handleFilesChange,
    handleImageFileUploaderOpen: handleFileUploaderOpen,
    handleImageFileUploaderCancel: handleFileUploaderCancel,
    handleFileRemove,
    handleRejectedFileRemove,
  };
};

export default useImageUploaderController;
