import { useCallback } from 'react';
import AnalyticsService from 'application/services/AnalyticsService';
import { YandexCaptchaEventNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { CaptchaStatus, UseYandexCaptchaControllerProps } from './types';

const useYandexCaptchaController = ({
  setCaptchaStatus,
  setIsVisible,
  successCallback,
  yandexErrorCallback,
  setCaptchaToken,
  resetCaptcha,
}: UseYandexCaptchaControllerProps) => {
  const handleChallengeVisible = useCallback(() => {
    setCaptchaStatus?.(CaptchaStatus.VISIBLE);

    AnalyticsService.event(YandexCaptchaEventNameAMP.SHOWED);
  }, [setCaptchaStatus]);

  const handleChallengeHidden = useCallback(() => {
    setIsVisible(false);
    setCaptchaStatus?.(CaptchaStatus.HIDDEN);
  }, [setCaptchaStatus, setIsVisible]);

  const handleSuccess = useCallback(
    (token: string) => {
      setCaptchaStatus?.(CaptchaStatus.SUCCESS);
      setCaptchaToken(token);

      successCallback?.(token);

      AnalyticsService.event(YandexCaptchaEventNameAMP.COMPLETED);
    },
    [successCallback, setCaptchaStatus, setCaptchaToken]
  );

  const handleTokenExpired = useCallback(() => {
    setCaptchaStatus?.(CaptchaStatus.TOKEN_EXPIRED);
    setCaptchaToken(null);
    resetCaptcha();
  }, [setCaptchaToken, setCaptchaStatus, resetCaptcha]);

  const handleNetworkError = useCallback(() => {
    setCaptchaStatus?.(CaptchaStatus.NETWORK_ERROR);
    yandexErrorCallback?.();

    AnalyticsService.event(YandexCaptchaEventNameAMP.YANDEX_ERROR);
  }, [yandexErrorCallback, setCaptchaStatus]);

  const handleJavaScriptError = useCallback(() => {
    setCaptchaStatus?.(CaptchaStatus.JS_ERROR);
    yandexErrorCallback?.();

    AnalyticsService.event(YandexCaptchaEventNameAMP.YANDEX_ERROR);
  }, [yandexErrorCallback, setCaptchaStatus]);

  return {
    handleChallengeVisible,
    handleJavaScriptError,
    handleNetworkError,
    handleSuccess,
    handleTokenExpired,
    handleChallengeHidden,
  };
};

export default useYandexCaptchaController;
