import React from 'react';
import { FieldError, FieldValues, useController } from 'react-hook-form';

import ImageUploaderComponent from 'ui/FileUploader/ImageUploader';
import { ChangeEventArgsType } from 'ui/FileUploader/types';
import { ImageFileErrorCode } from 'ui/FileUploader/ImageUploader/types';
import { ButtonType } from 'ui/Button';

import { ImageUploaderFieldProps } from '../types';
import Description from './Description';

import styles from './index.module.scss';

const ImageUploaderField = React.forwardRef(
  <T extends FieldValues>(props: ImageUploaderFieldProps<T>, ref: React.Ref<HTMLElement> | undefined) => {
    const {
      elementId,
      uploadButtonContent,
      description,
      error,
      handleFieldDirtyChange,
      rules,
      handleFieldError,
      onFieldChange,
    } = props;
    const { field, formState } = useController({
      ...props,
      rules: {
        ...rules,
        validate: {
          hasNotErrors: (value) => {
            const args = value as ChangeEventArgsType<ImageFileErrorCode> | null;

            if (args) {
              return args.fileRejections.length === 0;
            }

            return true;
          },
          hasFiles: (value) => {
            if (rules?.required) {
              const args = value as ChangeEventArgsType<ImageFileErrorCode>;
              return args.files.length !== 0;
            }

            return true;
          },
        },
      },
    });

    const handleChange = (args: ChangeEventArgsType<ImageFileErrorCode>) => {
      field.onChange(args);
      const isEmpty = args.files.length === 0 && args.fileRejections.length === 0;

      handleFieldDirtyChange?.(field.name, !isEmpty);
      onFieldChange?.(field);

      if (handleFieldError && args.fileRejections.length !== 0) {
        handleFieldError(field.name, 'error');
      }
    };

    return (
      <ImageUploaderComponent
        {...field}
        {...props}
        ref={ref}
        elementId={`form_image_uploader_field_${elementId}`}
        uploadButtonContent={
          rules?.required ? (
            <div>
              {uploadButtonContent}
              <span className={styles.ImageUploaderFieldButtonSuffix}>*</span>
            </div>
          ) : (
            uploadButtonContent
          )
        }
        description={
          <Description
            description={description}
            fieldError={formState.errors[field.name] as FieldError}
            externalErrors={error}
            elementId={elementId}
          />
        }
        uploadButtonType={formState.errors[field.name] || error ? ButtonType.DANGER : ButtonType.SECONDARY}
        onChange={handleChange}
        hasError={!!formState.errors[field.name] || !!error}
      />
    );
  }
);

export default ImageUploaderField;
