import { PAGE_SIZE } from '@zvuk-b2b/react-uikit/ui/InfiniteItemList/mocks';
import { useCompanyId } from 'application/providers/AccountProvider';
import useVideoStreamRepository from 'application/repositories/videoStream/useVideoStreamRepository';
import { useUploadMediaFilesMutation } from 'domain/api/graphql/generated';
import { useEffect } from 'react';

const pagination = {
  page: 0,
  pageSize: PAGE_SIZE,
};

export const useVideoMediafiesPresenter = (query?: string) => {
  const companyId = useCompanyId();

  const [uploadMediaFilesMutation] = useUploadMediaFilesMutation();

  const {
    getCompanyMediafiles,
    companyMediafiles,
    refetchCompanyMediafiles,
    companyMediafilesLoading,
    getMoreCompanyMediafiles,
    pagination: paginationInfo,
  } = useVideoStreamRepository();

  const filters = {
    companyId,
    query,
  };
  useEffect(() => {
    getCompanyMediafiles({ pagination, filters });
  }, [query]);

  const refetch = () => {
    refetchCompanyMediafiles({ pagination, filters });
  };

  const loadMore = () => {
    if (!paginationInfo?.hasNextPage) {
      return;
    }

    const newPagination = {
      page: pagination.page + 1,
      pageSize: pagination.pageSize,
    };

    getMoreCompanyMediafiles({ pagination: newPagination, filters });
  };

  return {
    videoMediafiles: companyMediafiles || undefined,
    loading: companyMediafilesLoading,
    refetch,
    loadMore,
    companyId,
    uploadMediaFilesMutation,
    paginationInfo,
  };
};
