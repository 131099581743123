export const TEXTS = {
  PAGE_TITLE: 'Прогноз вещания',
  DOC_TITLE: 'Прогноз вещания',
  SWITCHER_HOUR_MODE: 'Час',
  DAY: 'День',
  CUT_DURATION: (value: number) => `Обрезка трека ${value} сек`,
  LABEL_INTERVAL: 'С интервалом',
  LABEL_EXACT: 'В указанное время',
  LABEL_SKIPPED: 'Пропущенный',
  UPDATE_MODAL_SUBMIT: 'Обновить',
  UPDATE_MODAL_CANCEL: 'Отменить',
  UPDATE_MODAL_WARNING: 'Изменение настроек применится на адресах:',
  TOOLBAR_SAVE_CHANGES: 'Сохранить изменения',
  TOOLBAR_CREATE_AD_CAMPAIGN: 'Создать рекламную кампанию',
  CHANGES_NOT_SAVED: 'Не сохранены изменения в прогнозе вещания',
  KEEP_CHANGES: 'Сохранить изменения',
  DELETE_CHANGES: 'Удалить изменения',
  SAVE_CHANGES_WARNING:
    'Сохраните изменения в прогнозе вещания, чтобы не потерять последние настройки в рекламных кампаниях',
  HELP_MODAL_TITLE: 'Прогноз вещания',
  HELP_OK_BUTTON: 'Понятно',
  HELP_TEXT_1:
    'Прогноз вещания показывает время выхода рекламных компаний. Первая строка серого цвета — музыкальная волна, которая играет на вашем адресе. Ниже показаны рекламные компании. Одна строчка — одна рекламная компания. Цвет зависит от типа вещания:',
  HELP_TEXT_2: 'По очереди с интервалом',
  HELP_TEXT_3: 'В указанное время',
  HELP_TEXT_4: 'Пропущенный ролик',
  HELP_TEXT_5:
    'Если включена обрезка треков, то при наложении роликов проигрывается только первый ролик, а остальные пропускаются. Чтобы убрать наложения, измените настройки рекламной компании — кликните на любой ролик этой рекламной компании в прогнозе',
  HELP_TEXT_6: 'Обрезка треков',
  SKIPPED_ADS_COUNT: (value: number, ending1 = 'о', ending2 = 'ов') =>
    `Сегодня будет пропущен${ending1} ${value} ролик${ending2} из-за наложения. Попробуйте изменить настройки ниже`,
  ALL_AD_CAMPAIGNS_TITLE: 'Все рекламные кампании',
  ALL_AD_CAMPAIGNS_LABEL: 'Все рекламные кампании',
  AD_CAMPAIGNS_MODAL_SHOW: 'Показать',
  AD_CAMPAIGNS_MODAL_SEARCH: 'Поиск...',
  SETTINGS_SAVED: 'Настройки сохранены',
  SETTINGS_SAVE_ERROR: 'Ошибка сохранения настроек',
  UPDATE_SCHEDULE: 'Обновление прогноза',
  AD_CAMPAIGN_CREATED: 'Рекламная кампания создана',
  AD_CAMPAIGN_TYPE_ACTIVE: 'Активные',
  AD_CAMPAIGN_TYPE_PLANNED: 'Запланированые',
  AD_CAMPAIGN_TYPE_EXPIRED: 'Прошедшие',
  AD_CAMPAIGN_TYPE_STOPPED: 'Остановленые',
};
