import React, { useEffect, useMemo, useRef, useState } from 'react';

import { usePointRepositoryInjector } from 'application/providers/DIContainerProvider';
import { useModalContext, ModalTypes } from 'application/providers/ModalProvider';
import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import {
  useNotificationContext,
  NotificationColors,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NOTIFICATION_DEFAULT_DELAY,
} from 'application/providers/NotificationProvider';

import { ApolloError } from '@apollo/client';
import { PlayerTypes, PlayerChangeControllerProps } from './types';
import { PLAYER_TYPES } from './consts';

const usePlayerChangeController = ({ pointId, fromPlayerType, onSuccessCallback }: PlayerChangeControllerProps) => {
  const { openModal, closeModal } = useModalContext();
  const notification = useNotificationContext();
  const { pointSetDevice, updateDeviceLoading, updateDeviceError } = usePointRepositoryInjector();

  const playerTypeRef = useRef<HTMLDivElement>(null);

  const [bindingCodeError, setBindingCodeError] = useState<string | false>(false);
  const [bindingCode, setBindingCode] = useState<string>('');
  const [lastCallBindingCode, setLastCallBindingCode] = useState<string | null>(null);
  const [toPlayerType, setToPlayerType] = useState<PlayerTypes>(fromPlayerType === 'WEBPLAYER' ? 'APP' : 'WEB');

  useEffect(() => {
    if (updateDeviceLoading) {
      setBindingCodeError(false);
    }
  }, [updateDeviceLoading]);

  const mapBindError = (updateError?: ApolloError) => {
    if (updateError) {
      if (updateError.message.includes(TEXTS.PLAYER_CHANGE_DEVICE_BACKEND_NO_DEVICE_ERROR)) {
        return TEXTS.PLAYER_CHANGE_DEVICE_MODAL_NO_DEVICE_ERROR;
      }
      if (updateError.message.includes(TEXTS.PLAYER_CHANGE_DEVICE_BACKEND_ALREADY_BOUND_ERROR)) {
        return TEXTS.PLAYER_CHANGE_DEVICE_MODAL_ALREADY_BOUND_ERROR;
      }
      return TEXTS.PLAYER_CHANGE_DEVICE_MODAL_UNKNOWN_ERROR;
    }

    return undefined;
  };

  useEffect(() => {
    if (updateDeviceError && bindingCode === lastCallBindingCode) {
      const codeError = mapBindError(updateDeviceError);
      if (codeError) {
        setBindingCodeError(codeError);
      }
    }

    if (updateDeviceError && bindingCode !== lastCallBindingCode) {
      setLastCallBindingCode(null);
      setBindingCodeError(false);
    }
  }, [updateDeviceError, bindingCode, lastCallBindingCode]);

  const toDeviceOptions = useMemo(() => {
    let options: PlayerTypes[];
    if (fromPlayerType === 'WEBPLAYER') {
      options = ['APP'];
    } else {
      options = ['APP', 'WEB'];
    }

    return options.map((key) => ({
      content: PLAYER_TYPES[key],
      selected: toPlayerType === key,
      key: `select_to_player_type_${key}`,
      onClick: () => {
        setToPlayerType(key);
      },
    }));
  }, [fromPlayerType, toPlayerType, setToPlayerType]);

  const handleUpdateClick = async (
    _?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback?: () => void,
    deviceCallback?: (requiredVersionUrl?: string) => void
  ) => {
    setLastCallBindingCode(bindingCode);

    callback?.();

    try {
      const toWebPlayer = toPlayerType === 'WEB';

      const { ok, deviceType, requiredVersionUrl } = await pointSetDevice({
        pointId,
        deviceBindingCode: toWebPlayer ? undefined : bindingCode,
        createWebPlayer: toWebPlayer,
      });

      if (ok && deviceType) {
        onSuccessCallback?.(deviceType, requiredVersionUrl);
        deviceCallback?.(requiredVersionUrl);
      }

      return requiredVersionUrl;
    } catch (err) {
      if (mapBindError(err as ApolloError) === undefined) {
        // other cases covers inside useEffect
        notification.showNotification({
          type: NotificationColors.ERROR,
          children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
          delay: NOTIFICATION_DEFAULT_DELAY,
        });
      }
    }

    return undefined;
  };

  const handleOpenConfirmationModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    openModal(ModalTypes.POINT_PLAYER_CHANGE_CONFIRMATION, {
      handleConfirmPlayerChange: async () => {
        handleUpdateClick(event, closeModal);
      },
      fromPlayerType,
    });
  };

  return {
    toDeviceOptions,
    toPlayerType,
    playerTypeRef,
    bindingCode,
    bindingCodeError,
    updateDeviceLoading,
    setBindingCode,
    setBindingCodeError,
    handleOpenConfirmationModal,
    handleUpdateClick,
    setToPlayerType,
  };
};

export default usePlayerChangeController;
