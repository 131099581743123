import { useApolloClient } from 'application/providers/DataProvider';

import useAdCampaign from 'domain/media/useAdCampaign';
import useAdCampaignCreate from 'domain/media/useAdCampaignCreate';
import useAdCampaignEnable from 'domain/media/useAdCampaignEnable';
import useAdCampaignInstantLaunch from 'domain/media/useAdCampaignInstantLaunch';
import useAdCampaignRemove from 'domain/media/useAdCampaignRemove';
import useAdCampaignReportCreate from 'domain/media/useAdCampaignReportCreate';
import useAdCampaignSetTags from 'domain/media/useAdCampaignSetTags';
import useAdCampaignUpdate from 'domain/media/useAdCampaignUpdate';
import useAdCampaigns from 'domain/media/useAdCampaigns';
import useAdPromosUpdate from 'domain/media/useAdPromosUpdate';
import usePromoUpload from 'domain/media/usePromoUpload';
import useAdSetPoints from 'domain/media/useAdSetPoints';

import {
  AdCampaignMetadataTypeFragment,
  AdCampaignPureCreateMutationInput,
  AdCampaignPureSetPointsMutationInput,
  AdCampaignPureType,
  AdCampaignShortTypeFragment,
  AdCampaignsQueryPaginationType,
  MediaAdCampaignDocument,
  MediaAdCampaignQuery,
  MediaAdCampaignsPaginationQueryDocument,
  MediaAdCampaignsPaginationQueryQuery,
  PromoAdInput,
} from 'domain/api/graphql/generated';
import { AdCampaignType } from 'domain/media/types';

import useAdCampaignsMetadata from 'domain/media/useAdCampaignsMetadata';
import { GetAdCampaignsType, UseAdCampaignRepositoryResultType } from './types';

const useAdCampaignRepository = (): UseAdCampaignRepositoryResultType => {
  const client = useApolloClient();

  const [adCampaignsRequest, adCampaignsResult] = useAdCampaigns();
  const [adCampaignsMetadataRequest] = useAdCampaignsMetadata();
  const [adCampaignRequest, adCampaignResult] = useAdCampaign();
  const [adCampaignCreateRequest, adCampaignCreateResult] = useAdCampaignCreate();
  const promoUpload = usePromoUpload();
  const [adCampaignEnableRequest] = useAdCampaignEnable();
  const [adCampaignRemoveRequest, adCampaignRemoveRequestResult] = useAdCampaignRemove();
  const [adCampaignReportCreateRequest, adCampaignReportCreateRequestResult] = useAdCampaignReportCreate();
  const [adCampaignUpdateRequest, adCampaignUpdateResult] = useAdCampaignUpdate();
  const [adCampaignPromoUpdateRequest, adCampaignPromoUpdateResult] = useAdPromosUpdate();
  const [setTagsRequest] = useAdCampaignSetTags();
  const {
    request: adCampaignInstantLaunchRequest,
    result: adCampaignInstantLaunchResult,
    abortRequest: adCampaignInstantLaunchAbortRequest,
  } = useAdCampaignInstantLaunch();
  const { request: updateAdPoints, error: adPointsUpdateError, loading: adPointsUpdateLoading } = useAdSetPoints();

  const getAdCampaigns = async ({ filters, pagination, sort }: GetAdCampaignsType) => {
    await adCampaignsRequest(filters, pagination, sort);
  };

  const readAdCampaigns = ({ filters, pagination, sort }: GetAdCampaignsType) => {
    const result = client.readQuery<MediaAdCampaignsPaginationQueryQuery>({
      query: MediaAdCampaignsPaginationQueryDocument,
      variables: { filters, pagination, sort },
    });

    return (result?.adcampaignsPaginationQuery as AdCampaignsQueryPaginationType) || null;
  };

  const getAdCampaignsMetadata = async ({ filters, pagination, sort }: GetAdCampaignsType) => {
    const result = await adCampaignsMetadataRequest(filters, pagination, sort);
    return result.data?.adcampaignsPaginationQuery.result as AdCampaignMetadataTypeFragment[];
  };

  const refetchAdCampaigns = async ({ filters, pagination, sort }: GetAdCampaignsType) => {
    const result = await adCampaignsResult.refetch({
      filters,
      pagination,
      sort,
    });

    return (result.data?.adcampaignsPaginationQuery.result || []) as AdCampaignShortTypeFragment[];
  };

  const getMoreAdCampaigns = async ({ filters, pagination, sort }: GetAdCampaignsType) => {
    await adCampaignsResult.loadMore(filters, pagination, sort);
  };

  const clearAdCampaigns = () => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'adcampaignsPaginationQuery',
      broadcast: false,
    });
  };

  const getAdCampaign = async (id: string) => {
    const result = await adCampaignRequest(id);
    return result.data?.adCampaignPure as AdCampaignType;
  };

  const readAdCampaign = (adCampaignId: string) => {
    const result = client.readQuery<MediaAdCampaignQuery>({
      query: MediaAdCampaignDocument,
      variables: {
        adCampaignId,
      },
    });

    return (result?.adCampaignPure || {}) as AdCampaignPureType;
  };

  const createAdCampaign = async (
    name: string,
    promoIds: string[],
    companyId: string,
    overrideValues: Partial<AdCampaignPureCreateMutationInput> = {}
  ): Promise<AdCampaignType> => {
    const result = await adCampaignCreateRequest(name, promoIds, companyId, overrideValues);
    return result as AdCampaignType;
  };

  const removeAdCampaign = async (id: string): Promise<boolean> => {
    const result = await adCampaignRemoveRequest(id);
    return result;
  };

  const updateAdCampaign = async (campaign: AdCampaignType): Promise<boolean> => {
    const result = await adCampaignUpdateRequest(campaign);
    return result;
  };

  const enableAdCampaign = async (id: string, enable: boolean): Promise<boolean> => {
    const result = await adCampaignEnableRequest(id, enable);
    return result;
  };

  const adCampaignPromoUpdate = async (id: string, adPromos: PromoAdInput[]): Promise<boolean> => {
    const result = await adCampaignPromoUpdateRequest(id, adPromos);
    return result;
  };

  const adCampaignCreateReport = async (
    adCampaignIds: string[],
    periodStart: string,
    periodEnd: string,
    recipientEmail?: string
  ): Promise<boolean> => {
    const result = await adCampaignReportCreateRequest(adCampaignIds, periodStart, periodEnd, recipientEmail);
    return result;
  };

  const adCampaignSetTags = async (adCampaignId: string, companyTagIds: string[]): Promise<boolean> => {
    const result = await setTagsRequest({ adCampaignId, companyTagIds });
    return result;
  };

  const adCampaignInstantLaunch = async (adCampaign: AdCampaignShortTypeFragment, pointId: string) => {
    const result = await adCampaignInstantLaunchRequest({ adcampaignId: adCampaign.id, pointId });
    return result;
  };

  const adCampaignSetPoints = async (input: AdCampaignPureSetPointsMutationInput) => {
    const result = await updateAdPoints(input);
    return result;
  };

  return {
    getAdCampaigns,
    readAdCampaigns,
    getAdCampaignsMetadata,
    refetchAdCampaigns,
    getMoreAdCampaigns,
    clearAdCampaigns,
    adCampaignsResult,
    adCampaign: adCampaignResult.data.adCampaign,
    adCampaignLoading: adCampaignResult.loading,
    adCampaignCreateLoading: adCampaignCreateResult.loading,
    adCampaignCreateError: adCampaignCreateResult.error,
    adCampaignRemoveLoading: adCampaignRemoveRequestResult.loading,
    adCampaignRemoveError: adCampaignRemoveRequestResult.error,
    adCampaignUpdateLoading: adCampaignUpdateResult.loading,
    adCampaignUpdateError: adCampaignUpdateResult.error,
    adCampaignCreateReportLoading: adCampaignReportCreateRequestResult.loading,
    adCampaignCreateReportErrors: adCampaignReportCreateRequestResult.data.errors,
    adCampaignPromoUpdateLoading: adCampaignPromoUpdateResult.loading,
    adCampaignPromoUpdateError: adCampaignPromoUpdateResult.error,
    adCampaignInstantLaunchLoading: adCampaignInstantLaunchResult.loading,
    adCampaignInstantLaunchError: adCampaignInstantLaunchResult.error,
    promoUpload,
    getAdCampaign,
    readAdCampaign,
    createAdCampaign,
    removeAdCampaign,
    updateAdCampaign,
    enableAdCampaign,
    adCampaignPromoUpdate,
    adCampaignCreateReport,
    adCampaignSetTags,
    adCampaignInstantLaunch,
    adCampaignInstantLaunchAbort: adCampaignInstantLaunchAbortRequest,
    adCampaignSetPoints,
    adPointsUpdateLoading,
    adPointsUpdateError,
  };
};

export default useAdCampaignRepository;
