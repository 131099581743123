import React from 'react';

import { Country } from 'domain/api/graphql/generated';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import classNames from 'classnames';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import styles from '../index.module.scss';
import { TEXTS } from './texts';

interface CertificateModalHintProps {
  isDescriptionExpaned: boolean;
  currentCompanyCountry?: Country;
  setIsDescriptionExpaned: React.Dispatch<React.SetStateAction<boolean>>;
}

const CertificateModalHint = ({
  isDescriptionExpaned,
  currentCompanyCountry,
  setIsDescriptionExpaned,
}: CertificateModalHintProps) => {
  const { isMobile } = useMedia();

  return (
    <>
      {(isDescriptionExpaned || !isMobile) && (
        <>
          <div className={styles.CertificateModalDescription}>
            <span>{TEXTS.CHOOSE_PONTS_DESCRIPTION}</span>
          </div>

          {currentCompanyCountry === Country.Ru && (
            <div className={styles.CertificateModalDescription}>{TEXTS.CHOOSE_PONTS_HELP_DESCRIPTION}</div>
          )}

          {isMobile && (
            <Button
              elementId="certificate_modal_hide_description"
              type={ButtonType.PLAIN}
              onClick={() => {
                setIsDescriptionExpaned(false);
              }}
              className={classNames(
                styles.CertificateModalExpandDescription,
                styles.CertificateModalExpandDescriptionWithMargin
              )}>
              {TEXTS.CHOOSE_PONTS_COLLAPSE_BUTTON}
            </Button>
          )}
        </>
      )}

      {!isDescriptionExpaned && isMobile && (
        <>
          <div className={styles.CertificateModalDescription}>
            {TEXTS.CHOOSE_PONTS_MOBILE_DESCRIPTION}
            <Button
              elementId="certificate_modal_expand_description"
              type={ButtonType.PLAIN}
              onClick={() => {
                setIsDescriptionExpaned(true);
              }}
              className={classNames(styles.CertificateModalExpandDescription)}>
              {TEXTS.CHOOSE_POINTS_EXPAND_BUTTON}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CertificateModalHint;
