import React from 'react';

import DeclensionUtils from 'application/utils/DeclensionUtils';

import { TEXTS } from '../texts';

import styles from './index.module.scss';

export type DescriptionProps = {
  isSourceZvukBusiness: boolean;
  withTrackCount?: boolean;
  title?: string;
  count: number;
};

const getDescription = (trackCount: number) => {
  const text = TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_TRACK;
  const declension = DeclensionUtils.pluralEnding(trackCount, text);

  return `${trackCount} ${text}${declension}`;
};

const Description = ({ isSourceZvukBusiness, title, count, withTrackCount = true }: DescriptionProps) => (
  <div className={styles.Description}>
    <span>{TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_FROM_TEXT}</span>
    <span>
      {isSourceZvukBusiness
        ? TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_FROM_ZVUK_BUSINESS_TEXT
        : TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_FROM_ZVUK_TEXT}
    </span>
    <span>«{title}»</span>
    {withTrackCount && <span>:&nbsp;{getDescription(count)}</span>}
  </div>
);

export default Description;
