import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import { IDraggableVideoMediafile } from '../types';
import { DraggableListItem } from './DraggableListItem';
import VideoStreamPreviewItem from '../SelectVideoMediafilesList/VideoMediafilePreviewItem';

import styles from './index.module.scss';
import { TEXTS } from '../texts';

export interface DraggableItemListType {
  itemList?: IDraggableVideoMediafile[];
  updateItems?: (items: Array<IDraggableVideoMediafile>) => void;
}

const DraggableVideoMediafiesList: React.FC<DraggableItemListType> = ({ itemList, updateItems }) => (
  <ReactSortable<IDraggableVideoMediafile>
    list={itemList}
    handle=".draggable"
    setList={updateItems}>
    {itemList?.map((item) => (
      <DraggableListItem key={item.id}>
        <VideoStreamPreviewItem
          className={styles.content}
          file={item}
          actionText={TEXTS.REMOVE_FROM_STREAM}
          actionHandler={() => updateItems && updateItems(itemList.filter((i) => i.id !== item.id))}
        />
      </DraggableListItem>
    ))}
  </ReactSortable>
);

export default DraggableVideoMediafiesList;
