import { useLocation as useLocationLib } from 'react-router-dom';
import type { Location } from 'react-router-dom';

// https://github.com/remix-run/react-router/pull/7326#issuecomment-626418225

export function useLocation<T>(): Location<Partial<T>> {
  const location = useLocationLib();

  return location;
}
