import React, { useLayoutEffect, useMemo, useState } from 'react';

import BrowserUtils from 'toolkit/BrowserUtils';

import { MediaStateType } from './types';
import {
  desktopLQueryList,
  desktopMQueryList,
  desktopQueryList,
  desktopSQueryList,
  desktopXLQueryList,
  mobileSQueryList,
  mobileMQueryList,
  mobileLQueryList,
  mobileQueryList,
  mobileXlQueryList,
  queriesList,
} from './consts';
import { MediaQueriesContext } from './MediaQueriesContext';

const checkMedia = (): MediaStateType => ({
  isMobileS: mobileSQueryList.matches,
  isMobileM: mobileMQueryList.matches,
  isMobileL: mobileLQueryList.matches,
  isMobile: mobileQueryList.matches,
  isDesktop: desktopQueryList.matches,
  isDesktopS: desktopSQueryList.matches,
  isDesktopL: desktopLQueryList.matches,
  isDesktopM: desktopMQueryList.matches,
  isDesktopXL: desktopXLQueryList.matches,
  isMobileXl: mobileXlQueryList.matches,
  isAndroid: BrowserUtils.isAndroid,
  isIOS: BrowserUtils.isIOS,
  isIpad: BrowserUtils.isIpad,
  isHuawei: BrowserUtils.isHuawei,
  isSafariDesktop: BrowserUtils.isSafariDesktop,
});

const MediaQueriesProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [mediaState, setMediaState] = useState(checkMedia());

  useLayoutEffect(() => {
    const handler = () => {
      const newMediaState = checkMedia();
      setMediaState(newMediaState);
    };

    queriesList.forEach((current) => {
      try {
        const isAddEventListenerExists = !!current.addEventListener;
        if (isAddEventListenerExists) {
          current.addEventListener('change', handler);
        } else {
          current.addListener(handler);
        }
      } catch (error) {
        console.error(`${current} does not support change event listeners. Error: ${error}`);
      }
    });

    return () => {
      queriesList.forEach((current) => {
        try {
          const isRemoveEventListenerExists = !!current.removeEventListener;
          if (isRemoveEventListenerExists) {
            current.removeEventListener('change', handler);
          } else {
            current.removeListener(handler);
          }
        } catch (error) {
          console.error(`${current} does not support change event listeners. Error: ${error}`);
        }
      });
    };
  }, []);

  const mediaStateValues = Object.values(mediaState);

  const contextValue = useMemo(() => mediaState, [mediaStateValues]);

  return <MediaQueriesContext.Provider value={contextValue}>{children}</MediaQueriesContext.Provider>;
};

export default MediaQueriesProvider;
