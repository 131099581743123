import { useEffect, useState } from 'react';

import { VALIDATION_TEXTS } from 'application/utils/ValidationUtils/texts';
import { useAccountContext } from 'application/providers/AccountProvider';
import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';
import { useNotificationContext } from 'application/providers/NotificationProvider';

import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';

import { AdLogsDate, UseAdLogsModalControllerProps } from './types';
import { NOTIFICATIONS } from '../consts';
import AdLogsModalUtils from './utils';

const useAdLogsModalController = ({
  id,
  close,
  adCampaignCreateReport,
  adCampaignCreateReportErrors,
}: UseAdLogsModalControllerProps) => {
  const { currentCompany } = useAccountContext();

  const notification = useNotificationContext();

  const dates = AdLogsModalUtils.getDates();

  const [value, setValue] = useState(dates[0].value);
  const [recipientEmail, setRecipientEmail] = useState(currentCompany?.requisite?.emailForDocuments);
  const [recipientEmailError, setRecipientEmailError] = useState<string>();

  useEffect(() => {
    if (adCampaignCreateReportErrors?.recipientEmail) {
      setRecipientEmailError(adCampaignCreateReportErrors.recipientEmail[0]);
    }
  }, [adCampaignCreateReportErrors?.recipientEmail]);

  useEffect(() => {
    setRecipientEmailError(undefined);
  }, []);

  const onChangeRecipientEmail = (value: string) => {
    setRecipientEmail(value);
    setRecipientEmailError(undefined);
  };

  const onFocusRecipientEmail = () => {
    setRecipientEmailError(undefined);
  };

  const validateForm = () => {
    if (recipientEmail?.length === 0) {
      setRecipientEmailError(VALIDATION_TEXTS.REQUIRED);
      return false;
    }

    return true;
  };

  const onAdCampaignReportCreate = async (value: AdLogsDate) => {
    try {
      const ok = await adCampaignCreateReport(
        [id],
        DateUtils.getFormatDateFrom(value.start, 'yyyy-MM-dd'),
        DateUtils.getFormatDateFrom(value.end, 'yyyy-MM-dd'),
        recipientEmail!.trim()
      );

      if (ok) {
        notification.showNotification(NOTIFICATIONS.REPORT_CREATE_SUCCESS);
        AnalyticsService.event(EventNameAMP.AD_CAMPAIGN_REPORT_REQUESTED);
      } else {
        throw Error();
      }
    } catch (e) {
      notification.showNotification(NOTIFICATIONS.ERROR);
    }

    close();
  };

  const onSubmitButtonClick = () => {
    if (validateForm()) {
      const adLosDate = dates.find((item) => item.value === value);
      onAdCampaignReportCreate(adLosDate!);
    }
  };

  return {
    value,
    setValue,
    recipientEmail,
    recipientEmailError,
    dates,
    onAdCampaignReportCreate,
    onChangeRecipientEmail,
    onFocusRecipientEmail,
    onSubmitButtonClick,
  };
};

export default useAdLogsModalController;
