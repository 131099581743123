import React, { FC } from 'react';
import classNames from 'classnames';

import { ModalTypes, ModalProps } from 'application/providers/ModalProvider';
import { TEXTS } from 'application/pages/AdsPage/renderer/texts';

import { AdCampaignTypeEnum } from 'domain/api/graphql/generated';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import AdInstantExplanation from './AdInstantExplanation';

import styles from './index.module.scss';

const EXPLANATION_TEXTS = {
  [AdCampaignTypeEnum.Interval]: TEXTS.AD_TYPE_INTERVAL_EXPLANATION,
  [AdCampaignTypeEnum.ExactTime]: TEXTS.AD_TYPE_EXACT_EXPLANATION,
  [AdCampaignTypeEnum.Period]: TEXTS.AD_TYPE_PERIOD_EXPLANATION,
  [AdCampaignTypeEnum.Instant]: <AdInstantExplanation />,
  [AdCampaignTypeEnum.Groups]: TEXTS.AD_TYPE_GROUPS_EXPLANATION,
};

const TITLE = {
  [AdCampaignTypeEnum.Interval]: TEXTS.AD_TYPE_INTERVAL_TITLE,
  [AdCampaignTypeEnum.ExactTime]: TEXTS.AD_TYPE_EXACT_TITLE,
  [AdCampaignTypeEnum.Period]: TEXTS.AD_TYPE_PERIOD_TITLE,
  [AdCampaignTypeEnum.Instant]: TEXTS.AD_TYPE_INSTANT_TITLE,
  [AdCampaignTypeEnum.Groups]: TEXTS.AD_TYPE_GROUPS_TITLE,
};

const ART = {
  [AdCampaignTypeEnum.Interval]: '/assets/img/campaigns/interval.png',
  [AdCampaignTypeEnum.ExactTime]: '/assets/img/campaigns/exact_time.png',
  [AdCampaignTypeEnum.Period]: '/assets/img/campaigns/period.png',
  [AdCampaignTypeEnum.Instant]: '/assets/img/campaigns/instant.png',
  [AdCampaignTypeEnum.Groups]: '/assets/img/campaigns/groups.png',
};

export type AdInfoModalProps = {
  type: AdCampaignTypeEnum;
};

const AdInfoModal: FC<ModalProps[ModalTypes.AD_CAMPAIGN_TYPE_EXPLANATION]> = ({ type, close }) => (
  <ModalScreen
    title={TITLE[type]}
    titlePlacement="left"
    hideCloseButton
    onClose={close}
    actionButtons={[
      <Button
        key="button_ad_info_modal"
        elementId="button_ad_info_modal"
        type={ButtonType.PRIMARY}
        onClick={close}>
        {TEXTS.MODAL_AD_INFO_RETURN_TEXT}
      </Button>,
    ]}>
    <div className={styles.AdInfoModal}>
      <span className={styles.AdInfoModalDesc}>{EXPLANATION_TEXTS[type]}</span>

      {type !== AdCampaignTypeEnum.Instant && (
        <div className={styles.AdInfoModalArtContainer}>
          <img
            src={ART[type]}
            alt={ART[type]}
            className={classNames(styles.AdInfoModalArt, {
              [styles.AdInfoModalArtInterval]: type === AdCampaignTypeEnum.Interval,
            })}
          />
        </div>
      )}
    </div>
  </ModalScreen>
);

export default AdInfoModal;
