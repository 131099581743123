import React, { useEffect } from 'react';

import LayoutToaster from 'application/layout/LayoutToaster';
import { windowUseDesk } from 'application/components/widgets/SupportChat';
import { Outlet } from 'application/providers/RouterProvider';

import styles from './index.module.scss';

const CleanLayout = () => {
  useEffect(() => {
    if (windowUseDesk.usedeskMessenger) {
      windowUseDesk.usedeskMessenger?.toggle(false);
    }
  }, [windowUseDesk.usedeskMessenger]);

  return (
    <div className={styles.CleanLayout}>
      <LayoutToaster />
      <Outlet />
    </div>
  );
};

export default CleanLayout;
