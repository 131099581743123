import { useAccountContext } from 'application/providers/AccountProvider';
import { useNavigate } from 'application/providers/RouterProvider';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { TEXTS } from 'application/pages/texts';
import { getStreamsCategoryPath, StreamsCategoryParamValues } from 'application/pages/StreamPage';

import { UseStreamRemoveModalControllerProps } from './types';

const useStreamRemoveModalController = ({
  targetStreamId,
  removeStream,
  getSavedStreams,
  close,
}: UseStreamRemoveModalControllerProps) => {
  const navigate = useNavigate();
  const notifications = useNotificationContext();

  const { companyIds } = useAccountContext();

  const onStreamRemove = async () => {
    try {
      if (!targetStreamId) {
        throw new Error();
      }

      const okRemove = await removeStream(+targetStreamId);

      if (!okRemove) {
        throw new Error();
      }

      await getSavedStreams(companyIds);
      navigate(getStreamsCategoryPath(StreamsCategoryParamValues.SAVED));

      notifications.showNotification({
        type: NotificationColors.INFO,
        children: TEXTS.MODAL_STREAM_REMOVE_SUCCESS_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });

      close?.();
    } catch (e) {
      notifications.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  return {
    onStreamRemove,
  };
};

export default useStreamRemoveModalController;
