import React from 'react';

import { useAccountContext } from 'application/providers/AccountProvider';
import SmartLayout from '../SmartLayout';
import CleanLayout from '../CleanLayout';

const SwitchLayout = () => {
  const { isAuthorized } = useAccountContext();

  if (isAuthorized) {
    return <SmartLayout />;
  }

  return <CleanLayout />;
};

export default SwitchLayout;
