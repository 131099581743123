import React, { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';

import MarketingAgreement from 'application/components/MarketingAgreement';
import { FORGOT_PAGE_PATH } from 'application/pages/EmailSendPage';
import { Link } from 'application/providers/RouterProvider';
import Input, { InputType } from '@zvuk-b2b/react-uikit/ui/Input';
import PasswordInput from '@zvuk-b2b/react-uikit/ui/PasswordInput';
import PhoneInput from '@zvuk-b2b/react-uikit/ui/PhoneInput';
import Button from '@zvuk-b2b/react-uikit/ui/Button';

import styles from './index.module.scss';

export type LegacyAuthFormErrors = {
  email?: string;
  phone?: string;
  password?: string;
};

export type LegacyAuthFormInitData = {
  email?: string;
  phone?: string;
};

export type LegacyAuthFormSubmitData = {
  email: string;
  phone?: string;
  password: string;
  marketingMaterialsAgreementReceived: boolean;
};

export type LegacyAuthFormProps = {
  className?: string;
  isLoading: boolean;
  isEmailDisabled?: boolean;
  isPhoneAvailable?: boolean;
  isRegisterMode?: boolean;
  inputEmailLabel: string;
  inputEmailPlaceholder: string;
  inputPhoneLabel?: string;
  inputPhonePlaceholder?: string;
  inputPasswordLabel: string;
  inputPasswordPlaceholder: string;
  forgotLinkLabel?: string;
  buttonContent: React.ReactNode;
  errors?: LegacyAuthFormErrors;
  initData?: LegacyAuthFormInitData;
  onSubmit: (data: LegacyAuthFormSubmitData) => void;
};

const LegacyAuthForm = (props: LegacyAuthFormProps) => {
  const {
    isEmailDisabled = false,
    isPhoneAvailable = false,
    isRegisterMode = false,
    inputEmailLabel,
    inputEmailPlaceholder,
    inputPhoneLabel,
    inputPhonePlaceholder,
    inputPasswordLabel,
    inputPasswordPlaceholder,
    buttonContent,
    forgotLinkLabel,
    initData,
    className,
    isLoading,
    errors,
    onSubmit,
  } = props;

  const [email, setEmail] = useState<string>(initData?.email || '');
  const [phone, setPhone] = useState<string>(initData?.phone || '');
  const [password, setPassword] = useState<string>('');
  const [marketingMaterialsAgreementReceived, setMarketingMaterialsAgreementReceived] = useState<boolean>(true);

  const onRegisterClick = (e: SyntheticEvent) => {
    e.preventDefault();

    if (isPhoneAvailable) {
      onSubmit({
        email: email.trim(),
        phone: phone.trim(),
        password,
        marketingMaterialsAgreementReceived,
      });
    } else {
      onSubmit({
        email: email.trim(),
        password,
        marketingMaterialsAgreementReceived,
      });
    }
  };

  return (
    <form
      className={classNames(styles.LegacyAuthForm, className)}
      onSubmit={onRegisterClick}>
      <Input
        elementId="input_email"
        type={InputType.TEXT}
        value={email}
        error={errors?.email}
        isDisabled={isLoading || isEmailDisabled}
        containerClassName={styles.LegacyAuthFormInput}
        label={inputEmailLabel}
        placeholder={inputEmailPlaceholder}
        onChange={setEmail}
        onBlur={() => setEmail(email.trim())}
      />

      {isPhoneAvailable && (
        <PhoneInput
          elementId="input_tel"
          initialValue={phone}
          error={errors?.phone}
          isDisabled={isLoading}
          containerClassName={styles.LegacyAuthFormInput}
          label={inputPhoneLabel}
          placeholder={inputPhonePlaceholder}
          onChange={(value) => setPhone(value as string)}
          onBlur={() => setPhone(phone.trim())}
        />
      )}

      <PasswordInput
        elementId="input_password"
        value={password}
        isDisabled={isLoading}
        error={errors?.password}
        containerClassName={styles.LegacyAuthFormPasswordInput}
        label={inputPasswordLabel}
        placeholder={inputPasswordPlaceholder}
        onChange={setPassword}
        onBlur={() => setPassword(password.trim())}
      />

      {forgotLinkLabel && (
        <Link
          className={styles.LegacyAuthFormLinkForgot}
          to={FORGOT_PAGE_PATH}>
          {forgotLinkLabel}
        </Link>
      )}

      <Button
        elementId="button_register"
        isLoading={isLoading}
        onClick={onRegisterClick}
        className={styles.LegacyAuthFormSubmitButton}>
        {buttonContent}
      </Button>

      {isRegisterMode && (
        <MarketingAgreement
          marketingMaterialsAgreementReceived={marketingMaterialsAgreementReceived}
          setMarketingMaterialsAgreementReceived={setMarketingMaterialsAgreementReceived}
          isLoading={isLoading}
          className={styles.LegacyAuthFormCheckbox}
        />
      )}

      <input
        type="submit"
        className={styles.LegacyAuthFormHiddenInput}
      />
    </form>
  );
};

export default LegacyAuthForm;
