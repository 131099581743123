import { BILLING_PAGE_PATH, StateParamValues } from 'application/pages/BillingPage';
import { TEXTS } from './texts';

// TODO: Cannot access '...' before initialization
// maybe need to create ROUTES file
const STREAMS_PAGE_PATH_ALIAS = '/streams/all';
const POINTS_MENU_PATH = '/points/all';

export enum ErrorTypes {
  PAYMENT = 'payment',
  PAYMENT_REQUEST = 'payment_request',
  NO_STREAM = 'no_stream',
  NO_POINT = 'no_point',
}

export const ErrorTitleByErrorType = {
  [ErrorTypes.PAYMENT]: TEXTS.ADDRESS_TURNED_OFF,
  [ErrorTypes.PAYMENT_REQUEST]: TEXTS.ADDRESS_TURNED_OFF,
  [ErrorTypes.NO_STREAM]: TEXTS.NO_STREAM,
  [ErrorTypes.NO_POINT]: TEXTS.LINK_IS_NOT_VALID,
};

export const ErrorContentByErrorType = {
  [ErrorTypes.PAYMENT]: TEXTS.ADDRESS_TURNED_OFF_CONTENT,
  [ErrorTypes.PAYMENT_REQUEST]: TEXTS.ADDRESS_TURNED_OFF_CONTENT,
  [ErrorTypes.NO_STREAM]: TEXTS.NO_STREAM_CONTENT,
  [ErrorTypes.NO_POINT]: TEXTS.NO_POINT_CONTENT,
};

export const ErrorButtonTextByErrorType = {
  [ErrorTypes.PAYMENT]: TEXTS.ADDRESS_TURNED_OFF_BUTTON,
  [ErrorTypes.PAYMENT_REQUEST]: TEXTS.ADDRESS_TURNED_OFF_BUTTON_NO_BILLING,
  [ErrorTypes.NO_STREAM]: TEXTS.NO_STREAM_BUTTON,
  [ErrorTypes.NO_POINT]: TEXTS.NO_POINT_BUTTON,
};

export const RedirectPathByErrorType = {
  [ErrorTypes.PAYMENT]: `${BILLING_PAGE_PATH}/${StateParamValues.INFO}`,
  [ErrorTypes.PAYMENT_REQUEST]: '',
  [ErrorTypes.NO_STREAM]: STREAMS_PAGE_PATH_ALIAS,
  [ErrorTypes.NO_POINT]: POINTS_MENU_PATH,
};

export const LoginRequiredModalContentByErrorType = {
  [ErrorTypes.PAYMENT]: TEXTS.POINT_DISABLED_CONTENT,
  [ErrorTypes.PAYMENT_REQUEST]: TEXTS.POINT_DISABLED_CONTENT,
  [ErrorTypes.NO_STREAM]: TEXTS.NO_STREAM_MODAL_CONTENT,
  [ErrorTypes.NO_POINT]: TEXTS.NOT_VALID_LINK_CONTENT,
};
