import { DEFAULT_AD_CAMPAIGN_STATUSES } from 'application/pages/AdsPage/renderer/AdsToolBar/consts';

import { DefaultFiltersType } from './types';

export const DEFAULT_FILTERS: DefaultFiltersType = {
  companyIds: [],
  query: '',
  pointId: '',
  statuses: DEFAULT_AD_CAMPAIGN_STATUSES,
  companyTagIds: [],
  type: [],
};

export const ALL_AD_CAMPAIGN_STATUSES_COUNT = DEFAULT_AD_CAMPAIGN_STATUSES.length;

export const DEFAULT_PAGINATION_INFO = {
  page: 0,
  pageSize: 100,
  totalCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  totalPages: 0,
};
