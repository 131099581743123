import { KEYS, useInjection, UsePointRepositoryType } from 'application/providers/DIContainerProvider';

const useStreamBindSuccessModalAdapter = () => {
  const usePointRepository = useInjection<UsePointRepositoryType>(KEYS.POINT_REPOSITORY);

  const { watchCurrentPoint, clearCurrentPoint, readCurrentPoint } = usePointRepository();

  return { watchCurrentPoint, clearCurrentPoint, readCurrentPoint };
};

export default useStreamBindSuccessModalAdapter;
