import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useRealResize } from 'toolkit/useResizeIOS';
import BrowserUtils from 'toolkit/BrowserUtils';
import useScrollOutsideCallback from 'toolkit/useScrollOutsideCallback';

import DropdownUtils from './utils';
import { DropdownItemDataType, UseDropdownControllerProps } from './types';

const useDropdownController = ({
  initialStyle,
  items,
  maxVisibleOptions,
  isFullMode,
  isMulti,
  parentNode,
  anchorNode,
  orientation,
  placement,
  menuMargin,
  isNeedCloseWhileParentScrolling,
  onClose,
  onSearchChange,
  withoutWrapper = false,
}: UseDropdownControllerProps) => {
  const [search, setSearch] = useState('');

  const filteredItems = useMemo(() => {
    if (onSearchChange) {
      return items;
    }

    return items.filter((items) => items.value?.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }, [items, search]);

  const [style, setStyle] = useState<React.CSSProperties | undefined>(initialStyle);
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const menuMaxHeight =
      withoutWrapper && !isFullMode
        ? 280
        : DropdownUtils.getMenuMaxHeight(ref.current!, maxVisibleOptions) + DropdownUtils.getBorder(ref.current!);
    const menuPosition =
      withoutWrapper && isFullMode
        ? {}
        : DropdownUtils.getMenuPosition(
            anchorNode,
            menuMaxHeight + DropdownUtils.getBorder(ref.current!),
            orientation,
            BrowserUtils.isIOS,
            menuMargin,
            placement
          );

    const defaultStyles = {
      ...initialStyle,
      ...menuPosition,
      opacity: 1,
    };

    if (initialStyle && (initialStyle.top || initialStyle.left)) {
      setStyle({
        ...initialStyle,
        maxHeight: menuMaxHeight,
        opacity: 1,
      });

      return;
    }

    if (!isFullMode) {
      setStyle({
        maxHeight: menuMaxHeight,
        ...defaultStyles,
      });
    } else {
      setStyle({
        maxHeight: '100%',
        width: '100%',
        ...defaultStyles,
      });
    }
  }, [items, anchorNode, maxVisibleOptions, orientation, ref.current]);

  useRealResize(onClose);

  useEffect(() => {
    const onClickOutside = (event: any) => {
      if (
        ref.current !== event.target &&
        (!parentNode || (parentNode && !BrowserUtils.isChildNode(event.target, parentNode)))
      ) {
        onClose();
      }
    };

    window.addEventListener('click', onClickOutside, true);

    return () => {
      window.removeEventListener('click', onClickOutside, true);
    };
  }, [onClose]);

  useScrollOutsideCallback(ref.current!, () => {
    if (!isFullMode && isNeedCloseWhileParentScrolling) {
      onClose();
    }
  });

  const handleDropdownItemClick = (
    item: DropdownItemDataType,
    event?: React.SyntheticEvent<Element, Event> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
    }

    item.onClick?.();

    if (!isMulti) {
      onClose();
    }
  };

  const handleSearchChange = (value: string) => {
    if (onSearchChange) {
      onSearchChange(value);
    }

    setSearch(value);
  };

  return {
    ref,
    filteredItems,
    search,
    style,
    handleSearchChange,
    handleDropdownItemClick,
  };
};

export default useDropdownController;
