import { useEffect, useMemo, useRef } from 'react';

import { usePointRepositoryInjector } from 'application/providers/DIContainerProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import {
  PointsPlayingStatusesPureType,
  PointQueryFilter,
  PointQueryPagination,
  PointQuerySort,
  CertificatePointsQueryFilter,
} from 'domain/api/graphql/generated';

import { ALL_POINT_STATUSES_COUNT, DEFAULT_FILTERS, DEFAULT_PAGINATION, DEFAULT_PAGINATION_INFO } from './consts';
import { CertificatePointsType } from './types';

const pageSize = DEFAULT_PAGINATION.pageSize;

const useCertificatePresenter = () => {
  const { currentCompany } = useAccountContext();

  const {
    pointsResult,
    pointsCertificateResult,
    getCertificatePoints,
    getMoreCertificatePoints,
    getPoint,
    point,
    pointLoading,
    readCertificatePoints,
  } = usePointRepositoryInjector();

  const filtersRef = useRef<CertificatePointsQueryFilter>({
    ...DEFAULT_FILTERS,
    companyId: currentCompany?.id || '',
  });

  const paginationRef = useRef<PointQueryPagination>({
    page: DEFAULT_PAGINATION.page,
    pageSize,
  });

  const points = readCertificatePoints({ filters: filtersRef.current, pagination: paginationRef.current })?.result;

  const sortRef = useRef<PointQuerySort>(PointQuerySort.IdDesc);
  const allPointsCount = useRef<null | number>(null);

  const resetFilters = () => {
    filtersRef.current = {
      ...DEFAULT_FILTERS,
      companyId: currentCompany?.id || '',
    };

    paginationRef.current = DEFAULT_PAGINATION;

    sortRef.current = PointQuerySort.IdDesc;
  };

  useEffect(() => () => resetFilters(), []);

  useEffect(() => {
    const resultData = pointsCertificateResult;

    if (
      resultData.data.paginationInfo?.totalCount !== undefined &&
      (allPointsCount.current === null || allPointsCount.current === 0)
    ) {
      allPointsCount.current = resultData.data.paginationInfo?.totalCount || 0;
    }
  }, [pointsResult, pointsCertificateResult]);

  const requestPoints = async (
    filters: PointQueryFilter = DEFAULT_FILTERS,
    pagination: PointQueryPagination = {
      page: DEFAULT_PAGINATION.page,
      pageSize: pageSize || DEFAULT_PAGINATION.pageSize,
    }
  ) => {
    filtersRef.current = {
      ...filtersRef.current,
      ...filters,
      companyId: currentCompany?.id || '',
    };

    paginationRef.current = {
      ...paginationRef.current,
      ...pagination,
    };

    await getCertificatePoints({
      filters: filtersRef.current,
      pagination: paginationRef.current,
    });
  };

  const loadMorePoints = async () => {
    const page = (pointsCertificateResult.data.paginationInfo?.page || 0) + 1;

    if (page <= paginationRef.current.page) return [];

    paginationRef.current.page = page;

    const newPoints = await getMoreCertificatePoints({
      filters: filtersRef.current,
      pagination: paginationRef.current,
    });

    return newPoints;
  };

  const requestPoint = async (id: string) => {
    await getPoint(id);
  };

  const preparedPoints = useMemo(
    () =>
      points?.map(
        (item) =>
          ({
            id: item.id,
            isEnabled: item.isEnabled,
            device: item.device,
            address: item.address,
            stream: item.stream,
            playingStatus: item.playingStatus,
            comment: item.comment,
            tags: item.tags,
            certificateIsAvailable: item.certificateIsAvailable,
            cutDuration: item.cutDuration,
            withMusicCut: item.withMusicCut,
          }) as CertificatePointsType
      ) || [],
    [points]
  );

  const preparedStatusesInfo = useMemo(() => {
    let statusesInfo: PointsPlayingStatusesPureType = {};

    const { data } = pointsResult;

    if (data.statusesInfo) {
      statusesInfo = { ...data.statusesInfo };
      delete statusesInfo.__typename;
    }

    return statusesInfo;
  }, [pointsResult.data.statusesInfo]);

  return {
    points: preparedPoints,
    point,
    pointLoading,
    statusesInfo: preparedStatusesInfo,
    paginationInfo: pointsCertificateResult.data.paginationInfo || DEFAULT_PAGINATION_INFO,
    certificatePointsCount: pointsCertificateResult?.certificatePointsCount,
    filters: filtersRef.current,
    pointsLoading: pointsCertificateResult.loading,
    pointsCalled: pointsCertificateResult.called,
    allPointsCount: allPointsCount.current,
    allPointStatusesCount: ALL_POINT_STATUSES_COUNT,
    requestPoints,
    loadMorePoints,
    requestPoint,
    resetFilters,
  };
};

export default useCertificatePresenter;
