import React, { useMemo } from 'react';
import classNames from 'classnames';

import { BANNER_TYPE, TopBannerState } from 'application/components/widgets/TopBanner/useTopBannerState';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import IconX from '@zvuk-b2b/react-uikit/ui/icons/X';
import { Country } from 'domain/api/graphql/generated';
import styles from './index.module.scss';
import { TEXTS } from './texts';
import TrialBanner from './TrialBanner';
import UnpaidBanner from './UnpaidBanner';

type TopBannerProps = {
  className?: string;
  banner: TopBannerState;
  isBillingPage: boolean;
  onClose: () => void;
  country: Country;
};

const TopBanner = ({ banner, onClose, className, isBillingPage, country }: TopBannerProps) => {
  const closeButton = useMemo(
    () => (
      <Button
        elementId="button-billing-banner-close"
        className={styles.TopBannerCloseButton}
        onClick={onClose}
        type={ButtonType.PLAIN}>
        <IconX className={styles.TopBannerCloseButtonIcon} />
      </Button>
    ),
    []
  );

  if (banner.type === BANNER_TYPE.TRIAL && !isBillingPage) {
    return (
      <TrialBanner
        banner={banner}
        className={className}
        onClose={onClose}
        country={country}
      />
    );
  }

  if (banner.type === BANNER_TYPE.GRACE) {
    return (
      <div
        className={classNames(styles.TopBanner, className)}
        data-type={banner.type}>
        <div className={styles.TopBannerDescription}>{TEXTS.BANNER_GRACE_TEXT}</div>
        {closeButton}
      </div>
    );
  }

  if (banner.type === BANNER_TYPE.UNPAID && !isBillingPage) {
    return (
      <UnpaidBanner
        banner={banner}
        onClose={onClose}
        className={className}
      />
    );
  }

  return null;
};

export default TopBanner;
