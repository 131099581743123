import { useCompanyUserCompanyTagCreateMutation, CompanyTagCreateMutationInput } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { CompanyCreateTagResultType, UseCompanyCreateTagType } from './types';

const useCompanyCreateTag = (): UseCompanyCreateTagType => {
  const [createMutation, createMutationResult] = useCompanyUserCompanyTagCreateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: CompanyTagCreateMutationInput) => {
    const newTag = await createMutation({
      variables: {
        input,
      },
    });

    return {
      isOk: !!newTag.data?.companyTagCreatePureMutation?.ok,
      tag: newTag.data?.companyTagCreatePureMutation?.companyTag,
    };
  };

  const result: CompanyCreateTagResultType = {
    error: createMutationResult.error,
    loading: createMutationResult.loading,
    called: createMutationResult.called,
  };

  return [request, result];
};

export default useCompanyCreateTag;
