import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';
import { TEXTS } from './texts';

export type OfertaModalProps = {
  onSubmit: () => void;
  isPopularTariff: boolean;
};

export const NEW_OFERTA_LINK = 'https://zvuk-b2b.com/oferta';
export const NEW_POPULAR_OFERTA_LINK = 'https://zvuk-b2b.com/oferta-popular';

const OfertaModal: FC<ModalProps[ModalTypes.OFFERTA]> = ({ isPopularTariff, onSubmit, close }) => (
  <ModalScreen
    title={TEXTS.MODAL_TITLE}
    onClose={close}
    hideCloseButton
    className={styles.OfertaModal}
    actionButtons={[
      <Button
        key="button_confirm_oferta"
        elementId="button_confirm_oferta"
        type={ButtonType.PRIMARY}
        onClick={onSubmit}>
        {TEXTS.MODAL_CONFIRM_BUTTON}
      </Button>,
    ]}>
    <div className={styles.OfertaModalDescription}>
      <div>
        {TEXTS.MODAL_TEXT}
        <a
          href={isPopularTariff ? NEW_POPULAR_OFERTA_LINK : NEW_OFERTA_LINK}
          target="_blank"
          rel="noopener noreferrer">
          {TEXTS.MODAL_LINK_ANCHOR}
        </a>
      </div>
    </div>
  </ModalScreen>
);

export default OfertaModal;
