import React, { FC } from 'react';

import { TEXTS } from 'application/pages/BillingPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import useCardBindModalPresenter from './useCardBindModalPresenter';

import styles from './index.module.scss';
import useCardBindModalController from './useCardBindModalController';

const CardBindModal: FC<ModalProps[ModalTypes.BILLING_BIND_CARD]> = ({ close }) => {
  const { getLinkForBindingCard, bindCardLoading } = useCardBindModalPresenter();

  const { bindCardConfirm } = useCardBindModalController({ getLinkForBindingCard });

  return (
    <ModalScreen
      title={TEXTS.BILLING_BIND_CARD_MODAL_TITLE}
      titlePlacement="left"
      hideCloseButton
      onClose={close}
      actionButtons={[
        <Button
          key="button_confirm_bind_card_modal"
          elementId="button_confirm_bind_card_modal"
          isLoading={bindCardLoading}
          type={ButtonType.PRIMARY}
          onClick={bindCardConfirm}>
          {TEXTS.BILLING_BIND_CARD_MODAL_KASSA}
        </Button>,
        <Button
          key="button_cancel_bind_card_modal"
          elementId="button_cancel_bind_card_modal"
          type={ButtonType.SECONDARY}
          onClick={close}>
          {TEXTS.BILLING_BIND_CARD_MODAL_CANCEL}
        </Button>,
      ]}>
      <div className={styles.CardBindModalDesc}>{TEXTS.BILLING_BIND_CARD_MODAL_DESCRIPTION}</div>
    </ModalScreen>
  );
};

export default CardBindModal;
