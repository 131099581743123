import React from 'react';

import { BILLING_PAGE_PATH } from 'application/consts';
import BillingPageWrapper from 'application/pages/BillingPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const billingRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: BILLING_PAGE_PATH,
        element: <BillingPageWrapper />,
      },
    ],
  },
];
