import React from 'react';

import { TEXTS } from '../texts';

import styles from './index.module.scss';

export type DescriptionProps = {
  playlistTitle?: string;
  profileName?: string;
  isPublished?: boolean | null;
};

const SuccessNotification = ({ playlistTitle = '', profileName = '', isPublished = true }: DescriptionProps) => (
  <div className={styles.SuccessNotification}>
    {TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_PUBLISH_SUCCESS_TEXT(playlistTitle, profileName, isPublished)}
  </div>
);

export default SuccessNotification;
