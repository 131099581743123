import React from 'react';

import { TEXTS } from 'application/pages/texts';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import useStreamRemoveModalAdapter from './useStreamRemoveModalAdapter';
import useStreamRemoveModalController from './useStreamRemoveModalController';
import { StreamRemoveModalProps } from './types';

import styles from './index.module.scss';

const StreamRemoveModal = ({ close, targetStreamId }: StreamRemoveModalProps) => {
  const { removeStream, getSavedStreams, isStreamRemoveLoading } = useStreamRemoveModalAdapter();

  const { onStreamRemove } = useStreamRemoveModalController({ targetStreamId, removeStream, getSavedStreams, close });

  return (
    <ModalScreen
      title={TEXTS.MODAL_STREAM_REMOVE_TITLE}
      titlePlacement="left"
      onClose={close}
      actionButtons={[
        <Button
          key="button_confirm_stream_campaign_remove_modal"
          elementId="button_confirm_stream_campaign_remove_modal"
          type={ButtonType.PRIMARY}
          onClick={onStreamRemove}
          isLoading={isStreamRemoveLoading}>
          {TEXTS.MODAL_STREAM_REMOVE_BUTTON_CONFIRM}
        </Button>,
        <Button
          key="button_cancel_stream_campaign_remove_modal"
          elementId="button_cancel_stream_campaign_remove_modal"
          type={ButtonType.SECONDARY}
          onClick={close}
          isLoading={isStreamRemoveLoading}>
          {TEXTS.MODAL_STREAM_REMOVE_BUTTON_CANCEL}
        </Button>,
      ]}>
      <div className={styles.StreamRemoveModalDesc}>{TEXTS.MODAL_STREAM_REMOVE_DESCRIPTION}</div>
    </ModalScreen>
  );
};

export default StreamRemoveModal;
