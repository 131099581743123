import React, { FC } from 'react';

import { TEXTS } from 'application/pages/AdsPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import useAdRemoveModalAdapter from './useAdRemoveModalAdapter';
import useAdRemoveModalController from './useAdRemoveModalController';

import styles from './index.module.scss';

const AdRemoveModal: FC<ModalProps[ModalTypes.AD_CAMPAIGN_REMOVE]> = ({ id, close }) => {
  const { removeAdCampaign, adCampaignRemoveLoading, clearAdCampaigns } = useAdRemoveModalAdapter();

  const { onAdCampaignRemove } = useAdRemoveModalController({ id, close, removeAdCampaign, clearAdCampaigns });

  return (
    <ModalScreen
      title={TEXTS.MODAL_AD_REMOVE_TITLE}
      titlePlacement="left"
      hideCloseButton
      onClose={close}
      className={styles.AdRemoveModalDesc}
      actionButtons={[
        <Button
          key="button_confirm_ad_campaign_remove_modal"
          elementId="button_confirm_ad_campaign_remove_modal"
          type={ButtonType.PRIMARY}
          onClick={onAdCampaignRemove}
          isLoading={adCampaignRemoveLoading}>
          {TEXTS.MODAL_AD_REMOVE_BUTTON_CONFIRM}
        </Button>,
        <Button
          key="button_cancel_ad_campaign_remove_modal"
          elementId="button_cancel_ad_campaign_remove_modal"
          type={ButtonType.SECONDARY}
          onClick={close}
          isDisabled={adCampaignRemoveLoading}>
          {TEXTS.MODAL_AD_REMOVE_BUTTON_CANCEL}
        </Button>,
      ]}>
      <div className={styles.AdRemoveModal}>{TEXTS.MODAL_AD_REMOVE_DESCRIPTION}</div>
    </ModalScreen>
  );
};

export default AdRemoveModal;
