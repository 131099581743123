import React, { useEffect, useMemo, useState } from 'react';

import { IS_OFERTA_CONFIRMED } from 'application/consts';
import { MenuItemDataType, UserMenuItemDataType } from 'application/providers/MenuItemsProvider/types';
import { NEW_OFERTA_LINK, NEW_POPULAR_OFERTA_LINK } from 'application/components/modals/OfertaModal';
import { OnCertSubmitType } from 'application/components/modals/CertificateModal';
import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';
import FileUtils from 'application/utils/FileUtils';

import useCreateCertificate from 'domain/certificate/useCreateCertificate';

import { useAccountContext } from '../AccountProvider';
import { ModalTypes, useModalContext } from '../ModalProvider';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from '../NotificationProvider';
import { MenuItemsContext } from './MenuItemsContext';

const MenuItemsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { currentCompany } = useAccountContext();
  const { openModal, closeModal } = useModalContext();

  const [mainMenuItems, setMainMenuItems] = useState<MenuItemDataType[]>([]);
  const [userMenuItems, setUserMenuItems] = useState<UserMenuItemDataType[]>([]);

  const notification = useNotificationContext();

  const { request: certificateRequest } = useCreateCertificate();

  const mainMenu = useMemo(
    () => ({
      items: mainMenuItems,
      setItems: setMainMenuItems,
    }),
    [mainMenuItems]
  );

  const userMenu = useMemo(
    () => ({
      items: userMenuItems,
      setItems: setUserMenuItems,
    }),
    [userMenuItems]
  );

  const onOffertaSubmit = (isPopularTariff: boolean) => {
    window.open(isPopularTariff ? NEW_POPULAR_OFERTA_LINK : NEW_OFERTA_LINK, '_blank');
    localStorage.setItem(IS_OFERTA_CONFIRMED, 'true');
    closeModal();
  };

  const showOfertaModal = (isPopularTariff: boolean) => {
    openModal(ModalTypes.OFFERTA, {
      onSubmit: () => {
        onOffertaSubmit(isPopularTariff);
      },
      isPopularTariff,
    });
  };

  const onCertModalSubmit = async ({ pointIds, createForAll }: OnCertSubmitType) => {
    closeModal();

    const input = {
      companyId: currentCompany?.id || '',
      pointIds,
      createForAll,
    };

    try {
      const archiveUrl = await certificateRequest(input);
      FileUtils.downloadExternalFile(archiveUrl.data || '');
    } catch (e) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const showCertModal = () => {
    AnalyticsService.event(EventNameAMP.CERTIFICATE_MENU_ITEM_CLICKED);

    openModal(ModalTypes.CERTIFICATE, {
      onSubmit: onCertModalSubmit,
    });
  };

  useEffect(() => () => closeModal?.(), []);

  return (
    <MenuItemsContext.Provider
      value={{
        mainMenu,
        userMenu,
        showOfertaModal,
        showCertModal,
      }}>
      {children}
    </MenuItemsContext.Provider>
  );
};

export default MenuItemsProvider;
