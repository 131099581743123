import { useMediaStreamTemplateGroupsLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const GENRES_GROUP_ID = '1';
const useStreamTemplateGroups = () => {
  const [templateGroupsRequest, templateGroupsResult] = useMediaStreamTemplateGroupsLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (groupId: string = GENRES_GROUP_ID) => {
    await templateGroupsRequest({
      variables: {
        groupId,
      },
    });
  };

  return {
    request,
    error: templateGroupsResult.error,
    called: templateGroupsResult.called,
    loading: templateGroupsResult.loading,
    streams: templateGroupsResult.data?.result || undefined,
  };
};

export default useStreamTemplateGroups;
