import React from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import styles from './index.module.scss';

export type TInteractiveErrorToaster = {
  onSubmit: () => void;
  onClose: () => void;
  title: string;
  submitButtonTitle: string;
  closeButtonTitle: string;
  elementId: string;
};

const InteractiveErrorToaster = ({
  onSubmit,
  onClose,
  title,
  submitButtonTitle,
  closeButtonTitle,
  elementId,
}: TInteractiveErrorToaster) => (
  <div className={styles.InteractiveErrorToaster}>
    <span>{title}</span>

    <div className={styles.InteractiveErrorToasterButtonContainer}>
      <Button
        elementId={`button_${elementId}_submit`}
        className={styles.InteractiveErrorToasterButton}
        type={ButtonType.PLAIN}
        onClick={onSubmit}>
        {submitButtonTitle}
      </Button>

      <Button
        elementId={`button_${elementId}_cancel`}
        className={styles.InteractiveErrorToasterButton}
        type={ButtonType.PLAIN}
        onClick={onClose}>
        {closeButtonTitle}
      </Button>
    </div>
  </div>
);

export default InteractiveErrorToaster;
