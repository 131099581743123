import React, { useMemo, useState } from 'react';

import useStream from 'domain/media/streams/useStream';
import useUserMediafiles from 'domain/media/useUserMediafiles';
import {
  StreamInfoTypeFragment,
  PaginationInfoType,
  MediaStreamQuery,
  Exact,
  MediafileApprovalFragmentFragment,
} from 'domain/api/graphql/generated';
import { QueryResult } from '@apollo/client';
import { KEYS, UseStreamRepositoryType, useInjection } from 'application/providers/DIContainerProvider';

export type ReviewStreamModelContextType = {
  stream?: StreamInfoTypeFragment;
  mediaFiles: MediafileApprovalFragmentFragment[];
  isMediaFilesLoading: boolean;
  error?: Error;
  loading: boolean;
  paginationInfo?: PaginationInfoType;
  mediaFileActionLoadingMap: Map<string, boolean>;
  requestStream: (id: string) => Promise<QueryResult<MediaStreamQuery, Exact<{ id: string }>>>;
  requestUserMediaFiles: (id: string, contentblockId: string, page?: number, pageSize?: number) => Promise<void>;
  unLikeMediaFile: (streamId: string, mediafileId: string) => Promise<boolean>;
  likeMediaFile: (streamId: string, mediafileId: string) => Promise<boolean>;
  dislikeMediaFile: (streamId: number, mediafileId: number) => Promise<boolean>;
  dislikeMediaFileRestore: (streamId: number, mediafileId: number) => Promise<boolean>;
  handleMediaFileAction: (key: string, value: boolean) => void;
};

const ReviewStreamModelContext = React.createContext<ReviewStreamModelContextType>({} as ReviewStreamModelContextType);

export const useReviewStreamModelContext = () => React.useContext(ReviewStreamModelContext);

const ReviewStreamModelProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const targetStreamData = useStream();
  const { request: requestUserMediaFiles, data, loading } = useUserMediafiles();
  const useStreamRepository = useInjection<UseStreamRepositoryType>(KEYS.STREAM_REPOSITORY);

  const { unLikeMediaFile, likeMediaFile, dislikeMediaFile, dislikeMediaFileRestore } = useStreamRepository();

  const [mediaFileActionLoadingMap, setMediaFileActionLoadingMap] = useState<Map<string, boolean>>(new Map());

  const handleMediaFileAction = (key: string, value: boolean) => {
    setMediaFileActionLoadingMap((prevMap) => {
      const updatedMap = new Map(prevMap);

      updatedMap.set(key, value);
      return updatedMap;
    });
  };

  const reviewStreamModel = useMemo(
    (): ReviewStreamModelContextType => ({
      stream: targetStreamData.stream,
      mediaFiles: data.mediafilesList || [],
      isMediaFilesLoading: loading,
      paginationInfo: data.paginationInfo,
      error: targetStreamData.error,
      loading: targetStreamData.loading,
      requestStream: targetStreamData.request,
      requestUserMediaFiles,
      unLikeMediaFile,
      likeMediaFile,
      dislikeMediaFile,
      dislikeMediaFileRestore,
      mediaFileActionLoadingMap,
      handleMediaFileAction,
    }),
    [targetStreamData.stream, data, loading, targetStreamData.error, targetStreamData, requestUserMediaFiles]
  );

  return <ReviewStreamModelContext.Provider value={reviewStreamModel}>{children}</ReviewStreamModelContext.Provider>;
};

export default ReviewStreamModelProvider;
