import {
  AuthCurrentUserDocument,
  PointSwitchDeviceMutationInput,
  usePointChangeDeviceMutation,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { PointChangeDeviceResultType } from './types';

const usePointChangeDevice = () => {
  const [mutation, mutationResult] = usePointChangeDeviceMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: PointSwitchDeviceMutationInput): Promise<PointChangeDeviceResultType> => {
    const mutationResult = await mutation({ variables: { input }, refetchQueries: [AuthCurrentUserDocument] });
    return {
      ok: !!mutationResult.data?.pointPureSwitchDevice?.ok,
      deviceType: mutationResult.data?.pointPureSwitchDevice?.point?.device?.type || undefined,
      requiredVersionUrl: mutationResult.data?.pointPureSwitchDevice?.point?.device?.requiredVersionUrl || undefined,
    };
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    point: mutationResult.data?.pointPureSwitchDevice?.point || undefined,
    request,
  };
};

export default usePointChangeDevice;
