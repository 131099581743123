import React, { useEffect, useRef, useState } from 'react';
import ProgressSlider from '@zvuk-b2b/react-uikit/ui/ProgressSlider';
import AnalyticsService, { EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';
import styles from './index.module.scss';

export type VolumeSliderProps = React.PropsWithChildren<{
  elementId: string;
  progress: number;
  onChange: (volume: number) => void;
  isDemo?: boolean;
  isPromo?: boolean;
}>;

const DEFAULT_MIN_DURATION = 0;
const DEFAULT_MAX_DURATION = 100;
const DEFAULT_STEP = 0.1;

const MediaListItemProgressSlider = ({ elementId, onChange, progress, isDemo, isPromo }: VolumeSliderProps) => {
  const [isChanging, setIsChanging] = useState(false);
  const [playedTime, setPlayedTime] = useState<number>(DEFAULT_MIN_DURATION);
  const sliderRef = useRef<HTMLInputElement | null>(null);

  const changeRangeProgressLine = (value: number) => {
    if (sliderRef.current) {
      sliderRef.current.style.setProperty('--range-progress', `calc(${value}%)`);
    }
  };

  useEffect(() => {
    if (!isChanging) {
      const value = +(progress * DEFAULT_MAX_DURATION).toFixed(1);
      setPlayedTime(value);
      changeRangeProgressLine(value);
    }
  }, [progress]);

  const getTouchValue = (e: React.TouchEvent<HTMLInputElement>) => {
    const slider = sliderRef.current!.getBoundingClientRect();
    const touchLocation = e.touches[0].clientX;
    const touchPosition = touchLocation - slider.left;
    const sliderWidth = slider.width;
    const touchRatio = touchPosition / sliderWidth;
    const newValue = Math.min(Math.max(touchRatio * DEFAULT_MAX_DURATION, DEFAULT_MIN_DURATION), DEFAULT_MAX_DURATION);

    return newValue;
  };

  const getMouseValue = (e: React.MouseEvent<HTMLInputElement>) => {
    const slider = sliderRef.current!.getBoundingClientRect();
    const clickPosition = e.clientX - slider.left;
    const sliderWidth = slider.width;
    const clickRatio = clickPosition / sliderWidth;
    const newValue = Math.min(Math.max(clickRatio * DEFAULT_MAX_DURATION, DEFAULT_MIN_DURATION), DEFAULT_MAX_DURATION);

    return newValue;
  };

  const handleChangeByThumb = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = Number(e.target.value);

    setPlayedTime(value);
    changeRangeProgressLine(value);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = getMouseValue(e);

    setIsChanging(true);
    setPlayedTime(value);
    changeRangeProgressLine(value);

    AnalyticsService.event(EventNameAMP.MEDIAFILE_PROGRESS_TOUCHED, {
      [ParamNameAMP.IS_DEMO]: Boolean(isDemo),
      [ParamNameAMP.IS_PROMO]: Boolean(isPromo),
    });

    if (sliderRef.current) {
      sliderRef.current.style.setProperty('--size-thumb-scale', '2');
    }
  };

  const onMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const target = e.target as HTMLInputElement;
    const value = Number(target.value);
    setIsChanging(false);
    onChange(value / 100);

    if (sliderRef.current) {
      sliderRef.current.style.setProperty('--size-thumb-scale', '1');
    }
  };

  const onTouchStart = (e: React.TouchEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = getTouchValue(e);

    AnalyticsService.event(EventNameAMP.MEDIAFILE_PROGRESS_TOUCHED, {
      [ParamNameAMP.IS_DEMO]: Boolean(isDemo),
      [ParamNameAMP.IS_PROMO]: Boolean(isPromo),
    });

    setIsChanging(true);
    setPlayedTime(value);
    changeRangeProgressLine(value);

    if (sliderRef.current) {
      sliderRef.current.style.setProperty('--size-thumb-scale', '2');
    }
  };

  const onTouchMove = (e: React.TouchEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = getTouchValue(e);

    setIsChanging(true);
    setPlayedTime(value);
    changeRangeProgressLine(value);
  };

  const onTouchEnd = (e: React.TouchEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const value = Number(target.value);

    setIsChanging(false);
    onChange(value / 100);

    if (sliderRef.current) {
      sliderRef.current.style.setProperty('--size-thumb-scale', '1');
    }
  };

  const onClickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.MediaListItemProgressSlider}>
      <ProgressSlider
        elementId="media_list_item_progress_slider"
        ref={sliderRef}
        id={elementId}
        min={DEFAULT_MIN_DURATION}
        max={DEFAULT_MAX_DURATION}
        value={playedTime}
        onInput={handleChangeByThumb}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onClick={onClickHandler}
        step={DEFAULT_STEP}
        className={isChanging ? styles.MediaListItemProgressSliderInput : ''}
      />
    </div>
  );
};

export default MediaListItemProgressSlider;
