import React, { FC, useEffect, useCallback, useMemo } from 'react';

import { TEXTS } from 'application/pages/texts';
import { HELP_CENTER_CONTRACTS_LINK } from 'application/pages/constants';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { withProviders } from 'application/helpers';
import PointModelProvider from 'application/pages/PointsPage/providers/PointModelProvider';
import CustomInfo from 'application/pages/StreamPage/renderer/CustomInfo/CustomInfo';
import SelectableList from 'application/components/SelectableList';
import EmptyState from 'application/components/EmptyState';
import PointItemContent from 'application/components/PointItemContent';

import { PointShortTypeFragment } from 'domain/api/graphql/generated';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import InputSearch from '@zvuk-b2b/react-uikit/ui/InputSearch';
import Checkbox, { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';

import AnalyticsService from 'application/services/AnalyticsService';
import { PointEventNameAMP, PointParamNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { PointCreateModalPages } from 'application/components/modals/PointCreateModal/types';
import useStreamBindModalController from './useStreamBindModalController';
import useStreamBindModalPresenter from './useStreamBindModalPresenter';

import styles from './index.module.scss';

const StreamBindModal: FC<ModalProps[ModalTypes.STREAM_BIND]> = ({
  pointFromLocation,
  targetStreamId,
  collectionId,
  isTemplateMode,
  close,
  onConfirmClose,
  onNext,
}) => {
  const {
    isEmpty,
    isEmptyFiltered,
    points,
    pointsLoading,
    onFilterExecute,
    isCustomInfoShouldShow,
    paginationInfo,
    allPointsCount,
    isLoading,
    userOnboardingFeaturesLoading,
    isDenyCreatePoint,
    loadMorePoints,
    changeStream,
    copyTemplateStream,
    getSavedStreams,
    getStream,
    onboardingPassedRequest,
    refetchFeaturesOnboarding,
    setCurrentPoint,
    readCurrentPoint,
    readStreamWithThematic,
    clearStreamWithThematic,
  } = useStreamBindModalPresenter();

  const {
    selectedPoints,
    disabledPoints,
    onPointChange,
    searchText,
    isStreamBindButtonDisabled,
    isStreamBindButtonLoading,
    onSearchChange,
    onInitialSearchChange,
    handleClick,
  } = useStreamBindModalController({
    points,
    targetStreamId,
    collectionId,
    isTemplateMode,
    pointFromLocation,
    onFilterExecute,
    changeStream,
    copyTemplateStream,
    getSavedStreams,
    getStream,
    onboardingPassedRequest,
    refetchFeaturesOnboarding,
    setCurrentPoint,
    readCurrentPoint,
    readStreamWithThematic,
    clearStreamWithThematic,
  });

  useEffect(
    () => () => {
      clearStreamWithThematic();
    },
    []
  );

  const { isIpad } = useMedia();

  useEffect(() => {
    if (pointFromLocation?.pointAddress && pointFromLocation?.pointAddress.length !== 0) {
      onInitialSearchChange(pointFromLocation?.pointAddress);
    }
  }, [pointFromLocation]);

  const handleCancel = () => {
    if (!pointFromLocation?.pointId) {
      onSearchChange('');
    }

    if (onConfirmClose) {
      onConfirmClose();
    } else {
      close();
    }
  };

  const handleAddPointClick = useCallback(() => {
    AnalyticsService.event(PointEventNameAMP.POINT_CREATE_BUTTON_CLICK, {
      [PointParamNameAMP.FROM_PAGE]: PointCreateModalPages.STREAM,
    });

    onNext?.({ selectedPoints: [], isDenyCreatePoint });
  }, [onNext, isDenyCreatePoint]);

  const actionButtons = useMemo(() => {
    const result: React.ReactNode[] = [];

    result.push(
      <Button
        key="button_bind_stream_modal"
        elementId="button_bind_stream_modal"
        type={ButtonType.PRIMARY}
        isLoading={isLoading || isStreamBindButtonLoading}
        onClick={async () => {
          await handleClick();
          onNext?.({ selectedPoints: points.filter((point) => selectedPoints.has(point.id)) });
        }}
        isDisabled={isStreamBindButtonDisabled}>
        {TEXTS.MODAL_BIND_BUTTON_SUBMIT_TEXT}
      </Button>
    );

    if (!pointFromLocation) {
      result.push(
        <Button
          key="button_create_new_point_modal"
          elementId="button_create_new_point_modal"
          type={ButtonType.SECONDARY}
          onClick={handleAddPointClick}>
          {TEXTS.MODAL_BIND_ADD_NEW_POINT_TEXT}
        </Button>
      );
    }

    return result;
  }, [isLoading, handleClick, onNext, points, isStreamBindButtonDisabled, handleAddPointClick, pointFromLocation]);

  return (
    <ModalScreen
      title={TEXTS.MODAL_BIND_TITLE}
      titlePlacement="left"
      disableScroll={!isIpad}
      contentClassName={styles.StreamBindModalContent}
      onClose={handleCancel}
      actionButtons={actionButtons}>
      {isCustomInfoShouldShow && userOnboardingFeaturesLoading && (
        <div className={styles.StreamBindModalHintSkeleton} />
      )}

      {isCustomInfoShouldShow && !userOnboardingFeaturesLoading && (
        <CustomInfo
          textObject={TEXTS.MODAL_INFO}
          link={HELP_CENTER_CONTRACTS_LINK}
        />
      )}

      <InputSearch
        containerClassName={styles.StreamBindModalPointListSearchInput}
        value={searchText}
        elementId="toolbar_input_search"
        onChange={onSearchChange}
        isLoading={isLoading || isStreamBindButtonLoading}
        placeholder={TEXTS.MODAL_BIND_SEARCH_PLACEHOLDER}
      />

      {!isEmpty && !isEmptyFiltered && (
        <SelectableList<PointShortTypeFragment>
          elementId="checkbox_item_bind"
          items={points}
          itemKeyForRender="address"
          itemContent={
            <PointItemContent
              className={styles.StreamBindModalPointListItemContent}
              showStreamData
              isLoading={isLoading || isStreamBindButtonLoading}
            />
          }
          labelPlacement={LabelPlacement.LEFT}
          itemsLoading={pointsLoading}
          isLoading={isLoading || isStreamBindButtonLoading}
          selectedItems={selectedPoints}
          disabledItems={disabledPoints}
          onChange={onPointChange}
          loadMore={loadMorePoints}
          paginationInfo={paginationInfo}
          totalItemsCount={allPointsCount || points.length}
          hasMultiselect={false}
          selectorRenderer={Checkbox}
        />
      )}

      {isEmpty && (
        <EmptyState>
          <span>{TEXTS.MODAL_BIND_POINTS_LIST_EMPTY}</span>
        </EmptyState>
      )}

      {isEmptyFiltered && (
        <EmptyState>
          <span>{TEXTS.MODAL_BIND_POINTS_FILTERED_LIST_EMPTY}</span>
        </EmptyState>
      )}
    </ModalScreen>
  );
};

export default withProviders(StreamBindModal, [PointModelProvider], { pageSize: 30 });
