export enum BusinessProfileEventNameAMP {
  BUSINESS_PROFILE_PAGE_VISIT = 'business profile page was visited',
  BUSINESS_PROFILE_CREATE_FORM_VISIT = 'business profile create form was visited',
  BUSINESS_PROFILE_CREATE = 'business profile was created',
  BUSINESS_PROFILE_REMOVE = 'business profile was removed',
  BUSINESS_PROFILE_HIDE = 'business profile was unpublished',
  BUSINESS_PROFILE_UPDATE = 'business profile was updated',
  BUSINESS_PROFILE_PLAYLIST_CREATE = 'business profile playlist was created',
  BUSINESS_PROFILE_PLAYLIST_CHOOSE = 'business profile playlist was chosen',
  BUSINESS_PROFILE_PLAYLIST_REMOVE = 'business profile playlist was removed',
  BUSINESS_PROFILE_PLAYLIST_UPDATE = 'business profile playlist was updated',
  BUSINESS_PROFILE_PLAYLIST_HIDE = 'business profile playlist was unpublished',
  BUSINESS_PROFILE_PLAYLIST_INSERT_LINK = 'business profile playlist link was inserted',
  BUSINESS_PROFILE_PLAYLIST_LINK_HELP_BUTTON_CLICK = 'business profile playlist link help button was clicked',
  BUSINESS_PROFILE_PLAYLIST_CREATE_BUTTON_CLICK = 'business profile playlist create button was clicked',
  BUSINESS_PROFILE_PLAYLIST_PUBLISH = 'business profile playlist was published',
  BUSINESS_PROFILE_PLAYLIST_LOAD_BUTTON_CLICK = 'business profile playlist load button was clicked',
  BUSINESS_PROFILE_PLAYLIST_SAVE_BUTTON_CLICK = 'business profile playlist save button was clicked',
  BUSINESS_PROFILE_PLAYLIST_CREATE_ERROR = 'business profile playlist creating got error',
}

export enum BusinessProfileParamNameAMP {
  PROFILES_COUNT = 'profiles_count',
  PROFILE_WITH = 'profile_with',
  PROFILE_UPDATE_FIELD = 'profile_update_field',
  PLAYLIST_FROM = 'playlist_from',
  PLAYLIST_WITH = 'playlist_with',
  PROFILE_CREATE_FORM_MODE = 'profile_create_form_mode',
}

export enum BusinessProfileWithParamValue {
  POSTER = 'poster',
  BANNER = 'banner',
  SITE = 'site',
}

export enum BusinessProfilePlaylistWithParamValue {
  POSTER = 'poster',
  DESCRIPTION = 'description',
}

export enum BusinessProfileFieldParamValue {
  POSTER = 'poster',
  DESCRIPTION = 'description',
}
