import React, { Suspense, useEffect } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { RouterUtils, matchPath, useNavigate, useLocation } from 'application/providers/RouterProvider';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export const COMPANY_PAGE_PATH = '/company';
export const SETTINGS_PAGE_PATH = '/settings';

const Renderer = React.lazy(() => import('./renderer'));

const CompanyPageWrapper: IApplicationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isNeedRedirect = !matchPath({ path: SETTINGS_PAGE_PATH, end: true }, location.pathname);

  useEffect(() => {
    if (isNeedRedirect) {
      navigate(RouterUtils.generatePath(SETTINGS_PAGE_PATH), { replace: true });
    }
  }, [isNeedRedirect]);

  return (
    <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
      {!isNeedRedirect && <Renderer />}
    </Suspense>
  );
};

CompanyPageWrapper.config = {
  routes: [
    {
      path: COMPANY_PAGE_PATH,
    },
    {
      path: SETTINGS_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default CompanyPageWrapper;
