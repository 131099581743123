export enum StreamBindFlowEventNameAMP {
  STREAM_BOUND = 'stream bound',
  STREAM_PLAYER_TYPE_BOUND = 'player type was bound',
  STREAM_RUN_CLICKED = 'stream run was clicked',
  STREAM_RUN_AT_CREATED_POINT_CLICKED = 'stream run at created point was clicked',
  STREAM_SELECT_PLAYER_CLICKED = 'player type was selected',
  STREAM_SELECT_PLAYER_RADIO_CLICKED = 'player type radio was clicked',
  STREAM_DESKTOP_DOWNLOAD_CLICKED = 'desktop app was downloaded',
  STREAM_MOBILE_DOWNLOAD_CLICKED = 'mobile app was downloaded',
  STREAM_REQUEST_DEVICE_CLICKED = 'device was requested',
  STREAM_BIND_SUCCESS_MODAL_SHOWED = 'stream bind success modal was showed',
}

export enum StreamBindFlowParamNameAMP {
  PLAYER_TYPE = 'player_type',
  DEVICE_TYPE = 'device_type',
  STREAM_BIND_MODAL_TYPE = 'stream_bind_modal_type',
  FROM_MOBILE = 'from_mobile',
  FIRST_DEVICE_REQUEST = 'is_first_request',
  FLOW_TYPE = 'flow_type',
  SUCCESS_MODAL_TYPE = 'success_modal_type',
}
