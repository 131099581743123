import { ApolloQueryResult, FetchResult, QueryResult } from '@apollo/client';

import {
  BaseMutationResultType,
  BaseQueryRefetchResultType,
  BaseQueryResultType,
  ErrorsMapType,
  FetchPolicyKeys,
} from 'domain/api/graphql/types';
import {
  PointPureType,
  AdPromoPureType,
  AdCampaignPureType,
  AdCampaignTypeEnum,
  StreamPureType,
  StreamPureDislikeMediafileMutationInput,
  StreamPureDislikeMediafileRestoreMutationInput,
  CoverFilePureType,
  PaginationInfoType,
  Maybe,
  AdCampaignQueryFilter,
  AdCampaignShortTypeFragment,
  AdCampaignsStatusesPureType,
  AdCampaignQueryPagination,
  AdCampaignQuerySort,
  MediaAdCampaignsPaginationQueryQuery,
  Exact,
  AdCampaignPureSetCompanyTagsMutationInput,
  MediaAdCampaignQuery,
  PromoAdInput,
  MediaPromoUploadMutation,
  PlayInstantAdcampaignMutationInput,
  AdCampaignPureCreateMutationInput,
  MediaAdCampaignsMetadataPaginationQueryQuery,
  AdCampaignMetadataTypeFragment,
  InputMaybe,
  AdCampaignStatuses,
  SourcefileType,
} from 'domain/api/graphql/generated';

/* Common Types */
export type AdCampaignsQueryState = {
  query?: string | null;
  companyIds: InputMaybe<string>[];
  companyTagIds: InputMaybe<string>[];
  statuses: InputMaybe<AdCampaignStatuses>[];
  pointId?: string | null;
  type: InputMaybe<AdCampaignTypeEnum>[];
  page: number;
  pageSize: number;
  sort: AdCampaignQuerySort;
  skip: boolean;
};

export enum WeekDaysValues {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export { AdCampaignTypeEnum };

export enum PlayingStatusCode {
  NO_CONNECTION = 404,
  PLAYER_DISABLED = 501,
  DOES_NOT_PLAY = 201,
  ON_PAUSE = 402,
  PLAYING = 200,
}

export type StreamType = {
  id: StreamPureType['id'];
  title: StreamPureType['title'];
  description: StreamPureType['description'];
  points: StreamPureType['points'];
};

/* useDislikeMediafiles Types */
export type DislikeMediafileInputType = Pick<StreamPureDislikeMediafileMutationInput, 'id' | 'mediafileId'>;

export type DislikeMediafileResultType = BaseQueryResultType;

export type UseDislikeMediafileType = [
  request: (input: DislikeMediafileInputType) => Promise<boolean | undefined>,
  result: DislikeMediafileResultType,
];

/* useDislikeMediafilesRestore Types */
export type DislikeMediafileRestoreInputType = Pick<
  StreamPureDislikeMediafileRestoreMutationInput,
  'id' | 'mediafileId'
>;

export type DislikeMediafileRestoreResultType = BaseQueryResultType;

export type UseDislikeMediafileRestoreType = [
  request: (input: DislikeMediafileRestoreInputType) => Promise<boolean | undefined>,
  result: DislikeMediafileRestoreResultType,
];

/* useStreamGroups Types */
export type StreamPreviewType = Pick<StreamPureType, 'id' | 'title' | 'description' | 'templateType'> & {
  master?: Maybe<StreamPureType>;
  cover?: Maybe<Pick<CoverFilePureType, 'id' | 'miniatureUri'>>;
};

export type StreamGroupsResultType = BaseQueryResultType & {
  data: {
    streams?: StreamPreviewType[];
    streamsTop?: StreamPreviewType[];
    streamsGenres?: StreamPreviewType[];
  };
  called: boolean;
};

export type UseStreamGroupsType = [
  request: (companyId: string, companyIds: string[], page?: number, pageSize?: number) => void,
  result: StreamGroupsResultType,
];

export type StreamsByBusinessSphereTypeResultType = BaseQueryResultType & {
  data: {
    streams: StreamPreviewType[];
    paginationInfo?: PaginationInfoType;
  };
};

export type UseStreamsByBusinessSphereType = [
  request: (page?: number, currentBusinessSphereId?: string) => Promise<void>,
  result: StreamsByBusinessSphereTypeResultType,
];

/* usePointInitCreate Types */
export type PointType = Pick<
  PointPureType,
  'id' | 'address' | 'typeName' | 'linkName' | 'deviceType' | 'device' | 'playingStatus' | 'stream'
>;

/* useAdCampaigns Types */
export type AdCampaignPreviewType = Pick<
  AdCampaignPureType,
  'id' | 'title' | 'timingSettings' | 'enable' | 'tags' | 'type'
> & {
  points: { id: string }[];
  promos: { id: string }[];
};

export type UseAdCampaignsMetadataResultType = BaseQueryRefetchResultType & {
  called: boolean;
  data: {
    adCampaigns?: AdCampaignMetadataTypeFragment[] | null;
    paginationInfo?: PaginationInfoType | null;
  };
  loadMore: (
    filters: AdCampaignQueryFilter,
    pagination: AdCampaignQueryPagination,
    sort: AdCampaignQuerySort
  ) => Promise<ApolloQueryResult<MediaAdCampaignsMetadataPaginationQueryQuery>>;
};

export type UseAdCampaignsResultType = BaseQueryRefetchResultType & {
  called: boolean;
  data: {
    adCampaigns?: AdCampaignShortTypeFragment[] | null;
    statusesInfo?: AdCampaignsStatusesPureType | null;
    paginationInfo?: PaginationInfoType | null;
  };
  loadMore: (
    filters: AdCampaignQueryFilter,
    pagination: AdCampaignQueryPagination,
    sort: AdCampaignQuerySort
  ) => Promise<ApolloQueryResult<MediaAdCampaignsPaginationQueryQuery>>;
};

export type UseAdCampaignsType = [
  request: (filters: AdCampaignQueryFilter, pagination: AdCampaignQueryPagination, sort: AdCampaignQuerySort) => void,
  result: UseAdCampaignsResultType,
];

export type UseAdCampaignsMetadataType = [
  request: (
    filters: AdCampaignQueryFilter,
    pagination: AdCampaignQueryPagination,
    sort: AdCampaignQuerySort,
    fetchPolicy?: FetchPolicyKeys
  ) => Promise<
    QueryResult<
      MediaAdCampaignsMetadataPaginationQueryQuery,
      Exact<{
        filters: AdCampaignQueryFilter;
        sort: AdCampaignQuerySort;
        pagination: AdCampaignQueryPagination;
      }>
    >
  >,
  result: UseAdCampaignsMetadataResultType,
];

/* useAdCampaign Types */
export type AdPromoType = AdPromoPureType;

export type AdCampaignMetadataType = AdCampaignMetadataTypeFragment;

export type AdCampaignType = AdCampaignPureType;

export type UseAdCampaignResultType = BaseQueryResultType & {
  refetch: (
    variables?:
      | Partial<
          Exact<{
            adCampaignId: string;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<MediaAdCampaignQuery>>;
  data: {
    adCampaign?: AdCampaignPureType;
  };
};

export type UseAdCampaignType = [
  request: (adCampaignId: string) => Promise<
    QueryResult<
      MediaAdCampaignQuery,
      Exact<{
        adCampaignId: string;
      }>
    >
  >,
  result: UseAdCampaignResultType,
];

/* useAdCampaignCreate Types */
export type UseAdCampaignCreateResultType = BaseMutationResultType & {
  data: {
    adCampaign?: AdCampaignType;
  };
};

export type UseAdCampaignCreateType = [
  request: (
    name: string,
    promoIds: string[],
    companyId: string,
    overrideValues?: Partial<AdCampaignPureCreateMutationInput>
  ) => Promise<AdCampaignType>,
  result: UseAdCampaignCreateResultType,
];

/* usePromoUpload Types */
export type PromoUploadStatusType = {
  file?: File;
  time?: string;
  id?: string;
  promoId?: string;
  promoAdId?: string;
  title: string;
  filename?: string;
  isLoading: boolean;
  error?: Error;
  duration?: number;
  performerTitle?: string;
  sourcefiles: Partial<SourcefileType>[];
};

export type UsePromoUploadResultType = BaseMutationResultType;

export type UsePromoUploadType = {
  result: UsePromoUploadResultType;
  uploadPromo: (file: File) => Promise<FetchResult<MediaPromoUploadMutation>>;
};

export type UseAdCampaignPromoUploadType = [
  request: (adCompanyId: string, adPromos: PromoAdInput[]) => Promise<boolean>,
  result: UsePromoUploadResultType,
];

export type UseAdCampaignUpdateResultType = BaseMutationResultType & {
  data: {
    adCampaign?: AdCampaignType;
  };
};

export type UseAdCampaignUpdateType = [
  request: (campaign: AdCampaignType) => Promise<boolean>,
  result: UseAdCampaignUpdateResultType,
];

/* useAdCampaignEnable Types */
export type UseAdCampaignEnableResultType = BaseMutationResultType & {
  data: {
    adCampaign?: AdCampaignType;
  };
};

export type UseAdCampaignEnableType = [
  request: (adCompanyId: string, enable: boolean) => Promise<boolean>,
  result: UseAdCampaignEnableResultType,
];

/* useAdCampaignRemove Types */
export type UseAdCampaignRemoveResultType = BaseMutationResultType;

export type UseAdCampaignRemoveType = [
  request: (adCompanyId: string) => Promise<boolean>,
  result: UseAdCampaignRemoveResultType,
];

/* useAdCampaignReportCreate Types */
export type UseAdCampaignReportCreateResultType = BaseMutationResultType & {
  data: {
    ok?: boolean | null;
    errors?: ErrorsMapType;
  };
};

export type UseAdCampaignReportCreateType = [
  request: (
    adCampaignIds: string[],
    periodStart: string,
    periodEnd: string,
    recipientEmail?: string
  ) => Promise<boolean>,
  result: UseAdCampaignReportCreateResultType,
];

/* useAdCampaignSetTags Types */
export type AdCampaignSetTagResultType = BaseMutationResultType;

export type UseAdCampaignSetTagsType = [
  request: (input: AdCampaignPureSetCompanyTagsMutationInput) => Promise<boolean>,
  result: AdCampaignSetTagResultType,
];

/* useAdCampaignInstantLaunch Types */
export type UseAdCampaignInstantLaunchResultType = BaseMutationResultType;

export type UseAdCampaignInstantLaunchType = {
  request: (input: PlayInstantAdcampaignMutationInput) => Promise<boolean>;
  result: UseAdCampaignInstantLaunchResultType;
  abortRequest: () => void;
};
