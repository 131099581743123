import { KEYS, useInjection, UseBusinessProfileRepositoryType } from 'application/providers/DIContainerProvider';
import { BusinessProfileFormData } from 'application/pages/BusinessProfilePage/renderer/BusinessProfileForm/types';
import { useAccountContext } from 'application/providers/AccountProvider';
import FileUtils from 'application/utils/FileUtils';

import { UserCompanyPureType, ZvukProfileCreateMutationInput } from 'domain/api/graphql/generated';

const getBusinessProfileInput = (
  currentCompany: UserCompanyPureType,
  formData: BusinessProfileFormData
): ZvukProfileCreateMutationInput => ({
  companyId: currentCompany!.id,
  companyName: formData.companyName,
  description: formData.description,
  site: formData.site,
  poster: formData.poster?.files[0],
  avatar: formData.avatar?.files[0],
  bannerLink: formData.bannerLink,
  bannerWeb: formData.bannerWeb?.files[0],
  bannerMobile: formData.bannerMobile?.files[0],
});

const useBusinessProfileUpsertModalPresenter = () => {
  const useBusinessProfileRepository = useInjection<UseBusinessProfileRepositoryType>(KEYS.BUSINESS_PROFILE_REPOSITORY);

  const {
    getBusinessProfile,
    createBusinessProfile,
    businessProfileCreateLoading,
    businessProfileCreateError,
    businessProfileCreateData,
    updateBusinessProfile,
    businessProfileUpdateLoading,
    businessProfileUpdateError,
    businessProfileUpdateData,
  } = useBusinessProfileRepository();

  const { currentCompany } = useAccountContext();

  const getBusinessProfileInitialFormData = async (
    targetBusinessProfileId: string
  ): Promise<BusinessProfileFormData> => {
    const data = getBusinessProfile(targetBusinessProfileId);

    const posterFiles = [];
    const avatarFiles = [];
    const bannerWebFiles = [];
    const bannerMobileFiles = [];

    if (data && data.poster) {
      const posterFile = await FileUtils.getFileFromFileUrl(data.poster, 'poster.png');
      posterFiles.push(posterFile);
    }

    if (data && data.avatar) {
      const avatarFile = await FileUtils.getFileFromFileUrl(data.avatar, 'avatar.png');
      avatarFiles.push(avatarFile);
    }

    if (data && data.bannerWeb) {
      const bannerWebFile = await FileUtils.getFileFromFileUrl(data.bannerWeb, 'bannerWeb.png');
      bannerWebFiles.push(bannerWebFile);
    }

    if (data && data.bannerMobile) {
      const bannerMobileFile = await FileUtils.getFileFromFileUrl(data.bannerMobile, 'bannerMobile.png');
      bannerMobileFiles.push(bannerMobileFile);
    }

    const defaultFormData: BusinessProfileFormData = {
      companyName: data?.companyName || '',
      description: data?.description || '',
      site: data?.site || '',
      poster: {
        files: posterFiles,
        fileRejections: [],
      },
      avatar: {
        files: avatarFiles,
        fileRejections: [],
      },
      bannerLink: data?.bannerLink || '',
      bannerWeb: {
        files: bannerWebFiles,
        fileRejections: [],
      },
      bannerMobile: {
        files: bannerMobileFiles,
        fileRejections: [],
      },
      acceptedPrivatePolicy: true,
    };

    return defaultFormData;
  };

  const onCreateBusinessProfile = async (formData: BusinessProfileFormData) => {
    const input = getBusinessProfileInput(currentCompany!, formData);

    const result = await createBusinessProfile(input);

    return result;
  };

  const onUpdateBusinessProfile = async (profileId: string, formData: BusinessProfileFormData) => {
    const input = getBusinessProfileInput(currentCompany!, formData);

    const result = await updateBusinessProfile({
      profileId,
      ...input,
    });

    return result;
  };

  return {
    getBusinessProfileInitialFormData,
    onCreateBusinessProfile,
    businessProfileCreateLoading,
    businessProfileCreateError,
    businessProfileCreateData,
    onUpdateBusinessProfile,
    businessProfileUpdateLoading,
    businessProfileUpdateError,
    businessProfileUpdateData,
  };
};

export default useBusinessProfileUpsertModalPresenter;
