import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';

export const MONTHES = (month: number) =>
  [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ][month];

export const DEFAULT_WORD_FOR_ENDING = 'адрес';
export const WORDS_WITH_OV_ENDING = ['адрес', 'трек'];
export const WORDS_WITH_EV_ENDING = ['месяц'];

const POINTS_ENDING = {
  адресу: [1],
  адресам: [2, 3, 4, 5, 6, 7, 8, 9, 0],
};

const POINTS_PLURAL_ENDINGS = ['точка', 'точки', 'точек'];

class DeclensionUtils {
  static getFormattedDate(dateStr: string, loc: (m: number) => string) {
    const date = new Date(dateStr);

    const day = DateUtils.getFormatDateFrom(date, 'd');
    const month = Number(DateUtils.getFormatDateFrom(date, 'M')) - 1;

    return `${day} ${loc(month)}`;
  }

  static getFormattedFullDate(dateStr: string, loc: (m: number) => string) {
    const date = new Date(dateStr);

    DeclensionUtils.getFormattedDate(dateStr, loc);

    return `${DeclensionUtils.getFormattedDate(dateStr, loc)} ${date.getFullYear()}`;
  }

  static getFormattedDay(day: number) {
    if (day === 1) {
      return '1 день';
    }

    if ([2, 3, 4].includes(day)) {
      return `${day} дня`;
    }

    return `${day} дней`;
  }

  static pluralEnding(amount: number, initialWord = DEFAULT_WORD_FOR_ENDING): string {
    if (amount % 10 === 1 && amount % 100 !== 11) {
      return '';
    }

    if ([2, 3, 4].includes(amount % 10) && ![12, 13, 14].includes(amount % 100)) {
      return 'а';
    }

    if (WORDS_WITH_EV_ENDING.includes(initialWord)) {
      return 'ев';
    }

    return 'ов';
  }

  static pluralFromEnding(amount: number): string {
    if (amount % 10 === 1 && amount % 100 !== 11) {
      return 'a';
    }

    return 'ов';
  }

  static pointsEnding(pointsCounts: number): string {
    const lastNumber = pointsCounts % 10;

    for (const [key, value] of Object.entries(POINTS_ENDING)) {
      if (value.includes(lastNumber)) {
        return key;
      }
    }

    return '';
  }

  static pluralPointsEnding(pointsCount: number): string {
    const [one, two, five] = POINTS_PLURAL_ENDINGS;
    const n = Math.abs(pointsCount) % 100;
    const lastDigit = n % 10;

    let result = five;

    if (!(n >= 5 && n <= 20)) {
      if (lastDigit === 1) {
        result = one;
      } else if (lastDigit >= 2 && lastDigit <= 4) {
        result = two;
      }
    }

    return result;
  }

  static wordMorphByNumber = (int: number, words: string[]) => {
    const firstWordNumber = 0;
    const secondWordNumber = 1;
    const thirdWordNumber = 2;

    return words[
      int % 100 > 4 && int % 100 < 20
        ? 2
        : [thirdWordNumber, firstWordNumber, secondWordNumber, secondWordNumber, secondWordNumber, thirdWordNumber][
            int % 10 < 5 ? int % 10 : 5
          ]
    ];
  };
}

export default DeclensionUtils;
