import React, { Suspense } from 'react';

import PAGES_CONST from 'application/pages/constants';
import { IApplicationPage } from 'application/pages/types';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import PlayerProvider from 'application/providers/PlayerProvider';
import PointModelProvider from 'application/pages/PointsPage/providers/PointModelProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

import AdCampaignModelProvider from './providers/AdCampaignModelProvider';

export const ADS_PAGE_PATH = '/promo/:id?';
export const ADS_PAGE_PATH_ALIAS = '/promo';

export enum IdParamValues {
  ALL = 'all',
}

export type AdsPageParamsType = {
  id?: string | IdParamValues;
};

const Renderer = React.lazy(() => import('./renderer'));

const AdsPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <PlayerProvider>
      <AdCampaignModelProvider>
        <PointModelProvider>
          <Renderer />
        </PointModelProvider>
      </AdCampaignModelProvider>
    </PlayerProvider>
  </Suspense>
);

AdsPageWrapper.config = {
  routes: [
    {
      path: ADS_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default AdsPageWrapper;
