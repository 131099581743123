import { KeyboardEventCode } from './types';

export type CallbackType<T, S> = (event: T & { code: string }, param?: S) => void;
export type UseKeyDownCallbackType<T, S> = CallbackType<T, S>;

function useKeyDownCallback<T, S = any>(
  callback: CallbackType<T, S>,
  options?: {
    include?: KeyboardEventCode[];
    exclude?: KeyboardEventCode[];
  }
): UseKeyDownCallbackType<T, S> {
  return (event, param?: S) => {
    if (
      options?.include?.includes?.(event.code as KeyboardEventCode) &&
      !options?.exclude?.includes?.(event.code as KeyboardEventCode)
    ) {
      callback(event, param);
    }
  };
}

export default useKeyDownCallback;
