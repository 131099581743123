import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import useDisableBodyScroll from 'toolkit/useDisableBodyScroll';
import IconButton from 'ui/IconButton';
import IconX from 'ui/icons/X';

import styles from './index.module.scss';

export enum ModalSize {
  SMALL,
  MEDIUM,
  LARGE,
  FULL_HEIGHT_LARGE,
}

export type ModalScreenProps = React.PropsWithChildren<{
  title?: string;
  titlePlacement?: 'left' | 'center';
  titleClassName?: string;
  actionsPlacement?: 'left' | 'center' | 'right';
  titleIcon?: React.ReactNode;
  className?: string;
  disableScroll?: boolean;
  containerClassName?: string;
  contentClassName?: string;
  hideCloseButton?: boolean;
  actionButtons?: React.ReactNode[];
  cornerText?: string;
  onClose?: () => void;
  modalSize?: ModalSize;
  containerActionButtonsClassName?: string;
  withBackground?: boolean;
  elementId?: string;
}>;

const ModalScreen = ({
  title,
  titlePlacement = 'left',
  titleClassName,
  actionsPlacement,
  titleIcon,
  children,
  className,
  disableScroll = true,
  containerClassName,
  contentClassName,
  actionButtons = [],
  cornerText,
  hideCloseButton = false,
  onClose,
  modalSize = ModalSize.SMALL,
  containerActionButtonsClassName,
  withBackground = false,
  elementId = 'modal_screen',
}: ModalScreenProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const bodyScroll = useDisableBodyScroll();

  useEffect(() => {
    if (disableScroll) {
      bodyScroll.disableBodyScroll(modalContentRef.current!);
    } else {
      bodyScroll.enableBodyScroll(modalContentRef.current!);
    }

    return () => {
      bodyScroll.enableBodyScroll(modalContentRef.current!);
    };
  }, [disableScroll]);

  return (
    <>
      {withBackground && <div className={styles.ModalScreenBackground} />}
      <div
        data-testid={elementId}
        className={classNames(styles.ModalScreen, className)}
        ref={modalRef}>
        <section
          role="dialog"
          className={classNames(
            styles.ModalScreenContainer,
            containerClassName,
            modalSize === ModalSize.MEDIUM && styles.ModalScreenContainerMedium,
            modalSize === ModalSize.LARGE && styles.ModalScreenContainerLarge,
            modalSize === ModalSize.FULL_HEIGHT_LARGE && styles.ModalScreenContainerFullHeightLarge
          )}>
          {cornerText && <div className={styles.ModalScreenContainerCorner}>{cornerText}</div>}

          {!hideCloseButton && (
            <IconButton
              elementId="button_modal_screen_close"
              className={styles.ModalScreenContainerCloseButton}
              onClick={onClose}>
              <IconX />
            </IconButton>
          )}

          {titleIcon && <div className={styles.ModalScreenContainerIconContainer}>{titleIcon}</div>}

          {title && (
            <h3
              className={classNames(styles.ModalScreenContainerTitle, titleClassName)}
              data-placement={titlePlacement}>
              {title}
            </h3>
          )}

          {children && (
            <div
              className={classNames(
                styles.ModalScreenContainerContent,
                contentClassName,
                modalSize === ModalSize.FULL_HEIGHT_LARGE && styles.ModalScreenContainerContentFullHeightLarge
              )}
              ref={modalContentRef}>
              {children}
            </div>
          )}

          <div
            className={classNames(styles.ModalScreenContainerActionButtons, containerActionButtonsClassName)}
            data-placement={actionsPlacement || titlePlacement}>
            {actionButtons}
          </div>
        </section>
      </div>
    </>
  );
};

export default ModalScreen;
