export const TEXTS = {
  BANNER_GRACE_TEXT:
    'Деньги за подписку не списались. Вещание остановится в ближайшее время. Проверьте состояние карты.',
  BANNER_UNPAID_TEXT: 'Вещание на адресах остановлено. Чтобы возобновить вещание, оплатите подписку.',
  BANNER_TRIAL_TEXT: 'Оплатите, чтобы вещание продолжалось. До конца тестового периода осталось',
  BANNER_TRIAL_KZ_MESSAGE_TEXT: 'Прошу выставить счет по завершению тестового периода',

  BUTTON_GO_TO_BILLING_VARIANT_TITLE: 'Оплатить подписку',
  BUTTON_GO_TO_BILLING_VARIANT_TITLE_TRIAL: 'Оплатить',
};
