import React, { Suspense, useEffect } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import CompanyUtils from 'application/utils/CompanyUtils';
import { useAuthContext, LocalAccessRoles } from 'application/providers/AuthProvider';
import { STREAMS_CATEGORY_PATH, StreamsCategoryParamValues } from 'application/pages/StreamPage';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import { CompanyContractKind } from 'domain/api/graphql/generated';

import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export const CONTRACT_MIGRATION_PAGE_PATH = '/accept-oferta';

const Renderer = React.lazy(() => import('./renderer'));

const ContractMigrationPageWrapper: IApplicationPage = () => {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  const companyWithActiveContractMigration = CompanyUtils.getCompanyWithActiveContractMigration(
    authContext.userData?.companies || [],
    CompanyContractKind.Offerta
  );

  const hasActiveContractMigration =
    !!companyWithActiveContractMigration &&
    !!CompanyUtils.getActiveContractMigration(companyWithActiveContractMigration, CompanyContractKind.Offerta);

  useEffect(() => {
    if (!hasActiveContractMigration) {
      navigate(
        RouterUtils.generatePath(STREAMS_CATEGORY_PATH, {
          category: StreamsCategoryParamValues.RECOMMENDED,
        }),
        {
          replace: true,
        }
      );
    }
  }, [hasActiveContractMigration]);

  return (
    <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
      <Renderer />
    </Suspense>
  );
};

ContractMigrationPageWrapper.config = {
  routes: [{ path: CONTRACT_MIGRATION_PAGE_PATH }],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default ContractMigrationPageWrapper;
