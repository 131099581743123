export enum Colors {
  COLOR_WHITE = '#FFF',
  COLOR_ACCENT_500 = 'hsl(171, 100%, 35%)',
  COLOR_SUCCESS_400 = 'hsl(144, 80%, 60%)',
  COLOR_SUCCESS_700 = 'hsl(144, 80%, 30%)',
  COLOR_SUCCESS_500 = 'hsl(144, 80%, 50%)',
  COLOR_NEUTRAL_300 = 'hsl(228, 20%, 70%)',
  COLOR_NEUTRAL_400 = 'hsl(228, 20%, 60%)',
  COLOR_NEUTRAL_500 = 'hsl(228, 20%, 50%)',
  COLOR_NEUTRAL_600 = 'hsl(228, 20%, 40%)',
  COLOR_NEUTRAL_700 = 'hsl(228, 20%, 30%)',
  COLOR_NEUTRAL_800 = 'hsl(228, 20%, 20%)',
  COLOR_DANGER_400 = 'hsl(336, 100%, 60%)',
  COLOR_DANGER_500 = 'hsl(336, 100%, 50%)',
  COLOR_DANGER_700 = 'hsl(336, 100%, 30%)',
  COLOR_WARNING_400 = 'hsl(45, 100%, 60%)',
  COLOR_WARNING_500 = 'hsl(45, 100%, 50%)',
  COLOR_WARNING_600 = 'hsl(45, 100%, 40%)',
  COLOR_WARNING_700 = 'hsl(45, 100%, 30%)',
}
