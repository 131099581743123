import DateUtils from './DateUtils';

class ToolkitUtils {
  static sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static replaceSymbols = (str: string, symbols: string[], target: string) =>
    symbols.reduce((acc, symbol) => acc.replace(new RegExp(symbol, 'g'), target), str);

  static getWidthWithoutBorder = (item: HTMLElement): number => {
    const styles = window.getComputedStyle(item);
    return item.getBoundingClientRect().width - parseFloat(styles.borderWidth) * 2;
  };

  static getWidth = (item: HTMLElement): number => item?.getBoundingClientRect().width;

  static range = (startAt: number, endAt: number = DateUtils.NOW.getFullYear()) => {
    const arr = [];
    let index = 0;

    while (index + startAt <= endAt) {
      arr.push(startAt + index);
      index += 1;
    }

    return arr;
  };

  static getRandomString(length: number) {
    return (Math.random() + 1).toString(36).substring(length);
  }

  static toCamesCase(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
      .replace(/\s+/g, '');
  }

  static kebabToCamelCase(str: string) {
    return str.replace(/-./g, (x) => x[1].toUpperCase());
  }

  static capitalizedFirstLetter(word: string): string {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  }

  static replaceSpecialSymbols = (str: string, regExpSymbol: RegExp, symbol: string) =>
    str.replace(regExpSymbol, symbol);

  static camelCaseToUpperSnakeCase = (str: string) =>
    str
      .split(/\.?(?=[A-Z])/)
      .join('_')
      .toUpperCase();
}

export default ToolkitUtils;
