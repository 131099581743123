import React from 'react';
import classNames from 'classnames';

import { Link as ReactRouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import styles from './index.module.scss';

interface LinkProps extends RouterLinkProps {
  testId?: string;
}

const Link = (props: LinkProps) => {
  const { to, id, className, children, onClick, replace, testId, state } = props;

  return (
    <ReactRouterLink
      className={classNames(styles.Link, className)}
      to={to}
      state={state}
      id={id}
      replace={replace}
      onClick={onClick}
      data-testid={testId}>
      {children}
    </ReactRouterLink>
  );
};

export default Link;
