import { useState } from 'react';

import usePromoState from 'application/hooks/usePromoState';
import { ADS_PAGE_PATH } from 'application/pages/AdsPage';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useNotificationContext } from 'application/providers/NotificationProvider';
import { usePlayerContext } from 'application/providers/PlayerProvider';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import AnalyticsService, { EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';

import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import { MediaUploaderItemType, MediaUploaderItemValue } from '@zvuk-b2b/react-uikit/ui/MediaUploader';

import FileUtils from 'application/utils/FileUtils';
import { NAME_MAX_LENGTH_ERROR, NOTIFICATIONS } from '../consts';
import { UseAdCreateModalControllerProps } from './types';

const useAdCreateModalController = ({
  close,
  onCreate,
  createAdCampaign,
  clearAdCampaigns,
}: UseAdCreateModalControllerProps) => {
  const { currentCompany } = useAccountContext();
  const { isDesktop } = useMedia();
  const navigate = useNavigate();
  const notification = useNotificationContext();
  const playerContext = usePlayerContext();

  const { data, upload, remove, retry, clear } = usePromoState();

  const [adName, setAdName] = useState('');

  const createModalItems = data.statuses.map<MediaUploaderItemType>((item) => ({
    label: item.file?.name || item.title || '',
    isLoading: item.isLoading,
    error: item.error,
    value: {
      id: item.id || '',
      filename: item.filename || '',
      url: FileUtils.getMediafileUrl(item.sourcefiles),
    },
  }));

  const isAnyItemLoading = createModalItems.some((item) => item.isLoading);

  const onFilesSelected = (files: File[]) => {
    upload(files);
  };

  const onRemoveFile = (value: MediaUploaderItemValue) => {
    remove(value.id);
  };

  const onRetryFile = (value: MediaUploaderItemValue) => {
    retry(value.id);
  };

  const onPlayClick = (value: MediaUploaderItemValue) => {
    playerContext.play({ fileUuid: value.id, fileName: value.filename, fileUrl: value.url });
  };

  const onStopClick = (_: MediaUploaderItemValue) => {
    playerContext.stop();
  };

  const onRemoveClick = (value: MediaUploaderItemValue) => {
    if (playerContext.fileName === value.filename) {
      playerContext.stop();
    }

    onRemoveFile(value);
  };

  const onAdCampaignCreateClick = async (name: string, promoIds: string[]) => {
    try {
      const { id, type } = await createAdCampaign(name, promoIds, currentCompany!.id);

      if (id) {
        notification.showNotification(NOTIFICATIONS.CREATE_SUCCESS);
        AnalyticsService.event(EventNameAMP.AD_CAMPAIGN_CREATED, {
          [ParamNameAMP.AD_CAMPAIGN_ID]: id,
          [ParamNameAMP.AD_CAMPAIGN_TYPE]: type,
        });

        if (isDesktop) {
          navigate(RouterUtils.generatePath(ADS_PAGE_PATH, { id }));
        }

        clearAdCampaigns();
      } else {
        throw Error();
      }
    } catch (error: any) {
      if (JSON.stringify(error).includes(NAME_MAX_LENGTH_ERROR)) {
        notification.showNotification(NOTIFICATIONS.MAX_LENGTH_ERROR);
      } else {
        notification.showNotification(NOTIFICATIONS.ERROR);
      }
    }

    clear();
    close();
  };

  const onSubmitClick = () => {
    playerContext.stop();
    const promoIds = data.statuses.map((item) => item.promoId!).filter(Boolean) || [];

    if (onCreate) {
      onCreate(adName, data.statuses);
      close();
    } else {
      onAdCampaignCreateClick(adName, promoIds);
    }
  };

  const onCancelClick = () => {
    playerContext.stop();
    close();
  };

  return {
    items: createModalItems,
    playerContext,
    adName,
    setAdName,
    isAnyItemLoading,
    onFilesSelected,
    onRetryFile,
    onSubmitClick,
    onCancelClick,
    onPlayClick,
    onStopClick,
    onRemoveClick,
  };
};

export default useAdCreateModalController;
