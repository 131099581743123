/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { PaginationInfoType } from 'domain/api/graphql/generated';
import InView from 'react-intersection-observer';
import ChooseItemListItem from './ChooseMediffilesListItem';
import styles from './index.module.scss';
import VideoStreamPreviewItem from './VideoMediafilePreviewItem';
import { IVideoMediafile } from '../types';

export type ChooseItemListType = {
  onChange: (value: any, currentItem: IVideoMediafile) => void;
  itemList?: Array<IVideoMediafile>;
  selectedItems: Array<IVideoMediafile>;
  isLoading: boolean;
  isReloading?: boolean;
  paginationSettings?: PaginationInfoType;
  loadMore?: () => void;
  loadedItemsCount?: number;
  emptySearchText?: string;
};

const SelectVideoMediafilesList = ({
  itemList,
  onChange,
  isLoading,
  selectedItems,
  loadMore,
  paginationSettings,
  isReloading,
  loadedItemsCount = 0,
  emptySearchText,
}: ChooseItemListType) => {
  const totalCount = paginationSettings?.totalCount || 0;
  const [selectedSet, setSelectedSet] = useState(new Set(selectedItems.map((item) => item.id)));

  useEffect(() => {
    setSelectedSet(new Set(selectedItems?.map((item) => item.id)));
  }, [selectedItems]);

  const skeletonCount = totalCount - loadedItemsCount;

  const skeletonItems = useMemo(() => (skeletonCount > 0 ? new Array(skeletonCount).fill(null) : []), [skeletonCount]);

  return (
    <>
      {itemList?.map((currentItem: IVideoMediafile, index: number) => (
        <ChooseItemListItem
          key={`${index}_${currentItem.id}`}
          isChecked={selectedSet.has(currentItem.id)}
          currentItem={currentItem}
          onChange={(value: boolean) => onChange(value, currentItem)}
          isLoading={isLoading || isReloading}
          isDisabled={isReloading || isLoading || currentItem.uploading}>
          <VideoStreamPreviewItem file={currentItem} />
        </ChooseItemListItem>
      ))}

      {paginationSettings?.hasNextPage && (
        <InView
          onChange={(inView) => {
            if (!inView) return;
            loadMore?.();
          }}
          key="infinite_medialist_hidden_loading_zone"
        />
      )}

      {itemList?.length === 0 && !isLoading && <div className={styles.SearchItemsEmpty}>{emptySearchText}</div>}

      {skeletonItems?.map(() => (
        <ChooseItemListItem
          isChecked={false}
          onChange={() => {}}
          isDisabled>
          <VideoStreamPreviewItem />
        </ChooseItemListItem>
      ))}
    </>
  );
};

export default SelectVideoMediafilesList;
