import React from 'react';
import { FieldError } from 'react-hook-form';

import styles from './index.module.scss';

export type DescriptionProps = {
  description: React.ReactNode;
  fieldError?: FieldError;
  externalErrors?: string[];
  elementId: string;
};

const Description = ({ description, fieldError, externalErrors, elementId }: DescriptionProps) => {
  if (fieldError) {
    return (
      <div className={styles.DescriptionContainer}>
        <div>{description}</div>
        <div className={styles.DescriptionError}>{fieldError.message}</div>
      </div>
    );
  }

  if (externalErrors && externalErrors.length !== 0) {
    return (
      <div className={styles.DescriptionContainer}>
        <div>{description}</div>
        {externalErrors.map((error) => (
          <div
            key={error}
            data-testid={`${elementId}_error`}
            className={styles.DescriptionError}>
            {error}
          </div>
        ))}
      </div>
    );
  }

  return <div className={styles.DescriptionDefault}>{description}</div>;
};

export default Description;
