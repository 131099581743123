import { useState, useEffect, useMemo } from 'react';

import { KEYS, UseBillingRepositoryType, useInjection } from 'application/providers/DIContainerProvider';

import { useAccountContext } from 'application/providers/AccountProvider';
import { TOP_BANNER_TYPE_CLOSED } from 'application/consts';
import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import { Country } from 'domain/api/graphql/generated';

export enum BANNER_TYPE {
  GRACE = 'GRACE',
  UNPAID = 'UNPAID',
  TRIAL = 'TRIAL',
}

export type TopBannerState = {
  type: BANNER_TYPE;
  trialDaysLeft?: number;
};

export const resetTopBannerTypeClosed = () => localStorage.removeItem(TOP_BANNER_TYPE_CLOSED);

function useTopBannerState() {
  const useBillingRepository = useInjection<UseBillingRepositoryType>(KEYS.BILLING_REPOSITORY);

  const [hiddenTopBannerState, setHiddenTopBannerState] = useState(
    localStorage.getItem(TOP_BANNER_TYPE_CLOSED) as BANNER_TYPE | undefined
  );

  const { currentUser, currentCompany } = useAccountContext();

  const { getBillingInfo, watchBillingInfo } = useBillingRepository();

  const billingInfo = watchBillingInfo(currentCompany?.id || '');

  const trialPeriod = currentCompany?.trialPeriod;

  const trialDaysLeft = useMemo(
    () => DateUtils.getDaysLeft(trialPeriod?.lengthMinutes, trialPeriod?.lengthDays, trialPeriod?.startedAt),
    [trialPeriod?.lengthMinutes, trialPeriod?.lengthDays, trialPeriod?.startedAt]
  );

  const isBillingEnabled = !!currentCompany?.isBillingEnabled;

  const isGraceBanner = isBillingEnabled && billingInfo.nextBillingDate && billingInfo.hasGraceLicenses;

  const isUnpaidBanner = isBillingEnabled && billingInfo.hasInactiveLicenses;

  const isTrialBannerKz =
    currentCompany?.country === Country.Kz &&
    currentCompany?.trialPeriod?.isFinished === false &&
    currentCompany?.trialPeriod?.isExpired === false;
  const isTrialBanner = billingInfo.hasTrialLicenses;

  useEffect(() => {
    if (currentUser?.id && !billingInfo.status) {
      getBillingInfo(currentCompany?.id!);
    }
  }, [currentUser?.id]);

  let banner: TopBannerState | undefined;

  if (currentCompany) {
    if (isTrialBanner || isTrialBannerKz) {
      banner = {
        type: BANNER_TYPE.TRIAL,
        trialDaysLeft,
      };
    }

    if (isGraceBanner) {
      banner = {
        type: BANNER_TYPE.GRACE,
      };
    }

    if (isUnpaidBanner) {
      banner = {
        type: BANNER_TYPE.UNPAID,
      };
    }
  }

  return {
    banner,
    isVisible: !!banner && banner?.type !== hiddenTopBannerState,
    onClose: () => {
      setHiddenTopBannerState(banner?.type);
      if (banner?.type) {
        localStorage.setItem(TOP_BANNER_TYPE_CLOSED, banner?.type);
      } else {
        resetTopBannerTypeClosed();
      }
    },
    country: currentCompany?.country || Country.Ru,
  };
}

export default useTopBannerState;
