import React, { useState, FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import getCertificateActionButtons from './CertificateActionButtons';
import DisabledPointHint from './DisabledPointHint';
import { TEXTS } from './texts';
import PointsListCheckboxGroup from '../../StreamGroupsDrawer/StreamDrawerModals/StreamBindModal/PointsListCheckboxGroup';
import useCertificateController from './useCertificateController';

import styles from './index.module.scss';
import useCertificatePresenter from './useCertificatePresenter';
import CertificateModalHint from './CertificateModalHint';
import CertificateNoAvailableHint from './CertificateNoAvailableHint';

export const POINTS_MENU_PATH = '/points/all';

export const BILLING_PAGE_PATH = '/billing/:state';
export const PRICE_PLAN_PAGE_PATH = '/price-plan';

export type OnCertSubmitType = {
  pointIds?: string[];
  createForAll?: boolean;
};

export type CertificateModalProps = {
  onSubmit: (input: OnCertSubmitType) => void;
};

const CertificateModal: FC<ModalProps[ModalTypes.CERTIFICATE]> = ({ onSubmit, close }) => {
  const [isDescriptionExpaned, setIsDescriptionExpaned] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const {
    points: initialPoints,
    pointsLoading,
    allPointsCount,
    paginationInfo,
    requestPoints,
    loadMorePoints,
    certificatePointsCount,
    filters,
    pointLoading,
  } = useCertificatePresenter();

  const {
    showBillingModal,
    showEmptyPointsModal,
    showEmptyPointsTrialModal,
    showPointsModal,
    chosenPointsIds,
    chosenPoints,
    setPoints,
    searchText,
    onSearchChange,
    billingPath,
    isBillingEnabled,
    currentCompanyCountry,
    onCreateCertificate,
    onSelect,
    onSelectAll,
  } = useCertificateController({
    onSubmit,
    initialPoints,
    pointsLoading,
    allPointsCount,
    paginationInfo,
    requestPoints,
    loadMorePoints,
    certificatePointsCount,
    filters,
    setIsAllSelected,
    pointLoading,
  });

  const actionButtons = getCertificateActionButtons({
    showPointsModal,
    showEmptyPointsModal,
    showEmptyPointsTrialModal,
    showBillingModal,
    chosenPointsIds,
    onCancel: close,
    onCreateCertificate,
    billingPath,
    allPointsCount: certificatePointsCount || 0,
    isAllSelected,
    isBillingEnabled,
    country: currentCompanyCountry,
  });

  if (allPointsCount === null) {
    return null;
  }

  return (
    <ModalScreen
      title={TEXTS.TITLE}
      onClose={close}
      className={styles.CertificateModal}
      actionButtons={actionButtons}>
      {showPointsModal && (
        <div>
          <CertificateModalHint
            isDescriptionExpaned={isDescriptionExpaned}
            setIsDescriptionExpaned={setIsDescriptionExpaned}
            currentCompanyCountry={currentCompanyCountry}
          />

          <PointsListCheckboxGroup
            points={chosenPoints}
            setPoints={setPoints}
            searchText={searchText}
            searchInputPlaceholder={TEXTS.CHOOSE_POINTS_SEARCH_PLACEHOLDER}
            allPointsCount={allPointsCount}
            onSearchChange={onSearchChange}
            paginationInfo={paginationInfo}
            loadMorePoints={loadMorePoints}
            pointsLoading={pointsLoading || false}
            labelPlacement={LabelPlacement.RIGHT}
            containerClassNames={[
              styles.PointsListLargeContainer,
              isDescriptionExpaned ? styles.PointsListLargeContainerCollapsed : '',
            ]}
            showStreamData={false}
            onSelect={onSelect}
            enableItemHint={
              <DisabledPointHint
                pageLink={billingPath}
                onClick={close}
              />
            }
            isAllSelected={isAllSelected}
            onSelectAll={onSelectAll}
            isMulti
          />
        </div>
      )}

      <CertificateNoAvailableHint
        currentCompanyCountry={currentCompanyCountry}
        isBillingEnabled={isBillingEnabled}
        showBillingModal={showBillingModal}
        showEmptyPointsModal={showEmptyPointsModal}
        showEmptyPointsTrialModal={showEmptyPointsTrialModal}
      />
    </ModalScreen>
  );
};

export default CertificateModal;
