import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

const CustomModal: FC<ModalProps[ModalTypes.CUSTOM]> = ({
  title,
  description,
  actionButtons,
  hideCloseButton = true,
  close,
}) => (
  <ModalScreen
    title={title}
    onClose={close}
    hideCloseButton={hideCloseButton}
    contentClassName={styles.CustomModalContent}
    actionButtons={actionButtons}>
    {description}
  </ModalScreen>
);

export default CustomModal;
