import { useWebPlayerActionsContext, useWebPlayerContext } from 'application/providers/WebPlayerProvider';
import React from 'react';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import IconSkipForward from '@zvuk-b2b/react-uikit/ui/icons/SkipForward';
import { EPlayState } from '@player-core/use-player-frame';
import { TEXTS } from '../texts';
import styles from '../index.module.scss';

const getSkipButtonHint = (isDisabled: boolean, playState: EPlayState, isConnected: boolean) => {
  if (isDisabled) {
    if (playState !== EPlayState.PLAY) {
      return TEXTS.PLAY_FOR_SKIP;
    }
    if (!isConnected) {
      return TEXTS.SKIP_DISABLED_OFFILE;
    }
    return TEXTS.PLAYER_PROCESSING;
  }
  return TEXTS.SKIP_TRACK;
};

const WebPlayerSkipButton: React.VFC = () => {
  const { actionButtonsDisabled, playState, isConnected } = useWebPlayerContext();

  const { onSkip } = useWebPlayerActionsContext();

  return (
    <IconButton
      className={`${styles.autoEvents} ${styles.skipButton}`}
      isDisabled={actionButtonsDisabled}
      elementId="skip_button"
      hint={getSkipButtonHint(actionButtonsDisabled, playState, isConnected)}
      onClick={onSkip}
      hintPlacement={HintPlacement.PLACEMENT_BOTTOM}>
      <IconSkipForward />
    </IconButton>
  );
};

export default WebPlayerSkipButton;
