import {
  CertificatePointsQueryFilter,
  CertificatePointsQueryPagination,
  PaginationInfoType,
  usePointCerttificateListQuery,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { useState } from 'react';
import { PointsCertificateQueryState, UsePointCertificateListResultType, UsePointsCertificateType } from './types';

const certFilters = ['query', 'companyId'];

const usePointsCertificate = (): UsePointsCertificateType => {
  const [aborterRef, setAbortRef] = useState(new AbortController());

  const [queryState, setQueryState] = useState<PointsCertificateQueryState>({
    query: '',
    companyId: '',
    page: 0,
    pageSize: 0,
    skip: true,
  });

  const { data, called, loading, fetchMore } = usePointCerttificateListQuery({
    variables: {
      filters: {
        query: queryState.query,
        companyId: queryState.companyId,
      },
      pagination: { page: queryState.page, pageSize: queryState.pageSize },
    },
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
    skip: queryState.skip,
  });

  const clearFilters = (filters: CertificatePointsQueryFilter) => {
    const correctFilters = filters;

    for (const [key, _value] of Object.entries(filters)) {
      if (!certFilters.includes(key)) {
        // @ts-ignore
        delete correctFilters[`${key}`];
      }
    }

    return correctFilters;
  };

  const request = (filters: CertificatePointsQueryFilter, pagination: CertificatePointsQueryPagination) => {
    setQueryState({
      query: filters.query,
      companyId: filters.companyId || '',
      page: pagination.page,
      pageSize: pagination.pageSize,
      skip: false,
    });
  };

  async function loadMore(
    filters: CertificatePointsQueryFilter,
    pagination: PaginationInfoType | CertificatePointsQueryPagination
  ) {
    const result = await fetchMore({
      variables: {
        filters: clearFilters(filters),
        pagination,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const data = {
          ...prev,
          certificatePointsQuery: {
            ...prev?.certificatePointsQuery,
            ...fetchMoreResult?.certificatePointsQuery,
            result: [
              ...(prev?.certificatePointsQuery?.result || []),
              ...(fetchMoreResult?.certificatePointsQuery.result || []),
            ],
          },
        };

        return data;
      },
    });

    return result;
  }

  const abortRequest = () => {
    aborterRef.abort();
    setAbortRef(new AbortController());
  };

  const result: UsePointCertificateListResultType = {
    loading,
    called,
    data: {
      points: data?.certificatePointsQuery.result,
      paginationInfo: data?.certificatePointsQuery.paginationInfo,
    },
    loadMore,
    certificatePointsCount: data?.certificatePointsQuery.certificatePointsCount || 0,
    abortRequest,
  };

  return [request, result];
};

export default usePointsCertificate;
