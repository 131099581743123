import React from 'react';

import { CompanyTagsProvider } from 'application/components/common';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

const withProviders =
  (WrappedComponent: React.ElementType) =>
  ({ initialTags, onSubmit, close }: ModalProps[ModalTypes.LINKED_TAGS]) => (
    <CompanyTagsProvider>
      <WrappedComponent
        initialTags={initialTags}
        onSubmit={onSubmit}
        close={close}
      />
    </CompanyTagsProvider>
  );

export default withProviders;
