import React from 'react';

import Checkbox, { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';
import Switcher from '@zvuk-b2b/react-uikit/ui/Switcher';
import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import styles from './index.module.scss';

export enum SelectorType {
  'checkbox',
  'switch',
  'dropdown',
}

type WrapperType = {
  onChange: (value: boolean, currentItem: PointsListCheckboxGroupPointType) => void;
  currentItem: PointsListCheckboxGroupPointType;
  isChecked: boolean;
  children: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export type ChooseItemListItemType = WrapperType & {
  selectorType: SelectorType;
};

export const CheckboxWrapper = ({ onChange, currentItem, children, isChecked, isDisabled, isLoading }: WrapperType) => (
  <Checkbox
    key={`checkbox_item_bind_${currentItem.id}`}
    elementId={`checkbox_item_bind_${currentItem.id}`}
    isChecked={isChecked}
    isDisabled={currentItem.isDisabled || isDisabled}
    labelPlacement={LabelPlacement.LEFT}
    isStretch
    isLoading={isLoading}
    className={styles.ChooseItemListItemCheckbox}
    onChange={(value) => {
      onChange(value, currentItem);
    }}>
    {children}
  </Checkbox>
);

export const SwitcherWrapper = ({
  onChange,
  currentItem,
  children,
  isChecked,
  isDisabled,
  isLoading = false,
}: WrapperType) => (
  <Switcher
    key={`switch_item_bind-${currentItem.id}`}
    elementId={`switch_item_bind_${currentItem.address}`}
    isChecked={isChecked}
    isDisabled={isDisabled}
    labelPlacement={LabelPlacement.LEFT}
    isStretch
    isLoading={isLoading}
    onChange={(value) => {
      onChange(value, currentItem);
    }}>
    {children}
  </Switcher>
);

const ChooseItemListItem = ({
  currentItem,
  onChange,
  selectorType,
  isChecked,
  children,
  isDisabled = false,
  isLoading = false,
}: ChooseItemListItemType) => (
  <div
    className={styles.ChooseItemListItem}
    key={`item-list_item_${currentItem.id}`}>
    {selectorType === SelectorType.checkbox && (
      <CheckboxWrapper
        isChecked={isChecked}
        currentItem={currentItem}
        onChange={(value: boolean) => {
          onChange(value, currentItem);
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}>
        {children}
      </CheckboxWrapper>
    )}

    {selectorType === SelectorType.switch && (
      <SwitcherWrapper
        isChecked={isChecked}
        currentItem={currentItem}
        onChange={(value: boolean) => {
          onChange(value, currentItem);
        }}
        isDisabled={isDisabled}>
        {children}
      </SwitcherWrapper>
    )}
  </div>
);

export default ChooseItemListItem;
