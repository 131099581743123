import React, { FC, useLayoutEffect, useState } from 'react';
import { useGeneratedReportsContext } from 'application/providers/GeneratedReportsProvider';
import { useNavigate, useLocation } from 'application/providers/RouterProvider';
import useSetNotificationViewed from 'domain/media/useSetNotificationViewed';
import { REPORTS_PAGE_PATH } from 'application/pages/ReportsPage';
import { ViewNotificationMutationInput } from 'domain/api/graphql/generated';
import GeneratedReportsModal from './index';

enum NOTIFICATION_TYPES {
  RAO_VOIS_COMPANY_REPORT_CREATED = 'rao_vois_company_report_created',
}

const NOTIFICATION_TYPE_ROUTES: { [key: string]: string } = {
  [NOTIFICATION_TYPES.RAO_VOIS_COMPANY_REPORT_CREATED]: REPORTS_PAGE_PATH,
};

const GeneratedReportsWrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { notifications } = useGeneratedReportsContext();
  const { request: setNotificationViewed } = useSetNotificationViewed();
  const [showModal, setShowModal] = useState(false);
  const isNotificationShow = showModal && notifications?.result && notifications.result.length;

  useLayoutEffect(() => {
    if (notifications?.result) {
      setShowModal(true);
    }
  }, [pathname, notifications?.result]);

  if (isNotificationShow) {
    const [firstNotification] = notifications.result;
    const notificationId = firstNotification.modelId;

    const handleNotificationViewed = (input: ViewNotificationMutationInput) => {
      setShowModal(false);
      setNotificationViewed(input);
    };

    const confirmHandler = (input: ViewNotificationMutationInput) => {
      const navigationPage = NOTIFICATION_TYPE_ROUTES[firstNotification.notificationType];

      if (navigationPage) {
        navigate(navigationPage);
      }

      handleNotificationViewed(input);
    };

    return (
      <>
        {children}
        <GeneratedReportsModal
          title={firstNotification.title}
          message={firstNotification.message}
          buttonText={firstNotification.confirm}
          isCloseDisabled={firstNotification.isBlocked}
          notificationType={firstNotification.notificationType}
          onCancel={() => handleNotificationViewed({ notificationId })}
          onConfirm={() => confirmHandler({ notificationId })}
        />
      </>
    );
  }

  return <>{children}</>;
};

export default GeneratedReportsWrapper;
