import React from 'react';

import { SuggestionCompanyType } from 'domain/autocomplete/types';

const CITIES_ORDER = ['г москва', 'г санкт-петербург'].reduce<{ [s: string]: number }>((acc, key, index) => {
  acc[key] = index;
  return acc;
}, {});

class SuggestionUtils {
  static defaultSort(items: SuggestionCompanyType[]): SuggestionCompanyType[] {
    return items.sort((a, b) => {
      const cityAKey = a.region?.toLocaleLowerCase?.();
      const cityBKey = b.region?.toLocaleLowerCase?.();

      const indexA = CITIES_ORDER[cityAKey!] ?? Number.MAX_SAFE_INTEGER;
      const indexB = CITIES_ORDER[cityBKey!] ?? Number.MAX_SAFE_INTEGER;

      return indexA - indexB;
    });
  }

  static createSuggestionPreview(
    target: string,
    value: string,
    MarkRenderer: React.ElementType = React.Fragment
  ): React.ReactNode {
    const preparedResult = target.toLowerCase();
    const preparedValue = value.toLowerCase().trim();

    let currentIndex: number = 0;
    return preparedResult.split(preparedValue).reduce<React.ReactNode[]>((acc, item, index) => {
      acc.push(target.substr(currentIndex, item.length));
      currentIndex += item.length;

      const key = `mark_${target}_${index}`;
      acc.push(<MarkRenderer key={key}>{target.substr(currentIndex, value.length)}</MarkRenderer>);
      currentIndex += value.length;
      return acc;
    }, []);
  }
}

export default SuggestionUtils;
