import React from 'react';

import IconRenderer, { IconProps } from 'ui/icons/IconRenderer';

import icon from './help-circle.svg';

const IconHelpCircle = ({ className, style }: IconProps) => (
  <IconRenderer
    iconHtml={icon}
    className={className}
    style={style}
  />
);

export default IconHelpCircle;
