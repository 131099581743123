import React, { useMemo } from 'react';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import { MediaCompanyThematicContentBlocksQuery } from 'domain/api/graphql/generated';
import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import { useStreamRepositoryInjector } from 'application/providers/DIContainerProvider';
import AnalyticsService, { ParamNameAMP } from 'application/services/AnalyticsService';
import { ThematicEventNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { NY_BANNER_TYPES_ENUM } from 'application/services/AnalyticsService/eventTypes/thematic';
import { TEXTS } from './consts';

import styles from './index.module.scss';

export type CompanyThematicContentBlockType = MediaCompanyThematicContentBlocksQuery['result'][0];

export interface AddNewYearContentModalProps {
  thematicBlock: CompanyThematicContentBlockType;
}

const AddNewYearContentModal = (props: ModalProps[ModalTypes.ADD_NEW_YEAR_CONTENT_MODAL]) => {
  const { thematicBlock, close, onNext } = props;

  const { setStreamWithThematic } = useStreamRepositoryInjector();

  const { from, to } = useMemo(() => {
    const parsedFrom = DateUtils.parse(thematicBlock.playFromDate, 'yyyy-MM-dd');
    const parsedTo = DateUtils.parse(thematicBlock.playToDate, 'yyyy-MM-dd');
    const from = DateUtils.getFormatDateFrom(parsedFrom, 'dd MMMM yyyy');
    const to = DateUtils.getFormatDateFrom(parsedTo, 'dd MMMM yyyy');

    return { from, to };
  }, [thematicBlock]);

  const handleSkip = () => {
    AnalyticsService.event(ThematicEventNameAMP.SKIP_THEMATIC_CONTENT_PRESSED, {
      [ParamNameAMP.TYPE]: NY_BANNER_TYPES_ENUM.MODAL_ONE_WAVE,
    });

    close();
    onNext?.();
  };

  const handleAdd = () => {
    AnalyticsService.event(ThematicEventNameAMP.ADD_THEMATIC_CONTENT_PRESSED, {
      [ParamNameAMP.TYPE]: NY_BANNER_TYPES_ENUM.MODAL_ONE_WAVE,
    });

    setStreamWithThematic(true);
    close();
    onNext?.();
  };

  return (
    <ModalScreen
      onClose={close}
      actionButtons={[
        <Button
          elementId="new_year_run_stream_add"
          type={ButtonType.PRIMARY}
          onClick={handleAdd}>
          {TEXTS.ADD_BUTTON}
        </Button>,
        <Button
          elementId="new_year_run_stream_skip"
          type={ButtonType.SECONDARY}
          onClick={handleSkip}>
          {TEXTS.SKIP_BUTTON}
        </Button>,
      ]}>
      <div className={styles.AddNewYearContentModal}>
        <div className={styles.AddNewYearContentModalCover} />
        <h2>{TEXTS.TITLE}</h2>
        <p>
          {TEXTS.DESCRIPTION_PART_ONE}
          <strong>
            {from}
            {TEXTS.DESCRIPTION_PART_TWO}
            {to}
          </strong>
          {TEXTS.DESCRIPTION_PART_THREE} {TEXTS.DESCRIPTION_PART_FOUR} <strong>{TEXTS.DESCRIPTION_PART_FIVE}</strong>
        </p>
        <p>{TEXTS.SUB_DESCRIPTION_TEXT}</p>
      </div>
    </ModalScreen>
  );
};

export default AddNewYearContentModal;
