/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type PopperContentProps = React.PropsWithChildren<{
  elementId: string;
  popperClassName?: string;
  arrowClassName?: string;
  tooltipProps: {
    'data-popper-interactive': boolean | undefined;
    style: React.CSSProperties;
  };
  arrowProps: {
    style: React.CSSProperties;
    'data-popper-arrow': boolean;
  };
  setTooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}>;

const PopperContent = ({
  elementId,
  children,
  popperClassName,
  setTooltipRef,
  tooltipProps,
  arrowClassName,
  arrowProps,
}: PopperContentProps) => (
  <div
    id={elementId}
    className={classNames(styles.Popper, popperClassName)}
    ref={setTooltipRef}
    data-testid={elementId}
    {...tooltipProps}>
    <div
      className={classNames(styles.PopperArrow, arrowClassName)}
      {...arrowProps}
    />
    {children}
  </div>
);

export default PopperContent;
