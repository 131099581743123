class PlayerUtils {
  static clamp(value: number, min: number = 0, max: number = Number.MAX_SAFE_INTEGER): number {
    return Math.max(Math.min(value, max), min);
  }

  static getProgress(audio: HTMLAudioElement): number {
    return audio.duration && audio.currentTime ? audio.currentTime / audio.duration : 0;
  }

  static getIsLoading(audio: HTMLAudioElement): boolean {
    return audio.buffered.length === 0;
  }
}

export default PlayerUtils;
