import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';
import VideoMediafilePreviewItemContent from './VideoMediafileItemContent';
import VideoMediafilesPreviewItemSkeleton from './VideoMediafilePreviewItemSkeleton';
import { IVideoMediafile } from '../../types';

export type StreamPreviewItemProps = {
  file?: IVideoMediafile;
  className?: string;
  actionText?: string;
  actionHandler?: () => void;
};

const VideoStreamPreviewItem: React.FC<StreamPreviewItemProps> = ({ file, className, actionText, actionHandler }) => {
  const renderItem = () => {
    if (file) {
      return (
        <VideoMediafilePreviewItemContent
          imgLink={file.previewUrl}
          title={file.title}
          uploading={file.uploading}
          duration={file.duration}
          actionHandler={actionHandler}
          actionText={actionText}
        />
      );
    }

    return <VideoMediafilesPreviewItemSkeleton />;
  };

  return <div className={classNames(styles.StreamPreviewItem, className)}>{renderItem()}</div>;
};

export default VideoStreamPreviewItem;
