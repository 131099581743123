import { useBusinessProfilePlaylistHideMutation, ZvukPlaylistHideMutationInput } from 'domain/api/graphql/generated';
import { BaseMutationResultType, FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseBusinessProfilePlaylistHideType } from './types';

const useBusinessProfilePlaylistHide = (): UseBusinessProfilePlaylistHideType => {
  const [hideMutation, hideMutationResult] = useBusinessProfilePlaylistHideMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukPlaylistHideMutationInput) => {
    const hideMutationData = await hideMutation({
      variables: {
        input,
      },
      refetchQueries: ['zvukProfileList'],
    });

    return {
      isOk: !!hideMutationData.data?.zvukPlaylistHide?.ok,
      playlist: hideMutationData.data?.zvukPlaylistHide?.playlist,
    };
  };

  const result: BaseMutationResultType = {
    error: hideMutationResult.error,
    loading: hideMutationResult.loading,
    called: hideMutationResult.called,
  };

  return [request, result];
};

export default useBusinessProfilePlaylistHide;
