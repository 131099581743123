import { useCompanyId } from 'application/providers/AccountProvider';
import { useCreateVideoStreamMutation, useUpdateVideoStreamMutation } from 'domain/api/graphql/generated';

export const useCreateVideoStreamPresenter = () => {
  const [createVideoStreamMutation] = useCreateVideoStreamMutation();
  const [updateVideoStreamMutation] = useUpdateVideoStreamMutation();

  const companyId = useCompanyId();

  return {
    createVideoStream: createVideoStreamMutation,
    updateVideoStream: updateVideoStreamMutation,
    companyId,
  };
};
