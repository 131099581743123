import { useState, useRef } from 'react';
import { useMediafilesApprovalLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { DEFAULT_PAGE_SIZE } from 'domain/api/graphql/constants';

const POLLING_INTERVAL = 180000;

const useUserMediafiles = () => {
  const [isReloading, setIsReloading] = useState(true);
  const ref = useRef(0);

  const [query, queryResult] = useMediafilesApprovalLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_FIRST,
    pollInterval: POLLING_INTERVAL,
  });

  const request = async (id: string, contentblockId: string, page = 0, pageSize = DEFAULT_PAGE_SIZE) => {
    ref.current = 0;
    setIsReloading(true);

    await query({
      variables: {
        filters: { streamId: id, contentBlockId: contentblockId },
        pagination: { pageSize, page },
      },
    });

    setIsReloading(false);
  };

  const loadMore = async (id: string, contentblockId?: string, pageSize = DEFAULT_PAGE_SIZE) => {
    const page = (queryResult.data?.streamMediafilesApproval.paginationInfo.page || 0) + 1;

    if (page <= ref.current) return;
    ref.current = page;

    await queryResult.fetchMore({
      variables: {
        filters: { streamId: id, contentblockId, disliked: false },
        pagination: {
          pageSize,
          page,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        mediafilesUserPaginationQuery: {
          ...prev?.streamMediafilesApproval,
          ...fetchMoreResult?.streamMediafilesApproval,
          result: [
            ...(prev.streamMediafilesApproval.result || []),
            ...(fetchMoreResult.streamMediafilesApproval.result || []),
          ],
        },
      }),
    });
  };

  const mediafilesList = queryResult.data?.streamMediafilesApproval.result;

  return {
    request,
    loadMore,
    error: queryResult.error,
    loading: queryResult.loading,
    isReloading,
    data: {
      mediafilesList,
      paginationInfo: queryResult.data?.streamMediafilesApproval.paginationInfo,
    },
  };
};

export default useUserMediafiles;
