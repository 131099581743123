import { useMemo, useCallback, useState, useEffect } from 'react';

import FileUploaderUtils from '@zvuk-b2b/react-uikit/ui/FileUploader/utils';

import {
  UseBusinessProfilePlaylistFormControllerProps,
  DirtyFields,
  BusinessProfilePlaylistFormData,
  BusinessProfilePlaylistFormDataKeys,
} from './types';
import { DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA } from './consts';
import { TEXTS } from './texts';

const useBusinessProfilePlaylistFormController = ({
  initialFormData,
  errors,
  onSubmit,
}: UseBusinessProfilePlaylistFormControllerProps) => {
  const [formDirtyFields, setFormDirtyFields] = useState<DirtyFields>(new Map());
  const [externalErrors, setExternalErrors] = useState<Map<BusinessProfilePlaylistFormDataKeys, string[]>>(new Map());
  const [hasExternalErrors, setHasExternalErrors] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      ...initialFormData,
      title: initialFormData?.title || DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA.title,
      description: initialFormData?.description || DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA.description,
      poster: initialFormData?.poster || DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA.poster,
    }),
    [initialFormData, DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA]
  );

  const getUploadedImageFileDescription = useCallback(
    (file: File) => {
      const name = file.name;
      const size = FileUploaderUtils.getFileSizeWithUnits(file.size);

      return TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_ERROR(name, size);
    },
    [TEXTS]
  );

  const handleFieldDirtyChange = useCallback(
    (field: BusinessProfilePlaylistFormDataKeys, value: boolean) => {
      const newFormDirtyFields = new Map(formDirtyFields);
      newFormDirtyFields.set(field, value);

      const newExternalErrors = new Map(externalErrors);
      newExternalErrors.delete(field);

      setFormDirtyFields(newFormDirtyFields);
      setExternalErrors(newExternalErrors);
    },
    [setFormDirtyFields, setExternalErrors, formDirtyFields, externalErrors]
  );

  const handleSubmit = useCallback(
    (formData: BusinessProfilePlaylistFormData) => {
      onSubmit(formData);
      setHasExternalErrors(false);
    },
    [onSubmit]
  );

  useEffect(() => {
    if (errors && !hasExternalErrors) {
      const newExternalErrors: Map<BusinessProfilePlaylistFormDataKeys, string[]> = new Map();
      Object.keys(errors).forEach((key) => {
        newExternalErrors.set(key as BusinessProfilePlaylistFormDataKeys, errors[key]);
      });

      setExternalErrors(newExternalErrors);
      setHasExternalErrors(true);
    }
  }, [errors]);

  return {
    initialValues,
    externalErrors,
    getUploadedImageFileDescription,
    handleFieldDirtyChange,
    handleSubmit,
  };
};

export default useBusinessProfilePlaylistFormController;
