import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import classNames from 'classnames';

import InputComponent from 'ui/Input';

import FieldUtils from '../FieldUtils';
import { FieldRulesType, TextFieldProps } from '../types';

import styles from './index.module.scss';

const TextField = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const { field, fieldState } = useController(props);

  const { elementId, error, rules, containerClassName, handleFieldDirtyChange, onFieldChange } = props;

  const handleChange = (value: string, _: React.SyntheticEvent<Element, Event>) => {
    field.onChange(value);
    const isEmpty = value.length === 0;
    handleFieldDirtyChange?.(field.name, !isEmpty);

    onFieldChange?.(field);
  };

  return (
    <InputComponent
      {...field}
      {...props}
      elementId={`form_text_field_${elementId}`}
      containerClassName={classNames(styles.TextField, containerClassName)}
      required={!!rules?.required}
      error={
        Array.isArray(error)
          ? error
          : FieldUtils.formatError(fieldState.invalid, field.value, rules as FieldRulesType, error)
      }
      onChange={handleChange}
    />
  );
};

export default TextField;
