export const TEXTS = {
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_ADD_TITLE: 'Добавить плейлист в бизнес-профиль',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_UPDATE_TITLE: 'Редактирование плейлиста',

  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_FROM_TEXT: 'Загружен плейлист из ',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_FROM_ZVUK_BUSINESS_TEXT: 'Звук Бизнеса ',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_FROM_ZVUK_TEXT: 'Звука ',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_TRACK: 'трек',

  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_1: 'Из-за авторских прав ',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_2: 'нельзя использовать название и логотип вашего бренда ',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_3:
    'в обложке и названии плейлиста. Чтобы сделать брендированный плейлист, ',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_4: 'напишите менеджеру',

  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_ZVUK_BUSINESS_WARNING:
    'Сейчас нельзя редактировать треки плейлистов из Звук Бизнеса',
  BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_ZVUK_WARNING: 'Сейчас нельзя редактировать треки в уже созданном плейлисте',

  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_CREATE_TITLE: 'Сохранить',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_CREATE_ERROR_TEXT: 'Ошибка при создании плейлиста. Проверьте введенные данные',

  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_PUBLISH_TITLE: 'Опубликовать плейлист в Звук',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_PUBLISH_SUCCESS_TEXT: (
    playlist: string,
    profile: string,
    isPublished?: boolean | null
  ) => `Плейлист «${playlist}» в профиле «${profile}» ${isPublished ? 'публикуется' : 'создан, но не опубликован'}.`,
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_SUCCESS_ZVUK_LINK: 'Посмотреть в Звуке',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_PUBLISH_ERROR_TEXT:
    'Ошибка при публикации плейлиста. Попробуйте еще раз',

  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_UPDATE_TITLE: 'Обновить плейлист в Звуке',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_UPDATE_SUCCESS_TEXT: (playlist: string, profile: string) =>
    `Плейлист «${playlist}» в профиле «${profile}» обновлен.`,
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_UPDATE_ERROR_TEXT:
    'Ошибка при обновлении плейлиста. Проверьте введенные данные',

  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_REMOVE_TITLE: 'Удалить плейлист',
  BUSINESS_PROFILE_PLAYLIST_REMOVE_MODAL_TITLE: 'Удаление плелиста',
  BUSINESS_PROFILE_PLAYLIST_REMOVE_MODAL_DESCRIPTION: (playlistTitle: string) =>
    `Вы действительно хотите удалить плейлист «${playlistTitle}?»`,
  BUSINESS_PROFILE_PLAYLIST_REMOVE_MODAL_SUBMIT_BUTTON: 'Удалить',
  BUSINESS_PROFILE_PLAYLIST_REMOVE_MODAL_CANCEL_BUTTON: 'Отмена',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_REMOVE_SUCCESS_TEXT: 'Плейлист удален из Звука',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_REMOVE_ERROR_TEXT: 'Ошибка при удалении плейлиста. Попробуйте еще раз',

  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_HIDE_TITLE: 'Скрыть в Звуке',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_HIDE_SUCCESS_TEXT: 'Плейлист будет скрыт из Звука',
  BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_HIDE_ERROR_TEXT: 'Ошибка при скрытии плейлиста. Попробуйте еще раз',
};
