import React, { FC } from 'react';

import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

export type PointUpdateConfirmModalProps = {
  onSubmit: () => void;
};

const PointUpdateConfirmModal: FC<ModalProps[ModalTypes.POINT_UPDATE_CONFIRM]> = ({ onSubmit, close }) => (
  <ModalScreen
    title={TEXTS.SAVE_POINT_MODAL_TITLE}
    hideCloseButton
    onClose={close}
    className={styles.AdCreateConfirmModalDesc}
    actionButtons={[
      <Button
        key="button_confirm_point_update_modal"
        elementId="button_confirm_point_update_modal"
        type={ButtonType.PRIMARY}
        onClick={onSubmit}>
        {TEXTS.BUTTON_SAVE_POINT_TITLE}
      </Button>,
      <Button
        key="button_cancel_point_update_modal"
        elementId="button_cancel_point_update_modal"
        type={ButtonType.SECONDARY}
        onClick={close}>
        {TEXTS.BUTTON_CANCEL_POINT_TITLE}
      </Button>,
    ]}>
    <div className={styles.AdCreateConfirmModal}>{TEXTS.SAVE_POINT_MODAL_DESCRIPTION}</div>
  </ModalScreen>
);

export default PointUpdateConfirmModal;
