import {
  useBusinessProfilePlaylistCreateFromZvukMutation,
  ZvukPlaylistCreateFromZvukMutationInput,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { BusinessProfileCreatePlaylistRequestResultType, UseBusinessProfileCreatePlaylistFromZvukType } from './types';

const useBusinessProfilePlaylistCreateFromZvuk = (): UseBusinessProfileCreatePlaylistFromZvukType => {
  const [mutation, mutationResult] = useBusinessProfilePlaylistCreateFromZvukMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukPlaylistCreateFromZvukMutationInput) => {
    const mutationData = await mutation({
      variables: {
        input,
      },
    });

    return {
      isOk: !!mutationData.data?.zvukPlaylistCreateFromZvuk?.ok,
      playlist: mutationData.data?.zvukPlaylistCreateFromZvuk?.playlist,
    };
  };

  const zvukProfilePlaylistCreateFromZvukData = mutationResult.data?.zvukPlaylistCreateFromZvuk;
  const hasErrors = !!zvukProfilePlaylistCreateFromZvukData?.errors;
  const zvukProfilePlaylistCreateFromZvukResultData = {
    ok: zvukProfilePlaylistCreateFromZvukData?.ok,
    errors: hasErrors ? GraphQLApiUtils.errorsArrayToObject(zvukProfilePlaylistCreateFromZvukData?.errors) : undefined,
  };

  const result: BusinessProfileCreatePlaylistRequestResultType = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    data: zvukProfilePlaylistCreateFromZvukResultData,
  };

  return [request, result];
};

export default useBusinessProfilePlaylistCreateFromZvuk;
