import { useAdCampaignRepositoryInjector } from 'application/providers/DIContainerProvider';

const useAdRenameModalAdapter = () => {
  const { readAdCampaign, updateAdCampaign, adCampaignUpdateLoading, clearAdCampaigns } =
    useAdCampaignRepositoryInjector();

  return {
    readAdCampaign,
    updateAdCampaign,
    adCampaignUpdateLoading,
    clearAdCampaigns,
  };
};

export default useAdRenameModalAdapter;
