import { Colors } from '@zvuk-b2b/react-uikit/ui/types';
import { CSSProperties } from 'react';

export const FRAME_WIDTH = 36;
export const FRAME_HEIGHT = 36;

export const PLAYER_STYLES: Record<string, CSSProperties> = {
  wrapperStyles: {
    width: `${FRAME_WIDTH}px`,
    height: `${FRAME_HEIGHT}px`,
    padding: 5,
  },
  playProperties: {
    height: 18,
    width: 18,
    borderStyle: 'double',
    borderWidth: '0px 0 0px 16px',
    borderColor: `transparent transparent transparent ${Colors.COLOR_NEUTRAL_500}`,
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  stopProperties: {
    width: 20,
    height: 20,
    borderStyle: 'solid',
    borderWidth: '10px 0px 10px 20px',
    borderColor: `transparent transparent transparent ${Colors.COLOR_NEUTRAL_500}`,
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const FRAME_STYLES = {
  height: `${FRAME_HEIGHT}px`,
  width: `${FRAME_WIDTH}px`,
  border: '0px',
};

export const PERMISSION_REGEXP = /do not have permission/;
