/* eslint-disable no-nested-ternary */
import { EPlayState } from '@player-core/use-player-frame';
import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import AnalyticsService, {
  EventNameAMP,
  ParamNameAMP,
  PlatformValues,
  PlayerView,
} from 'application/services/AnalyticsService';
import { PointParamNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { useEffect, useState } from 'react';

const lastUniquePlayerStartedKey = 'lastUniquePlayerStartedKey';

interface IUseWebPlayerPlayAmplitudeProps {
  pointId?: string | number;
  streamId?: string;
  playState: EPlayState;
  isWidgetActive?: boolean;
  playlistLength?: number;
  firstStart?: boolean | null;
  firstGenerationCompletedTime?: number | null;
  startLoadingTime?: number | null;
}

export const useWebPlayerPlayAmplitude = ({
  pointId,
  streamId,
  playState,
  isWidgetActive,
  firstGenerationCompletedTime,
  firstStart,
  startLoadingTime,
  playlistLength,
}: IUseWebPlayerPlayAmplitudeProps) => {
  const { isAndroid, isIOS, isDesktop } = useMedia();
  const [playerLoadingRegistered, setPlayerLoadingRegistered] = useState<boolean>(false);

  const platform = isDesktop
    ? PlatformValues.DESKTOP
    : isAndroid
      ? PlatformValues.ANDROID
      : isIOS
        ? PlatformValues.IOS
        : PlatformValues.UNKNOWN;

  const currentDate = DateUtils.getFormatDateFrom(new Date(), 'yyyy-M-dd');
  const prevDate = localStorage.getItem(lastUniquePlayerStartedKey);

  useEffect(() => {
    if (playState === EPlayState.PLAY) {
      AnalyticsService.event(EventNameAMP.PLAYER_PLAYBACK_STARTED, {
        [ParamNameAMP.UNIQUE_PER_DAY]: prevDate !== currentDate,
        [ParamNameAMP.STREAM_ID]: streamId,
        [PointParamNameAMP.POINT_ID]: pointId,
        [ParamNameAMP.PLATFORM]: platform,
        [ParamNameAMP.PLAYER_VIEW]: isWidgetActive ? PlayerView.WIDGET : PlayerView.PAGE,
      });

      localStorage.setItem(lastUniquePlayerStartedKey, currentDate);
    }
  }, [playState]);

  useEffect(() => {
    if (!playerLoadingRegistered && startLoadingTime && (playlistLength || firstGenerationCompletedTime)) {
      AnalyticsService.event(EventNameAMP.PLAYER_LOADED, {
        [ParamNameAMP.PLAYER_START_TIME]: Math.floor(
          ((firstGenerationCompletedTime || Date.now()) - startLoadingTime) / 1000
        ),
        [ParamNameAMP.FIRST_PLAYER_START]: firstStart,
      });
      setPlayerLoadingRegistered(true);
    }
  }, [playerLoadingRegistered, startLoadingTime, firstGenerationCompletedTime, playlistLength]);
};
