import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

const Renderer = React.lazy(() => import('./renderer'));

export const FORGOT_COMPLETE_PAGE_PATH = '/forgot-complete';

const LetterSentPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

LetterSentPageWrapper.config = {
  routes: [{ path: FORGOT_COMPLETE_PAGE_PATH }],
  access: [LocalAccessRoles.ANONYMOUS],
};

export default LetterSentPageWrapper;
