export const TEXTS = {
  BUSINESS_PROFILE_PLAYLIST_FORM_TITLE: 'Название плейлиста',
  BUSINESS_PROFILE_PLAYLIST_FORM_TITLE_ERROR: 'Введите название плейлиста',
  BUSINESS_PROFILE_PLAYLIST_FORM_TITLE_LENGTH_ERROR: (count: number) => `Максимальная длина - ${count} символов`,

  BUSINESS_PROFILE_PLAYLIST_FORM_DESCRIPTION: 'Описание',
  BUSINESS_PROFILE_PLAYLIST_FORM_DESCRIPTION_ERROR: 'Введите описание плейлиста',
  BUSINESS_PROFILE_PLAYLIST_FORM_DESCRIPTION_LENGTH_ERROR: (count: number) => `Максимальная длина - ${count} символов`,

  BUSINESS_PROFILE_PLAYLIST_FORM_POSTER: 'Загрузить обложку',
  BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION: (w: number, h: number) =>
    `Размер: ${w}х${h}px. Расширения: .png, .jpeg, jpg`,
  BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_ERROR: (name: string, size: string) =>
    `${name}: Размер: ${size}. Расширения: .png, .jpeg, jpg`,
  BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_SIZES: (w: number, h: number) => `Размер: ${w}х${h} px`,
  BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_EXTENSIONS: 'Расширения: .png, .jpeg, jpg',
  BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_BRAND_WARNING:
    'Изображение не должно содержать название вашего бренда или ваш логотип',

  BUSINESS_PROFILE_PLAYLIST_FORM_BUTTON_DEFAULT_TEXT: 'Сохранить',
  BUSINESS_PROFILE_PLAYLIST_FORM_BUTTON_CANCEL_TEXT: 'Отменить',
};
