/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import parsePhoneNumber from 'libphonenumber-js';
import ReactPhoneInput from 'react-phone-number-input/input';

import Input, { InputProps, InputType } from 'ui/Input';

import styles from './index.module.scss';

import { PhoneInputProps } from './types';

export enum PHONE_NUMBER_COUNTRY_CODES {
  RU = 'RU',
  BY = 'BY',
  KZ = 'KZ',
}

const DEFAULT_COUNTRY = PHONE_NUMBER_COUNTRY_CODES.RU;

type InputWrapperProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  inputProps?: InputProps;
};

const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>((props: InputWrapperProps, ref) => {
  const { value, size, placeholder, autoComplete, inputProps, onChange, ...rest } = props;

  return (
    <Input
      {...rest}
      ref={ref}
      onChange={(_value, event) => onChange?.(event as React.ChangeEvent<HTMLInputElement>)}
      placeholder={placeholder}
      autoComplete={autoComplete}
      value={value as string}
      elementId={inputProps?.elementId || ''}
      label={inputProps?.label}
      type={inputProps?.type || InputType.TEL}
      error={inputProps?.error}
      required={inputProps?.required}
      isDisabled={inputProps?.isDisabled}
      isLoading={inputProps?.isLoading}
      inputClassName={inputProps?.inputClassName}
      containerClassName={inputProps?.containerClassName}
      adornmentLeft={inputProps?.adornmentLeft}
      adornmentRight={inputProps?.adornmentRight}
      inputRef={inputProps?.inputRef}
      onEnterPressed={inputProps?.onEnterPressed}
      onMouseOver={inputProps?.onMouseOver}
      onMouseOut={inputProps?.onMouseOut}
    />
  );
});

const PhoneInput = (props: PhoneInputProps) => {
  const { initialValue, className, placeholder, onChange, onBlur, onFocus, onMouseOver, onMouseOut } = props;

  const value = useMemo(() => initialValue, []);

  const onBlurWrapper = (event: React.FocusEvent<HTMLInputElement>) => {
    const parsedValue = parsePhoneNumber(event.target.value);
    const formattedValue = parsedValue?.formatInternational();
    if (formattedValue) {
      onChange(formattedValue);
    }
    onBlur?.(event);
  };

  return (
    <div className={classNames(styles.PhoneInput, className)}>
      <ReactPhoneInput
        value={value}
        defaultCountry={DEFAULT_COUNTRY}
        placeholder={placeholder}
        inputComponent={InputWrapper}
        inputProps={props}
        onChange={(value) => onChange(String(value))}
        onBlur={onBlurWrapper}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </div>
  );
};

export default PhoneInput;
