import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';
import { ScheduleAdTypeMarkerProps } from './types';

const ScheduleAdTypeMarker = ({ type, size, className }: ScheduleAdTypeMarkerProps) => (
  <div
    className={classNames(styles.ScheduleMarker, className, {
      [styles.ScheduleMarkerSm]: size === 'sm',
      [styles.ScheduleMarkerInterval]: type === 'interval',
      [styles.ScheduleMarkerExact]: type === 'exact',
      [styles.ScheduleMarkerSkipped]: type === 'skipped',
    })}
  />
);

export default ScheduleAdTypeMarker;
