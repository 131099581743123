import React from 'react';

import { BROADCAST_SCHEDULE_PAGE_PATH } from 'application/consts';
import BroadcastSchedulePageWrapper from 'application/pages/BroadcastSchedulePage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const broadcastScheduleRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: BROADCAST_SCHEDULE_PAGE_PATH,
        element: <BroadcastSchedulePageWrapper />,
      },
    ],
  },
];
