import React from 'react';
import classNames from 'classnames';

import useKeyDownCallback from 'toolkit/useKeyDownCallback';
import { KeyboardEventCode } from 'toolkit/types';

import styles from './index.module.scss';

export type DropdownItemProps = React.PropsWithChildren<{
  elementId: React.Key;
  onMouseDown: (event?: React.SyntheticEvent) => void;
  className?: string;
  contentClassName?: string;
  selected?: boolean;
  disabled?: boolean;
  adornmentLeft?: React.ReactNode;
  adornmentRight?: React.ReactNode;
}>;

const DropdownItem = ({
  children,
  onMouseDown,
  className,
  contentClassName,
  selected,
  disabled = false,
  elementId,
  adornmentLeft,
  adornmentRight,
}: DropdownItemProps) => {
  const onKeyDown = useKeyDownCallback(() => onMouseDown(), { include: [KeyboardEventCode.ENTER] });

  return (
    <li
      role="menuitem"
      onMouseDown={!disabled ? onMouseDown : undefined}
      onKeyDown={!disabled ? onKeyDown : undefined}
      className={classNames(styles.DropdownItem, className)}
      data-selected={selected}
      data-disabled={disabled}
      data-testid={`dropdown_item_${elementId}`}
      id={elementId as string}>
      {adornmentLeft && (
        <div className={classNames(styles.DropdownItemAdornment, styles.DropdownItemAdornmentL)}>{adornmentLeft}</div>
      )}

      <span
        className={classNames(styles.DropdownItemContent, contentClassName, {
          [styles.DropdownItemContentWithAdornmentLeft]: !!adornmentLeft,
          [styles.DropdownItemContentWithAdornmentRight]: !!adornmentRight,
        })}>
        {children}
      </span>

      {adornmentRight && (
        <div className={classNames(styles.DropdownItemAdornment, styles.DropdownItemAdornmentR)}>{adornmentRight}</div>
      )}
    </li>
  );
};

export default DropdownItem;
