import React from 'react';

import { PASSWORD_RESET_PAGE_PATH, PASSWORD_RESET_PAGE_PATH_ALIAS } from 'application/consts';
import PasswordResetPageWrapper from 'application/pages/PasswordResetPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const passwordResetRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: PASSWORD_RESET_PAGE_PATH,
        element: <PasswordResetPageWrapper />,
      },
      {
        path: PASSWORD_RESET_PAGE_PATH_ALIAS,
        element: <PasswordResetPageWrapper />,
      },
    ],
  },
];
