import React from 'react';

import { AllFileErrorCodeType, FileError, RejectedFileListProps } from 'ui/FileUploader/types';
import IconButton from 'ui/IconButton';
import IconTrash2 from 'ui/icons/Trash2';

import styles from './index.module.scss';

const RejectedFileList = <T extends File, K extends AllFileErrorCodeType>({
  fileRejections,
  withRemoveButton = false,
  getUploadedFileDescription,
  handleRejectedFileRemove,
  elementId,
}: RejectedFileListProps<T, K>) => (
  <>
    {[...fileRejections.values()].map(({ file, errors }) => {
      const typedFile = file as T;

      return (
        <div
          className={styles.RejectedFileList}
          key={`${typedFile.name}_container`}
          data-testid={`${elementId}_errors_container`}>
          <ul
            id={`${typedFile.name}_container`}
            className={styles.RejectedFileListFile}>
            <li key={typedFile.name}>
              {getUploadedFileDescription(typedFile)}
              <ul className={styles.RejectedFileListError}>
                {errors.map((e: FileError<K>) => (
                  <li key={e.code.toString()}>{e.message}</li>
                ))}
              </ul>
            </li>
          </ul>
          {withRemoveButton && (
            <IconButton
              elementId={`${typedFile.name}_remove_button`}
              key={`${typedFile.name}_remove_button`}
              className={styles.RejectedFileListButton}
              onClick={() => handleRejectedFileRemove?.(typedFile.name)}>
              <IconTrash2 />
            </IconButton>
          )}
        </div>
      );
    })}
  </>
);

export default RejectedFileList;
