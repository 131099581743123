import React, { FC, useEffect } from 'react';

import { useCurrentPointAdapter } from 'application/adapters';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import AnalyticsService, {
  StreamBindFlowEventNameAMP,
  StreamBindFlowParamNameAMP,
} from 'application/services/AnalyticsService';
import usePlayerChangeController from 'application/hooks/usePlayerChangeController';
import { PointWithDevice } from 'application/presenters/point/types';
import Popper, { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';

import { DeviceTypes } from 'domain/api/graphql/generated';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import IconSettings from '@zvuk-b2b/react-uikit/ui/icons/Settings';
import IconMic from '@zvuk-b2b/react-uikit/ui/icons/Mic';
import IconVolume2 from '@zvuk-b2b/react-uikit/ui/icons/Volume2';

import { TEXTS } from './texts';
import { PlayerTypesInFlow } from '../../SelectPlayerModal/types';
import useRequestDevicesController from '../useRequestDevicesController';

import styles from '../index.module.scss';

const DEVICE_IMAGE_PATH = '/assets/img/device_photos/sberBoom.png';

export type SberBoomModalType = {
  point: PointWithDevice;
};

const SberBoomModal: FC<ModalProps[ModalTypes.SBERBOOM_REQUEST]> = ({
  onPrevious,
  onConfirmClose,
  onNext,
  close,
  point,
}) => {
  const { clearCurrentPoint } = useCurrentPointAdapter();
  const { deviceRequest, deviceRequestLoading, currentCompany, userRequest } = useAccountContext();
  const { onSuccessCallback, hasRecentRequest, cantRequestDevice, isFirstRequest } = useRequestDevicesController({
    point,
    currentCompany,
    modalDeviceType: DeviceTypes.BoomSpeaker,
  });

  const { setToPlayerType } = usePlayerChangeController({
    pointId: point.id,
    fromPlayerType: DeviceTypes.BoomSpeaker,
    onSuccessCallback,
  });

  useEffect(() => {
    setToPlayerType('WEB');
  }, []);

  const onSubmit = async () => {
    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_REQUEST_DEVICE_CLICKED, {
      [StreamBindFlowParamNameAMP.DEVICE_TYPE]: PlayerTypesInFlow.BOOM_SPEAKER,
      [StreamBindFlowParamNameAMP.FIRST_DEVICE_REQUEST]: isFirstRequest,
    });

    const result = await deviceRequest({
      companyId: currentCompany?.id || '',
      deviceTypes: [DeviceTypes.BoomSpeaker],
      pointId: point.id,
    });

    if (result.ok) {
      onNext?.({});
      userRequest();
    }
  };

  const handleCancel = () => {
    if (hasRecentRequest) {
      onNext?.({ hasRequest: hasRecentRequest });
      clearCurrentPoint();
      close();
    } else if (onConfirmClose) {
      onConfirmClose();
    } else {
      clearCurrentPoint();
      close();
    }
  };

  const SubmitButton = (
    <Button
      key="button_sberboom_request_modal_success"
      elementId="button_sberboom_request_modal_success"
      type={ButtonType.PRIMARY}
      onClick={onSubmit}
      isLoading={deviceRequestLoading}
      isDisabled={hasRecentRequest || cantRequestDevice}>
      {hasRecentRequest ? TEXTS.SUCCESS_DISABLED_BUTTON : TEXTS.SUCCESS_BUTTON}
    </Button>
  );

  const CancelButton = (
    <Button
      key="button_sberboom_request_modal_cancel"
      elementId="button_sberboom_request_modal_cancel"
      type={ButtonType.SECONDARY}
      isLoading={deviceRequestLoading}
      onClick={() => {
        onPrevious?.();
      }}>
      {TEXTS.CANCEL_BUTTON}
    </Button>
  );

  const SubmitButtonWithHint = (
    <Popper
      containerClassName={styles.DeviceModalHint}
      placement={HintPlacement.PLACEMENT_TOP_START}
      triggerNode={SubmitButton}
      isShow>
      {TEXTS.DISABLED_BUTTON_BINDED_HINT}
    </Popper>
  );

  return (
    <ModalScreen
      titlePlacement="left"
      onClose={handleCancel}
      className={styles.DeviceModal}
      contentClassName={styles.DeviceModalContent}
      containerClassName={styles.DeviceModalContainer}
      actionButtons={[cantRequestDevice ? SubmitButtonWithHint : SubmitButton, CancelButton]}>
      <img
        src={DEVICE_IMAGE_PATH}
        alt=""
        loading="lazy"
        className={styles.DeviceModalImage}
      />

      <h2>{TEXTS.MODAL_TITLE}</h2>
      <div>{TEXTS.TAGLINE}</div>

      <ul>
        <li>
          <IconMic />
          {TEXTS.FEATURES_VOICE}
        </li>
        <li>
          <IconSettings />
          {TEXTS.FEATURES_SMART_HOUSE}
        </li>
        <li>
          <IconVolume2 />
          {TEXTS.FEATURES_NOISE}
        </li>
      </ul>

      <div>{TEXTS.DESCRIPTION_FIRST_BLOCK}</div>
      <div>
        {TEXTS.DESCRIPTION_SECOND_BLOCK}
        <br />
        {TEXTS.DESCRIPTION_THIRD_BLOCK}
      </div>
    </ModalScreen>
  );
};

export default SberBoomModal;
