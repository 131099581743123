import { useEffect } from 'react';

import {
  useInjection,
  KEYS,
  UseStreamRepositoryType,
  UseBillingRepositoryType,
} from 'application/providers/DIContainerProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import { TARIFFS } from 'application/pages/constants';
import CompanyUtils from 'application/utils/CompanyUtils';

import useUserFeaturesOnboardingPass from 'domain/media/useUserFeaturesOnboardingPass';
import useUserFeaturesOnboarding from 'domain/media/useUserFeaturesOnboarding';
import { UserFeatures } from 'domain/api/graphql/generated';

const useSelectPlayerModalAdapter = () => {
  const useStreamRepository = useInjection<UseStreamRepositoryType>(KEYS.STREAM_REPOSITORY);
  const useBillingRepository = useInjection<UseBillingRepositoryType>(KEYS.BILLING_REPOSITORY);

  const {
    isChangeStreamLoading,
    isCopyStreamTemplateLoading,
    changeStream,
    copyTemplateStream,
    getSavedStreams,
    isSavedStreamsLoading,
  } = useStreamRepository();

  const { getBillingInfo } = useBillingRepository();

  const { currentCompany } = useAccountContext();

  const { request: onboardingPassedRequest, result } = useUserFeaturesOnboardingPass();
  const { items: userOnboardingFeatures, refetch: refetchFeaturesOnboarding } = useUserFeaturesOnboarding({
    features: [UserFeatures.WarningStreamFirstStart],
  });

  const isPopularTariff = CompanyUtils.compareTariff(currentCompany?.pricePlan?.alias ?? '', TARIFFS.POPULAR);
  const isCustomInfoShouldShow = !userOnboardingFeatures[0]?.isOnboarded && isPopularTariff;
  const isBillingEnabled = currentCompany?.isBillingEnabled;

  useEffect(() => {
    if (isBillingEnabled) {
      getBillingInfo(currentCompany?.id!);
    }
  }, []);

  return {
    isBillingEnabled,
    isCustomInfoShouldShow,
    isChangeStreamLoading,
    isCopyStreamTemplateLoading,
    isUserFeaturesOnboardingPassLoading: result.loading,
    isSavedStreamsLoading,
    changeStream,
    copyTemplateStream,
    getSavedStreams,
    onboardingPassedRequest,
    refetchFeaturesOnboarding,
    country: currentCompany?.country,
  };
};

export default useSelectPlayerModalAdapter;
