import { set as setCookie, get as getCookie, remove as removeCookie } from 'es-cookie';
import { CookieAttributes } from 'es-cookie/src/CookieAttributes';

import { PROD } from 'application/consts';

import BrowserUtils from '@zvuk-b2b/react-uikit/toolkit/BrowserUtils';
import CookieUtils, { CookieAttributes as CookieAttributesUtils } from '@zvuk-b2b/react-uikit/toolkit/CookieUtils';

import { AuthTokenType, UserDataType } from './types';

export const AUTH_COOKIE_NAME = 'JWT';
export const ROISTAT_VISIT_COOKIE_NAME = 'roistat_visit';
export const PROMOCODE_COOKIE_NAME = 'promocode'; // TODO: move to local storage
export const SBER_AGENT_ID_COOKIE_NAME = 'sber_agent_id'; // TODO: move to local storage

export const USER_STORAGE_KEY = 'USER_CACHE';

export const AUTH_COOKIE_ATTRIBUTES: CookieAttributes = {
  expires: 30,
};

class AuthUtils {
  static getAuthHeaderObject(token: AuthTokenType | undefined): { Authorization?: String } {
    const Authorization = token && `JWT ${token}`;

    if (Authorization) {
      return { Authorization };
    }

    return {};
  }

  static setTokenCookie(token: AuthTokenType) {
    if (token) {
      AuthUtils.setCookieByKey(AUTH_COOKIE_NAME, token, AUTH_COOKIE_ATTRIBUTES);
    }
  }

  static removeTokenCookie() {
    removeCookie(AUTH_COOKIE_NAME);
  }

  static getTokenCookie(): AuthTokenType | undefined {
    return getCookie(AUTH_COOKIE_NAME);
  }

  static getCookieByKey(key: string): string | undefined {
    return getCookie(key);
  }

  static setCookieByKey(key: string, value: string, params?: CookieAttributes) {
    const inIframe = BrowserUtils.inIframe();

    if (inIframe) {
      const attributes: CookieAttributesUtils = {
        ...params,
        sameSite: 'none',
        secure: true,
        partitioned: true,
      };

      CookieUtils.set(key, value, attributes);

      return;
    }

    const attributes: CookieAttributes = {
      ...params,
      sameSite: 'none',
      secure: true,
    };

    setCookie(key, value, PROD ? attributes : params);
  }

  static readCachedUser(): UserDataType | undefined {
    const data = localStorage.getItem(USER_STORAGE_KEY);

    try {
      if (!data) {
        throw new Error();
      }

      return JSON.parse(data);
    } catch (e) {
      return undefined;
    }
  }

  static cacheUser(data: UserDataType) {
    if (!data) {
      AuthUtils.clearCachedUser();
    }

    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(data));
  }

  static clearCachedUser() {
    localStorage.removeItem(USER_STORAGE_KEY);
  }
}

export default AuthUtils;
