import { SourceValues } from 'application/services/AnalyticsService';
import { useURLSearchParams } from 'application/providers/RouterProvider';
import { AuthQueryKeys } from 'application/pages/types';
import BrowserUtils from 'application/utils/BrowserUtils';
import ActivationService from 'application/services/ActivationService';
import { RegisterQueryKeys } from 'domain/auth/types';

export enum FromValues {
  LANDING = 'landing', // это значение выставляется в Тильде
  MOBILE = 'mobile',
  TTS_BOT = 'tts_bot',
}

const usePageShownAmpSource = () => {
  const [from, _, promocode] = useURLSearchParams([
    AuthQueryKeys.FROM,
    AuthQueryKeys.FROM_SOURCE,
    RegisterQueryKeys.PROMOCODE,
  ]);
  const inIframe = BrowserUtils.inIframe();

  let source = SourceValues.SITE;

  const activationType = ActivationService.getActivationType();

  if (activationType) {
    source = SourceValues[activationType];
  }

  if (from === FromValues.TTS_BOT) {
    source = SourceValues.TTS_BOT;
  }

  if (promocode && promocode === SourceValues.MYBEAST) {
    source = SourceValues.MYBEAST;
  }

  if (inIframe) {
    switch (from) {
      case SourceValues.RKEEPER: {
        source = SourceValues.RKEEPER;
        break;
      }
      default:
        source = SourceValues.SITE_IFRAME;
    }
  }

  return source;
};

export default usePageShownAmpSource;
