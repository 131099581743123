import { useBusinessProfilePublishMutation, ZvukProfilePublishMutationInput } from 'domain/api/graphql/generated';
import { BaseMutationResultType, FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseBusinessProfilePublishType } from './types';

const useBusinessProfilePublish = (): UseBusinessProfilePublishType => {
  const [publishMutation, publishMutationResult] = useBusinessProfilePublishMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukProfilePublishMutationInput) => {
    const publishMutationData = await publishMutation({
      variables: {
        input,
      },
      refetchQueries: ['zvukProfileList'],
    });

    return {
      isOk: !!publishMutationData.data?.zvukProfilePublish?.ok,
      businessProfile: publishMutationData.data?.zvukProfilePublish?.profile,
    };
  };

  const result: BaseMutationResultType = {
    error: publishMutationResult.error,
    loading: publishMutationResult.loading,
    called: publishMutationResult.called,
  };

  return [request, result];
};

export default useBusinessProfilePublish;
