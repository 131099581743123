import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { TEXTS } from 'application/pages/PointsPage/renderer/texts';

import { DeviceTypes } from 'domain/api/graphql/generated';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

export type PlayerChangeConfirmationModalProps = {
  handleConfirmPlayerChange: () => Promise<void>;
  fromPlayerType?: DeviceTypes;
};

const PlayerChangeConfirmationModal: FC<ModalProps[ModalTypes.POINT_PLAYER_CHANGE_CONFIRMATION]> = ({
  handleConfirmPlayerChange,
  fromPlayerType,
  close,
}) => (
  <ModalScreen
    title={TEXTS.PLAYER_CHANGE_DEVICE_CONFIRM_TITLE}
    titlePlacement="center"
    onClose={close}
    hideCloseButton
    actionButtons={[
      <Button
        elementId="button_confirm_player_change"
        type={ButtonType.PRIMARY}
        onClick={handleConfirmPlayerChange}>
        {TEXTS.PLAYER_CHANGE_DEVICE_CONFIRM_OK}
      </Button>,
      <Button
        elementId="button_decline_player_change"
        type={ButtonType.SECONDARY}
        onClick={close}>
        {TEXTS.PLAYER_CHANGE_DEVICE_CONFIRM_DECLINE}
      </Button>,
    ]}>
    <div className={styles.PlayerChangeConfirmDesc}>
      {fromPlayerType && TEXTS.PLAYER_CHANGE_DEVICE_CONFIRM_DESCRIPTION}
    </div>
  </ModalScreen>
);

export default PlayerChangeConfirmationModal;
