import { TEXTS } from 'application/pages/BillingPage/renderer/texts';
import { useNotificationContext } from 'application/providers/NotificationProvider';
import { RouterUtils } from 'application/providers/RouterProvider';
import { BILLING_PAGE_PATH, StateParamValues } from 'application/pages/BillingPage';
import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';

import { UseCardBindModalControllerProps } from './types';

const useCardBindModalController = ({ getLinkForBindingCard }: UseCardBindModalControllerProps) => {
  const notification = useNotificationContext();

  const pathname = RouterUtils.generatePath(BILLING_PAGE_PATH, { state: StateParamValues.INFO });

  const redirectToBindCard = (link?: string) => {
    AnalyticsService.event(EventNameAMP.BIND_CARD_PRESSED);

    if (link) {
      RouterUtils.setTargetPathname(pathname);
      window.location.assign(link);
    } else {
      notification.showErrorNotification({
        children: TEXTS.BILLING_BIND_CARD_NOTIFICATION_ERROR_MESSAGE,
      });
    }
  };

  const bindCardConfirm = async () => {
    const link = await getLinkForBindingCard(`${window.location.origin}${pathname}`);

    redirectToBindCard(link);
  };

  return { bindCardConfirm };
};

export default useCardBindModalController;
