/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useCallback } from 'react';

import { ModalsMap } from './modalsMap';
import { ModalsMapType } from './types';
import { useModalContext } from './useModalContext';

const ModalRenderer = () => {
  const { currentModals, closeModal } = useModalContext();

  const handleClose = useCallback(
    async (preClose?: () => void) => {
      try {
        await preClose?.();
      } catch (e) {
        closeModal();
      } finally {
        closeModal();
      }
    },
    [closeModal]
  );

  const modals = useMemo(
    () =>
      currentModals.map((modal) => {
        const CurrentModalComponent = currentModals ? ModalsMap[modal?.type as keyof ModalsMapType] : null;

        if (!CurrentModalComponent) return <div />;

        return (
          <CurrentModalComponent
            {...modal?.state}
            key={modal.type}
            open
            close={() => handleClose(modal?.state?.preClose)}
          />
        );
      }),
    [closeModal, currentModals]
  );

  if (modals.length) {
    return <div>{modals}</div>;
  }

  return null;
};

export default ModalRenderer;
