import { MutationResult, QueryResult } from '@apollo/client/react/types/types';
import { ErrorType } from './generated';

export enum FetchPolicyKeys {
  CACHE_FIRST = 'cache-first',
  NETWORK_ONLY = 'network-only',
  CACHE_AND_NETWORK = 'cache-and-network',
  CACHE_ONLY = 'cache-only',
  NO_CACHE = 'no-cache',
  STANDBY = 'standby',
}

export type ErrorTypes = Array<ErrorType | null | undefined>;

export type ErrorsMapType = {
  [s: string]: Array<string>;
};

export type CommonDataType = {
  ok?: boolean | null;
  errors?: ErrorsMapType;
};

export type BaseMutationResultType = Pick<MutationResult, 'error' | 'loading' | 'called'>;
export type BaseQueryResultType = Pick<QueryResult, 'error' | 'loading'>;
export type BasePollingQueryResultType = Pick<
  QueryResult,
  'error' | 'loading' | 'startPolling' | 'stopPolling' | 'networkStatus'
>;
export type BaseQueryRefetchResultType = Pick<QueryResult, 'error' | 'loading' | 'refetch' | 'networkStatus'>;
