import React, { useEffect, useState, useMemo } from 'react';

import { useAccountContext } from 'application/providers/AccountProvider';

import {
  consoleFeatureFlagsWindow,
  CONSOLE_FEATURE_FLAGS_UPDATED,
  runtimeFeatureFlagsKeys,
  buildFeatureFlagsKeys,
  buildFeatureFlags,
} from './consts';
import { RuntimeFeatureFlagsType, BuildFeatureFlagsType, FeatureFlagsType } from './types';
import { FeatureFlagsContext } from './FeatureFlagsContext';

consoleFeatureFlagsWindow.setFeatureConsole = (key: string, value: boolean) => {
  consoleFeatureFlagsWindow.featuresConsole = {
    ...consoleFeatureFlagsWindow?.featuresConsole,
    [key]: value,
  };

  window.dispatchEvent(new Event(CONSOLE_FEATURE_FLAGS_UPDATED));
};

const FeatureFlagsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { currentCompany } = useAccountContext();

  const [featureFlagsConsole, setFeatureFlagsConsole] = useState(consoleFeatureFlagsWindow?.featuresConsole);

  useEffect(() => {
    const handler = () => setFeatureFlagsConsole(consoleFeatureFlagsWindow.featuresConsole);

    window.addEventListener(CONSOLE_FEATURE_FLAGS_UPDATED, handler);

    return () => {
      window.removeEventListener(CONSOLE_FEATURE_FLAGS_UPDATED, handler);
    };
  }, []);

  const preparedRuntimeFeatureFlags = useMemo(() => {
    const result: Record<string, boolean> = {};

    runtimeFeatureFlagsKeys.forEach((key) => {
      if (featureFlagsConsole && featureFlagsConsole[key] !== null) {
        result[key] = featureFlagsConsole[key];
      } else if (currentCompany && currentCompany.uiFeatures) {
        result[key] = currentCompany.uiFeatures[key];
      } else {
        result[key] = false;
      }
    });

    return result as RuntimeFeatureFlagsType;
  }, [currentCompany?.uiFeatures, featureFlagsConsole]);

  const preparedBuildFeatureFlags = useMemo(() => {
    const result: Record<string, boolean> = {};

    buildFeatureFlagsKeys.forEach((key) => {
      if (featureFlagsConsole && featureFlagsConsole[key] !== null) {
        result[key] = featureFlagsConsole[key];
      } else if (buildFeatureFlags[key]) {
        result[key] = Boolean(buildFeatureFlags[key]);
      } else {
        result[key] = false;
      }
    });

    return result as BuildFeatureFlagsType;
  }, [buildFeatureFlags, featureFlagsConsole]);

  const featureFlags = useMemo(() => {
    const featureFlagsMap = new Map(Object.entries(preparedBuildFeatureFlags));

    for (const [key, value] of Object.entries(preparedRuntimeFeatureFlags)) {
      if (featureFlagsMap.has(key)) {
        featureFlagsMap.set(key, !!featureFlagsMap.get(key) && value);
      } else {
        featureFlagsMap.set(key, value);
      }
    }

    const featureFlagsObject = Object.fromEntries(featureFlagsMap) as FeatureFlagsType;

    consoleFeatureFlagsWindow.featuresConsole = featureFlagsObject;
    return featureFlagsObject;
  }, [preparedRuntimeFeatureFlags, preparedBuildFeatureFlags]);

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
};

export default FeatureFlagsProvider;
