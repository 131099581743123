export const TEXTS = {
  BUSINESS_PROFILE_FORM_COMPANY_UPLOAD_TEXT: 'Загрузить',

  BUSINESS_PROFILE_FORM_COMPANY_NAME: 'Название компании',
  BUSINESS_PROFILE_FORM_COMPANY_NAME_ERROR: 'Введите название компании',
  BUSINESS_PROFILE_FORM_COMPANY_NAME_LENGTH_ERROR: (count: number) => `Максимальная длина - ${count} символов`,

  BUSINESS_PROFILE_FORM_DESCRIPTION: 'Описание',
  BUSINESS_PROFILE_FORM_DESCRIPTION_PLACEHOLDER: 'Краткая информация о вашей компании или описание бизнес-профиля',
  BUSINESS_PROFILE_FORM_DESCRIPTION_ERROR: 'Введите описание компании или бизнес-профиля',
  BUSINESS_PROFILE_FORM_DESCRIPTION_LENGTH_ERROR: (count: number) => `Максимальная длина - ${count} символов`,

  BUSINESS_PROFILE_FORM_COMPANY_SITE: 'Сайт компании',
  BUSINESS_PROFILE_FORM_COMPANY_SITE_PLACEHOLDER: 'www.site.ru',

  BUSINESS_PROFILE_FORM_COMPANY_POSTER_LABEL: 'Обложка',
  BUSINESS_PROFILE_FORM_COMPANY_POSTER_DESCRIPTION: (w: number, h: number) =>
    `Размер: ${w}х${h}px. Расширения: .png, .jpeg, jpg`,
  BUSINESS_PROFILE_FORM_COMPANY_POSTER_DESCRIPTION_ERROR: (name: string, size: string) =>
    `${name}: Размер: ${size}. Расширения: .png, .jpeg, jpg`,

  BUSINESS_PROFILE_FORM_COMPANY_AVATAR_LABEL: 'Логотип',
  BUSINESS_PROFILE_FORM_COMPANY_AVATAR_DESCRIPTION: (w: number, h: number) => `Размер: ${w}х${h}px. Расширения: .png`,
  BUSINESS_PROFILE_FORM_COMPANY_AVATAR_ERROR: 'Загрузите аватар компании или бизнес-профиля',

  BUSINESS_PROFILE_FORM_COMPANY_BANNER_LINK: 'Ссылка для рекламного баннера',
  BUSINESS_PROFILE_FORM_COMPANY_BANNER_LINK_DISABLED:
    'Ссылка для рекламного баннера (для разблокировки загрузите оба рекламных баннера)',
  BUSINESS_PROFILE_FORM_COMPANY_BANNER_LINK_PLACEHOLDER: 'Ссылка',

  BUSINESS_PROFILE_FORM_COMPANY_BANNER_WEB_LABEL: 'Рекламный баннер. Размер для компьютера',
  BUSINESS_PROFILE_FORM_COMPANY_BANNER_WEB_DESCRIPTION: (w: number, h: number) =>
    `Размер: ${w}х${h}px. Расширения: .png, .jpeg, jpg`,

  BUSINESS_PROFILE_FORM_COMPANY_BANNER_MOBILE_LABEL: 'Рекламный баннер. Размер для мобильных телефонов',
  BUSINESS_PROFILE_FORM_COMPANY_BANNER_MOBILE_DESCRIPTION: (w: number, h: number) =>
    `Размер: ${w}х${h}px. Расширения: .png, .jpeg, jpg`,

  BUSINESS_PROFILE_FORM_CONFIRM_TEXT: 'Я принимаю ',
  BUSINESS_PROFILE_FORM_TERM_SERVICE: 'условия использования сервиса, ',
  BUSINESS_PROFILE_FORM_PERSONAL_DATA: 'согласен на обработку персональных данных ',
  BUSINESS_PROFILE_FORM_AND: 'и на ',
  BUSINESS_PROFILE_FORM_INFO: 'информирование',

  BUSINESS_PROFILE_FORM_BUTTON_DEFAULT_TEXT: 'Создать бизнес-профиль',
  BUSINESS_PROFILE_FORM_BUTTON_CANCEL_TEXT: 'Отменить',
};
