import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export enum InfoTypes {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export type InfoProps = React.PropsWithChildren<{
  className?: string;
  type?: InfoTypes;
  elementId?: string;
}>;

const Info = ({ className, type = InfoTypes.INFO, children, elementId = 'info' }: InfoProps) => (
  <div
    className={classNames(styles.Info, className)}
    data-testid={elementId}
    data-type={type}>
    {children}
  </div>
);

export default Info;
