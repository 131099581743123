import { StreamsQuerySort, useBusinessProfileCompanySavedStreamsLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { BusinessProfileCompanySavedStreamsType } from './types';

const PAGE_SIZE = 50;

const useBusinessProfileCompanySavedStreams = () => {
  const [savedStreamsQuery, savedStreamsResult] = useBusinessProfileCompanySavedStreamsLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (companyIds: string[], query = '') => {
    const result = await savedStreamsQuery({
      variables: {
        filters: { companyIds, query },
        pagination: { page: 0, pageSize: PAGE_SIZE },
        sort: StreamsQuerySort.IdDesc,
      },
    });

    return result.data?.streamsPaginationQuery.result;
  };

  return {
    request,
    error: savedStreamsResult.error,
    isLoading: savedStreamsResult.loading,
    called: savedStreamsResult.called,
    savedStreams: (savedStreamsResult.data?.streamsPaginationQuery.result ||
      []) as BusinessProfileCompanySavedStreamsType[],
  };
};

export default useBusinessProfileCompanySavedStreams;
