import { useState } from 'react';

import {
  AdCampaignQueryFilter,
  AdCampaignQueryPagination,
  AdCampaignQuerySort,
  useMediaAdCampaignsPaginationQueryQuery,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { AdCampaignsQueryState, UseAdCampaignsResultType, UseAdCampaignsType } from './types';

const useAdCampaigns = (): UseAdCampaignsType => {
  const [queryState, setQueryState] = useState<AdCampaignsQueryState>({
    query: '',
    companyIds: [],
    companyTagIds: [],
    statuses: [],
    type: [],
    pointId: '',
    page: 0,
    pageSize: 0,
    sort: AdCampaignQuerySort.IdDesc,
    skip: true,
  });

  const { data, called, loading, error, networkStatus, fetchMore, refetch } = useMediaAdCampaignsPaginationQueryQuery({
    variables: {
      filters: {
        query: queryState.query,
        companyIds: queryState.companyIds,
        companyTagIds: queryState.companyTagIds as string[],
        statuses: queryState.statuses,
        pointId: queryState.pointId,
        type: queryState.type,
      },
      pagination: { page: queryState.page, pageSize: queryState.pageSize },
      sort: queryState.sort,
    },
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
    skip: queryState.skip,
  });

  const request = (
    filters: AdCampaignQueryFilter,
    pagination: AdCampaignQueryPagination,
    sort: AdCampaignQuerySort
  ) => {
    setQueryState({
      query: filters.query,
      companyIds: filters.companyIds || [],
      companyTagIds: filters.companyTagIds || [],
      statuses: filters.statuses || [],
      pointId: filters.pointId,
      type: filters.type || [],
      page: pagination.page,
      pageSize: pagination.pageSize,
      sort,
      skip: false,
    });
  };

  async function loadMore(
    filters: AdCampaignQueryFilter,
    pagination: AdCampaignQueryPagination,
    sort: AdCampaignQuerySort
  ) {
    const result = await fetchMore({
      variables: {
        filters,
        pagination,
        sort,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const data = {
          ...prev,
          adcampaignsPaginationQuery: {
            ...prev?.adcampaignsPaginationQuery,
            ...fetchMoreResult?.adcampaignsPaginationQuery,
            result: [
              ...(prev?.adcampaignsPaginationQuery?.result || []),
              ...(fetchMoreResult?.adcampaignsPaginationQuery?.result || []),
            ],
          },
        };

        return data;
      },
    });

    return result;
  }

  const result: UseAdCampaignsResultType = {
    error,
    loading,
    called,
    networkStatus,
    data: {
      adCampaigns: data?.adcampaignsPaginationQuery.result,
      statusesInfo: data?.adcampaignsPaginationQuery.statusesInfo,
      paginationInfo: data?.adcampaignsPaginationQuery.paginationInfo,
    },
    loadMore,
    refetch,
  };

  return [request, result];
};

export default useAdCampaigns;
