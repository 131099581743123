import React from 'react';

import { DESKTOP_S_WIDTH, MOBILE_M_WIDTH } from '@zvuk-b2b/react-uikit/toolkit/useMedia';

import { TEXTS } from '../texts';

import styles from './index.module.scss';

const BANNER_MOBILE_PATH = '/assets/img/business_profile/banner_mobile_hints/banner_mobile.png';
const BANNER_MOBILE_SMALL_PATH = '/assets/img/business_profile/banner_mobile_hints/banner_mobile_small.png';

const BANNER_MOBILE_SOURCE_IMAGE_WIDTH = 864;
const BANNER_MOBILE_SMALL_SOURCE_IMAGE_WIDTH = 272;

const BannerMobile = () => {
  const defaultImage = `${BANNER_MOBILE_SMALL_PATH} ${BANNER_MOBILE_SMALL_SOURCE_IMAGE_WIDTH}w`;
  const fallbackImage = `${BANNER_MOBILE_PATH} ${BANNER_MOBILE_SOURCE_IMAGE_WIDTH}w`;

  return (
    <div className={styles.BannerMobileContainer}>
      <div>
        <span>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_DESCRIPTION_1}</span>
        <strong>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_SIZE_TEXT}</strong>
        <div>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_DESCRIPTION_2}</div>
      </div>
      <div>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_DESCRIPTION_3}</div>

      <div className={styles.ImagesContainer}>
        <img
          src={BANNER_MOBILE_PATH}
          srcSet={`${defaultImage}, ${fallbackImage}`}
          sizes={`(max-width: ${DESKTOP_S_WIDTH}px) ${MOBILE_M_WIDTH}px,
         ${DESKTOP_S_WIDTH}px`}
          alt=""
          loading="lazy"
          className={styles.BannerMobile}
          data-testid="business_profile_hint_banner_mobile"
        />
      </div>
    </div>
  );
};

export default BannerMobile;
