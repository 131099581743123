import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import CompanyTagChip, {
  VISIBLE_TITLE_LENGTH,
  VISIBLE_TITLE_LENGTH_MOBILE,
} from 'application/components/CompanyTagChip';
import AnalyticsService, { EventNameAMP, ParamNameAMP, PageNames } from 'application/services/AnalyticsService';
import { useCompanyTagsContext } from 'application/components/common';
import Chip from '@zvuk-b2b/react-uikit/ui/Chip';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import Dropdown, { DropdownItemDataType } from '@zvuk-b2b/react-uikit/ui/Dropdown';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import IconChevronDown from '@zvuk-b2b/react-uikit/ui/icons/ChevronDown';
import IconX from '@zvuk-b2b/react-uikit/ui/icons/X';
import { CompanyTagType } from 'domain/company/types';
import styles from './index.module.scss';

import { TEXTS } from '../TagFilters/texts';

type FiltersTagsProps = {
  className?: string;
  onChange?: (tags: DropdownItemDataType[]) => void;
  tagIds?: string[];
  pageType: 'point' | 'ad_campaign';
  filterTagsFn?: (tag: CompanyTagType) => boolean;
};

const FiltersTags = ({ className, onChange, tagIds, pageType, filterTagsFn }: FiltersTagsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const anchorRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLInputElement>(null);

  const {
    getFilterOptions,
    filterSelectedTags: selectedTags,
    setFilterSelectedTags: setSelectedTags,
    maxVisibleOptions,
    menuMargin,
    isLoading,
    onChange: onSearchChange,
    tagsRequest,
  } = useCompanyTagsContext();

  const filterOptions = getFilterOptions(filterTagsFn);

  useEffect(() => {
    tagsRequest('');
  }, []);

  const onDropdownClose = () => {
    setIsExpanded(false);
    onSearchChange('');
  };

  const onDisableTag = (tag: DropdownItemDataType) => {
    const newTags = selectedTags.filter((selectedTag) => tag.key !== selectedTag.key);
    setSelectedTags(newTags);

    const ampParam = pageType === 'point' ? PageNames.POINT : PageNames.AD_CAMPAIGN;

    AnalyticsService.event(EventNameAMP.COMPANY_TAG_FILTER_REMOVED, {
      [ParamNameAMP.FILTER_BY_TAG_SOURCE]: ampParam,
    });
  };

  const onSelect = (tag: DropdownItemDataType) => {
    if (!tag.selected) {
      const keys = selectedTags.map((selectedTag) => selectedTag.key);

      if (!keys.includes(tag.key)) {
        setSelectedTags([...selectedTags, tag]);

        const ampParam = pageType === 'point' ? PageNames.POINT : PageNames.AD_CAMPAIGN;

        AnalyticsService.event(EventNameAMP.COMPANY_TAG_FILTER_ADDED, {
          [ParamNameAMP.FILTER_BY_TAG_SOURCE]: ampParam,
        });
      }
    } else {
      onDisableTag(tag);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedTags);
    }
  }, [selectedTags]);

  useEffect(() => {
    if (tagIds === undefined) {
      setSelectedTags([]);
    }
  }, [tagIds]);

  const { isMobile, isDesktop } = useMedia();

  return (
    <div
      className={classNames(styles.FiltersTags, className)}
      data-is-desktop={isDesktop}>
      <div ref={wrapperRef}>
        <Chip
          ref={anchorRef}
          elementId="tags_filter"
          isClickable
          isDisabled={filterOptions.length <= 0}
          className={styles.FiltersTagsChip}
          adornmentClassName={styles.FiltersTagsAdornmentDown}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          adornmentRight={
            <IconButton
              key="button_expand_tags"
              elementId="button_expand_tags"
              className={styles.FiltersTagsIconButton}
              isDisabled={filterOptions.length <= 0}
              isLoading={isLoading}
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}>
              <IconChevronDown />
            </IconButton>
          }>
          {TEXTS.TAGS_FILTER_TITLE}
        </Chip>

        {isExpanded && (
          <div className={styles.FiltersTagsInputWrapper}>
            {isMobile && (
              <IconButton
                elementId="tags_dropdown_close_button"
                className={styles.FiltersTagsCloseModalButton}
                onClick={(event) => {
                  onDropdownClose();
                  event.preventDefault();
                }}>
                <IconX />
              </IconButton>
            )}

            <Dropdown
              items={filterOptions}
              anchorNode={!isMobile ? wrapperRef.current! : anchorRef.current!}
              parentNode={wrapperRef.current!}
              elementId="tags_dropdown"
              onClose={onDropdownClose}
              onSearchChange={onSearchChange}
              isFullMode={isMobile}
              isMulti
              maxVisibleOptions={maxVisibleOptions}
              menuMargin={menuMargin}
              hasSearch
              onSelect={onSelect}
              withoutWrapper
              className={styles.FiltersTagsDropdown}
              hasSearchIcon={false}
              isNeedCloseWhileParentScrolling={false}
            />
          </div>
        )}
      </div>

      {selectedTags.map((tag) => (
        <CompanyTagChip
          key={tag.key}
          id={`current_filter_tag_${tag.key}`}
          title={tag.value || ''}
          onRemoveClick={() => {
            onDisableTag(tag);
          }}
          visibleTitleLength={isDesktop ? VISIBLE_TITLE_LENGTH : VISIBLE_TITLE_LENGTH_MOBILE}
        />
      ))}
    </div>
  );
};

export default FiltersTags;
