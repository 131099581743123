import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import { SelectPlayerFlowType } from 'application/components/common/SelectPlayerFlow/types';
import { PointWithDevice } from 'application/presenters/point/types';

export const BREAK_EDGE_VALUE = 4;

export type PointFromLocationType = {
  pointId: string;
  pointAddress: string;
};

export enum StreamBindSuccessModalMode {
  DEFAULT = 'DEFAULT',
  WEB_PLAYER = 'WEB_PLAYER',
}

export type UseStreamBindSuccessModalControllerProps = {
  selectedPoints: PointsListCheckboxGroupPointType[];
  flowType?: SelectPlayerFlowType;
  point?: PointWithDevice;
};

export type onChangeStreamClickProps = {
  pointId?: string;
  pointAddress?: string;
};

export enum StreamBindSuccessModalType {
  SELECT_WAVE = 'device_bind',
  OPEN_WEBPLAYER = 'web_player',
  COMPLETED = 'completed',
}
