export const TEXTS = {
  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_MODAL_TITLE: 'Где найти ссылку?',

  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_REGISTER: 'Зарегистрируйтесь на ',
  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_REGISTER_LINK: 'zvuk.com',
  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_INSTRUCTION:
    '. В «Моих коллекциях» создайте плейлист и скопируйте ссылку на него. ',
  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_INSTRUCTION_LINK: 'Инструкция',
  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_MANAGER_HELP:
    'Если не можете создать аккаунт или плейлист в Звуке — обратитесь к менеджеру.',

  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_OK_BUTTON: 'Понятно',
};
