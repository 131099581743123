import React, { FC, useMemo } from 'react';

import { TEXTS } from 'application/pages/texts';
import { TEXTS as POINT_TEXTS } from 'application/pages/PointsPage/renderer/texts';
import { HELP_CENTER_CONTRACTS_LINK } from 'application/pages/constants';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import CustomInfo from 'application/pages/StreamPage/renderer/CustomInfo/CustomInfo';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import Autocomplete from '@zvuk-b2b/react-uikit/ui/Autocomplete';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Info from '@zvuk-b2b/react-uikit/ui/Info';

import { DropdownPlacement } from '@zvuk-b2b/react-uikit/ui/Dropdown';
import { Country } from 'domain/api/graphql/generated';
import usePointCreateModalController from './usePointCreateModalController';
import usePointCreatePresenter from './usePointCreateModalPresenter';
import { PointCreateModalPages } from './types';

import styles from './index.module.scss';

const getText = (
  page: PointCreateModalPages,
  billingText: string,
  pointText: string,
  streamText: string,
  canAddPointWithPayment?: boolean
) => {
  if (canAddPointWithPayment) return billingText;
  if (page === PointCreateModalPages.POINT) return pointText;
  return streamText;
};

const PointCreateModal: FC<ModalProps[ModalTypes.POINT_CREATE]> = ({
  close,
  onSubmit,
  onNext,
  page = PointCreateModalPages.POINT,
  targetStreamId,
  collectionId,
  isTemplateMode,
}) => {
  const {
    isButtonLoading,
    isButtonWithCostLoading,
    createPoint,
    createPointError,
    getStandardizedPoints,
    userOnboardingFeatures,
    userOnboardingFeaturesLoading,
    getExtendSubscriptionCost,
    extendSubscription,
    getBillingInfo,
    hasTrialLicenses,
    hasActiveLicenses,
    getPaymentMethod,
    paymentMethod,
    subscriptionCostResult,
    changeStream,
    copyTemplateStream,
    onboardingPassedRequest,
    getSavedStreams,
    setCurrentPoint,
    readCurrentPoint,
    companyCountry,
  } = usePointCreatePresenter();

  const {
    addressState,
    isIpad,
    isButtonDisabled,
    isShowHintAboutPrice,
    isCustomInfoShouldShow,
    canAddPointWithPayment,
    handleCreateClick,
    onPointCreateHintModalShow,
    onPointCreateConfirmModalShow,
  } = usePointCreateModalController({
    close,
    onSubmit,
    onNext,
    createPoint,
    getStandardizedPoints,
    userOnboardingFeatures,
    getExtendSubscriptionCost,
    extendSubscription,
    getBillingInfo,
    hasActiveLicenses,
    getPaymentMethod,
    paymentMethod,
    extendSubscriptionCost: subscriptionCostResult.data.extendCost,
    hasTrialLicenses,
    page,
    targetStreamId,
    collectionId,
    isTemplateMode,
    changeStream,
    copyTemplateStream,
    onboardingPassedRequest,
    getSavedStreams,
    setCurrentPoint,
    readCurrentPoint,
  });

  const pointCreatePlaceholder = useMemo(() => {
    switch (companyCountry) {
      case Country.By:
        return TEXTS.POINT_CREATE_ADDRESS_PLACEHOLDER_BY;
      case Country.Kz:
        return TEXTS.POINT_CREATE_ADDRESS_PLACEHOLDER_KZ;
      default:
        return TEXTS.POINT_CREATE_ADDRESS_PLACEHOLDER_RU;
    }
  }, [companyCountry]);

  return (
    <ModalScreen
      title={getText(
        page,
        POINT_TEXTS.POINT_CREATE_TITLE,
        POINT_TEXTS.POINT_CREATE_TITLE,
        TEXTS.POINT_CREATE_TITLE,
        isShowHintAboutPrice
      )}
      titlePlacement="left"
      onClose={close}
      contentClassName={isIpad ? styles.PointCreateWithPaymentModalContent : undefined}
      actionButtons={[
        <Button
          elementId="button_create_new_point_modal"
          key="button_create_new_point_modal"
          type={ButtonType.PRIMARY}
          onClick={canAddPointWithPayment ? onPointCreateConfirmModalShow : handleCreateClick}
          isDisabled={isButtonDisabled}
          isLoading={canAddPointWithPayment ? isButtonWithCostLoading : isButtonLoading}>
          {getText(
            page,
            POINT_TEXTS.POINT_CREATE_WITH_PAYMENT_BUTTON_TEXT(subscriptionCostResult.data.extendCost),
            POINT_TEXTS.POINT_CREATE_BUTTON_TEXT,
            TEXTS.POINT_CREATE_BUTTON_TEXT,
            canAddPointWithPayment
          )}
        </Button>,
      ]}>
      <div>
        {isCustomInfoShouldShow && userOnboardingFeaturesLoading && (
          <div className={styles.PointCreateWithPaymentModalHintSkeleton} />
        )}
        {isCustomInfoShouldShow && !userOnboardingFeaturesLoading && (
          <CustomInfo
            textObject={TEXTS.MODAL_INFO}
            link={HELP_CENTER_CONTRACTS_LINK}
          />
        )}
        <Autocomplete
          elementId="input_create_point_modal_address"
          autoComplete="off"
          placeholder={pointCreatePlaceholder}
          value={addressState.value}
          options={addressState.options || []}
          error={createPointError?.message}
          isLoading={addressState.loading}
          onChange={addressState.onChange}
          dropdownPlacement={DropdownPlacement.BOTTOM}
        />
        <Button
          elementId="button_show_create_point_with_payment_hint_modal"
          type={ButtonType.PLAIN}
          className={styles.PointCreateWithPaymentModalHintButton}
          onClick={onPointCreateHintModalShow}>
          {TEXTS.POINT_CREATE_HINT_MODAL_TITLE}
        </Button>

        {isShowHintAboutPrice && (
          <Info className={styles.PointCreateWithPaymentModalHintInfo}>
            {POINT_TEXTS.POINT_CREATE_HINT_COST_MODAL_TITLE}
          </Info>
        )}
      </div>
    </ModalScreen>
  );
};

export default PointCreateModal;
