import { FetchPolicyKeys } from 'domain/api/graphql/types';
import {
  MediaDislikedMediafilesPaginationDocument,
  MediaDislikedMediafilesPaginationQuery,
  MediafileEventTypes,
  StreamPureDislikeMediafileMutationInput,
  useRestoreMediafileMutation,
} from 'domain/api/graphql/generated';
import { ApolloCache } from '@apollo/client';
import { DislikeMediafileResultType } from './types';

const optimisticResponse = {
  __typename: 'Mutation',
  result: {
    __typename: 'StreamPureDislikeMediafileRestoreMutation',
    ok: true,
    errors: null,
  },
} as const;

type VariablesType = { filters: { streamId: string; eventType: string } };

const handleDislikedMediafiles = (cache: ApolloCache<unknown>, variables: VariablesType, mediaFileId: string) => {
  const dislikedMediafiles = cache.readQuery<MediaDislikedMediafilesPaginationQuery>({
    query: MediaDislikedMediafilesPaginationDocument,
    variables,
  });

  if (dislikedMediafiles) {
    const modifiedCache = dislikedMediafiles.streamDislikedMediafiles.result?.filter(
      (el) => el.mediafile.id !== mediaFileId
    );

    cache.writeQuery({
      query: MediaDislikedMediafilesPaginationDocument,
      variables,
      data: {
        streamDislikedMediafiles: {
          ...dislikedMediafiles.streamDislikedMediafiles,
          result: modifiedCache,
          paginationInfo: {
            ...dislikedMediafiles.streamDislikedMediafiles.paginationInfo,
            totalCount: dislikedMediafiles.streamDislikedMediafiles.paginationInfo.totalCount - 1,
            pageSize: dislikedMediafiles.streamDislikedMediafiles.paginationInfo.totalCount - 1,
          },
        },
      },
    });
  }
};

const useExcludeMediafileRestore = () => {
  const [mutation, mutationResult] = useRestoreMediafileMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
    refetchQueries: ['mediaStream', 'mediafilesStream'],
  });

  const removeMediaFileFromCache = (cache: ApolloCache<unknown>, id: number, mediafileId: number) => {
    const streamId = id.toString();
    const mediaFileId = mediafileId.toString();
    const variables = { filters: { streamId, eventType: MediafileEventTypes.Dislike } };

    handleDislikedMediafiles(cache, variables, mediaFileId);
  };

  const request = async (input: StreamPureDislikeMediafileMutationInput) => {
    const mutationResult = await mutation({
      variables: { input },
      optimisticResponse,
      update: (cache) => removeMediaFileFromCache(cache, input.id, input.mediafileId),
    });

    return mutationResult.data?.result?.ok;
  };

  const result: DislikeMediafileResultType = {
    error: mutationResult.error,
    loading: mutationResult.loading,
  };

  return {
    request,
    result,
  };
};

export default useExcludeMediafileRestore;
