import React, { useRef } from 'react';

import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import { TEXTS } from 'application/pages/texts';
import { PaginationInfoType } from 'domain/api/graphql/generated';
import InputSearch, { InputSize } from '@zvuk-b2b/react-uikit/ui/InputSearch';
import InfiniteItemList from '@zvuk-b2b/react-uikit/ui/InfiniteItemList';

import Checkbox, { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';
import classNames from 'classnames';
import PointListCheckbox, { PointListCheckboxProps } from './PointListCheckbox';
import PointListCheckboxItemSkeleton from './PointListCheckbox/PointListCheckboxItemSkeleton';

import styles from './index.module.scss';

export type PointsListCheckboxGroupProps = {
  points: PointsListCheckboxGroupPointType[];
  setPoints: (value: React.SetStateAction<PointsListCheckboxGroupPointType[]>) => void;
  searchText?: string;
  pointsLoading: boolean;
  searchInputPlaceholder: string;
  allPointsCount: number | null;
  paginationInfo: PaginationInfoType;
  onSearchChange: (value: string) => void;
  loadMorePoints: () => void;
  labelPlacement?: LabelPlacement;
  isMulti?: boolean;
  showStreamData?: boolean;
  onSelect?: (value: boolean, currentPoint: PointsListCheckboxGroupPointType) => void;
  onSelectAll?: (value: boolean) => void;
  enableItemHint?: React.ReactNode;
  isAllSelected?: boolean;
  containerClassNames?: string[];
  isSearchDisabled?: boolean;
};

const PointsListCheckboxGroup = ({
  points,
  setPoints,
  searchText,
  paginationInfo,
  pointsLoading,
  searchInputPlaceholder,
  allPointsCount,
  onSearchChange,
  loadMorePoints,
  labelPlacement,
  isMulti = false,
  showStreamData = true,
  onSelect,
  onSelectAll,
  enableItemHint,
  isAllSelected,
  containerClassNames = [],
  isSearchDisabled = false,
}: PointsListCheckboxGroupProps) => {
  const listContainerRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (value: string) => {
    if (listContainerRef && listContainerRef.current?.scrollTop !== 0) {
      listContainerRef.current?.scrollTo(0, 0);
    }

    onSearchChange(value);
  };

  const onChangeCallback = (value: boolean, currentPoint: PointsListCheckboxGroupPointType) => {
    setPoints(
      points.map((point) => ({
        ...point,
        isSelected: point.id === currentPoint.id ? value : point.isSelected,
      }))
    );

    onSelect?.(value, currentPoint);
  };

  const selectAll = () => {
    onSelectAll?.(!isAllSelected);
  };

  const selectedPoints = points.filter((point) => point.isSelected);

  return (
    <div
      className={classNames(styles.PointsListCheckboxGroup, ...containerClassNames)}
      ref={listContainerRef}>
      <div className={styles.PointsListCheckboxGroupStickyHeader}>
        <InputSearch
          elementId="toolbar_input_search"
          size={InputSize.SMALL}
          value={searchText}
          isSearchLoading={pointsLoading || isSearchDisabled}
          placeholder={searchInputPlaceholder}
          containerClassName={styles.PointsListCheckboxGroupSearchInput}
          autoComplete="off"
          onChange={handleSearchChange}
          isDisabled={isSearchDisabled}
        />

        {isMulti && (
          <Checkbox
            elementId="items_list_select_all"
            isChecked={isAllSelected}
            labelPlacement={labelPlacement}
            isStretch
            className={classNames(styles.PointsListCheckboxGroupMultiselectButton)}
            onChange={selectAll}>
            {TEXTS.CERTIFICAT_MODAL_SELECT_ALL_POINTS}
          </Checkbox>
        )}
      </div>

      <div className={classNames(isAllSelected && styles.PointsListCheckboxGroupHidden)}>
        {points.length !== 0 && (
          <InfiniteItemList<PointsListCheckboxGroupPointType, PointListCheckboxProps>
            itemListRenderer={PointListCheckbox}
            itemListRendererProps={{
              itemList: points,
              selectedItems: selectedPoints,
              onChange: onChangeCallback,
              isLoading: pointsLoading,
              showStreamData,
              labelPlacement,
              className: styles.PointsListCheckboxGroupItem,
              enableItemHint,
              parentRef: listContainerRef,
            }}
            items={points}
            itemListSkeleton={<PointListCheckboxItemSkeleton />}
            loadMore={loadMorePoints}
            totalItemsCount={allPointsCount || 0}
            hasNextPage={paginationInfo.hasNextPage}
          />
        )}

        {!pointsLoading && points.length === 0 && searchText?.length !== 0 && (
          <div className={styles.PointsListCheckboxGroupEmpty}>{TEXTS.MODAL_BIND_POINTS_LIST_EMPTY}</div>
        )}
      </div>
    </div>
  );
};

export default PointsListCheckboxGroup;
