import {
  SmartLkLibraryStreamQuerySort,
  useLibrarySubCollectionLazyQuery,
  useLibrarySubCollectionStreamsWithPaginationLazyQuery,
} from 'domain/api/graphql/generated';

const DEFAULT_STREAM_ITEMS = 7;

const useLibraryCollection = () => {
  const [getSubCollectionsRequest] = useLibrarySubCollectionLazyQuery();
  const [getStreamsRequest] = useLibrarySubCollectionStreamsWithPaginationLazyQuery();

  const getSubCollections = (collectionId: string, companyId: string) =>
    getSubCollectionsRequest({
      variables: {
        collectionId,
        companyId,
      },
    });

  const getStreams = (subcollectionId: string, companyId: string) =>
    getStreamsRequest({
      variables: {
        filters: {
          companyId,
          subcollectionId,
        },
        pagination: { page: 0, pageSize: DEFAULT_STREAM_ITEMS },
        sort: SmartLkLibraryStreamQuerySort.IdDesc,
      },
    });

  return {
    getSubCollections,
    getStreams,
  };
};

export default useLibraryCollection;
