import { useCaptchaSettingsQueryLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useCaptchaSettings = () => {
  const [query, queryResult] = useCaptchaSettingsQueryLazyQuery({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async () => {
    await query();
  };

  return {
    request,
    error: queryResult.error,
    loading: queryResult.loading,
    captchaSettings: queryResult.data?.captchaSettings,
  };
};

export default useCaptchaSettings;
