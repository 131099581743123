import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import useKeyDownCallback from 'toolkit/useKeyDownCallback';
import { KeyboardEventCode } from 'toolkit/types';

import styles from './index.module.scss';

export enum InputType {
  TEXT = 'text',
  TEL = 'tel',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum InputSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export type InputProps = {
  type?: InputType;
  size?: InputSize;
  label?: React.ReactNode;
  labelIcon?: React.ReactNode;
  value?: string;
  error?: string | boolean | string[];
  required?: boolean;
  maxLength?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  containerClassName?: string;
  adornmentLeft?: React.ReactNode;
  adornmentRight?: React.ReactNode;
  placeholder?: string;
  elementId: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  autoComplete?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onChange?: (value: string, event: React.SyntheticEvent) => void;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isFullMode?: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
  const {
    label,
    error,
    inputRef,
    value: propValue = '',
    required = false,
    maxLength = Number.MAX_SAFE_INTEGER,
    isDisabled = false,
    isLoading = false,
    type = InputType.TEXT,
    size = InputSize.MEDIUM,
    containerClassName,
    inputClassName,
    labelClassName,
    adornmentLeft,
    adornmentRight,
    placeholder,
    elementId,
    autoComplete = 'on',
    onChange,
    onFocus,
    onBlur,
    onMouseOver,
    onMouseOut,
    onEnterPressed,
    labelIcon,
    isFullMode = false,
  } = props;

  const [value, setValue] = useState<string>(propValue);
  const [localError, setLocalError] = useState<string | boolean | string[] | undefined>(error);
  useEffect(() => setLocalError(error), [error]);

  const hideError = () => setLocalError(undefined);

  useEffect(() => setValue(propValue), [propValue]);

  const onFocusWrapper = (event: React.FocusEvent<HTMLInputElement>) => {
    hideError();
    onFocus?.(event);
  };

  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    hideError();

    const newValue = event.target.value.substr(0, maxLength);
    onChange?.(newValue, event);
    setValue(newValue);
  };

  const onKeyDown = useKeyDownCallback<React.KeyboardEvent<HTMLInputElement>>((event) => onEnterPressed?.(event), {
    include: [KeyboardEventCode.ENTER],
  });

  return (
    <div className={classNames(styles.InputContainer, containerClassName)}>
      {(label || isFullMode) && (
        <label
          htmlFor={elementId}
          className={classNames(
            styles.InputContainerLabel,
            isFullMode && styles.InputContainerLabelFullMode,
            labelClassName
          )}>
          {required && <span data-required>*</span>}
          {label || ''}
          {labelIcon}
        </label>
      )}

      {isLoading ? (
        <div
          className={styles.InputSkeleton}
          data-size={size}
        />
      ) : (
        <div
          className={styles.InputWrapperContainer}
          data-size={size}>
          <input
            type={type}
            data-size={size}
            data-adornment-left={!!adornmentLeft}
            data-adornment-right={!!adornmentRight}
            value={value}
            id={elementId}
            data-testid={elementId}
            ref={inputRef || ref}
            disabled={isDisabled}
            placeholder={placeholder}
            aria-errormessage={localError ? `${elementId}_errormessage` : undefined}
            autoComplete={autoComplete}
            className={classNames(inputClassName)}
            onChange={onChangeWrapper}
            onFocus={onFocusWrapper}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          />

          {adornmentLeft && (
            <div className={classNames(styles.InputWrapperContainerAdornment, styles.InputWrapperContainerAdornmentL)}>
              {adornmentLeft}
            </div>
          )}

          {adornmentRight && (
            <div className={classNames(styles.InputWrapperContainerAdornment, styles.InputWrapperContainerAdornmentR)}>
              {adornmentRight}
            </div>
          )}
        </div>
      )}

      {localError && typeof localError === 'string' && (
        <span
          id={`${elementId}_error_message`}
          data-testid={`${elementId}_error_message`}
          className={styles.InputContainerError}>
          {localError}
        </span>
      )}

      {localError && Array.isArray(localError) && (
        <div
          data-testid={`${elementId}_error_messages`}
          id={`${elementId}_error_messages`}
          className={styles.InputContainerErrors}>
          {localError.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </div>
      )}
    </div>
  );
});

export default Input;
