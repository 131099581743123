import { useSubscriptionCostPresenter } from 'application/presenters';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useBillingRepositoryInjector } from 'application/providers/DIContainerProvider';

const useLicensesModalPresenter = () => {
  const { currentCompany } = useAccountContext();

  const { watchBillingInfo, readUnpaidLicenses } = useBillingRepositoryInjector();

  const { hasInactiveLicenses, totalLicenses } = watchBillingInfo(currentCompany!.id);

  const { licenseCostLoading, getSubscriptionCost } = useSubscriptionCostPresenter();

  const unpaidLicenses = readUnpaidLicenses(currentCompany!.id);

  const unpaidLicensesIds = hasInactiveLicenses ? unpaidLicenses.map((item) => item.id) : [];

  return {
    hasInactiveLicenses,
    totalLicenses,
    unpaidLicenses,
    unpaidLicensesIds,
    licenseCostLoading,
    getSubscriptionCost,
  };
};

export default useLicensesModalPresenter;
