import { useBusinessProfileHideMutation, ZvukProfileHideMutationInput } from 'domain/api/graphql/generated';
import { BaseMutationResultType, FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseBusinessProfileHideType } from './types';

const useBusinessProfileHide = (): UseBusinessProfileHideType => {
  const [hideMutation, hideMutationResult] = useBusinessProfileHideMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukProfileHideMutationInput) => {
    const hideMutationData = await hideMutation({
      variables: {
        input,
      },
      refetchQueries: ['zvukProfileList'],
    });

    return {
      isOk: !!hideMutationData.data?.zvukProfileHide?.ok,
      businessProfile: hideMutationData.data?.zvukProfileHide?.profile,
    };
  };

  const result: BaseMutationResultType = {
    error: hideMutationResult.error,
    loading: hideMutationResult.loading,
    called: hideMutationResult.called,
  };

  return [request, result];
};

export default useBusinessProfileHide;
