import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import { TEXTS } from './texts';
import styles from './index.module.scss';

const PointCreateHintModal: FC<ModalProps[ModalTypes.POINT_CREATE_HINT]> = ({ close }) => (
  <ModalScreen
    title={TEXTS.POINT_CREATE_HINT_MODAL_TITLE}
    hideCloseButton
    onClose={close}
    actionButtons={[
      <Button
        key="button_cancel_point_create_hint"
        elementId="button_cancel_point_create_hint"
        type={ButtonType.PRIMARY}
        onClick={close}>
        {TEXTS.POINT_CREATE_HINT_BUTTON_CANCEL}
      </Button>,
    ]}>
    <div className={styles.PointCreateHintModalDesc}>{TEXTS.POINT_CREATE_HINT_DESCRIPTION}</div>
  </ModalScreen>
);

export default PointCreateHintModal;
