import React, { useEffect } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

const CSS_VAR_SPINNER_COLOR = '--spinner-color';

export type SpinnerProps = {
  elementId?: string;
  className?: string;
  color?: string;
};

const Spinner = ({ elementId, className, color }: SpinnerProps) => {
  useEffect(() => {
    if (!color) return;

    document.documentElement.style.setProperty(CSS_VAR_SPINNER_COLOR, color);
  }, []);

  return (
    <div
      id={elementId}
      data-testid={elementId}
      className={classNames(styles.Spinner, className)}
    />
  );
};

export default Spinner;
