import React, { FC } from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import AnalyticsService, { StreamBindFlowEventNameAMP } from 'application/services/AnalyticsService';
import styles from './index.module.scss';
import { TEXTS } from './texts';

const DEVICE_IMAGE_PATH = '/assets/img/app/video/tv.png';

const DownloadTVAppModal: FC<ModalProps[ModalTypes.DOWNLOAD_TV_APP]> = ({
  onPrevious,
  onConfirmClose,
  onNext,
  close,
}) => {
  const onDownloadClick = () => {
    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_MOBILE_DOWNLOAD_CLICKED);
    onNext?.();
  };

  const handleCancel = () => {
    if (onConfirmClose) {
      onConfirmClose();
    } else {
      close();
    }
  };

  return (
    <ModalScreen
      titlePlacement="left"
      onClose={handleCancel}
      className={styles.DownloadTVAppModal}
      contentClassName={styles.DownloadTVAppModalContent}
      title={TEXTS.MODAL_TITLE}
      containerActionButtonsClassName={styles.DownloadTVAppModalActionButtons}
      actionButtons={[
        <Button
          key="button_tv_app_download_modal_success"
          elementId="button_tv_app_download_modal_success"
          type={ButtonType.PRIMARY}
          onClick={onDownloadClick}>
          {TEXTS.SUCCESS_BUTTON}
        </Button>,
        <Button
          key="button_tv_app_download_modal_cancel"
          elementId="button_tv_app_download_modal_cancel"
          type={ButtonType.SECONDARY}
          onClick={onPrevious}>
          {TEXTS.CANCEL_BUTTON}
        </Button>,
      ]}>
      <div className={styles.DownloadTVAppModalDownloadContent}>
        <img
          src={DEVICE_IMAGE_PATH}
          alt=""
          loading="lazy"
          className={styles.DownloadTVAppModalImageApp}
        />
      </div>
      <div className={styles.text}>
        <div className={styles.textSubtitle}>{TEXTS.SUBTITLE}</div>
        <div className={styles.textDescription}>
          <ol>
            <li>{TEXTS.TURN_ON}</li>
            <li>{TEXTS.SCROLL}</li>
            <li>{TEXTS.CHOOSE}</li>
            <li>{TEXTS.SELECT}</li>
          </ol>
        </div>
      </div>
    </ModalScreen>
  );
};

export default DownloadTVAppModal;
