import React from 'react';

import { DEBUG_PAGE_PATH } from 'application/consts';
import DebugPageWrapper from 'application/pages/DebugPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const debugRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: DEBUG_PAGE_PATH,
        element: <DebugPageWrapper />,
      },
    ],
  },
];
