import React, { FC } from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { TEXTS } from './texts';

import styles from '../index.module.scss';

const DeviceRequestedModal: FC<ModalProps[ModalTypes.DEVICE_REQUESTED]> = ({ onNext, close }) => (
  <ModalScreen
    title={TEXTS.MODAL_TITLE}
    titlePlacement="left"
    onClose={close}
    actionButtons={[
      <Button
        key="button_device_requested_modal_success"
        elementId="button_device_requested_modal_success"
        type={ButtonType.PRIMARY}
        onClick={onNext}>
        {TEXTS.SUCCESS_BUTTON}
      </Button>,
    ]}>
    <div className={styles.DeviceModalRequested}>{TEXTS.DESCRIPTION}</div>
  </ModalScreen>
);

export default DeviceRequestedModal;
