import React from 'react';

import { ErrorsMapType } from 'domain/api/graphql/types';

import { ChangeEventArgsType } from '@zvuk-b2b/react-uikit/ui/FileUploader/types';
import { ImageFileErrorCode } from '@zvuk-b2b/react-uikit/ui/FileUploader/ImageUploader/types';

export enum BusinessProfileFormImagesHintType {
  POSTER = 'POSTER',
  BANNER_WEB = 'BANNER_WEB',
  BANNER_MOBILE = 'BANNER_MOBILE',
}

export type BusinessProfileFormData = {
  companyName: string;
  description: string;
  site?: string;
  poster: ChangeEventArgsType<ImageFileErrorCode> | null;
  avatar: ChangeEventArgsType<ImageFileErrorCode> | null;
  bannerLink?: string;
  bannerWeb?: ChangeEventArgsType<ImageFileErrorCode> | null;
  bannerMobile?: ChangeEventArgsType<ImageFileErrorCode> | null;
  acceptedPrivatePolicy: boolean;
};

export type BusinessProfileFormProps = {
  containerClassName?: string;
  initialFormData?: Partial<BusinessProfileFormData>;
  isLoading: boolean;
  errors?: ErrorsMapType;
  withAcceptedPrivatePolicy?: boolean;
  withRemoveButton?: boolean;
  withDirty?: boolean;
  submitButtonContent?: React.ReactNode;
  onOpenImagesHint?: (type: BusinessProfileFormImagesHintType) => void;
  onSubmit: (formData: BusinessProfileFormData) => void;
  onCancel?: () => void;
};

export type UseBusinessProfileFormControllerProps = {
  initialFormData: BusinessProfileFormProps['initialFormData'];
  errors: BusinessProfileFormProps['errors'];
  onSubmit: BusinessProfileFormProps['onSubmit'];
};

export type BusinessProfileFormDataKeys = keyof BusinessProfileFormData;

export type DirtyFields = Map<BusinessProfileFormDataKeys, boolean>;
