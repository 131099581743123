import { DEFAULT_POINT_STATUSES } from 'application/pages/PointsPage/renderer/PointsToolbar/consts';

import { DefaultFiltersType } from './types';

export const DEFAULT_FILTERS: DefaultFiltersType = {
  query: '',
  companyId: undefined,
};

export const ALL_POINT_STATUSES_COUNT = DEFAULT_POINT_STATUSES.length;

export const DEFAULT_PAGINATION = {
  page: 0,
  pageSize: 100,
};

export const DEFAULT_PAGINATION_INFO = {
  ...DEFAULT_PAGINATION,
  totalCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  totalPages: 0,
};
