import { AudioFile } from './AudioUploader/types';
import { ImageFile } from './ImageUploader/types';
import FileUploaderUtils from './utils';

export const TEXTS = {
  DEFAULT_UPLOAD_BUTTON_TEXT: 'Загрузить',

  DEFAULT_UPLOADED_IMAGE_FILE_DESCRIPTION: ({ name, width, height, size }: ImageFile) =>
    `${name}: Ширина ${width}px, Высота ${height}px, Размер ${FileUploaderUtils.getFileSizeWithUnits(size)}`,

  DEFAULT_UPLOADED_AUDIO_FILE_DESCRIPTION: ({ name, size }: AudioFile) =>
    `${name}: Размер ${FileUploaderUtils.getFileSizeWithUnits(size)}`,

  DEFAULT_IMAGE_FILE_WIDTH_ERROR_TEXT: (width: number) => `Ширина изображения должна быть ${width}px`,
  DEFAULT_IMAGE_FILE_HEIGHT_ERROR_TEXT: (height: number) => `Высота изображения должна быть ${height}px`,

  DEFAULT_IMAGE_FILE_MIN_WIDTH_ERROR_TEXT: (width: number) => `Минимальная ширина изображения - ${width}px`,
  DEFAULT_IMAGE_FILE_MAX_WIDTH_ERROR_TEXT: (width: number) => `Максимальная ширина изображения - ${width}px`,
  DEFAULT_IMAGE_FILE_MIN_HEIGHT_ERROR_TEXT: (height: number) => `Минимальная высота изображения - ${height}px`,
  DEFAULT_IMAGE_FILE_MAX_HEIGHT_ERROR_TEXT: (height: number) => `Максимальная высота изображения - ${height}px`,

  DEFAULT_FILE_SIZE_ERROR_TEXT: (size: number) => `Размер файла должен быть ${size} байт`,
  DEFAULT_FILE_MIN_SIZE_ERROR_TEXT: (size: number) =>
    `Минимальный размер файла - ${FileUploaderUtils.getFileSizeWithUnits(size)}`,
  DEFAULT_FILE_MAX_SIZE_ERROR_TEXT: (size: number) =>
    `Максимальный размер файла - ${FileUploaderUtils.getFileSizeWithUnits(size)}`,
};
