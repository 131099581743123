import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { BusinessProfileFormImagesHintType } from 'application/pages/BusinessProfilePage/renderer/BusinessProfileForm/types';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import { TEXTS } from './texts';
import Poster from './Poster';
import BannerWeb from './BannerWeb';
import BannerMobile from './BannerMobile';

import styles from './index.module.scss';

const getTitle = (hintType: BusinessProfileFormImagesHintType) => {
  if (hintType === BusinessProfileFormImagesHintType.BANNER_WEB) {
    return TEXTS.BUSINESS_PROFILE_IMAGES_HINT_MODAL_BANNER_WEB_TITLE;
  }

  if (hintType === BusinessProfileFormImagesHintType.BANNER_MOBILE) {
    return TEXTS.BUSINESS_PROFILE_IMAGES_HINT_MODAL_BANNER_MOBILE_TITLE;
  }

  return TEXTS.BUSINESS_PROFILE_IMAGES_HINT_MODAL_POSTER_TITLE;
};

const BusinessProfileImagesHintModal: FC<ModalProps[ModalTypes.BUSINESS_PROFILE_IMAGES_HINT]> = ({
  hintType = BusinessProfileFormImagesHintType.POSTER,
  close,
}) => (
  <ModalScreen
    title={getTitle(hintType)}
    titlePlacement="left"
    onClose={close}
    contentClassName={styles.BusinessProfileImagesHintModalContent}
    withBackground
    actionButtons={[
      <Button
        key="business_profile_modal_button_close"
        elementId="business_profile_modal_button_close"
        type={ButtonType.PRIMARY}
        onClick={close}>
        {TEXTS.BUSINESS_PROFILE_IMAGES_HINT_MODAL_BUTTON_TEXT}
      </Button>,
    ]}>
    {hintType === BusinessProfileFormImagesHintType.POSTER && <Poster />}
    {hintType === BusinessProfileFormImagesHintType.BANNER_WEB && <BannerWeb />}
    {hintType === BusinessProfileFormImagesHintType.BANNER_MOBILE && <BannerMobile />}
  </ModalScreen>
);

export default BusinessProfileImagesHintModal;
