import { useLayoutEffect, useState } from 'react';

const useMediaQueryChangeListener = (mediaQueryList: MediaQueryList) => {
  const [isMediaQueryListMatched, setIsMediaQueryListMatched] = useState<boolean>(mediaQueryList.matches);

  useLayoutEffect(() => {
    const handler = () => {
      setIsMediaQueryListMatched(mediaQueryList.matches);
    };

    try {
      const isAddEventListenerExists = !!mediaQueryList.addEventListener;

      if (isAddEventListenerExists) {
        mediaQueryList.addEventListener('change', handler);
      } else {
        mediaQueryList.addListener(handler);
      }
    } catch (error) {
      console.error(`${mediaQueryList} does not support change event listeners. Error: ${error}`);
    }

    return () => {
      try {
        const isRemoveEventListenerExists = !!mediaQueryList.removeEventListener;
        if (isRemoveEventListenerExists) {
          mediaQueryList.removeEventListener('change', handler);
        } else {
          mediaQueryList.removeListener(handler);
        }
      } catch (error) {
        console.error(`${mediaQueryList} does not support change event listeners. Error: ${error}`);
      }
    };
  }, [mediaQueryList]);

  return isMediaQueryListMatched;
};

export default useMediaQueryChangeListener;
