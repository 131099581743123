import Info, { InfoTypes } from '@zvuk-b2b/react-uikit/ui/Info';
import React, { FC } from 'react';
import style from './CustomInfo.module.scss';

interface CustomInfoProps {
  textObject: {
    BOLD_TEXT: string;
    MAIN_TEXT: string;
    LINK_TEXT: string;
  };
  link: string;
}

const CustomInfo: FC<CustomInfoProps> = (props) => {
  const { link, textObject } = props;
  const { BOLD_TEXT, LINK_TEXT, MAIN_TEXT } = textObject;

  return (
    <Info
      type={InfoTypes.ERROR}
      className={style.Container}>
      <span className={style.ContainerBold}>{BOLD_TEXT}</span>
      &nbsp;
      {MAIN_TEXT}
      &nbsp;
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer">
        {LINK_TEXT}
      </a>
    </Info>
  );
};

export default CustomInfo;
