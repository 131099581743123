import { useMediaAdCampaignUpdateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import { UseAdCampaignUpdateResultType, UseAdCampaignUpdateType, AdCampaignType } from './types';

const useAdCampaignUpdate = (): UseAdCampaignUpdateType => {
  const [updateMutation, updateMutationResult] = useMediaAdCampaignUpdateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (campaign: AdCampaignType) => {
    const newAdCampaign = await updateMutation({
      variables: {
        input: {
          adCompanyId: campaign.id,
          startCompany: campaign.timingSettings.startDate,
          endCompany: campaign.timingSettings.endDate,
          startDayTime: campaign.timingSettings.dayStartTime,
          endDayTime: campaign.timingSettings.dayEndTime,
          interval: campaign.interval,
          period: campaign.period,
          pointIds: campaign.points.map(({ id }) => id),
          prevMediafileRequired: campaign.prevMediafileRequired,
          title: campaign.title,
          type: campaign.type,
          days: DateUtils.getWeekDays(campaign.timingSettings.weekdays),
        },
      },
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
    });

    return !!newAdCampaign.data?.result?.ok;
  };

  const result: UseAdCampaignUpdateResultType = {
    error: updateMutationResult.error,
    loading: updateMutationResult.loading,
    called: updateMutationResult.called,
    data: {
      adCampaign: updateMutationResult.data?.result?.adCompany as AdCampaignType,
    },
  };

  return [request, result];
};

export default useAdCampaignUpdate;
