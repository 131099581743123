import React from 'react';

import { LOGIN_PAGE_PATH } from 'application/consts';
import LoginPage from 'application/pages/LoginPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const loginRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: LOGIN_PAGE_PATH,
        element: <LoginPage />,
      },
    ],
  },
];
