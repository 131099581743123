import { useMemo } from 'react';

import { StreamInfoTypeFragment, useMediaStreamLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useStream = () => {
  const [query, queryResult] = useMediaStreamLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });
  const request = async (id: string) => {
    const result = await query({
      variables: {
        id,
      },
    });

    return result;
  };

  const stream: StreamInfoTypeFragment | undefined = queryResult?.data?.result || undefined;

  return useMemo(
    () => ({
      loading: queryResult?.loading,
      called: queryResult?.called,
      error: queryResult?.error,
      request,
      stream,
    }),
    [stream, queryResult?.loading, queryResult?.error]
  );
};

export default useStream;
