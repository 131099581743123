import { UserFeatures } from 'domain/api/graphql/generated';
import useUserFeaturesOnboarding from 'domain/media/useUserFeaturesOnboarding';

import { UseAccountRepositoryType } from './types';

const useAccountRepository = (): UseAccountRepositoryType => {
  const { items: userOnboardingFeatures, loading: userOnboardingFeaturesLoading } = useUserFeaturesOnboarding({
    features: [UserFeatures.WarningStreamFirstStart],
  });

  return {
    userOnboardingFeatures,
    userOnboardingFeaturesLoading,
  };
};

export default useAccountRepository;
