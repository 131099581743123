import React from 'react';

import Info from '@zvuk-b2b/react-uikit/ui/Info';

import { TEXTS } from '../texts';

import styles from './index.module.scss';

export type AboutEditingInfoProps = {
  isSourceZvukBusiness: boolean;
};

const AboutEditingInfo = ({ isSourceZvukBusiness }: AboutEditingInfoProps) => (
  <Info className={styles.AboutEditingInfo}>
    {isSourceZvukBusiness
      ? TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_ZVUK_BUSINESS_WARNING
      : TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_ZVUK_WARNING}
  </Info>
);

export default AboutEditingInfo;
