import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import Input, { InputSize } from '@zvuk-b2b/react-uikit/ui/Input';
import IconVolume2 from '@zvuk-b2b/react-uikit/ui/icons/Volume2';
import IconVolumeX from '@zvuk-b2b/react-uikit/ui/icons/VolumeX';

import Popper, { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import ProgressSlider from '@zvuk-b2b/react-uikit/ui/ProgressSlider';
import IconVolume1 from '@zvuk-b2b/react-uikit/ui/icons/Volume1';
import styles from './index.module.scss';

export type VolumeSliderProps = React.PropsWithChildren<{
  initialVolume?: number;
  className?: string;
  elementId: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (volume: number) => void;
  withDigits?: boolean;
  withMute?: boolean;
  hint?: string;
  minValue?: number;
  maxValue?: number;
}>;

const DEFAULT_MIN_VOLUME = 0;
const DEFAULT_MAX_VOLUME = 100;
const DEFAULT_STEP = 1;
const DEFAULT_WAIT = 2000;

const VolumeSlider = ({
  className,
  elementId,
  isDisabled,
  isLoading,
  onChange,
  initialVolume = DEFAULT_MAX_VOLUME,
  withDigits = true,
  withMute = true,
  hint,
  minValue = DEFAULT_MIN_VOLUME,
  maxValue = DEFAULT_MAX_VOLUME,
}: VolumeSliderProps) => {
  const [volume, setVolume] = useState<number>(initialVolume);
  const [muteVolume, setMuteVolume] = useState(!initialVolume);

  const sliderRef = useRef<HTMLInputElement | null>(null);
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const refVolume = useRef<number>(initialVolume);

  const handleMuteClick = () => {
    const newMuteVolume = !muteVolume;
    setMuteVolume(newMuteVolume);
    setVolume(newMuteVolume ? 0 : 100);
    onChange(newMuteVolume ? 0 : 100);
  };

  const handleChangeVolumeByInput = (value: string) => {
    const numValue = Math.min(Math.max(+value, minValue), maxValue);

    if (Number.isNaN(numValue) || numValue < 0 || numValue > 100) {
      return;
    }

    setVolume(numValue);
    setMuteVolume(numValue === 0);

    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    timerId.current = setTimeout(() => onChange(numValue), DEFAULT_WAIT);
  };

  const handleChangeVolumeByThumb = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    refVolume.current = value;
    setVolume(value);
  };

  const handleChangeVolumeByEnterPressed = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    onChange(volume);
  };

  const addSynteticOnChange = () => {
    const handleChangeVolume = () => {
      setVolume(refVolume.current);
      setMuteVolume(refVolume.current === 0);
      onChange(refVolume.current);
      document.removeEventListener('touchend', handleChangeVolume, false);
      document.removeEventListener('mouseup', handleChangeVolume, false);
    };

    if (navigator.maxTouchPoints > 0) {
      document.addEventListener('touchend', handleChangeVolume, false);
    } else {
      document.addEventListener('mouseup', handleChangeVolume, false);
    }
  };

  const handleTouchStart = (_: React.TouchEvent<HTMLInputElement>) => {
    addSynteticOnChange();

    if (sliderRef.current) {
      sliderRef.current.style.setProperty('--size-thumb-scale', '2');
    }
  };

  const handleTouchEnd = (_: React.TouchEvent<HTMLInputElement>) => {
    if (sliderRef.current) {
      sliderRef.current.style.setProperty('--size-thumb-scale', '1');
    }
  };

  useEffect(() => {
    if (sliderRef.current && !isLoading) {
      sliderRef.current.style.setProperty('--range-progress', `${volume}%`);
    }
  }, [volume, isLoading]);

  useEffect(() => {
    setVolume(initialVolume);
  }, [initialVolume]);

  if (isLoading) {
    return <div className={styles.VolumeSliderSkeleton} />;
  }

  const IconVolume = volume <= 50 ? IconVolume1 : IconVolume2;

  const progressSlider = (
    <ProgressSlider
      ref={sliderRef}
      id={elementId}
      min={minValue}
      max={maxValue}
      step={DEFAULT_STEP}
      value={volume}
      onInput={handleChangeVolumeByThumb}
      onMouseDown={addSynteticOnChange}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      disabled={isDisabled}
    />
  );

  return (
    <div
      className={classNames(styles.VolumeSlider, className, { [styles.VolumeSliderWithPopper]: hint })}
      data-disabled={isDisabled}>
      {withMute && (
        <IconButton
          elementId="icon_button_volume_slider_mute"
          onClick={handleMuteClick}>
          {muteVolume || volume === 0 ? (
            <IconVolumeX className={styles.VolumeSliderMuteButtonIcon} />
          ) : (
            <IconVolume className={styles.VolumeSliderMuteButtonIcon} />
          )}
        </IconButton>
      )}
      {hint ? (
        <Popper
          placement={HintPlacement.PLACEMENT_BOTTOM}
          containerClassName={styles.VolumeSliderPopperContainer}
          triggerNode={progressSlider}
          triggerNodeRef={sliderRef}>
          {hint}
        </Popper>
      ) : (
        progressSlider
      )}
      {withDigits && (
        <Input
          elementId="input_volume_slider_field"
          containerClassName={styles.VolumeSliderInput}
          size={InputSize.SMALL}
          maxLength={3}
          value={volume.toFixed(0)}
          onChange={handleChangeVolumeByInput}
          onEnterPressed={handleChangeVolumeByEnterPressed}
        />
      )}
    </div>
  );
};

export default VolumeSlider;
