import React from 'react';

import IconRenderer, { IconProps } from 'ui/icons/IconRenderer';

import icon from './skip-forward.svg';

const IconSkipForward = ({ className, style }: IconProps) => (
  <IconRenderer
    iconHtml={icon}
    className={className}
    style={style}
  />
);

export default IconSkipForward;
