import React, { FC } from 'react';
import Text from '@zvuk-b2b/react-uikit/ui/Text';
import { StreamMainToolBarFiltersModalChip } from '../StreamMainToolBarFiltersModalChip';
import styles from '../index.module.scss';
import { StreamMainToolBarFiltersModalSectionProps, StreamTagType } from '../../types';

const StreamMainToolBarFiltersModalSection: FC<StreamMainToolBarFiltersModalSectionProps> = (props) => {
  const { id, title, tags, selectedTags, setTagActive, setTagInactive } = props;

  const renderTags = (tag: StreamTagType) => {
    if (!tag) return null;

    return (
      <StreamMainToolBarFiltersModalChip
        id={tag.id}
        key={tag.id}
        title={tag.title}
        selectedTags={selectedTags}
        setTagActive={setTagActive}
        setTagInactive={setTagInactive}
      />
    );
  };

  return (
    <div
      key={id}
      className={styles.StreamToolBarModalListItem}>
      <Text
        elementId="filter_tags_section"
        className={styles.StreamToolBarModalListItemText}
        as="span">
        {title}
      </Text>
      <div className={styles.StreamToolBarModalListItemChips}>{tags.map(renderTags)}</div>
    </div>
  );
};

export default StreamMainToolBarFiltersModalSection;
