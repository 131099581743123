import React from 'react';

import { REPORTS_PAGE_PATH } from 'application/consts';
import ReportsPageWrapper from 'application/pages/ReportsPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const reportsRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: REPORTS_PAGE_PATH,
        element: <ReportsPageWrapper />,
      },
    ],
  },
];
