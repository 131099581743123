import React from 'react';

import { useAccountContext } from 'application/providers/AccountProvider';
import ProfileButton from 'application/components/ProfileButton';
import PhoneUtils from 'application/utils/PhoneUtils';

import styles from './index.module.scss';

export type ProfileInfoModalProps = {
  onChangePasswordClick: () => void;
  onLogoutClick: () => void;
};

const ProfileInfoModal: React.FC = () => {
  const { currentUser } = useAccountContext();

  return (
    <div className={styles.ProfileInfo}>
      <div>
        <ProfileButton />
      </div>
      <div className={styles.ProfileInfoContent}>
        <div
          className={styles.ProfileInfoTitle}
          data-testid="profile_info_modal_email">
          {currentUser?.email}
        </div>
        <div className={styles.ProfileInfoSubtitle}>{PhoneUtils.formatPhoneNumber(currentUser?.phone || '')}</div>
      </div>
    </div>
  );
};

export default ProfileInfoModal;
