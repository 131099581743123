import { useState } from 'react';

import {
  PlayInstantAdcampaignMutationInput,
  useMediaAdCampaignInstantLaunchMutation,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseAdCampaignInstantLaunchResultType, UseAdCampaignInstantLaunchType } from './types';

const useAdCampaignInstantLaunch = (): UseAdCampaignInstantLaunchType => {
  const [aborter, setAborter] = useState(new AbortController());

  const [launchMutation, launchMutationResult] = useMediaAdCampaignInstantLaunchMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
    context: {
      fetchOptions: {
        signal: aborter.signal,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const request = async (input: PlayInstantAdcampaignMutationInput) => {
    const result = await launchMutation({
      variables: {
        input,
      },
    });

    return !!result.data?.playInstantAdcampaign?.ok;
  };

  const abortRequest = () => {
    aborter.abort();
    setAborter(new AbortController());
  };

  const result: UseAdCampaignInstantLaunchResultType = {
    error: launchMutationResult.error,
    loading: launchMutationResult.loading,
    called: launchMutationResult.called,
  };

  return { request, result, abortRequest };
};

export default useAdCampaignInstantLaunch;
