import { useEffect } from 'react';

import { useAuthContext, LocalAccessRoles } from 'application/providers/AuthProvider';
import { useParams, useURLSearchParams } from 'application/providers/RouterProvider';
import { REGISTER_PAGE_PATH } from 'application/pages/RegisterPage';
import { IApplicationPage } from 'application/pages/types';
import { REDIRECT_PAGE_PATH } from 'application/consts';
import AnalyticsService, {
  EventNameAMP,
  EventNameGTM,
  LoginPathValues,
  ParamNameAMP,
  ParamNameGTM,
} from 'application/services/AnalyticsService';

import useUtmMarks from 'domain/auth/useUtmMarks';
import useCurrentUser from 'domain/auth/useCurrentUser';

import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';

export enum RedirectQueryKeys {
  TOKEN = 'token',
  MODE = 'mode',
}

export enum RedirectModeQueryValues {
  REGISTER = 'registration',
  LOGIN = 'login',
}

export type RedirectPageParamsType = {
  from?: string;
};

const RedirectPage: IApplicationPage = () => {
  const authContext = useAuthContext();

  const [utmRequest] = useUtmMarks();
  const [userRequest] = useCurrentUser();

  const pathParams = useParams<RedirectPageParamsType>();
  const [authToken, mode] = useURLSearchParams([RedirectQueryKeys.TOKEN, RedirectQueryKeys.MODE]);

  useEffect(() => {
    if (mode === RedirectModeQueryValues.REGISTER) {
      AnalyticsService.event(EventNameAMP.USER_SIGNED_UP);

      AnalyticsService.eventGTM(EventNameGTM.UI_ACTION, {
        [ParamNameGTM.UI_ACTION_KEY]: EventNameAMP.USER_SIGNED_UP,
        [ParamNameGTM.ACCOUNT_CREATED_DATE]: DateUtils.getDate(),
      });
    }

    if (mode === RedirectModeQueryValues.LOGIN) {
      AnalyticsService.event(EventNameAMP.USER_LOGGED_IN, {
        [ParamNameAMP.LOG_IN_PATH]: LoginPathValues.SBBID,
      });
    }
  }, [mode]);

  const initUserData = async () => {
    authContext.setAuthData({ authToken });
    const userData = await userRequest();
    authContext.setAuthData({ authToken, userData });

    if (mode === RedirectModeQueryValues.LOGIN) {
      AnalyticsService.eventGTM(EventNameGTM.AUTH_SUCCESS, {
        [ParamNameGTM.ACCOUNT_CREATED_DATE]: authContext.userData?.mainCompany?.created,
      });
    }
  };

  useEffect(() => {
    if (authToken) {
      initUserData();
    }

    return () => {
      const isRedirectFromRegistration = pathParams.from === encodeURIComponent(REGISTER_PAGE_PATH);
      if (authToken && isRedirectFromRegistration) {
        utmRequest();
      }
    };
  }, [authToken, authContext.setAuthData]);

  return null;
};

RedirectPage.config = {
  routes: [{ path: REDIRECT_PAGE_PATH }],
  access: [LocalAccessRoles.ANONYMOUS],
};

export default RedirectPage;
