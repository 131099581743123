import React from 'react';
import classNames from 'classnames';

import CommunicationUtils from 'application/utils/CommunicationUtils';

import { Country } from 'domain/api/graphql/generated';
import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';
import { ButtonSize, ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import { TEXTS } from './texts';

import styles from './index.module.scss';

type ConnectWithManagerButtonProps = {
  className?: string;
  country?: Country;
  size?: ButtonSize;
  onClickCallback?: () => void;
  type?: ButtonType;
};

const ConnectWithManagerButton = ({
  className,
  country,
  size = ButtonSize.MEDIUM,
  type = ButtonType.PRIMARY,
  onClickCallback,
}: ConnectWithManagerButtonProps) => {
  const whatsAppLink = CommunicationUtils.getWhatsAppLink(country);
  const onClick = () => {
    AnalyticsService.event(EventNameAMP.SUPPORT_REQUESTED);
    onClickCallback?.();
  };

  return (
    <a
      key="button_manager_connect"
      data-testid="button_manager_connect"
      onClick={onClick}
      className={classNames(styles.ConnectWithManagerButton, className)}
      target="_blank"
      href={whatsAppLink}
      data-size={size}
      data-type={type}
      rel="noreferrer">
      {TEXTS.CONNECT_WITH_MANAGER_BUTTON_TEXT}
    </a>
  );
};

export default ConnectWithManagerButton;
