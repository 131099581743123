import {
  parse,
  format,
  startOfDay,
  startOfMonth,
  endOfDay,
  endOfMonth,
  addMinutes,
  addSeconds,
  addMonths,
  subMonths,
  subDays,
  subYears,
  differenceInMinutes,
  differenceInSeconds,
  differenceInHours,
  differenceInCalendarMonths,
  isBefore,
  isAfter,
  isValid,
  getDate,
  getYear,
  getMonth,
  intervalToDuration,
  formatDuration,
} from 'date-fns';
import ruRuLocale from 'date-fns/locale/ru';

import { DayNumbers, Days, WeekDaysType } from './types';

export const DATE_PATTERN = new RegExp('(0?[0-9]|1[012])[.](0?[0-9]|[12][0-9]|3[01])[.](0000|(19|20)?\\d\\d)');

export const DAYS_TEXTS = {
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  sunday: 'Вс',
};

export const TIME_TEXTS = {
  HOUR_SHORT: 'ч',
  MINUTE_SHORT: 'мин',
  SECOND_SHORT: 'сек',
};

class DateUtils {
  static NOW = new Date();

  static START_TODAY = startOfDay(DateUtils.NOW);

  static END_TODAY = endOfDay(DateUtils.NOW);

  static HOURS_IN_DAY = 24;

  static SECONDS_IN_MINUTE = 60;

  static MINUTES_IN_HOUR = 60;

  static MILLISECONDS_IN_HOUR = 1000 * this.SECONDS_IN_MINUTE * this.MINUTES_IN_HOUR;

  static MILLISECONDS_IN_DAY = this.MILLISECONDS_IN_HOUR * this.HOURS_IN_DAY;

  static zeroPad = (num: number) => String(num).padStart(2, '0');

  static getFormatDateFrom(date: Date, formatString = 'dd MMMM yyyy HH:mm'): string {
    try {
      return format(date, formatString, { locale: ruRuLocale });
    } catch (e) {
      return '';
    }
  }

  static getFormatTimeFrom = (time: string) => {
    if (!time) return undefined;

    const [hh, mm] = time.split(':');
    return `${hh || ''}:${mm || ''}`;
  };

  static getStartOfDate(date: Date): Date {
    const offset = date.getTimezoneOffset();
    return addMinutes(startOfDay(date), offset < 0 ? -offset : offset);
  }

  static getStartOfDay(date: Date): Date {
    return startOfDay(date);
  }

  static getStartOfMonth(date: Date): Date {
    const offset = date.getTimezoneOffset();
    return addMinutes(startOfMonth(date), offset < 0 ? -offset : offset);
  }

  static getEndOfDate(date: Date): Date {
    const offset = date.getTimezoneOffset();
    return addMinutes(endOfDay(date), offset < 0 ? -offset : offset);
  }

  static getEndOfDay(date: Date): Date {
    return endOfDay(date);
  }

  static getEndOfMonth(date: Date): Date {
    const offset = date.getTimezoneOffset();
    return addMinutes(endOfMonth(date), offset < 0 ? -offset : offset);
  }

  static subtractDaysFromStartToday(dayCount: number): Date {
    return subDays(DateUtils.START_TODAY, dayCount);
  }

  static subtractYearsFromStartToday(yearsCount: number): Date {
    return subYears(DateUtils.START_TODAY, yearsCount);
  }

  static differenceInMinutes(startDate: Date, endDate: Date): number {
    return differenceInMinutes(startDate, endDate);
  }

  static differenceInSeconds(startDate: Date, endDate: Date): number {
    return differenceInSeconds(startDate, endDate);
  }

  static differenceInHours(startDate: Date, endDate: Date): number {
    return differenceInHours(startDate, endDate);
  }

  static differenceInCalendarMonths(startDate: Date, endDate: Date): number {
    return differenceInCalendarMonths(startDate, endDate);
  }

  static addMonths(date: Date, amount: number): Date {
    return addMonths(date, amount);
  }

  static addSeconds(date: Date, amount: number): Date {
    return addSeconds(date, amount);
  }

  static subMonths(date: Date, amount: number): Date {
    return subMonths(date, amount);
  }

  static parse(value: string, format: string = 'dd/MM/yyyy'): Date {
    return parse(value, format, DateUtils.NOW, { locale: ruRuLocale });
  }

  static isDateBefore(startDate: Date, endDate: Date): boolean {
    return isBefore(startDate, endDate);
  }

  static isDateAfter(startDate: Date, endDate: Date): boolean {
    return isAfter(startDate, endDate);
  }

  static isValid(date: Date): boolean {
    return isValid(date);
  }

  static getFullDate(value: string): string {
    const [dd, mm, year = ''] = value.split('.');
    const newYear = year.padStart(4, '2020');
    return `${dd}.${mm}.${newYear}`;
  }

  static getDaysInMonth(month: number, year: number): number {
    const date = new Date(year, month, 0);
    return date.getDate();
  }

  static validateDate = (date: string) => {
    if (!date.match(DATE_PATTERN)) {
      return false;
    }

    const [dd, mm, yyyy] = date.split('.');

    if (yyyy.length < 4) {
      return false;
    }

    if (+dd > DateUtils.getDaysInMonth(+mm, +yyyy) || +mm > 12 || +yyyy > 9999) {
      return false;
    }

    if (+dd < 1 || +mm < 1 || +yyyy < 0) {
      return false;
    }

    return true;
  };

  static getDate = (date: number | Date = DateUtils.NOW) => getDate(date);

  static getYear = (date: number | Date = DateUtils.NOW) => getYear(date);

  static getMonth = (date: number | Date = DateUtils.NOW) => getMonth(date);

  static msToHMS = (ms: number, format: ['minutes', 'seconds'] | ['hours', 'minutes', 'seconds']): string => {
    const duration = intervalToDuration({ start: 0, end: ms });

    return formatDuration(duration, {
      format,
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance: (_token, count) => DateUtils.zeroPad(count),
      },
    });
  };

  static convertWeekdaysToArray = (weekdays: Record<string, boolean | string>) => {
    const weekdaysSorted = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const weekdaysSet = new Set(weekdaysSorted);

    return Object.keys(weekdays).reduce<number[]>((days, day) => {
      if (weekdaysSet.has(day) && weekdays[day]) {
        days.push(weekdaysSorted.indexOf(day) + 1);
      }
      return days;
    }, []);
  };

  static isMoreThenDay = (firstDate: Date, secondDate: Date) => {
    const diff = DateUtils.differenceInHours(firstDate, secondDate);

    return Math.abs(diff) >= DateUtils.HOURS_IN_DAY;
  };

  static getDaysLeft = (
    lengthMinutes: number | undefined = 1,
    lengthDays: number | undefined = 1,
    startedAt: string | number = ''
  ): number => {
    const periodStepsDelta = (lengthMinutes || 1) / (lengthDays || 1);
    const currentDeltaInMinutes = DateUtils.differenceInMinutes(DateUtils.NOW, new Date(startedAt));
    const trialMinutesLeft = (lengthMinutes || 1) - (currentDeltaInMinutes || 0);

    return Math.max(Math.round(trialMinutesLeft / periodStepsDelta), 0);
  };

  static getWeekDays = (weekdays: WeekDaysType): number[] => {
    const keys = Object.keys(weekdays);
    const result: number[] = [];

    keys.forEach((key) => {
      const day = key as Days;
      if (weekdays[day] && typeof weekdays[day] !== 'string') {
        result.push(DayNumbers[day]);
      }
    });

    return result.sort();
  };

  static durationToShortString = (duration: number): string => {
    const [hours, minutes, seconds] = this.msToHMS(duration, ['hours', 'minutes', 'seconds']).split(':').map(Number);
    const dict = [TIME_TEXTS.HOUR_SHORT, TIME_TEXTS.MINUTE_SHORT, TIME_TEXTS.SECOND_SHORT];
    const map = { hours, minutes, seconds };
    return Object.values(map)
      .map((value, index) => {
        const text = dict[index];
        return value > 0 ? `${value} ${text}` : '';
      })
      .join(' ');
  };
}
export default DateUtils;
