import { useCallback, useState, useMemo, useEffect } from 'react';

import FileUploaderUtils from '@zvuk-b2b/react-uikit/ui/FileUploader/utils';

import {
  BusinessProfileFormData,
  BusinessProfileFormDataKeys,
  DirtyFields,
  UseBusinessProfileFormControllerProps,
} from './types';
import { DEFAULT_BUSINESS_PROFILE_FORM_DATA } from './consts';
import { TEXTS } from './texts';

const useBusinessProfileFormController = ({
  initialFormData,
  errors,
  onSubmit,
}: UseBusinessProfileFormControllerProps) => {
  const [formDirtyFields, setFormDirtyFields] = useState<DirtyFields>(new Map());
  const [formErrors, setFormErrors] = useState<Map<BusinessProfileFormDataKeys, string>>(new Map());
  const [externalErrors, setExternalErrors] = useState<Map<BusinessProfileFormDataKeys, string[]>>(new Map());
  const [hasExternalErrors, setHasExternalErrors] = useState<boolean>(false);

  const shouldAvatarBeRelative =
    !!formErrors.get('poster') || !!externalErrors.get('poster') || !formDirtyFields.get('poster');

  const isBannerLinkEnabled = formDirtyFields.get('bannerWeb') && formDirtyFields.get('bannerMobile');

  const initialValues = useMemo(
    () => ({
      ...initialFormData,
      companyName: initialFormData?.companyName || DEFAULT_BUSINESS_PROFILE_FORM_DATA.companyName,
      description: initialFormData?.description || DEFAULT_BUSINESS_PROFILE_FORM_DATA.description,
      poster: initialFormData?.poster || DEFAULT_BUSINESS_PROFILE_FORM_DATA.poster,
      avatar: initialFormData?.avatar || DEFAULT_BUSINESS_PROFILE_FORM_DATA.avatar,
      acceptedPrivatePolicy:
        initialFormData?.acceptedPrivatePolicy || DEFAULT_BUSINESS_PROFILE_FORM_DATA.acceptedPrivatePolicy,
      bannerLink: isBannerLinkEnabled ? initialFormData?.bannerLink : DEFAULT_BUSINESS_PROFILE_FORM_DATA.bannerLink,
    }),
    [initialFormData, DEFAULT_BUSINESS_PROFILE_FORM_DATA, isBannerLinkEnabled]
  );

  const getUploadedImageFileDescription = useCallback(
    (file: File) => {
      const name = file.name;
      const size = FileUploaderUtils.getFileSizeWithUnits(file.size);

      return TEXTS.BUSINESS_PROFILE_FORM_COMPANY_POSTER_DESCRIPTION_ERROR(name, size);
    },
    [TEXTS]
  );

  const handleFieldDirtyChange = (
    field: BusinessProfileFormDataKeys,
    value: boolean,
    getValues: (fieldName?: string) => any
  ) => {
    const newFormDirtyFields = new Map(formDirtyFields);
    newFormDirtyFields.set(field, value);

    const formValues = getValues();

    if (externalErrors.has(field)) {
      const newExternalErrors = new Map(externalErrors);
      newExternalErrors.delete(field);
      setExternalErrors(newExternalErrors);
    }

    if (formErrors.has(field)) {
      const newFormErrors = new Map(formErrors);
      newFormErrors.delete(field);
      setFormErrors(newFormErrors);
    }

    if (formValues.bannerWeb?.files?.length > 0) {
      newFormDirtyFields.set('bannerWeb', true);
    }

    if (formValues.bannerMobile?.files?.length > 0) {
      newFormDirtyFields.set('bannerMobile', true);
    }

    setFormDirtyFields(newFormDirtyFields);
  };

  const handleFieldError = useCallback(
    (field: string | BusinessProfileFormDataKeys, message: string) => {
      const newFormErrors = new Map(formErrors);
      newFormErrors.set(field as BusinessProfileFormDataKeys, message);
      setFormErrors(newFormErrors);
    },
    [formErrors, setFormErrors]
  );

  const handleSubmit = useCallback(
    (formData: BusinessProfileFormData) => {
      onSubmit(formData);
      setHasExternalErrors(false);
    },
    [onSubmit]
  );

  useEffect(() => {
    if (errors && !hasExternalErrors) {
      const newExternalErrors: Map<BusinessProfileFormDataKeys, string[]> = new Map();
      Object.keys(errors).forEach((key) => {
        newExternalErrors.set(key as BusinessProfileFormDataKeys, errors[key]);
      });

      setExternalErrors(newExternalErrors);
      setHasExternalErrors(true);
    }
  }, [errors]);

  useEffect(() => {
    const newFormDirtyFields = new Map(formDirtyFields);

    if (initialFormData && initialFormData.bannerWeb && initialFormData.bannerWeb.files.length !== 0) {
      newFormDirtyFields.set('bannerWeb', true);
    }

    if (initialFormData && initialFormData.bannerMobile && initialFormData.bannerMobile.files.length !== 0) {
      newFormDirtyFields.set('bannerMobile', true);
    }

    setFormDirtyFields(newFormDirtyFields);
  }, [initialFormData]);

  return {
    initialValues,
    shouldAvatarBeRelative,
    isBannerLinkEnabled,
    formErrors,
    externalErrors,
    getUploadedImageFileDescription,
    handleFieldDirtyChange,
    handleFieldError,
    handleSubmit,
  };
};

export default useBusinessProfileFormController;
