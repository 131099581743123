import React from 'react';

export type MockDataType = {
  id: number;
  title: string;
};

export type MockListItemProps = {
  id: number;
  title: string;
};

export const MockItemSkeleton = () => (
  <div
    style={{
      width: '250px',
      height: '15px',
      backgroundColor: 'hsl(228deg, 20%, 50%)',
      opacity: '0.1',
      marginTop: '16px',
      borderRadius: '8px',
    }}
  />
);

export const MockItem = ({ id, title }: MockListItemProps) => <div id={id.toString()}>{title}</div>;

export type MockListProps = React.PropsWithChildren<{
  items: MockDataType[];
  isLoading: boolean;
}>;

export const MockListItem = ({ items, isLoading, children }: MockListProps) => (
  <section style={{ width: '100%', position: 'relative' }}>
    {!isLoading && (
      <div>
        {items?.map((item) => (
          <MockItem
            key={`mock_list_item_${item.id}`}
            id={item.id}
            title={item.title}
          />
        ))}
      </div>
    )}

    <div
      style={{
        opacity: items && items.length > 0 && !isLoading ? '0' : '1',
        display: 'grid',
        gridAutoFlow: 'row',
        position: 'absolute',
        top: 0,
        left: 0,
        transition: 'opacity 0.3s',
      }}>
      {Array.from(Array(Math.min(25, items?.length || 25)).keys()).map((item) => (
        <MockItemSkeleton key={`mock_list_skeleton_item_${item}`} />
      ))}
    </div>

    {children}
  </section>
);

export const mockData: MockDataType[] = [];

for (let i = 0; i < 1000; i += 1) {
  mockData.push({
    id: i + 1,
    title: `title_${i + 1}`,
  });
}

export const PAGE_SIZE = 100;
