import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type DelimiterProps = {
  title?: React.ReactNode;
  className?: string;
  elementId?: string;
};

const Delimiter = ({ title, className, elementId = 'delimiter' }: DelimiterProps) => (
  <div
    data-testid={elementId}
    className={classNames(styles.Delimiter, className)}>
    <div />
    {title && <span>{title}</span>}
    <div />
  </div>
);

export default Delimiter;
