import { useMemo } from 'react';

import { useMediaStreamRemoveMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useStreamRemove = () => {
  const [mutation, mutationResult] = useMediaStreamRemoveMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (id: number) => {
    const mutationResult = await mutation({
      variables: {
        input: {
          id,
        },
      },
    });

    return mutationResult.data?.result?.ok || undefined;
  };

  return useMemo(
    () => ({
      error: mutationResult.error,
      loading: mutationResult.loading,
      request,
    }),
    [mutationResult.error, mutationResult.loading]
  );
};

export default useStreamRemove;
