import { useState, useEffect } from 'react';

import { useNotificationContext } from 'application/providers/NotificationProvider';

import { NOTIFICATIONS, NAME_MAX_LENGTH_ERROR } from '../consts';
import { UseAdRenameModalControllerProps } from './types';

const useAdRenameModalController = ({
  id,
  readAdCampaign,
  updateAdCampaign,
  adCampaignUpdateLoading,
  close,
}: UseAdRenameModalControllerProps) => {
  const notification = useNotificationContext();

  const [name, setName] = useState('');

  const adCampaign = readAdCampaign(id);
  const isRenameButtonDisabled = name.length === 0 || adCampaign?.title === name;

  useEffect(() => {
    if (adCampaign && adCampaign.title) {
      setName(adCampaign.title);
    }
  }, []);

  const onAdCampaignRename = async () => {
    try {
      const adCampaign = readAdCampaign(id);

      const ok = await updateAdCampaign({
        ...adCampaign!,
        title: name,
      });

      if (!ok) {
        throw new Error();
      }

      notification.showNotification(NOTIFICATIONS.RENAME_SUCCESS);
      close();
    } catch (error: any) {
      if (JSON.stringify(error).includes(NAME_MAX_LENGTH_ERROR)) {
        notification.showNotification(NOTIFICATIONS.MAX_LENGTH_ERROR);
      } else {
        notification.showNotification(NOTIFICATIONS.ERROR);
      }
    }
  };

  return {
    name,
    setName,
    adCampaignUpdateLoading,
    isRenameButtonDisabled,
    onAdCampaignRename,
  };
};

export default useAdRenameModalController;
