import React, { forwardRef, useCallback, useState, useEffect } from 'react';

interface ProgressSliderProps {
  elementId?: string;
  id: string;
  min: number;
  max: number;
  value: number;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onTouchStart?: (event: React.TouchEvent<HTMLInputElement>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLInputElement>) => void;
  step: number;
  className?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onTouchMove?: (event: React.TouchEvent<HTMLInputElement>) => void;
}

const ProgressSlider = forwardRef<HTMLInputElement, ProgressSliderProps>(
  (
    {
      id,
      max,
      min,
      step,
      value: propValue,
      className,
      disabled,
      elementId,
      onInput,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
      onClick,
      onTouchMove,
    }: ProgressSliderProps,
    ref
  ) => {
    const [value, setValue] = useState<number>(propValue);

    const handleInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(+e.target.value);
        onInput?.(e);
      },
      [onInput]
    );

    useEffect(() => {
      setValue(propValue);
    }, [propValue]);

    return (
      <input
        className={className}
        ref={ref}
        id={id}
        type="range"
        min={min}
        max={max}
        value={value}
        onInput={handleInput}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onKeyDown={(event) => event.preventDefault()}
        onClick={onClick}
        step={step}
        disabled={disabled}
        data-testid={elementId}
      />
    );
  }
);

export default ProgressSlider;
