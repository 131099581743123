import { useEffect } from 'react';

import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import ValidationUtils from 'application/utils/ValidationUtils';
import AnalyticsService, {
  EventNameGTM,
  ParamNameAMP,
  StreamBindFlowEventNameAMP,
} from 'application/services/AnalyticsService';
import useAddressState from 'application/hooks/useAddressState';
import { POINTS_PAGE_PATH } from 'application/pages/PointsPage';
import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import { useAccountContext } from 'application/providers/AccountProvider';
import CompanyUtils from 'application/utils/CompanyUtils';
import { TARIFFS } from 'application/pages/constants';
import { ModalTypes, useModalContext } from 'application/providers/ModalProvider';
import LicensesUtils from 'application/repositories/billing/utils';
import { PointWithDevice } from 'application/presenters/point/types';

import { PointInfoTypeFragment, UserFeatures } from 'domain/api/graphql/generated';

import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';

import { PointEventNameAMP, PointParamNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { PointCreateModalPages, UsePointCreateModalControllerProps } from './types';

export enum PointCreateWithPaymentModalMode {
  HINT,
  CONFIRM,
}

const usePointCreateModalController = ({
  onSubmit,
  close,
  onNext,
  userOnboardingFeatures,
  createPoint,
  getStandardizedPoints,
  getExtendSubscriptionCost,
  extendSubscription,
  getBillingInfo,
  hasActiveLicenses,
  getPaymentMethod,
  paymentMethod,
  extendSubscriptionCost,
  hasTrialLicenses,
  page,
  targetStreamId,
  collectionId,
  isTemplateMode,
  changeStream,
  copyTemplateStream,
  onboardingPassedRequest,
  getSavedStreams,
  setCurrentPoint,
  readCurrentPoint,
}: UsePointCreateModalControllerProps) => {
  const { currentCompany } = useAccountContext();
  const navigate = useNavigate();
  const notification = useNotificationContext();
  const { openModal, closeModal } = useModalContext();

  const addressState = useAddressState({ location: currentCompany?.country });

  const { isIpad } = useMedia();

  const isButtonDisabled = !ValidationUtils.validateAddress(addressState.value);
  const isShowHintAboutPrice = currentCompany?.isBillingEnabled && !hasTrialLicenses;

  const isPopularTariff = CompanyUtils.compareTariff(currentCompany?.pricePlan?.alias ?? '', TARIFFS.POPULAR);
  const isCustomInfoShouldShow =
    !userOnboardingFeatures?.[0]?.isOnboarded && isPopularTariff && page === PointCreateModalPages.STREAM;

  const isLastInvoiceHasZeroAmount =
    LicensesUtils.getLastPaidPeriodicInvoice(currentCompany?.invoices || [])?.amount === 0;

  const canAddPointWithPayment =
    currentCompany?.isBillingEnabled && paymentMethod && !isLastInvoiceHasZeroAmount && hasActiveLicenses;

  const onRequestExtendSubscriptionCost = async () => {
    await getExtendSubscriptionCost(currentCompany?.id!, 1);
  };

  const initData = async () => {
    if (currentCompany) {
      await getBillingInfo(currentCompany.id);
      await getPaymentMethod(currentCompany.id);
    }

    if (currentCompany?.isBillingEnabled) {
      await onRequestExtendSubscriptionCost();
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const changeStreamOnNewPoint = async () => {
    const currentPoint = readCurrentPoint();

    let streamId = targetStreamId;

    if (isTemplateMode) {
      streamId = (await copyTemplateStream(streamId!, currentCompany!.id))!;
    }

    changeStream([
      {
        newStreamId: streamId!,
        pointId: currentPoint!.id,
      },
    ]);

    getSavedStreams([currentCompany!.id]);
    onboardingPassedRequest({ feature: UserFeatures.WarningStreamFirstStart });

    const stream = { id: streamId };
    const newPoint = { ...currentPoint, stream } as PointWithDevice;
    setCurrentPoint(newPoint);

    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_BOUND, {
      [ParamNameAMP.STREAM_ID]: streamId,
      [ParamNameAMP.COLLECTION_ID]: collectionId,
    });

    return newPoint;
  };

  const handleSuccessCreatePointWithPayment = async () => {
    const currentPoint = readCurrentPoint();

    try {
      if (canAddPointWithPayment) {
        await extendSubscription(currentCompany?.id!, [currentPoint!.license!.id]);

        notification.showNotification({
          type: NotificationColors.INFO,
          children: TEXTS.POINT_CREATE_CONFIRM_MODAL_WITH_PAYMENT_SUCCESS_TEXT,
          delay: NOTIFICATION_DEFAULT_DELAY,
        });
      }
    } catch (e) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    } finally {
      let point = { ...currentPoint } as PointInfoTypeFragment;

      if (page === PointCreateModalPages.STREAM) {
        point = await changeStreamOnNewPoint();
      }

      onSubmit?.(point);

      if (page === PointCreateModalPages.POINT) {
        navigate(RouterUtils.generatePath(POINTS_PAGE_PATH, { id: point!.id }), { replace: true });
      }

      if (onNext) {
        onNext({ point });
      } else {
        close();
      }
    }
  };

  const handleCreateClick = async () => {
    const defaultTimezone = 'Europe/Moscow';
    const requestData = await getStandardizedPoints(addressState.value);

    const standardizedPoint = requestData?.length > 0 ? requestData[0] : null;
    const timezone = requestData ? standardizedPoint?.timezone : defaultTimezone;

    const addressData = standardizedPoint && {
      fiasId: standardizedPoint.fiasId,
      street: standardizedPoint.street,
      country: standardizedPoint.country,
      city: standardizedPoint.city,
      region: standardizedPoint.region,
      postalCode: standardizedPoint.postalCode,
      latitude: Number(standardizedPoint.latitude),
      longitude: Number(standardizedPoint.longitude),
      fullAddress: standardizedPoint.address,
    };

    const result = await createPoint({
      companyId: currentCompany!.id,
      address: addressState.value,
      timezone,
      createWebDevice: false,
      addressData,
    });

    AnalyticsService.event(PointEventNameAMP.POINT_CREATED, {
      [PointParamNameAMP.POINT_ID]: result.point?.id,
      [ParamNameAMP.CALLED_FROM]: page,
      [PointParamNameAMP.POINT_IS_ENABLED]: result.point?.isEnabled,
    });

    AnalyticsService.eventGTM(EventNameGTM.ADDRESS_ADDED);

    if (result.ok && result.point) {
      setCurrentPoint({ ...result.point, isNew: true } as PointWithDevice);
      await handleSuccessCreatePointWithPayment();
    } else {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const onPointCreateHintModalShow = () => {
    openModal(ModalTypes.POINT_CREATE_HINT, {});
  };

  const onPointCreateConfirmModalShow = () => {
    openModal(ModalTypes.POINT_CREATE_CONFIRM, {
      onSubmit: async () => {
        closeModal();
        await handleCreateClick();
      },
      cost: extendSubscriptionCost,
    });
  };

  return {
    isIpad,
    handleCreateClick,
    addressState,
    isButtonDisabled,
    isCustomInfoShouldShow,
    isBillingEnabled: currentCompany?.isBillingEnabled,
    isShowHintAboutPrice,
    canAddPointWithPayment,
    onPointCreateHintModalShow,
    onPointCreateConfirmModalShow,
  };
};

export default usePointCreateModalController;
