import React from 'react';

import { PageConfigType } from 'application/pages/types';

export type MenuItemsContextType = {
  mainMenu: {
    items: MenuItemDataType[];
    setItems: (value: MenuItemDataType[]) => void;
  };
  userMenu: {
    items: UserMenuItemDataType[];
    setItems: (value: UserMenuItemDataType[]) => void;
  };
  showOfertaModal: (isPopularTariff: boolean) => void;
  showCertModal: () => void;
};

export enum MenuItemTarget {
  DEFAULT,
  BOTTOM,
  PROFILE,
  LOGOUT,
}

export type MenuItemDataType = {
  pathname?: string;
  state?: Record<string, string>;
  onClick?: () => void;
  title?: string;
  icon?: React.ComponentType<{ className?: string }>;
  target?: MenuItemTarget;
  config?: PageConfigType;
  isMarked?: boolean;
  isNoticed?: boolean;
  isVisible: boolean;
};

export type UserMenuItemDataType = {
  onClick: () => void;
  title: string;
  icon: React.ComponentType<{ className?: string }>;
};
