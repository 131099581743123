import React from 'react';

import { useNavigate } from 'application/providers/RouterProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import { Country } from 'domain/api/graphql/generated';
import ConnectWithManagerButton from 'application/components/ConnectWithManagerButton';
import { TEXTS } from '../texts';

export interface CertificateActionButtonsProps {
  showPointsModal: boolean;
  showEmptyPointsModal: boolean;
  showBillingModal: boolean | null;
  showEmptyPointsTrialModal: boolean;
  chosenPointsIds: string[];
  onCancel?: () => void;
  onCreateCertificate: (createForAll?: boolean) => void;
  billingPath: string;
  allPointsCount: number;
  isAllSelected: boolean;
  isBillingEnabled?: boolean;
  country?: Country;
}

const POINTS_MENU_PATH = '/points/all';

const getCertificateActionButtons = ({
  showPointsModal,
  showEmptyPointsModal,
  showBillingModal,
  showEmptyPointsTrialModal,
  chosenPointsIds,
  onCancel,
  onCreateCertificate,
  billingPath,
  isAllSelected,
  allPointsCount,
  isBillingEnabled,
  country,
}: CertificateActionButtonsProps) => {
  const navigate = useNavigate();
  const buttons = [];

  if (showPointsModal) {
    if (isAllSelected && allPointsCount !== 0) {
      buttons.push(
        <Button
          key="button_create_all_certificate"
          elementId="button_create_all_certificate"
          type={ButtonType.PRIMARY}
          onClick={() => onCreateCertificate(true)}>
          {TEXTS.CHOOSE_PONTS_CERT_BUTTON(allPointsCount)}
        </Button>
      );
    } else {
      buttons.push(
        <Button
          key="button_create_certificate"
          elementId="button_create_certificate"
          type={ButtonType.PRIMARY}
          onClick={() => onCreateCertificate()}
          isDisabled={chosenPointsIds.length === 0}>
          {chosenPointsIds.length === 0
            ? TEXTS.CHOOSE_POINTS_EMPTY_CERT_BUTTON
            : TEXTS.CHOOSE_PONTS_CERT_BUTTON(chosenPointsIds.length)}
        </Button>
      );
    }
  }

  if (showEmptyPointsModal) {
    if (isBillingEnabled || showEmptyPointsTrialModal) {
      buttons.push(
        <Button
          key="button_points_redirect"
          elementId="button_points_redirect"
          type={ButtonType.PRIMARY}
          onClick={() => {
            navigate(POINTS_MENU_PATH);
            onCancel?.();
          }}>
          {TEXTS.EMPTY_POINTS_REDIRECT_BUTTON}
        </Button>
      );
    } else {
      buttons.push(
        <ConnectWithManagerButton
          country={country}
          onClickCallback={onCancel}
        />
      );
    }
  }

  if (showBillingModal) {
    buttons.push(
      <Button
        key="button_billing_redirect"
        elementId="button_billing_redirect"
        type={ButtonType.PRIMARY}
        onClick={() => {
          navigate(billingPath);
          onCancel?.();
        }}>
        {TEXTS.EMPTY_PAID_PONTS_REDIRECT_BUTTON}
      </Button>
    );
  }

  buttons.push(
    <Button
      key="button_cancel_model"
      elementId="button_cancel_model"
      type={ButtonType.SECONDARY}
      onClick={() => {
        onCancel?.();
      }}>
      {TEXTS.CANCEL_BUTTON}
    </Button>
  );

  return buttons;
};

export default getCertificateActionButtons;
