import {
  ZVUK_WITH_BROADCAST_SCHEDULE,
  ZVUK_FEATURE_SHELF_PAGE,
  ZVUK_FEATURE_SUB_COLLECTIONS,
  ZVUK_SHOW_CAPTCHA,
  ZVUK_SHOW_CONTACT_WITH_MANAGER_BUTTON,
  ZVUK_BIND_VIDEO_DEVICE,
  ZVUK_NEW_POINT_AD_LIST,
  ZVUK_SHOW_VIDEO_STREAM_INFO,
  ZVUK_SHOW_VIDEO_STREAMS_PAGE,
} from 'application/consts';
import {
  RuntimeFeatureFlagsKeysType,
  BuildFeatureFlagsKeysType,
  FeatureFlagsKeysType,
  FeaturesConsoleWindow,
  BuildFeatureFlagsType,
} from './types';

export const CONSOLE_FEATURE_FLAGS_UPDATED = 'consoleFeatureFlagsUpdated';

export const consoleFeatureFlagsWindow: FeaturesConsoleWindow = window;

export const runtimeFeatureFlagsKeys: RuntimeFeatureFlagsKeysType[] = [
  'isBusinessProfileAvailable',
  'isCertLetterAvailable',
  'isChangePricePlanAvailable',
  'isInstantAdcampaignAvailable',
  'isOffertaChanged',
  'isShowReports',
];

const withBroadcastSchedule = String(ZVUK_WITH_BROADCAST_SCHEDULE) === 'true';
const isFeatureShelfPage = String(ZVUK_FEATURE_SHELF_PAGE) === 'true';
const isFeatureSubCollections = String(ZVUK_FEATURE_SUB_COLLECTIONS) === 'true';
const showCaptcha = String(ZVUK_SHOW_CAPTCHA) === 'true';
const showContactWithManagerButton = String(ZVUK_SHOW_CONTACT_WITH_MANAGER_BUTTON) === 'true';
const bindVideoDevice = String(ZVUK_BIND_VIDEO_DEVICE) === 'true';
const newPointAdList = String(ZVUK_NEW_POINT_AD_LIST) === 'true';
const showVideoStreamInfo = String(ZVUK_SHOW_VIDEO_STREAM_INFO) === 'true';
const showVideoStreamsPage = String(ZVUK_SHOW_VIDEO_STREAMS_PAGE) === 'true';

export const buildFeatureFlags: BuildFeatureFlagsType = {
  withBroadcastSchedule,
  isFeatureShelfPage,
  isFeatureSubCollections,
  showCaptcha,
  newPointAdList,
  showContactWithManagerButton,
  bindVideoDevice,
  showVideoStreamInfo,
  showVideoStreamsPage,
};

export const buildFeatureFlagsKeys: BuildFeatureFlagsKeysType[] = [
  'isFeatureShelfPage',
  'isFeatureSubCollections',
  'showCaptcha',
  'showContactWithManagerButton',
  'newPointAdList',
  'bindVideoDevice',
  'showVideoStreamInfo',
  'showVideoStreamsPage',
];

export const featureFlagsKeys: FeatureFlagsKeysType[] = [...runtimeFeatureFlagsKeys, ...buildFeatureFlagsKeys];
