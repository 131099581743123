import React, { FC } from 'react';

import { TEXTS } from 'application/components/modals/ConfirmOperationModal/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

const ConfirmOperationModal: FC<ModalProps[ModalTypes.CONFIRM_OPERATION]> = ({
  title = TEXTS.CONFIRM_OPERATION_MODAL_DEFAULT_TITLE,
  description = TEXTS.CONFIRM_OPERATION_MODAL_DEFAULT_DESCRIPTION,
  submitButtonContent = TEXTS.CONFIRM_OPERATION_MODAL_SUBMIT_BUTTON_DEFAULT_TEXT,
  cancelButtonContent = TEXTS.CONFIRM_OPERATION_MODAL_CANCEL_BUTTON_DEFAULT_TEXT,
  isLoading = false,
  close,
  closeCallback,
  onSubmit,
  afterClose,
}) => {
  const handleClose = () => {
    close();
    closeCallback?.();
    afterClose?.();
  };

  return (
    <ModalScreen
      title={title}
      onClose={handleClose}
      hideCloseButton
      contentClassName={styles.ConfirmOperationModalContent}
      actionButtons={[
        <Button
          elementId="button_submit_confirm_operation_modal"
          key="button_submit_confirm_operation_modal"
          type={ButtonType.PRIMARY}
          isLoading={isLoading}
          onClick={() => {
            onSubmit();
            close();
          }}>
          {submitButtonContent}
        </Button>,
        <Button
          elementId="button_cancel_confirm_operation_modal"
          key="button_cancel_confirm_operation_modal"
          type={ButtonType.SECONDARY}
          isLoading={isLoading}
          onClick={handleClose}>
          {cancelButtonContent}
        </Button>,
      ]}>
      {description}
    </ModalScreen>
  );
};

export default ConfirmOperationModal;
