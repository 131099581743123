/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import classNames from 'classnames';
import { Accept, useDropzone } from 'react-dropzone';

import Button, { ButtonType } from 'ui/Button';

import { MediaUploaderItemType, MediaUploaderItemValue } from './MediaUploaderItem';

import styles from './index.module.scss';

export type { MediaUploaderItemType, MediaUploaderItemValue };

export type MediaUploaderProps = {
  className?: string;
  multiple?: boolean;
  accept: Accept;
  items: MediaUploaderItemType[];
  isLoading: boolean;
  elementId: string;
  buttonTitle: string;
  description: string;
  progress: number;
  isPaused: boolean;
  isMediaUploaderVisible?: boolean;
  onDrop: (files: File[]) => void;
  onPlay: (value: { fileUuid?: string; fileName: string; fileUrl: string }) => void;
  onPause: () => void;
  onResume: () => void;
  onRemove: (value: MediaUploaderItemValue) => void;
  onRetry: (value: MediaUploaderItemValue) => void;
  onChangeProgress: (value: number) => void;
  playingMediafileData: { id?: string; name?: string };
  renderer: React.ElementType;
  rendererChildren?: React.ElementType;
};

const MEDIA_FILE_TYPE = 1;

const MediaUploader = (props: MediaUploaderProps) => {
  const {
    className,
    multiple = true,
    accept,
    items,
    isLoading,
    elementId,
    buttonTitle,
    description,
    progress,
    isMediaUploaderVisible = true,
    isPaused,
    onDrop,
    onPlay,
    onPause,
    onRemove,
    onResume,
    onRetry,
    onChangeProgress,
    playingMediafileData,
    renderer: MediaListRenderer,
    rendererChildren: MediaListRendererChildren,
  } = props;

  const dropzone = useDropzone({
    multiple,
    accept,
    onDrop,
  });

  const onClickUpload = () => {
    dropzone.open();
  };

  return (
    <div
      className={classNames(styles.MediaUploader, className)}
      id={elementId}>
      {isMediaUploaderVisible && (
        <>
          <Button
            elementId={`${elementId}_button`}
            className={styles.MediaUploaderButton}
            isLoading={isLoading}
            type={ButtonType.SECONDARY}
            onClick={onClickUpload}>
            {buttonTitle}
          </Button>

          <div {...dropzone.getRootProps({ style: { display: 'none' } })}>
            <input
              data-testid={`${elementId}_input`}
              {...dropzone.getInputProps()}
            />
          </div>

          <span className={styles.MediaUploaderDesc}>{description}</span>
        </>
      )}

      <ul
        id={`${elementId}_list`}
        data-testid={`${elementId}_list`}
        className={styles.MediaUploaderList}>
        {items.map((item) => {
          const isMediafilePlaying =
            playingMediafileData.name === item.value.filename && playingMediafileData.id === item.value.id;
          const mediaFileProgress = isMediafilePlaying ? progress : undefined;
          const isRemoveVisible = !item.error && !isLoading && onRemove;

          const removeHandler = () => onRemove(item.value);
          const retryHandler = () => onRetry(item.value);
          const playHandler = () =>
            onPlay({ fileName: item.value.filename, fileUuid: item.value.id, fileUrl: item.value.url });

          return (
            <MediaListRenderer
              key={`media_upload_item-${item.value.id}`}
              title={item.label}
              onClickPlay={playHandler}
              onClickPause={onPause}
              onClickResume={onResume}
              onChangeProgress={onChangeProgress}
              isPaused={isPaused}
              isMediafilePlaying={isMediafilePlaying}
              progress={mediaFileProgress}
              isLoading={item.isLoading}
              type={MEDIA_FILE_TYPE}>
              {MediaListRendererChildren && (
                <MediaListRendererChildren
                  id={elementId}
                  isRemove={isRemoveVisible}
                  isError={item.error}
                  onRemove={removeHandler}
                  onRetry={retryHandler}
                />
              )}
            </MediaListRenderer>
          );
        })}
      </ul>
    </div>
  );
};

export default MediaUploader;
