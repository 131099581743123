import React from 'react';

import { ModalTypes } from 'application/providers/ModalProvider/consts';
import { ModalProps } from 'application/providers/ModalProvider/modalsProps';

import { PointInfoTypeFragment, PointShortTypeFragment } from 'domain/api/graphql/generated';

import { PointWithDevice } from 'application/presenters/point/types';
import { PlayerTypesInFlow } from './SelectPlayerModal/types';

export type FlowMapKeysType =
  | ModalTypes.DENY_OPERATION
  | ModalTypes.DESKTOP_PLAYER_BIND
  | ModalTypes.DESKTOP_PLAYER_DOWNLOAD
  | ModalTypes.DEVICE_REQUESTED
  | ModalTypes.MOBILE_PLAYER_BIND
  | ModalTypes.MOBILE_PLAYER_DOWNLOAD
  | ModalTypes.PAYMENT_REQUIREMENT
  | ModalTypes.POINT_CREATE
  | ModalTypes.SELECT_PLAYER_MODAL
  | ModalTypes.SBERBOOM_REQUEST
  | ModalTypes.SBERBUSSINESS_REQUEST
  | ModalTypes.STREAM_BIND
  | ModalTypes.STREAM_BIND_SUCCESS
  | ModalTypes.CHANGE_DEVICE_WARNING
  | ModalTypes.CHOOSE_VIDEO_PLAYER
  | ModalTypes.VIDEO_PLAYER_BIND
  | ModalTypes.DOWNLOAD_TV_APP;

export const MODAL_MODE_BY_DEVICE_TYPE: Record<PlayerTypesInFlow, FlowMapKeysType> = {
  [PlayerTypesInFlow.WEBPLAYER]: ModalTypes.STREAM_BIND_SUCCESS,
  [PlayerTypesInFlow.MOBILE]: ModalTypes.MOBILE_PLAYER_DOWNLOAD,
  [PlayerTypesInFlow.BOOM_SPEAKER]: ModalTypes.SBERBOOM_REQUEST,
  [PlayerTypesInFlow.DESKTOP]: ModalTypes.DESKTOP_PLAYER_DOWNLOAD,
  [PlayerTypesInFlow.ANDROIDBOX]: ModalTypes.SBERBUSSINESS_REQUEST,
  [PlayerTypesInFlow.VIDEOPLAYER]: ModalTypes.CHOOSE_VIDEO_PLAYER,
};

export type UseSelectPlayerFlowControllerProps = {
  targetStreamId?: string;
  collectionId?: string;
  isTemplateMode: boolean;
  pointFromLocation?: {
    pointId: string;
    pointAddress: string;
  };
  updatePointsList: () => Promise<PointShortTypeFragment[]>;
  selectPlayerFlowType: SelectPlayerFlowType;
  point?: PointWithDevice;
  isCalledFromStreamPage?: boolean;
};

export type UseSelectPlayerFlowHelperProps = {
  openModal: <T extends ModalTypes>(
    type: T,
    modalState: Omit<ModalProps[T], 'close' | 'open'>,
    onModalCloseCallback?: () => void
  ) => void;
  closeModal: (count?: number) => void;
  closeAllModals: () => void;

  setModalMode: React.Dispatch<React.SetStateAction<FlowMapKeysType | undefined>>;
  setInFlow: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateClick: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    callback?: () => void,
    deviceCallback?: (requiredVersionUrl?: string) => void
  ) => Promise<string | undefined>;

  currentPoint?: PointInfoTypeFragment;
  pointFromLocation?: {
    pointId: string;
    pointAddress: string;
  };
  flowType?: SelectPlayerFlowType;
  isNeedPayment: boolean;
  readCurrentPoint: () => PointWithDevice | null;
  setCurrentPoint: (currentPoint: PointWithDevice) => boolean;
  clearCurrentPoint: () => void;
};

export enum SelectPlayerFlowType {
  CREATE_POINT = 'CREATE_POINT',
  CHANGE_DEVICE = 'CHANGE_DEVICE',
  BIND_STREAM = 'BIND_STREAM',
}
