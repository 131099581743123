/* eslint-disable jsx-a11y/no-static-element-interactions */
import IconChevronDown from '@zvuk-b2b/react-uikit/ui/icons/ChevronDown';
import IconChevronRight from '@zvuk-b2b/react-uikit/ui/icons/ChevronRight';
import React, { useState } from 'react';
import styles from './index.module.scss';

export type CollapsibleSectionProps = React.PropsWithChildren<{
  title: string;
  children: React.ReactNode;
}>;

const CollapsibleSection = ({ title, children }: CollapsibleSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleIsCollapsed = React.useCallback(() => setIsCollapsed((value) => !value), []);

  return (
    <div className={styles.CollapsibleSection}>
      <div
        className={styles.CollapsibleSectionTitle}
        onClick={toggleIsCollapsed}
        onKeyDown={toggleIsCollapsed}>
        {title}
        {isCollapsed ? (
          <IconChevronRight className={styles.CollapsibleSectionChevron} />
        ) : (
          <IconChevronDown className={styles.CollapsibleSectionChevron} />
        )}
      </div>
      <div className={styles.CollapsibleSectionContent}>{!isCollapsed && children}</div>
    </div>
  );
};

export default CollapsibleSection;
