import {
  useBusinessProfileCreateMutation,
  ZvukProfileCreateMutationInput,
  ZvukProfileListDocument,
  AuthCurrentUserDocument,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { BusinessProfileCreateResultType, UseBusinessProfileCreateType } from './types';

const useBusinessProfileCreate = (): UseBusinessProfileCreateType => {
  const [createMutation, createMutationResult] = useBusinessProfileCreateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukProfileCreateMutationInput) => {
    const newBusinessProfile = await createMutation({
      variables: {
        input,
      },
      refetchQueries: [ZvukProfileListDocument, AuthCurrentUserDocument],
    });

    return {
      isOk: !!newBusinessProfile.data?.zvukProfileCreate?.ok,
      businessProfile: newBusinessProfile.data?.zvukProfileCreate?.profile,
    };
  };

  const zvukProfileCreateData = createMutationResult.data?.zvukProfileCreate;
  const hasErrors = !!zvukProfileCreateData?.errors;
  const zvukProfileCreateResultData = {
    ok: zvukProfileCreateData?.ok,
    errors: hasErrors ? GraphQLApiUtils.errorsArrayToObject(zvukProfileCreateData?.errors) : undefined,
  };

  const result: BusinessProfileCreateResultType = {
    error: createMutationResult.error,
    loading: createMutationResult.loading,
    called: createMutationResult.called,
    data: zvukProfileCreateResultData,
  };

  return [request, result];
};

export default useBusinessProfileCreate;
