import React, { useEffect } from 'react';
import { matchPath, useNavigate, useRouteError } from 'react-router-dom';

import { DEV, LOGIN_PAGE_PATH, STREAMS_STREAM_CATEGORY_BY_ID_PATH } from 'application/consts';
import { StreamsCategoryParamValues } from 'application/pages/StreamPage';

import { useLocation } from './useLocation';
import RouterUtils from './utils';

const streamsRecommendedPage = RouterUtils.generatePath(STREAMS_STREAM_CATEGORY_BY_ID_PATH, {
  category: StreamsCategoryParamValues.RECOMMENDED,
});

const RouterErrorBoundary = () => {
  const error = useRouteError() as Error;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isDouble = matchPath({ path: '//', end: true }, location.pathname);
    const isStreams = matchPath({ path: '/streams', end: true }, location.pathname);
    const isDevMatch = location.pathname.includes('dev');

    if (isDouble || isDevMatch) {
      navigate(LOGIN_PAGE_PATH, { replace: true });
    } else if (isStreams) {
      navigate(streamsRecommendedPage, { replace: true });
    }
  }, [location.pathname]);

  if (DEV) {
    console.error(error);
  }

  if (error?.message) {
    return <div>{`${error.message}`}</div>;
  }

  return <div>{`${error}`}</div>;
};

export default RouterErrorBoundary;
