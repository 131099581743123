import React from 'react';
import styles from './index.module.scss';

export type LinkContainerProps = {
  link?: string;
  icon: React.FunctionComponent<{ className: string }>;
  title: string;
  subTitle: string;
  target?: '_self' | '_blank';
};

const LinkContainer = ({ link, icon, title, subTitle, target }: LinkContainerProps) => {
  const Icon = icon;
  return (
    <a
      href={link}
      target={target}>
      <div className={styles.LinkContainerLink}>
        <Icon className={styles.LinkContainerLinkIcon} />
        <div className={styles.LinkContainerLinkDescription}>
          <div className={styles.LinkContainerLinkDescriptionTitle}>{title}</div>
          <div className={styles.LinkContainerLinkDescriptionSubTitle}>{subTitle}</div>
        </div>
      </div>
    </a>
  );
};

export default LinkContainer;
