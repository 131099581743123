import {
  AdCampaignQueryFilter,
  AdCampaignQueryPagination,
  AdCampaignQuerySort,
  useMediaAdCampaignsMetadataPaginationQueryLazyQuery,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseAdCampaignsMetadataResultType, UseAdCampaignsMetadataType } from './types';

const useAdCampaignsMetadata = (): UseAdCampaignsMetadataType => {
  const [query, { data, called, loading, error, networkStatus, fetchMore, refetch }] =
    useMediaAdCampaignsMetadataPaginationQueryLazyQuery({
      fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
      nextFetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    });

  const request = async (
    filters: AdCampaignQueryFilter,
    pagination: AdCampaignQueryPagination,
    sort: AdCampaignQuerySort,
    fetchPolicy = FetchPolicyKeys.CACHE_FIRST
  ) =>
    query({
      variables: {
        filters,
        pagination,
        sort,
      },
      fetchPolicy,
    });

  async function loadMore(
    filters: AdCampaignQueryFilter,
    pagination: AdCampaignQueryPagination,
    sort: AdCampaignQuerySort
  ) {
    const result = await fetchMore({
      variables: {
        filters,
        pagination,
        sort,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const data = {
          ...prev,
          adcampaignsPaginationQuery: {
            ...prev?.adcampaignsPaginationQuery,
            ...fetchMoreResult?.adcampaignsPaginationQuery,
            result: [
              ...(prev?.adcampaignsPaginationQuery?.result || []),
              ...(fetchMoreResult?.adcampaignsPaginationQuery?.result || []),
            ],
          },
        };

        return data;
      },
    });

    return result;
  }

  const result: UseAdCampaignsMetadataResultType = {
    error,
    loading,
    called,
    networkStatus,
    data: {
      adCampaigns: data?.adcampaignsPaginationQuery.result,
      paginationInfo: data?.adcampaignsPaginationQuery.paginationInfo,
    },
    loadMore,
    refetch,
  };

  return [request, result];
};

export default useAdCampaignsMetadata;
