import { useState } from 'react';
import useMedia from './useMedia';

const useResizeIOS = (callback: (deviceHeight: number) => void) => {
  const { isIOS } = useMedia();

  if (!isIOS) {
    return () => {};
  }

  const resizeCallback = () => {
    const deviceViewport = window.visualViewport;
    const deviceHeight = deviceViewport!.height;

    callback(deviceHeight);
  };

  window.visualViewport!.addEventListener('resize', resizeCallback);

  return () => {
    window.visualViewport!.removeEventListener('resize', resizeCallback, true);
  };
};

export const useRealResize = (callback: () => void) => {
  const [previousWidth, setPreviousWidth] = useState(window.screen.width);
  const [previousHeight, setPreviousHeight] = useState(window.screen.height);

  const resizeCallback = () => {
    if (window.screen.width !== previousWidth || window.screen.height !== previousHeight) {
      callback();

      setPreviousWidth(window.screen.width);
      setPreviousHeight(window.screen.height);
    }
  };

  window.addEventListener('resize', resizeCallback, true);

  return () => {
    window.removeEventListener('resize', resizeCallback, true);
  };
};

export default useResizeIOS;
