import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';
import { Country } from 'domain/api/graphql/generated';

export const WHATSAPP_URL = 'https://wa.me/79602785741';
export const WHATSAPP_KZ_URL = 'https://wa.me/77710110061';

class CommunicationUtils {
  static connectWithManager(country?: Country) {
    AnalyticsService.event(EventNameAMP.SUPPORT_REQUESTED);

    const whatsAppLink = this.getWhatsAppLink(country);
    window.open(whatsAppLink, '_blank');
  }

  static getWhatsAppLink(country?: Country) {
    if (country === Country.Kz) {
      return WHATSAPP_KZ_URL;
    }

    return WHATSAPP_URL;
  }
}

export default CommunicationUtils;
