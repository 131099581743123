import React from 'react';

import WelcomePageWrapper from 'application/pages/WelcomePage';
import CleanLayout from 'application/layout/CleanLayout';
import { WELCOME_PAGE_PATH } from 'application/consts';

import { RouteConfig } from '..';

export const welcomeRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: WELCOME_PAGE_PATH,
        element: <WelcomePageWrapper />,
      },
    ],
  },
];
