import React, { useMemo } from 'react';
import classNames from 'classnames';

import { TopBannerState } from 'application/components/widgets/TopBanner/useTopBannerState';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import { BILLING_PAGE_PATH, StateParamValues } from 'application/pages/BillingPage';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import IconX from '@zvuk-b2b/react-uikit/ui/icons/X';
import styles from '../index.module.scss';
import { TEXTS } from '../texts';

type UnpaidBannerProps = {
  className?: string;
  banner: TopBannerState;
  onClose: () => void;
};

const UnpaidBanner = ({ banner, onClose, className }: UnpaidBannerProps) => {
  const navigate = useNavigate();
  const billingPageLinkTo = RouterUtils.generatePath(BILLING_PAGE_PATH, { state: StateParamValues.INFO });

  const closeButton = useMemo(
    () => (
      <Button
        elementId="button-billing-banner-close"
        className={styles.TopBannerCloseButton}
        onClick={onClose}
        type={ButtonType.PLAIN}>
        <IconX className={styles.TopBannerCloseButtonIcon} />
      </Button>
    ),
    []
  );

  return (
    <div
      className={classNames(styles.TopBanner, className)}
      data-type={banner.type}>
      <div className={styles.TopBannerDescription}>{TEXTS.BANNER_UNPAID_TEXT}</div>
      <Button
        elementId="trial_period_banner_button_go_to_billing"
        type={ButtonType.PLAIN}
        className={styles.TopBannerButton}
        onClick={() => navigate(billingPageLinkTo)}>
        {TEXTS.BUTTON_GO_TO_BILLING_VARIANT_TITLE}
      </Button>
      {closeButton}
    </div>
  );
};

export default UnpaidBanner;
