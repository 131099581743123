import React, { useEffect, useMemo } from 'react';

import { RouterUtils, useURLSearchParams, useNavigate } from 'application/providers/RouterProvider';
import { useAuthContext } from 'application/providers/AuthProvider';
import AnalyticsService, {
  EventNameAMP,
  EventNameGTM,
  LoginPathValues,
  ParamNameAMP,
  ParamNameGTM,
} from 'application/services/AnalyticsService';
import usePageShownAmpSource from 'application/hooks/usePageShownAmpSource';
import { AuthQueryKeys } from 'application/pages/types';
import { REGISTER_PAGE_PATH } from 'application/pages/RegisterPage';
import { Platform } from 'application/pages/constants';
import AuthForm from 'application/components/AuthForm';
import { AuthFormMode, AuthFormStep } from 'application/components/AuthForm/types';
import BrowserUtils from 'application/utils/BrowserUtils';
import PhoneUtils from 'application/utils/PhoneUtils';
import ActivationService from 'application/services/ActivationService';
import { AuthTokenType, LoginQueryKeys, UserDataType } from 'domain/auth/types';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';
import { TEXTS } from './texts';

const SEARCH_PARAMS = [
  AuthQueryKeys.FROM,
  AuthQueryKeys.STEP,
  LoginQueryKeys.EMAIL,
  LoginQueryKeys.PHONE,
  AuthQueryKeys.PLATFORM,
];

interface ILoginModalProps {
  onModalClose: () => void;
}

export const LoginModal: React.FC<ILoginModalProps> = ({ onModalClose }) => {
  const authContext = useAuthContext();

  const isActivationFlow = ActivationService.isActivationFlow();

  const ampSource = usePageShownAmpSource();
  const inIframe = BrowserUtils.inIframe();

  const navigate = useNavigate();

  const [from, queryStep, email, phone, platform] = useURLSearchParams(SEARCH_PARAMS);

  const step = (queryStep as AuthFormStep) || AuthFormStep.LEGACY_AUTH;

  const [currentPhone, setCurrentPhone] = React.useState<string | undefined>(undefined);

  const isSBBIDAvailable = !inIframe && !email && !isActivationFlow && platform !== Platform.IOS;

  const initData = useMemo(
    () => ({
      email: email || undefined,
      phone: phone ? PhoneUtils.parsePhoneNumberFromString(decodeURIComponent(phone))?.number : undefined,
    }),
    [email, phone]
  );

  const registerLinkTo = RouterUtils.generatePath(REGISTER_PAGE_PATH, undefined, from ? { from } : undefined);

  const onSuccess = (authToken: AuthTokenType, userData: UserDataType) => {
    authContext.setAuthData({
      authToken,
      userData,
    });

    AnalyticsService.event(EventNameAMP.USER_LOGGED_IN, {
      [ParamNameAMP.LOG_IN_PATH]: LoginPathValues.DEFAULT,
    });

    AnalyticsService.eventGTM(EventNameGTM.AUTH_SUCCESS, {
      [ParamNameGTM.ACCOUNT_CREATED_DATE]: userData?.mainCompany?.created,
    });

    onModalClose();
    navigate(RouterUtils.generatePath(window.location.pathname, undefined, {}), { replace: true });
  };

  const onPhoneChange = (phone: string) => {
    setCurrentPhone(phone);
  };

  const onStepChange = (step: AuthFormStep, phone?: string) => {
    navigate(
      RouterUtils.generatePath(window.location.pathname, undefined, {
        ...RouterUtils.getURLSearchParamsAsObject(),
        step,
        phone: phone ? encodeURIComponent(phone) : undefined,
      })
    );
  };

  useEffect(() => {
    AnalyticsService.event(EventNameAMP.LOGIN_PAGE_SHOWN, {
      [ParamNameAMP.SOURCE]: ampSource,
    });
  }, []);

  const onClose = () => {
    navigate(RouterUtils.generatePath(window.location.pathname, undefined, {}), { replace: true });
    onModalClose();
  };

  return (
    <ModalScreen onClose={onClose}>
      {(step === AuthFormStep.PHONE_INPUT || step === AuthFormStep.LEGACY_AUTH) && (
        <h3 className={styles.LoginPageTitle}>{TEXTS.PAGE_TITLE}</h3>
      )}

      {step === AuthFormStep.CODE_INPUT && currentPhone && (
        <span className={styles.LoginPageDescription}>
          {TEXTS.SUBTITLE_CODE_DESC(PhoneUtils.formatPhoneNumber(currentPhone))}
        </span>
      )}

      {(step === AuthFormStep.PHONE_INPUT || step === AuthFormStep.LEGACY_AUTH) && !isActivationFlow && (
        <div className={styles.LoginPageRegister}>{TEXTS.BUTTON_REGISTER_TITLE(registerLinkTo)}</div>
      )}

      <AuthForm
        className={styles.LoginPageForm}
        isSBBIDAvailable={isSBBIDAvailable}
        step={step}
        initData={initData}
        mode={AuthFormMode.LOGIN}
        onPhoneChange={onPhoneChange}
        onStepChange={onStepChange}
        onSuccess={onSuccess}
      />
    </ModalScreen>
  );
};
