import React from 'react';

export const TEXTS = {
  DOC_TITLE: 'Реклама – Звук Бизнес',
  PAGE_TITLE: 'Реклама',
  PAGE_DESCRIPTION:
    'Выберите любую кампанию из списка слева или создайте новую, чтобы дополнить звучание у себя в заведении',

  AD_ITEM_STATUS_ACTIVE: 'Активна',
  AD_ITEM_STATUS_PLANNED: 'Запланирована',
  AD_ITEM_STATUS_ENDED: 'Прошла',
  AD_ITEM_STATUS_PAUSED: 'Остановлена',

  AD_MENU_ITEM_REPORT_CREATE: 'Получить отчёт',
  AD_MENU_ITEM_STOP: 'Остановить',
  AD_MENU_ITEM_START: 'Возобновить',
  AD_MENU_ITEM_RENAME: 'Переименовать',
  AD_MENU_ITEM_REMOVE: 'Удалить',
  AD_MENU_ITEM_COPY: 'Дублировать',

  BUTTON_CREATE_AD_TITLE: 'Создать рекламную кампанию',
  LIST_AD_SEARCH_PLACEHOLDER: 'Поиск...',
  LIST_AD_ADDRESS_PLACEHOLDER: 'Адрес...',

  MODAL_AD_CREATE_TITLE: 'Создать рекламную кампанию',
  BUTTON_AD_CREATE_TITLE: 'Создать',
  INPUT_AD_CREATE_TITLE: 'Название',
  INPUT_AD_CREATE_PLACEHOLDER: 'Например, промо-акция',
  INPUT_AD_CREATE_MEDIA_UPLOAD_TITLE: 'Загрузить ролик',
  MODAL_AD_CREATE_MEDIA_UPLOAD_DESC: (extensions: string) => `Поддерживаемые расширения файлов: ${extensions}`,
  MODAL_AD_CREATE_SUCCESS_MESSAGE: 'Рекламная кампания создана',

  MODAL_AD_REMOVE_BUTTON_CONFIRM: 'Удалить',
  MODAL_AD_REMOVE_BUTTON_CANCEL: 'Отменить',
  MODAL_AD_REMOVE_TITLE: 'Удалить рекламную кампанию?',
  MODAL_AD_REMOVE_DESCRIPTION: 'Кампания будет удалена из личного кабинета вместе со всеми роликами',
  MODAL_AD_REMOVE_SUCCESS_MESSAGE: 'Рекламная кампания удалена',

  MODAL_AD_RENAME_BUTTON_CONFIRM: 'Сохранить',
  MODAL_AD_RENAME_TITLE: 'Переименовать кампанию',
  MODAL_AD_RENAME_INPUT_LABEL: 'Название',
  MODAL_AD_RENAME_SUCCESS_MESSAGE: 'Рекламная кампания переименована',

  MODAL_AD_REPORT_CREATE_TITLE: 'Получить отчет о вещании',
  MODAL_AD_REPORT_CREATE_BUTTON: 'Получить отчёт',
  MODAL_AD_REPORT_CREATE_RECIPIENT_EMAIL_LABEL: 'E-mail для получения',
  MODAL_AD_REPORT_CREATE_RECIPIENT_EMAIL_PLACEHOLDER: 'example@zvuk.com',
  MODAL_AD_REPORT_CREATE_DESCRIPTION: (email: string) => `Отчёт будет отправлен на почту ${email} в формате excel`,
  MODAL_AD_REPORT_CREATE_SUCCESS_MESSAGE: 'Отчёт отправлен',
  MODAL_AD_REPORT_CREATE_PERIOD: 'Отчётный период',

  MODAL_AD_COPY_SUCCESS_MESSAGE: 'Создана копия рекламной кампании',

  TAB_SETTING_TITLE: 'Настройки',
  TAB_ADDRESS_TITLE: 'Адреса',
  TAB_PROMOS_TITLE: 'Ролики',

  FORM_BLOCK_DATE_TITLE: 'Даты',
  INPUT_START_DATE_TITLE: 'Первый день вещания',
  INPUT_END_DATE_TITLE: 'Последний день вещания',
  SWITCHER_INFINITELY_TITLE: 'Проигрывать бесконечно',
  FORM_BLOCK_TIME_TITLE: 'Часы вещания',
  INPUT_START_TIME_TITLE: 'Начало вещания',
  INPUT_END_TIME_TITLE: 'Окончание вещания',
  FORM_BLOCK_MODE_TITLE: 'Тип вещания',
  SELECT_MODE_TITLE: 'Как проиграть ролики?',
  INPUT_MODE_INTERVAL_TITLE: 'Интервал проигрывания, мин',
  INPUT_MODE_PERIOD_TITLE: 'Интервал повтора, мин',
  SWITCHER_SYNC_TITLE: 'Не прерывать играющий трек',
  AD_TIMING_SETTING_TITLE: 'Дни недели',

  AD_TYPE_INTERVAL: 'По очереди с интервалом',
  AD_TYPE_INTERVAL_TITLE: 'Вещание с интервалом',
  AD_TYPE_INTERVAL_QUESTION: 'Как работает вещание с интервалами?',
  AD_TYPE_INTERVAL_EXPLANATION: (
    <>
      Все ролики в рекламной кампании проигрываются по порядку с установленным интервалом.
      <p>
        <ul>
          <li>Проигрывается первый ролик</li>
          <li>Проходит интервал</li>
          <li>Проигрывается следующий ролик в очереди</li>
        </ul>
      </p>
    </>
  ),

  AD_TYPE_PERIOD: 'Повторять каждый ролик',
  AD_TYPE_PERIOD_TITLE: 'Вещание с повторами',
  AD_TYPE_PERIOD_QUESTION: 'Как работает вещание с повторами каждого ролика?',
  AD_TYPE_PERIOD_EXPLANATION: 'Каждый ролик повторяется с установленным интервалом',

  AD_TYPE_EXACT: 'В указанное время',
  AD_TYPE_EXACT_TITLE: 'Вещание в указанное время',
  AD_TYPE_EXACT_QUESTION: 'Как работает вещание в указанное время?',
  AD_TYPE_EXACT_EXPLANATION: 'Каждый ролик проигрывается в указанное вами время',

  AD_TYPE_GROUPS: 'Группа',
  AD_TYPE_GROUPS_TITLE: 'Вещание в группе',
  AD_TYPE_GROUPS_QUESTION: 'Как работает вещание в группе?',
  AD_TYPE_GROUPS_EXPLANATION: '',

  AD_TYPE_INSTANT: 'Моментальный ролик',
  AD_TYPE_INSTANT_TITLE: 'Моментальный ролик',
  AD_TYPE_INSTANT_QUESTION: 'Как работает моментальный ролик?',
  AD_TYPE_INSTANT_EXPLANATION_ONE: 'Проигрывается моментально по нажатию на кнопку «Запустить моментальный ролик».',
  AD_TYPE_INSTANT_EXPLANATION_TWO: 'Для прослушивания ролика на нужных адресах, привяжите их во вкладке «Адреса».',
  AD_TYPE_INSTANT_EXPLANATION_THREE:
    'Для запуска ролика переходите в раздел Адреса. Моментальный ролик доступен на всех типах устройств, кроме мобильного приложения и колонки Сбербум.',
  AD_TYPE_INSTANT_EXPLANATION_ART_TITLE: 'Плеер',
  AD_TYPE_INSTANT_EXPLANATION_ART_PLAYER: 'Приложение',
  AD_TYPE_INSTANT_EXPLANATION_ART_CODE_LABEL: 'Код: ',
  AD_TYPE_INSTANT_EXPLANATION_ART_CODE_VALUE: '4f84',
  AD_TYPE_INSTANT_EXPLANATION_ART_WEB_OPEN_BUTTON: 'Открыть плеер',
  AD_TYPE_INSTANT_EXPLANATION_ART_LAUNCH_BUTTON: 'Запустить моментальный ролик',
  AD_TYPE_INSTANT_DISABLED_HINT: 'Доступен, когда загружен один ролик.',
  AD_TYPE_INSTANT_DISABLED_HINT_LINK: 'Перейти в ролики',
  AD_TYPE_INSTANT_SETTINGS_HINT: 'Для запуска моментального ролика ',
  AD_TYPE_INSTANT_SETTINGS_HINT_LINK: 'перейдите во вкладку «Адреса»',
  AD_TYPE_INSTANT_POINTS_HINT: 'Моментальный ролик доступен для адресов с типом плеера «Приложение для ПК»',
  AD_TYPE_INSTANT_PROMO_HINT: 'Выбран «Моментальный ролик» по кнопке, поэтому возможен только один ролик',

  MODAL_AD_INFO_RETURN_TEXT: 'Вернуться к настройкам',

  BUTTON_SAVE_AD_TITLE: 'Сохранить',
  SAVE_AD_MODAL_TITLE: 'Сохранить настройки рекламной кампании',
  SAVE_AD_MODAL_DESCRIPTION: 'Изменения вступят в силу в течение нескольких минут',
  SAVE_INSTANT_AD_MODAL_DESCRIPTION:
    'Моментальные ролики будут отправлены на плееры по выбранным адресам. Привязка к адресам займет до 10 минут',
  SAVE_INSTANT_AD_MODAL_SUCCESS_TITLE: 'Изменения настроек сохранены',
  SAVE_INSTANT_AD_MODAL_SUCCESS_DESCRIPTION: 'Для запуска моментального ролика перейдите в раздел «Адреса»',
  SAVE_INSTANT_AD_MODAL_SUCCESS_OK_BUTTON_TEXT: 'Перейти в адреса',
  SAVE_INSTANT_AD_MODAL_SUCCESS_CANCEL_BUTTON_TEXT: 'Отменить',
  SAVE_AD_MODAL_WARNING_BEFORE_TODAY: (date: string) =>
    `Вы указали последний день вещания на ${date}. Эта дата уже прошла. Проверьте, нет ли в этом ошибки?`,
  SAVE_AD_MODAL_WARNING_NO_END_DATE: 'Вы не указали дату окончания вещания. Проверьте, нет ли в этом ошибки?',

  AD_CAMPAIGN_CREATE_FIRST_PROMO:
    'Рекламная кампания позволит вам делать объявления и проигрывать рекламу по адресам вещания',
  AD_CAMPAIGN_EMPTY_SEARCH: 'По данному запросу рекламных кампаний не найдено',
  AD_POINTS_FILTERED_EMPTY_TEXT: 'По данному запросу адресов не найдено',
  AD_POINTS_EMPTY_TEXT: 'Адресов нет',
  AD_SET_POINTS_BUTTON_TEXT: 'Сохранить',
  AD_CAMPAIGN_STOPPED: 'Рекламная кампания приостановлена',
  AD_CAMPAIGN_STARTED: 'Рекламная кампания возобновлена',
  NOTIFICATION_SUCCESS_UPDATE_AD_CAMPAIGN: 'Изменения сохранены',

  AD_POINTS_LIST_SELECT_ALl: 'Включить все',
  AD_POINTS_LIST_SELECT_NONE: 'Отключить все',
  AD_SET_ALL_POINTS_MODAL_TITLE_WARNING: 'Есть несохраненные изменения',
  AD_SET_ALL_POINTS_MODAL_DESCRIPTION_WARNING_1: 'Вы точно хотите закрыть страницу?',
  AD_SET_ALL_POINTS_MODAL_DESCRIPTION_WARNING_2: 'Несохраненные изменения будут потеряны',
  BUTTON_AD_SET_POINTS_CLOSE_TITLE: 'Закрыть',
  AD_SET_ALL_POINTS_MODAL_DESCRIPTION_WARNING_3: 'Вы точно хотите изменить фильтры?',
  AD_SET_ALL_POINTS_MODAL_DESCRIPTION_WARNING_4: 'Изменение фильтров автоматически сохранит текущий выбор',
  BUTTON_AD_SET_POINTS_CLOSE_TITLE_2: 'Изменить с сохранением',
  AD_POINTS_SUBMIT_MESSAGE: 'Список адресов обновлен',
  AD_POINTS_FILTERS_TAGS_TEXT: 'Фильтр по тегам',

  AD_POINTS_LIST_EMPTY: 'У вас нет адресов',

  TAB_ALL_TITLE: 'Все',
  TAB_ACTIVE_TITLE: 'Активные',
  TAB_PASSED_TITLE: 'Прошедшие',
  TAB_STOPPED_TITLE: 'Остановленные',
  TAB_PLANNED_TITLE: 'Запланированные',

  NAME_MAX_LENGTH_ERROR_MESSAGE: 'Название рекламной кампании не должно превышать 128 символов',

  INSTANT_AD_CANNOT_STOP_MESSAGE: 'Остановка рекламной кампании типа Моментальный ролик не предусмотрена',

  AD_LINKED_TAGS_TITLE: 'Теги',
  AD_LINKED_TAGS_ADD_TITLE: 'Добавить',
  AD_LINKED_TAGS_CHANGE_TITLE: 'Изменить',
  AD_LINKED_TAGS_SET_SUCCESS: 'Теги рекламной кампании изменены',

  AD_COPY_SUFFIX: '(копия)',
};
