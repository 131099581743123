export enum PointEventNameAMP {
  POINT_CREATED = 'point created',
  POINT_CREATE_BUTTON_CLICK = 'point create button was clicked',
  POINT_CREATE_MODAL_SUCCESS_SUBMIT = 'point create modal was successfully submitted',
  ADDRESS_PAGE_SHOWN = 'address page shown',
  ADDRESS_LIST_SHOWN = 'address list shown',
  POINT_PAGE_SHOWN = 'point page shown',
  POINT_CHANGE_QUALITY = 'point change quality',
  POINT_ADS_FILTER_TAGS_CLICKED = 'point ads filter tags clicked',
  POINT_ADS_FILTER_TAG_APPLIED = 'point ads filter tag applied',
  POINT_ADS_FILTER_TAG_AD_CLICKED = 'point ads filter tag ad clicked',
  POINT_ADS_FILTER_TAG_SAVE_CLICKED = 'point ads filter tag save clicked',
}

export enum PointParamNameAMP {
  POINT_ID = 'point_id',
  POINT_IS_ENABLED = 'point_is_enabled',
  FROM_PAGE = 'from_page',
  COMPANY_ID = 'company_id',
  QUALITY = 'quality',
}

export enum PointQuality {
  LOW = 'LOW',
  HIGH = 'HIGH',
}
