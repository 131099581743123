import {
  useMediaAdCampaignSetCompanyTagsMutation,
  AdCampaignPureSetCompanyTagsMutationInput,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { AdCampaignSetTagResultType, UseAdCampaignSetTagsType } from './types';

const useAdCampaignSetTags = (): UseAdCampaignSetTagsType => {
  const [createMutation, createMutationResult] = useMediaAdCampaignSetCompanyTagsMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: AdCampaignPureSetCompanyTagsMutationInput) => {
    const newTag = await createMutation({
      variables: {
        input,
      },
    });

    return !!newTag.data?.adcampaignSetCompanyTags?.ok;
  };

  const result: AdCampaignSetTagResultType = {
    error: createMutationResult.error,
    loading: createMutationResult.loading,
    called: createMutationResult.called,
  };

  return [request, result];
};

export default useAdCampaignSetTags;
