import React, { useEffect, useMemo, useRef } from 'react';

import { KEYS, useInjection, UsePointRepositoryType } from 'application/providers/DIContainerProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import {
  PointsPlayingStatusesPureType,
  PointQueryFilter,
  PointQueryPagination,
  PointQuerySort,
  SmartlkPointCreateMutationInput,
  PointPureUpdateMutationInput,
  PointPureSleepSettingsMutationInput,
  PointSwitchDeviceMutationInput,
  CertificatePointsQueryFilter,
  PointPureSetAdCampaignsMutationInput,
} from 'domain/api/graphql/generated';
import { PointCreateRequestResultType } from 'domain/point/types';

import { PointModelContextProps, PointModelContextType } from './types';
import { ALL_POINT_STATUSES_COUNT, DEFAULT_FILTERS, DEFAULT_PAGINATION, DEFAULT_PAGINATION_INFO } from './consts';

const PointModelContext = React.createContext<PointModelContextType>({} as PointModelContextType);

export const usePointModelContext = () => React.useContext(PointModelContext);

const PointModelProvider: React.FC<PointModelContextProps> = ({ children, pageSize = DEFAULT_PAGINATION.pageSize }) => {
  const { currentUser, currentCompany, companyIds, isReadyToUseLK } = useAccountContext();

  const usePointRepository = useInjection<UsePointRepositoryType>(KEYS.POINT_REPOSITORY);
  const {
    pointsResult,
    pointsCertificateResult,
    timezones,
    getPoints,
    readPoints,
    getMorePoints,
    getTimezones,
    getPoint,
    point,
    pointLoading,
    pointHistory,
    pointHistoryPaginationInfo,
    createPointLoading,
    createPointError,
    updatePointLoading,
    updatePointError,
    removePointLoading,
    removePointError,
    updatePointSleepSettingsLoading,
    updateDeviceLoading,
    updateDeviceError,
    updatedPointDevice,
    pointHistoryLoading,
    pointAdsUpdateLoading,
    pointAdsUpdateError,
    createPoint: createPointRepository,
    updatePoint: updatePointRepository,
    removePoint: removePointRepository,
    pointSetAdCampaigns: setAdCampaigns,
    pointSetCompanyTags: setCompanyTags,
    pointSetSleepSettings: setSleepSettings,
    pointSetDevice: setDevice,
    getPointHistory: getHistory,
    getMorePointHistory: getMoreHistory,
    downloadPointHistory: getPointHistoryReport,
    pointHistoryReportCreateLoading,
    pointHistoryReportCreateError,
  } = usePointRepository();

  const filtersRef = useRef<PointQueryFilter | CertificatePointsQueryFilter>({
    ...DEFAULT_FILTERS,
    companyIds,
  });

  const paginationRef = useRef<PointQueryPagination>({
    page: DEFAULT_PAGINATION.page,
    pageSize,
  });

  const sortRef = useRef<PointQuerySort>(PointQuerySort.IdDesc);
  const allPointsCount = useRef<null | number>(null);

  const cachedPoints = readPoints({
    filters: filtersRef.current,
    pagination: paginationRef.current,
    sort: sortRef.current,
  });

  const initModel = async () => {
    await getTimezones();
    await getPoints({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });
  };

  const resetFilters = () => {
    filtersRef.current = {
      ...DEFAULT_FILTERS,
      companyIds,
    };

    paginationRef.current = DEFAULT_PAGINATION;

    sortRef.current = PointQuerySort.IdDesc;
  };

  useEffect(() => () => resetFilters(), []);

  useEffect(() => {
    if (currentUser?.id && currentCompany?.id && isReadyToUseLK) {
      initModel();
    }
  }, [currentUser?.id, currentCompany?.id, isReadyToUseLK]);

  useEffect(() => {
    if (
      pointsResult.data.paginationInfo?.totalCount !== undefined &&
      (allPointsCount.current === null || allPointsCount.current === 0)
    ) {
      allPointsCount.current = pointsResult.data.paginationInfo?.totalCount || 0;
    }
  }, [pointsResult, pointsCertificateResult]);

  const requestPoints = async (
    filters: PointQueryFilter = DEFAULT_FILTERS,
    pagination: PointQueryPagination = {
      page: DEFAULT_PAGINATION.page,
      pageSize: pageSize || DEFAULT_PAGINATION.pageSize,
    }
  ) => {
    filtersRef.current = {
      ...filtersRef.current,
      ...filters,
      companyIds,
    };

    paginationRef.current = {
      ...paginationRef.current,
      ...pagination,
    };

    await getPoints({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });
  };

  const readPointsWithDefaultFilters = (
    filters: PointQueryFilter = DEFAULT_FILTERS,
    pagination: PointQueryPagination = {
      page: DEFAULT_PAGINATION.page,
      pageSize: pageSize || DEFAULT_PAGINATION.pageSize,
    }
  ) => {
    filtersRef.current = {
      ...filtersRef.current,
      ...filters,
      companyIds,
    };

    paginationRef.current = {
      ...paginationRef.current,
      ...pagination,
    };

    const result = readPoints({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });

    return result;
  };

  const loadMorePoints = async () => {
    const page = (pointsResult.data.paginationInfo?.page || 0) + 1;

    if (page <= paginationRef.current.page) return;

    paginationRef.current.page = page;

    await getMorePoints({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });
  };

  const requestPoint = async (id: string) => {
    await getPoint(id);
  };

  const createPoint = async (input: SmartlkPointCreateMutationInput): Promise<PointCreateRequestResultType> => {
    const result = await createPointRepository(input);

    if (result && result.ok) {
      resetFilters();
      await requestPoints();
    }

    return result;
  };

  const updatePoint = async (input: PointPureUpdateMutationInput) => {
    const isOk = await updatePointRepository(input);
    return isOk;
  };

  const removePoint = async (id: string): Promise<boolean> => {
    const isOk = await removePointRepository(id);

    if (isOk) {
      resetFilters();
    }

    return isOk;
  };

  const pointSetAdCampaigns = async (input: PointPureSetAdCampaignsMutationInput) => {
    const isOk = await setAdCampaigns(input);
    return isOk;
  };

  const pointSetCompanyTags = async (pointId: string, companyTagIds: string[]) => {
    const isOk = await setCompanyTags(pointId, companyTagIds);

    if (isOk) {
      resetFilters();
      await requestPoints();
      await requestPoint(pointId);
    }

    return isOk;
  };

  const pointSetSleepSettings = async (input: PointPureSleepSettingsMutationInput) => {
    const isOk = await setSleepSettings(input);
    return isOk;
  };

  const pointSetDevice = async (input: PointSwitchDeviceMutationInput) => {
    const isOk = await setDevice(input);
    return isOk;
  };

  const requestPointHistory = async (pointId: string, dateFrom: string, dateTo: string) => {
    await getHistory(pointId, dateFrom, dateTo);
  };

  const loadMorePointHistory = async (pointId: string, dateFrom: string, dateTo: string) => {
    await getMoreHistory(pointId, dateFrom, dateTo);
  };

  const downloadPointHistory = async (pointId: string, reportDate: string) => {
    const result = await getPointHistoryReport(pointId, reportDate);
    return result;
  };

  const preparedPoints = useMemo(
    () =>
      cachedPoints?.result?.map((item) => ({
        id: item.id,
        isEnabled: item.isEnabled,
        device: item.device,
        address: item.address,
        stream: item.stream,
        playingStatus: item.playingStatus,
        comment: item.comment,
        tags: item.tags,
        certificateIsAvailable: item.certificateIsAvailable,
        cutDuration: item.cutDuration,
        withMusicCut: item.withMusicCut,
        videoStream: item.videoStream,
      })) || [],
    [cachedPoints]
  );

  const preparedStatusesInfo = useMemo(() => {
    let statusesInfo: PointsPlayingStatusesPureType = {};

    const { data } = pointsResult;

    if (data.statusesInfo) {
      statusesInfo = { ...data.statusesInfo };
      delete statusesInfo.__typename;
    }

    return statusesInfo;
  }, [pointsResult.data.statusesInfo]);

  const preparedTimezones = useMemo(() => {
    if (!timezones) return [];

    return timezones.map((timezone) => ({
      timezone: timezone?.timezone || '',
      title: timezone?.title || '',
    }));
  }, [timezones]);

  const pointsModel: PointModelContextType = {
    points: preparedPoints,
    point,
    pointLoading,
    pointHistory,
    pointHistoryPaginationInfo,
    statusesInfo: preparedStatusesInfo,
    paginationInfo: pointsResult.data.paginationInfo || DEFAULT_PAGINATION_INFO,
    certificatePointsCount: pointsCertificateResult?.certificatePointsCount,
    filters: filtersRef.current,
    timezones: preparedTimezones,
    pointsLoading: pointsResult.loading,
    createPointLoading,
    createPointError,
    updatePointLoading,
    updatePointError,
    removePointLoading,
    removePointError,
    updatePointSleepSettingsLoading,
    updateDeviceLoading,
    updateDeviceError,
    updatedPointDevice,
    pointHistoryLoading,
    pointHistoryReportCreateLoading,
    pointHistoryReportCreateError,
    pointAdsUpdateLoading,
    pointAdsUpdateError,
    pointsCalled: pointsResult.called,
    allPointsCount: allPointsCount.current,
    allPointStatusesCount: ALL_POINT_STATUSES_COUNT,
    requestPoints,
    readPoints: readPointsWithDefaultFilters,
    loadMorePoints,
    requestPoint,
    createPoint,
    updatePoint,
    removePoint,
    pointSetAdCampaigns,
    pointSetCompanyTags,
    pointSetSleepSettings,
    pointSetDevice,
    requestPointHistory,
    loadMorePointHistory,
    downloadPointHistory,
    resetFilters,
  };

  return <PointModelContext.Provider value={pointsModel}>{children}</PointModelContext.Provider>;
};

export default PointModelProvider;
