import { useBillingOrganizationCurrentPricePlanLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useCurrentPricePlan = () => {
  const [query, queryResult] = useBillingOrganizationCurrentPricePlanLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (organizationId: string) => {
    const result = await query({
      variables: {
        organizationId,
      },
    });

    return result;
  };

  const pricePlan = queryResult.data?.result;

  return {
    request,
    error: queryResult.error,
    loading: queryResult.loading,
    data: {
      pricePlan,
    },
  };
};

export default useCurrentPricePlan;
