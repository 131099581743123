import { useMediaLikeMediafileMutation, useMediaUnLikeMediafileMutation } from 'domain/api/graphql/generated';

const useMediaLikeAndUnLike = () => {
  const [likeRequest, likeResult] = useMediaLikeMediafileMutation({
    refetchQueries: ['mediaStream', 'mediafilesApproval'],
  });
  const [unLikeRequest, unlikeResult] = useMediaUnLikeMediafileMutation({
    refetchQueries: ['mediaStream', 'mediafilesApproval'],
  });

  const unLikeMediaFile = (streamId: string, mediafileId: string) =>
    unLikeRequest({
      variables: {
        input: {
          streamId,
          mediafileId,
        },
      },
    });

  const likeMediaFile = (streamId: string, mediafileId: string) =>
    likeRequest({
      variables: {
        input: {
          streamId,
          mediafileId,
        },
      },
    });

  return {
    likeResult,
    unlikeResult,
    unLikeMediaFile,
    likeMediaFile,
  };
};

export default useMediaLikeAndUnLike;
