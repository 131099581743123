import React, { useContext } from 'react';

import {
  useStreamRepository,
  usePointRepository,
  useBillingRepository,
  usePaymentMethodRepository,
  usePricePlanRepository,
  useAdCampaignRepository,
  useAccountRepository,
  useBusinessProfileRepository,
  useCompanyRepository,
  useCompanyTagsRepository,
} from 'application/repositories';

import Container from './DIContainer';
import { KEYS } from './consts';

export type UsePointRepositoryType = typeof usePointRepository;
export type UseAdCampaignRepositoryType = typeof useAdCampaignRepository;
export type UseStreamRepositoryType = typeof useStreamRepository;
export type UseBillingRepositoryType = typeof useBillingRepository;
export type UsePaymentMethodRepositoryType = typeof usePaymentMethodRepository;
export type UsePricePlanRepositoryType = typeof usePricePlanRepository;
export type UseAccountRepositoryType = typeof useAccountRepository;
export type UseBusinessProfileRepositoryType = typeof useBusinessProfileRepository;
export type UseCompanyRepositoryType = typeof useCompanyRepository;
export type UseCompanyTagsRepositoryType = typeof useCompanyTagsRepository;

export type DIContainerType = {
  container: typeof Container;
};

export const DIContext = React.createContext<Partial<DIContainerType>>({
  container: {},
} as DIContainerType);

const DIContainerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  Container.register<UsePointRepositoryType>(KEYS.POINT_REPOSITORY, usePointRepository);
  Container.register<UseAdCampaignRepositoryType>(KEYS.AD_CAMPAIGN_REPOSITORY, useAdCampaignRepository);
  Container.register<UseStreamRepositoryType>(KEYS.STREAM_REPOSITORY, useStreamRepository);
  Container.register<UseBillingRepositoryType>(KEYS.BILLING_REPOSITORY, useBillingRepository);
  Container.register<UsePaymentMethodRepositoryType>(KEYS.PAYMENT_METHOD_REPOSITORY, usePaymentMethodRepository);
  Container.register<UsePricePlanRepositoryType>(KEYS.PRICE_PLAN_REPOSITORY, usePricePlanRepository);
  Container.register<UseAccountRepositoryType>(KEYS.ACCOUNT_REPOSITORY, useAccountRepository);
  Container.register<UseBusinessProfileRepositoryType>(KEYS.BUSINESS_PROFILE_REPOSITORY, useBusinessProfileRepository);

  Container.register<UseCompanyRepositoryType>(KEYS.COMPANY_REPOSITORY, useCompanyRepository);
  Container.register<UseCompanyTagsRepositoryType>(KEYS.COMPANY_TAGS_REPOSITORY, useCompanyTagsRepository);

  return (
    <DIContext.Provider
      value={{
        container: Container,
      }}>
      {children}
    </DIContext.Provider>
  );
};

const useInjection = <T,>(key: symbol): T => {
  const { container } = useContext(DIContext) as DIContainerType;
  const hookByKey = container.get<T>(key);
  return hookByKey;
};

const usePointRepositoryInjector = () => {
  const usePointRepository = useInjection<UsePointRepositoryType>(KEYS.POINT_REPOSITORY);

  const pointRepository = usePointRepository();

  return { ...pointRepository };
};

const useAdCampaignRepositoryInjector = () => {
  const useAdCampaignRepository = useInjection<UseAdCampaignRepositoryType>(KEYS.AD_CAMPAIGN_REPOSITORY);

  const adCampaignRepository = useAdCampaignRepository();

  return { ...adCampaignRepository };
};

const useStreamRepositoryInjector = () => {
  const useStreamRepository = useInjection<UseStreamRepositoryType>(KEYS.STREAM_REPOSITORY);

  const streamRepository = useStreamRepository();

  return { ...streamRepository };
};

const useBillingRepositoryInjector = () => {
  const useBillingRepository = useInjection<UseBillingRepositoryType>(KEYS.BILLING_REPOSITORY);

  const billingRepository = useBillingRepository();

  return { ...billingRepository };
};

const usePaymentMethodRepositoryInjector = () => {
  const usePaymentMethodRepository = useInjection<UsePaymentMethodRepositoryType>(KEYS.PAYMENT_METHOD_REPOSITORY);

  const paymentMethodRepository = usePaymentMethodRepository();

  return { ...paymentMethodRepository };
};

const usePricePlanRepositoryInjector = () => {
  const usePricePlanRepository = useInjection<UsePricePlanRepositoryType>(KEYS.PRICE_PLAN_REPOSITORY);

  const pricePlanRepository = usePricePlanRepository();

  return { ...pricePlanRepository };
};

const useAccountRepositoryInjector = () => {
  const useAccountRepository = useInjection<UseAccountRepositoryType>(KEYS.ACCOUNT_REPOSITORY);

  const accountRepository = useAccountRepository();

  return { ...accountRepository };
};

const useBusinessProfileRepositoryInjector = () => {
  const useBusinessProfileRepository = useInjection<UseBusinessProfileRepositoryType>(KEYS.BUSINESS_PROFILE_REPOSITORY);

  const businessProfileRepository = useBusinessProfileRepository();

  return { ...businessProfileRepository };
};

const useCompanyRepositoryInjector = () => {
  const useCompanyRepository = useInjection<UseCompanyRepositoryType>(KEYS.COMPANY_REPOSITORY);

  const companyRepository = useCompanyRepository();

  return { ...companyRepository };
};

const useCompanyTagsRepositoryInjector = () => {
  const useCompanyTagsRepository = useInjection<UseCompanyTagsRepositoryType>(KEYS.COMPANY_TAGS_REPOSITORY);

  const companyTagsRepository = useCompanyTagsRepository();

  return { ...companyTagsRepository };
};

export {
  useInjection,
  KEYS,
  usePointRepositoryInjector,
  useAdCampaignRepositoryInjector,
  useStreamRepositoryInjector,
  useBillingRepositoryInjector,
  usePaymentMethodRepositoryInjector,
  usePricePlanRepositoryInjector,
  useAccountRepositoryInjector,
  useBusinessProfileRepositoryInjector,
  useCompanyRepositoryInjector,
  useCompanyTagsRepositoryInjector,
};

export default DIContainerProvider;
