import { useBillingRemoveCardMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useRemoveCard = () => {
  const [mutation, mutationResult] = useBillingRemoveCardMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (companyId: string) => {
    try {
      const mutationResult = await mutation({
        variables: {
          input: {
            companyId,
          },
        },
        refetchQueries: ['billingPaymentMethod'],
      });
      return !!mutationResult.data?.companyRemoveCard?.ok;
    } catch (e) {
      return false;
    }
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
  };

  return {
    request,
    result,
  };
};

export default useRemoveCard;
