import React, { useState, FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';

import usePasswordChange from 'domain/auth/usePasswordChange';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import PasswordInput from '@zvuk-b2b/react-uikit/ui/PasswordInput';

import ProfileInfo from '../ProfileInfo';
import { TEXTS } from '../texts';

import styles from './index.module.scss';

export type ChangePasswordFormErrors = {
  currentPassword?: string;
  newPassword?: string;
};

export type ChangePasswordModalProps = {};

const ChangePasswordModal: FC<ModalProps[ModalTypes.PROFILE_CHANGE_PASSWORD]> = ({ close }) => {
  const notification = useNotificationContext();

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const [passwordChangeRequest, passwordChangeResult] = usePasswordChange();

  const onCurrentPasswordChange = (value: string) => {
    setCurrentPassword(value);
  };

  const onNewPasswordChange = (value: string) => {
    setNewPassword(value);
  };

  const onChangePasswordSubmit = async (currentPassword: string, newPassword: string) => {
    try {
      const isSuccess = await passwordChangeRequest({
        currentPassword,
        newPassword,
      });

      if (isSuccess) {
        notification.showNotification({
          type: NotificationColors.INFO,
          children: TEXTS.PROFILE_CHANGE_PASSWORD_SUCCESS,
          delay: NOTIFICATION_DEFAULT_DELAY,
        });

        close?.();
      }
    } catch (e: unknown) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const handleSubmit = () => {
    onChangePasswordSubmit(currentPassword, newPassword);
  };

  return (
    <ModalScreen
      onClose={close}
      className={styles.ChangePasswordModal}
      actionButtons={[
        <Button
          key="button_change_password_submit"
          elementId="button_change_password_submit"
          isLoading={passwordChangeResult.loading}
          isDisabled={currentPassword.length === 0 || newPassword.length === 0}
          type={ButtonType.PRIMARY}
          onClick={handleSubmit}>
          {TEXTS.PROFILE_CHANGE_PASSWORD_BUTTON}
        </Button>,
      ]}>
      <div className={styles.ChangePasswordModalContent}>
        <ProfileInfo />

        <PasswordInput
          elementId="change_password_input_current_password"
          value={currentPassword}
          isDisabled={passwordChangeResult.loading}
          error={passwordChangeResult.data.errors?.currentPassword?.[0]}
          containerClassName={styles.ChangePasswordModalContentPasswordInput}
          label={TEXTS.PROFILE_CHANGE_PASSWORD_CURRENT}
          placeholder={TEXTS.PROFILE_CHANGE_PASSWORD_CURRENT}
          onChange={onCurrentPasswordChange}
        />

        <PasswordInput
          elementId="change_password_input_new_password"
          value={newPassword}
          isDisabled={passwordChangeResult.loading}
          error={passwordChangeResult.data.errors?.newPassword?.[0]}
          containerClassName={styles.ChangePasswordModalContentPasswordInput}
          label={TEXTS.PROFILE_CHANGE_PASSWORD_NEW}
          placeholder={TEXTS.PROFILE_CHANGE_PASSWORD_NEW}
          onChange={onNewPasswordChange}
          onEnterPressed={handleSubmit}
        />
      </div>
    </ModalScreen>
  );
};

export default ChangePasswordModal;
