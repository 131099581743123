import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import PAGES_CONST from 'application/pages/constants';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export enum InviteQueryKeys {
  TOKEN = 'token',
}

export const INVITE_PAGE_PATH = '/invite';

const Renderer = React.lazy(() => import('./renderer'));

const InvitePageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

InvitePageWrapper.config = {
  routes: [
    {
      path: INVITE_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.ANONYMOUS, LocalAccessRoles.AUTHORIZED],
};

export default InvitePageWrapper;
