import { matchPath, generatePath } from 'react-router-dom';
import { parse, stringify } from 'query-string';

import { PageConfigType, PageConfigRouteType } from 'application/pages/types';
import { LocalAccessRoles } from '../AuthProvider';

export type PathParamsType = { [s: string]: string | number | undefined };

export const TARGET_REDIRECT_URL_KEY = 'TARGET_REDIRECT_URL';

class RouterUtils {
  static findPageConfigByPathInPageConfigs<S extends PageConfigType = PageConfigType>(
    pageConfigs: S[],
    pathname: string
  ): S | undefined | null {
    return pageConfigs.find((pageConfig: S) => !!RouterUtils.findPageConfigRouteByPath(pageConfig, pathname));
  }

  static findPageConfigRouteByPathInPageConfigs(
    pageConfigs: PageConfigType[],
    pathname: string
  ): PageConfigRouteType | undefined | null {
    for (let i = 0; i < pageConfigs.length; i++) {
      const pageConfig = pageConfigs[i];
      const pageConfigRoute = RouterUtils.findPageConfigRouteByPath(pageConfig, pathname);
      if (pageConfigRoute) {
        return pageConfigRoute;
      }
    }

    return null;
  }

  static findPageConfigRouteByPath(
    pageConfig: PageConfigType,
    pathname: string
  ): PageConfigRouteType | undefined | null {
    return pageConfig.routes.find((route) => !!matchPath({ path: route.path, end: false }, pathname));
  }

  static mapPageConfigRouteInPageConfigs<T, S extends PageConfigType = PageConfigType>(
    configs: S[],
    callback: (route: PageConfigRouteType, config: S) => T
  ): Array<T> {
    return configs.reduce<Array<T>>((acc, config) => {
      config.routes.forEach((route) => {
        acc.push(callback(route, config));
      });
      return acc;
    }, []);
  }

  static generatePath(pathname: string, params?: PathParamsType, query?: PathParamsType): string {
    return query ? `${generatePath(pathname, params)}?${stringify(query)}` : generatePath(pathname, params);
  }

  static readTargetPathname() {
    return localStorage.getItem(TARGET_REDIRECT_URL_KEY);
  }

  static clearTargetPathname() {
    localStorage.removeItem(TARGET_REDIRECT_URL_KEY);
  }

  static setTargetPathname(value: string) {
    localStorage.setItem(TARGET_REDIRECT_URL_KEY, value);
  }

  static getURLSearchParamsAsObject() {
    return parse(window.location.search);
  }

  static getPathnameFromStorage = (accessRole: LocalAccessRoles): string | undefined => {
    const targetRedirectURL = RouterUtils.readTargetPathname();

    if (targetRedirectURL && accessRole === LocalAccessRoles.AUTHORIZED) {
      RouterUtils.clearTargetPathname();
      return targetRedirectURL;
    }

    return undefined;
  };
}

export default RouterUtils;
