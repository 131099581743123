export const TEXTS = {
  BUSINESS_PROFILE_UPSERT_MODAL_CREATE_TITLE: 'Создание бизнес-профиля',
  BUSINESS_PROFILE_UPSERT_MODAL_UPDATE_TITLE: 'Редактирование бизнес-профиля',
  BUSINESS_PROFILE_UPSERT_MODAL_CREATE_SUCCESS_TEXT: 'Бизнес-профиль создан',
  BUSINESS_PROFILE_UPSERT_MODAL_UPDATE_SUCCESS_TEXT: 'Профиль будет обновлен',
  BUSINESS_PROFILE_UPSERT_MODAL_CREATE_ERROR_TEXT: 'Ошибка при создании бизнес-профиля. Проверьте введенные данные',
  BUSINESS_PROFILE_UPSERT_MODAL_UPDATE_ERROR_TEXT:
    'Ошибка при обновлении/публикации бизнес-профиля. Проверьте введенные данные',
  BUSINESS_PROFILE_UPSERT_MODAL_SYNC_STATUS_ERROR_TEXT: 'Бизнес-профиль еще не синхронизирован. Попробуйте позже',
  BUSINESS_PROFILE_UPSERT_MODAL_BUTTON_CREATE_TITLE: 'Создать',
  BUSINESS_PROFILE_UPSERT_MODAL_BUTTON_UPDATE_TITLE: 'Обновить',
  BUSINESS_PROFILE_UPSERT_MODAL_BUTTON_PUBLISH_TITLE: 'Опубликовать',
};
