import { PromoAdInput, useMediaAdCampaignPromosUpdateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseAdCampaignPromoUploadType, UsePromoUploadResultType } from './types';

const useAdPromosUpdate = (): UseAdCampaignPromoUploadType => {
  const [createMutation, createMutationResult] = useMediaAdCampaignPromosUpdateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (adCompanyId: string, adPromos: PromoAdInput[]) => {
    const newAdCampaign = await createMutation({
      variables: {
        input: {
          adCompanyId,
          adPromos: adPromos.map(({ promoAdId, promoId, time }) => ({
            promoAdId,
            promoId,
            time,
          })),
        },
      },
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
    });

    return !!newAdCampaign.data?.result?.ok;
  };

  const result: UsePromoUploadResultType = {
    error: createMutationResult.error,
    loading: createMutationResult.loading,
    called: createMutationResult.called,
  };

  return [request, result];
};

export default useAdPromosUpdate;
