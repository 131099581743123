import { useState } from 'react';

import {
  PointQueryFilter,
  PointQueryPagination,
  PointQuerySort,
  usePointListMetadataQuery,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UsePointListMetadataResultType, UsePointListMetadataType, PointListQueryState } from './types';

const usePointsMetadata = (): UsePointListMetadataType => {
  const [queryState, setQueryState] = useState<PointListQueryState>({
    query: '',
    companyIds: [],
    companyTagIds: [],
    playingStatuses: [],
    page: 0,
    pageSize: 0,
    sort: PointQuerySort.IdDesc,
    skip: true,
  });

  const { called, loading, error, networkStatus, fetchMore, refetch } = usePointListMetadataQuery({
    variables: {
      filters: {
        query: queryState.query,
        companyIds: queryState.companyIds,
        companyTagIds: queryState.companyTagIds as string[],
        playingStatuses: queryState.playingStatuses,
      },
      pagination: { page: queryState.page, pageSize: queryState.pageSize },
      sort: queryState.sort,
    },
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
    skip: queryState.skip,
  });

  const request = async (filters: PointQueryFilter, pagination: PointQueryPagination, sort: PointQuerySort) => {
    setQueryState({
      query: filters.query,
      companyIds: filters.companyIds || [],
      companyTagIds: filters.companyTagIds || [],
      playingStatuses: filters.playingStatuses || [],
      page: pagination.page,
      pageSize: pagination.pageSize,
      sort,
      skip: false,
    });
  };

  async function loadMore(filters: PointQueryFilter, pagination: PointQueryPagination, sort: PointQuerySort) {
    await fetchMore({
      variables: {
        filters,
        pagination,
        sort,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const data = {
          ...prev,
          pointsPaginationQuery: {
            ...prev?.pointsPaginationQuery,
            ...fetchMoreResult?.pointsPaginationQuery,
            result: [
              ...(prev?.pointsPaginationQuery?.result || []),
              ...(fetchMoreResult?.pointsPaginationQuery?.result || []),
            ],
          },
        };

        return data;
      },
    });
  }

  const result: UsePointListMetadataResultType = {
    error,
    loading,
    called,
    networkStatus,
    loadMore,
    refetch,
  };

  return [request, result];
};

export default usePointsMetadata;
