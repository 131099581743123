import React, { FC } from 'react';

import { TEXTS } from 'application/pages/BillingPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import useExtendSubscriptionModalPresenter from './useExtendSubscriptionModalPresenter';
import useExtendSubscriptionModalController from './useExtendSubscriptionModalController';

import styles from './index.module.scss';

const ExtendSubscriptionModal: FC<ModalProps[ModalTypes.BILLING_EXTEND_SUBSCRIPTION]> = ({
  close,
  licenses,
  closeAllModals,
}) => {
  const {
    licenseSubscriptionLoading,
    getSubscriptionCost,
    hasInactiveLicenses,
    launchSubscription,
    extendSubscription,
    billingInfoRequest,
  } = useExtendSubscriptionModalPresenter();

  const { subscriptionCost, onExtendSubscriptionConfirm } = useExtendSubscriptionModalController({
    licenses,
    hasInactiveLicenses,
    getSubscriptionCost,
    launchSubscription,
    extendSubscription,
    billingInfoRequest,
    closeAllModals,
  });

  return (
    <ModalScreen
      title={TEXTS.BILLING_EXTEND_SUBSCRIPTION_MODAL_TITLE}
      hideCloseButton
      onClose={close}
      actionButtons={[
        <Button
          key="button_confirm_extend_subscription_modal"
          elementId="button_confirm_extend_subscription_modal"
          isLoading={licenseSubscriptionLoading || !subscriptionCost}
          type={ButtonType.PRIMARY}
          onClick={onExtendSubscriptionConfirm}>
          {TEXTS.BILLING_LICENSES_AMOUNT_BUTTON_TITLE(subscriptionCost)}
        </Button>,
        <Button
          key="button_cancel_extend_subscription_modal"
          elementId="button_cancel_extend_subscription_modal"
          type={ButtonType.SECONDARY}
          onClick={close}>
          {TEXTS.BILLING_EXTEND_SUBSCRIPTION_MODAL_CANCEL}
        </Button>,
      ]}>
      <div className={styles.ExtendSubscriptionModalDesc}>{TEXTS.BILLING_EXTEND_SUBSCRIPTION_MODAL_DESC}</div>
    </ModalScreen>
  );
};

export default ExtendSubscriptionModal;
