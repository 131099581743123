import { NOTIFICATION_DEFAULT_DELAY, useNotificationContext } from 'application/providers/NotificationProvider';
import { TEXTS } from '../texts';

export type WebPlayerSettingsControllerProps = {
  bindingCode: string;
};

export const useWebPlayerSettingsController = ({ bindingCode }: WebPlayerSettingsControllerProps) => {
  const notification = useNotificationContext();

  const onCopyClick = () => {
    navigator.clipboard.writeText(
      `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/web-player/${bindingCode}`
    );

    notification.showInfoNotification({
      children: TEXTS.LINK_COPIED,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  return { onCopyClick };
};
