import { useCallback, useEffect, useMemo, useState } from 'react';

import { LicenseInfoTypeFragment } from 'domain/billing/types';

import { ModalLicenseType } from './LicensesList';

import { UseLicensesModalControllerProps } from './types';

const useLicensesModalController = ({
  unpaidLicensesIds,
  totalLicenses,
  getSubscriptionCost,
}: UseLicensesModalControllerProps) => {
  const [toSuspendAutorenewal, setToSuspendAutorenewal] = useState<string[]>([]);
  const [toEnableAutorenewal, setToEnableAutorenewal] = useState<string[]>(unpaidLicensesIds);
  const [licensesSubscriptionCost, setLicensesSubscriptionCost] = useState<number>(0);

  const isSubmitButtonDisabled = !(toSuspendAutorenewal.length > 0 || toEnableAutorenewal.length > 0);

  const getModalLicenseItem = useCallback(
    (item: LicenseInfoTypeFragment): ModalLicenseType => {
      const isInSuspendAutorenewal = toSuspendAutorenewal.indexOf(item.id) > -1;
      const isInEnableAutorenewal = toEnableAutorenewal.indexOf(item.id) > -1;
      const isChecked = isInEnableAutorenewal || (isInSuspendAutorenewal ? false : item.autoRenewal);

      return {
        ...item,
        isChecked,
      };
    },
    [toSuspendAutorenewal, toEnableAutorenewal]
  );

  const modalLicenseItems = useMemo(() => totalLicenses.map(getModalLicenseItem), [totalLicenses, getModalLicenseItem]);

  const onChange = useCallback(
    (item: LicenseInfoTypeFragment, value: boolean) => {
      if (item.autoRenewal && value) {
        setToSuspendAutorenewal(toSuspendAutorenewal.filter((id) => id !== item.id));
      }

      if (item.autoRenewal && !value) {
        setToSuspendAutorenewal([...toSuspendAutorenewal.filter((id) => id !== item.id), item.id]);
      }

      if (!item.autoRenewal && value) {
        setToEnableAutorenewal([...toEnableAutorenewal.filter((id) => id !== item.id), item.id]);
      }

      if (!item.autoRenewal && !value) {
        setToEnableAutorenewal(toEnableAutorenewal.filter((id) => id !== item.id));
      }
    },
    [toSuspendAutorenewal, toEnableAutorenewal]
  );

  const setSubscriptionCost = async () => {
    if (toEnableAutorenewal.length > 0) {
      const cost = await getSubscriptionCost(toEnableAutorenewal);

      setLicensesSubscriptionCost(cost);
    }
  };

  useEffect(() => {
    setSubscriptionCost();
  }, [toEnableAutorenewal.length]);

  return {
    toSuspendAutorenewal,
    toEnableAutorenewal,
    isSubmitButtonDisabled,
    modalLicenseItems,
    licensesSubscriptionCost,
    onChange,
  };
};

export default useLicensesModalController;
