import { useRef } from 'react';

import { StreamsQuerySort, useMediaCompanyStreamsLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

export type SavedStreamRequestType = {
  companyIds: string[];
};

const PAGE_SIZE = 20;

const useStreamsSaved = () => {
  const [companyStreamsRequest, companyStreamsResult] = useMediaCompanyStreamsLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const ref = useRef(0);

  const request = async ({ companyIds }: SavedStreamRequestType) => {
    ref.current = 0;
    await companyStreamsRequest({
      variables: {
        filters: { companyIds },
        pagination: { page: 0, pageSize: PAGE_SIZE },
        sort: StreamsQuerySort.IdDesc,
      },
    });
  };

  const fetchMore = async (companyIds: string[]) => {
    const page = (companyStreamsResult.data?.result.paginationInfo.page || 0) + 1;
    if (page <= ref.current) return;
    ref.current = page;

    await companyStreamsResult.fetchMore({
      variables: {
        filters: { companyIds },
        pagination: {
          page,
          pageSize: PAGE_SIZE,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        result: {
          ...prev?.result,
          ...fetchMoreResult?.result,
          result: [...(prev.result.result || []), ...(fetchMoreResult.result.result || [])],
        },
      }),
    });
  };

  return {
    request,
    fetchMore,
    error: companyStreamsResult.error,
    called: companyStreamsResult.called,
    loading: companyStreamsResult.loading,
    streams: companyStreamsResult.data?.result.result || undefined,
    paginationInfo: companyStreamsResult.data?.result.paginationInfo || undefined,
  };
};

export default useStreamsSaved;
