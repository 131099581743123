import React, { useMemo } from 'react';
import classNames from 'classnames';

import { Link, RouterUtils, useLocation } from 'application/providers/RouterProvider';
import { MenuItemDataType } from 'application/providers/MenuItemsProvider/types';

import styles from './index.module.scss';

export type SmartMenuItemProps = {
  pageMenu: MenuItemDataType;
  elementId: string;
  isExpanded: boolean;
  className?: string;
  isMarked?: boolean;
  isNoticed?: boolean;
};

const SmartMenuItem = (props: SmartMenuItemProps) => {
  const { pageMenu, elementId, isExpanded, className, isMarked, isNoticed } = props;
  const location = useLocation();

  const isSelected = useMemo(
    () => !!(pageMenu.config && !!RouterUtils.findPageConfigRouteByPath(pageMenu.config, location.pathname)),
    [pageMenu.config, location.pathname]
  );

  const Icon: MenuItemDataType['icon'] = pageMenu.icon;
  const content = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      role="menuitem"
      id={elementId}
      className={classNames(styles.SmartMenuItemContent, className)}
      data-selected={isSelected}
      data-marked={isMarked}
      data-noticed={isNoticed}
      onClick={pageMenu.onClick}>
      {Icon && (
        <>
          <Icon
            className={classNames(
              styles.SmartMenuItemContentIcon,
              isExpanded ? styles.SmartMenuItemContentIconExpanded : undefined
            )}
          />
          {isNoticed && <div className={styles.SmartMenuItemContentNoticed} />}
        </>
      )}

      <span
        className={styles.SmartMenuItemContentTitle}
        data-hidden={!isExpanded}>
        {pageMenu.title}
      </span>
    </li>
  );

  if (pageMenu.pathname) {
    return (
      <Link
        to={{ pathname: pageMenu.pathname }}
        state={pageMenu?.state}
        id={`${elementId}_link`}
        className={styles.SmartMenuItemLink}>
        {content}
      </Link>
    );
  }

  return content;
};

export default SmartMenuItem;
