import {
  useBusinessProfileUpdateMutation,
  ZvukProfileUpdateMutationInput,
  ZvukProfileListDocument,
  AuthCurrentUserDocument,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { BusinessProfileUpdateResultType, UseBusinessProfileUpdateType } from './types';

const useBusinessProfileUpdate = (): UseBusinessProfileUpdateType => {
  const [updateMutation, updateMutationResult] = useBusinessProfileUpdateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukProfileUpdateMutationInput) => {
    const updatedBusinessProfile = await updateMutation({
      variables: {
        input,
      },
      refetchQueries: [ZvukProfileListDocument, AuthCurrentUserDocument],
    });

    return {
      isOk: !!updatedBusinessProfile.data?.zvukProfileUpdate?.ok,
      businessProfile: updatedBusinessProfile.data?.zvukProfileUpdate?.profile,
    };
  };

  const zvukProfileUpdateData = updateMutationResult.data?.zvukProfileUpdate;
  const hasErrors = !!zvukProfileUpdateData?.errors;
  const zvukProfileUpdateResultData = {
    ok: zvukProfileUpdateData?.ok,
    errors: hasErrors ? GraphQLApiUtils.errorsArrayToObject(zvukProfileUpdateData?.errors) : undefined,
  };

  const result: BusinessProfileUpdateResultType = {
    error: updateMutationResult.error,
    loading: updateMutationResult.loading,
    called: updateMutationResult.called,
    data: zvukProfileUpdateResultData,
  };

  return [request, result];
};

export default useBusinessProfileUpdate;
