import React, { useEffect } from 'react';

import { useAccountContext } from 'application/providers/AccountProvider';
import { BusinessSpherePureType, StreamLibraryTypeFragment } from 'domain/api/graphql/generated';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import useGetStreamLibrary from 'domain/media/useGetStreamLibrary';
import useGetCurrentBusinessSphere from 'application/hooks/useCurrentBusinessSphere';

export type StreamLibrariesStateType = {
  currentStreamLibrary?: StreamLibraryTypeFragment | null;
  loading: boolean;
  currentBusinessSphere?: BusinessSpherePureType;
};

const StreamsLibraries = React.createContext({} as StreamLibrariesStateType);

export const useStreamLibrariesContext = () => React.useContext(StreamsLibraries);

const StreamLibrariesProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const notification = useNotificationContext();
  const { currentCompany, streamLibraryId } = useAccountContext();

  const currentStreamLibrary = useGetStreamLibrary();
  const { currentBusinessSphere } = useGetCurrentBusinessSphere();

  const initialLoad = async () => {
    try {
      if (currentCompany && streamLibraryId) {
        await currentStreamLibrary.request(currentCompany.id, streamLibraryId);
      }
    } catch (e) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  useEffect(() => {
    initialLoad();
  }, [currentCompany?.id, streamLibraryId]);

  return (
    <StreamsLibraries.Provider
      value={{
        currentStreamLibrary: currentStreamLibrary.streamLibrary,
        loading: currentStreamLibrary.loading,
        currentBusinessSphere,
      }}>
      {children}
    </StreamsLibraries.Provider>
  );
};

export default StreamLibrariesProvider;
