import React, { FC } from 'react';
import MediaListItemWrapperError from '../MediaListItemIconsError';
import MediaListItemWrapperRemove from '../MediaListItemIconsRemove';

interface MediaListItemIconsUploaderProps {
  id?: string;
  onRetry?: () => void;
  onRemove?: () => void;
  isError: boolean;
  isRemove: boolean;
}

const MediaListItemIconsUploader: FC<MediaListItemIconsUploaderProps> = (props) => {
  const { id, isError, isRemove, onRemove, onRetry } = props;

  if (isError && onRetry) {
    return (
      <MediaListItemWrapperError
        id={id}
        onRetry={onRetry}
      />
    );
  }

  if (isRemove && onRemove) {
    return (
      <MediaListItemWrapperRemove
        id={id}
        onRemove={onRemove}
      />
    );
  }

  return null;
};

export default MediaListItemIconsUploader;
