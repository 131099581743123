import React from 'react';

import styles from './index.module.scss';

const PointListCheckboxItemSkeleton = () => (
  <div className={styles.PointListCheckboxItemSkeleton}>
    <div className={styles.PointListCheckboxItemSkeletonTitle} />
    <div className={styles.PointListCheckboxItemSkeletonStream} />
    <div className={styles.PointListCheckboxItemSkeletonPlayer} />
  </div>
);

export default PointListCheckboxItemSkeleton;
