import React, { Suspense, useEffect } from 'react';

import PlayerProvider from 'application/providers/PlayerProvider';
import PointModelProvider from 'application/pages/PointsPage/providers/PointModelProvider';
import AdCampaignModelProvider from 'application/pages/AdsPage/providers/AdCampaignModelProvider';
import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { RouterUtils, matchPath, useNavigate, useLocation } from 'application/providers/RouterProvider';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export const POINTS_PAGE_PATH = '/points/:id';
export const POINTS_MENU_PATH = '/points/all';

export enum IdParamValues {
  ALL = 'all',
}

export type PointsPageParamsType = {
  id?: string | IdParamValues;
};

const Renderer = React.lazy(() => import('./renderer'));

const PointsPageWrapper: IApplicationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isNeedRedirect = !matchPath({ path: POINTS_PAGE_PATH, end: false }, location.pathname);

  useEffect(() => {
    if (isNeedRedirect) {
      navigate(RouterUtils.generatePath(POINTS_PAGE_PATH, { id: IdParamValues.ALL }), { replace: true });
    }
  }, [isNeedRedirect]);

  return (
    <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
      {!isNeedRedirect && (
        <PlayerProvider>
          <PointModelProvider>
            <AdCampaignModelProvider>
              <Renderer />
            </AdCampaignModelProvider>
          </PointModelProvider>
        </PlayerProvider>
      )}
    </Suspense>
  );
};

PointsPageWrapper.config = {
  routes: [
    {
      path: POINTS_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default PointsPageWrapper;
