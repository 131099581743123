import { MediaListItemProps } from '../types';

interface UseMedialistItemControllerProps {
  isPaused: boolean;
  progress: MediaListItemProps['progress'];
  onClickPlay: MediaListItemProps['onClickPlay'];
  onClickResume: MediaListItemProps['onClickResume'];
  onClickPause: MediaListItemProps['onClickPause'];
}

const useMedialistItemController = (props: UseMedialistItemControllerProps) => {
  const { isPaused, progress, onClickPlay, onClickPause, onClickResume } = props;

  const currentClickHandler = () => {
    if (!progress) {
      onClickPlay();
      return;
    }

    if (isPaused) {
      onClickResume();
      return;
    }

    onClickPause();
  };

  return {
    currentClickHandler,
  };
};

export default useMedialistItemController;
