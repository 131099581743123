import parsePhoneNumberFromString from 'libphonenumber-js';

class PhoneUtils {
  static parsePhoneNumberFromString(value: string) {
    return parsePhoneNumberFromString(value);
  }

  static formatPhoneNumber(value: string) {
    return parsePhoneNumberFromString(value)?.formatInternational() || value;
  }
}

export default PhoneUtils;
