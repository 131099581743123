import React from 'react';

import { useNotificationContext } from 'application/providers/NotificationProvider';
import Toaster from '@zvuk-b2b/react-uikit/ui/Toaster';

const LayoutToaster = () => {
  const notification = useNotificationContext();

  return (
    <Toaster
      elementId="default_toaster_notification_id"
      color={notification.data?.type as unknown as string}
      isOpen={notification.isOpen}
      onClick={notification.data?.closeAfterClick ? () => notification.hideNotification() : undefined}>
      {notification?.data?.children}
    </Toaster>
  );
};

export default LayoutToaster;
