import React from 'react';

import { TEXTS } from 'application/pages/AdsPage/renderer/texts';

import styles from './index.module.scss';

const HINT_IMAGE = '/assets/img/campaigns/instant_hint.svg';

const AdInstantExplanation = () => (
  <div className={styles.AdInstantExplanation}>
    <div>{TEXTS.AD_TYPE_INSTANT_EXPLANATION_ONE}</div>
    <div>{TEXTS.AD_TYPE_INSTANT_EXPLANATION_TWO}</div>

    <div className={styles.AdInstantExplanationTabExample}>
      <span>{TEXTS.TAB_SETTING_TITLE}</span>
      <span className={styles.AdInstantExplanationTabExampleAddress}>{TEXTS.TAB_ADDRESS_TITLE}</span>
      <span>{TEXTS.TAB_PROMOS_TITLE}</span>
    </div>

    <div>{TEXTS.AD_TYPE_INSTANT_EXPLANATION_THREE}</div>

    <img
      src={HINT_IMAGE}
      alt=""
      loading="lazy"
    />
  </div>
);

export default AdInstantExplanation;
