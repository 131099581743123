import { PointPureUpdateMutationInput, usePointUpdateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const usePointUpdate = () => {
  const [mutation, mutationResult] = usePointUpdateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async ({
    pointId,
    timezone,
    comment,
    amplifyTrack,
    amplifyPromo,
    amplifyInstant,
    address,
    volume,
    withMusicCut,
    cutDuration,
    fileFormat,
  }: PointPureUpdateMutationInput) => {
    const mutationResult = await mutation({
      variables: {
        input: {
          pointId,
          timezone,
          comment,
          amplifyTrack,
          amplifyPromo,
          amplifyInstant,
          address,
          volume,
          withMusicCut,
          cutDuration,
          fileFormat,
        },
      },
    });
    return !!mutationResult.data?.pointPureUpdate?.ok;
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    request,
  };
};

export default usePointUpdate;
