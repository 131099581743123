import React from 'react';
import classNames from 'classnames';

import Form, { FieldUtils } from '@zvuk-b2b/react-uikit/ui/Form';
import { MIN_ACCEPT_IMAGE_FILES } from '@zvuk-b2b/react-uikit/ui/FileUploader/ImageUploader/consts';
import IconUpload from '@zvuk-b2b/react-uikit/ui/icons/Upload';

import { BusinessProfileFormData, BusinessProfileFormImagesHintType, BusinessProfileFormProps } from './types';
import { TEXTS } from './texts';
import {
  AVATAR_HEIGHT,
  AVATAR_WIDTH,
  AVATAR_MAX_SIZE,
  BANNER_WEB_HEIGHT,
  BANNER_WEB_WIDTH,
  BANNER_WEB_MAX_SIZE,
  BANNER_MOBILE_MAX_WIDTH,
  BANNER_MOBILE_MAX_HEIGHT,
  BANNER_MOBILE_MAX_SIZE,
  DEFAULT_BUSINESS_PROFILE_FORM_DATA,
  POSTER_HEIGHT,
  POSTER_WIDTH,
  POSTER_MAX_SIZE,
  BUSINESS_PROFILE_NAME_MAX_LENGTH,
  BUSINESS_PROFILE_DESCRIPTION_MAX_LENGTH,
} from './consts';
import ImageUploaderFieldDescription from './ImageUploaderFieldDescription';
import Agreement from './Agreement';
import useBusinessProfileFormController from './useBusinessProfileFormController';

import styles from './index.module.scss';

const BusinessProfileForm = ({
  containerClassName,
  initialFormData,
  isLoading,
  errors,
  withAcceptedPrivatePolicy = true,
  withDirty,
  withRemoveButton,
  submitButtonContent = TEXTS.BUSINESS_PROFILE_FORM_BUTTON_DEFAULT_TEXT,
  onOpenImagesHint,
  onSubmit,
  onCancel,
}: BusinessProfileFormProps) => {
  const {
    initialValues,
    shouldAvatarBeRelative,
    isBannerLinkEnabled,
    externalErrors,
    getUploadedImageFileDescription,
    handleFieldDirtyChange,
    handleFieldError,
    handleSubmit,
  } = useBusinessProfileFormController({ initialFormData, errors, onSubmit });

  const onChange = (setValue: any, getValues: (fieldName?: string) => any, field: any) => {
    if (field.name === 'bannerMobile' || field.name === 'bannerWeb') {
      const bannerMobile = getValues('bannerMobile');
      const bannerWeb = getValues('bannerWeb');

      if (!(bannerWeb?.files?.length > 0 && bannerMobile?.files?.length > 0)) {
        setValue('bannerLink', '', { shouldValidate: true });
      }
    }
  };

  return (
    <div className={classNames(styles.BusinessProfileFormContainer, containerClassName)}>
      <Form<BusinessProfileFormData>
        elementId="business_profile_form"
        defaultValues={DEFAULT_BUSINESS_PROFILE_FORM_DATA}
        values={initialValues}
        submitButtonContent={submitButtonContent}
        withDirty={withDirty}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        onChange={onChange}
        handleFieldDirtyChange={handleFieldDirtyChange}>
        <Form.TextField
          elementId="business_profile_form_company_name"
          name="companyName"
          rules={{
            maxLength: {
              value: BUSINESS_PROFILE_NAME_MAX_LENGTH,
              message: TEXTS.BUSINESS_PROFILE_FORM_COMPANY_NAME_LENGTH_ERROR(BUSINESS_PROFILE_NAME_MAX_LENGTH),
            },
          }}
          maxLength={BUSINESS_PROFILE_NAME_MAX_LENGTH}
          label={
            <div className={styles.BusinessProfileFormFieldLabel}>
              <span className={styles.BusinessProfileFormFieldLabelRequiredSign}>*</span>
              <span>{TEXTS.BUSINESS_PROFILE_FORM_COMPANY_NAME}</span>
            </div>
          }
          error={externalErrors.get('companyName')}
        />

        <Form.TextAreaField
          elementId="business_profile_form_company_description"
          name="description"
          cols={30}
          rules={{
            maxLength: {
              value: BUSINESS_PROFILE_DESCRIPTION_MAX_LENGTH,
              message: TEXTS.BUSINESS_PROFILE_FORM_DESCRIPTION_LENGTH_ERROR(BUSINESS_PROFILE_DESCRIPTION_MAX_LENGTH),
            },
          }}
          maxLength={BUSINESS_PROFILE_DESCRIPTION_MAX_LENGTH}
          label={
            <div className={styles.BusinessProfileFormFieldLabel}>
              <span className={styles.BusinessProfileFormFieldLabelRequiredSign}>*</span>
              <span>{TEXTS.BUSINESS_PROFILE_FORM_DESCRIPTION}</span>
            </div>
          }
          placeholder={TEXTS.BUSINESS_PROFILE_FORM_DESCRIPTION_PLACEHOLDER}
          error={externalErrors.get('description')}
        />

        <Form.TextField
          elementId="business_profile_form_company_site"
          name="site"
          label={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_SITE}
          placeholder={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_SITE_PLACEHOLDER}
          error={externalErrors.get('site')}
        />

        <Form.ImageUploaderField
          elementId="business_profile_form_company_poster"
          name="poster"
          label={
            <div className={styles.BusinessProfileFormFieldLabel}>
              <span className={styles.BusinessProfileFormFieldLabelRequiredSign}>*</span>
              <span>{TEXTS.BUSINESS_PROFILE_FORM_COMPANY_POSTER_LABEL}</span>
            </div>
          }
          uploadButtonContent={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_UPLOAD_TEXT}
          previewImageClassName={classNames(
            styles.BusinessProfileFormFullWidthPreview,
            styles.BusinessProfileFormCompanyPoster
          )}
          description={
            <ImageUploaderFieldDescription
              elementId="business_profile_poster_hint"
              text={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_POSTER_DESCRIPTION(POSTER_WIDTH, POSTER_HEIGHT)}
              type={BusinessProfileFormImagesHintType.POSTER}
              onOpenHint={onOpenImagesHint}
            />
          }
          getUploadedImageFileDescription={getUploadedImageFileDescription}
          accept={MIN_ACCEPT_IMAGE_FILES}
          maxSize={POSTER_MAX_SIZE}
          multiple={false}
          withPreviewImages
          uploadButtonAdornmentLeft={<IconUpload />}
          error={externalErrors.get('poster')}
          handleFieldError={handleFieldError}
        />

        <Form.ImageUploaderField
          elementId="business_profile_form_company_avatar"
          name="avatar"
          label={
            <div className={styles.BusinessProfileFormFieldLabel}>
              <span className={styles.BusinessProfileFormFieldLabelRequiredSign}>*</span>
              <span>{TEXTS.BUSINESS_PROFILE_FORM_COMPANY_AVATAR_LABEL}</span>
            </div>
          }
          uploadButtonContent={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_UPLOAD_TEXT}
          description={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_AVATAR_DESCRIPTION(AVATAR_WIDTH, AVATAR_HEIGHT)}
          maxSize={AVATAR_MAX_SIZE}
          getUploadedImageFileDescription={getUploadedImageFileDescription}
          accept={MIN_ACCEPT_IMAGE_FILES}
          multiple={false}
          withPreviewImages
          uploadButtonAdornmentLeft={<IconUpload />}
          error={externalErrors.get('avatar')}
          previewImageClassName={styles.BusinessProfileFormAvatarPreview}
          previewToolboxClassName={styles.BusinessProfileFormAvatarPreviewToolbox}
          previewImagesContainerWithSelectedImagesClassName={classNames(
            styles.BusinessProfileFormPreviewImageListContainer,
            {
              [styles.BusinessProfileFormPreviewImageListContainerRelative]: shouldAvatarBeRelative,
            }
          )}
        />

        <Form.ImageUploaderField
          elementId="business_profile_form_company_banner_web"
          name="bannerWeb"
          label={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_BANNER_WEB_LABEL}
          uploadButtonContent={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_UPLOAD_TEXT}
          previewImageClassName={classNames(
            styles.BusinessProfileFormFullWidthPreview,
            styles.BusinessProfileFormCompanyBannerWeb
          )}
          description={
            <ImageUploaderFieldDescription
              elementId="business_profile_banner_web_hint"
              text={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_BANNER_WEB_DESCRIPTION(BANNER_WEB_WIDTH, BANNER_WEB_HEIGHT)}
              type={BusinessProfileFormImagesHintType.BANNER_WEB}
              onOpenHint={onOpenImagesHint}
            />
          }
          getUploadedImageFileDescription={getUploadedImageFileDescription}
          maxSize={BANNER_WEB_MAX_SIZE}
          accept={MIN_ACCEPT_IMAGE_FILES}
          multiple={false}
          withPreviewImages
          withRemoveButton={withRemoveButton}
          uploadButtonAdornmentLeft={<IconUpload />}
          error={externalErrors.get('bannerWeb')}
        />

        <Form.ImageUploaderField
          elementId="business_profile_form_company_banner_mobile"
          name="bannerMobile"
          label={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_BANNER_MOBILE_LABEL}
          uploadButtonContent={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_UPLOAD_TEXT}
          previewImageClassName={styles.BusinessProfileFormBannerMobilePreview}
          description={
            <ImageUploaderFieldDescription
              elementId="business_profile_banner_mobile_hint"
              text={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_BANNER_MOBILE_DESCRIPTION(
                BANNER_MOBILE_MAX_WIDTH,
                BANNER_MOBILE_MAX_HEIGHT
              )}
              type={BusinessProfileFormImagesHintType.BANNER_MOBILE}
              onOpenHint={onOpenImagesHint}
            />
          }
          getUploadedImageFileDescription={getUploadedImageFileDescription}
          maxSize={BANNER_MOBILE_MAX_SIZE}
          accept={MIN_ACCEPT_IMAGE_FILES}
          multiple={false}
          withPreviewImages
          withRemoveButton={withRemoveButton}
          uploadButtonAdornmentLeft={<IconUpload />}
          error={externalErrors.get('bannerMobile')}
        />

        <Form.TextField
          elementId="business_profile_form_company_banner_link"
          name="bannerLink"
          label={
            isBannerLinkEnabled
              ? TEXTS.BUSINESS_PROFILE_FORM_COMPANY_BANNER_LINK
              : TEXTS.BUSINESS_PROFILE_FORM_COMPANY_BANNER_LINK_DISABLED
          }
          placeholder={TEXTS.BUSINESS_PROFILE_FORM_COMPANY_BANNER_LINK_PLACEHOLDER}
          containerClassName={styles.BusinessProfileFormInputContainer}
          labelClassName={styles.BusinessProfileFormInputLabel}
          error={externalErrors.get('bannerLink')}
          isDisabled={!isBannerLinkEnabled}
        />

        <Form.CheckboxField
          elementId="business_profile_form_company_accepted_private_policy"
          className={classNames({
            [styles.BusinessProfileAgreementContainerHidden]: !withAcceptedPrivatePolicy,
          })}
          name="acceptedPrivatePolicy"
          rules={FieldUtils.getRequiredRule()}>
          <Agreement />
        </Form.CheckboxField>
      </Form>
    </div>
  );
};

export default BusinessProfileForm;
