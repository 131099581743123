import React, { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export enum BadgeType {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Neutral = 'neutral',
  Neutral2 = 'neutral2',
  Accent = 'accent',
  Extra1 = 'extra1',
  Extra1Light = 'extra1_light',
  Extra2 = 'extra2',
  Disabled = 'disabled',
}

type BadgeProps = {
  label: string;
  type?: BadgeType;
  className?: string;
  adornmentRight?: React.ReactNode;
  elementId?: string;
};

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ label, type = BadgeType.Success, className, adornmentRight, elementId = 'badge' }: BadgeProps, ref) => (
    <div
      role="none"
      ref={ref}
      className={classNames(styles.Badge, className)}
      data-testid={elementId}
      data-type={type}>
      {label}

      {adornmentRight && <div className={styles.BadgeAdornment}>{adornmentRight}</div>}
    </div>
  )
);

export default Badge;
