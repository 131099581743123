import React, { forwardRef, ReactNode, RefObject } from 'react';
import classNames from 'classnames';

import Button, { ButtonProps, ButtonSize, ButtonType } from 'ui/Button/index';
import Popper, { HintPlacement } from 'ui/Popper';
import { desktopQueryList, useMediaQueryChangeListener } from 'toolkit/useMedia';

import styles from './index.module.scss';

export type IconButtonProps = ButtonProps & {
  hint?: string | ReactNode;
  hintPlacement?: HintPlacement;
  hintClassName?: string;
  hintFollowCursor?: boolean;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props: IconButtonProps, ref) => {
  const {
    isLoading = false,
    isDisabled = false,
    autofocus = false,
    type = ButtonType.PLAIN,
    size = ButtonSize.SMALL,
    opacity = 1,
    className,
    children,
    elementId,
    onClick,
    onKeyDown,
    hint,
    hintPlacement = HintPlacement.PLACEMENT_BOTTOM,
    hintClassName,
    hintFollowCursor,
  } = props;

  const isDesktop = useMediaQueryChangeListener(desktopQueryList);

  if (hint) {
    return (
      <Popper
        triggerNode={
          <Button
            ref={ref}
            type={type}
            size={size}
            opacity={opacity}
            isDisabled={isDisabled}
            isLoading={isLoading}
            autofocus={autofocus}
            className={classNames(styles.IconButton, className)}
            elementId={elementId}
            onClick={onClick}
            onKeyDown={onKeyDown}>
            {children}
          </Button>
        }
        triggerNodeRef={ref as RefObject<HTMLElement>}
        isShow
        placement={hintPlacement}
        popperClassName={hintClassName}
        isShowingOnClick={!isDesktop}
        followCursor={hintFollowCursor}
        delayShow={400}
        stopHoverPropagation={false}
        elementId={`${elementId}_popper}`}>
        {hint}
      </Popper>
    );
  }

  return (
    <Button
      ref={ref}
      type={type}
      size={size}
      opacity={opacity}
      isDisabled={isDisabled}
      isLoading={isLoading}
      autofocus={autofocus}
      className={classNames(styles.IconButton, className)}
      elementId={elementId}
      onClick={onClick}
      onKeyDown={onKeyDown}>
      {children}
    </Button>
  );
});

export default IconButton;
