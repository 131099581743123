import React, { FC } from 'react';

import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import ApplicationLinks from 'application/components/ApplicationsLinks';
import { ModalProps, ModalTypes, useModalContext } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

export type PlayerAppLinksModalProps = {};

const PlayerAppLinksModal: FC<ModalProps[ModalTypes.POINT_APP_LINKS]> = ({ close }) => {
  const { isModalOpen } = useModalContext();

  return (
    <ModalScreen
      hideCloseButton
      onClose={close}
      disableScroll={isModalOpen}
      actionButtons={[
        <Button
          elementId="button_app_links_player_modal"
          type={ButtonType.PRIMARY}
          onClick={close}>
          {TEXTS.PLAYER_APP_LINKS_BACK}
        </Button>,
      ]}>
      <ApplicationLinks />
    </ModalScreen>
  );
};

export default PlayerAppLinksModal;
