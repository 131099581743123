import React from 'react';

import { SHELF_PAGE_PATH } from 'application/consts';
import ShelfPageWrapper from 'application/pages/ShelfPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const shelfRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: SHELF_PAGE_PATH,
        element: <ShelfPageWrapper />,
      },
    ],
  },
];
