import React from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { TEXTS } from './texts';

interface IStopTrackPlayingModalProps {
  onCancel: () => void;
  onSubmit: () => void;
}

const StopTrackPlayingModal: React.FC<IStopTrackPlayingModalProps> = ({ onCancel, onSubmit }) => (
  <ModalScreen
    title={TEXTS.STOP_TRACK_PAYING_TITLE}
    titlePlacement="center"
    onClose={onCancel}
    actionButtons={[
      <Button
        key="button_confirm_stop_track_playing_modal"
        elementId="button_confirm_stop_track_playing_modal"
        type={ButtonType.PRIMARY}
        onClick={onSubmit}>
        {TEXTS.LISTEN}
      </Button>,
      <Button
        key="button_cancel_stop_track_playing_modal"
        elementId="button_cancel_stop_track_playing_modal"
        type={ButtonType.SECONDARY}
        onClick={onCancel}>
        {TEXTS.CANCEL}
      </Button>,
    ]}>
    <div>{TEXTS.CONTENT}</div>
  </ModalScreen>
);

export default StopTrackPlayingModal;
