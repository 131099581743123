import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import { ZvukPlaylistSource } from 'domain/api/graphql/generated';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import Radio from '@zvuk-b2b/react-uikit/ui/Radio';
import Input, { InputSize } from '@zvuk-b2b/react-uikit/ui/Input';
import Autocomplete from '@zvuk-b2b/react-uikit/ui/Autocomplete';

import { PLAYLIST_FROM_ITEMS } from './consts';
import { TEXTS } from './texts';
import useBusinessProfileAddPlaylistModalController from './useBusinessProfileAddPlaylistModalController';
import useBusinessProfileAddPlaylistModalPresenter from './useBusinessProfileAddPlaylistModalPresenter';

import styles from './index.module.scss';

const BusinessProfileAddPlaylistModal: FC<ModalProps[ModalTypes.BUSINESS_PROFILE_ADD_PLAYLIST]> = ({
  targetProfileId,
  close,
  onOpenUpsertPlaylist,
  onOpenHint,
}) => {
  const {
    isSavedStreamsReady,
    savedStreams,
    isSavedStreamsLoading,
    getSavedStreams,
    setSelectedBusinessProfilePlaylist,
    getZvukPlaylistInfo,
    getZvukPlaylistInfoLoading,
  } = useBusinessProfileAddPlaylistModalPresenter();

  const {
    isEmpty,
    isSubmitButtonDisabled,
    isSubmitButtonHasLoadText,
    playlistFrom,
    businessProfilePlaylistTitle,
    playlistsOptions,
    zvukPlaylistLink,
    zvukPlaylistLinkError,
    onZvukBusinessPlaylistTitleChange,
    onZvukPlaylistLinkChange,
    onPlaylistFromChange,
    onAddPlaylist,
  } = useBusinessProfileAddPlaylistModalController({
    targetProfileId,
    savedStreams,
    isSavedStreamsLoading,
    isSavedStreamsReady,
    getSavedStreams,
    setSelectedBusinessProfilePlaylist,
    onOpenUpsertPlaylist,
    streamOptionContentClassName: styles.BusinessProfileAddPlaylistModalOptionContent,
    close,
    getZvukPlaylistInfo,
  });

  return (
    <ModalScreen
      title={TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_MODAL_TITLE}
      titlePlacement="left"
      onClose={close}
      contentClassName={styles.BusinessProfileAddPlaylistModalContent}
      actionButtons={[
        <Button
          key="business_profile_add_playlist_modal_button_submit"
          elementId="business_profile_add_playlist_modal_button_submit"
          type={ButtonType.PRIMARY}
          isDisabled={isSubmitButtonDisabled}
          isLoading={getZvukPlaylistInfoLoading}
          onClick={onAddPlaylist}>
          {isSubmitButtonHasLoadText
            ? TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_BUTTON_TEXT
            : TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_BUTTON_FALLBACK_TEXT}
        </Button>,
        <Button
          key="business_profile_add_playlist_modal_button_cancel"
          elementId="business_profile_add_playlist_modal_button_cancel"
          type={ButtonType.SECONDARY}
          onClick={close}>
          {TEXTS.BUSINESS_PROFILE_CANCEL_BUTTON_TEXT}
        </Button>,
      ]}>
      <div>{TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_FROM}</div>
      <div className={styles.BusinessProfileAddPlaylistModalRadioGroup}>
        {PLAYLIST_FROM_ITEMS.map(({ value, label }) => (
          <Radio
            key={value}
            elementId={`business_profile_playlist_from_${value}`}
            isChecked={value === playlistFrom}
            onChange={() => onPlaylistFromChange(value)}>
            {label}
          </Radio>
        ))}
      </div>

      {playlistFrom === ZvukPlaylistSource.ZvukB2BStream && isEmpty && (
        <div className={styles.BusinessProfileAddPlaylistModalZvukLabel}>
          {TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_FALLBACK}
        </div>
      )}

      {playlistFrom === ZvukPlaylistSource.ZvukB2BStream && !isEmpty && (
        <div>
          <div className={styles.BusinessProfileAddPlaylistModalZvukLabel}>
            {TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_LABEL}
          </div>
          <Autocomplete
            elementId="business_profile_add_playlist_modal_input_zvuk_business"
            autoComplete="off"
            value={businessProfilePlaylistTitle}
            options={playlistsOptions}
            error={false}
            isLoading={isSavedStreamsLoading}
            dropdownClassName={styles.BusinessProfileAddPlaylistModalDropdown}
            onChange={onZvukBusinessPlaylistTitleChange}
            placeholder={TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_PLACEHOLDER}
            inputClassName={styles.MainToolbarInput}
            containerClassName={styles.MainToolbarInput}
            size={InputSize.MEDIUM}
            useClearButton
          />
        </div>
      )}

      {playlistFrom === ZvukPlaylistSource.ZvukPlaylist && (
        <div>
          <div className={styles.BusinessProfileAddPlaylistModalZvukLabel}>
            <span>{TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_LABEL}</span>
            <Button
              elementId="business_profile_add_playlist_modal_button_hint"
              className={styles.BusinessProfileAddPlaylistModalHintButton}
              type={ButtonType.PLAIN}
              onClick={onOpenHint}>
              {TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_HINT}
            </Button>
          </div>
          <Input
            elementId="business_profile_add_playlist_modal_input_zvuk"
            size={InputSize.MEDIUM}
            value={zvukPlaylistLink}
            placeholder={TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_PLACEHOLDER}
            onChange={onZvukPlaylistLinkChange}
            error={zvukPlaylistLinkError}
          />
        </div>
      )}
    </ModalScreen>
  );
};

export default BusinessProfileAddPlaylistModal;
