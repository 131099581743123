import React, { FC } from 'react';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import { TEXTS } from 'application/pages/texts';
import styles from './index.module.scss';
import useStreamMainToolBarFiltersModalController from './useStreamMainToolBarFiltersModalController';
import { StreamMainToolBarFiltersModalProps, StreamTagsType } from '../types';
import StreamMainToolBarFiltersModalSection from './StreamMainToolBarFiltersModalSection';

const StreamMainToolBarFiltersModal: FC<StreamMainToolBarFiltersModalProps> = (props) => {
  const { streamTags } = props;
  const { selectedTags, handleApply, handleClear, onCloseHandler, setTagActive, setTagInactive } =
    useStreamMainToolBarFiltersModalController(props);

  const renderSections = ({ id, title, children }: StreamTagsType) => (
    <StreamMainToolBarFiltersModalSection
      id={id}
      key={id}
      title={title}
      tags={children}
      selectedTags={selectedTags}
      setTagActive={setTagActive}
      setTagInactive={setTagInactive}
    />
  );

  return (
    <ModalScreen
      title={TEXTS.FILTERS}
      containerClassName={styles.StreamToolBarModal}
      titlePlacement="left"
      actionsPlacement="left"
      onClose={onCloseHandler}
      containerActionButtonsClassName={styles.StreamToolBarModalActionButtons}
      actionButtons={[
        <Button
          key="filter_tags_apply"
          elementId="filter_tags_apply"
          type={ButtonType.PRIMARY}
          onClick={handleApply}>
          {TEXTS.SHOW_STREAMS}
        </Button>,
        !!selectedTags.size && (
          <Button
            key="filter_tags_clear"
            elementId="filter_tags_clear"
            type={ButtonType.SECONDARY}
            onClick={handleClear}>
            {TEXTS.CLEAR}
          </Button>
        ),
      ]}>
      <div className={styles.StreamToolBarModalList}>{streamTags?.map(renderSections)}</div>
    </ModalScreen>
  );
};

export default StreamMainToolBarFiltersModal;
