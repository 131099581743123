import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import useKeyDownCallback from 'toolkit/useKeyDownCallback';
import { KeyboardEventCode } from 'toolkit/types';
import { InputProps } from 'ui/Input';

import RichInputExtensions from './RichInputExtensions';

import styles from './index.module.scss';

export enum InputType {
  TEXT = 'text',
  TEL = 'tel',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export type RichInputProps = InputProps & {
  extensions?: React.ReactNode | React.ReactNode[];
  onBackspacePressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const RichInput = React.forwardRef<HTMLInputElement, RichInputProps>((props: RichInputProps, ref) => {
  const {
    label,
    error,
    inputRef,
    value: propValue = '',
    required = false,
    maxLength = Number.MAX_SAFE_INTEGER,
    isDisabled = false,
    isLoading = false,
    type = InputType.TEXT,
    containerClassName,
    inputClassName,
    adornmentLeft,
    adornmentRight,
    placeholder,
    elementId,
    autoComplete = 'on',
    extensions: propExtensions,
    onChange,
    onFocus,
    onBlur,
    onMouseOver,
    onMouseOut,
    onEnterPressed,
    onBackspacePressed,
    labelIcon,
    isFullMode = false,
  } = props;

  const [value, setValue] = useState<string>(propValue);
  const [extensions, setExtensions] = useState<React.ReactNode | React.ReactNode[]>(propExtensions);
  const [localError, setLocalError] = useState<string | boolean | string[] | undefined>(error);

  useEffect(() => setValue(propValue), [propValue]);

  useEffect(() => setExtensions(propExtensions), [propExtensions]);

  useEffect(() => setLocalError(error), [error]);

  const hideError = () => setLocalError(undefined);

  const onFocusWrapper = (event: React.FocusEvent<HTMLInputElement>) => {
    hideError();
    onFocus?.(event);
  };

  const onChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    hideError();

    const newValue = event.target.value.substr(0, maxLength);
    setValue(newValue);
    onChange?.(newValue, event);
  };

  const onAddExtensions = (value: string, event: React.SyntheticEvent<Element, Event>) => {
    let newExtensions = [];
    if (Array.isArray(extensions)) {
      newExtensions = [...extensions];
    } else {
      newExtensions.push(extensions);
    }

    newExtensions.push(value);
    setExtensions(newExtensions);
    setValue('');
    onChange?.('', event);
  };

  const onRemoveExtensions = (value: string, event: React.SyntheticEvent<Element, Event>) => {
    if (value.length === 0) {
      if (Array.isArray(extensions)) {
        const newExtensions = [...extensions];
        newExtensions.pop();
        setExtensions(newExtensions);
      } else {
        setExtensions([]);
      }
    } else {
      setValue(value);
      onChange?.(value, event);
    }
  };

  const onKeyDown = useKeyDownCallback<React.KeyboardEvent<HTMLInputElement>>(
    (event) => {
      if (event.code === KeyboardEventCode.ENTER) {
        if (onEnterPressed) {
          onEnterPressed(event);
        } else {
          onAddExtensions(event.currentTarget.value, event);
        }
      }

      if (event.code === KeyboardEventCode.BACKSPACE) {
        if (onBackspacePressed) {
          onBackspacePressed(event);
        } else {
          onRemoveExtensions(event.currentTarget.value, event);
        }
      }
    },
    { include: [KeyboardEventCode.ENTER, KeyboardEventCode.BACKSPACE] }
  );

  return (
    <div
      className={classNames(styles.RichInputContainer, containerClassName)}
      ref={ref}>
      {(label || isFullMode) && (
        <label
          htmlFor={elementId}
          className={classNames(styles.RichInputContainerLabel, isFullMode && styles.RichInputContainerLabelFullMode)}>
          {required && <span data-required>*</span>}
          {label || ''}
          {labelIcon}
        </label>
      )}

      {isLoading ? (
        <div className={styles.RichInputSkeleton} />
      ) : (
        <div className={styles.RichInputWrapperContainer}>
          <RichInputExtensions
            elementId={`${elementId}_extensions`}
            extensions={extensions}
            onEnterPressed={onEnterPressed}
            onBackspacePressed={onBackspacePressed}
          />

          <div className={styles.RichInputWrapperContainerInputWithAdornments}>
            {adornmentLeft && (
              <div
                className={classNames(
                  styles.RichInputWrapperContainerAdornment,
                  styles.RichInputWrapperContainerAdornmentL
                )}>
                {adornmentLeft}
              </div>
            )}

            <input
              type={type}
              data-adornment-left={!!adornmentLeft}
              data-adornment-right={!!adornmentRight}
              value={value}
              id={elementId}
              data-testid={elementId}
              ref={inputRef}
              disabled={isDisabled}
              placeholder={placeholder}
              aria-errormessage={localError ? `${elementId}_errormessage` : undefined}
              autoComplete={autoComplete}
              className={classNames(inputClassName)}
              onChange={onChangeWrapper}
              onFocus={onFocusWrapper}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            />

            {adornmentRight && (
              <div
                className={classNames(
                  styles.RichInputWrapperContainerAdornment,
                  styles.RichInputWrapperContainerAdornmentR
                )}>
                {adornmentRight}
              </div>
            )}
          </div>
        </div>
      )}

      {localError && typeof localError === 'string' && (
        <span
          id={`${elementId}_error_message`}
          data-testid={`${elementId}_error_message`}
          className={styles.RichInputContainerError}>
          {localError}
        </span>
      )}
    </div>
  );
});

export default RichInput;
