export const TEXTS = {
  MODAL_TITLE: 'Видео-плеер',
  TITLE_DESCRIPTION:
    'Подключите ваш аккаунт со всеми настройками — введите код из приложения на телевизоре мобильное приложение «Звук Бизнес TV» и введите код.',

  CODE_BIND_TITLE: 'Код из приложения',
  CODE_BIND_PLACEHOLDER: '4 символа',

  SUCCESS_BUTTON: 'Привязать устройство',
  CANCEL_BUTTON: 'Назад',
};
