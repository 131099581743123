import React from 'react';
import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import Text from '@zvuk-b2b/react-uikit/ui/Text';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import VideoMediafileItemPoster from '../VideoMediafileItemPoster';

import styles from './index.module.scss';
import { TEXTS } from '../../../texts';

interface VideoMediafilePreviewItemContentProps {
  title?: string | null;
  duration?: number | null;
  imgLink?: string | null;
  uploading?: boolean;
  actionText?: string;
  actionHandler?: () => void;
}

const VideoMediafilePreviewItemContent: React.FC<VideoMediafilePreviewItemContentProps> = ({
  title,
  duration,
  imgLink,
  uploading,
  actionText,
  actionHandler,
}) => {
  const durationText = duration && DateUtils.durationToShortString(Number(duration));
  const descriptionText = uploading ? TEXTS.UPLOADING : durationText;

  return (
    <div className={styles.VideoMediafilePreviewItemContent}>
      <div className={styles.VideoMediafilePreviewItemContentPosterContainer}>
        <VideoMediafileItemPoster
          title={title}
          link={imgLink}
          uploading={uploading}
        />
      </div>

      <div className={styles.VideoMediafilePreviewItemContentTextContainer}>
        <Text
          elementId="video_media_title"
          className={styles.VideoMediafilePreviewItemContentTextContainerTitle}>
          {title}
        </Text>
        {descriptionText && (
          <p className={styles.VideoMediafilePreviewItemContentTextContainerDescription}>{descriptionText}</p>
        )}
        {actionText && (
          <Button
            className={styles.actionButton}
            elementId="video_media_action"
            type={ButtonType.PLAIN}
            onClick={actionHandler}>
            {actionText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default VideoMediafilePreviewItemContent;
