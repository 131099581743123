import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import classNames from 'classnames';

import CheckboxComponent from 'ui/Checkbox';

import { CheckboxFieldProps } from '../types';

import styles from './index.module.scss';

const CheckboxField = React.forwardRef(
  <T extends FieldValues>(
    props: React.PropsWithChildren<CheckboxFieldProps<T>>,
    _: React.Ref<HTMLElement> | undefined
  ) => {
    const { rules, clearErrors } = props;
    const { field, formState } = useController({
      ...props,
      rules: {
        ...rules,
        validate: {
          hasChecked: (value) => {
            if (rules?.required) {
              return value;
            }

            return true;
          },
        },
      },
    });

    const { elementId, buttonClassName, error, isChecked } = props;

    const handleChange = (value: boolean, event?: React.ChangeEvent<Element> | undefined) => {
      clearErrors?.(field.name);
      field.onChange(value, event);
    };

    return (
      <CheckboxComponent
        {...field}
        {...props}
        isChecked={field.value || isChecked}
        elementId={`form_checkbox_field_${elementId}`}
        buttonClassName={classNames(buttonClassName, {
          [styles.CheckboxFieldError]: formState.errors[field.name] || error,
        })}
        onChange={handleChange}
      />
    );
  }
);

export default CheckboxField;
