import { useMediaAdCampaignEnableMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseAdCampaignEnableResultType, UseAdCampaignEnableType, AdCampaignType } from './types';

const useAdCampaignEnable = (): UseAdCampaignEnableType => {
  const [enableMutation, enableMutationResult] = useMediaAdCampaignEnableMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (adCompanyId: string, enable: boolean) => {
    const enabledAdCampaign = await enableMutation({
      variables: {
        input: {
          adCompanyId,
          enable,
        },
      },
      refetchQueries: ['mediaAdCampaignsPaginationQuery'],
    });

    return !!enabledAdCampaign.data?.result?.ok;
  };

  const result: UseAdCampaignEnableResultType = {
    error: enableMutationResult.error,
    loading: enableMutationResult.loading,
    called: enableMutationResult.called,
    data: {
      adCampaign: enableMutationResult.data?.result?.adCompany as AdCampaignType,
    },
  };

  return [request, result];
};

export default useAdCampaignEnable;
