import React from 'react';
import { getCoverKey } from 'application/components/utils';
import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import styles from './index.module.scss';

interface Props {
  link?: string | null;
  title?: string | null;
  uploading?: boolean;
}

const MAX_DEFAULT_COVERS_COUNT = 4;
const DISABLED_COVER_INDEX = '3';

const VideoMediafileItemPoster: React.FC<Props> = ({ link, title, uploading }) => {
  if (!link) {
    return (
      <div
        className={styles.Poster}
        // TEMP replace to default image
        data-hash={
          uploading
            ? DISABLED_COVER_INDEX
            : getCoverKey(title || TEXTS.VIDEO_STREAM_INFO_TITLE, MAX_DEFAULT_COVERS_COUNT)
        }
      />
    );
  }

  return (
    <img
      src={link}
      alt=""
      loading="lazy"
      className={styles.Poster}
    />
  );
};

export default VideoMediafileItemPoster;
