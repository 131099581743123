import React from 'react';
import classNames from 'classnames';

import IconButton from 'ui/IconButton';
import IconEdit2 from 'ui/icons/Edit2';
import IconTrash2 from 'ui/icons/Trash2';

import { ImageFile } from '../../types';

import styles from './index.module.scss';

export type ImageToolboxProps = {
  elementId: string;
  imageFile: ImageFile;
  imageSrc: string;
  withImageFilename: boolean;
  withEditButton?: boolean;
  withRemoveButton?: boolean;
  previewImageClassName?: string;
  previewToolboxClassName?: string;
  handleImageFileUploaderOpen: (currentFile: ImageFile) => void;
  handleFileRemove: (filename: string) => void;
};

const ImageToolbox = ({
  elementId,
  imageFile,
  imageSrc,
  withImageFilename,
  withEditButton = true,
  withRemoveButton = true,
  previewImageClassName,
  previewToolboxClassName,
  handleImageFileUploaderOpen,
  handleFileRemove,
}: ImageToolboxProps) => (
  <div className={styles.ImageToolboxContainer}>
    <div className={classNames(styles.ImageToolbox, previewToolboxClassName)}>
      {withEditButton && (
        <IconButton
          key={`${elementId}_button_edit_image`}
          elementId={`${elementId}_button_edit_image`}
          className={styles.ImageToolboxButton}
          onClick={() => handleImageFileUploaderOpen(imageFile)}>
          <IconEdit2 className={styles.ImageToolboxButtonIcon} />
        </IconButton>
      )}

      {withRemoveButton && (
        <IconButton
          key={`${elementId}_button_remove_image`}
          elementId={`${elementId}_button_remove_image`}
          className={styles.ImageToolboxButton}
          onClick={() => handleFileRemove(imageFile.name)}>
          <IconTrash2 className={styles.ImageToolboxButtonIcon} />
        </IconButton>
      )}
    </div>

    <img
      key={elementId}
      id={elementId}
      src={imageSrc}
      alt=""
      className={classNames(styles.ImageToolboxImage, previewImageClassName)}
    />

    {withImageFilename && <div>{imageFile.name}</div>}
  </div>
);

export default ImageToolbox;
