import React from 'react';

import { LOCK_PAGE_PATH } from 'application/consts';
import LockPageWrapper from 'application/pages/LockPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const lockRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: LOCK_PAGE_PATH,
        element: <LockPageWrapper />,
      },
    ],
  },
];
