import React from 'react';

import { ADS_PAGE_PATH } from 'application/consts';
import AdsPageWrapper from 'application/pages/AdsPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const adsRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: ADS_PAGE_PATH,
        element: <AdsPageWrapper />,
      },
    ],
  },
];
