import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, fromPromise } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { onError } from '@apollo/client/link/error';

import { useAuthContext } from 'application/providers/AuthProvider';
import AuthProviderUtils from 'application/providers/AuthProvider/utils';
import { ZVUK_API_GRAPHQL_URL } from 'application/consts';

import {
  UserBillingInfo,
  PaymentMethodStatusType,
  OrganizationPricePlanPureType,
} from 'application/repositories/billing/typePolicies';

import DataProviderUtils from './utils';

const httpLink = createUploadLink({ uri: ZVUK_API_GRAPHQL_URL });
const inMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        adcampaignsPaginationQuery: {
          keyArgs: ['filters'],
        },
        pointsPaginationQuery: {
          keyArgs: ['filters'],
        },
        streamMediafiles: {
          keyArgs: ['filters'],
        },
        streamMediafilesEvents: {
          keyArgs: ['filters'],
        },
      },
    },
  },
});

inMemoryCache.policies.addTypePolicies({
  UserBillingInfo,
  PaymentMethodStatusType,
  OrganizationPricePlanPureType,
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: inMemoryCache,
});

const DataProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const pendingRequestsAsRef = useRef<Function[]>([]);
  const isRefreshingAsRef = useRef(false);

  const authContext = useAuthContext();

  useLayoutEffect(() => {
    const errorLink = onError(
      ({
        graphQLErrors,
        operation,
        forward,
        // eslint-disable-next-line consistent-return
      }) => {
        const resolvePendingRequests = () => {
          pendingRequestsAsRef.current.map((callback) => callback());
          pendingRequestsAsRef.current = [];
        };

        if (graphQLErrors) {
          const isNeedRequestJWT = DataProviderUtils.checkIsJWTExpiredError(graphQLErrors);

          if (isNeedRequestJWT) {
            if (!isRefreshingAsRef.current) {
              isRefreshingAsRef.current = true;

              return fromPromise(
                DataProviderUtils.tokenRefresh()
                  .then(async (authToken) => {
                    await AuthProviderUtils.waitForAuthTokenUpdated(() => {
                      authContext.setAuthData({ authToken });
                    });
                  })
                  .catch(() => {
                    authContext.setAuthData({ authToken: undefined });
                    resolvePendingRequests();
                    isRefreshingAsRef.current = false;
                  })
              ).flatMap(() => {
                resolvePendingRequests();
                isRefreshingAsRef.current = false;
                return forward(operation);
              });
            }

            return fromPromise(
              new Promise<void>((resolve) => {
                pendingRequestsAsRef.current.push(() => resolve());
              })
            ).flatMap(() => forward(operation));
          }
        }
      }
    );

    apolloClient.setLink(errorLink.concat(httpLink));
  }, [authContext.setAuthData]);

  useEffect(() => {
    if (!authContext.authToken) {
      apolloClient.resetStore();
    }
  }, [authContext.authToken]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default DataProvider;
