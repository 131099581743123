import { useState } from 'react';
import { useNavigate } from 'application/providers/RouterProvider';
import { ActiveTagType } from 'application/providers/StreamSearchProvider/types';
import AnalyticsService, { EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';
import { StreamMainToolBarFiltersModalProps } from '../types';

interface useStreamMainToolBarFiltersModalControllerProps extends StreamMainToolBarFiltersModalProps {}

const useStreamMainToolBarFiltersModalController = (props: useStreamMainToolBarFiltersModalControllerProps) => {
  const { activeTags, redirectLink, clearTags, setTags, close } = props;
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState<Map<string, ActiveTagType>>(activeTags);

  const handleApply = () => {
    AnalyticsService.event(EventNameAMP.FILTER_SHOW_RESULTS_PRESSED);

    if (!selectedTags.size) {
      navigate(redirectLink, { replace: true });
      clearTags();
      close();
      return;
    }

    setTags(selectedTags);
    close();
  };

  const handleClear = () => {
    AnalyticsService.event(EventNameAMP.FILTER_CLEAR_PRESSED, { [ParamNameAMP.FILTER_TYPE]: 'modal' });
    setSelectedTags(new Map());
  };

  const onCloseHandler = () => {
    AnalyticsService.event(EventNameAMP.FILTER_CLOSE_PRESSED);
    close();
  };

  const setTagActive = (params: ActiveTagType) => {
    setSelectedTags((prev) => {
      const newActiveTags = new Map(prev);
      newActiveTags.set(params.id, params);
      return newActiveTags;
    });
  };

  const setTagInactive = (tagId: string) =>
    setSelectedTags((prev) => {
      const newActiveTags = new Map(prev);
      newActiveTags.delete(tagId);
      return newActiveTags;
    });

  return {
    selectedTags,
    handleApply,
    handleClear,
    onCloseHandler,
    setTagActive,
    setTagInactive,
  };
};

export default useStreamMainToolBarFiltersModalController;
