import React from 'react';
import classNames from 'classnames';

import Button, { ButtonType } from 'ui/Button';

import { FileUploaderProps } from './types';
import { TEXTS } from './texts';
import FileUploaderUtils from './utils';
import useFileUploaderController from './useFileUploaderController';

import styles from './index.module.scss';

const FileUploader = React.forwardRef<HTMLInputElement | null, FileUploaderProps>(
  (
    {
      inputRef,
      elementId,
      className,
      uploadButtonClassName,
      accept,
      multiple = true,
      label,
      description,
      isLoading,
      uploadButtonContent = TEXTS.DEFAULT_UPLOAD_BUTTON_TEXT,
      uploadButtonType = ButtonType.SECONDARY,
      uploadButtonAdornmentLeft,
      uploadButtonAdornmentRight,
      isUploadButtonVisible = true,
      onChange,
      onOpenFileUploader,
      onCancel,
    },
    ref
  ) => {
    const { openFileUploader, handleFilesChange } = useFileUploaderController({
      elementId,
      inputRef,
      onChange,
      onOpenFileUploader,
      onCancel,
    });

    return (
      <div
        id={elementId}
        className={classNames(styles.FileUploaderContainer, className)}>
        <input
          id={`${elementId}_input`}
          data-testid={`${elementId}_input`}
          ref={inputRef || ref}
          tabIndex={-1}
          className={styles.FileUploaderInput}
          type="file"
          accept={FileUploaderUtils.acceptPropAsAcceptAttr(accept)}
          multiple={multiple}
          onChange={handleFilesChange}
        />

        {isUploadButtonVisible && (
          <>
            {label && (
              <label
                className={styles.FileUploaderLabel}
                htmlFor={`${elementId}_button_upload`}>
                {label}
              </label>
            )}

            <Button
              elementId={`${elementId}_button_upload`}
              className={classNames(styles.FileUploaderButton, uploadButtonClassName)}
              type={uploadButtonType}
              adornmentLeft={uploadButtonAdornmentLeft}
              adornmentLeftClassName={styles.FileUploaderButtonAdornment}
              adornmentRight={uploadButtonAdornmentRight}
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={openFileUploader}>
              {uploadButtonContent}
            </Button>

            <div className={styles.FileUploaderDescription}>{description}</div>
          </>
        )}
      </div>
    );
  }
);

export default FileUploader;
