import { NetworkStatus } from '@apollo/client';
import { useLibraryCollectionsSearchLazyQuery, useLibraryStreamsSearchLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const PAGE_SIZE = 20;

type RequestParamsType = {
  companyId: string;
  textQuery: string;
  searchTagsIds?: string[];
};

const useStreamSearch = () => {
  const [streamSearchRequest, streamsSearchResult] = useLibraryStreamsSearchLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [collectionsSearchRequest, collectionsSearchResult] = useLibraryCollectionsSearchLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const streamsSearchData = streamsSearchResult.data?.result;
  const collectionSearchData = collectionsSearchResult.data?.result;
  const streamSearchPagination = streamsSearchData?.paginationInfo;
  const collectionSearchPagination = collectionSearchData?.paginationInfo;
  const isStreamSearchFetching = streamsSearchResult.networkStatus === NetworkStatus.fetchMore;
  const isCollectionsSearchFetching = collectionsSearchResult.networkStatus === NetworkStatus.fetchMore;
  const isLoading = streamsSearchResult.loading || collectionsSearchResult.loading;

  const request = async ({ companyId, textQuery, searchTagsIds }: RequestParamsType) => {
    const requestBody = {
      fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
      variables: {
        filters: {
          companyId,
          textQuery: textQuery || null,
          searchTagsIds,
        },
        pagination: {
          page: 0,
          pageSize: PAGE_SIZE,
        },
      },
    };

    await streamSearchRequest(requestBody);

    if (!searchTagsIds?.length && textQuery) {
      const requestBody = {
        fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
        variables: {
          filters: {
            companyId,
            textQuery,
          },
          pagination: {
            page: 0,
            pageSize: PAGE_SIZE,
          },
        },
      };

      await collectionsSearchRequest(requestBody);
    }
  };

  const loadMoreStreams = ({ companyId, textQuery, searchTagsIds }: RequestParamsType) => {
    if (isStreamSearchFetching) return;

    streamsSearchResult.fetchMore({
      variables: {
        filters: { companyId, textQuery: textQuery || null, searchTagsIds },
        pagination: {
          page: (streamSearchPagination?.page ?? 0) + 1,
          pageSize: PAGE_SIZE,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        result: {
          paginationInfo: {
            ...prev.result.paginationInfo,
            ...fetchMoreResult.result.paginationInfo,
          },
          result: [...(prev.result.result || []), ...(fetchMoreResult.result.result || [])],
        },
      }),
    });
  };

  const loadMoreCollections = ({ companyId, textQuery }: RequestParamsType) => {
    streamsSearchResult.fetchMore({
      variables: {
        filters: { companyId, textQuery },
        pagination: {
          page: (collectionSearchPagination?.page ?? 0) + 1,
          pageSize: PAGE_SIZE,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        result: {
          paginationInfo: {
            ...prev.result.paginationInfo,
            ...fetchMoreResult.result.paginationInfo,
          },
          result: [...(prev.result.result || []), ...(fetchMoreResult.result.result || [])],
        },
      }),
    });
  };

  return {
    streamsSearchData,
    collectionSearchData,
    isLoading,
    isStreamSearchFetching,
    isCollectionsSearchFetching,
    request,
    loadMoreStreams,
    loadMoreCollections,
  };
};

export default useStreamSearch;
