import DeclensionUtils from 'application/utils/DeclensionUtils';

export const TEXTS = {
  TITLE: 'Сертификат',
  EMPTY_POINTS_REDIRECT_BUTTON: 'Перейти к адресам',
  EMPTY_POINTS_CONNECT_WITH_MANAGER_BUTTON: 'Связаться с менеджером',
  EMPTY_PAID_PONTS_REDIRECT_BUTTON: 'Перейти к оплате',
  CANCEL_BUTTON: 'Отмена',
  CHOOSE_POINTS_EXPAND_BUTTON: 'Дальше',
  CHOOSE_POINTS_EMPTY_CERT_BUTTON: 'Сформировать',
  CHOOSE_PONTS_CERT_BUTTON: (pointsCount: number) =>
    `Сформировать по ${pointsCount} ${DeclensionUtils.pointsEnding(pointsCount)}`,
  CHOOSE_POINTS_SEARCH_PLACEHOLDER: 'Поиск...',
  CHOOSE_POINTS_DISABLED_HINT_DESCRIPTION: 'Адрес не оплачен.',
  CHOOSE_POINTS_DISABLED_HINT_LINK: 'Оплатить',
  DOWNLOAD_SUCCESS_MESSAGE: 'Сертификат создан, проверьте загрузки',
  DOWNLOAD_ERROR_MESSAGE: 'Не получилось создать сертификат',
};
