import React from 'react';
import classNames from 'classnames';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import TEXTS from '../texts';

import styles from './index.module.scss';

export type SelectableListHeaderProps = {
  isLoading: boolean;
  onChangeAll: (value: boolean) => void;
  hasSelectedItems: boolean;
  hasUnselectedItems: boolean;
  totalItemsCount?: number;
  showItemsCount?: boolean;
};

const SelectableListHeader = ({
  hasSelectedItems,
  hasUnselectedItems,
  totalItemsCount = 0,
  showItemsCount = false,
  isLoading,
  onChangeAll,
}: SelectableListHeaderProps) => {
  if (isLoading) {
    return (
      <div className={styles.SelectableListHeaderSkeleton}>
        <div className={styles.SelectableListHeaderSkeletonItem} />
        <div className={styles.SelectableListHeaderSkeleton}>
          <div className={styles.SelectableListHeaderSkeletonItem} />
          <div className={styles.SelectableListHeaderSkeletonItem} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.SelectableListHeaderMultiSelect, {
        [styles.SelectableListHeaderMultiSelectWithoutItemsCount]: !showItemsCount,
      })}>
      {showItemsCount && <div>{TEXTS.SELECTABLE_LIST_FOUND(totalItemsCount)}</div>}
      <div>
        {hasUnselectedItems && (
          <Button
            elementId="selectable_list_select_all"
            type={ButtonType.PLAIN}
            className={styles.SelectableListHeaderMultiSelectButton}
            onClick={() => onChangeAll(true)}
            isDisabled={isLoading}>
            {TEXTS.SELECTABLE_LIST_ALL}
          </Button>
        )}
        {hasSelectedItems && hasUnselectedItems && <span className={styles.SelectableListHeaderMultiSelectDot}>•</span>}
        {hasSelectedItems && (
          <Button
            elementId="selectable_list_select_all_none"
            type={ButtonType.PLAIN}
            className={styles.SelectableListHeaderMultiSelectButton}
            onClick={() => onChangeAll(false)}
            isDisabled={isLoading}>
            {TEXTS.SELECTABLE_LIST_ALL_NONE}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SelectableListHeader;
