import React from 'react';

import { INVITE_PAGE_PATH } from 'application/consts';
import InvitePageWrapper from 'application/pages/InvitePage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const inviteRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: INVITE_PAGE_PATH,
        element: <InvitePageWrapper />,
      },
    ],
  },
];
