import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Colors } from 'ui/types';
import Spinner from 'ui/Spinner';
import styles from './index.module.scss';

export enum LabelPlacement {
  LEFT = 'left',
  RIGHT = 'right',
}

export type SwitcherProps = React.PropsWithChildren<{
  className?: string;
  trackClassName?: string;
  thumbClassName?: string;
  labelClassName?: string;
  elementId: string;
  labelPlacement?: LabelPlacement;
  isChecked?: boolean;
  isDisabled?: boolean;
  isStretch?: boolean;
  onChange?: (value: boolean) => void;
  isLoading?: boolean;
}>;

const Switcher = React.forwardRef<HTMLSpanElement, SwitcherProps>(
  (
    {
      className,
      trackClassName,
      thumbClassName,
      labelClassName,
      elementId,
      labelPlacement = LabelPlacement.LEFT,
      isChecked: defaultCheckedState,
      isDisabled = false,
      isStretch = false,
      onChange,
      isLoading,
      children,
    }: SwitcherProps,
    ref
  ) => {
    const [isChecked, setIsChecked] = useState(defaultCheckedState);

    useEffect(() => {
      setIsChecked(defaultCheckedState);
    }, [defaultCheckedState]);

    const handleClick = () => {
      if (!isDisabled) {
        onChange?.(!isChecked);
        setIsChecked(!isChecked);
      }
    };

    return (
      <div
        ref={ref as React.Ref<HTMLDivElement>}
        id={elementId}
        data-testid={elementId}
        className={classNames(styles.Switcher, className, {
          [styles.SwitcherStretched]: isStretch,
        })}>
        {labelPlacement === LabelPlacement.LEFT && (
          <span className={classNames(styles.SwitcherLabelLeft, labelClassName)}>{children}</span>
        )}

        {!isLoading && (
          <div className={styles.SwitcherContainer}>
            <span
              role="none"
              id={`track_${elementId}`}
              data-testid={`track_${elementId}`}
              className={classNames(styles.SwitcherContainerTrack, trackClassName)}
              data-checked={isChecked}
              onClick={handleClick}
              data-disabled={isDisabled}
            />
            <span
              role="none"
              id={`thumb_${elementId}`}
              data-testid={`thumb_${elementId}`}
              className={classNames(styles.SwitcherContainerThumb, thumbClassName)}
              onClick={handleClick}
              data-checked={isChecked}
              data-disabled={isDisabled}
            />
          </div>
        )}

        {isLoading && <Spinner color={Colors.COLOR_NEUTRAL_600} />}

        {labelPlacement === LabelPlacement.RIGHT && (
          <span className={classNames(styles.SwitcherLabelRight, labelClassName)}>{children}</span>
        )}
      </div>
    );
  }
);

export default Switcher;
