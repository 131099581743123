import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

// @ts-ignore
const Renderer = React.lazy(() => import('./renderer'));

export const DEBUG_PAGE_PATH = '/test';
export const DEBUG_PAGE_PATH_ALIAS = '/debug';

const DebugPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

DebugPageWrapper.config = {
  routes: [
    {
      path: DEBUG_PAGE_PATH,
    },
    {
      path: DEBUG_PAGE_PATH_ALIAS,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED, LocalAccessRoles.ANONYMOUS],
};

export default DebugPageWrapper;
