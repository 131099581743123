import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropProps, FileUploadQue, TQues } from './types';

export const useUploadController = () => {
  const [dropProps, setDropProps] = React.useState<DropProps>();
  const [queId, setQuId] = React.useState<string>();

  const [uploadQueIds, setUploadQueIds] = React.useState<string[]>([]);

  const [ques, setQues] = useState<TQues>({});

  const initQue = (id: string, handler: (files: File) => Promise<void>) => {
    if (!ques[id]?.files.length) {
      setQues({ ...ques, [id]: { files: [], handler } });
    }
  };

  const destroyQues = (id: string) => {
    if (ques[id]) {
      setQues({ ...ques, [id]: undefined });
    }
  };

  const uploadHandler = async (que: FileUploadQue) => {
    const { files, handler } = que;

    await Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            handler(file)
              .then(() => {
                resolve(true);
              })
              .catch((e) => {
                reject(e);
              });
          })
      )
    );
  };

  const setFilesToQue = async (id: string, files: File[]) => {
    const que = ques[id];

    if (que) {
      const newQue = { ...que, files: [...que.files, ...files] };
      setQues({ ...ques, [id]: newQue });
    }
  };

  useEffect(() => {
    Object.keys(ques).forEach(async (key) => {
      if (ques[key]?.files?.length && !uploadQueIds?.includes(key)) {
        const que = ques[key];
        if (que) {
          setUploadQueIds([...uploadQueIds, key]);
          await uploadHandler(que);

          const newQueWithoutFiles = { ...que, files: [] };
          setQues({ ...ques, [key]: newQueWithoutFiles });
          setUploadQueIds(uploadQueIds.filter((id) => id !== key));

          if (queId !== key) {
            destroyQues(key);
          }
        }
      }

      if (queId !== key && !ques[key]?.files.length) {
        destroyQues(key);
      }
    });
  }, [queId, ques]);

  const dropzone = useDropzone({
    ...dropProps,

    onDrop: (files) => {
      if (!queId) {
        return;
      }
      setFilesToQue(queId, files);
    },
  });

  const onClickUpload = useCallback(() => {
    dropzone.open();
  }, [dropzone]);

  const loading = ques && queId && ques[queId] ? Boolean(ques[queId]?.files?.length) : false;
  const uploadingFiles = useMemo(
    () => (ques && queId && ques[queId] && ques[queId]?.files?.length ? ques[queId]?.files : []),
    [queId, ques]
  );

  return {
    loading,
    uploadingFiles: uploadingFiles || [],
    onClickUpload,
    initQue,
    setDropProps,
    setQuId,
    dropzone,
    isInputActive: Boolean(dropProps),
  };
};
