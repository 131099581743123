import useCompanyUpdate from 'domain/auth/useCompanyUpdate';
import { CompanyUpdateInputType } from 'domain/auth/types';

import { UseCompanyRepositoryType } from './types';

const useCompanyRepository = (): UseCompanyRepositoryType => {
  const {
    request: companyUpdateRequest,
    result: companyUpdateResult,
    clearErrors: updateCompanyClearErrors,
  } = useCompanyUpdate();

  const updateCompany = async (input: CompanyUpdateInputType) => {
    const result = await companyUpdateRequest(input);

    return result;
  };

  return {
    updateCompany,
    updateCompanyCalled: companyUpdateResult.called,
    updateCompanyLoading: companyUpdateResult.loading,
    updateCompanyError: companyUpdateResult.error,
    updateCompanyClearErrors,
  };
};

export default useCompanyRepository;
