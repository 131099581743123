import React, { useMemo } from 'react';
import classNames from 'classnames';

import SelectableListItem from '../SelectableListItem';
import SelectableListItemSkeleton from '../SelectableListItemSkeleton';

import styles from './index.module.scss';
import { ItemShortType, SelectableListWrapperProps } from '../types';

const INITIAL_SKELETON_LENGTH = 30;

const SelectableListWrapper = <T extends ItemShortType>({
  elementId,
  items,
  itemKeyForRender,
  itemContent,
  itemsLoading,
  isLoading,
  selectedItems,
  disabledItems,
  className,
  selectorRenderer,
  selectorDisabled,
  labelPlacement,
  onChange,
  children,
}: SelectableListWrapperProps<T>) => {
  const skeletonArray = useMemo(
    () => Array.from(Array(Math.min(INITIAL_SKELETON_LENGTH, items.length || INITIAL_SKELETON_LENGTH)).keys()),
    [items.length]
  );

  return (
    <section className={classNames(styles.SelectableListWrapper, className)}>
      {!itemsLoading && !isLoading && (
        <div className={styles.SelectableListWrapperItemsContainer}>
          {items.map((item) => {
            const isDisabled = disabledItems.has(item.id);

            return (
              <SelectableListItem<T>
                key={item.id}
                elementId={elementId || 'selectable_item'}
                selectorRenderer={selectorRenderer}
                currentItem={item}
                isChecked={selectedItems.has(item.id)}
                isDisabled={isDisabled || selectorDisabled}
                isLoading={isLoading}
                labelPlacement={labelPlacement}
                onChange={onChange}>
                {itemContent
                  ? React.cloneElement(itemContent, {
                      currentItem: item,
                      disabled: isDisabled,
                    })
                  : item[itemKeyForRender]}
              </SelectableListItem>
            );
          })}
        </div>
      )}

      <div
        className={styles.SelectableListWrapperSkeleton}
        data-is-loaded={items.length > 0 && !itemsLoading}>
        {skeletonArray.map((item) => (
          <SelectableListItemSkeleton key={`selectable_list_skeleton_item_${item}`} />
        ))}
      </div>

      {children}
    </section>
  );
};

export default React.memo(SelectableListWrapper);
