import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import BusinessProfilePlaylistForm from 'application/pages/BusinessProfilePage/renderer/BusinessProfilePlaylistForm';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import IconTrash2 from '@zvuk-b2b/react-uikit/ui/icons/Trash2';

import useBusinessProfileUpsertPlaylistModalPresenter from './useBusinessProfileUpsertPlaylistModalPresenter';
import useBusinessProfileUpsertPlaylistModalController from './useBusinessProfileUpsertPlaylistModalController';
import { TEXTS } from './texts';
import { BusinessProfilePlaylistModalMode } from './types';
import Description from './Description';
import AboutBrandInfo from './AboutBrandInfo';
import AboutEditingInfo from './AboutEditingInfo';

import styles from './index.module.scss';

const BusinessProfilePlaylistUpsertModal: FC<ModalProps[ModalTypes.BUSINESS_PROFILE_PLAYLIST_UPSERT]> = ({
  mode = BusinessProfilePlaylistModalMode.CREATE,
  playlist,
  targetBusinessProfileId,
  close,
  onSubmit,
}) => {
  const {
    getBusinessProfile,
    refetchBusinessProfiles,
    removeSelectedBusinessProfilePlaylist,
    getBusinessProfilePlaylistInitialFormData,
    onCreateBusinessProfilePlaylistFromStream,
    createPlaylistFromStreamLoading,
    createPlaylistFromStreamError,
    createPlaylistFromStreamData,
    onCreateBusinessProfilePlaylistFromZvuk,
    createPlaylistFromZvukLoading,
    createPlaylistFromZvukError,
    createPlaylistFromZvukData,
    onUpdateBusinessProfilePlaylist,
    updatePlaylistLoading,
    updatePlaylistError,
    updatePlaylistData,
    hidePlaylist,
    hidePlaylistLoading,
    hidePlaylistError,
    removePlaylist,
    removePlaylistLoading,
    removePlaylistError,
    publishPlaylist,
    publishPlaylistLoading,
    publishPlaylistError,
    currentCompany,
  } = useBusinessProfileUpsertPlaylistModalPresenter();

  const {
    initialFormData,
    isCreateMode,
    isSourceZvukBusiness,
    isInitialDataLoading,
    isUnpublishedProfile,
    submitButtonContent,
    handleSubmitPlaylist,
    handleConfirmRemovePlaylist,
    handleHidePlaylist,
  } = useBusinessProfileUpsertPlaylistModalController({
    mode,
    playlist,
    targetBusinessProfileId,
    onSubmit,
    close,
    getBusinessProfile,
    refetchBusinessProfiles,
    removeSelectedBusinessProfilePlaylist,
    getBusinessProfilePlaylistInitialFormData,
    onCreateBusinessProfilePlaylistFromStream,
    createPlaylistFromStreamError,
    onCreateBusinessProfilePlaylistFromZvuk,
    createPlaylistFromZvukError,
    onUpdateBusinessProfilePlaylist,
    updatePlaylistError,
    hidePlaylist,
    hidePlaylistError,
    removePlaylist,
    removePlaylistError,
    publishPlaylist,
    publishPlaylistError,
  });

  return (
    <ModalScreen
      title={
        isCreateMode
          ? TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_ADD_TITLE
          : TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_UPDATE_TITLE
      }
      titlePlacement="left"
      onClose={close}
      containerClassName={styles.BusinessProfileUpsertPlaylistModalContainer}
      containerActionButtonsClassName={styles.BusinessProfileUpsertPlaylistModalActionButtons}
      contentClassName={styles.BusinessProfileUpsertPlaylistModalContent}>
      <Description
        title={initialFormData?.title}
        count={initialFormData?.tracksCount || 0}
        isSourceZvukBusiness={isSourceZvukBusiness}
        withTrackCount={!isCreateMode}
      />

      <AboutBrandInfo country={currentCompany?.country} />

      <BusinessProfilePlaylistForm
        initialFormData={initialFormData}
        containerClassName={styles.BusinessProfileUpsertPlaylistModalFormContainer}
        isLoading={
          createPlaylistFromStreamLoading ||
          createPlaylistFromZvukLoading ||
          updatePlaylistLoading ||
          publishPlaylistLoading ||
          isInitialDataLoading
        }
        errors={updatePlaylistData.errors || createPlaylistFromStreamData.errors || createPlaylistFromZvukData.errors}
        submitButtonContent={submitButtonContent}
        footerContent={<AboutEditingInfo isSourceZvukBusiness={isSourceZvukBusiness} />}
        onSubmit={handleSubmitPlaylist}
        onCancel={isCreateMode ? close : undefined}
      />

      {!isCreateMode && !isUnpublishedProfile(playlist) && (
        <Button
          key="business_profile_upsert_modal_button_hide_playlist"
          elementId="business_profile_upsert_modal_button_hide_playlist"
          className={styles.BusinessProfileUpsertPlaylistModalButton}
          type={ButtonType.SECONDARY}
          isLoading={hidePlaylistLoading}
          onClick={handleHidePlaylist}>
          {TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_HIDE_TITLE}
        </Button>
      )}

      {!isCreateMode && (
        <Button
          key="business_profile_upsert_modal_button_remove_playlist"
          elementId="business_profile_upsert_modal_button_remove_playlist"
          className={styles.BusinessProfileUpsertPlaylistModalButton}
          type={ButtonType.DANGER}
          isLoading={removePlaylistLoading}
          adornmentLeft={<IconTrash2 className={styles.BusinessProfileUpsertPlaylistModalButtonIcon} />}
          adornmentLeftClassName={styles.BusinessProfileUpsertPlaylistModalButtonAdornment}
          onClick={handleConfirmRemovePlaylist}>
          {TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_REMOVE_TITLE}
        </Button>
      )}
    </ModalScreen>
  );
};

export default BusinessProfilePlaylistUpsertModal;
