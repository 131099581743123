import { useEffect, useState } from 'react';

import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';

import { UseExtendSubscriptionModalControllerProps } from './types';

const useExtendSubscriptionModalController = ({
  licenses,
  hasInactiveLicenses,
  launchSubscription,
  extendSubscription,
  getSubscriptionCost,
  closeAllModals,
  billingInfoRequest,
}: UseExtendSubscriptionModalControllerProps) => {
  const [subscriptionCost, setSubscriptionCost] = useState<number>(0);

  const setCost = async () => {
    if (licenses.length !== 0) {
      const cost = await getSubscriptionCost(licenses);

      setSubscriptionCost(cost);
    }
  };

  useEffect(() => {
    setCost();
  }, []);

  const onExtendSubscriptionConfirm = async () => {
    AnalyticsService.event(EventNameAMP.BILLING_LICENSES_PAY_CONFIRM_CLICKED);

    if ((licenses?.length || 0) > 0) {
      if (hasInactiveLicenses) {
        await launchSubscription(licenses);
      } else {
        await extendSubscription(licenses);
      }
    }

    await billingInfoRequest();
    closeAllModals();
  };

  return {
    subscriptionCost,
    onExtendSubscriptionConfirm,
  };
};

export default useExtendSubscriptionModalController;
