import React from 'react';

import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import PointAddressItem from './PointAddressItem';

export type PointAddressesListProps = {
  selectedPoints: PointsListCheckboxGroupPointType[];
};

const PointAddressesList = ({ selectedPoints }: PointAddressesListProps) => (
  <>
    {selectedPoints.map((point) => (
      <PointAddressItem
        key={point.id}
        point={point}
      />
    ))}
  </>
);

export default PointAddressesList;
