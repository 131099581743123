import React from 'react';

import { LocalAccessRoles } from 'application/providers/AuthProvider';
import { SourcefileType } from 'domain/api/graphql/generated';

export type PageConfigRouteType = {
  path: string;
};

export type PageConfigType = {
  routes: PageConfigRouteType[];
  access: LocalAccessRoles[];
};

export interface IApplicationPage extends React.FC {
  config: PageConfigType;
}

export type LocationStateEmailType = {
  email: string;
};

export type LocationStateRequiredVersionUrlType = {
  requiredVersionUrl?: string;
  linkName?: string;
};

export type LocationStatePointsType = {
  pointsFromLocation?: Array<{ pointId: string; pointAddress: string }>;
  scrollToId: string | undefined;
  search: string | null;
};

export enum AuthQueryKeys {
  SBBID_ERROR = 'error',
  FROM = 'from',
  FROM_SOURCE = 'from_source',
  STEP = 'step',
  PLATFORM = 'platform',
}

export type StreamDemoType = {
  id: string;
  filename: string;
  sourcefiles: Partial<SourcefileType>[];
};

export type StremGenresType = {
  tag: {
    id: string;
    title: string;
  };
};

export enum MediaFileTypes {
  TRACK = 0,
  PROMO = 1,
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type IdType = {
  id: string;
};
