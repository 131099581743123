import React from 'react';

import CleanLayout from 'application/layout/CleanLayout';
import ActivationSuccessPageWrapper from 'application/pages/ActivationSuccessPage';
import { ACTIVATION_SUCCESS_PAGE_PATH } from 'application/consts';

import { RouteConfig } from '..';

export const activationSuccessRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: ACTIVATION_SUCCESS_PAGE_PATH,
        element: <ActivationSuccessPageWrapper />,
      },
    ],
  },
];
