import { PointPureChangeStreamMutationInput, usePointChangeStreamMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const usePointChangeStream = () => {
  const [mutation, mutationResult] = usePointChangeStreamMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (inputs: PointPureChangeStreamMutationInput[]) => {
    const promises = inputs.map((input) => mutation({ variables: { input } }));
    const results = await Promise.all(promises);
    return results.every(({ data }) => !!data?.pointPureChangeStream?.ok);
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    request,
  };
};

export default usePointChangeStream;
