class LinkedTagsModalUtils {
  static getExistingTagByTitle(errorMessage: string, tagsMap: Map<string, string>, companyId: string) {
    const regExp = /"(.*?)"/;
    const matches = regExp.exec(errorMessage);
    const tagTitle = matches?.[1] || '';
    let tagId = '';

    for (const [key, value] of tagsMap.entries()) {
      if (value.toLowerCase() === tagTitle.toLowerCase()) {
        tagId = key;
      }
    }

    return {
      id: tagId,
      companyId,
      title: tagTitle,
    };
  }
}

export default LinkedTagsModalUtils;
