import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type ToasterProps = React.PropsWithChildren<{
  isOpen: boolean;
  className?: string;
  elementId: string;
  color?: string;
  onClick?: () => void;
}>;

const Toaster = (props: ToasterProps) => {
  const { isOpen, className, elementId, children, color, onClick } = props;

  const handleClick = () => {
    onClick?.();
  };

  return (
    <div
      role="none"
      id={elementId}
      data-testid={elementId}
      data-type-opened={isOpen}
      className={classNames(styles.Toaster, className)}
      style={{ backgroundColor: color }}
      onClick={handleClick}>
      {children}
    </div>
  );
};

export default Toaster;
