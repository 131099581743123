/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import BrowserUtils from 'toolkit/BrowserUtils';
import { BrowserEvents } from 'toolkit/types';
import PopperContent from './PopperContent';

export enum HintPlacement {
  PLACEMENT_AUTO = 'auto',
  PLACEMENT_AUTO_START = 'auto-start',
  PLACEMENT_AUTO_END = 'auto-end',
  PLACEMENT_TOP = 'top',
  PLACEMENT_TOP_START = 'top-start',
  PLACEMENT_TOP_END = 'top-end',
  PLACEMENT_BOTTOM = 'bottom',
  PLACEMENT_BOTTOM_START = 'bottom-start',
  PLACEMENT_BOTTOM_END = 'bottom-end',
  PLACEMENT_RIGHT = 'right',
  PLACEMENT_RIGHT_START = 'right-start',
  PLACEMENT_RIGHT_END = 'right-end',
  PLACEMENT_LEFT = 'left',
  PLACEMENT_LEFT_START = 'left-start',
  PLACEMENT_LEFT_END = 'left-end',
}

export type PopperProps = React.PropsWithChildren<{
  elementId?: string;
  containerClassName?: string;
  parentRef?: React.RefObject<HTMLElement> | null;
  popperClassName?: string;
  arrowClassName?: string;
  triggerNode: React.ReactElement;
  triggerNodeRef?: React.RefObject<HTMLElement> | null;
  isShow?: boolean;
  delayHide?: number;
  delayShow?: number;
  onVisibleChange?: () => void;
  followCursor?: boolean;
  placement?: HintPlacement;
  portalNode?: HTMLElement | null;
  offset?: [number, number];
  isShowingOnClick?: boolean;
  stopHoverPropagation?: boolean;
}>;

const Popper = ({
  elementId = 'popper_id',
  containerClassName,
  popperClassName,
  arrowClassName,
  triggerNode,
  triggerNodeRef,
  isShow = true,
  delayHide = 0,
  delayShow = 0,
  placement = HintPlacement.PLACEMENT_AUTO,
  onVisibleChange = () => {},
  followCursor = false,
  children,
  portalNode,
  offset = [4, 4],
  isShowingOnClick = false,
  parentRef,
  stopHoverPropagation = true,
}: PopperProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement,
    delayShow,
    delayHide,
    onVisibleChange: () => {
      if (visible && !isShowingOnClick) {
        setIsHovered(true);
      }
      onVisibleChange();
    },
    followCursor,
    offset,
  });

  useEffect(() => {
    if (triggerNodeRef) {
      setTriggerRef(triggerNodeRef.current);
    }
  }, []);

  const hintContainerRef = useRef(null);
  const containerRef = useRef(null);

  const onHover = (event: MouseEvent) => {
    if (stopHoverPropagation) {
      event.stopPropagation();
    }

    const parentNode = isShowingOnClick ? containerRef.current : hintContainerRef.current;
    const isChild = parentNode !== null && BrowserUtils.isChildNode(event.target as HTMLElement, parentNode);

    if (!isChild && isHovered && !isShowingOnClick) {
      setIsHovered(false);
    }

    if (isChild && !isHovered && isShowingOnClick) {
      setIsHovered(true);
    } else if (!isChild && isHovered && isShowingOnClick) {
      setIsHovered(false);
    }
  };

  const onScroll = () => {
    setIsHovered(false);
  };

  const showHint = ((visible && !isShowingOnClick) || isHovered) && isShow;

  useEffect(() => {
    const observableEvent = isShowingOnClick ? BrowserEvents.CLICK : BrowserEvents.MOUSE_MOVE;
    const listenerNode = document;

    listenerNode.removeEventListener(observableEvent, onHover); // remove previous
    listenerNode.addEventListener(observableEvent, onHover);

    if (parentRef?.current !== null) {
      if (isHovered) {
        parentRef?.current.addEventListener('scroll', onScroll);
      } else {
        parentRef?.current.removeEventListener('scroll', onScroll);
      }
    }

    return () => {
      listenerNode.removeEventListener(observableEvent, onHover);
      if (parentRef?.current !== null) {
        parentRef?.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [isHovered]);

  const contentProps = {
    elementId,
    popperClassName,
    setTooltipRef,
    tooltipProps: getTooltipProps(),
    arrowClassName,
    arrowProps: getArrowProps(),
  };

  return (
    <div
      className={containerClassName}
      ref={containerRef}>
      {triggerNodeRef ? triggerNode : React.cloneElement(triggerNode, { ref: setTriggerRef })}

      {showHint && !portalNode && (
        <div ref={hintContainerRef}>
          <PopperContent {...contentProps}>{children}</PopperContent>
        </div>
      )}

      {showHint &&
        portalNode &&
        createPortal(
          <div ref={hintContainerRef}>
            <PopperContent {...contentProps}>{children}</PopperContent>
          </div>,
          portalNode
        )}
    </div>
  );
};

export default Popper;
