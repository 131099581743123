import React from 'react';

import { MENU_MARGIN } from './consts';
import { DropdownProps, DropdownOrientation, DropdownPlacement } from './types';

class DropdownUtils {
  static getBorder = (list: HTMLUListElement) => {
    const styles = window.getComputedStyle(list);
    const margin = parseFloat(styles.borderWidth) * 2;
    return Math.ceil(margin);
  };

  static getMenuPosition = (
    node: DropdownProps['anchorNode'] | undefined,
    height: number,
    orientation: DropdownProps['orientation'],
    isIOS: boolean,
    menuMargin = MENU_MARGIN,
    placement: DropdownProps['placement']
  ): React.CSSProperties | undefined => {
    if (!node || !height) return undefined;

    const nodeRect = node.getBoundingClientRect();
    const result: React.CSSProperties = {};

    switch (placement) {
      case DropdownPlacement.TOP:
        result.top = nodeRect.y - height - menuMargin + (isIOS ? window.visualViewport!.offsetTop : 0);
        break;
      case DropdownPlacement.BOTTOM:
        result.top = nodeRect.y + nodeRect.height + menuMargin + (isIOS ? window.visualViewport!.offsetTop : 0);
        break;
      case DropdownPlacement.LEFT:
        result.top = nodeRect.y + nodeRect.height - menuMargin + (isIOS ? window.visualViewport!.offsetTop : 0);
        result.left = window.innerWidth - nodeRect.width - nodeRect.right;
        break;
      case DropdownPlacement.RIGHT:
        result.top = nodeRect.y + nodeRect.height - menuMargin + (isIOS ? window.visualViewport!.offsetTop : 0);
        result.right = window.innerWidth - nodeRect.width - nodeRect.right;
        break;
      default:
        if (nodeRect.y + nodeRect.height + height > window.innerHeight) {
          result.top = nodeRect.y - height - menuMargin + (isIOS ? window.visualViewport!.offsetTop : 0);
        } else {
          result.top = nodeRect.y + nodeRect.height + menuMargin + (isIOS ? window.visualViewport!.offsetTop : 0);
        }

        if (orientation === DropdownOrientation.LEFT) {
          result.left = nodeRect.x;
        } else {
          result.right = window.innerWidth - nodeRect.right;
        }
    }

    return result;
  };

  static getMenuMaxHeight = (list: HTMLUListElement | undefined, maxVisibleOptions: number): number => {
    if (!list || maxVisibleOptions === 0) {
      return 0;
    }

    let maxHeight: number = 0;

    for (let i = 0; i < list.children.length; i++) {
      const item = list.children[i];

      if (i < maxVisibleOptions) {
        maxHeight += item.clientHeight;
      }
      if (i === maxVisibleOptions) {
        maxHeight += item.clientHeight / 2;
      }
    }

    return maxHeight;
  };
}

export default DropdownUtils;
