import { useMediaPromoUploadMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UsePromoUploadResultType, UsePromoUploadType } from './types';

const usePromoUpload = (): UsePromoUploadType => {
  const [promoUploadMutation, promoUploadMutationResult] = useMediaPromoUploadMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const uploadPromo = async (file: File) =>
    promoUploadMutation({
      variables: {
        input: { file },
      },
    });

  const result: UsePromoUploadResultType = {
    error: promoUploadMutationResult.error,
    loading: promoUploadMutationResult.loading,
    called: promoUploadMutationResult.called,
  };

  return {
    result,
    uploadPromo,
  };
};

export default usePromoUpload;
