import { useMemo } from 'react';

import { useMediaStreamCopyFromTemplateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useStreamCopyTemplate = () => {
  const [mutation, mutationResult] = useMediaStreamCopyFromTemplateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (templateId: string, companyId: string, withThematic: boolean) => {
    const mutationResult = await mutation({
      variables: {
        input: {
          templateId,
          companyId,
          withThematic,
        },
      },
    });
    return mutationResult.data?.result?.stream?.id;
  };

  const resultStreamId = mutationResult.data?.result?.stream?.id || undefined;

  return useMemo(
    () => ({
      error: mutationResult.error,
      loading: mutationResult.loading,
      request,
      data: {
        id: resultStreamId,
      },
    }),
    [resultStreamId, mutationResult.error, mutationResult.loading]
  );
};

export default useStreamCopyTemplate;
