import React from 'react';

import { TEXTS } from 'application/pages/AdsPage/renderer/texts';

import Select from '@zvuk-b2b/react-uikit/ui/Select';
import { DropdownItemDataType } from '@zvuk-b2b/react-uikit/ui/Dropdown';

type AdLogsDateSelectProps = {
  value?: string;
  items: DropdownItemDataType[];
  containerClassName?: string;
};

const AdLogsDateSelect = ({ value, items, containerClassName }: AdLogsDateSelectProps) => (
  <div className={containerClassName}>
    <Select
      elementId="select_ad_campaign_period_point"
      value={value}
      options={items}
      label={TEXTS.MODAL_AD_REPORT_CREATE_PERIOD}
    />
  </div>
);

export default AdLogsDateSelect;
