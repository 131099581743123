import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import React, { FC } from 'react';
import IconTrash2 from '@zvuk-b2b/react-uikit/ui/icons/Trash2';
import { REMOVE_ADDS } from '../../MediaListItem/texts';

interface MediaListItemWrapperRemoveProps {
  id?: string;
  onRemove: () => void;
}

const MediaListItemWrapperRemove: FC<MediaListItemWrapperRemoveProps> = (props) => {
  const { id, onRemove } = props;

  return (
    <IconButton
      elementId={`${id}_button_promo_remove`}
      onClick={(e) => {
        e.stopPropagation();
        onRemove?.();
      }}
      hint={REMOVE_ADDS}>
      <IconTrash2 />
    </IconButton>
  );
};

export default MediaListItemWrapperRemove;
