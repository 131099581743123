export type SameSiteValues = 'strict' | 'lax' | 'none';

export type CookieAttributes = {
  expires?: Date | number;
  maxAge?: number;
  domain?: string;
  path?: string;
  secure?: boolean;
  sameSite?: SameSiteValues;
  partitioned?: boolean;
};

export type CookieAttributesKeys = keyof CookieAttributes;

export enum CookieAttributesKeysEnum {
  'expires' = 'expires',
  'maxAge' = 'maxAge',
  'domain' = 'domain',
  'path' = 'path',
  'secure' = 'secure',
  'sameSite' = 'sameSite',
  'partitioned' = 'partitioned',
}

export type BaseAttributesValues = string | number | boolean | undefined;

export type AttributesValues = BaseAttributesValues | Date;
