/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useWebPlayerActionsContext, useWebPlayerContext } from 'application/providers/WebPlayerProvider';
import React, { useEffect, useRef } from 'react';
import Info from 'application/components/WebPlayer/Info';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';

import WebPlayerActionsBlock from 'application/components/WebPlayer/ActionsBlock';
import WebPlayerSettingsBlock from 'application/components/WebPlayer/SettingsBlock';
import { useNavigate } from 'application/providers/RouterProvider';
import styles from './index.module.scss';

const WebPlayerWidget = () => {
  const { isWidgetActive, bindingCode } = useWebPlayerContext();
  const iframeWrapperRef = useRef<HTMLDivElement>(null);

  const { setIframeRef } = useWebPlayerActionsContext();

  const { isDesktop, isMobileXl } = useMedia();

  const navigate = useNavigate();

  useEffect(() => {
    if (isWidgetActive) {
      setIframeRef(iframeWrapperRef.current);
    }

    if (isWidgetActive) {
      document.body.classList.add('withWebPlayer');
    }

    return () => {
      document.body.classList.remove('withWebPlayer');
    };
  }, [isWidgetActive, iframeWrapperRef.current]);

  if (!isWidgetActive) {
    return null;
  }

  const onWrapperClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (isDesktop || isMobileXl) {
      return;
    }
    try {
      const target = evt.target as HTMLElement;

      if (target.closest('iframe') || target.closest('#address_wrapper')) {
        return;
      }

      navigate(`/web-player/${bindingCode}`);
      // eslint-disable-next-line no-empty
    } catch (_err) {}
  };

  return (
    <div
      onClick={onWrapperClick}
      className={styles.wrapper}>
      <Info />
      <WebPlayerActionsBlock />
      {(isMobileXl || isDesktop) && <WebPlayerSettingsBlock />}
    </div>
  );
};

export default WebPlayerWidget;
