import { useEffect } from 'react';

import { LocalAccessRoles } from 'application/providers/AuthProvider';
import { IApplicationPage } from 'application/pages/types';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import { ModalTypes, useModalContext } from 'application/providers/ModalProvider';
import { useNavigate } from 'application/providers/RouterProvider';

export const DOWNLOAD_APP_PAGE_PATH = '/download-app';

const IOS_APP = 'https://redirect.appmetrica.yandex.com/serve/748886506348805275?source=help_center';
const HUAWEI_APP = 'https://appgallery.huawei.com/app/C107456511';
const ANDROID_APP = 'https://redirect.appmetrica.yandex.com/serve/1037116876410363672?source=help_center';

const DownloadAppPage: IApplicationPage = () => {
  const { isAndroid, isIOS, isIpad, isHuawei } = useMedia();
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAndroid) {
      window.location.href = ANDROID_APP;
    } else if (isIOS || isIpad) {
      window.location.href = IOS_APP;
    } else if (isHuawei) {
      window.location.href = HUAWEI_APP;
    } else {
      openModal(ModalTypes.DOWNLOAD_APP_WARNING_MODAL, {
        onOk: () => {
          closeModal();
          navigate('/');
        },
      });
    }
  }, []);

  return null;
};

DownloadAppPage.config = {
  routes: [{ path: DOWNLOAD_APP_PAGE_PATH }],
  access: [LocalAccessRoles.AUTHORIZED, LocalAccessRoles.ANONYMOUS],
};

export default DownloadAppPage;
