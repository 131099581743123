import {
  useExtendSubscriptionCostLazyQuery,
  useResumeSubscriptionCostLazyQuery,
  useBillingSubscriptionCostByPricePlanPeriodLazyQuery,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { ResumeSubscriptionCostInfoType, SubscriptionCostResultType, UseSubscriptionCostType } from './types';

const useSubscriptionCost = (): UseSubscriptionCostType => {
  const [resumeCostQuery, resumeCostQueryResult] = useResumeSubscriptionCostLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const [extendCostQuery, extendCostQueryResult] = useExtendSubscriptionCostLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const [subscriptionCostByPricePlanPeriodQuery, subscriptionCostByPricePlanPeriodQueryResult] =
    useBillingSubscriptionCostByPricePlanPeriodLazyQuery({
      fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    });

  const requestResumeCost = async (companyId: string, licensesNum: number) => {
    const result = await resumeCostQuery({
      variables: { licensesNum, organizationId: companyId },
    });

    if (result.data && result.data.result) {
      return result.data.result as ResumeSubscriptionCostInfoType;
    }

    return undefined;
  };

  const requestExtendCost = async (companyId: string, licensesNum: number) => {
    const result = await extendCostQuery({
      variables: { licensesNum, companyId },
    });

    return result.data?.result;
  };

  const requestSubscriptionCostByPricePlanPeriod = async (organizationId: string, pricePlanPeriodId: string) => {
    const result = await subscriptionCostByPricePlanPeriodQuery({
      variables: { organizationId, pricePlanPeriodId },
    });

    return result.data?.result;
  };

  const result: SubscriptionCostResultType = {
    error:
      resumeCostQueryResult.error || extendCostQueryResult.error || subscriptionCostByPricePlanPeriodQueryResult.error,
    loading:
      resumeCostQueryResult.loading ||
      extendCostQueryResult.loading ||
      subscriptionCostByPricePlanPeriodQueryResult.loading,
    data: {
      resumeCost:
        resumeCostQueryResult.data?.result.costWithDiscount || resumeCostQueryResult.data?.result.cost || undefined,
      extendCost: extendCostQueryResult.data?.result || undefined,
      subscriptionCostByPricePlanPeriod: subscriptionCostByPricePlanPeriodQueryResult.data?.result || undefined,
    },
  };

  return [
    {
      resumeCost: requestResumeCost,
      extendCost: requestExtendCost,
      subscriptionCostByPricePlanPeriod: requestSubscriptionCostByPricePlanPeriod,
    },
    result,
  ];
};

export default useSubscriptionCost;
