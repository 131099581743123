import { useEffect, useMemo, useState } from 'react';
import useCountdown from 'ahooks/lib/useCountDown';

import { useAccountContext } from 'application/providers/AccountProvider';
import { PHONE_NUMBER_COUNTRY_CODES } from 'application/pages/constants';
import ValidationUtils from 'application/utils/ValidationUtils';
import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import PhoneUtils from 'application/utils/PhoneUtils';
import CompanyUtils from 'application/utils/CompanyUtils';
import { useURLSearchParams } from 'application/providers/RouterProvider';
import useActivationCodeStatus from 'domain/auth/useActivationCodeStatus';
import usePhoneAllowedActions from 'domain/auth/usePhoneAllowedActions';
import usePhoneLogin from 'domain/auth/usePhoneLogin';
import useRegister from 'domain/auth/useRegister';
import useLogin from 'domain/auth/useLogin';
import { SMSStatus, RegisterQueryKeys, RegisterPhoneFormInputType, RegisterFormInputType } from 'domain/auth/types';

import { isPhoneAuthAvailable } from './useAuthMethods';
import { LegacyAuthFormSubmitData } from './LegacyAuthForm';
import useAuthNotifications from './useAuthNotifications';
import { AuthFormErrors, AuthFormMode, AuthFormProps, AuthFormStep } from './types';

import { TEXTS } from './texts';

const DELAY_FOR_NEW_SMS_CODE = 60;

const DELAY_FOR_CHECK_SMS = 30000;

export enum AllowedActionForPhone {
  REGISTER = 'register',
  LOGIN_BY_PHONE = 'login_by_phone',
  LOGIN_BY_EMAIL = 'login_by_email',
  LOGIN_OTHER = 'login_other',
}

const CAPTCHA_TOKEN_ERROR_MATCHING = 'Invalid token';

const COUNTRIES_AVAILABLE_TO_SEND_CODE = [PHONE_NUMBER_COUNTRY_CODES.RU, PHONE_NUMBER_COUNTRY_CODES.KZ];

const SEARCH_PARAMS = [RegisterQueryKeys.REF];

export const checkIsPhoneRegistrationAvailable = (country: PHONE_NUMBER_COUNTRY_CODES) =>
  COUNTRIES_AVAILABLE_TO_SEND_CODE.includes(country) && isPhoneAuthAvailable;

const useAuthForm = (props: AuthFormProps) => {
  const {
    mode = AuthFormMode.LOGIN,
    step = AuthFormStep.PHONE_INPUT,
    initData,
    onPhoneChange,
    onModeChange,
    onStepChange,
    onSuccess,
  } = props;
  const phoneLogin = usePhoneLogin();
  const defaultLogin = useLogin();
  const register = useRegister();
  const allowedActions = usePhoneAllowedActions();
  const activationCodeStatus = useActivationCodeStatus();
  const [referral] = useURLSearchParams(SEARCH_PARAMS);

  const authNotifications = useAuthNotifications();
  const { companyUpdateRequest } = useAccountContext();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentPhone, setCurrentPhone] = useState<string>(initData?.phone || '');
  const [errors, setErrors] = useState<AuthFormErrors>();

  const isCodeLoading = phoneLogin.code.loading || allowedActions.loading;

  const hasSmsTroubles =
    !!activationCodeStatus.error ||
    (activationCodeStatus.status && activationCodeStatus.status !== SMSStatus.DELIVERED);

  const currentPhoneCountryCode = useMemo(
    () => PhoneUtils.parsePhoneNumberFromString(currentPhone)?.country,
    [currentPhone]
  );

  const [countdown, setTargetDate] = useCountdown({ interval: 500 });
  const codeCountdown = Math.ceil(countdown / 1000);

  const onCodeRequest = async (phone: string = currentPhone, captchaToken?: string | null) => {
    setErrors(undefined);
    setCurrentPhone(phone);

    const currentAllowedActions = phone ? await allowedActions.request(phone) : undefined;

    // Штатный логин по номеру телефона
    if (mode === AuthFormMode.LOGIN && currentAllowedActions === AllowedActionForPhone.LOGIN_BY_PHONE) {
      await phoneLogin.code.requestCode(phone, captchaToken);
      onStepChange(AuthFormStep.CODE_INPUT, phone);
      return;
    }

    // Штатная рега по номеру телефона
    if (mode === AuthFormMode.REGISTER && currentAllowedActions === AllowedActionForPhone.REGISTER) {
      await phoneLogin.code.requestCode(phone, captchaToken);
      onStepChange(AuthFormStep.CODE_INPUT, phone);
      return;
    }

    // Пытаемся зарегаться по номеру, а надо логиниться по номеру
    if (mode === AuthFormMode.REGISTER && currentAllowedActions === AllowedActionForPhone.LOGIN_BY_PHONE) {
      onModeChange?.(AuthFormMode.LOGIN, AuthFormStep.CODE_INPUT, phone);

      authNotifications.userAlreadyRegisteredByPhone();
      return;
    }

    // Пытаемся логиниться по номеру, а надо по email
    if (mode === AuthFormMode.LOGIN && currentAllowedActions === AllowedActionForPhone.LOGIN_BY_EMAIL) {
      onStepChange(AuthFormStep.LEGACY_AUTH, phone);
      authNotifications.userRegisteredByEmail();
      return;
    }

    // Пытаемся логиниться по номеру, а надо регаться или исправить номер
    if (mode === AuthFormMode.LOGIN && currentAllowedActions === AllowedActionForPhone.REGISTER) {
      authNotifications.userNotFound();
      return;
    }

    // Пытаемся регаться по номеру, а надо логиниться по email
    if (mode === AuthFormMode.REGISTER && currentAllowedActions === AllowedActionForPhone.LOGIN_BY_EMAIL) {
      onModeChange?.(AuthFormMode.LOGIN, AuthFormStep.LEGACY_AUTH, phone);
      authNotifications.userAlereadyRegisteredByEmail();
      return;
    }

    if (!phone && !currentAllowedActions) {
      return;
    }

    authNotifications.unexpectedError();
  };

  const modifyCaptchaErrorMessage = (errorMessage?: string) => {
    if (errorMessage === CAPTCHA_TOKEN_ERROR_MATCHING) {
      return TEXTS.CAPTCHA_TOKEN_ERROR;
    }

    return errorMessage;
  };

  const onCodeSubmit = async (code: string) => {
    setErrors(undefined);
    setLoading(true);

    try {
      if (mode === AuthFormMode.LOGIN) {
        const result = await phoneLogin.token.requestToken(currentPhone, code);

        if (result.ok) {
          onSuccess(result.token, result.user);
        }
      }

      if (mode === AuthFormMode.REGISTER) {
        const input: RegisterPhoneFormInputType = {
          code,
          phone: currentPhone,
          country: CompanyUtils.phoneCountryToOrganizationCountry(currentPhone),
        };

        if (referral) {
          input.referralUuid = referral;
        }

        const result = await register.phone.requestPhone(input);

        if (result.ok) {
          onSuccess(result.token, result.user);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onLegacyFormSubmit = async (data: LegacyAuthFormSubmitData) => {
    setErrors(undefined);
    setLoading(true);

    try {
      if (mode === AuthFormMode.REGISTER) {
        const inputPhone = currentPhone || (data.phone as string);

        const input: RegisterFormInputType = {
          email: data.email,
          phone: inputPhone,
          password: data.password,
          country: CompanyUtils.phoneCountryToOrganizationCountry(inputPhone),
        };

        if (referral) {
          input.referralUuid = referral;
        }

        const isRegSuccess = await register.email.requestEmail(input);

        if (!isRegSuccess) {
          throw new Error();
        }
      }

      const result = await defaultLogin.request({
        username: data.email,
        password: data.password,
      });

      onSuccess(result.token, result.user);

      await companyUpdateRequest({
        companyId: result.user.mainCompany!.id,
        marketingMaterialsAgreementReceived: data.marketingMaterialsAgreementReceived,
      });
    } finally {
      setLoading(false);
    }
  };

  const validatePhone = (phone: string, successCallback: () => void) => {
    setErrors(undefined);

    if (!ValidationUtils.isValidPhoneNumber(phone)) {
      setTimeout(() => setErrors({ phone: TEXTS.INPUT_PHONE_LOCAL_VALIDATION_ERROR_TEXT }), 0);

      return;
    }

    successCallback();
  };

  const phoneSubmit = (phone: string, code: string, token?: string) => {
    if (checkIsPhoneRegistrationAvailable(code as PHONE_NUMBER_COUNTRY_CODES)) {
      onCodeRequest(phone, token);
    } else {
      setCurrentPhone(phone);
      onStepChange(AuthFormStep.LEGACY_AUTH, phone);
    }
  };

  const onPhoneSubmit = async (phone: string, code: string, token?: string) => {
    const successCallback = () => {
      phoneSubmit(phone, code, token);
    };

    validatePhone(phone, successCallback);
  };

  const onChangeAuthMethod = () => {
    onStepChange(step === AuthFormStep.PHONE_INPUT ? AuthFormStep.LEGACY_AUTH : AuthFormStep.PHONE_INPUT, currentPhone);
  };

  useEffect(() => {
    const date = DateUtils.parse(phoneLogin.code.data.codeData?.created, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX");

    if (date) {
      setTargetDate(DateUtils.addSeconds(date, DELAY_FOR_NEW_SMS_CODE));
    }
  }, [phoneLogin.code.data.codeData?.created]);

  useEffect(() => {
    if (!hasSmsTroubles || step !== AuthFormStep.CODE_INPUT) {
      return;
    }

    if (mode === AuthFormMode.LOGIN) {
      authNotifications.loginSMSTrouble(() => onStepChange(AuthFormStep.LEGACY_AUTH, currentPhone));
      return;
    }

    authNotifications.registerSMSTrouble(() => onStepChange(AuthFormStep.LEGACY_AUTH, currentPhone));
  }, [hasSmsTroubles, mode, step]);

  useEffect(() => {
    setLoading(false);
    setErrors(undefined);

    if (mode === AuthFormMode.LOGIN && step === AuthFormStep.PHONE_INPUT && !isPhoneAuthAvailable) {
      onStepChange(AuthFormStep.LEGACY_AUTH);
    }

    let timeoutId: ReturnType<typeof setTimeout>;
    if (step === AuthFormStep.CODE_INPUT && currentPhone) {
      timeoutId = setTimeout(() => activationCodeStatus.request(currentPhone), DELAY_FOR_CHECK_SMS);
    }

    return () => clearTimeout(timeoutId);
  }, [step]);

  useEffect(() => {
    if (!currentPhone || step !== AuthFormStep.CODE_INPUT) {
      return;
    }

    const country = PhoneUtils.parsePhoneNumberFromString(currentPhone)?.country;
    if (country) {
      onPhoneSubmit(currentPhone, country);
    }
  }, []);

  useEffect(() => {
    const phoneError = modifyCaptchaErrorMessage(phoneLogin.code.error?.message);

    setErrors({
      phone: phoneError,
      code: phoneLogin.token.error?.message || register.phone.error?.message || phoneError,
      email: defaultLogin.error?.message || register.email.data.errors?.email?.[0],
      password: defaultLogin.error?.message || register.email.data.errors?.password?.[0],
    });
    setLoading(false);
  }, [
    phoneLogin.code.error?.message,
    phoneLogin.token.error?.message,
    defaultLogin.error?.message,
    register.phone.error?.message,
    register.email.data.errors?.email?.[0],
    register.email.data.errors?.password?.[0],
  ]);

  useEffect(() => {
    onPhoneChange?.(currentPhone, currentPhoneCountryCode);
  }, [currentPhone, currentPhoneCountryCode, onPhoneChange]);

  return {
    errors,
    isLoading,
    isCodeLoading,
    hasSmsTroubles,
    codeCountdown,
    step,
    mode,
    initData,
    phone: {
      value: currentPhone,
      country: currentPhoneCountryCode,
    },
    onCodeSubmit,
    onPhoneSubmit,
    onLegacyFormSubmit,
    onChangeAuthMethod,
    onCodeRequest,
    validatePhone,
    onSetPhone: setCurrentPhone,
  };
};

export default useAuthForm;
