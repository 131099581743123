import React, { useState } from 'react';

import Input, { InputProps, InputType } from 'ui/Input';
import IconEye from 'ui/icons/Eye';
import IconEyeOff from 'ui/icons/EyeOff';
import IconButton from 'ui/IconButton';

type fields =
  | 'label'
  | 'value'
  | 'inputRef'
  | 'isDisabled'
  | 'isLoading'
  | 'inputClassName'
  | 'containerClassName'
  | 'placeholder'
  | 'elementId'
  | 'error'
  | 'onChange'
  | 'onEnterPressed'
  | 'onBlur';

export type PasswordInputProps = Pick<InputProps, fields>;

const DEFAULT_PLACEHOLDER = 'password';

const PasswordInput = (props: PasswordInputProps) => {
  const {
    label,
    error,
    value = '',
    inputRef,
    isDisabled = false,
    isLoading = false,
    containerClassName,
    inputClassName,
    placeholder,
    elementId,
    onChange,
    onEnterPressed,
    onBlur,
  } = props;

  const [type, setType] = useState<InputType>(InputType.PASSWORD);

  const isHidden: boolean = type === InputType.PASSWORD;

  return (
    <Input
      label={label}
      error={error}
      type={type}
      value={value}
      inputRef={inputRef}
      isDisabled={isDisabled}
      isLoading={isLoading}
      containerClassName={containerClassName}
      inputClassName={inputClassName}
      placeholder={placeholder || DEFAULT_PLACEHOLDER}
      elementId={elementId}
      onChange={onChange}
      onEnterPressed={onEnterPressed}
      onBlur={onBlur}
      adornmentRight={
        <IconButton
          onClick={() => setType(isHidden ? InputType.TEXT : InputType.PASSWORD)}
          elementId="password_input_button_is_hidden">
          {isHidden ? <IconEye /> : <IconEyeOff />}
        </IconButton>
      }
    />
  );
};

export default PasswordInput;
