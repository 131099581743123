import { useBillingPaymentMethodLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { PaymentMethodResultType, PaymentMethodType } from './types';

const usePaymentMethod = () => {
  const [query, queryResult] = useBillingPaymentMethodLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (companyId: string): Promise<PaymentMethodType> => {
    const result = await query({
      variables: {
        companyId,
      },
    });

    return {
      paymentMethod: result.data?.paymentMethodPureQuery?.paymentMethod || undefined,
      isLinkingInProcess: result.data?.paymentMethodPureQuery?.isLinkingInProcess,
    };
  };

  const result: PaymentMethodResultType = {
    error: queryResult.error,
    loading: queryResult.loading,
  };

  return {
    request,
    result,
  };
};

export default usePaymentMethod;
