import { useRef, useState } from 'react';

import { MediafilePureType, useMediaMediafilesRandomLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const PAGE_SIZE = 50;

const useRandomMediafiles = () => {
  const ref = useRef<Set<string>>();
  const [isReloading, setIsReloading] = useState(true);

  const [query, queryResult] = useMediaMediafilesRandomLazyQuery({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (id: string, excludeMediafiles: Array<string> = []) => {
    ref.current = undefined;
    setIsReloading(true);

    await query({
      variables: {
        filters: { streamId: id, excludeMediafilesIds: excludeMediafiles, excludeDisliked: true },
        pagination: { pageSize: PAGE_SIZE },
      },
    });

    setIsReloading(false);
  };

  const loadMore = async (id: string, excludeMediafiles: Array<string> = []) => {
    const newIds = excludeMediafiles.filter((item) => !ref.current?.has(item));

    if (newIds.length === 0) return;
    ref.current = new Set(excludeMediafiles);

    await queryResult.fetchMore({
      variables: {
        id,
        filters: { streamId: id, excludeMediafilesIds: excludeMediafiles, excludeDisliked: true },
        pagination: {
          pageSize: PAGE_SIZE,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        streamMediafilesRandomPaginationQuery: {
          ...prev?.streamMediafilesRandomPaginationQuery,
          ...fetchMoreResult?.streamMediafilesRandomPaginationQuery,
          result: [
            ...(prev.streamMediafilesRandomPaginationQuery?.result || []),
            ...(fetchMoreResult.streamMediafilesRandomPaginationQuery?.result || []),
          ],
        },
      }),
    });
  };

  const mediafilesList = queryResult.data?.streamMediafilesRandomPaginationQuery?.result as
    | MediafilePureType[]
    | undefined;

  return {
    request,
    loadMore,
    isReloading,
    called: queryResult.called,
    error: queryResult.error,
    loading: queryResult.loading,
    data: {
      mediafilesList,
      paginationInfo: queryResult.data?.streamMediafilesRandomPaginationQuery.paginationInfo,
    },
  };
};

export default useRandomMediafiles;
