import {
  useBusinessProfilePlaylistCreateFromStreamMutation,
  ZvukPlaylistCreateFromStreamMutationInput,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import {
  BusinessProfileCreatePlaylistRequestResultType,
  UseBusinessProfileCreatePlaylistFromStreamType,
} from './types';

const useBusinessProfilePlaylistCreateFromStream = (): UseBusinessProfileCreatePlaylistFromStreamType => {
  const [mutation, mutationResult] = useBusinessProfilePlaylistCreateFromStreamMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukPlaylistCreateFromStreamMutationInput) => {
    const mutationData = await mutation({
      variables: {
        input,
      },
    });

    return {
      isOk: !!mutationData.data?.zvukPlaylistCreateFromStream?.ok,
      playlist: mutationData.data?.zvukPlaylistCreateFromStream?.playlist,
    };
  };

  const zvukProfilePlaylistCreateFromStreamData = mutationResult.data?.zvukPlaylistCreateFromStream;
  const hasErrors = !!zvukProfilePlaylistCreateFromStreamData?.errors;
  const zvukProfilePlaylistCreateFromStreamResultData = {
    ok: zvukProfilePlaylistCreateFromStreamData?.ok,
    errors: hasErrors
      ? GraphQLApiUtils.errorsArrayToObject(zvukProfilePlaylistCreateFromStreamData?.errors)
      : undefined,
  };

  const result: BusinessProfileCreatePlaylistRequestResultType = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    data: zvukProfilePlaylistCreateFromStreamResultData,
  };

  return [request, result];
};

export default useBusinessProfilePlaylistCreateFromStream;
