import React from 'react';
import classNames from 'classnames';

import CommunicationUtils from 'application/utils/CommunicationUtils';
import IconMessageCircle from '@zvuk-b2b/react-uikit/ui/icons/MessageCircle';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';

import { Country } from 'domain/api/graphql/generated';
import styles from './index.module.scss';

type ContactWithManagerButtonProps = {
  className?: string;
  country?: Country;
};

const ContactWithManagerButton = ({ className, country }: ContactWithManagerButtonProps) => (
  <IconButton
    key="button_manager_contact"
    elementId="button_manager_contact"
    className={classNames(styles.ContactWithManagerButton, className)}
    onClick={() => CommunicationUtils.connectWithManager(country)}>
    <IconMessageCircle />
  </IconButton>
);

export default ContactWithManagerButton;
