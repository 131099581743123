import { usePointSetCompanyTagsMutation, PointPureSetCompanyTagsMutationInput } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { PointSetCompanyTagResultType, UsePointSetCompanyTagsType } from './types';

const usePointSetCompanyTags = (): UsePointSetCompanyTagsType => {
  const [createMutation, createMutationResult] = usePointSetCompanyTagsMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: PointPureSetCompanyTagsMutationInput) => {
    const newTag = await createMutation({
      variables: {
        input,
      },
    });

    return !!newTag.data?.pointPureSetCompanyTags?.ok;
  };

  const result: PointSetCompanyTagResultType = {
    error: createMutationResult.error,
    loading: createMutationResult.loading,
    called: createMutationResult.called,
  };

  return [request, result];
};

export default usePointSetCompanyTags;
