import AnalyticsService from 'application/services/AnalyticsService';
import {
  BusinessProfileEventNameAMP,
  BusinessProfileParamNameAMP,
} from 'application/services/AnalyticsService/ampTypes';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { ApolloError } from '@apollo/client';
import { BusinessProfileFormData } from '../BusinessProfileForm/types';
import { TEXTS } from './texts';
import { BusinessProfileModalMode } from './types';
import useBusinessProfileStatusHelpers from '../useBusinessProfileHelpers';

type TrackProfileUpdateType = {
  profileFormData: BusinessProfileFormData;
};

type ShowErrorNotificationType = {
  error: Error;
  businessProfileCreateError?: ApolloError | null;
  businessProfileUpdateError?: ApolloError | null;
};

type UseBusinessProfileUpsertModalInfoType = {
  mode: BusinessProfileModalMode;
};

const useBusinessProfileUpsertModalInfo = ({ mode }: UseBusinessProfileUpsertModalInfoType) => {
  const notification = useNotificationContext();
  const { isSyncStatusError, logProfileCreate } = useBusinessProfileStatusHelpers();

  const isCreateMode = mode === BusinessProfileModalMode.CREATE;

  const trackFormVisit = () => {
    AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_CREATE_FORM_VISIT, {
      [BusinessProfileParamNameAMP.PROFILE_CREATE_FORM_MODE]: BusinessProfileModalMode,
    });
  };

  const trackProfileUpdate = ({ profileFormData }: TrackProfileUpdateType) => {
    const eventType = isCreateMode
      ? BusinessProfileEventNameAMP.BUSINESS_PROFILE_CREATE
      : BusinessProfileEventNameAMP.BUSINESS_PROFILE_UPDATE;

    logProfileCreate({ formData: profileFormData, eventName: eventType });
  };

  const showSuccessNotification = () => {
    const successText = isCreateMode
      ? TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_CREATE_SUCCESS_TEXT
      : TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_UPDATE_SUCCESS_TEXT;

    notification.showNotification({
      type: NotificationColors.INFO,
      children: successText,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  const showApolloErrorNotification = ({
    error,
    businessProfileCreateError,
    businessProfileUpdateError,
  }: ShowErrorNotificationType) => {
    const updateOrPublishErrorMessage = businessProfileUpdateError?.message;

    const errorTextFromServer = isCreateMode ? businessProfileCreateError?.message : updateOrPublishErrorMessage;

    const errorTextFallback = isCreateMode
      ? TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_CREATE_ERROR_TEXT
      : TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_UPDATE_ERROR_TEXT;

    notification.showNotification({
      type: NotificationColors.ERROR,
      children: error.message || errorTextFromServer || errorTextFallback,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  const showErrorNotification = ({
    error,
    businessProfileCreateError,
    businessProfileUpdateError,
  }: ShowErrorNotificationType) => {
    const isSyncError = isSyncStatusError(error);

    if (isSyncError) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_SYNC_STATUS_ERROR_TEXT,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    } else {
      showApolloErrorNotification({
        error,
        businessProfileCreateError,
        businessProfileUpdateError,
      });
    }
  };

  const showFieldErrorsNotification = () => {
    notification.showNotification({
      type: NotificationColors.ERROR,
      children: TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_CREATE_ERROR_TEXT,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  return {
    trackProfileUpdate,
    trackFormVisit,
    showSuccessNotification,
    showErrorNotification,
    showFieldErrorsNotification,
  };
};

export default useBusinessProfileUpsertModalInfo;
