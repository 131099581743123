import React from 'react';

import { TEXTS } from 'application/components/AuthForm/texts';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import styles from './index.module.scss';

export enum ToasterSMSTroubleMode {
  REGISTER,
  LOGIN,
}

export type ToasterSMSTroubleProps = {
  mode: ToasterSMSTroubleMode;
  onSubmit: () => void;
  onClose: () => void;
};

const ToasterSMSTrouble = ({ mode, onSubmit, onClose }: ToasterSMSTroubleProps) => (
  <div className={styles.ToasterSMSTrouble}>
    <span>
      {mode === ToasterSMSTroubleMode.REGISTER && TEXTS.ERROR_REGISTRATION_SMS_TROUBLE_TITLE}
      {mode === ToasterSMSTroubleMode.LOGIN && TEXTS.ERROR_LOGIN_SMS_TROUBLE_TITLE}
    </span>

    <div className={styles.ToasterSMSTroubleButtonContainer}>
      <Button
        elementId="button_company_sms_troubles_fallback"
        className={styles.ToasterSMSTroubleButton}
        type={ButtonType.PLAIN}
        onClick={onSubmit}>
        {mode === ToasterSMSTroubleMode.REGISTER && TEXTS.BUTTON_REGISTRATION_SMS_TROUBLE_SUBMIT}
        {mode === ToasterSMSTroubleMode.LOGIN && TEXTS.BUTTON_LOGIN_SMS_TROUBLE_SUBMIT}
      </Button>

      <Button
        elementId="button_company_sms_troubles_close"
        className={styles.ToasterSMSTroubleButton}
        type={ButtonType.PLAIN}
        onClick={onClose}>
        {mode === ToasterSMSTroubleMode.REGISTER && TEXTS.BUTTON_REGISTRATION_SMS_TROUBLE_CLOSE}
        {mode === ToasterSMSTroubleMode.LOGIN && TEXTS.BUTTON_LOGIN_SMS_TROUBLE_CLOSE}
      </Button>
    </div>
  </div>
);

export default ToasterSMSTrouble;
