import React from 'react';

import { ACTIVATION_TRIAL_WARNING_PAGE_PATH } from 'application/consts';
import CleanLayout from 'application/layout/CleanLayout';
import TrialWarningPageWrapper from 'application/pages/TrialWarningPage';

import { RouteConfig } from '..';

export const activationTrialWarningRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: ACTIVATION_TRIAL_WARNING_PAGE_PATH,
        element: <TrialWarningPageWrapper />,
      },
    ],
  },
];
