import React from 'react';

export const TEXTS = {
  EMPTY_POINTS_DESCRIPTION: 'Чтобы сформировать сертификат, нужно добавить и оплатить все необходимые адреса вещания.',
  EMPTY_POINTS_DESCRIPTION_CONNECT_WITH_MANAGER: 'Для создания адреса вещания свяжитесь с менеджером.',
  EMPTY_PAID_POINTS_DESCRIPTION_RU: (
    <>
      Нужен сертификат о том, что музыка играет легально? Оплатите адреса, чтобы сформировать по ним сертификат и
      направить в РАО <span>и/или</span> ВОИС.
    </>
  ),
  EMPTY_PAID_PONTS_DESCRIPTION_OTHER:
    'Нужен сертификат о том, что музыка играет легально? Оплатите адреса, чтобы сформировать по ним сертификат.',
  EMPTY_POINTS_TRIAL_NO_RU_DESCRIPTION: 'Чтобы сформировать сертификат, нужно добавить адреса вещания.',
};
