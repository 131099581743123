import React from 'react';

import {
  ImageFile,
  ImageFileErrorCode,
  ImageFileError,
  ImageUploaderWidthValidationType,
  ImageUploaderHeightValidationType,
  GetImageRejectionProps,
} from './types';
import FileUploaderUtils from '../utils';
import { FileRejection } from '../types';

class ImageUploaderUtils {
  static getImageFilesFromEvent = async (event: React.ChangeEvent<HTMLInputElement>): Promise<ImageFile[]> => {
    const initialFiles = await FileUploaderUtils.getFilesFromChangeEvent(event);
    const promises = [];

    for (let index = 0; index < initialFiles.length; index++) {
      const file = initialFiles[index] as ImageFile;

      const promise = new Promise((resolve) => {
        const image = new Image();

        image.onload = () => {
          file.width = image.width;
          file.height = image.height;
          resolve(file);
        };

        image.src = URL.createObjectURL(file);
      });

      promises.push(promise);
    }

    const result = await Promise.all(promises);

    return result as ImageFile[];
  };

  static getImageWidthRejection = (
    imageFile: ImageFile,
    errors: ImageFileError[],
    {
      width,
      minWidth,
      maxWidth,
      widthErrorText,
      minWidthErrorText,
      maxWidthErrorText,
    }: ImageUploaderWidthValidationType
  ) => {
    if (width) {
      if (imageFile.width !== width) {
        errors.push({
          code: ImageFileErrorCode.WidthMustBe,
          message: widthErrorText,
        });
      }
    } else {
      if (minWidth && imageFile.width < minWidth) {
        errors.push({
          code: ImageFileErrorCode.WidthTooSmall,
          message: minWidthErrorText,
        });
      }

      if (maxWidth && imageFile.width > maxWidth) {
        errors.push({
          code: ImageFileErrorCode.WidthTooLarge,
          message: maxWidthErrorText,
        });
      }
    }

    return errors;
  };

  static getImageHeightRejection = (
    imageFile: ImageFile,
    errors: ImageFileError[],
    {
      height,
      minHeight,
      maxHeight,
      heightErrorText,
      minHeightErrorText,
      maxHeightErrorText,
    }: ImageUploaderHeightValidationType
  ) => {
    if (height) {
      if (imageFile.height !== height) {
        errors.push({
          code: ImageFileErrorCode.HeightMustBe,
          message: heightErrorText,
        });
      }
    } else {
      if (minHeight && imageFile.height < minHeight) {
        errors.push({
          code: ImageFileErrorCode.HeightTooSmall,
          message: minHeightErrorText,
        });
      }

      if (maxHeight && imageFile.height > maxHeight) {
        errors.push({
          code: ImageFileErrorCode.HeightTooLarge,
          message: maxHeightErrorText,
        });
      }
    }

    return errors;
  };

  static getImageRejection = (
    file: File,
    {
      width,
      height,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      widthErrorText,
      heightErrorText,
      minWidthErrorText,
      maxWidthErrorText,
      minHeightErrorText,
      maxHeightErrorText,
      size,
      minSize,
      maxSize,
      sizeErrorText,
      minSizeErrorText,
      maxSizeErrorText,
    }: GetImageRejectionProps
  ): FileRejection<ImageFileErrorCode> | null => {
    const imageFile = file as ImageFile;

    const errors: ImageFileError[] = [];

    ImageUploaderUtils.getImageWidthRejection(imageFile, errors, {
      width,
      minWidth,
      maxWidth,
      widthErrorText,
      minWidthErrorText,
      maxWidthErrorText,
    });

    ImageUploaderUtils.getImageHeightRejection(imageFile, errors, {
      height,
      minHeight,
      maxHeight,
      heightErrorText,
      minHeightErrorText,
      maxHeightErrorText,
    });

    FileUploaderUtils.getFileSizeRejection(imageFile, errors, {
      size,
      minSize,
      maxSize,
      sizeErrorText,
      minSizeErrorText,
      maxSizeErrorText,
    });

    const rejection: FileRejection<ImageFileErrorCode> | null =
      errors.length !== 0
        ? {
            file: imageFile,
            errors,
          }
        : null;

    return rejection;
  };
}

export default ImageUploaderUtils;
