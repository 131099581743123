import {
  useCompanyUserCompanyTagsLazyQuery,
  CompanyTagQueryFilter,
  CompanyTagQueryPagination,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseCompanyTagsType, CompanyTagsResultType } from './types';

const useCompanyTags = (): UseCompanyTagsType => {
  const [query, { data, called, loading, networkStatus, fetchMore, refetch }] = useCompanyUserCompanyTagsLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (filters: CompanyTagQueryFilter, pagination: CompanyTagQueryPagination) => {
    const result = await query({
      variables: {
        filters,
        pagination,
      },
    });

    return result;
  };

  const loadMore = async (filters: CompanyTagQueryFilter, pagination: CompanyTagQueryPagination) => {
    await fetchMore({
      variables: {
        filters,
        pagination,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const data = {
          ...prev,
          companyTagPaginationQuery: {
            ...prev?.companyTagPaginationQuery,
            ...fetchMoreResult?.companyTagPaginationQuery,
            result: [
              ...(prev?.companyTagPaginationQuery?.result || []),
              ...(fetchMoreResult?.companyTagPaginationQuery.result || []),
            ],
          },
        };

        return data;
      },
    });
  };

  const result: CompanyTagsResultType = {
    loading,
    called,
    networkStatus,
    data: {
      tags: data?.companyTagPaginationQuery.result?.map((tag) => ({
        ...tag,
        adCampaigns: tag.adCampaigns?.map((ad) => ({ id: ad.id })),
        points: tag.points?.map((point) => ({ id: point.id })),
      })),
      paginationInfo: data?.companyTagPaginationQuery.paginationInfo,
    },
    loadMore,
    refetch,
  };

  return [request, result];
};

export default useCompanyTags;
