import { useMemo, useState } from 'react';

import { STREAMS_CATEGORY_PATH, StreamsCategoryParamValues } from 'application/pages/StreamPage';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import AnalyticsService, {
  StreamBindFlowEventNameAMP,
  StreamBindFlowParamNameAMP,
} from 'application/services/AnalyticsService';
import { Country, UserFeatures } from 'domain/api/graphql/generated';

import { useFeatureFlagsContext } from 'application/providers/FeatureFlagsProvider';
import { PLAYER_TEXT_TYPE, PlayerRadioType, PlayerTypesInFlow, UseSelectPlayerModalControllerProps } from './types';

const useSelectPlayerModalController = ({
  targetStreamId,
  isTemplateMode,
  onboardingPassedRequest,
  refetchFeaturesOnboarding,
  onNext,
  country,
}: UseSelectPlayerModalControllerProps) => {
  const navigate = useNavigate();

  const { bindVideoDevice } = useFeatureFlagsContext();

  const [selectedPlayer, setSelectedPlayer] = useState<PlayerTypesInFlow>(PlayerTypesInFlow.WEBPLAYER);
  const [confirmSelectedPlayerLoading, setConfirmSelectedPlayerLoading] = useState<boolean>(false);

  const playersData = useMemo(() => {
    const players = [] as PlayerRadioType[];

    for (const type in PlayerTypesInFlow) {
      if (Object.prototype.hasOwnProperty.call(PlayerTypesInFlow, type)) {
        const playerType = type as PlayerTypesInFlow;

        const hideDevice = PLAYER_TEXT_TYPE[playerType].ruByOnly && !(country === Country.Ru || country === Country.By);

        const isHideVideoDevice = playerType === PlayerTypesInFlow.VIDEOPLAYER && !bindVideoDevice;

        if (!hideDevice && !isHideVideoDevice) {
          players.push({
            title: PLAYER_TEXT_TYPE[playerType].title,
            description: PLAYER_TEXT_TYPE[playerType].description,
            type: playerType,
          });
        }
      }
    }

    return players;
  }, []);

  const onPlayerChange = (playerType: PlayerTypesInFlow) => {
    setSelectedPlayer(playerType);
    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_SELECT_PLAYER_RADIO_CLICKED, {
      [StreamBindFlowParamNameAMP.PLAYER_TYPE]: playerType,
    });
  };

  const onBindStream = async (withOpenCreatedStream?: boolean) => {
    const streamId = targetStreamId;

    if (isTemplateMode && withOpenCreatedStream) {
      navigate(
        RouterUtils.generatePath(STREAMS_CATEGORY_PATH, {
          category: StreamsCategoryParamValues.SAVED,
          collectionId: 0,
          id: streamId!,
        })
      );
    }

    const isOk = await onboardingPassedRequest({ feature: UserFeatures.WarningStreamFirstStart });

    if (!isOk) {
      throw new Error();
    }

    refetchFeaturesOnboarding();
  };

  const handleClick = async () => {
    setConfirmSelectedPlayerLoading(true);

    if (selectedPlayer === PlayerTypesInFlow.WEBPLAYER) {
      await onBindStream(true);
    }

    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_SELECT_PLAYER_CLICKED, {
      [StreamBindFlowParamNameAMP.PLAYER_TYPE]: selectedPlayer,
    });

    await onNext?.({ deviceType: selectedPlayer });

    setConfirmSelectedPlayerLoading(false);
  };

  return {
    selectedPlayer,
    playersData,
    confirmSelectedPlayerLoading,
    onPlayerChange,
    handleClick,
  };
};

export default useSelectPlayerModalController;
