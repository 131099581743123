import { FetchPolicyKeys } from 'domain/api/graphql/types';
import {
  MediafilesStreamDocument,
  MediafilesStreamQuery,
  StreamPureDislikeMediafileMutationInput,
  useExcludeMediafileMutation,
} from 'domain/api/graphql/generated';
import { ApolloCache } from '@apollo/client';
import { DislikeMediafileResultType } from './types';

const optimisticResponse = {
  __typename: 'Mutation',
  result: {
    __typename: 'StreamPureDislikeMediafileMutation',
    ok: true,
    errors: null,
  },
} as const;

type VariablesType = { filters: { streamId: string } };
type RequestInputType = StreamPureDislikeMediafileMutationInput & { contentBlockId?: string };

const handleSavedMediafiles = (cache: ApolloCache<unknown>, variables: VariablesType, mediaFileId: string) => {
  const savedMediafiles = cache.readQuery<MediafilesStreamQuery>({
    query: MediafilesStreamDocument,
    variables,
  });

  if (savedMediafiles) {
    const modifiedCache = savedMediafiles.streamMediafiles.result?.filter((el) => el.id !== mediaFileId);

    cache.writeQuery({
      query: MediafilesStreamDocument,
      variables,
      data: {
        streamMediafiles: {
          ...savedMediafiles.streamMediafiles,
          result: modifiedCache,
          paginationInfo: {
            ...savedMediafiles.streamMediafiles.paginationInfo,
            totalCount: savedMediafiles.streamMediafiles.paginationInfo.totalCount - 1,
            pageSize: savedMediafiles.streamMediafiles.paginationInfo.totalCount - 1,
          },
        },
      },
    });
  }
};

const useExcludeMediafile = () => {
  const [mutation, mutationResult] = useExcludeMediafileMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
    refetchQueries: ['mediaStream', 'mediaDislikedMediafilesPagination', 'mediafilesApproval'],
  });

  const removeMediaFileFromCache = (cache: ApolloCache<unknown>, input: RequestInputType) => {
    const { id, mediafileId, contentBlockId } = input;
    const streamId = id.toString();
    const mediaFileId = mediafileId.toString();
    const variables = { filters: { streamId, contentBlockId } };

    handleSavedMediafiles(cache, variables, mediaFileId);
  };

  const request = async (input: RequestInputType) => {
    const mutationResult = await mutation({
      variables: { input: { id: input.id, mediafileId: input.mediafileId } },
      optimisticResponse,
      update: (cache) => removeMediaFileFromCache(cache, input),
    });

    return mutationResult.data?.result?.ok;
  };

  const result: DislikeMediafileResultType = {
    error: mutationResult.error,
    loading: mutationResult.loading,
  };

  return {
    request,
    result,
  };
};

export default useExcludeMediafile;
