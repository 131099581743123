import React, { FC } from 'react';

import MediaUploaderWrapper from 'application/components/MediaUploaderWrapper';
import { TEXTS } from 'application/pages/AdsPage/renderer/texts';
import { ACCEPT_FILETYPES } from 'application/pages/constants';
import CommonUtils from 'application/utils/CommonUtils';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { withProviders } from 'application/helpers';
import PlayerProvider from 'application/providers/PlayerProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import Input, { InputType } from '@zvuk-b2b/react-uikit/ui/Input';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import useAdCreateModalPresenter from './useAdCreateModalPresenter';
import useAdCreateModalController from './useAdCreateModalController';

import styles from './index.module.scss';

const AdCreateModal: FC<ModalProps[ModalTypes.AD_CAMPAIGN_CREATE]> = ({ close, onCreate }) => {
  const { isLoading, createAdCampaign, clearAdCampaigns } = useAdCreateModalPresenter();
  const {
    items,
    adName,
    setAdName,
    isAnyItemLoading,
    onFilesSelected,
    onRetryFile,
    onSubmitClick,
    onCancelClick,
    onRemoveClick,
  } = useAdCreateModalController({ close, onCreate, createAdCampaign, clearAdCampaigns });

  return (
    <ModalScreen
      title={TEXTS.MODAL_AD_CREATE_TITLE}
      titlePlacement="left"
      onClose={onCancelClick}
      actionButtons={[
        <Button
          key="button_create_new_ad_modal"
          elementId="button_create_new_ad_modal"
          type={ButtonType.PRIMARY}
          isDisabled={
            adName.length === 0 ||
            items.length === 0 ||
            !!items.find((item) => !!item.error) ||
            isAnyItemLoading ||
            isLoading
          }
          onClick={onSubmitClick}
          isLoading={isLoading}>
          {TEXTS.BUTTON_AD_CREATE_TITLE}
        </Button>,
      ]}>
      <Input
        elementId="input_start-campaign"
        containerClassName={styles.AdCreateModalInput}
        placeholder={TEXTS.INPUT_AD_CREATE_PLACEHOLDER}
        label={TEXTS.INPUT_AD_CREATE_TITLE}
        isDisabled={isLoading}
        type={InputType.TEXT}
        value={adName}
        onChange={setAdName}
      />

      <MediaUploaderWrapper
        elementId="media_uploader_create_new_campaign"
        accept={ACCEPT_FILETYPES}
        items={items}
        isLoading={isLoading}
        buttonTitle={TEXTS.INPUT_AD_CREATE_MEDIA_UPLOAD_TITLE}
        description={TEXTS.MODAL_AD_CREATE_MEDIA_UPLOAD_DESC(CommonUtils.convertObjectValuesToString(ACCEPT_FILETYPES))}
        onDrop={onFilesSelected}
        onRemove={onRemoveClick}
        onRetry={onRetryFile}
        mediaUploaderClassname={styles.AdCreateModalMediaUploaderList}
      />
    </ModalScreen>
  );
};

export default withProviders(AdCreateModal, [PlayerProvider]);
