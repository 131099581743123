import React from 'react';

import { IdType } from 'application/pages/types';

import { SelectableListItemProps } from '../types';

import styles from './index.module.scss';

const SelectableListItem = <T extends IdType>({
  elementId,
  selectorRenderer: Renderer,
  currentItem,
  onChange,
  isChecked,
  children,
  isDisabled = false,
  isLoading = false,
  labelPlacement,
}: SelectableListItemProps<T>) => (
  <div
    className={styles.SelectableListItem}
    data-selector-id={`container_${elementId}_${currentItem.id}`}
    key={`selectable_list_item_${currentItem.id}`}>
    <Renderer
      key={currentItem.id}
      elementId={`${elementId}_${currentItem.id}`}
      isChecked={isChecked}
      labelPlacement={labelPlacement}
      onChange={(value: boolean) => {
        onChange(value, currentItem);
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}>
      {children}
    </Renderer>
  </div>
);

export default SelectableListItem;
