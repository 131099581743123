import React, { useEffect } from 'react';
import classNames from 'classnames';

import ContactWithManagerButton from 'application/components/ContactWithManagerButton';
import LogoutSmallButton from 'application/components/LogoutSmallButton';
import { useAuthContext } from 'application/providers/AuthProvider';
import Logo from 'application/components/Logo';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';

import WebPlayerWidget from 'application/components/widgets/WebPlayer';
import { useWebPlayerContext } from 'application/providers/WebPlayerProvider';
import { useFeatureFlagsContext } from 'application/providers/FeatureFlagsProvider';
import styles from './index.module.scss';

const DEFAULT_TITLE = 'Звук Бизнес';

export type CleanMainProps = React.PropsWithChildren<{
  containerClassName?: string;
  contentClassName?: string;
  title?: string;
  contentContainerAs?: React.ElementType;
}>;

const CleanMain = (props: CleanMainProps) => {
  const { children, title, contentContainerAs: Renderer = 'div', contentClassName, containerClassName } = props;
  const authContext = useAuthContext();

  const { isDesktop, isMobile } = useMedia();
  const { showContactWithManagerButton } = useFeatureFlagsContext();

  const { isWidgetActive } = useWebPlayerContext();

  useEffect(() => {
    document.title = title || DEFAULT_TITLE;
  }, [title]);

  return (
    <main className={styles.CleanMain}>
      <header className={classNames(styles.CleanMainHeader)}>
        {isMobile && showContactWithManagerButton && (
          <ContactWithManagerButton className={styles.CleanMainContactButton} />
        )}

        <Logo />

        {isMobile && !!authContext.authToken && <LogoutSmallButton className={styles.CleanMainLogoutButton} />}
      </header>

      <section className={classNames(styles.CleanMainSection, containerClassName)}>
        <Renderer className={classNames(styles.CleanMainSectionContent, contentClassName)}>{children}</Renderer>
      </section>

      {isDesktop && showContactWithManagerButton && (
        <ContactWithManagerButton className={styles.CleanMainContactButton} />
      )}

      {isDesktop && !!authContext.authToken && <LogoutSmallButton className={styles.CleanMainLogoutButton} />}

      {isWidgetActive && <WebPlayerWidget />}
    </main>
  );
};

export default CleanMain;
