import { usePointSmartLkCreateMutation, SmartlkPointCreateMutationInput } from 'domain/api/graphql/generated';

import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { PointCreateRequestResultType } from './types';

const usePointCreate = () => {
  const [mutation, mutationResult] = usePointSmartLkCreateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: SmartlkPointCreateMutationInput): Promise<PointCreateRequestResultType> => {
    const mutationResult = await mutation({
      variables: { input },
      refetchQueries: ['pointList'],
    });

    return {
      ok: !!mutationResult.data?.smartlkPointCreate?.ok,
      point: mutationResult.data?.smartlkPointCreate?.point || undefined,
    };
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    request,
  };
};

export default usePointCreate;
