import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'ahooks';
import classNames from 'classnames';

import Input from 'ui/Input';
import RichInput, { RichInputProps } from 'ui/RichInput';
import Spinner from 'ui/Spinner';
import { Colors } from 'ui/types';
import ToolkitUtils from 'toolkit/utils';
import { mobileQueryList, useMediaQueryChangeListener } from 'toolkit/useMedia';

import Dropdown, { DropdownItemDataType, DropdownPlacement } from 'ui/Dropdown';

import useResizeIOS from 'toolkit/useResizeIOS';
import styles from './index.module.scss';
import IconButton from '../IconButton';
import IconX from '../icons/X';
import IconXCircle from '../icons/XCircle';

const DEBOUNCE_OPTIONS = { leading: true, wait: 400 };
const MAX_OPTIONS = 4;

export type AutocompleteProps = Partial<RichInputProps> & {
  options?: DropdownItemDataType[];
  dropdownClassName?: string;
  dropdownPlacement?: DropdownPlacement;
  optionClassName?: string;
  clearIconClassName?: string;
  useLargeOptionsContainer?: boolean;
  dropDownWidth?: string;
  useClearButton?: boolean;
  isMulti?: boolean;
  isFocused?: boolean;
  maxVisibleOptions?: number;
  maxOptions?: number;
  menuMargin?: number;
  isNeedCloseWhileParentScrolling?: boolean;
};

const Autocomplete = (props: AutocompleteProps) => {
  const {
    label,
    inputRef,
    error,
    required = false,
    value = '',
    options = [],
    isDisabled = false,
    isLoading = false,
    inputClassName,
    containerClassName,
    dropdownClassName,
    optionClassName,
    clearIconClassName,
    placeholder,
    elementId,
    autoComplete,
    onFocus,
    onBlur,
    onChange,
    onEnterPressed,
    onBackspacePressed,
    useLargeOptionsContainer = false,
    dropDownWidth = '400px',
    adornmentLeft,
    adornmentRight,
    useClearButton = false,
    size,
    isMulti = false,
    isFocused = false,
    extensions,
    maxVisibleOptions,
    maxOptions = MAX_OPTIONS,
    menuMargin,
    isNeedCloseWhileParentScrolling = true,
    dropdownPlacement = DropdownPlacement.AUTO,
  } = props;

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const debouncedIsOptionsVisible = useDebounce(isOptionsVisible, DEBOUNCE_OPTIONS);

  const [isFullMode, setIsFullMode] = useState(false);
  const isMobile = useMediaQueryChangeListener(mobileQueryList);

  const anchorRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLInputElement>(null);

  useEffect(
    useResizeIOS((iosHeight: number) => {
      const isFocused = document.activeElement === anchorRef.current! || anchorRef.current! === document.body;
      const isModalOpen = isOptionsVisible && isFullMode && isMobile;

      if (isModalOpen && isFocused && wrapperRef.current?.clientHeight !== iosHeight) {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }
    }),
    []
  );

  useEffect(() => {
    if (isFocused) {
      inputRef?.current?.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    if (isOptionsVisible && isFullMode && isMobile) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, [debouncedIsOptionsVisible]);

  const onFocusWrapper = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsOptionsVisible(true);
    onChange?.(value, event);
    onFocus?.(event);
    setIsFullMode(true);
  };

  const onChangeWrapper: RichInputProps['onChange'] = (value, event) => {
    setIsOptionsVisible(true);
    onChange?.(value, event);
    setIsFullMode(true);
  };

  const onDropdownClose = () => {
    setIsOptionsVisible(false);
    setIsFullMode(false);
  };

  const currentAdornmentRight = useMemo(() => {
    if (isLoading) {
      return (
        <Spinner
          color={Colors.COLOR_NEUTRAL_500}
          className={styles.AutocompleteContainerSpinner}
        />
      );
    }

    return value?.length && useClearButton ? (
      <IconButton
        elementId={`${props}_left_button`}
        className={classNames(styles.AutocompleteContainerClearIcon, clearIconClassName)}
        onClick={(e) => onChange?.('', e)}>
        <IconXCircle />
      </IconButton>
    ) : (
      adornmentRight
    );
  }, [adornmentRight, isLoading, value]);

  const InputRenderer = useMemo(() => (isMulti ? RichInput : Input), [isMulti]);

  return (
    <section
      className={classNames(
        containerClassName,
        isFullMode ? styles.AutocompleteContainerFullMode : styles.AutocompleteContainer
      )}
      ref={wrapperRef}>
      <InputRenderer
        value={value}
        label={label}
        error={error}
        ref={anchorRef}
        inputRef={inputRef}
        elementId={elementId!}
        isDisabled={isDisabled}
        placeholder={placeholder}
        adornmentLeft={adornmentLeft}
        adornmentRight={currentAdornmentRight}
        inputClassName={inputClassName}
        autoComplete={autoComplete}
        onChange={onChangeWrapper}
        onFocus={onFocusWrapper}
        onBlur={onBlur}
        onEnterPressed={onEnterPressed}
        onBackspacePressed={onBackspacePressed}
        required={required}
        isFullMode={isFullMode}
        size={size}
        extensions={extensions}
        labelIcon={
          isFullMode &&
          isMobile && (
            <IconButton
              elementId={`${elementId}_button__close`}
              className={styles.AutocompleteContainerInputCloseButton}
              onClick={(event) => {
                onDropdownClose();
                event.preventDefault();
              }}>
              <IconX />
            </IconButton>
          )
        }
      />

      {debouncedIsOptionsVisible && options.length > 0 && (
        <Dropdown
          items={options.slice(0, maxOptions)}
          style={{
            width: useLargeOptionsContainer && !isMobile ? dropDownWidth : ToolkitUtils.getWidth(anchorRef.current!),
          }}
          className={dropdownClassName}
          itemClassName={optionClassName}
          anchorNode={isMulti && !isMobile ? wrapperRef.current! : anchorRef.current!}
          parentNode={wrapperRef.current!}
          elementId={`${elementId}_autocomplete_dropdown`}
          onClose={onDropdownClose}
          isFullMode={isFullMode && isMobile}
          isMulti={isMulti}
          maxVisibleOptions={maxVisibleOptions}
          menuMargin={menuMargin}
          isNeedCloseWhileParentScrolling={isNeedCloseWhileParentScrolling}
          placement={dropdownPlacement}
        />
      )}
    </section>
  );
};

export default Autocomplete;
