import React from 'react';

import { FORGOT_COMPLETE_PAGE_PATH } from 'application/consts';
import LetterSentPageWrapper from 'application/pages/LetterSentPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const letterSentRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: FORGOT_COMPLETE_PAGE_PATH,
        element: <LetterSentPageWrapper />,
      },
    ],
  },
];
