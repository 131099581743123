export const TEXTS = {
  MODAL_TITLE: 'Приложение для ПК',

  INSTALLATION_GUIDE_EXPAND_BUTTON: 'Windows. Технические требования',

  REQUIREMENTS_ITEM_ONE: 'Windows 7 и выше;',
  REQUIREMENTS_ITEM_TWO: 'Процессор — Intel Pentium 4 (with SSE2) и новее;',
  REQUIREMENTS_ITEM_THREE: 'Свободная оперативная память — от 512 Mb;',
  REQUIREMENTS_ITEM_FOUR: 'Свободное место на диске — 30 Гб и выше;',
  REQUIREMENTS_ITEM_FIVE: 'Net Framework 4.8 и выше для Windows 7.z',
  REQUIREMENTS_ITEM_SIX: 'Интернет со скоростью не менее 500 Kb/sec.',

  COPY_APP_LINK: 'Копировать ссылку на скачивание для Windows',

  SUCCESS_BUTTON: 'Скачать приложение для Windows',
  SUCCESS_BUTTON_MOBILE: 'Скачать приложение',
  CANCEL_BUTTON: 'Назад',

  LINK_WAS_COPIED: 'Ссылка скопирована',
  APP_WAS_DOWNLOADED: 'Приложение скачивается...',
  APP_WAS_NOT_DOWNLOADED: 'Приложение доступно только для Windows',
};
