import React, { useLayoutEffect } from 'react';

import { useAuthContext } from 'application/providers/AuthProvider';
import { matchPath, Navigate, Outlet, RouterUtils, useLocation } from 'application/providers/RouterProvider';
import { LOGIN_PAGE_PATH } from 'application/consts';

import { AVAILABLE_TO_REDIRECT_PATHS } from './consts';

const PrivateRoute = () => {
  const { hasAccess } = useAuthContext();

  const location = useLocation();

  useLayoutEffect(() => {
    if (!hasAccess) {
      const isMatch = AVAILABLE_TO_REDIRECT_PATHS.find((path) => matchPath({ path, end: false }, location.pathname));

      if (isMatch) {
        RouterUtils.setTargetPathname(location.pathname);
      }
    }
  }, []);

  return hasAccess ? (
    <Outlet />
  ) : (
    <Navigate
      to={LOGIN_PAGE_PATH}
      replace
    />
  );
};

export default PrivateRoute;
