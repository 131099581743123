import { useAuthAllowedActionsForPhoneLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const usePhoneAllowedActions = () => {
  const [query, queryResult] = useAuthAllowedActionsForPhoneLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (phone: string) => {
    const result = await query({
      variables: { phone },
    });

    return result.data?.allowedActionsForPhone;
  };

  return {
    request,
    actions: queryResult.data?.allowedActionsForPhone,
    loading: queryResult.loading,
  };
};

export default usePhoneAllowedActions;
