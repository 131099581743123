import React, { FC, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import usePlayerChangeController from 'application/hooks/usePlayerChangeController';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import { DeviceTypes } from 'domain/api/graphql/generated';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import IconChevronDown from '@zvuk-b2b/react-uikit/ui/icons/ChevronDown';
import IconChevronRight from '@zvuk-b2b/react-uikit/ui/icons/ChevronRight';
import Input from '@zvuk-b2b/react-uikit/ui/Input';

import AnalyticsService, {
  StreamBindFlowEventNameAMP,
  StreamBindFlowParamNameAMP,
} from 'application/services/AnalyticsService';

import { PointParamNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { TEXTS } from './texts';

import styles from '../index.module.scss';

export type BindDesktopModalProps = {
  pointId: string;
};

const BindDesktopModal: FC<ModalProps[ModalTypes.DESKTOP_PLAYER_BIND]> = ({
  onPrevious,
  onConfirmClose,
  pointId,
  onNext,
  close,
}) => {
  const [isInstructionExtanded, setIsInstructionExtanded] = useState(false);

  const onSuccessCallback = useCallback(
    (deviceType: DeviceTypes) => {
      AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_PLAYER_TYPE_BOUND, {
        [StreamBindFlowParamNameAMP.PLAYER_TYPE]: deviceType,
        [PointParamNameAMP.POINT_ID]: pointId,
      });

      onNext?.({ deviceType });
    },
    [onNext]
  );

  const { bindingCode, setBindingCode, bindingCodeError, handleUpdateClick, updateDeviceLoading, setToPlayerType } =
    usePlayerChangeController({
      pointId,
      fromPlayerType: DeviceTypes.Desktop,
      onSuccessCallback,
    });

  useEffect(() => {
    setToPlayerType('APP');
  }, []);

  const handleCancel = () => {
    if (onConfirmClose) {
      onConfirmClose();
    } else {
      close();
    }
  };

  return (
    <ModalScreen
      titlePlacement="left"
      onClose={handleCancel}
      title={TEXTS.MODAL_TITLE}
      className={styles.DesktopPlayerFlowModal}
      contentClassName={styles.DesktopPlayerFlowModalContent}
      actionButtons={[
        <Button
          key="button_desktop_player_modal_bind_success"
          elementId="button_desktop_player_modal_bind_success"
          type={ButtonType.PRIMARY}
          isDisabled={bindingCode.length < 4}
          isLoading={updateDeviceLoading}
          onClick={handleUpdateClick}>
          {TEXTS.SUCCESS_BUTTON}
        </Button>,
        <Button
          key="button_desktop_player_modal_bind_cancel"
          elementId="button_desktop_player_modal_bind_cancel"
          type={ButtonType.SECONDARY}
          isLoading={updateDeviceLoading}
          onClick={onPrevious}>
          {TEXTS.CANCEL_BUTTON}
        </Button>,
      ]}>
      <div>{TEXTS.TITLE_DESCRIPTION}</div>

      <Input
        elementId="desktop_player_modal_bind_code_input"
        onChange={setBindingCode}
        value={bindingCode}
        placeholder={TEXTS.CODE_BIND_PLACEHOLDER}
        maxLength={4}
        label={TEXTS.CODE_BIND_TITLE}
        error={bindingCodeError}
        containerClassName={styles.DesktopPlayerFlowModalInput}
      />

      <Button
        type={ButtonType.PLAIN}
        onClick={() => {
          setIsInstructionExtanded(!isInstructionExtanded);
        }}
        className={classNames(styles.DesktopPlayerFlowModalExpandButton, styles.DesktopPlayerFlowModalButton)}
        elementId="desktop_player_modal_expand_button">
        <span>{TEXTS.INSTALLATION_GUIDE_EXPAND_BUTTON}</span>
        <div className={styles.DesktopPlayerFlowModalExpandButtonIcon}>
          {isInstructionExtanded ? <IconChevronDown /> : <IconChevronRight />}
        </div>
      </Button>

      {isInstructionExtanded && (
        <ul data-testid="desktop_player_modal_installation_guide_list">
          <li>{TEXTS.INSTALLATION_GUIDE_ITEM_ONE}</li>
          <li>{TEXTS.INSTALLATION_GUIDE_ITEM_TWO}</li>
          <li>{TEXTS.INSTALLATION_GUIDE_ITEM_THREE}</li>
          <li>{TEXTS.INSTALLATION_GUIDE_ITEM_FOUR}</li>
        </ul>
      )}
    </ModalScreen>
  );
};

export default BindDesktopModal;
