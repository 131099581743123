import React, { FC, useEffect } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { PointWithDevice } from 'application/presenters/point/types';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import { BILLING_PAGE_PATH, StateParamValues } from 'application/pages/BillingPage';
import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import AnalyticsService from 'application/services/AnalyticsService';
import { BillingEventNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { useCurrentPointAdapter } from 'application/adapters';

import Button from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import { TEXTS } from './texts';
import PointAddressItem from '../StreamBindSuccessModal/PointAddressesList/PointAddressItem';

import styles from './index.module.scss';

export type PaymentRequirementModalProps = {
  point: PointWithDevice;
};

const PaymentRequirementModal: FC<ModalProps[ModalTypes.PAYMENT_REQUIREMENT]> = ({ point, close }) => {
  const { clearCurrentPoint } = useCurrentPointAdapter();
  const navigate = useNavigate();
  const billingPagePath = RouterUtils.generatePath(BILLING_PAGE_PATH, { state: StateParamValues.INFO });

  useEffect(() => {
    AnalyticsService.event(BillingEventNameAMP.PAYMENT_REQUIREMENT_MODAL_OPEN);
  }, []);

  const onSubmit = () => {
    AnalyticsService.event(BillingEventNameAMP.PAYMENT_REQUIREMENT_MODAL_SUBMIT);
    close();
    navigate(billingPagePath, { replace: true });
  };

  const onClose = () => {
    clearCurrentPoint();
    close();
  };

  return (
    <ModalScreen
      title={TEXTS.MODAL_TITLE}
      titlePlacement="left"
      onClose={onClose}
      contentClassName={styles.PaymentRequirementModalContent}
      actionButtons={[
        <Button
          key="payment_requirement_modal_move_to_billing_button"
          elementId="payment_requirement_modal_move_to_billing_button"
          onClick={onSubmit}>
          {TEXTS.MODAL_BUTTON_SUBMIT}
        </Button>,
      ]}>
      <span className={styles.PaymentRequirementModalDescription}>{TEXTS.MODAL_DESCRIPTION}</span>
      {point.id && <PointAddressItem point={point as PointsListCheckboxGroupPointType} />}
    </ModalScreen>
  );
};

export default PaymentRequirementModal;
