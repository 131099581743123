import { useMediaAdCampaignRemoveMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseAdCampaignRemoveResultType, UseAdCampaignRemoveType } from './types';

const useAdCampaignRemove = (): UseAdCampaignRemoveType => {
  const [removeMutation, removeMutationResult] = useMediaAdCampaignRemoveMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (adCompanyId: string) => {
    const removedAdCampaign = await removeMutation({
      variables: {
        input: {
          adCompanyId,
        },
      },
      refetchQueries: ['mediaAdCampaignsPaginationQuery'],
    });

    return !!removedAdCampaign.data?.result?.ok;
  };

  const result: UseAdCampaignRemoveResultType = {
    error: removeMutationResult.error,
    loading: removeMutationResult.loading,
    called: removeMutationResult.called,
  };

  return [request, result];
};

export default useAdCampaignRemove;
