import React from 'react';
import classNames from 'classnames';

import IconCircle from 'ui/icons/Circle';
import Spinner from 'ui/Spinner';
import { Colors } from 'ui/types';

import styles from './index.module.scss';

export enum LabelPlacement {
  LEFT = 'left',
  RIGHT = 'right',
}

export type RadioProps = React.PropsWithChildren<{
  elementId: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isStretch?: boolean;
  isLoading?: boolean;
  className?: string;
  buttonClassName?: string;
  labelClassName?: string;
  labelPlacement?: LabelPlacement;
  onChange: (value: boolean, event?: React.ChangeEvent) => void;
}>;

const Radio = ({
  elementId,
  isChecked = false,
  isDisabled = false,
  isStretch = false,
  isLoading = false,
  className,
  buttonClassName,
  labelClassName,
  labelPlacement = LabelPlacement.RIGHT,
  children,
  onChange,
}: RadioProps) => (
  <div
    className={classNames(styles.RadioContainer, className, {
      [styles.RadioContainerStretched]: isStretch,
    })}>
    {labelPlacement === LabelPlacement.LEFT && (
      <label
        htmlFor={elementId}
        className={classNames(styles.RadioLabel, styles.RadioLabelLeft, labelClassName)}>
        {children}
      </label>
    )}

    <input
      id={elementId}
      data-testid={elementId}
      type="radio"
      className={classNames(styles.Radio, {
        [styles.RadioRight]: labelPlacement === LabelPlacement.LEFT,
      })}
      checked={isChecked}
      data-enabled={isChecked}
      disabled={isDisabled}
      onChange={(event) => onChange(event.target.checked, event)}
    />

    {!isLoading && (
      <button
        type="button"
        id={`radio_button_${elementId}`}
        data-testid={`radio_button_${elementId}`}
        className={classNames(styles.RadioButton, buttonClassName, {
          [styles.RadioButtonChecked]: isChecked,
        })}
        disabled={isDisabled}>
        <IconCircle
          className={classNames(styles.RadioIcon, {
            [styles.RadioIconCheckedDisabled]: isChecked && isDisabled,
            [styles.RadioIconUncheckedDisabled]: !isChecked && isDisabled,
          })}
        />
      </button>
    )}

    {isLoading && (
      <Spinner
        className={styles.RadioSpinner}
        color={Colors.COLOR_NEUTRAL_600}
      />
    )}

    {labelPlacement === LabelPlacement.RIGHT && (
      <label
        htmlFor={elementId}
        className={classNames(styles.RadioLabel, styles.RadioLabelRight, labelClassName)}>
        {children}
      </label>
    )}
  </div>
);

export default Radio;
