import { ZvukPlaylistType, ZvukProfileType, ZvukSyncStatus } from 'domain/api/graphql/generated';
import { BusinessProfileEventNameAMP } from 'application/services/AnalyticsService/ampTypes';
import {
  BusinessProfileParamNameAMP,
  BusinessProfileWithParamValue,
} from 'application/services/AnalyticsService/eventTypes/businessProfile';
import AnalyticsService from 'application/services/AnalyticsService';
import { BusinessProfileFormData } from './BusinessProfileForm/types';

const SYNC_ERROR_SUBSTRING = 'is not synced yet';

type LogProfileCreateProps = {
  formData: BusinessProfileFormData;
  eventName?: BusinessProfileEventNameAMP;
};

const useBusinessProfileStatusHelpers = () => {
  const isDisabledProfile = (profile: ZvukProfileType | ZvukPlaylistType) =>
    profile.syncStatus === ZvukSyncStatus.AwaitingSync || profile.syncStatus === ZvukSyncStatus.NeverSynced;

  const isSyncStatusError = (error: Error) => `${error}`.indexOf(SYNC_ERROR_SUBSTRING) !== -1;

  const isUnpublishedProfile = (profile: ZvukProfileType | ZvukPlaylistType) =>
    !profile.isPublished || isDisabledProfile(profile);

  const logProfileCreate = ({
    formData,
    eventName = BusinessProfileEventNameAMP.BUSINESS_PROFILE_CREATE,
  }: LogProfileCreateProps) => {
    const params = [];

    if (formData.poster) {
      params.push(BusinessProfileWithParamValue.POSTER);
    }

    if (formData.bannerLink || formData.bannerWeb || formData.bannerMobile) {
      params.push(BusinessProfileWithParamValue.BANNER);
    }

    if (formData.site) {
      params.push(BusinessProfileWithParamValue.SITE);
    }

    AnalyticsService.event(eventName, {
      [BusinessProfileParamNameAMP.PROFILE_WITH]: params,
    });
  };

  return {
    isDisabledProfile,
    isSyncStatusError,
    isUnpublishedProfile,
    logProfileCreate,
  };
};

export default useBusinessProfileStatusHelpers;
