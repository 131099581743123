import { Link } from 'application/providers/RouterProvider';
import React from 'react';
import { TEXTS } from '../texts';

export interface DisabledPointHintProps {
  pageLink: string;
  onClick: () => void;
}

const DisabledPointHint = ({ pageLink, onClick }: DisabledPointHintProps) => (
  <>
    {`${TEXTS.CHOOSE_POINTS_DISABLED_HINT_DESCRIPTION} `}
    <Link
      onClick={onClick}
      to={pageLink}>
      {TEXTS.CHOOSE_POINTS_DISABLED_HINT_LINK}
    </Link>
  </>
);

export default DisabledPointHint;
