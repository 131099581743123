import { useAdCampaignRepositoryInjector } from 'application/providers/DIContainerProvider';

const useAdCreateModalPresenter = () => {
  const { createAdCampaign, adCampaignCreateLoading, adCampaignsResult, clearAdCampaigns } =
    useAdCampaignRepositoryInjector();

  const isLoading = adCampaignCreateLoading || adCampaignsResult.loading;

  return {
    createAdCampaign,
    isLoading,
    clearAdCampaigns,
  };
};

export default useAdCreateModalPresenter;
