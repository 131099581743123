import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { TEXTS } from './texts';

const DownloadAppWarningModal: FC<ModalProps[ModalTypes.DOWNLOAD_APP_WARNING_MODAL]> = ({ onOk, close }) => (
  <ModalScreen
    title={TEXTS.MODAL_TITLE}
    onClose={close}
    hideCloseButton
    actionButtons={[
      <Button
        key="download_app_warning_modal_confirm"
        elementId="download_app_warning_modal_confirm"
        type={ButtonType.PRIMARY}
        onClick={onOk}>
        {TEXTS.SUCCESS_BUTTON}
      </Button>,
    ]}>
    <div>{TEXTS.WARNING_MESSAGE}</div>
  </ModalScreen>
);

export default DownloadAppWarningModal;
