import React from 'react';
import classNames from 'classnames';

import { TEXTS } from 'application/pages/texts';
import { DeviceTypes } from 'domain/api/graphql/generated';
import Popper, { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import IconHelpCircle from '@zvuk-b2b/react-uikit/ui/icons/HelpCircle';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import styles from './index.module.scss';
import { PointsListCheckboxGroupPointType, SelectorContentType, PointItemContentType } from './types';

const getDescription = (currentPoint: PointsListCheckboxGroupPointType) => {
  const title = currentPoint?.stream?.title ? currentPoint.stream?.title : TEXTS.MODAL_BIND_STREAM_NOT_BIND;

  return `${TEXTS.MODAL_BIND_STREAM_BIND}: ${title}`;
};

const getPlayerDescription = (currentPoint: PointsListCheckboxGroupPointType) => {
  const type = currentPoint?.device?.type;

  if (!type) {
    return TEXTS.MODAL_BIND_NO_DEVICE;
  }

  if (type === DeviceTypes.Webplayer) {
    return TEXTS.MODAL_BIND_WEB_PLAYER;
  }

  return TEXTS.MODAL_BIND_PLAYER_APPLICATION;
};

const SelectorFullContent = ({ currentItem, isLoading, className, enableItemHint, parentRef }: SelectorContentType) => {
  const { isDesktop } = useMedia();

  if (!(currentItem?.title || currentItem?.address)) {
    return <></>;
  }

  const ItemContent = (
    <div className={classNames(styles.PointItemContent, styles.PointItemFullContent, className)}>
      <strong
        data-loading={isLoading}
        className={styles.PointItemContent}>
        {!isLoading && (currentItem?.title || currentItem?.address)}
      </strong>
      <span
        data-loading={isLoading}
        className={styles.PointItemContentSecondTextType}>
        {!isLoading && getDescription(currentItem)}
      </span>
      <span
        data-loading={isLoading}
        className={styles.PointItemContentSecondTextType}>
        {!isLoading && (
          <>
            {`${TEXTS.MODAL_BIND_DEVICE_TYPE}: `}
            {getPlayerDescription(currentItem)}
          </>
        )}
      </span>
      {enableItemHint && (
        <span
          id="point_item_content_hint"
          className={styles.PointItemContentPopperWrapper}>
          <IconHelpCircle />
        </span>
      )}
    </div>
  );

  if (enableItemHint) {
    return (
      <Popper
        triggerNode={ItemContent}
        isShow
        placement={isDesktop ? HintPlacement.PLACEMENT_RIGHT_END : HintPlacement.PLACEMENT_BOTTOM}
        delayShow={400}
        delayHide={400}
        portalNode={document.body}
        containerClassName={styles.PointItemContentPopperWrapper}
        isShowingOnClick
        parentRef={parentRef}>
        {enableItemHint}
      </Popper>
    );
  }

  return ItemContent;
};

const SelectorContent = ({ currentItem, isLoading, className, enableItemHint, parentRef }: SelectorContentType) => {
  const { isDesktop } = useMedia();

  if (!(currentItem?.title || currentItem?.address)) {
    return <></>;
  }

  const ItemContent = (
    <div className={classNames(styles.PointItemContent, className)}>
      <span
        data-loading={isLoading}
        className={styles.PointItemContent}>
        {!isLoading && (currentItem?.title || currentItem?.address)}
      </span>

      {enableItemHint && (
        <span
          id="point_item_content_hint"
          className={styles.PointItemContentPopperWrapper}>
          <IconHelpCircle />
        </span>
      )}
    </div>
  );

  if (enableItemHint) {
    return (
      <Popper
        triggerNode={ItemContent}
        isShow
        parentRef={parentRef}
        placement={isDesktop ? HintPlacement.PLACEMENT_RIGHT_END : HintPlacement.PLACEMENT_BOTTOM}
        delayShow={400}
        delayHide={400}
        portalNode={document.body}
        containerClassName={styles.PointItemContentPopperWrapper}
        isShowingOnClick>
        {enableItemHint}
      </Popper>
    );
  }

  return ItemContent;
};

const PointItemContent = ({
  currentItem,
  isLoading,
  showStreamData = false,
  className,
  disabled,
  enableItemHint,
  parentRef,
}: PointItemContentType) =>
  showStreamData ? (
    <SelectorFullContent
      enableItemHint={disabled && enableItemHint}
      className={classNames(className, disabled && styles.PointItemContentDisabled)}
      currentItem={currentItem}
      isLoading={isLoading}
      parentRef={parentRef}
    />
  ) : (
    <SelectorContent
      enableItemHint={disabled && enableItemHint}
      className={classNames(className, disabled && styles.PointItemContentDisabled)}
      currentItem={currentItem}
      isLoading={isLoading}
      parentRef={parentRef}
    />
  );

export default PointItemContent;
