import React from 'react';

import { TEXTS } from '../texts';

import styles from './index.module.scss';

const POSTER_BASE_PATH = '/assets/img/business_profile/poster_hints/poster_base.png';
const AVATAR_BASE_PATH = '/assets/img/business_profile/poster_hints/avatar_base.png';

const Poster = () => (
  <div className={styles.PosterContainer}>
    <div>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_1}</div>
    <div>
      <strong>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_TEXT}</strong>
      <span>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_2}</span>
    </div>
    <div>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_3}</div>
    <div>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_4}</div>

    <div className={styles.ImagesContainer}>
      <div className={styles.PosterSizesContainer}>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_BASE}</div>
      <img
        src={POSTER_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.PosterBase}
        data-testid="business_profile_hint_poster_base"
      />
      <img
        src={AVATAR_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.AvatarBase}
        data-testid="business_profile_hint_avatar_base"
      />
    </div>

    <div className={styles.ImagesContainer}>
      <div className={styles.PosterOverlay} />
      <div className={styles.PosterSizesContainerMedium}>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_MEDIUM}</div>
      <img
        src={POSTER_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.PosterMedium}
        data-testid="business_profile_hint_poster_medium"
      />
      <img
        src={AVATAR_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.AvatarMedium}
        data-testid="business_profile_hint_avatar_medium"
      />
    </div>

    <div className={styles.ImagesContainer}>
      <div className={styles.PosterOverlaySmall} />
      <div className={styles.PosterSizesContainerSmall}>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_IOS}</div>
      <img
        src={POSTER_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.PosterSmall}
        data-testid="business_profile_hint_poster_ios"
      />
      <img
        src={AVATAR_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.AvatarSmall}
        data-testid="business_profile_hint_avatar_ios"
      />
    </div>

    <div className={styles.ImagesContainer}>
      <div className={styles.PosterOverlayExtraSmall} />
      <div className={styles.PosterSizesContainerExtraSmall}>
        {TEXTS.BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_ANDROID}
      </div>
      <img
        src={POSTER_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.PosterExtraSmall}
        data-testid="business_profile_hint_poster_android"
      />
      <img
        src={AVATAR_BASE_PATH}
        alt=""
        loading="lazy"
        className={styles.AvatarSmall}
        data-testid="business_profile_hint_avatar_android"
      />
    </div>
  </div>
);

export default Poster;
