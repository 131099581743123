class BrowserUtils {
  static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static isChildNode(childNode: HTMLElement, parentNode: HTMLElement) {
    let child = childNode as HTMLElement | null;

    while (child !== undefined && child !== null && child.tagName.toUpperCase() !== 'BODY') {
      if (child === parentNode) {
        return true;
      }
      child = child.parentElement;
    }
    return false;
  }
}

export default BrowserUtils;
