import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAccountContext } from 'application/providers/AccountProvider';
import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';

import { LicenseInfoTypeFragment } from 'domain/billing/types';

import { ModalLicenseType } from '../LicenseModal/LicensesList';

import { UseLicensesUnpaidModalControllerProps } from './types';

const useLicensesUnpaidModalController = ({
  totalLicenses,
  getSubscriptionCost,
  billingInfoRequest,
  billingInfo,
  onSubmit,
}: UseLicensesUnpaidModalControllerProps) => {
  const { currentCompany } = useAccountContext();

  const [toEnableAutorenewal, setToEnableAutorenewal] = useState<string[]>(
    billingInfo.unpaidLicenses.map((item) => item.id)
  );
  const [licensesSubscriptionCost, setLicensesSubscriptionCost] = useState<number>(0);

  const isSubmitButtonDisabled = !(toEnableAutorenewal.length > 0);

  const getModalLicenseItem = useCallback(
    (item: LicenseInfoTypeFragment): ModalLicenseType => {
      const isInEnableAutorenewal = toEnableAutorenewal.indexOf(item.id) > -1;
      const isChecked = isInEnableAutorenewal || item.autoRenewal;

      return {
        ...item,
        isChecked,
      };
    },
    [toEnableAutorenewal]
  );

  const modalLicenseItems = useMemo(() => totalLicenses.map(getModalLicenseItem), [totalLicenses, getModalLicenseItem]);

  const onChange = useCallback(
    (item: LicenseInfoTypeFragment, value: boolean) => {
      if (value) {
        const currentToEnableAutorenewal = [...toEnableAutorenewal, item.id];
        setToEnableAutorenewal(currentToEnableAutorenewal);
      } else {
        const newToEnableAutorenewal = toEnableAutorenewal.filter((id) => id !== item.id);
        setToEnableAutorenewal(newToEnableAutorenewal);
      }
    },
    [toEnableAutorenewal]
  );

  const handleSubmit = useCallback(() => {
    onSubmit([], toEnableAutorenewal);

    AnalyticsService.event(EventNameAMP.BILLING_UNPAID_LICENSES_PAY_CLICKED);
  }, [onSubmit, toEnableAutorenewal]);

  const getLicensesSubscriptionCost = async () => {
    if (toEnableAutorenewal.length > 0) {
      const cost = await getSubscriptionCost(toEnableAutorenewal);
      setLicensesSubscriptionCost(cost);
    }
  };

  useEffect(() => {
    if (toEnableAutorenewal.length > 0) {
      getLicensesSubscriptionCost();
    }
  }, [toEnableAutorenewal.length]);

  const getBillingInfo = async () => {
    const billingInfo = await billingInfoRequest(currentCompany!.id);
    setToEnableAutorenewal(billingInfo.unpaidLicenses.map((item) => item.id));
  };

  useEffect(() => {
    getBillingInfo();
  }, []);

  return {
    toEnableAutorenewal,
    isSubmitButtonDisabled,
    modalLicenseItems,
    licensesSubscriptionCost,
    onChange,
    handleSubmit,
  };
};

export default useLicensesUnpaidModalController;
