import React, { FC, useCallback, useMemo } from 'react';

import { TEXTS } from 'application/pages/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';

import {
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import styles from './index.module.scss';
import VideoStreamPointsList from '../VideoStreamPointsList';
import { usePointsTabPresenter } from '../presenters/PointsTabPresenter';
import { useVideoStreamsPointsController } from '../controllers/VideoStreamsPointsController';

const VideoStreamBindModal: FC<ModalProps[ModalTypes.VIDEO_STREAM_BIND]> = ({
  selectedPoints: initialSelectedPoints,
  isButtonLoading,
  bindVideoStream,
  openSuccessModal,
  close,
  openModal,
  isDenyCreatePoint,
}) => {
  const { allPointList, loadMore } = usePointsTabPresenter();

  const { isIpad } = useMedia();

  const { selectedPoints, onPointsChange, isButtonDisabled } = useVideoStreamsPointsController({
    initialSelectedPoints,
    points: allPointList.points || [],
  });

  const notification = useNotificationContext();

  const handleAddPointClick = useCallback(async () => {
    await close();
    const modalType = isDenyCreatePoint ? ModalTypes.DENY_OPERATION : ModalTypes.POINT_CREATE;
    openModal(modalType, {}); // onPointCreate();
  }, [openModal, close]);

  const handleSaveClick = async () => {
    close();
    try {
      await bindVideoStream(selectedPoints);
    } catch (error: any) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: error?.message ? error.message : NOTIFICATION_DEFAULT_ERROR_MESSAGE,
      });
      return;
    }
    openSuccessModal(selectedPoints);
  };

  const actionButtons = useMemo(() => {
    const result: React.ReactNode[] = [
      <Button
        key="button_bind_video_stream_modal"
        elementId="button_bind_video_stream_modal"
        type={ButtonType.PRIMARY}
        isLoading={isButtonLoading}
        onClick={handleSaveClick}
        isDisabled={isButtonDisabled}>
        {TEXTS.MODAL_BIND_BUTTON_SUBMIT_TEXT}
      </Button>,
      <Button
        key="button_create_new_point_modal"
        elementId="button_create_new_point_modal"
        type={ButtonType.SECONDARY}
        onClick={handleAddPointClick}>
        {TEXTS.MODAL_BIND_ADD_NEW_POINT_TEXT}
      </Button>,
    ];

    return result;
  }, [
    isButtonLoading,
    selectedPoints,
    isButtonDisabled,
    handleAddPointClick,
    bindVideoStream,
    openSuccessModal,
    handleSaveClick,
    close,
  ]);

  return (
    <ModalScreen
      title={TEXTS.MODAL_BIND_TITLE}
      titlePlacement="left"
      disableScroll={!isIpad}
      contentClassName={styles.VideoStreamBindModalContent}
      onClose={close}
      actionButtons={actionButtons}>
      <VideoStreamPointsList
        points={allPointList.points || []}
        isLoading={allPointList.isReloading}
        loadMore={loadMore}
        onChange={onPointsChange}
        selectedPoints={selectedPoints}
      />
    </ModalScreen>
  );
};

export default VideoStreamBindModal;
