import { useMemo, useState } from 'react';
import { DeviceTypes } from 'domain/api/graphql/generated';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import { StreamsCategoryParamValues, STREAMS_CATEGORY_PATH } from 'application/pages/StreamPage';
import { SelectPlayerFlowType } from 'application/components/common/SelectPlayerFlow/types';
import { BREAK_EDGE_VALUE, UseStreamBindSuccessModalControllerProps, onChangeStreamClickProps } from './const';
import useStreamBindSuccessModalAdapter from './useStreamBindSuccessModalAdapter';

const useStreamBindSuccessModalController = ({
  selectedPoints,
  flowType,
}: UseStreamBindSuccessModalControllerProps) => {
  const { readCurrentPoint } = useStreamBindSuccessModalAdapter();
  const currentPoint = readCurrentPoint();

  const navigate = useNavigate();
  const [isAllPointsVisible, setIsAllPointsVisible] = useState<boolean>(false);

  const showExpandButton = useMemo(
    () => selectedPoints.length > BREAK_EDGE_VALUE && !isAllPointsVisible,
    [selectedPoints, isAllPointsVisible]
  );

  const selectedPointsAddresses = useMemo(() => {
    if (showExpandButton) {
      return selectedPoints.slice(0, BREAK_EDGE_VALUE);
    }

    return selectedPoints;
  }, [showExpandButton]);

  const onChangeStream = ({ pointId, pointAddress }: onChangeStreamClickProps) => {
    navigate(
      RouterUtils.generatePath(STREAMS_CATEGORY_PATH, {
        category: StreamsCategoryParamValues.RECOMMENDED,
      }),
      {
        state: { pointsFromLocation: [{ pointId, pointAddress }] },
      }
    );
  };

  const isPointPage = flowType === SelectPlayerFlowType.CHANGE_DEVICE || flowType === SelectPlayerFlowType.CREATE_POINT;
  const pointHasStream = !!(currentPoint && currentPoint?.stream?.id);

  const isChangeStreamFlow = flowType === SelectPlayerFlowType.CHANGE_DEVICE && pointHasStream;

  const showWebPlayerButtonForStreamPage =
    selectedPoints.length === 1 &&
    flowType === SelectPlayerFlowType.BIND_STREAM &&
    selectedPoints[0].device?.type === DeviceTypes.Webplayer;

  const showWebPlayerButtonForPointPage =
    selectedPoints.length === 1 &&
    flowType !== SelectPlayerFlowType.BIND_STREAM &&
    currentPoint?.device?.type === DeviceTypes.Webplayer;

  const showWebPlayerButton = showWebPlayerButtonForStreamPage || showWebPlayerButtonForPointPage;

  const isRunStreamOnExistingPointsFlow =
    selectedPoints.length > 1 || (!!selectedPoints[0]?.stream?.id && !!selectedPoints[0]?.device?.id);

  const isSettingsFinished =
    (!!currentPoint?.device?.type && !!currentPoint.stream?.id) || isRunStreamOnExistingPointsFlow;
  const showStreamsButton = !pointHasStream && !isSettingsFinished;

  return {
    showExpandButton,
    selectedPointsAddresses,
    setIsAllPointsVisible,
    onChangeStream,
    isPointPage,
    showStreamsButton,
    isChangeStreamFlow,
    showWebPlayerButton,
    isSettingsFinished,
  };
};

export default useStreamBindSuccessModalController;
