import { useAuthPasswordSetNewMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { PasswordChangeInputType, PasswordChangeResultType, UsePasswordChangeType } from './types';

const usePasswordChange = (): UsePasswordChangeType => {
  const [mutation, mutationResult] = useAuthPasswordSetNewMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: PasswordChangeInputType) => {
    const mutationResult = await mutation({
      variables: { input },
    });

    return !!mutationResult.data?.passwordPureSetNew?.ok;
  };

  const passwordChangeData = mutationResult.data?.passwordPureSetNew;
  const hasErrors = !!passwordChangeData?.errors;
  const changeResultData: PasswordChangeResultType['data'] = { ok: passwordChangeData?.ok };

  if (hasErrors) {
    changeResultData.errors = GraphQLApiUtils.errorsArrayToObject(passwordChangeData?.errors);
  }

  const result: PasswordChangeResultType = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    data: changeResultData,
  };

  return [request, result];
};

export default usePasswordChange;
