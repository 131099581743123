/* global RequestInit */
export const DEFAULT_GET_METHOD_OPTIONS: RequestInit = {
  method: 'GET',
  mode: 'no-cors',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const DEFAULT_POST_METHOD_OPTIONS: RequestInit = {
  method: 'POST',
  mode: 'no-cors',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json',
  },
};

class RequestService {
  static async get(url: string, options: RequestInit = DEFAULT_GET_METHOD_OPTIONS) {
    const response = await fetch(url, {
      ...options,
    });

    return response;
  }

  static async post(url: string, data?: object, options: RequestInit = DEFAULT_POST_METHOD_OPTIONS) {
    const response = await fetch(url, {
      ...options,
      body: JSON.stringify(data),
    });

    return response;
  }
}

export default RequestService;
