import { isValidPhoneNumber } from 'react-phone-number-input';

const MIN_ADDR_LENGTH = 3;
const INN_REGEXP = /^(\d{10}|\d{12})$/;
const KPP_REGEXP = /^(\d{9})$/;
const BIN_REGEXP = /^(\d{12})$/;

export const INN_LENGTH_LEGAL = 10;
export const INN_LENGTH_INDIVIDUAL = 12;
export const MAX_KPP_LENGTH = 9;

class ValidationUtils {
  static validateUNP(value?: string): boolean {
    return (value?.length || 0) >= 9;
  }

  static validateIINBIN(value?: string): boolean {
    return (value?.length || 0) >= 12;
  }

  static validateINN(value?: string): boolean {
    if (!value) {
      return false;
    }

    return !!INN_REGEXP.test(value);
  }

  static validateBIN(value?: string): boolean {
    if (!value) {
      return false;
    }

    return !!BIN_REGEXP.test(value);
  }

  static validateKPP(value?: string): boolean {
    if (!value) {
      return false;
    }

    return !!KPP_REGEXP.test(value);
  }

  static validateAddress(value?: string): boolean {
    if (value && !value.replace(/\s/g, '').length) {
      return false;
    }

    return value?.length! >= MIN_ADDR_LENGTH;
  }

  static hasValueOnlySpace(value: string): boolean {
    return /^\s*$/.test(value);
  }

  static isValidPhoneNumber(value: string): boolean {
    return isValidPhoneNumber(value);
  }

  static validateDayTime = (dayTime: string, timePattern: RegExp) => {
    if (!dayTime.match(timePattern)) {
      return false;
    }

    const [hh, mm] = dayTime.split(':');
    if (+hh > 23 || +mm > 59) {
      return false;
    }

    if (+hh < 0 || +mm < 0) {
      return false;
    }

    return true;
  };
}

export default ValidationUtils;
