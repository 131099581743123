import React from 'react';

import { PRICE_PLAN_PAGE_PATH } from 'application/consts';
import PricePlanPageWrapper from 'application/pages/PricePlanPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const pricePlanRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: PRICE_PLAN_PAGE_PATH,
        element: <PricePlanPageWrapper />,
      },
    ],
  },
];
