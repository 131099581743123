import React, { FC, useLayoutEffect, useMemo } from 'react';

import { useLocation } from 'application/providers/RouterProvider';
import { useAccountContext } from 'application/providers/AccountProvider';

import { useGetNotifications } from 'domain/media/useGetNotifications';

import { GeneratedReportsContext } from './GeneratedReportsContext';

const GeneratedReportsProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const notifications = useGetNotifications();

  const value = useMemo(
    () => ({
      notifications: notifications?.data,
    }),
    [notifications]
  );

  const { pathname } = useLocation();

  const { currentUser, currentCompany, isReadyToUseLK } = useAccountContext();

  useLayoutEffect(() => {
    if (currentUser?.id && currentCompany?.id && isReadyToUseLK) {
      notifications?.refetch();
    }
  }, [pathname, currentUser, currentCompany, isReadyToUseLK]);

  return <GeneratedReportsContext.Provider value={value}>{children}</GeneratedReportsContext.Provider>;
};

export default GeneratedReportsProvider;
