import { useApolloClient } from 'application/providers/DataProvider';

import useBusinessProfileCreate from 'domain/businessProfiles/useBusinessProfileCreate';
import useBusinessProfileUpdate from 'domain/businessProfiles/useBusinessProfileUpdate';

import { BusinessProfileType, BusinessPlaylistType } from 'domain/api/fragments.graphql';

import {
  ZvukProfileCreateMutationInput,
  ZvukProfileHideMutationInput,
  ZvukProfileRemoveMutationInput,
  BusinessProfileTypeFragment,
  ZvukProfileUpdateMutationInput,
  ZvukPlaylistCreateFromStreamMutationInput,
  ZvukPlaylistUpdateMutationInput,
  ZvukPlaylistHideMutationInput,
  ZvukPlaylistRemoveMutationInput,
  ZvukPlaylistPublishMutationInput,
  ZvukPlaylistInfoMutationInput,
  ZvukPlaylistCreateFromZvukMutationInput,
  ZvukProfilePublishMutationInput,
  ZvukProfileType,
} from 'domain/api/graphql/generated';

import {
  BusinessProfileCompanySavedStreamsType,
  BusinessProfileCreateRequestResult,
  BusinessProfileMutationRequestResult,
  BusinessProfilePlaylistMutationRequestResult,
  BusinessProfileZvukPlaylistInfoMutationRequestResult,
} from 'domain/businessProfiles/types';

import useBusinessProfiles from 'domain/businessProfiles/useBusinessProfiles';
import useBusinessProfileHide from 'domain/businessProfiles/useBusinessProfileHide';
import useBusinessProfileRemove from 'domain/businessProfiles/useBusinessProfileRemove';
import useBusinessProfileCompanySavedStreams from 'domain/businessProfiles/useBusinessProfileCompanySavedStreams';
import useBusinessProfilePlaylistCreateFromStream from 'domain/businessProfiles/useBusinessProfilePlaylistCreateFromStream';
import useBusinessProfilePlaylistUpdate from 'domain/businessProfiles/useBusinessProfilePlaylistUpdate';
import useBusinessProfilePlaylistHide from 'domain/businessProfiles/useBusinessProfilePlaylistHide';
import useBusinessProfilePlaylistRemove from 'domain/businessProfiles/useBusinessProfilePlaylistRemove';
import useBusinessProfilePlaylistPublish from 'domain/businessProfiles/useBusinessProfilePlaylistPublish';
import useBusinessProfileZvulPlaylistInfo from 'domain/businessProfiles/useBusinessProfileZvukPlaylistInfo';
import useBusinessProfilePlaylistCreateFromZvuk from 'domain/businessProfiles/useBusinessProfilePlaylistCreateFromZvuk';

import useBusinessProfilePublish from 'domain/businessProfiles/useBusinessProfilePublish';
import {
  BusinessProfileSelectedPlaylistType,
  CurrentPlaylistCachedData,
  UseBusinessProfileRepositoryType,
} from './types';
import { BUSINESS_PROFILE_SET_SELECTED_PLAYLIST_QUERY } from './consts';

const useBusinessProfileRepository = (): UseBusinessProfileRepositoryType => {
  const client = useApolloClient();

  const {
    request: requestBusinessProfiles,
    isLoading: businessProfilesLoading,
    error: businessProfilesError,
    refetchBusinessProfiles: refetchBusinessProfileList,
  } = useBusinessProfiles();
  const [businessProfileCreateRequest, businessProfileCreateResult] = useBusinessProfileCreate();
  const [businessProfileUpdateRequest, businessProfileUpdateResult] = useBusinessProfileUpdate();
  const [businessProfileHideRequest, businessProfileHideResult] = useBusinessProfileHide();
  const [businessProfilePublishRequest, businessProfilePublishResult] = useBusinessProfilePublish();
  const [businessProfileRemoveRequest, businessProfileRemoveResult] = useBusinessProfileRemove();

  const companySavedStreams = useBusinessProfileCompanySavedStreams();

  const [businessProfilePlaylistCreateFromStreamRequest, businessProfilePlaylistCreateFromStreamResult] =
    useBusinessProfilePlaylistCreateFromStream();
  const [businessProfilePlaylistUpdateRequest, businessProfilePlaylistUpdateResult] =
    useBusinessProfilePlaylistUpdate();
  const [businessProfilePlaylistHideRequest, businessProfilePlaylistHideResult] = useBusinessProfilePlaylistHide();
  const [businessProfilePlaylistRemoveRequest, businessProfilePlaylistRemoveResult] =
    useBusinessProfilePlaylistRemove();
  const [businessProfilePlaylistPublishRequest, businessProfilePlaylistPublishResult] =
    useBusinessProfilePlaylistPublish();
  const [businessProfileZvukPlaylistInfoRequest, businessProfileZvukPlaylistInfoResult] =
    useBusinessProfileZvulPlaylistInfo();
  const [businessProfilePlaylistCreateFromZvukRequest, businessProfilePlaylistCreateFromZvukResult] =
    useBusinessProfilePlaylistCreateFromZvuk();

  const getBusinessProfiles = async (companyId: string) => {
    const result = await requestBusinessProfiles(companyId);

    return result;
  };

  const refetchBusinessProfiles = async (companyId: string) => {
    const result = await refetchBusinessProfileList({ companyId });

    return (result.data.zvukProfileList || []) as ZvukProfileType[];
  };

  const getBusinessProfile = (id: string): BusinessProfileTypeFragment | null => {
    const businessProfile = client.readFragment({
      id: `ZvukProfileType:${id}`,
      fragment: BusinessProfileType,
      fragmentName: 'BusinessProfileType',
    });

    return businessProfile;
  };

  const createBusinessProfile = async (
    input: ZvukProfileCreateMutationInput
  ): Promise<BusinessProfileCreateRequestResult> => {
    const result = await businessProfileCreateRequest(input);
    return result;
  };

  const updateBusinessProfile = async (
    input: ZvukProfileUpdateMutationInput
  ): Promise<BusinessProfileMutationRequestResult> => {
    const result = await businessProfileUpdateRequest(input);
    return result;
  };

  const hideBusinessProfile = async (
    input: ZvukProfileHideMutationInput
  ): Promise<BusinessProfileMutationRequestResult> => {
    const result = await businessProfileHideRequest(input);
    return result;
  };

  const publishBusinessProfile = async (
    input: ZvukProfilePublishMutationInput
  ): Promise<BusinessProfileMutationRequestResult> => {
    const result = await businessProfilePublishRequest(input);
    return result;
  };

  const removeBusinessProfile = async (
    input: ZvukProfileRemoveMutationInput
  ): Promise<BusinessProfileMutationRequestResult> => {
    const result = await businessProfileRemoveRequest(input);
    return result;
  };

  const getSavedStreams = async (companyIds: string[], query?: string) => {
    const result = await companySavedStreams.request(companyIds, query);

    return result as BusinessProfileCompanySavedStreamsType[];
  };

  const getBusinessProfilePlaylist = (id: string) => {
    const businessProfilePlaylistData = client.readQuery<CurrentPlaylistCachedData>({
      query: BUSINESS_PROFILE_SET_SELECTED_PLAYLIST_QUERY,
    });

    if (
      businessProfilePlaylistData &&
      Object.keys(businessProfilePlaylistData).length !== 0 &&
      businessProfilePlaylistData.playlist.id === id
    ) {
      return businessProfilePlaylistData.playlist;
    }

    const businessProfilePlaylist = client.readFragment({
      id: `ZvukPlaylistType:${id}`,
      fragment: BusinessPlaylistType,
      fragmentName: 'BusinessPlaylistType',
    });

    return businessProfilePlaylist;
  };

  const setSelectedBusinessProfilePlaylist = (playlist: BusinessProfileSelectedPlaylistType) => {
    const businessProfilePlaylist = client.writeQuery({
      query: BUSINESS_PROFILE_SET_SELECTED_PLAYLIST_QUERY,
      data: {
        playlist,
      },
    });

    return !!businessProfilePlaylist;
  };

  const removeSelectedBusinessProfilePlaylist = (playlistId: string) => {
    const normalizedId = client.cache.identify({ id: playlistId, __typename: 'ZvukPlaylistType' });
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  };

  const createPlaylistFromStream = async (input: ZvukPlaylistCreateFromStreamMutationInput) => {
    const result = await businessProfilePlaylistCreateFromStreamRequest(input);

    if (result.isOk && result.playlist) {
      client.writeQuery({
        query: BUSINESS_PROFILE_SET_SELECTED_PLAYLIST_QUERY,
        data: {
          playlist: result.playlist,
        },
      });
    }

    return result;
  };

  const updatePlaylist = async (input: ZvukPlaylistUpdateMutationInput) => {
    const result = await businessProfilePlaylistUpdateRequest(input);

    return result;
  };

  const hidePlaylist = async (
    input: ZvukPlaylistHideMutationInput
  ): Promise<BusinessProfilePlaylistMutationRequestResult> => {
    const result = await businessProfilePlaylistHideRequest(input);
    return result;
  };

  const removePlaylist = async (
    input: ZvukPlaylistRemoveMutationInput
  ): Promise<BusinessProfilePlaylistMutationRequestResult> => {
    const result = await businessProfilePlaylistRemoveRequest(input);
    return result;
  };

  const publishPlaylist = async (
    input: ZvukPlaylistPublishMutationInput
  ): Promise<BusinessProfilePlaylistMutationRequestResult> => {
    const result = await businessProfilePlaylistPublishRequest(input);
    return result;
  };

  const getZvukPlaylistInfo = async (
    input: ZvukPlaylistInfoMutationInput
  ): Promise<BusinessProfileZvukPlaylistInfoMutationRequestResult> => {
    const result = await businessProfileZvukPlaylistInfoRequest(input);
    return result;
  };

  const createPlaylistFromZvuk = async (input: ZvukPlaylistCreateFromZvukMutationInput) => {
    const result = await businessProfilePlaylistCreateFromZvukRequest(input);

    if (result.isOk && result.playlist) {
      client.writeQuery({
        query: BUSINESS_PROFILE_SET_SELECTED_PLAYLIST_QUERY,
        data: {
          playlist: result.playlist,
        },
      });
    }

    return result;
  };

  return {
    getBusinessProfiles,
    businessProfilesLoading,
    businessProfilesError,
    refetchBusinessProfiles,

    getBusinessProfile,
    createBusinessProfile,
    businessProfileCreateLoading: businessProfileCreateResult.loading,
    businessProfileCreateError: businessProfileCreateResult.error,
    businessProfileCreateData: businessProfileCreateResult.data,

    updateBusinessProfile,
    businessProfileUpdateLoading: businessProfileUpdateResult.loading,
    businessProfileUpdateError: businessProfileUpdateResult.error,
    businessProfileUpdateData: businessProfileUpdateResult.data,

    hideBusinessProfile,
    businessProfileHideLoading: businessProfileHideResult.loading,
    businessProfileHideError: businessProfileHideResult.error,

    publishBusinessProfile,
    businessProfilePublishLoading: businessProfilePublishResult.loading,
    businessProfilePublishError: businessProfilePublishResult.error,

    removeBusinessProfile,
    businessProfileRemoveLoading: businessProfileRemoveResult.loading,
    businessProfileRemoveError: businessProfileRemoveResult.error,

    getSavedStreams,
    savedStreams: companySavedStreams.savedStreams,
    isSavedStreamsLoading: companySavedStreams.isLoading,
    isSavedStreamsCalled: companySavedStreams.called,

    getBusinessProfilePlaylist,
    setSelectedBusinessProfilePlaylist,
    removeSelectedBusinessProfilePlaylist,

    createPlaylistFromStream,
    createPlaylistFromStreamLoading: businessProfilePlaylistCreateFromStreamResult.loading,
    createPlaylistFromStreamError: businessProfilePlaylistCreateFromStreamResult.error,
    createPlaylistFromStreamData: businessProfilePlaylistCreateFromStreamResult.data,

    updatePlaylist,
    updatePlaylistLoading: businessProfilePlaylistUpdateResult.loading,
    updatePlaylistError: businessProfilePlaylistUpdateResult.error,
    updatePlaylistData: businessProfilePlaylistUpdateResult.data,

    hidePlaylist,
    hidePlaylistLoading: businessProfilePlaylistHideResult.loading,
    hidePlaylistError: businessProfilePlaylistHideResult.error,

    removePlaylist,
    removePlaylistLoading: businessProfilePlaylistRemoveResult.loading,
    removePlaylistError: businessProfilePlaylistRemoveResult.error,

    publishPlaylist,
    publishPlaylistLoading: businessProfilePlaylistPublishResult.loading,
    publishPlaylistError: businessProfilePlaylistPublishResult.error,

    getZvukPlaylistInfo,
    getZvukPlaylistInfoLoading: businessProfileZvukPlaylistInfoResult.loading,
    getZvukPlaylistInfoError: businessProfileZvukPlaylistInfoResult.error,
    getZvukPlaylistInfoData: businessProfileZvukPlaylistInfoResult.data,

    createPlaylistFromZvuk,
    createPlaylistFromZvukLoading: businessProfilePlaylistCreateFromZvukResult.loading,
    createPlaylistFromZvukError: businessProfilePlaylistCreateFromZvukResult.error,
    createPlaylistFromZvukData: businessProfilePlaylistCreateFromZvukResult.data,
  };
};

export default useBusinessProfileRepository;
