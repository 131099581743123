import { AdCampaignStatuses, AdCampaignTypeEnum } from 'domain/api/graphql/generated';
import { Colors } from '@zvuk-b2b/react-uikit/ui/types';

import { TEXTS } from '../texts';

export const AD_STATUSES_LABELS = {
  all: TEXTS.TAB_ALL_TITLE,
  [AdCampaignStatuses.Active]: TEXTS.TAB_ACTIVE_TITLE,
  [AdCampaignStatuses.Passed]: TEXTS.TAB_PASSED_TITLE,
  [AdCampaignStatuses.Planned]: TEXTS.TAB_PLANNED_TITLE,
  [AdCampaignStatuses.Stopped]: TEXTS.TAB_STOPPED_TITLE,
};

export const AD_STATUSES_COLORS = {
  all: undefined,
  [AdCampaignStatuses.Active]: Colors.COLOR_SUCCESS_500,
  [AdCampaignStatuses.Passed]: Colors.COLOR_NEUTRAL_500,
  [AdCampaignStatuses.Planned]: Colors.COLOR_WARNING_500,
  [AdCampaignStatuses.Stopped]: Colors.COLOR_DANGER_500,
};

export const ALL_ALIAS = 'all';

export const DEFAULT_STATUSES_INFO = {
  [AdCampaignStatuses.Active]: 0,
  [AdCampaignStatuses.Planned]: 0,
  [AdCampaignStatuses.Passed]: 0,
  [AdCampaignStatuses.Stopped]: 0,
};

export const DEFAULT_AD_CAMPAIGN_STATUSES = [
  AdCampaignStatuses.Active,
  AdCampaignStatuses.Passed,
  AdCampaignStatuses.Planned,
  AdCampaignStatuses.Stopped,
];

export const DEFAULT_AD_CAMPAIGN_TYPES = [
  AdCampaignTypeEnum.Interval,
  AdCampaignTypeEnum.Period,
  AdCampaignTypeEnum.ExactTime,
  AdCampaignTypeEnum.Instant,
  AdCampaignTypeEnum.Groups,
];
