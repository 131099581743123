import React from 'react';

import { REDIRECT_PAGE_PATH } from 'application/consts';
import RedirectPage from 'application/pages/RedirectPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const redirectRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: REDIRECT_PAGE_PATH,
        element: <RedirectPage />,
      },
    ],
  },
];
