import { useBillingStartLinkCardMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useBindCard = () => {
  const [mutation, mutationResult] = useBillingStartLinkCardMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (uriForReturn: string, companyId: string) => {
    try {
      const mutationResult = await mutation({
        variables: {
          input: { uriForReturn, companyId },
        },
      });
      return mutationResult.data?.startLinkCard?.confirmLink;
    } catch (e) {
      return undefined;
    }
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
  };

  return {
    request,
    result,
  };
};

export default useBindCard;
