import { KEYS, UseBillingRepositoryType, useInjection } from 'application/providers/DIContainerProvider';

import { SubscriptionStatus } from 'domain/billing/types';
import { useAccountContext } from 'application/providers/AccountProvider';

const useSubscriptionCostPresenter = () => {
  const useBillingRepository = useInjection<UseBillingRepositoryType>(KEYS.BILLING_REPOSITORY);

  const {
    watchBillingInfo,
    subscriptionCostResult,
    licenseCostLoading,
    resumeSubscriptionCost: resumeSubscriptionCostRequest,
    extendSubscriptionCost: extendSubscriptionCostRequest,
    getSubscriptionCostByPricePlanPeriod,
  } = useBillingRepository();

  const { currentCompany } = useAccountContext();

  const billingInfo = watchBillingInfo(currentCompany!.id);

  const subscriptionCostByPricePlanPeriod = subscriptionCostResult.data.subscriptionCostByPricePlanPeriod || 0;

  const resumeSubscriptionCost = async (count: number) => {
    const costResult = await resumeSubscriptionCostRequest(currentCompany!.id, count);

    const resumeCost = costResult?.costWithDiscount || costResult?.cost || undefined;

    return billingInfo.status !== SubscriptionStatus.TrialSubscription ? resumeCost || 0 : 0;
  };

  const extendSubscriptionCost = async (count: number) => {
    const costResult = await extendSubscriptionCostRequest(currentCompany!.id, count);

    return costResult || 0;
  };

  const getSubscriptionCost = async (licensesIds: string[]) => {
    const ids = licensesIds.filter((id) => billingInfo.unpaidLicenses.find((license) => license.id === id));

    const cost = billingInfo.hasInactiveLicenses
      ? await resumeSubscriptionCost(ids.length)
      : await extendSubscriptionCost(ids.length);

    return cost;
  };

  return {
    subscriptionCostByPricePlanPeriod,
    licenseCostLoading,
    extendSubscriptionCost,
    resumeSubscriptionCost,
    getSubscriptionCost,
    getSubscriptionCostByPricePlanPeriod,
  };
};

export default useSubscriptionCostPresenter;
