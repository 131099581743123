import { ReadFieldFunction, Reference } from 'application/providers/DataProvider';

import { LicensePaymentState } from 'domain/billing/types';
import { InvoicePureType, InvoiceState, InvoiceType } from 'domain/api/graphql/generated';
import { InvoiceShortType } from './types';

class LicensesUtils {
  static hasWaitPaymentLicense(licenses: Reference[], readField: ReadFieldFunction): boolean {
    return !!licenses?.find(
      (license) => readField<LicensePaymentState>('paymentState', license) === LicensePaymentState.WaitPayment
    );
  }

  static hasGraceLicense(licenses: Reference[], readField: ReadFieldFunction): boolean {
    return !!licenses?.find(
      (license) => readField<LicensePaymentState>('paymentState', license) === LicensePaymentState.Grace
    );
  }

  static getLastPaidPeriodicInvoice<T extends InvoiceShortType = InvoicePureType>(invoices: T[]): T | undefined {
    const sortedArray = [...invoices]; // it should be new array because sort have tried modify old array

    return sortedArray
      .sort((a, b) => Number(b.id) - Number(a.id))
      .find((invoice) => invoice.type === InvoiceType.Periodic && invoice.status === InvoiceState.Paid);
  }
}

export default LicensesUtils;
