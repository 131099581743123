import { SafariWindow, TextWidthType } from './types';

class BrowserUtils {
  static isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

  static isHuawei = /(huawei)/i.test(navigator.userAgent);

  static isAndroid = /(Android)/g.test(navigator.userAgent);

  static isIpad =
    /(iPad)/g.test(navigator.userAgent) || (/MacIntel/g.test(navigator.platform) && navigator.maxTouchPoints > 2);

  static isSafariDesktop = (window as unknown as SafariWindow).safari !== undefined;

  static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static isChildNode(childNode: HTMLElement | null, parentNode: HTMLElement) {
    let child = childNode as HTMLElement | null;

    while (child !== undefined && child !== null && child.tagName.toUpperCase() !== 'BODY') {
      if (child === parentNode) {
        return true;
      }
      child = child.parentElement;
    }
    return false;
  }

  static getTextWidth(inputText: string, font: string = '14px SBSansText'): TextWidthType {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      context.font = font;
      const width = context.measureText(inputText).width;
      const formattedWidth = `${Math.ceil(width)}px`;

      return {
        width,
        formattedWidth,
      };
    }

    return {
      width: inputText.length,
      formattedWidth: `${inputText.length}px`,
    };
  }
}

export default BrowserUtils;
