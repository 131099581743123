export const VALIDATION_TEXTS = {
  REQUIRED: 'Это поле является обязательным',
  MAX_LENGTH: (length: number) => `Максимальное число символов поля - ${length}`,
  MIN_LENGTH: (length: number) => `Минимальное число символов поля - ${length}`,
  MAX: (length: number) => `Максимальное значение для поля - ${length},`,
  MIN: (length: number) => `Минимальное значение для поля - ${length},`,
  PATTERN: 'Ошибка валидации',
  NO_SPACE: 'Значение из одних пробелов не является валидным',
  ONLY_DIGITS: 'Поле может содержать только цифры',
  LENGTH: (count: number) => `В поле должно быть ${count} символов`,
};
