import React from 'react';

export type YandexCaptchaProps = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCaptchaStatus?: React.Dispatch<React.SetStateAction<CaptchaStatus>>;
  successCallback?: (token?: string) => void;
  yandexErrorCallback?: () => void;
  captchaKey: number;
  resetCaptcha: () => void;
};

export enum CaptchaStatus {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  SUCCESS = 'success',
  TOKEN_EXPIRED = 'token-expired',
  NETWORK_ERROR = 'network-error',
  JS_ERROR = 'javascript-error',
}

export type UseYandexCaptchaControllerProps = {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCaptchaStatus?: React.Dispatch<React.SetStateAction<CaptchaStatus>>;
  successCallback?: (token?: string) => void;
  yandexErrorCallback?: () => void;
  setCaptchaToken: (token: string | null) => void;
  resetCaptcha: () => void;
};
