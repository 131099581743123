import React, { useEffect, useMemo, useRef } from 'react';

import { useInjection, KEYS, UseAdCampaignRepositoryType } from 'application/providers/DIContainerProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import { DEFAULT_PAGINATION } from 'application/pages/constants';

import {
  AdCampaignsStatusesPureType,
  AdCampaignShortTypeFragment,
  AdCampaignQueryFilter,
  AdCampaignQueryPagination,
  AdCampaignQuerySort,
  AdCampaignPureType,
  PromoAdInput,
  AdCampaignPureCreateMutationInput,
} from 'domain/api/graphql/generated';

import { AdCampaignModelContextType } from './types';
import { ALL_AD_CAMPAIGN_STATUSES_COUNT, DEFAULT_FILTERS, DEFAULT_PAGINATION_INFO } from './consts';

const AdCampaignModelContext = React.createContext<AdCampaignModelContextType>({} as AdCampaignModelContextType);

export const useAdCampaignModelContext = () => React.useContext(AdCampaignModelContext);

const AdCampaignModelProvider: React.FC<{ children?: React.ReactNode; pageSize?: number }> = ({
  children,
  pageSize = DEFAULT_PAGINATION.pageSize,
}) => {
  const { companyIds } = useAccountContext();

  const useAdCampaignRepository = useInjection<UseAdCampaignRepositoryType>(KEYS.AD_CAMPAIGN_REPOSITORY);
  const {
    adCampaignsResult,
    adCampaign,
    promoUpload,
    adCampaignLoading,
    adCampaignCreateLoading,
    adCampaignCreateError,
    adCampaignRemoveLoading,
    adCampaignRemoveError,
    adCampaignUpdateLoading,
    adCampaignUpdateError,
    adCampaignCreateReportLoading,
    adCampaignCreateReportErrors,
    adCampaignPromoUpdateLoading,
    adCampaignPromoUpdateError,
    adCampaignInstantLaunchLoading,
    adCampaignInstantLaunchError,
    getAdCampaigns,
    readAdCampaigns,
    refetchAdCampaigns: adCampaignsRefetch,
    getMoreAdCampaigns,
    getAdCampaign,
    createAdCampaign: createAdCampaignRepository,
    removeAdCampaign: removeAdCampaignRepository,
    updateAdCampaign: updateAdCampaignRepository,
    enableAdCampaign: enableAdCampaignRepository,
    adCampaignPromoUpdate: updatePromo,
    adCampaignCreateReport: createReport,
    adCampaignSetTags: setCompanyTags,
    adCampaignInstantLaunch: adCampaignInstantLaunchRepository,
    adCampaignInstantLaunchAbort,
  } = useAdCampaignRepository();

  const filtersRef = useRef<AdCampaignQueryFilter>({
    ...DEFAULT_FILTERS,
    companyIds,
  });
  const paginationRef = useRef<AdCampaignQueryPagination>({
    page: DEFAULT_PAGINATION_INFO.page,
    pageSize: DEFAULT_PAGINATION_INFO.pageSize,
  });
  const sortRef = useRef<AdCampaignQuerySort>(AdCampaignQuerySort.IdDesc);

  const cachedAdCampaigns = readAdCampaigns({
    filters: filtersRef.current,
    pagination: paginationRef.current,
    sort: sortRef.current,
  });

  const resetFilters = () => {
    filtersRef.current = {
      ...DEFAULT_FILTERS,
      companyIds,
    };

    paginationRef.current = {
      page: DEFAULT_PAGINATION_INFO.page,
      pageSize: DEFAULT_PAGINATION_INFO.pageSize,
    };

    sortRef.current = AdCampaignQuerySort.IdDesc;
  };

  useEffect(() => () => resetFilters(), []);

  const requestAdCampaigns = async (filters: AdCampaignQueryFilter = DEFAULT_FILTERS) => {
    filtersRef.current = {
      ...filtersRef.current,
      ...filters,
      companyIds,
    };

    paginationRef.current.page = 0;

    await getAdCampaigns({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });
  };

  const refetchAdCampaigns = async (filters: AdCampaignQueryFilter = DEFAULT_FILTERS) => {
    filtersRef.current = {
      ...filtersRef.current,
      ...filters,
      companyIds,
    };

    paginationRef.current.page = 0;

    await adCampaignsRefetch({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });
  };

  const readAdCampaignsWithDefaultFilters = (
    filters: AdCampaignQueryFilter = DEFAULT_FILTERS,
    pagination: AdCampaignQueryPagination = {
      page: DEFAULT_PAGINATION.page,
      pageSize: pageSize || DEFAULT_PAGINATION.pageSize,
    }
  ) => {
    filtersRef.current = {
      ...filtersRef.current,
      ...filters,
      companyIds,
    };

    paginationRef.current = {
      ...paginationRef.current,
      ...pagination,
    };

    const result = readAdCampaigns({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });

    return result;
  };

  const loadMoreAdCampaigns = async () => {
    const page = (adCampaignsResult.data.paginationInfo?.page || 0) + 1;

    if (page <= paginationRef.current.page) return;

    paginationRef.current.page = page;

    await getMoreAdCampaigns({
      filters: filtersRef.current,
      pagination: paginationRef.current,
      sort: sortRef.current,
    });
  };

  const requestAdCampaign = async (id: string) => {
    await getAdCampaign(id);
  };

  const createAdCampaign = async (
    name: string,
    promoIds: string[],
    companyId: string,
    overrideValues: Partial<AdCampaignPureCreateMutationInput> = {}
  ): Promise<AdCampaignPureType> => {
    const result = await createAdCampaignRepository(name, promoIds, companyId, overrideValues);

    if (result) {
      resetFilters();
      await requestAdCampaigns();
    }

    return result;
  };

  const updateAdCampaign = async (campaign: AdCampaignPureType) => {
    const isOk = await updateAdCampaignRepository(campaign);

    if (isOk) {
      resetFilters();
      await requestAdCampaigns();
    }

    return isOk;
  };

  const removeAdCampaign = async (id: string): Promise<boolean> => {
    const isOk = await removeAdCampaignRepository(id);

    if (isOk) {
      resetFilters();
    }

    return isOk;
  };

  const enableAdCampaign = async (id: string, enable: boolean) => {
    const isOk = await enableAdCampaignRepository(id, enable);

    if (isOk) {
      resetFilters();
      await requestAdCampaigns();
    }

    return isOk;
  };

  const adCampaignSetCompanyTags = async (adCampaignId: string, companyTagIds: string[]) => {
    const isOk = await setCompanyTags(adCampaignId, companyTagIds);

    if (isOk) {
      resetFilters();
      await requestAdCampaigns();
      await requestAdCampaign(adCampaignId);
    }

    return isOk;
  };

  const adCampaignUpdatePromo = async (id: string, adPromos: PromoAdInput[]) => {
    const isOk = await updatePromo(id, adPromos);
    return isOk;
  };

  const adCampaignCreateReport = async (
    adCampaignIds: string[],
    periodStart: string,
    periodEnd: string,
    recipientEmail?: string
  ) => {
    const isOk = await createReport(adCampaignIds, periodStart, periodEnd, recipientEmail);
    return isOk;
  };

  const adCampaignInstantLaunch = async (adCampaign: AdCampaignShortTypeFragment, pointId: string) => {
    const isOk = await adCampaignInstantLaunchRepository(adCampaign, pointId);
    return isOk;
  };

  const preparedAdCampaigns = useMemo(
    () =>
      cachedAdCampaigns?.result?.map((item) => ({
        id: item.id,
        enable: item.enable,
        title: item.title,
        points: item.points,
        promos: item.promos,
        adPromos: item.adPromos,
        timingSettings: item.timingSettings,
        tags: item.tags,
        type: item.type,
        durationPromos: item.durationPromos,
      })) || [],
    [cachedAdCampaigns]
  );

  const preparedStatusesInfo = useMemo(() => {
    let statusesInfo: AdCampaignsStatusesPureType = {};

    const { data } = adCampaignsResult;

    if (data.statusesInfo) {
      statusesInfo = { ...data.statusesInfo };
      delete statusesInfo.__typename;
    }

    return statusesInfo;
  }, [adCampaignsResult.data.statusesInfo]);

  const adCampaignModel: AdCampaignModelContextType = {
    adCampaigns: preparedAdCampaigns,
    statusesInfo: preparedStatusesInfo,
    paginationInfo: adCampaignsResult.data.paginationInfo || DEFAULT_PAGINATION_INFO,
    filters: filtersRef.current,
    adCampaignsLoading: adCampaignsResult.loading,
    adCampaignsCalled: adCampaignsResult.called,
    adCampaignsNetworkStatus: adCampaignsResult.networkStatus,
    allAdCampaignStatusesCount: ALL_AD_CAMPAIGN_STATUSES_COUNT,
    requestAdCampaigns,
    readAdCampaigns: readAdCampaignsWithDefaultFilters,
    refetchAdCampaigns,
    loadMoreAdCampaigns,
    resetFilters,
    adCampaign,
    promoUpload,
    adCampaignLoading,
    adCampaignCreateLoading,
    adCampaignCreateError,
    adCampaignRemoveLoading,
    adCampaignRemoveError,
    adCampaignUpdateLoading,
    adCampaignUpdateError,
    adCampaignCreateReportLoading,
    adCampaignCreateReportErrors,
    adCampaignPromoUpdateLoading,
    adCampaignPromoUpdateError,
    adCampaignInstantLaunchLoading,
    adCampaignInstantLaunchError,
    requestAdCampaign,
    createAdCampaign,
    updateAdCampaign,
    removeAdCampaign,
    enableAdCampaign,
    adCampaignSetCompanyTags,
    adCampaignUpdatePromo,
    adCampaignCreateReport,
    adCampaignInstantLaunch,
    adCampaignInstantLaunchAbort,
  };

  return <AdCampaignModelContext.Provider value={adCampaignModel}>{children}</AdCampaignModelContext.Provider>;
};

export default AdCampaignModelProvider;
