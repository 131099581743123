import { PointQuerySort, PointListQuery, usePointListLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { useState } from 'react';

const MAX_AVAILABLE_COUNT = 4000;
const PAGE_SIZE = 2000;

type UseAllPointListProps = {
  companyIds: string[];
  loadAllItems?: boolean;
  playingStatuses: string[];
  hasVideoSupport?: boolean;
};

const useAllPointList = ({
  companyIds,
  loadAllItems = true,
  playingStatuses,
  hasVideoSupport,
}: UseAllPointListProps) => {
  const [isReloading, setReloading] = useState(false);

  const defaultFilters = {
    query: '',
    companyIds,
    playingStatuses,
    hasVideoSupport,
  };

  const cacheAndSortSettings = {
    sort: PointQuerySort.IdDesc,
    fetchPolicy: FetchPolicyKeys.CACHE_FIRST,
    nextFetchPolicy: FetchPolicyKeys.CACHE_FIRST,
  };

  const onCompleted = (data: PointListQuery) => {
    const { page, totalCount } = data.pointsPaginationQuery.paginationInfo;
    const points = data.pointsPaginationQuery.result || [];

    if (totalCount > points.length && points.length <= MAX_AVAILABLE_COUNT && loadAllItems) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchMore(getFetchMoreQuery(page));
    } else {
      setReloading(false);
    }
  };

  const getFetchMoreQuery = (page: number) => ({
    variables: {
      filters: defaultFilters,
      pagination: { page: page + 1, pageSize: PAGE_SIZE },
      ...cacheAndSortSettings,
    },
    updateQuery: (prev: PointListQuery, { fetchMoreResult }: any) => {
      const data = {
        ...prev,
        pointsPaginationQuery: {
          ...prev?.pointsPaginationQuery,
          ...fetchMoreResult?.pointsPaginationQuery,
          result: [
            ...(prev.pointsPaginationQuery.result || []),
            ...(fetchMoreResult?.pointsPaginationQuery.result || []),
          ],
        },
      };

      onCompleted(data);
      return data;
    },
  });

  const [query, { data, loading, fetchMore }] = usePointListLazyQuery({
    variables: {
      filters: defaultFilters,
      pagination: { page: 0, pageSize: PAGE_SIZE },
      ...cacheAndSortSettings,
    },
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    onCompleted,
  });

  const request = () => {
    setReloading(true);
    return query({
      variables: {
        filters: defaultFilters,
        pagination: { page: 0, pageSize: PAGE_SIZE },
        ...cacheAndSortSettings,
      },
    });
  };

  const fetchMorePoints = (page: number) => {
    fetchMore(getFetchMoreQuery(page));
  };

  return {
    isReloading,
    loading,
    fetchMore: fetchMorePoints,
    paginationInfo: data?.pointsPaginationQuery.paginationInfo,
    points: data?.pointsPaginationQuery.result,
    request,
  };
};

export default useAllPointList;
