import React from 'react';
import styles from '../index.module.scss';

const VideoMediafilesPreviewItemSkeleton = () => (
  <div className={styles.VideoPreviewItemSkeleton}>
    <div
      id="poster"
      className={styles.VideoPreviewItemSkeletonPoster}
    />
    <div className={styles.VideoPreviewItemSkeletonTextWrapper}>
      <div className={styles.VideoPreviewItemSkeletonTextWrapperTitleSkeleton} />
      <div className={styles.VideoPreviewItemSkeletonTextWrapperDescriptionSkeleton} />
    </div>
  </div>
);

export default VideoMediafilesPreviewItemSkeleton;
