import { useAuthContext } from 'application/providers/AuthProvider';
import useCurrentUser from 'domain/auth/useCurrentUser';
import { useEffect } from 'react';

import AuthProviderUtils from 'application/providers/AuthProvider/utils';

const useUserDataChecker = (): boolean => {
  const authContext = useAuthContext();
  const [userRequest, userResult] = useCurrentUser();

  useEffect(() => {
    if (authContext.authToken) {
      AuthProviderUtils.setAuthTokenUpdateTimeout(userRequest);
    }
  }, [authContext.authToken]);

  useEffect(() => {
    if (userResult.data.user) {
      authContext.setAuthData({ userData: userResult.data.user, authToken: authContext.authToken });
    }
  }, [userResult.data.user]);

  const isReady = !!authContext.userData || !authContext.authToken;
  return isReady;
};

export default useUserDataChecker;
