import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { useMenuItemsContext } from 'application/providers/MenuItemsProvider';
import { MenuItemTarget } from 'application/providers/MenuItemsProvider/types';
import Logo, { LogoMode } from 'application/components/Logo';
import useDisableBodyScroll from '@zvuk-b2b/react-uikit/toolkit/useDisableBodyScroll';
import useScrollOutsideCallback from '@zvuk-b2b/react-uikit/toolkit/useScrollOutsideCallback';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';

import { useModalContext } from 'application/providers/ModalProvider';
import SmartMenuItem from './Item';

import styles from './index.module.scss';

export type SmartMenuProps = {
  className?: string;
  isMenuOpen: boolean;
  showMenu: () => void;
  hideMenu: () => void;
};

const SmartMenu = ({ className, isMenuOpen, showMenu, hideMenu }: SmartMenuProps) => {
  const ref = useRef(null);

  const { isDesktop, isIpad } = useMedia();
  const bodyScroll = useDisableBodyScroll();

  const { mainMenu } = useMenuItemsContext();
  const { isModalOpen } = useModalContext();

  useEffect(() => {
    if (isDesktop && !isModalOpen && ref.current && !isIpad) {
      bodyScroll.enableBodyScroll(ref.current);
    }
  }, [bodyScroll, isDesktop, isModalOpen, isIpad]);

  useEffect(() => {
    const menuRefCurrent = ref.current;

    if (menuRefCurrent && !isIpad) {
      if (isMenuOpen) {
        bodyScroll.disableBodyScroll(menuRefCurrent);
      } else {
        bodyScroll.enableBodyScroll(menuRefCurrent);
      }
    }

    return () => {
      if (!isModalOpen && menuRefCurrent) {
        bodyScroll.enableBodyScroll(menuRefCurrent);
      }
    };
  }, [isMenuOpen, isModalOpen, bodyScroll, isIpad]);

  useScrollOutsideCallback(ref.current!, () => {
    if (isDesktop) {
      hideMenu();
    }
  });

  const defaultItems = useMemo(
    () => mainMenu.items.filter((item) => item.target === MenuItemTarget.DEFAULT && item.isVisible),
    [mainMenu.items]
  );

  const bottomItems = useMemo(
    () => mainMenu.items.filter((item) => item.target === MenuItemTarget.BOTTOM && item.isVisible),
    [mainMenu.items]
  );

  const profileItem = useMemo(
    () => mainMenu.items.find((item) => item.target === MenuItemTarget.PROFILE && item.isVisible),
    [mainMenu.items]
  );

  return (
    <ul
      role="menu"
      className={classNames(styles.SmartMenu, className)}
      data-expanded={isMenuOpen}
      ref={ref}
      onMouseOver={() => isDesktop && showMenu()}
      onFocus={() => isDesktop && showMenu()}
      onMouseLeave={() => isDesktop && hideMenu()}>
      <div className={styles.SmartMenuLogo}>
        {isDesktop && <Logo mode={isMenuOpen ? LogoMode.FULL : LogoMode.SHORT} />}
      </div>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.SmartMenuTop}
        onClick={() => !isDesktop && hideMenu()}>
        {defaultItems.map((item) => (
          <SmartMenuItem
            key={`smart-menu-${item.target}-${item.title}`}
            elementId={`smart-menu-${item.target}-${item.title}`}
            pageMenu={item}
            isExpanded={isMenuOpen}
            isMarked={item.isMarked}
            isNoticed={item.isNoticed}
          />
        ))}
      </div>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.SmartMenuBottom}
        onClick={() => !isDesktop && hideMenu()}>
        {bottomItems.map((item) => (
          <SmartMenuItem
            key={`smart-menu-${item.target}-${item.title}`}
            elementId={`smart-menu-${item.target}-${item.title}`}
            pageMenu={item}
            isExpanded={isMenuOpen}
            isMarked={item.isMarked}
            isNoticed={item.isNoticed}
          />
        ))}

        {profileItem && (
          <SmartMenuItem
            key={`smart-menu-${profileItem.target}-${profileItem.title}`}
            elementId={`smart-menu-${profileItem.target}-${profileItem.title}`}
            pageMenu={profileItem}
            isExpanded={isMenuOpen}
          />
        )}
      </div>
    </ul>
  );
};

export default SmartMenu;
