import React, { useState } from 'react';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import InputSearch from '@zvuk-b2b/react-uikit/ui/InputSearch';
import classNames from 'classnames';
import { PaginationInfoType } from 'domain/api/graphql/generated';
import Autocomplete from '@zvuk-b2b/react-uikit/ui/Autocomplete';
import useAddressState from 'application/hooks/useAddressState';
import { AdCampaignPreviewType } from 'domain/media/types';
import { useAccountContext } from 'application/providers/AccountProvider';
import ChooseItemList from './ChooseItemList';
import { SelectorType } from './ChooseItemList/ChooseItemListItem';
import TEXTS from './texts';
import styles from './index.module.scss';
import { PointsListCheckboxGroupPointType } from '../PointItemContent/types';

export type ItemDataType = PointsListCheckboxGroupPointType | AdCampaignPreviewType;

export type SearchItemsType = {
  items: Array<ItemDataType>;
  selectedItems: Array<ItemDataType>;
  isLoading: boolean;
  isReloading?: boolean;
  hasSearch?: boolean;
  hasMultiselect?: boolean;
  className?: string;
  onChange: (items: Array<ItemDataType>) => void;
  loadMore?: () => void;
  paginationSettings?: PaginationInfoType;
  selectorType: SelectorType;
  onChangeWrapper: (value: boolean, item: ItemDataType) => void;
  showStreamData?: boolean;
  emptySearchText: string;
  inputPlaceholder?: string;
};

const SearchItems = ({
  items,
  isLoading,
  isReloading,
  selectedItems,
  hasMultiselect = false,
  hasSearch = false,
  onChange,
  className,
  loadMore,
  paginationSettings,
  selectorType,
  onChangeWrapper,
  showStreamData = false,
  emptySearchText,
  inputPlaceholder,
}: SearchItemsType) => {
  const [search, setSearch] = useState('');

  const { currentCompany } = useAccountContext();
  const addressState = useAddressState({ location: currentCompany?.country });

  const currentItems = items.filter(
    (
      item // TODO: fix field for search for different types
    ) =>
      // @ts-ignore
      (item.address || item.title)?.toLowerCase().indexOf(search.toLowerCase()) !== -1
  );

  const selectedItemsList = currentItems.filter((item) =>
    selectedItems.map((selectedItem) => selectedItem.id).includes(item.id)
  );

  const hasSelectedItems = selectedItemsList.length > 0;
  const hasUnselectedItems = currentItems.length > selectedItemsList.length;

  const onChangeAll = (value: boolean) => {
    if (value) {
      onChange(currentItems);
      return;
    }

    const filteredItems = selectedItems.filter((current) => !currentItems.some((item) => item.id === current.id));

    onChange(filteredItems);
  };

  return (
    <div className={classNames(styles.SearchItems, className)}>
      {hasSearch && SelectorType.dropdown !== selectorType && (
        <InputSearch
          containerClassName={styles.SearchItemsInput}
          value={search}
          elementId="search_input_items_list"
          onChange={setSearch}
          isDisabled={isLoading || isReloading}
          placeholder={inputPlaceholder}
        />
      )}

      {hasMultiselect && (
        <div className={styles.SearchItemsMultiselect}>
          {hasUnselectedItems && (
            <Button
              elementId="items_list_select_all"
              type={ButtonType.PLAIN}
              className={styles.SearchItemsMultiselectButton}
              onClick={() => onChangeAll(true)}
              isDisabled={isLoading || isReloading}>
              {TEXTS.ITEM_LIST_SELECT_ALl}
            </Button>
          )}
          {hasUnselectedItems && hasSelectedItems && <span className={styles.SearchItemsMultiselectPoint}>•</span>}
          {hasSelectedItems && (
            <Button
              elementId="items_list_select_none"
              type={ButtonType.PLAIN}
              className={styles.SearchItemsMultiselectButton}
              onClick={() => onChangeAll(false)}
              isDisabled={isLoading || isReloading}>
              {TEXTS.ITEM_LIST_SELECT_NONE}
            </Button>
          )}
        </div>
      )}

      {currentItems.length === 0 && !isLoading && <div className={styles.SearchItemsEmpty}>{emptySearchText}</div>}

      {selectorType === SelectorType.dropdown && ( // todo: it needed only for ADs
        <Autocomplete
          elementId="input_activate_device"
          autoComplete="off"
          value={addressState.value}
          options={addressState.options || []}
          error={false}
          isLoading={addressState.loading}
          onChange={addressState.onChange}
          isDisabled={isLoading || isReloading}
        />
      )}

      {selectorType !== SelectorType.dropdown && (
        <ChooseItemList
          itemList={currentItems}
          loadedItemsCount={items.length}
          isLoading={isLoading}
          isReloading={isReloading}
          selectedItems={selectedItems}
          selectorType={selectorType}
          onChange={(value: boolean, point: ItemDataType) => {
            onChangeWrapper(value, point);
          }}
          loadMore={loadMore}
          paginationSettings={paginationSettings}
          showStreamData={showStreamData}
        />
      )}
    </div>
  );
};

export default SearchItems;
