import React from 'react';
import classNames from 'classnames';

import { LAW_CONST, OfferLinkByTariff, TARIFFS } from 'application/pages/constants';
import AnalyticsService, { CalledFromValues, EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';
import { Country } from 'domain/api/graphql/generated';

import { useURLSearchParams } from 'application/providers/RouterProvider';
import { RegisterQueryKeys } from 'domain/auth/types';
import styles from './index.module.scss';

import { TEXTS } from './texts';

export enum PrivacyPolicyMode {
  SIGN_UP,
  SIGN_UP_KZ,
  SHORT,
  SHORT_BY,
  MIGRATION,
}

export type PrivacyPolicyProps = {
  mode: PrivacyPolicyMode;
  country?: Country;
  className?: string;
};

const PrivacyPolicy = (props: PrivacyPolicyProps) => {
  const { mode, country, className } = props;

  const SEARCH_PARAMS = [RegisterQueryKeys.TARIFF];

  const [tariff] = useURLSearchParams(SEARCH_PARAMS);
  const currentTariff = tariff ?? TARIFFS.COMFORT_2023;

  const OFFER_URL_RU =
    OfferLinkByTariff[currentTariff as keyof typeof OfferLinkByTariff] ?? OfferLinkByTariff.comfort_2023;

  const onContractURLClick = (url: string) => {
    if (url === OFFER_URL_RU && mode === PrivacyPolicyMode.SIGN_UP) {
      AnalyticsService.event(EventNameAMP.SHOW_OFERTA_PRESSED, {
        [ParamNameAMP.CALLED_FROM]: CalledFromValues.SIGN_UP_PAGE,
      });
    }

    if ([OFFER_URL_RU, LAW_CONST.PUBLIC_OFFER_URL_KZ].includes(url) && mode === PrivacyPolicyMode.MIGRATION) {
      AnalyticsService.event(EventNameAMP.SHOW_OFERTA_PRESSED, {
        [ParamNameAMP.CALLED_FROM]: CalledFromValues.OFERTA_ACCEPTING,
      });
    }
  };

  return (
    <div className={classNames(styles.PrivacyPolicy, className)}>
      {mode === PrivacyPolicyMode.MIGRATION &&
        country === Country.Ru &&
        TEXTS.POLICY_MIGRATION_TITLE(
          OFFER_URL_RU,
          LAW_CONST.PERS_DATA_URL_RU,
          LAW_CONST.PRIVACY_POLICY_URL_RU,
          onContractURLClick
        )}
      {mode === PrivacyPolicyMode.MIGRATION &&
        country === Country.Kz &&
        TEXTS.POLICY_MIGRATION_TITLE(
          LAW_CONST.PUBLIC_OFFER_URL_KZ,
          LAW_CONST.PERS_DATA_URL_KZ,
          LAW_CONST.PRIVACY_POLICY_URL_KZ,
          onContractURLClick
        )}
      {mode === PrivacyPolicyMode.SIGN_UP &&
        TEXTS.POLICY_TITLE(
          OFFER_URL_RU,
          LAW_CONST.PERS_DATA_URL_RU,
          LAW_CONST.PRIVACY_POLICY_URL_RU,
          onContractURLClick
        )}
      {mode === PrivacyPolicyMode.SIGN_UP_KZ &&
        TEXTS.POLICY_TITLE(
          LAW_CONST.PUBLIC_OFFER_URL_KZ,
          LAW_CONST.PERS_DATA_URL_KZ,
          LAW_CONST.PRIVACY_POLICY_URL_KZ,
          onContractURLClick
        )}
      {mode === PrivacyPolicyMode.SHORT &&
        country === Country.Ru &&
        TEXTS.POLICY_SHORT_TITLE(LAW_CONST.PERS_DATA_URL_RU, LAW_CONST.PRIVACY_POLICY_URL_RU, onContractURLClick)}
      {mode === PrivacyPolicyMode.SHORT &&
        country === Country.Kz &&
        TEXTS.POLICY_SHORT_TITLE(LAW_CONST.PERS_DATA_URL_KZ, LAW_CONST.PRIVACY_POLICY_URL_KZ, onContractURLClick)}
      {mode === PrivacyPolicyMode.SHORT &&
        country === Country.By &&
        TEXTS.POLICY_SHORT_BY_TITLE(LAW_CONST.PRIVACY_POLICY_URL_BY, onContractURLClick)}
      {mode === PrivacyPolicyMode.SHORT_BY &&
        TEXTS.POLICY_SHORT_BY_TITLE(LAW_CONST.PRIVACY_POLICY_URL_BY, onContractURLClick)}
    </div>
  );
};

export default PrivacyPolicy;
