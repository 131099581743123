/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import DIContainerProvider from 'application/providers/DIContainerProvider';
import AuthProvider from 'application/providers/AuthProvider';
import DataProvider from 'application/providers/DataProvider';
import AccountProvider from 'application/providers/AccountProvider';
import NotificationProvider from 'application/providers/NotificationProvider';
import GTMWrapper from 'application/components/GTMWrapper';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import FeatureFlagsProvider from 'application/providers/FeatureFlagsProvider/FeatureFlagsProvider';
import SupportChat from 'application/components/widgets/SupportChat';
import { MediaQueriesProvider } from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import { PROD, ZVUK_SENTRY_ENV } from 'application/consts';
import ApplicationRouter from './Application';

const PROD_SENTRY_INTEGRATIONS = [new Integrations.BrowserTracing({})];

const DEV_SENTRY_INTEGRATIONS = [
  ...PROD_SENTRY_INTEGRATIONS,
  new Sentry.Integrations.Breadcrumbs({
    console: false,
  }),
];

const CURRENT_SENTRY_INTEGRATIONS = PROD ? PROD_SENTRY_INTEGRATIONS : DEV_SENTRY_INTEGRATIONS;

Sentry.init({
  dsn: 'https://029a44aeea3b44298743622d0c818eb3@sentry.muzlab.ru/21',
  integrations: CURRENT_SENTRY_INTEGRATIONS,
  environment: ZVUK_SENTRY_ENV || 'local',
});

const Main = () => (
  <AuthProvider>
    <DataProvider>
      <DIContainerProvider>
        <AccountProvider>
          <FeatureFlagsProvider>
            <MediaQueriesProvider>
              <NotificationProvider>
                <ApplicationRouter />
              </NotificationProvider>
            </MediaQueriesProvider>
          </FeatureFlagsProvider>
          <SupportChat />
        </AccountProvider>
      </DIContainerProvider>
      <GTMWrapper />
    </DataProvider>
  </AuthProvider>
);

export default Main;
