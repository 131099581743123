import { useMemo } from 'react';
import { PointsListDataItem } from '../../types';

export const useIsPointsListChanged = (
  selectedPoints: PointsListDataItem[],
  initialSelectedPoints: PointsListDataItem[]
) =>
  useMemo(() => {
    if (selectedPoints.length !== initialSelectedPoints.length) return false;
    const selectedPointsSet = new Set(selectedPoints.map((point) => point.id));
    const initialSelectedPointsSet = new Set(initialSelectedPoints.map((point) => point.id));

    return (
      selectedPoints.every((point) => initialSelectedPointsSet.has(point.id)) &&
      initialSelectedPoints.every((point) => selectedPointsSet.has(point.id))
    );
  }, [selectedPoints, initialSelectedPoints]);
