import Button, { ButtonType } from 'ui/Button';
import IconChevronDown from 'ui/icons/ChevronDown';
import IconMoreHorizontal from 'ui/icons/MoreHorizontal';
import IconMoreVertical from 'ui/icons/MoreVertical';
import React, { ReactNode, useRef, useState } from 'react';
import Dropdown, { DropdownItemDataType, DropdownOrientation } from '../index';

import styles from './index.module.scss';

export enum ExpandIconType {
  CHEVRON,
  MORE_HORIZONTAL,
  MORE_VERTICAL,
}

export type ButtonProps = {
  text: ReactNode;
  iconType: ExpandIconType;
};

export type DropdownMenuProps = {
  elementId: string;
  items: Array<DropdownItemDataType>;
  orientation: DropdownOrientation;
  maxVisibleOptions?: number;
  onChange?: () => void;
  buttonProps: ButtonProps;
  hasSearch?: boolean;
};

const DropdownMenu = ({
  elementId,
  items,
  orientation,
  maxVisibleOptions,
  buttonProps,
  onChange,
  hasSearch,
}: DropdownMenuProps) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLInputElement>(null);

  const getContainerStyles = () => {
    let containerStyles = styles.DropdownMenuTitleContainer;

    if (buttonProps.iconType === ExpandIconType.CHEVRON) {
      containerStyles += ` ${styles.DropdownMenuTitleContainerFullWidth}`;
    } else {
      containerStyles += ` ${styles.DropdownMenuTitleContainerCentered}`;
    }

    return containerStyles;
  };

  const getIconStyles = () => {
    let iconStyles = styles.DropdownMenuButtonIcon;

    if (buttonProps.iconType === ExpandIconType.MORE_HORIZONTAL) {
      iconStyles += ` ${styles.DropdownMenuButtonIconWithRightMargin} ${styles.DropdownMenuButtonIconPrimary}`;
    } else if (buttonProps.iconType === ExpandIconType.MORE_VERTICAL) {
      iconStyles += ` ${styles.DropdownMenuButtonIconWithRightMargin}`;
    }

    return iconStyles;
  };

  const iconButton = (iconType: ExpandIconType) => {
    switch (iconType) {
      case ExpandIconType.CHEVRON:
        return <IconChevronDown className={getIconStyles()} />;
      case ExpandIconType.MORE_HORIZONTAL:
        return <IconMoreHorizontal className={getIconStyles()} />;
      case ExpandIconType.MORE_VERTICAL:
        return <IconMoreVertical className={getIconStyles()} />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={getContainerStyles()}
      ref={wrapperRef}>
      <Button
        elementId={`button_${elementId}`}
        type={ButtonType.PLAIN}
        onClick={() => {
          setOpen(true);
          onChange?.();
        }}
        className={styles.DropdownMenuHintButton}>
        {buttonProps.text}
        {iconButton(buttonProps.iconType)}
      </Button>
      {isOpen && (
        <Dropdown
          elementId={`dropdown_${elementId}`}
          items={items}
          onClose={() => setOpen(false)}
          anchorNode={wrapperRef.current!}
          parentNode={wrapperRef.current!}
          orientation={orientation}
          maxVisibleOptions={maxVisibleOptions}
          hasSearch={hasSearch}
        />
      )}
    </div>
  );
};

export default DropdownMenu;
