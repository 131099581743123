import { TimezoneObjectType, useTimezonesLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { useEffect } from 'react';

const useTimezones = () => {
  const [query, { data, loading }] = useTimezonesLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async () => {
    const timezonesData = await query();

    return timezonesData;
  };

  const timezones = data?.timezones as TimezoneObjectType[];

  useEffect(() => {
    request();
  }, []);

  return {
    loading,
    timezones,
    request,
  };
};

export default useTimezones;
