class CommonUtils {
  static numberWithSeparation(num: number | string, separator: string = ' '): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  }

  static capitalizedFirstLetter(word: string): string {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  }

  static unCapitalizedFirstLetter(word: string): string {
    return `${word.charAt(0).toLowerCase()}${word.slice(1)}`;
  }

  static getSubstringBetweenChars = (str: string, char1: string, char2: string) =>
    str.slice(str.indexOf(char1) + 1, str.lastIndexOf(char2));

  static convertObjectValuesToString = (obj: object): string =>
    Object.values(obj)
      .reduce((acc, value) => [...acc, ...value], [])
      .join(', ');
}

export default CommonUtils;
