export const TEXTS = {
  MODAL_TITLE: 'Умная колонка СберБум',
  TAGLINE: 'Больше, чем звук',

  SUCCESS_DISABLED_BUTTON: 'Запрос отправлен',
  SUCCESS_BUTTON: 'Отправить запрос',
  CANCEL_BUTTON: 'Назад',

  FEATURES_VOICE: 'Управление голосом',
  FEATURES_SMART_HOUSE: 'Подключение умного дома',
  FEATURES_NOISE: 'Детектор шума',

  DESCRIPTION_FIRST_BLOCK:
    'Только умная колонка СберБум, и никаких других устройств, позволит бесконечно наслаждаться треками, подобранными для вас.',
  DESCRIPTION_SECOND_BLOCK:
    'Подключив колонку по Bluetooth к телефону, вы можете управлять волнами через мобильное приложение:',
  DESCRIPTION_THIRD_BLOCK: 'запустить музыкальную волну, управлять громкостью или удалять композиции с волны.',

  DISABLED_BUTTON_BINDED_HINT: 'Этот тип устройства сейчас привязан',
};
