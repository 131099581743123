import { StreamMediafilesSort, useMediafilesStreamLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { DEFAULT_PAGE_SIZE } from 'domain/api/graphql/constants';
import { NetworkStatus } from '@apollo/client';

const useMediafiles = () => {
  const [query, queryResult] = useMediafilesStreamLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_FIRST,
    notifyOnNetworkStatusChange: true,
  });

  const { data, loading } = queryResult;
  const isFetching = queryResult.networkStatus === NetworkStatus.fetchMore;
  const mediafilesList = data?.streamMediafiles.result;

  const request = async (id: string) => {
    await query({
      variables: {
        filters: { streamId: id },
        sort: StreamMediafilesSort.IdDesc,
        pagination: { pageSize: DEFAULT_PAGE_SIZE, page: 0 },
      },
    });
  };

  const loadMore = async (id: string) => {
    if (isFetching || loading) {
      return;
    }

    await queryResult.fetchMore({
      variables: {
        filters: { streamId: id },
        sort: StreamMediafilesSort.IdDesc,
        pagination: {
          pageSize: DEFAULT_PAGE_SIZE,
          page: queryResult.data?.streamMediafiles.paginationInfo.page! + 1,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        streamMediafiles: {
          ...prev?.streamMediafiles,
          ...fetchMoreResult?.streamMediafiles,
          result: [...(prev.streamMediafiles.result || []), ...(fetchMoreResult.streamMediafiles.result || [])],
        },
      }),
    });
  };

  return {
    request,
    loadMore,
    error: queryResult.error,
    loading: queryResult.loading,
    data: {
      mediafilesList,
      paginationInfo: queryResult.data?.streamMediafiles.paginationInfo,
    },
  };
};

export default useMediafiles;
