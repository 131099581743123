import React from 'react';

export const TEXTS = {
  DOC_TITLE: 'Вход – Звук Бизнес',
  PAGE_TITLE: 'Вход',
  BUTTON_REGISTER_TITLE: (a: string) => (
    <>
      <span>Еще нет аккаунта? </span>
      <a href={a}>Зарегистрироваться</a>
    </>
  ),
  SUBTITLE_CODE_DESC: (phone: string) => (
    <>
      <span>Введите код из SMS-сообщения.</span>
      <br />
      <span>Мы отправили его на номер </span>
      <span data-phone>{phone}</span>
    </>
  ),
};
