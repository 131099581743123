import { UserFeaturesOnboardingFilter, useUserFeaturesOnboardingQueryQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useUserFeaturesOnboarding = (filters?: UserFeaturesOnboardingFilter) => {
  const {
    data: userFeaturesOnboarding,
    loading,
    error,
    refetch,
  } = useUserFeaturesOnboardingQueryQuery({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
    variables: {
      filters: filters || {},
    },
  });

  return {
    error,
    loading,
    items: userFeaturesOnboarding?.result || [],
    refetch,
  };
};

export default useUserFeaturesOnboarding;
