import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

import TextAreaComponent from 'ui/TextArea';

import FieldUtils from '../FieldUtils';
import { FieldRulesType, TextAreaFieldProps } from '../types';

import styles from './index.module.scss';

const TextAreaField = <T extends FieldValues>(props: TextAreaFieldProps<T>) => {
  const { field, fieldState } = useController(props);
  const { onFieldChange } = props;

  const { elementId, error, rules, handleFieldDirtyChange } = props;

  const handleChange = (value: string, _: React.SyntheticEvent<Element, Event>) => {
    field.onChange(value);
    const isEmpty = value.length === 0;
    handleFieldDirtyChange?.(field.name, !isEmpty);

    onFieldChange?.(field);
  };

  return (
    <TextAreaComponent
      {...field}
      {...props}
      elementId={`form_text_area_field_${elementId}`}
      containerClassName={styles.TextAreaField}
      required={!!rules?.required}
      error={
        Array.isArray(error)
          ? error
          : FieldUtils.formatError(fieldState.invalid, field.value, rules as FieldRulesType, error)
      }
      onChange={handleChange}
    />
  );
};

export default TextAreaField;
