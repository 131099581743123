import { DropdownItemDataType } from '@zvuk-b2b/react-uikit/ui/Dropdown';
import { AdCampaignPureType } from 'domain/api/graphql/generated';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { CompanyTagType } from 'domain/company/types';
import { IAdCampaignsMetadata } from '../../providers/types';
import { TEXTS } from '../texts';

export type UseAdCampaignsModalControllerParams = {
  adCampaigns: IAdCampaignsMetadata;
};

export enum AdCampaignsGroupName {
  Active = 'active',
  Planned = 'planned',
  Expired = 'expired',
  Stopped = 'stopped',
}

const useAdCampaignsModalController = ({ adCampaigns }: UseAdCampaignsModalControllerParams) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const filteredAdCampaigns = useMemo(
    () =>
      Object.values(adCampaigns).filter(
        (ad) =>
          (!searchValue || ad.title.toLocaleLowerCase().includes(searchValue)) &&
          (selectedTags.length === 0 || ad.tags?.some((tag) => selectedTags.includes(tag.title)))
      ),
    [searchValue, selectedTags]
  );

  const adCampaignGroups = useMemo(
    () =>
      Object.values(filteredAdCampaigns).reduce<Record<AdCampaignsGroupName, AdCampaignPureType[]>>(
        (groups, adCampaign) => {
          const startDate = moment(adCampaign.timingSettings.startDate);
          const endDate = moment(adCampaign.timingSettings.endDate);
          const now = moment();

          if (!adCampaign.enable) {
            groups[AdCampaignsGroupName.Stopped].push(adCampaign);
          } else if (now.isBetween(startDate, endDate) || (now.isAfter(startDate) && !endDate.isValid())) {
            groups[AdCampaignsGroupName.Active].push(adCampaign);
          } else if (now.isBefore(startDate)) {
            groups[AdCampaignsGroupName.Planned].push(adCampaign);
          } else if (now.isAfter(endDate)) {
            groups[AdCampaignsGroupName.Expired].push(adCampaign);
          }
          return groups;
        },
        {
          [AdCampaignsGroupName.Active]: [],
          [AdCampaignsGroupName.Planned]: [],
          [AdCampaignsGroupName.Expired]: [],
          [AdCampaignsGroupName.Stopped]: [],
        }
      ),
    [filteredAdCampaigns]
  );

  const adCampaignGroupTitles = {
    [AdCampaignsGroupName.Active]: TEXTS.AD_CAMPAIGN_TYPE_ACTIVE,
    [AdCampaignsGroupName.Planned]: TEXTS.AD_CAMPAIGN_TYPE_PLANNED,
    [AdCampaignsGroupName.Expired]: TEXTS.AD_CAMPAIGN_TYPE_EXPIRED,
    [AdCampaignsGroupName.Stopped]: TEXTS.AD_CAMPAIGN_TYPE_STOPPED,
  };

  const adCampaignIdsSet = useMemo(
    () => new Set(Object.values(adCampaigns).map((adCampaign) => adCampaign.id)),
    [adCampaigns]
  );

  const filterAdCampaignTags = (tag: CompanyTagType) =>
    !!tag.adCampaigns?.some((adCampaign) => adCampaignIdsSet.has(adCampaign.id));

  const onSearchChange: (value: string) => void = debounce((value: string) => {
    const searchValue = value.trim().toLocaleLowerCase();
    setSearchValue(searchValue);
  }, 100);

  const onTagFilterChange = (tags: DropdownItemDataType[]) => {
    setSelectedTags(tags.map((tag) => tag.value) as string[]);
  };

  return {
    filteredAdCampaigns,
    adCampaignGroups,
    adCampaignGroupTitles,
    filterAdCampaignTags,
    onSearchChange,
    onTagFilterChange,
  };
};

export default useAdCampaignsModalController;
