/* eslint-disable jsx-a11y/no-autofocus */
import React, { forwardRef, useMemo, useEffect, useRef } from 'react';
import { useDebounce } from 'ahooks';
import classNames from 'classnames';

import Spinner from 'ui/Spinner';
import { Colors } from 'ui/types';

import { DebounceOptions } from 'ahooks/es/useDebounce/debounceOptions';
import styles from './index.module.scss';

const DEBOUNCE_OPTIONS: DebounceOptions = { leading: true, wait: 250 };

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  PLAIN = 'plain',
}

export type ButtonProps = React.PropsWithChildren<{
  type?: ButtonType;
  size?: ButtonSize;
  className?: string;
  adornmentLeftClassName?: string;
  adornmentRightClassName?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  autofocus?: boolean;
  elementId: string;
  opacity?: number;
  adornmentLeft?: React.ReactNode;
  adornmentRight?: React.ReactNode;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
}>;

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const {
    type = ButtonType.PRIMARY,
    size = ButtonSize.MEDIUM,
    isLoading = false,
    isDisabled = false,
    autofocus = false,
    opacity = 1,
    className,
    adornmentLeftClassName,
    adornmentRightClassName,
    children,
    elementId,
    adornmentLeft,
    adornmentRight,
    onClick,
    onKeyDown,
    onBlur,
  } = props;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const isLocalLoadingFalseDelayed = useDebounce(isLoading, DEBOUNCE_OPTIONS) || isLoading;

  const color = useMemo(() => {
    switch (type) {
      case ButtonType.SECONDARY:
        return Colors.COLOR_NEUTRAL_500;
      case ButtonType.PLAIN:
        return Colors.COLOR_NEUTRAL_300;
      default:
        return Colors.COLOR_WHITE;
    }
  }, [type]);

  useEffect(() => {
    if (buttonRef && buttonRef.current) {
      buttonRef.current.style.setProperty('--var-opacity', `${opacity}`);
    }
  }, [opacity]);

  const content = isLocalLoadingFalseDelayed ? (
    <Spinner
      color={color}
      className={styles.ButtonSpinner}
    />
  ) : (
    children
  );

  return (
    <button
      ref={ref || buttonRef}
      type="button"
      disabled={isDisabled}
      data-loading={isLocalLoadingFalseDelayed}
      data-type={type}
      data-size={size}
      autoFocus={autofocus}
      className={classNames(styles.Button, className)}
      id={elementId}
      data-testid={elementId}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onBlur={onBlur}>
      {adornmentLeft && (
        <div className={classNames(styles.ButtonAdornment, styles.ButtonAdornmentL, adornmentLeftClassName)}>
          {adornmentLeft}
        </div>
      )}

      {content}

      {adornmentRight && (
        <div className={classNames(styles.ButtonAdornment, styles.ButtonAdornmentR, adornmentRightClassName)}>
          {adornmentRight}
        </div>
      )}
    </button>
  );
});

export default Button;
