import React from 'react';
import classNames from 'classnames';

import { useAccountContext } from 'application/providers/AccountProvider';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import IconUser from '@zvuk-b2b/react-uikit/ui/icons/User';

import styles from './index.module.scss';

const ProfileButton = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  const { currentUser } = useAccountContext();

  return (
    <div className={styles.ProfileButtonContainer}>
      <IconButton
        key="button_user_profile"
        elementId="button_user_profile"
        className={classNames(styles.ProfileButton, className)}
        onClick={onClick}>
        {currentUser ? currentUser.email[0].toUpperCase() : <IconUser className={styles.ProfileButtonIcon} />}
      </IconButton>
    </div>
  );
};

export default ProfileButton;
