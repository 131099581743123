import React, { useMemo, useCallback, useRef, useReducer } from 'react';

import { ModalsMap } from './modalsMap';
import { ModalProps } from './modalsProps';
import { ModalContextState, OpenModalActionType } from './types';
import ModalRenderer from './ModalRenderer';
import { ModalContext } from './ModalContext';

type Action =
  | { type: 'openModal'; modal: OpenModalActionType }
  | { type: 'closeModal'; count: number }
  | { type: 'closeAllModals' };

const reducer = (state: ModalContextState, action: Action): ModalContextState => {
  switch (action.type) {
    case 'openModal':
      return {
        currentModals: [
          ...state.currentModals,
          {
            type: action.modal.type,
            state: action.modal.state,
          },
        ],
      };
    case 'closeModal':
      return {
        currentModals: state.currentModals.slice(0, -action.count),
      };
    case 'closeAllModals':
      return {
        currentModals: [],
      };
    default:
      return state;
  }
};

const ModalProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { currentModals: [] });
  const onCloseCallback = useRef<(() => void) | undefined>(undefined);

  const openModal = <T extends keyof typeof ModalsMap>(
    type: T,
    modalState: Omit<ModalProps[T], 'open' | 'close'>,
    onModalCloseCallback?: () => void
  ) => {
    dispatch({
      type: 'openModal',
      modal: {
        state: modalState,
        type,
      },
    });

    onCloseCallback.current = onModalCloseCallback;
  };

  const closeModal = useCallback(
    (count: number = 1) => {
      dispatch({
        type: 'closeModal',
        count,
      });

      onCloseCallback.current?.();
    },
    [state.currentModals]
  );

  const closeAllModals = useCallback(() => {
    dispatch({
      type: 'closeAllModals',
    });

    onCloseCallback.current?.();
  }, [state.currentModals]);

  const value = useMemo(
    () => ({
      currentModals: state.currentModals,
      closeModal,
      closeAllModals,
      openModal,
      isModalOpen: state.currentModals.length !== 0,
    }),
    [state, openModal, closeModal, closeAllModals]
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalRenderer />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
