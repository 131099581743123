import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export type SkeletonType = {
  itemCount?: number;
  className?: string;
  isHidden?: boolean;
  items: any[];
  hasNextPage?: boolean;
  totalCount: number;
  children: React.ReactNode;
};

const INITIAL_SKELETON_LENGTH = 10;

const ItemsListSkeleton = ({
  itemCount = INITIAL_SKELETON_LENGTH,
  className,
  isHidden,
  items,
  hasNextPage,
  totalCount,
  children,
}: SkeletonType) => {
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    const show = hasNextPage || !items || items?.length === 0;

    if (!show) {
      setTimeout(() => {
        setShowSkeleton(show);
      }, 500);
    }
  }, [hasNextPage, items]);

  const skeletonItemsCount = useMemo(() => {
    if (totalCount < itemCount) {
      return itemCount;
    }

    return Math.min(totalCount - (items?.length || 0), itemCount);
  }, [totalCount, items]);

  const skeletonArray = useMemo(() => {
    const itemsCount = skeletonItemsCount > 0 ? skeletonItemsCount : 0;
    return Array.from(Array(itemsCount).keys());
  }, [skeletonItemsCount]);

  if (!showSkeleton) {
    return null;
  }

  return (
    <section
      className={classNames([styles.ItemListSkeleton, className])}
      data-hide={isHidden}
      data-testid="item-list-skeleton">
      {skeletonArray && skeletonArray.map(() => children)}
    </section>
  );
};

export default ItemsListSkeleton;
