import React, { useLayoutEffect } from 'react';

import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';

import { useScript } from 'application/hooks';
import { GTMWindow } from 'application/services/AnalyticsService/types';
import AnalyticsService from 'application/services/AnalyticsService';
import { ZVUK_GTM_KEY } from 'application/consts';

const GTM_SITE_URL = `https://www.googletagmanager.com/gtm.js?id=${ZVUK_GTM_KEY}`;

const GTM = () => {
  useLayoutEffect(() => {
    const windowGTM: GTMWindow = window;

    windowGTM.dataLayer = windowGTM.dataLayer || [];
    windowGTM.dataLayer.push({
      'gtm.start': DateUtils.NOW.getTime(),
      event: 'gtm.js',
    });
  }, []);

  useScript(GTM_SITE_URL, true);
  return null;
};

const GTMWrapper = () => {
  const isGTMAvailable = !!ZVUK_GTM_KEY && AnalyticsService.isEnabled;

  return isGTMAvailable ? <GTM /> : null;
};

export default GTMWrapper;
