import { useAuthTokenRevokeMutation, useCurrentUserLogoutMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { LogoutResultType, UseLogoutType } from './types';

const useLogout = (): UseLogoutType => {
  const [tokenRevokeMutation, tokenRevokeResult] = useAuthTokenRevokeMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });
  const [logoutMutation, logoutResult] = useCurrentUserLogoutMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async () => {
    const tokenRevokeResult = await tokenRevokeMutation();
    const logoutResult = await logoutMutation();

    return {
      revoked: tokenRevokeResult.data?.tokenPureRevoke?.revoked,
      deleted: logoutResult.data?.currentUserLogout?.deleted,
    };
  };

  const result: LogoutResultType = {
    error: tokenRevokeResult.error || logoutResult.error,
    loading: tokenRevokeResult.loading || logoutResult.loading,
    called: tokenRevokeResult.called && logoutResult.called,
    data: {
      revoked: tokenRevokeResult.data?.tokenPureRevoke?.revoked || undefined,
      deleted: logoutResult.data?.currentUserLogout?.deleted || undefined,
    },
  };

  return [request, result];
};

export default useLogout;
