export const TEXTS = {
  MODAL_TITLE: 'Видео-плеер',
  SUBTITLE: 'Установите приложение «Звук Бизнес» на телевизоре:',

  TURN_ON: 'Включите телевизор и нажмите кнопку HOME (Домой) на пульте дистанционного управления.',
  SCROLL: 'Прокрутите вниз до раздела «Apps» (Приложения).',
  CHOOSE: 'Выберите Store (Магазин)',
  SELECT: 'Выберите приложение «Звук Бизнес»',

  SUCCESS_BUTTON: 'Дальше',
  CANCEL_BUTTON: 'Назад',
};
