import React from 'react';
import DeclensionUtils from 'application/utils/DeclensionUtils';

export const TEXTS = {
  DOC_TITLE: 'Оплата – Звук Бизнес',
  PAGE_TITLE: 'Оплата',
  BILLING_PAYMENT_TITLE: 'Способ оплаты',
  BILLING_PAYMENT_EMAIL_FOR_DOCUMENTS_TITLE: 'E-mail для чеков',
  BILLING_PAYMENT_EMAIL_FOR_DOCUMENTS_EDIT_SUCCESS: 'E-mail для чеков сохранён',
  BILLING_REBIND_CARD_BUTTON_TEXT: 'Заменить карту',
  BILLING_WAIT_PAYMENT_BUTTON_TEXT: 'Платеж в обработке',
  BILLING_REMOVE_CARD_BUTTON_TEXT: 'Удалить карту',
  BILLING_AWAITING_CARD_TEXT: 'Ожидается списание с вашей карты...',

  BILLING_AUTORENEWAL_SUSPENDED_TITLE: 'Вы убрали адреса из подписки',
  BILLING_AUTORENEWAL_SUSPENDED_DESC: 'Вещание на адресах продолжится до окончания действия подписки',
  BILLING_AUTORENEWAL_SUSPENDED_SUBMIT: 'Хорошо',
  BILLING_AUTORENEWAL_CANCEL: 'Отменить автопродление',

  BILLING_BIND_CARD_MODAL_TITLE: 'Привязка карты',
  BILLING_BIND_CARD_BUTTON_TEXT: 'Привязать карту',
  BILLING_BIND_CARD_MODAL_CANCEL: 'Отменить',
  BILLING_BIND_CARD_MODAL_KASSA: 'Перейти на сайт «ЮKassa»',
  BILLING_BIND_CARD_MODAL_DESCRIPTION:
    'Нам потребуется списать 1₽ с новой платежной карты, чтобы её верифицировать. Мы вернём деньги обратно в течение 5 минут.',
  BILLING_BIND_CARD_NOTIFICATION_ERROR_MESSAGE: `Ошибка, привязка карты пока недоступна.
    Пожалуйста, попробуйте позже`,

  BILLING_REMOVE_CARD_MODAL_TITLE: 'Удалить карту?',
  BILLING_REMOVE_CARD_MODAL_DESCRIPTION:
    'Все данные карты будут удалены. Чтобы оплатить подписку, вам нужно будет заново привязать карту',

  BILLING_REQUISITES_CONFIRM_MODAL_TITLE: 'Сохранить реквизиты?',
  BILLING_REQUISITES_CONFIRM_MODAL_BUTTON_SAVE: 'Сохранить',
  BILLING_REQUISITES_CONFIRM_MODAL_BUTTON_CANCEL: 'Отменить',

  BILLING_REMOVE_CARD_MODAL_CONFIRM: 'Удалить карту',
  BILLING_REMOVE_CARD_NOTIFICATION_MESSAGE: 'Карта удалена!',
  BILLING_REMOVE_CARD_NOTIFICATION_ERROR_MESSAGE: `Ошибка, удаление карты пока недоступно.
    Пожалуйста, попробуйте позже`,

  BILLING_ERROR_COMPANY_NOT_VALIDATED:
    'Чтобы иметь возможность оплачивать банковской картой, необходимо подтвердить реквизиты организации',
  BILLING_GO_TO_COMPANY_PAGE_BUTTON_TEXT: 'Перейти к реквизитам',

  BILLING_LICENSES_MODAL_CLOSE: 'Отменить',
  BILLING_LICENSES_MODAL_TITLE: 'Адреса в подписке',
  BILLING_LICENSES_RESUME_MODAL_TITLE: (isMonth: boolean = true) =>
    `Какие адреса включить в ${isMonth ? 'ежемесячную' : 'ежегодную'} подписку?`,
  BILLING_LICENSES_UNPAID_MODAL_TITLE: 'На каких адресах включить музыку?',
  BILLING_LICENSES_UNPAID_MODAL_INFO: (isMonth: boolean = true) =>
    `Выбранные адреса будут включены в автопродление. Оплата каждый ${isMonth ? 'месяц' : 'год'}`,
  BILLING_LICENSES_EDIT_BUTTON_TITLE: 'Редактировать подписку',
  BILLING_LICENSES_RESUME_BUTTON_TITLE: 'Оплатить подписку',
  BILLING_LICENSES_WAIT_BUTTON_TOOLTIP: 'Чтобы редактировать подписку, дождитесь списания оплаты и обновите страницу',
  BILLING_LICENSES_LIST_BUTTON_TOOLTIP: 'Изменения отсутствуют',
  BILLING_LICENSES_DETAILS_BUTTON_TITLE: 'Подробнее',
  BILLING_LICENSES_TITLE: 'Подписка',
  BILLING_LICENSES_STATUS_ACTIVE: 'Активна',
  BILLING_LICENSES_STATUS_NOT_ACTIVE: 'Неактивна',
  BILLING_LICENSES_STATUS_TRIAL: 'Тестовый период',
  BILLING_LICENSES_STATUS_WAIT_PAYMENT: 'Платеж в обработке',
  BILLING_LICENSES_STATUS_TRIAL_END_TEXT: (days: number) => (
    <>
      {days === 0 && <span>Закончится сегодня</span>}
      {days !== 0 && (
        <>
          <span>
            {days === 1 ? 'Остался' : 'Осталось'}
            &nbsp;
          </span>
          <span>{DeclensionUtils.getFormattedDay(days)}</span>
        </>
      )}
    </>
  ),
  BILLING_LICENSES_SUBMIT_BUTTON_TEXT: 'Подтвердить',
  BILLING_LICENSES_AMOUNT: (amount: string | number) => `${amount} ₽`,
  BILLING_LICENSES_AMOUNT_BUTTON_TITLE: (amount: number) => `Оплатить ${amount % 1 > 0 ? amount.toFixed(2) : amount} ₽`,
  BILLING_LICENSES_DISCOUNT: (amount: string | number) => `-${amount}%`,
  BILLING_LICENSES_POINTS_TOTAL: (points: number = 0) => `за ${points} адрес${DeclensionUtils.pluralEnding(points)}`,
  BILLING_LICENSES_NEED_PAYMENT_METHOD: 'Для оплаты подписки требуется привязать карту',
  BILLING_LICENSES_NEED_PAYMENT: 'необходимо оплатить',

  BILLING_EXTEND_SUBSCRIPTION_MODAL_TITLE: 'Оплатить подписку?',
  BILLING_EXTEND_SUBSCRIPTION_MODAL_DESC: 'Мы автоматически спишем нужную сумму с вашей карты',
  BILLING_EXTEND_SUBSCRIPTION_MODAL_CANCEL: 'Закрыть',

  BILL_MAIL_TIP_MODAL_TITLE: 'Не могу найти чек об оплате!',
  BILL_MAIL_TIP_MODAL_CANCEL: 'Хорошо',
  BILL_MAIL_TIP_MODAL_DESC: (email: string) => (
    <>
      После оплаты на электронную почту <b>{email}</b> должно прийти письмо с чеком.
      <br />
      <br />
      Если вы не можете найти письмо, то убедитесь в корректности написания электронной почты и ищите по следующим
      признакам:
      <ol>
        <li>По отправителю noreply@chek.pofd.ru;</li>
        <li>По ключевым словам «чек», «офд», «звук бизнес».</li>
      </ol>
    </>
  ),

  BILLING_PAYMENT_METHOD_FAILED_NOTIFICATION_MESSAGE:
    'Мы не смогли получить информацию о вашем платежном средстве. Попробуйте привязать карту позже.',

  POINT_CREATE_TITLE: 'Добавление адреса',
  POINT_CREATE_ADDRESS: 'Адрес',
  POINT_CREATE_ADDRESS_PLACEHOLDER_RU: 'Москва, ул. Новый Арбат д.13',
  POINT_CREATE_ADDRESS_PLACEHOLDER_KZ: 'Астана, ул. Космонавтов д.10',
  POINT_CREATE_ADDRESS_PLACEHOLDER_BY: 'Минск, ул. Ленина д.10',
  POINT_CREATE_HINT_MODAL_TITLE: 'Почему важно указывать полный и корректный адрес?',
  POINT_CREATE_INFO_TEXT: 'У вас нет адресов в подписке. Чтобы продолжить, нужно создать адрес вещания',
  RATES_BLOCK_RATES_FALLBACK: `Тарифы временно недоступны для просмотра.
    Нажмите на пункт меню "Договор", чтобы изучить тарифы в оферте.`,
  RATES_BLOCK_RATES: 'Тарифы',
  RATES_BLOCK_RATES_MONTH_TEXT: 'в месяц',
  RATES_BLOCK_RATES_YEAR_TEXT: 'в год',
  RATES_BLOCK_RATES_PREPAY: '100% предоплата',
  RATES_BLOCK_RATES_CONNECTION: 'При подключении >',
  RATES_BLOCK_RATES_SPECIAL_PROPOSAL: ' адресов действует специальное предложение',
  RATES_BLOCK_RATES_NDS: 'Стоимость указана с НДС',
  RATES_BLOCK_CHANGE_RATE_BUTTON_ACTIVE: 'Подключить',
  RATES_BLOCK_CHANGE_RATE_BUTTON_TRIAL: 'Подключить',
  RATES_BLOCK_CHANGE_RATE_BUTTON_INACTIVE: 'Выбрать адреса для оплаты',
  RATES_BLOCK_ACTIVE_WITHOUT_AUTORENEWAL_SUBSCRIPTION_INFO: (dateStr: string) => (
    <>
      У ваших адресов выключено автопродление
      <br />
      Вещание будет остановлено &nbsp;
      {dateStr}
    </>
  ),
  RATES_BLOCK_INACTIVE_SUBSCRIPTION_INFO: (
    <>
      Ваша подписка неактивна.
      <br />
      Выберите адреса и возобновите подписку.
    </>
  ),
  RATES_BLOCK_TOTAL_TEXT: 'Итого:',
  RATES_BLOCK_NO_LICENSES_INFO: (
    <>
      <span>Сейчас у вас нет адресов...</span>
      <br />
      <span>Создайте свой первый адрес, чтобы начать пользоваться сервисом</span>
    </>
  ),
  RATES_BLOCK_NO_LICENSES_BUTTON: 'Создать первый адрес',
  RATES_BLOCK_NO_LICENSES_BUTTON_AUTORENEWABLE: 'Включить автопродление',

  BILLING_CHANGE_RATE_CONFIRM_MODAL_TITLE: 'Сменить тариф?',
  BILLING_CHANGE_RATE_CONFIRM_MODAL_DESCRIPTION: 'Все примененные скидки перестанут действовать',
  BILLING_CHANGE_RATE_CONFIRM_MODAL_CONFIRM: 'Сменить тариф',
  BILLING_CHANGE_PRICE_PLAN_CONFIRM_NOTIFICATION_MESSAGE: 'Период оплаты изменен',
  BILLING_CHANGE_PRICE_PLAN_CONFIRM_NOTIFICATION_MESSAGE_ERROR: 'Не удалось изменить период оплаты',
  BILLING_CHANGE_RATE_CONFIRM_NOTIFICATION_MESSAGE: 'Тариф изменен',
  BILLING_CHANGE_RATE_NOTIFICATION_MESSAGE_ERROR: 'Не удалось изменить тариф, попробуйте позже',
  BILLING_CHANGE_RATE_BUTTON_TOOLTIP: 'Смена тарифа недоступна, пока ожидается списание с вашей карты',
  TOTAL_PRICE_BLOCK_SHOW_OFERTA: 'Посмотреть оферту',

  TOTAL_PRICE_BLOCK_FIRST_DEBITING_TEXT: (hasTrialLicenses: boolean, title: string) => `
  ${hasTrialLicenses ? 'Ваше первое' : 'Следующее'} ${title} за`,
  TOTAL_PRICE_BLOCK_FIRST_DEBITING_POINT_COUNT_TEXT: (count: number) =>
    `${count} адрес${DeclensionUtils.pluralEnding(count)}`,
  TOTAL_PRICE_BLOCK_FIRST_DEBITING_DATE: (dateStr: string) => `в подписке будет ${dateStr}`,

  PRICE_PLAN_LIST_ITEM_RATE_TEXT: (isCurrentPricePlan: boolean) => `
  ${isCurrentPricePlan ? 'Ваш тариф' : 'Тариф'}`,

  BILLING_RATES_TABLE_STEP_LOWER_ADDRESS: (step: number) => `${step === 1 ? 'Один' : step} адрес`,
  BILLING_RATES_TABLE_STEP_FROM_ADDRESS: (step: number) => (step === 1 ? 'За каждый адрес' : `От ${step} адресов`),
  BILLING_RATES_TABLE_STEP_RANGE_ADDRESSES: (stepFrom: number, stepTo: number) => `
    От ${stepFrom} до ${stepTo} адресов`,
  BILLING_RATES_TABLE_STEP_HIGHER_ADDRESS: (step: number) => `От ${step} адресов`,

  BILLING_SUBMIT_CHANGE_AUTORENEWABLE_SUCCESS: 'Подписка изменена',
  BILLING_SUBMIT_CHANGE_AUTORENEWABLE_ERROR: 'Ошибка при попытке возобновления подписки. Повторите попытку позже',
};
