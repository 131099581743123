export const TEXTS = {
  INPUT_PHONE_TITLE: 'Номер телефона',
  INPUT_PHONE_PLACEHOLDER: '+7 999 000 00 00',
  INPUT_CODE_TITLE: 'Код',
  INPUT_CODE_PLACEHOLDER: '012345',
  INPUT_EMAIL_TITLE: 'Электронная почта',
  INPUT_EMAIL_PLACEHOLDER: 'example@zvuk.com',
  INPUT_PASSWORD_TITLE: 'Пароль',
  INPUT_PASSWORD_PLACEHOLDER: 'Введите пароль...',
  DELIMITER_LOGIN_TITLE: 'Войти с помощью',
  DELIMITER_REGISTER_TITLE: 'Зарегистрироваться с помощью',
  BUTTON_RESEND_CODE_TITLE: 'Отправить код ещё раз',
  BUTTON_RESEND_SEC_CODE_TITLE: (sec: number) => `Отправить код ещё раз через ${sec} сек.`,
  BUTTON_CONTINUE_TITLE: 'Продолжить',
  BUTTON_REGISTER_TITLE: 'Зарегистрироваться',
  BUTTON_LOGIN_TITLE: 'Войти',
  INPUT_PHONE_LOCAL_VALIDATION_ERROR_TEXT: 'Убедитесь, что вы не ошиблись при вводе номера',
  CAPTCHA_TOKEN_ERROR: 'Что-то пошло не так, попробуйте еще раз',
  ERROR_USER_REGISTERED_BY_EMAIL: 'Воспользуйтесь входом по электронной почте',
  ERROR_USER_NOT_FOUND:
    'Мы не нашли пользователя с таким номером телефона. Убедитесь, что вы не ошиблись при вводе номера',
  ERROR_USER_ALREADY_REGISTERED_BY_PHONE:
    'У вас уже есть аккаунт. Введите код из SMS-сообщения, чтобы войти в свой личный кабинет',
  ERROR_USER_ALREADY_REGISTERED_BY_EMAIl:
    'У вас уже есть аккаунт. Используйте электронную почту и пароль, чтобы войти в свой личный кабинет',
  BUTTON_FORGOT_TITLE: 'Забыли пароль?',
  ERROR_REGISTRATION_SMS_TROUBLE_TITLE:
    'Кажется, вы не получили SMS-сообщение с кодом. Запросите код еще раз или зарегистрируйтесь через электронную почту.',
  BUTTON_REGISTRATION_SMS_TROUBLE_SUBMIT: 'Зарегистрироваться через эл. почту',
  BUTTON_REGISTRATION_SMS_TROUBLE_CLOSE: 'Закрыть',
  ERROR_LOGIN_SMS_TROUBLE_TITLE:
    'Кажется, вы не получили SMS-сообщение с кодом. Запросите код еще раз или войдите через электронную почту.',
  BUTTON_LOGIN_SMS_TROUBLE_SUBMIT: 'Войти через эл. почту',
  BUTTON_LOGIN_SMS_TROUBLE_CLOSE: 'Закрыть',
};
