import {
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  NOTIFICATION_DEFAULT_DELAY,
} from 'application/providers/NotificationProvider';

import { TEXTS } from './texts';

export const NOTIFICATIONS = {
  ERROR: {
    type: NotificationColors.ERROR,
    children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  STOPPED: {
    type: NotificationColors.INFO,
    children: TEXTS.AD_CAMPAIGN_STOPPED,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  STARTED: {
    type: NotificationColors.INFO,
    children: TEXTS.AD_CAMPAIGN_STARTED,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  CREATE_SUCCESS: {
    type: NotificationColors.INFO,
    children: TEXTS.MODAL_AD_CREATE_SUCCESS_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  RENAME_SUCCESS: {
    type: NotificationColors.INFO,
    children: TEXTS.MODAL_AD_RENAME_SUCCESS_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  REMOVE_SUCCESS: {
    type: NotificationColors.INFO,
    children: TEXTS.MODAL_AD_REMOVE_SUCCESS_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  REPORT_CREATE_SUCCESS: {
    type: NotificationColors.INFO,
    children: TEXTS.MODAL_AD_REPORT_CREATE_SUCCESS_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  MAX_LENGTH_ERROR: {
    type: NotificationColors.ERROR,
    children: TEXTS.NAME_MAX_LENGTH_ERROR_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  CANNOT_STOP_ERROR: {
    type: NotificationColors.ERROR,
    children: TEXTS.INSTANT_AD_CANNOT_STOP_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
  COPY_SUCCESS: {
    type: NotificationColors.INFO,
    children: TEXTS.MODAL_AD_COPY_SUCCESS_MESSAGE,
    delay: NOTIFICATION_DEFAULT_DELAY,
  },
};

export const NAME_MAX_LENGTH_ERROR = 'value too long for type character';
export const INSTANT_AD_CANNOT_STOP_ERROR = /INSTANT/;
