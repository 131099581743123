import { useAdCampaignRepositoryInjector } from 'application/providers/DIContainerProvider';

const useAdLogsModalAdapter = () => {
  const { adCampaignCreateReport, adCampaignCreateReportLoading, adCampaignCreateReportErrors } =
    useAdCampaignRepositoryInjector();

  return {
    adCampaignCreateReport,
    adCampaignCreateReportLoading,
    adCampaignCreateReportErrors,
  };
};

export default useAdLogsModalAdapter;
