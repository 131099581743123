import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { PointWithDevice } from 'application/presenters/point/types';
import { TEXTS } from './texts';

import styles from './index.module.scss';
import { PLAYER_NAME_TYPE } from '../SelectPlayerModal/types';

export type ChangeDeviceWarningModalProps = {
  point: PointWithDevice;
};

const ChangeDeviceWarningModal: FC<ModalProps[ModalTypes.CHANGE_DEVICE_WARNING]> = ({ point, onNext, close }) => (
  <ModalScreen
    title={TEXTS.MODAL_TITLE}
    titlePlacement="left"
    onClose={close}
    contentClassName={styles.ChangeDeviceWarningModal}
    actionButtons={[
      <Button
        key="change_device_warning_modal_submit"
        elementId="change_device_warning_modal_submit"
        onClick={() => {
          onNext?.();
        }}>
        {TEXTS.MODAL_BUTTON_SUBMIT}
      </Button>,
      <Button
        key="change_device_warning_modal_cancel"
        elementId="change_device_warning_modal_cancel"
        type={ButtonType.SECONDARY}
        onClick={close}>
        {TEXTS.MODAL_BUTTON_CANCEL}
      </Button>,
    ]}>
    <span className={styles.ChangeDeviceWarningModalDescription}>{TEXTS.MODAL_DESCRIPTION}</span>

    <div>
      <span className={styles.ChangeDeviceWarningModalNote}>{`${TEXTS.MODAL_NOTE_ADDRESS} ${point.address}`}</span>
      {point.device?.type && (
        <span
          className={
            styles.ChangeDeviceWarningModalNote
          }>{`${TEXTS.MODAL_NOTE_DEVICE} ${PLAYER_NAME_TYPE[point.device?.type]}`}</span>
      )}
    </div>
  </ModalScreen>
);

export default ChangeDeviceWarningModal;
