import React from 'react';

import { Colors } from '@zvuk-b2b/react-uikit/ui/types';

export enum NotificationColors {
  SUCCESS = Colors.COLOR_SUCCESS_500,
  ERROR = Colors.COLOR_DANGER_500,
  WARNING = Colors.COLOR_WARNING_600,
  INFO = Colors.COLOR_NEUTRAL_800,
}

export type NotificationDataType = {
  type: NotificationColors;
  children: React.ReactNode;
  closeAfterClick?: boolean;
  delay?: number;
};

export type NotificationDataByType = Partial<Omit<NotificationDataType, 'type'>>;

export type NotificationContextType = {
  isOpen: boolean;
  data?: NotificationDataType;
  showNotification: (data: NotificationDataType) => void;
  showInfoNotification: (data: NotificationDataByType) => void;
  showSuccessNotification: (data: NotificationDataByType) => void;
  showErrorNotification: (data: NotificationDataByType) => void;
  showWarningNotification: (data: NotificationDataByType) => void;
  hideNotification: () => void;
};
