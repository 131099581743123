import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import BusinessProfileForm from 'application/pages/BusinessProfilePage/renderer/BusinessProfileForm';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import { BusinessProfileModalMode } from './types';
import useBusinessProfileUpsertModalController from './useBusinessProfileUpsertModalController';
import useBusinessProfileUpsertModalPresenter from './useBusinessProfileUpsertModalPresenter';

import styles from './index.module.scss';

const BusinessProfileUpsertModal: FC<ModalProps[ModalTypes.BUSINESS_PROFILE_UPSERT]> = ({
  mode = BusinessProfileModalMode.CREATE,
  targetBusinessProfileId,
  targetBusinessProfileUnpublished,
  close,
  onOpenImagesHint,
  onSubmit,
}) => {
  const {
    getBusinessProfileInitialFormData,
    onCreateBusinessProfile,
    businessProfileCreateLoading,
    businessProfileCreateError,
    businessProfileCreateData,
    onUpdateBusinessProfile,
    businessProfileUpdateLoading,
    businessProfileUpdateError,
    businessProfileUpdateData,
  } = useBusinessProfileUpsertModalPresenter();

  const {
    isCreateMode,
    initialFormData,
    handleSubmit,
    isLoading,
    errors,
    modalTopRef,
    modalTitle,
    submitButtonContent,
  } = useBusinessProfileUpsertModalController({
    onSubmit,
    mode,
    targetBusinessProfileId,
    targetBusinessProfileUnpublished,
    close,
    getBusinessProfileInitialFormData,
    onCreateBusinessProfile,
    businessProfileCreateLoading,
    businessProfileCreateError,
    businessProfileCreateData,
    onUpdateBusinessProfile,
    businessProfileUpdateLoading,
    businessProfileUpdateError,
    businessProfileUpdateData,
  });

  return (
    <ModalScreen
      title={modalTitle}
      titlePlacement="left"
      onClose={close}
      containerClassName={styles.BusinessProfileUpsertModalContainer}
      containerActionButtonsClassName={styles.BusinessProfileUpsertModalActionButtons}
      contentClassName={styles.BusinessProfileUpsertModalContent}>
      <div ref={modalTopRef} />
      <BusinessProfileForm
        initialFormData={initialFormData}
        containerClassName={styles.BusinessProfileUpsertModalFormContainer}
        isLoading={isLoading}
        errors={errors}
        withAcceptedPrivatePolicy={isCreateMode}
        withDirty={isCreateMode}
        withRemoveButton={isCreateMode}
        submitButtonContent={submitButtonContent}
        onOpenImagesHint={onOpenImagesHint}
        onSubmit={handleSubmit}
        onCancel={close}
      />
    </ModalScreen>
  );
};

export default BusinessProfileUpsertModal;
