import { Colors } from '@zvuk-b2b/react-uikit/ui/types';

import {
  PointPlayingStatusesValues,
  PointPlayingStatusesVisibleValues,
  PointStatuses,
  PointStatusesMap,
} from './types';
import { TEXTS } from '../texts';

export const STATUS_TO_STATUS_MAP: Record<PointPlayingStatusesValues, PointPlayingStatusesVisibleValues> = {
  [PointStatuses.playerDisabled]: PointStatusesMap.notPlaying,
  [PointStatuses.notGeneration]: PointStatusesMap.notPlaying,
  [PointStatuses.playing]: PointStatusesMap.playing,
  [PointStatuses.notPlaying]: PointStatusesMap.notPlaying,
  [PointStatuses.stop]: PointStatusesMap.notPlaying,
  [PointStatuses.sleep]: PointStatusesMap.notPlaying,
  [PointStatuses.mbNoConnection]: PointStatusesMap.mbNoConnection,
  [PointStatuses.mbPlaying]: PointStatusesMap.playing,
};

export const POINT_STATUSES_LABELS = {
  all: TEXTS.TAB_ALL_TITLE,
  [PointStatusesMap.playing]: TEXTS.TAB_PLAYING_TITLE,
  [PointStatusesMap.notPlaying]: TEXTS.TAB_NOT_PLAYING_TITLE,
  [PointStatusesMap.mbNoConnection]: TEXTS.TAB_MB_NO_CONNECTION,
};

export const POINT_STATUSES_COLORS = {
  all: undefined,
  [PointStatusesMap.playing]: Colors.COLOR_SUCCESS_500,
  [PointStatusesMap.notPlaying]: Colors.COLOR_DANGER_500,
  [PointStatusesMap.mbNoConnection]: Colors.COLOR_WARNING_500,
};

export const ALL_ALIAS = 'all';

export const VISIBLE_POINT_STATUSES = [
  PointStatusesMap.playing,
  PointStatusesMap.notPlaying,
  PointStatusesMap.mbNoConnection,
];

export const DEFAULT_STATUSES_INFO = {
  [PointStatusesMap.playing]: 0,
  [PointStatusesMap.notPlaying]: 0,
  [PointStatusesMap.mbNoConnection]: 0,
};

export const DEFAULT_POINT_STATUSES = [
  PointStatusesMap.playerDisabled,
  PointStatusesMap.notGeneration,
  PointStatusesMap.playing,
  PointStatusesMap.notPlaying,
  PointStatusesMap.stop,
  PointStatusesMap.sleep,
  PointStatusesMap.mbNoConnection,
  PointStatusesMap.mbPlaying,
];
