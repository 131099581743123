import React from 'react';

export const TEXTS = {
  POLICY_TITLE: (a: string, b: string, c: string, onClick: (value: string) => void) => (
    <>
      <span>При регистрации вы соглашаетесь с условиями </span>
      <a
        href={a}
        onClick={() => onClick(a)}
        target="_blank"
        rel="noopener noreferrer">
        публичной оферты
      </a>
      <span> и предоставляете </span>
      <a
        href={b}
        onClick={() => onClick(b)}
        target="_blank"
        rel="noopener noreferrer">
        согласие на обработку персональных данных
      </a>
      <span> в соответствии </span>
      <a
        href={c}
        onClick={() => onClick(c)}
        target="_blank"
        rel="noopener noreferrer">
        Политикой обработки персональных данных
      </a>
    </>
  ),
  POLICY_MIGRATION_TITLE: (a: string, b: string, c: string, onClick: (value: string) => void) => (
    <>
      <span>Чтобы продолжить использовать сервис, примите условия </span>
      <a
        href={a}
        onClick={() => onClick(a)}
        target="_blank"
        rel="noopener noreferrer">
        публичной оферты
      </a>
      <span> и предоставьте свое </span>
      <a
        href={b}
        onClick={() => onClick(b)}
        target="_blank"
        rel="noopener noreferrer">
        согласие на обработку персональных данных
      </a>
      <span> в соответствии </span>
      <a
        href={c}
        onClick={() => onClick(c)}
        target="_blank"
        rel="noopener noreferrer">
        Политикой обработки персональных данных
      </a>
    </>
  ),
  POLICY_SHORT_TITLE: (a: string, b: string, onClick: (value: string) => void) => (
    <>
      <span>При регистрации вы предоставляете </span>
      <a
        href={a}
        onClick={() => onClick(a)}
        target="_blank"
        rel="noopener noreferrer">
        согласие на обработку персональных данных
      </a>
      <span> в соответствии с </span>
      <a
        href={b}
        onClick={() => onClick(a)}
        target="_blank"
        rel="noopener noreferrer">
        Политикой обработки персональных данных
      </a>
    </>
  ),
  POLICY_SHORT_BY_TITLE: (a: string, onClick: (value: string) => void) => (
    <>
      <span>При регистрации вы предоставляете согласие на обработку персональных данных в соответствии с </span>
      <a
        href={a}
        onClick={() => onClick(a)}
        target="_blank"
        rel="noopener noreferrer">
        Политикой обработки персональных данных
      </a>
    </>
  ),
};
