/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import AnalyticsService, { EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';
import s from './index.module.scss';

export type GeneratedReportsModalProps = {
  message: string;
  buttonText: string;
  title: string;
  isCloseDisabled: boolean;
  notificationType: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const GeneratedReportsModal = ({
  message,
  title,
  buttonText,
  isCloseDisabled,
  notificationType,
  onConfirm,
  onCancel,
}: GeneratedReportsModalProps) => {
  const onClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    try {
      const target = evt.target as HTMLElement;
      if (target.closest('a')) {
        AnalyticsService.event(EventNameAMP.REPORT_NOTIFICATION_LINK_CLICKED, {
          [ParamNameAMP.NOTIFICATION_TYPE]: notificationType,
        });
      }
      // eslint-disable-next-line no-empty
    } catch (_err) {}
  };

  const onClickHandler = () => {
    AnalyticsService.event(EventNameAMP.REPORT_NOTIFICATION_BUTTON_CLICKED, {
      [ParamNameAMP.NOTIFICATION_TYPE]: notificationType,
    });
  };

  return (
    <ModalScreen
      title={title}
      titlePlacement="left"
      actionsPlacement="right"
      onClose={onCancel}
      hideCloseButton={isCloseDisabled}
      actionButtons={[
        <Button
          key="bind_device_confirm"
          elementId=""
          type={ButtonType.PRIMARY}
          onClick={() => {
            onClickHandler();
            onConfirm();
          }}>
          {buttonText}
        </Button>,
      ]}>
      <div
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: message }}
        className={s.text}
      />
    </ModalScreen>
  );
};

export default GeneratedReportsModal;
