import React, { Suspense } from 'react';
import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export enum CategoryParamValues {
  FOUND = 'found',
  COLLECTIONS = 'collections',
}

export type StreamSearchPageParamsType = {
  category?: CategoryParamValues;
  id?: string;
};

export const STREAM_SEARCH_PAGE_PATH = `/streams/search/:category?/:id?/`;
export const STREAM_SEARCH_PAGE_PATH_FOUND_ALIAS = `/streams/search/${CategoryParamValues.FOUND}/`;
export const STREAM_SEARCH_PAGE_PATH_COLLECTIONS_ALIAS = `/streams/search/${CategoryParamValues.COLLECTIONS}/`;

const Renderer = React.lazy(() => import('./renderer'));

const StreamSearchPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

StreamSearchPageWrapper.config = {
  routes: [
    {
      path: STREAM_SEARCH_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default StreamSearchPageWrapper;
