export enum ModalTypes {
  AD_CAMPAIGN_CONFIRM_UPDATE,
  AD_CAMPAIGN_SUCCESS_UPDATE,
  AD_CAMPAIGN_TYPE_EXPLANATION,
  AD_CAMPAIGN_CREATE,
  AD_CAMPAIGN_RENAME,
  AD_CAMPAIGN_REMOVE,
  AD_CAMPAIGN_LOGS,
  BILLING_REMOVE_CARD,
  BILLING_BIND_CARD,
  BILLING_EXTEND_SUBSCRIPTION,
  BUSINESS_PROFILE_UPSERT,
  BUSINESS_PROFILE_ADD_PLAYLIST,
  BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT,
  BUSINESS_PROFILE_IMAGES_HINT,
  BUSINESS_PROFILE_PLAYLIST_UPSERT,
  CERTIFICATE,
  CHANGE_DEVICE_WARNING,
  CONFIRM_OPERATION,
  CUSTOM,
  DENY_OPERATION,
  DESKTOP_PLAYER_BIND,
  DESKTOP_PLAYER_DOWNLOAD,
  CHOOSE_VIDEO_PLAYER,
  DEVICE_REQUESTED,
  DOWNLOAD_APP_WARNING_MODAL,
  LINKED_TAGS,
  LICENSES,
  LICENSES_UNPAID,
  POINT_UPDATE_CONFIRM,
  POINT_SET_ADS_CONFIRM,
  MOBILE_PLAYER_BIND,
  VIDEO_PLAYER_BIND,
  MOBILE_PLAYER_DOWNLOAD,
  DOWNLOAD_TV_APP,
  OFFERTA,
  PAYMENT_REQUIREMENT,
  POINT_CREATE,
  POINT_CREATE_HINT,
  POINT_CREATE_CONFIRM,
  POINT_PLAYER_CHANGE_CONFIRMATION,
  POINT_PLAYER_CHANGE_SUCCESS,
  POINT_APP_LINKS,
  PROFILE_INFO,
  PROFILE_CHANGE_PASSWORD,
  SBERBOOM_REQUEST,
  SBERBUSSINESS_REQUEST,
  SELECT_PLAYER_MODAL,
  STREAM_BIND,
  VIDEO_STREAM_BIND,
  STREAM_BIND_SUCCESS,
  VIDEO_STREAM_BIND_SUCCESS,
  SELECT_VIDEO_MEDIAFILES,
  CREATE_VIDEO_STREAM,
  STREAM_REMOVE,
  STREAM_STOP_BINDING_FLOW_WARNING,
  WEB_PLAYER_REQUEST_EXTEND_SUBSCRIPTION_SUCCESS,
  MUSIC_CUT_HELP_MODAL,
  STREAM_MAIN_TOOL_BAR_FILTERS,
  UPDATE_BROADCAST_SCHEDULE,
  SAVE_BROADCAST_SCHEDULE_CHANGES,
  BROADCAST_SCHEDULE_AD_CAMPAIGNS,
  BROADCAST_SCHEDULE_HELP_MODAL,
  ADD_NEW_YEAR_CONTENT_MODAL,
  MULTIROOM_INFO_MODAL,
}
