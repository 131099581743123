import React from 'react';

import { COMPANY_PAGE_PATH, SETTINGS_PAGE_PATH } from 'application/consts';
import CompanyPageWrapper from 'application/pages/CompanyPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const companyRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: COMPANY_PAGE_PATH,
        element: <CompanyPageWrapper />,
      },
      {
        path: SETTINGS_PAGE_PATH,
        element: <CompanyPageWrapper />,
      },
    ],
  },
];
