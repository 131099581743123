import React from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { TEXTS } from './texts';

export const enum StopWebPlayerModalType {
  TRACK,
  WIDGET,
}

interface IStopWebPlayerModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  mode?: StopWebPlayerModalType;
}

const StopWebPlayerModal: React.FC<IStopWebPlayerModalProps> = ({
  onCancel,
  onSubmit,
  mode = StopWebPlayerModalType.TRACK,
}) => {
  const content = mode === StopWebPlayerModalType.TRACK ? TEXTS.CONTENT : TEXTS.WIDGET_CONTENT;
  const submitContent = mode === StopWebPlayerModalType.TRACK ? TEXTS.LISTEN_TRACK : TEXTS.WIDGET_SUBMIT;

  return (
    <ModalScreen
      title={TEXTS.STOP_WEB_PLAYER_TITLE}
      titlePlacement="center"
      onClose={onCancel}
      actionButtons={[
        <Button
          key="button_confirm_stop_wep_player_modal"
          elementId="button_confirm_stop_wep_player_modal"
          type={ButtonType.PRIMARY}
          onClick={onSubmit}>
          {submitContent}
        </Button>,
        <Button
          key="button_cancel_stop_wep_player_modal"
          elementId="button_cancel_stop_wep_player_modal"
          type={ButtonType.SECONDARY}
          onClick={onCancel}>
          {TEXTS.CANCEL}
        </Button>,
      ]}>
      <div>{content}</div>
    </ModalScreen>
  );
};

export default StopWebPlayerModal;
