import React from 'react';
import classNames from 'classnames';

import { IdType } from 'application/pages/types';

import InfiniteItemList from '@zvuk-b2b/react-uikit/ui/InfiniteItemList';

import SelectableListWrapper from './SelectableListWrapper';
import SelectableListItemSkeleton from './SelectableListItemSkeleton';
import SelectableListHeader from './SelectableListHeader';
import { SelectableListProps, SelectableListWrapperProps } from './types';

import styles from './index.module.scss';

const SelectableList = <T extends IdType>({
  elementId,
  items,
  itemsLoading,
  isLoading = false,
  selectedItems,
  disabledItems = new Map(),
  itemKeyForRender,
  itemContent,
  labelPlacement,
  hasMultiselect = false,
  hasSelectedItems = false,
  hasUnSelectedItems = true,
  onChange,
  onChangeAll,
  className,
  loadMore,
  paginationInfo,
  totalItemsCount,
  showItemsCount,
  selectorRenderer,
  selectorDisabled = false,
}: SelectableListProps<T>) => (
  <div className={classNames(styles.SelectableList, className)}>
    {hasMultiselect && onChangeAll && (
      <SelectableListHeader
        isLoading={itemsLoading || isLoading}
        hasSelectedItems={hasSelectedItems}
        hasUnselectedItems={hasUnSelectedItems}
        totalItemsCount={totalItemsCount}
        showItemsCount={showItemsCount}
        onChangeAll={onChangeAll}
      />
    )}

    <InfiniteItemList<T, SelectableListWrapperProps<T>>
      itemListRenderer={SelectableListWrapper}
      itemListRendererProps={{
        elementId,
        items,
        itemsLoading,
        isLoading,
        itemKeyForRender,
        itemContent,
        labelPlacement,
        selectedItems,
        disabledItems,
        selectorRenderer,
        selectorDisabled,
        onChange,
      }}
      itemListSkeleton={<SelectableListItemSkeleton />}
      items={items}
      isLoading={itemsLoading || isLoading}
      hasNextPage={paginationInfo?.hasNextPage}
      loadMore={loadMore}
      totalItemsCount={totalItemsCount}
      shouldScrollAfterTotalCountChanged
    />
  </div>
);

export default SelectableList;
