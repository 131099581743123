import { FieldPolicy, Reference, TypePolicy } from 'application/providers/DataProvider';

import { SubscriptionStatus } from 'domain/billing/types';
import { InvoiceState, InvoiceType, PaymentMethodObjectPureType } from 'domain/api/graphql/generated';

import LicensesUtils from './utils';
import { InvoiceShortType } from './types';

const isBillingEnabled: FieldPolicy<boolean> = {
  read(_, { readField, variables }) {
    const isBillingEnabled = readField<boolean>('isBillingEnabled', {
      __ref: `UserCompanyPureType:${variables?.companyId}`,
    });

    return !!isBillingEnabled;
  },
};

const hasTrialLicenses: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const subscriptionStatus = readField('status');

    if (!subscriptionStatus) return false;

    return subscriptionStatus === SubscriptionStatus.TrialSubscription;
  },
};

const hasActiveLicenses: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const subscriptionStatus = readField('status');

    if (!subscriptionStatus) return false;

    return subscriptionStatus === SubscriptionStatus.ActiveSubscription;
  },
};

const hasInactiveLicenses: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const subscriptionStatus = readField('status');

    if (!subscriptionStatus) return false;

    return subscriptionStatus === SubscriptionStatus.InactiveSubscription;
  },
};

const hasWaitPaymentLicenses: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const subscriptionStatus = readField('status');

    if (!subscriptionStatus) return false;

    return subscriptionStatus === SubscriptionStatus.WaitPaymentSubscription;
  },
};

const hasGraceLicenses: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const subscriptionStatus = readField('status');

    if (!subscriptionStatus) return false;

    return subscriptionStatus === SubscriptionStatus.GraceSubscription;
  },
};

const expiresLicenseDate: FieldPolicy<string> = {
  read(_, { readField }) {
    const nonRenewableLicensesRef = readField<Reference[]>('nonRenewableLicenses');

    if (!nonRenewableLicensesRef || nonRenewableLicensesRef.length === 0) return '';

    const expiresAt = readField<string>('expiresAt', nonRenewableLicensesRef[0]);

    return expiresAt || '';
  },
};

const isLastInvoiceHasZeroAmount: FieldPolicy<boolean> = {
  read(_, { readField, variables }) {
    const invoicesRef = readField<Reference[]>('invoices', {
      __ref: `UserCompanyPureType:${variables?.companyId}`,
    });

    if (!invoicesRef || invoicesRef.length === 0) return false;

    const invoices: InvoiceShortType[] = [];

    invoicesRef.forEach((ref) => {
      const invoice = {
        id: readField<string>('id', ref) || '',
        type: readField<InvoiceType>('type', ref) || InvoiceType.Periodic,
        status: readField<InvoiceState>('status', ref) || InvoiceState.Created,
        amount: readField<number>('amount', ref) || 0,
      };

      invoices.push(invoice);
    });

    const lastInvoice = LicensesUtils.getLastPaidPeriodicInvoice(invoices);

    return lastInvoice?.amount === 0;
  },
};

const nextBillingLicenses: FieldPolicy<readonly Reference[]> = {
  read(_, { readField }) {
    const hasInactiveLicenses = readField<boolean>('hasInactiveLicenses');
    const unpaidLicenses = readField<Reference[]>('unpaidLicenses') || [];
    const renewableLicenses = readField<Reference[]>('renewableLicenses') || [];

    return hasInactiveLicenses ? unpaidLicenses : renewableLicenses;
  },
};

const totalLicenses: FieldPolicy<Reference[]> = {
  read(_, { readField }) {
    const renewableLicenses = readField<Reference[]>('renewableLicenses') || [];
    const nonRenewableLicenses = readField<Reference[]>('nonRenewableLicenses') || [];
    const unpaidLicenses = readField<Reference[]>('unpaidLicenses') || [];

    return [...renewableLicenses, ...nonRenewableLicenses, ...unpaidLicenses];
  },
};

const isWaitPaymentLicense: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const renewableLicenses = readField<Reference[]>('renewableLicenses') || [];
    const nonRenewableLicenses = readField<Reference[]>('nonRenewableLicenses') || [];
    const licenses = [...renewableLicenses, ...nonRenewableLicenses];

    if (licenses.length === 0) return false;

    return LicensesUtils.hasWaitPaymentLicense(licenses, readField);
  },
};

const isGraceLicense: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const renewableLicenses = readField<Reference[]>('renewableLicenses') || [];
    const nonRenewableLicenses = readField<Reference[]>('nonRenewableLicenses') || [];
    const licenses = [...renewableLicenses, ...nonRenewableLicenses];

    if (licenses.length === 0) return false;

    return LicensesUtils.hasGraceLicense(licenses, readField);
  },
};

const paymentMethod: FieldPolicy<PaymentMethodObjectPureType | null> = {
  read(_, { readField }) {
    const paymentMethod = readField<PaymentMethodObjectPureType>('paymentMethod', {
      __ref: 'PaymentMethodStatusType:{}',
    });

    if (!paymentMethod) return null;

    return paymentMethod;
  },
};

const canRealizeBillingOperation: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const isWaitPaymentLicense = readField<boolean>('isWaitPaymentLicense');
    const isGraceLicense = readField<boolean>('isGraceLicense');
    const hasTrialLicenses = readField<boolean>('hasTrialLicenses');
    const hasActiveLicenses = readField<boolean>('hasActiveLicenses');
    const hasInactiveLicenses = readField<boolean>('hasInactiveLicenses');
    const isBillingEnabled = readField<boolean>('isBillingEnabled');

    return (
      isBillingEnabled &&
      !isWaitPaymentLicense &&
      !isGraceLicense &&
      (hasTrialLicenses || hasActiveLicenses || hasInactiveLicenses)
    );
  },
};

const canRealizeBillingOperationWithPayment: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const isBillingEnabled = readField<boolean>('isBillingEnabled');
    const paymentMethod = readField<PaymentMethodObjectPureType>('paymentMethod');
    const isLastInvoiceHasZeroAmount = readField<boolean>('isLastInvoiceHasZeroAmount');
    const hasActiveLicenses = readField<boolean>('hasActiveLicenses');

    return isBillingEnabled && paymentMethod && !isLastInvoiceHasZeroAmount && hasActiveLicenses;
  },
};

const hasRenewableLicenses: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const renewableLicenses = readField<Reference[]>('renewableLicenses');

    return renewableLicenses?.length !== 0;
  },
};

const hasNonRenewableLicenses: FieldPolicy<boolean> = {
  read(_, { readField }) {
    const nonRenewableLicenses = readField<Reference[]>('nonRenewableLicenses');

    return nonRenewableLicenses?.length !== 0;
  },
};

const UserBillingInfo: TypePolicy = {
  keyFields: [],
  fields: {
    isBillingEnabled,
    hasTrialLicenses,
    hasActiveLicenses,
    hasInactiveLicenses,
    hasWaitPaymentLicenses,
    hasGraceLicenses,
    expiresLicenseDate,
    nextBillingLicenses,
    totalLicenses,
    isLastInvoiceHasZeroAmount,
    isWaitPaymentLicense,
    isGraceLicense,
    paymentMethod,
    canRealizeBillingOperation,
    canRealizeBillingOperationWithPayment,
    hasRenewableLicenses,
    hasNonRenewableLicenses,
  },
};

const PaymentMethodStatusType: TypePolicy = {
  keyFields: [],
};

const OrganizationPricePlanPureType: TypePolicy = {
  keyFields: ['organizationId'],
};

export { UserBillingInfo, PaymentMethodStatusType, OrganizationPricePlanPureType };
