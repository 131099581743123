import { useEffect, useState } from 'react';

import usePlayer, { TPointMetadata } from '@player-core/use-player-frame';
// TODO move type to use player
import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import { TMediaFile, TPointData } from 'application/providers/WebPlayerProvider/types';
import FileUtils from 'application/utils/FileUtils';

const useWebPlayerState = (
  state: ReturnType<typeof usePlayer>['state'],
  onCloseWidget: () => void,
  formatNotification: (text: string) => string
) => {
  const [streamId, setStreamId] = useState<string>('');
  const [pointData, setPointData] = useState<TPointData | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [currentRotationId, setCurrentRotationId] = useState<number>();
  const [mediaFile, setMediaFile] = useState<TMediaFile | null>(null);
  const [disliked, setDisliked] = useState<{ [key: string]: boolean }>({});
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [withCaching, setWithCaching] = useState<boolean>(false);
  const [firstGenerationCompletedTime, setFirstGenerationCompletedTime] = useState<number | null>(null);
  const [firstStart, setFirstStart] = useState<boolean | null>(null);

  const [pointMetadata, setPointMetadata] = useState<TPointMetadata>({
    isNoPoint: false,
    pointHasNoStream: false,
    isPointDisabled: false,
  });

  const [sleepNotification, setSleepNotification] = useState('');

  useEffect(() => {
    const imageFile = state?.pointData?.streamCover || '';
    const newStreamId = state.pointData?.streamId;

    if (newStreamId) {
      setStreamId(newStreamId);
    }

    const newPointData = state?.pointData;

    if (newPointData) {
      setPointData(newPointData);
    }

    const imageUrl = FileUtils.generateMediafileURL(imageFile);
    if (imageFile) {
      setImageUrl(imageUrl);
    }
    if (state?.currentTrack?.mediafile) {
      // TODO fixe type in use player frame
      setMediaFile(state?.currentTrack?.mediafile as any);
      setCurrentRotationId(state?.currentTrack?.rotationId);
    }

    if (state.pointMetadata.isNoPoint) {
      onCloseWidget();
    }

    setPointMetadata(state.pointMetadata);
    setFileLoading(state.isFileLoading);

    if (state.sleepInterval?.end) {
      setSleepNotification(
        formatNotification(DateUtils.getFormatDateFrom(new Date(state.sleepInterval?.end), 'HH:mm'))
      );
    } else {
      setSleepNotification('');
    }
    if (typeof state?.applicationLoadingState?.firstStart === 'boolean') {
      setFirstStart(state.applicationLoadingState.firstStart);
    }

    if (typeof state.applicationLoadingState?.firstGenerationCompletedTime === 'number') {
      setFirstGenerationCompletedTime(state.applicationLoadingState.firstGenerationCompletedTime);
    }
  }, [
    state.currentTrack,
    state.history,
    state.playState,
    state.pointData,
    state.playlist,
    state.pointMetadata.isNoPoint,
    state.pointMetadata.isPointDisabled,
    state.pointMetadata.pointHasNoStream,
    state.sleepInterval,
    state.isFileLoading,
    state.applicationLoadingState?.firstStart,
    state.applicationLoadingState?.firstGenerationCompletedTime,
  ]);

  useEffect(() => {
    setDisliked({ ...state.disliked });
    setIsConnected(state.isConnected);
  }, [state.disliked, state.isConnected]);

  useEffect(() => {
    setWithCaching(state.playerSettings.TRACK_DOWNLOADING_ENABLED);
  }, [state.playerSettings]);

  return {
    streamId,
    pointData,
    imageUrl,
    currentRotationId,
    mediaFile,
    pointMetadata,
    sleepNotification,
    disliked,
    isConnected,
    setDisliked,
    fileLoading,
    withCaching,
    firstGenerationCompletedTime,
    firstStart,
  };
};

export default useWebPlayerState;
