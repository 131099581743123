import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import CutMusicModalContent from 'application/pages/PointsPage/renderer/PointsDrawer/PointsDrawerContent/CutMusic/HelpModal/HelpModalContent';
import { ModalProps, ModalTypes, useModalContext } from 'application/providers/ModalProvider';
import classNames from 'classnames';
import React, { FC } from 'react';
import ScheduleAdTypeMarker from '../ScheduleAdTypeMarker';
import { TEXTS } from '../texts';
import styles from './index.module.scss';

const CutMusicHelpModal: FC<ModalProps[ModalTypes.BROADCAST_SCHEDULE_HELP_MODAL]> = ({ close }) => {
  const { isModalOpen } = useModalContext();

  return (
    <ModalScreen
      title={TEXTS.HELP_MODAL_TITLE}
      hideCloseButton
      onClose={close}
      disableScroll={isModalOpen}
      containerClassName={styles.HelpModalContainer}
      actionButtons={[
        <Button
          elementId="button_cut_music_help_modal"
          type={ButtonType.PRIMARY}
          onClick={close}>
          {TEXTS.HELP_OK_BUTTON}
        </Button>,
      ]}>
      <div className={styles.HelpModalInner}>
        <div>{TEXTS.HELP_TEXT_1}</div>
        <div className={styles.HelpModalItem}>
          <div className={styles.HelpModalMarker}>
            <ScheduleAdTypeMarker type="interval" />
          </div>
          <div className={styles.HelpModalText}>{TEXTS.HELP_TEXT_2}</div>
        </div>
        <div className={styles.HelpModalItem}>
          <div className={styles.HelpModalMarker}>
            <ScheduleAdTypeMarker type="exact" />
          </div>
          <div className={styles.HelpModalText}>{TEXTS.HELP_TEXT_3}</div>
        </div>
        <div className={styles.HelpModalItem}>
          <div className={styles.HelpModalMarker}>
            <ScheduleAdTypeMarker type="skipped" />
          </div>
          <div className={styles.HelpModalText}>
            <div>{TEXTS.HELP_TEXT_4}</div>
            <div className={classNames(styles.HelpModalText, styles.Grey)}>{TEXTS.HELP_TEXT_5}</div>
          </div>
        </div>
        <h3 className={styles.HelpModalTitle}>{TEXTS.HELP_TEXT_6}</h3>
        <CutMusicModalContent />
      </div>
    </ModalScreen>
  );
};

export default CutMusicHelpModal;
