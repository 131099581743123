import { useStandardizedAddressLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const usePointTimezone = () => {
  const [query, { data, loading }] = useStandardizedAddressLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (queryParams: string) => {
    const timezoneData = await query({
      variables: { input: { query: queryParams } },
    });

    return timezoneData;
  };

  const pointWithTimezone = data?.standardizedAddressPureQuery.result;

  return {
    loading,
    pointWithTimezone,
    request,
  };
};

export default usePointTimezone;
