import React, { FC } from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import IconLink from '@zvuk-b2b/react-uikit/ui/icons/Link';
import classNames from 'classnames';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { DOWNLOAD_APP_PAGE_PATH } from 'application/pages/DownloadAppPage';
import AnalyticsService, { StreamBindFlowEventNameAMP } from 'application/services/AnalyticsService';
import styles from '../index.module.scss';
import { TEXTS } from './texts';

const DEVICE_IMAGE_PATH = '/assets/img/app/mobileApp.png';
const QR_CODE_PATH = '/assets/img/app/downloadAppQr.png';

const DownloadMobileModal: FC<ModalProps[ModalTypes.MOBILE_PLAYER_DOWNLOAD]> = ({
  onPrevious,
  onConfirmClose,
  onNext,
  close,
}) => {
  const notification = useNotificationContext();

  const siteUrl = window.location.origin;
  const downloadAppFullPath = `${siteUrl}${DOWNLOAD_APP_PAGE_PATH}`;

  const onClickCopy = () => {
    navigator.clipboard.writeText(downloadAppFullPath);

    notification.showNotification({
      type: NotificationColors.INFO,
      children: TEXTS.LINK_WAS_COPIED,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  const onDownloadClick = () => {
    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_MOBILE_DOWNLOAD_CLICKED);
    onNext?.();
  };

  const handleCancel = () => {
    if (onConfirmClose) {
      onConfirmClose();
    } else {
      close();
    }
  };

  return (
    <ModalScreen
      titlePlacement="left"
      onClose={handleCancel}
      className={styles.MobilePlayerFlowModal}
      contentClassName={styles.MobilePlayerFlowModalContent}
      title={TEXTS.MODAL_TITLE}
      containerActionButtonsClassName={styles.MobilePlayerFlowModalActionButtons}
      actionButtons={[
        <Button
          key="button_mobile_player_modal_download_success"
          elementId="button_mobile_player_modal_download_success"
          type={ButtonType.PRIMARY}
          onClick={onDownloadClick}>
          {TEXTS.SUCCESS_BUTTON}
        </Button>,
        <Button
          key="button_mobile_player_modal_download_cancel"
          elementId="button_mobile_player_modal_download_cancel"
          type={ButtonType.SECONDARY}
          onClick={onPrevious}>
          {TEXTS.CANCEL_BUTTON}
        </Button>,
      ]}>
      <div className={styles.MobilePlayerFlowModalDescriptionDownload}>{TEXTS.DESCRIPTION}</div>

      <div className={styles.MobilePlayerFlowModalDownloadContent}>
        <div>
          <img
            src={QR_CODE_PATH}
            alt=""
            loading="lazy"
            className={styles.MobilePlayerFlowModalImageQr}
          />

          <Button
            type={ButtonType.PLAIN}
            className={classNames(styles.MobilePlayerFlowModalCopyButton, styles.MobilePlayerFlowModalButton)}
            onClick={onClickCopy}
            elementId="button_mobile_player_modal_copy_link">
            <div className={styles.MobilePlayerFlowModalCopyButtonIcon}>
              <IconLink />
            </div>
            <span>{TEXTS.DOWNLOAD_LINK}</span>
          </Button>
        </div>

        <img
          src={DEVICE_IMAGE_PATH}
          alt=""
          loading="lazy"
          className={styles.MobilePlayerFlowModalImageApp}
        />
      </div>
    </ModalScreen>
  );
};

export default DownloadMobileModal;
