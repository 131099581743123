import { PointWithDevice } from 'application/presenters/point/types';
import {
  PaymentMethodInfoTypeFragment,
  PointInfoTypeFragment,
  PointPureChangeStreamMutationInput,
  PointPureCreateMutationInput,
  SmartlkPointCreateMutationInput,
  StandardizedAddressPureType,
  UserFeaturesOnboardingPassMutationInput,
  UserFeaturesOnboardingType,
} from 'domain/api/graphql/generated';
import { BillingInfoType, PaymentMethodType } from 'domain/billing/types';
import { PointCreateRequestResultType } from 'domain/point/types';

export enum PointCreateModalPages {
  POINT = 'POINT',
  STREAM = 'STREAM',
}

export type PointCreateModalProps = {
  canAddPointWithPayment?: boolean;
  pointSettings?: Omit<PointPureCreateMutationInput, 'address'>;
  onSubmit?: (point: PointInfoTypeFragment) => void;
  page?: PointCreateModalPages;
  targetStreamId?: string;
  collectionId?: string;
  isTemplateMode?: boolean;
};

export type UsePointCreateModalControllerProps = PointCreateModalProps & {
  createPointError?: Error;
  createPoint: (input: SmartlkPointCreateMutationInput) => Promise<PointCreateRequestResultType>;
  getStandardizedPoints: (queryParams: string) => Promise<StandardizedAddressPureType[]>;
  onNext?: (params?: object) => void;
  close: () => void;
  userOnboardingFeatures: (UserFeaturesOnboardingType | null)[] | null | undefined;
  getExtendSubscriptionCost: (companyId: string, count: number) => Promise<number | undefined>;
  extendSubscription: (companyId: string, licensesIds: string[]) => Promise<boolean>;
  getBillingInfo: (companyId: string) => Promise<BillingInfoType>;
  hasActiveLicenses: boolean;
  getPaymentMethod: (companyId: string) => Promise<PaymentMethodType>;
  paymentMethod?: PaymentMethodInfoTypeFragment;
  extendSubscriptionCost?: number;
  hasTrialLicenses: boolean;
  page: PointCreateModalProps['page'];
  targetStreamId: PointCreateModalProps['targetStreamId'];
  collectionId: PointCreateModalProps['collectionId'];
  isTemplateMode: PointCreateModalProps['isTemplateMode'];
  changeStream: (inputs: PointPureChangeStreamMutationInput[]) => Promise<boolean>;
  copyTemplateStream: (templateId: string, companyId: string) => Promise<string | undefined>;
  onboardingPassedRequest: (input: UserFeaturesOnboardingPassMutationInput) => Promise<true | undefined>;
  getSavedStreams: (companyIds: string[], isOnlyHifi?: boolean | undefined) => Promise<void>;
  setCurrentPoint: (currentPoint: PointWithDevice) => boolean;
  readCurrentPoint: () => PointWithDevice | null;
};
