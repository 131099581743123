import React, { Suspense, useEffect } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';
import useAllPointList from 'domain/point/useAllPointList';
import { useNavigate } from 'application/providers/RouterProvider';
import { TRIAL_WARNING_PAGE_PATH } from 'application/pages/TrialWarningPage';
import { DEFAULT_POINT_STATUSES } from 'application/pages//PointsPage/renderer/PointsToolbar/consts';
import PointModelProvider from 'application/pages/PointsPage/providers/PointModelProvider';
import CompanyUtils from 'application/utils/CompanyUtils';

export const ACTIVATION_PAGE_PATH = '/activation';

const Renderer = React.lazy(() => import('./renderer'));

const ActivationPageWrapper: IApplicationPage = () => {
  const { companyIds, currentCompany } = useAccountContext();
  const allPoints = useAllPointList({ companyIds, playingStatuses: DEFAULT_POINT_STATUSES });

  const navigate = useNavigate();

  useEffect(() => {
    if (companyIds.length > 0) {
      allPoints.request();
    }
  }, [companyIds]);

  useEffect(() => {
    const isDenyCreatePoint = CompanyUtils.isDenyCreatePoint(currentCompany?.isBillingEnabled, currentCompany);

    const isNeedRedirect = !allPoints.loading && isDenyCreatePoint && allPoints.points?.length === 0;

    if (isNeedRedirect) {
      navigate(TRIAL_WARNING_PAGE_PATH);
    }
  }, [allPoints.loading, allPoints.points]);

  return (
    <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
      <PointModelProvider>
        <Renderer />
      </PointModelProvider>
    </Suspense>
  );
};

ActivationPageWrapper.config = {
  routes: [
    {
      path: ACTIVATION_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default ActivationPageWrapper;
