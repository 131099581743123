import { useMemo } from 'react';
import { usePointLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { DeviceTypeType, PointType } from './types';

const POLLING_INTERVAL = 120000;

const usePoint = () => {
  const [query, queryResult] = usePointLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    pollInterval: POLLING_INTERVAL,
  });

  const request = async (id: string) =>
    query({
      variables: { id },
    });

  const pointPureQuery = queryResult.data?.pointPure;

  const point: PointType | undefined = useMemo(() => {
    if (!pointPureQuery) return undefined;

    return {
      id: pointPureQuery.id,
      comment: pointPureQuery.comment,
      address: pointPureQuery.address,
      isEnabled: pointPureQuery.isEnabled,
      playingStatus: pointPureQuery.playingStatus,
      sleepOptions: pointPureQuery.sleepOptions,
      timezone: pointPureQuery.timezone,
      stream: pointPureQuery.stream ? pointPureQuery.stream : undefined,
      device: pointPureQuery.device
        ? {
            id: pointPureQuery.device.id,
            requiredVersionUrl: pointPureQuery.device.requiredVersionUrl || undefined,
            bindingCode: pointPureQuery.device.bindingCode,
            volume: pointPureQuery.device.volume,
            bindedAt: pointPureQuery.device.bindedAt,
            supportedFeatures: pointPureQuery.device.supportedFeatures,
            type: pointPureQuery.device.type as DeviceTypeType,
          }
        : undefined,
      license: pointPureQuery.license
        ? {
            id: pointPureQuery.license.id,
            autoRenewal: pointPureQuery.license.autoRenewal,
            paymentState: pointPureQuery.license.paymentState,
            expiresAt: pointPureQuery.license.expiresAt,
            modified: pointPureQuery.license.modified,
          }
        : undefined,
      amplifyOptions: {
        amplifyMediafile: pointPureQuery.amplifyOptions.amplifyMediafile,
        amplifyPromo: pointPureQuery.amplifyOptions.amplifyPromo,
        amplifyInstant: pointPureQuery.amplifyOptions.amplifyInstant,
      },
      tags: pointPureQuery.tags || [],
      adcampaigns: pointPureQuery.adcampaigns || [],
      withMusicCut: pointPureQuery.withMusicCut,
      cutDuration: pointPureQuery.cutDuration,
      fileFormat: pointPureQuery.fileFormat,
      videoStream: pointPureQuery.videoStream,
    };
  }, [pointPureQuery]);

  return {
    error: queryResult.error,
    loading: queryResult.loading,
    point,
    request,
  };
};

export default usePoint;
