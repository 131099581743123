import { useBillingLaunchSubscriptionWithConfirmMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useBindCardAndPay = () => {
  const [mutation, mutationResult] = useBillingLaunchSubscriptionWithConfirmMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (uriForReturn: string, companyId: string, licensesIds: string[]) => {
    try {
      const mutationResult = await mutation({
        variables: {
          input: {
            uriForReturn,
            licensesIds,
            companyId,
          },
        },
      });
      return mutationResult.data?.launchSubscriptionWithConfirm?.confirmLink;
    } catch (e) {
      return undefined;
    }
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
  };

  return {
    request,
    result,
  };
};

export default useBindCardAndPay;
