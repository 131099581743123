export const TEXTS = {
  MODAL_UPDATED_TITLE: 'Выберите тип устройства',
  MODAL_WARNING_TITLE: 'Волна уже запущена',

  MODAL_ADDRESS_TITLE: (address: string) => `На адресе: ${address}`,

  SUCCESS_BUTTON: 'Выбрать',
  SUCCESS_CHANGE_BUTTON: 'Изменить устройство',
  CANCEL_BUTTON: 'Назад',

  WEBPLAYER_TITLE: 'Веб-плеер',
  WEBPLAYER_DESCRIPTION: 'В браузере',

  DESKTOP_TITLE: 'Приложение для ПК',
  DESKTOP_DESCRIPTION: 'Для Windows',

  MOBILE_TITLE: 'Мобильное приложение',
  MOBILE_DESCRIPTION: 'iOS, Android',

  BOOM_SPEAKER_TITLE: 'Умная колонка «СберБум»',
  BOOM_SPEAKER_DESCRIPTION: 'Нужна колонка и интернет',

  SBER_BUSINESS_PLAYER_TITLE: 'Плеер «Звук Бизнес»',
  SBER_BUSINESS_PLAYER_DESCRIPTION: 'Для тех, кто не хочет разбираться — мы сами все сделаем',

  CHANGE_DEVICE_TYPE_WARNING: 'При изменении типа устройства вещание на текущем устройстве будет прервано',
  CHOSEN_DEVICE_TYPE: 'Привязано',

  ALICE_TITLE: 'Алиса',
  RPI_TITLE: 'RPI',
  MUSICBOX_TITLE: 'Musicbox',
  NETCORE_TITLE: 'Netcore',

  VIDEO_PLAYER_TITLE: 'Видео-плеер',
  VIDEO_PLAYER_DESCRIPTION: 'Для телевизоров Android TV',
};
