import React from 'react';
import classNames from 'classnames';

import DeclensionUtils, { MONTHES } from 'application/utils/DeclensionUtils';
import { LicensePaymentState } from 'domain/billing/types';
import IconAlertTriangle from '@zvuk-b2b/react-uikit/ui/icons/AlertTriangle';
import IconClock from '@zvuk-b2b/react-uikit/ui/icons/Clock';
import IconSlash from '@zvuk-b2b/react-uikit/ui/icons/Slash';

import styles from './index.module.scss';

const TEXTS = {
  BILLING_LICENSES_STATUS_UNPAID_TEXT: 'Не входит в подписку',
  BILLING_LICENSES_STATUS_GRACE_TEXT: 'Ожидает оплаты',
  BILLING_LICENSES_STATUS_PAID_TEXT: (date: string) => `Активен до ${date}`,
};

export enum LicenseStatusLabelColor {
  WARNING = 'warning',
  DANGER = 'danger',
}

export type LicenseStatusLabelProps = {
  paymentState: LicensePaymentState;
  autoRenewal: boolean;
  expiresAt: any;
  isVisible?: boolean;
};

const LicenseStatusLabel = (props: LicenseStatusLabelProps) => {
  const { paymentState, autoRenewal, expiresAt, isVisible } = props;

  const isUnpaidSubtitleVisible = paymentState === LicensePaymentState.Unpaid;
  const isPaidSubtitleVisible = paymentState === LicensePaymentState.Paid && !autoRenewal;
  const isGraceSubtitleVisible = paymentState === LicensePaymentState.Grace;
  const isWaitPaymentSubtitleVisible = paymentState === LicensePaymentState.WaitPayment;

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={styles.LicenseStatusLabel}
      data-status={isUnpaidSubtitleVisible ? LicenseStatusLabelColor.DANGER : LicenseStatusLabelColor.WARNING}>
      {isUnpaidSubtitleVisible && (
        <IconSlash className={classNames(styles.LicenseStatusLabelIcon, styles.LicenseStatusLabelIconRed)} />
      )}

      {(isGraceSubtitleVisible || isWaitPaymentSubtitleVisible) && (
        <IconClock className={classNames(styles.LicenseStatusLabelIcon, styles.LicenseStatusLabelIconYellow)} />
      )}

      {isPaidSubtitleVisible && (
        <IconAlertTriangle className={classNames(styles.LicenseStatusLabelIcon, styles.LicenseStatusLabelIconYellow)} />
      )}

      {isUnpaidSubtitleVisible && TEXTS.BILLING_LICENSES_STATUS_UNPAID_TEXT}

      {(isGraceSubtitleVisible || isWaitPaymentSubtitleVisible) && TEXTS.BILLING_LICENSES_STATUS_GRACE_TEXT}

      {isPaidSubtitleVisible &&
        TEXTS.BILLING_LICENSES_STATUS_PAID_TEXT(DeclensionUtils.getFormattedDate(expiresAt!, MONTHES))}
    </div>
  );
};

export default LicenseStatusLabel;
