import React, { useEffect } from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { HELP_LINK_BROADCASTING } from 'application/pages/constants';
import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';
import { TEXTS } from './texts';

interface IAppInstallModalProps {
  onCancel: () => void;
}

const INSTALL_APP_LINK = 'https://redirect.appmetrica.yandex.com/serve/100160811351211931?source_type=web-player';

const AppInstallModal: React.FC<IAppInstallModalProps> = ({ onCancel }) => {
  const onConfirmClick = () => {
    AnalyticsService.event(EventNameAMP.INSTALL_APP_PRESSED);
    window.open(INSTALL_APP_LINK);
  };

  useEffect(() => {
    AnalyticsService.event(EventNameAMP.INSTALL_APP_SHOWN);
  }, []);

  return (
    <ModalScreen
      title={TEXTS.APP_INSTALL_MODAL_TITLE}
      titlePlacement="center"
      onClose={onCancel}
      actionButtons={[
        <Button
          key="button_confirm_app_install_modal"
          elementId="button_confirm_app_install_modal"
          type={ButtonType.PRIMARY}
          onClick={onConfirmClick}>
          {TEXTS.APP_INSTALL_MODAL_CONFIRM}
        </Button>,
        <Button
          key="button_cancel_app_install_modal"
          elementId="button_cancel_app_install_modal"
          type={ButtonType.SECONDARY}
          onClick={onCancel}>
          {TEXTS.APP_INSTALL_MODAL_CONTINUE}
        </Button>,
      ]}>
      <div>
        {TEXTS.APP_INSTALL_MODAL_CONTENT}{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={HELP_LINK_BROADCASTING}>
          {TEXTS.APP_INSTALL_MODAL_HELP_LINK}
        </a>{' '}
      </div>
    </ModalScreen>
  );
};

export default AppInstallModal;
