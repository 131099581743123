import {
  UserFeaturesOnboardingPassMutationInput,
  useFeaturesOnboardingPassMutation,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useUserFeaturesOnboardingPass = () => {
  const [mutation, mutationResult] = useFeaturesOnboardingPassMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: UserFeaturesOnboardingPassMutationInput) => {
    const mutationResult = await mutation({
      variables: {
        input,
      },
    });
    return mutationResult?.data?.result?.ok || undefined;
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
  };

  return { request, result };
};

export default useUserFeaturesOnboardingPass;
