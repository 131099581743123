import React, { FC } from 'react';

import MediaUploader, { MediaUploaderProps } from '@zvuk-b2b/react-uikit/ui/MediaUploader';

import { usePlayerContext } from 'application/providers/PlayerProvider';
import { MediaListItem } from '../MediaList/MediaListItem';
import { MediaListItemIconsUploader } from '../MediaList/MediaListItemIcons';

import styles from './index.module.scss';

type ExtendedMediaUploaderProps = Omit<
  MediaUploaderProps,
  'renderer' | 'onPlay' | 'onPause' | 'onResume' | 'progress' | 'onChangeProgress' | 'playingMediafileData' | 'isPaused'
>;

type MediaUploaderWrapperProps = ExtendedMediaUploaderProps & {
  hintText?: string;
  mediaUploaderClassname?: string;
};

const MediaUploaderWrapper: FC<MediaUploaderWrapperProps> = (props) => {
  const {
    accept,
    buttonTitle,
    description,
    elementId,
    isLoading,
    items,
    isMediaUploaderVisible,
    multiple,
    hintText,
    onDrop,
    onRemove,
    onRetry,
    mediaUploaderClassname,
  } = props;

  const player = usePlayerContext();

  const { isPaused, progress, fileName, fileUuid, changeProgress, play, pause, resume } = player;

  return (
    <>
      {hintText && <div className={styles.MediaUploaderWrapperHint}>{hintText}</div>}
      <MediaUploader
        elementId={elementId}
        accept={accept}
        items={items}
        isLoading={isLoading}
        buttonTitle={buttonTitle}
        description={description}
        onDrop={onDrop}
        onRemove={onRemove}
        onRetry={onRetry}
        isMediaUploaderVisible={isMediaUploaderVisible}
        multiple={multiple}
        renderer={MediaListItem}
        rendererChildren={MediaListItemIconsUploader}
        onChangeProgress={changeProgress}
        onPlay={play}
        onPause={pause}
        onResume={resume}
        progress={progress}
        isPaused={isPaused}
        playingMediafileData={{ id: fileUuid, name: fileName }}
        className={mediaUploaderClassname}
      />
    </>
  );
};

export default MediaUploaderWrapper;
