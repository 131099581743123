import React from 'react';
import classnames from 'classnames';

import { LAW_CONST } from 'application/pages/constants';

import Checkbox, { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';

import { TEXTS } from './texts';

import styles from './index.module.scss';

export type MarketingAgreementProps = {
  marketingMaterialsAgreementReceived: boolean;
  setMarketingMaterialsAgreementReceived: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  className?: string;
};

const MarketingAgreement = ({
  marketingMaterialsAgreementReceived,
  setMarketingMaterialsAgreementReceived,
  isLoading,
  className,
}: MarketingAgreementProps) => (
  <Checkbox
    elementId="checkbox_marketing_mail_agreement"
    isChecked={marketingMaterialsAgreementReceived}
    labelPlacement={LabelPlacement.RIGHT}
    isStretch
    className={classnames(styles.MarketingAgreementCheckbox, className)}
    labelClassName={styles.MarketingAgreementCheckboxLabel}
    onChange={setMarketingMaterialsAgreementReceived}
    isLoading={isLoading}>
    <span>{TEXTS.MARKETING_MAIL_AGREEMENT_CHECKBOX_LABEL}</span>
    <a
      href={LAW_CONST.MARKETING_EMAIL_AGREEMENT}
      target="_blank"
      rel="noopener noreferrer">
      {TEXTS.MARKETING_MAIL_AGREEMENT_CHECKBOX_LINK_TEXT}
    </a>
  </Checkbox>
);

export default MarketingAgreement;
