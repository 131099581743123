import React from 'react';

import { ProvidersComposerProps } from './types';

const ProvidersComposer = ({ providers = [], children, ...rest }: ProvidersComposerProps) => (
  <>
    {providers.reduceRight(
      (acc, ComposingProvider) => (
        <ComposingProvider {...rest}>{acc}</ComposingProvider>
      ),
      children
    )}
  </>
);

export default ProvidersComposer;
