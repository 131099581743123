import React, { FC, useState } from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import IconChevronDown from '@zvuk-b2b/react-uikit/ui/icons/ChevronDown';
import IconChevronRight from '@zvuk-b2b/react-uikit/ui/icons/ChevronRight';
import IconLink from '@zvuk-b2b/react-uikit/ui/icons/Link';
import classNames from 'classnames';
import FileUtils from 'application/utils/FileUtils';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import AnalyticsService, {
  StreamBindFlowEventNameAMP,
  StreamBindFlowParamNameAMP,
} from 'application/services/AnalyticsService';
import styles from '../index.module.scss';
import { TEXTS } from './texts';

const appLink = 'https://ftp.muzlab.ru/updates/desktop-player/v2/latest/windows-i386/muzlab-desktop-client-3.0.2.exe';

const DownloadDesktopModal: FC<ModalProps[ModalTypes.DESKTOP_PLAYER_DOWNLOAD]> = ({
  onPrevious,
  onConfirmClose,
  onNext,
  close,
}) => {
  const [isRequirementsExtanded, setIsRequirementsExtanded] = useState(false);
  const { isMobile } = useMedia();
  const notification = useNotificationContext();

  const onClickCopy = () => {
    navigator.clipboard.writeText(appLink);

    notification.showNotification({
      type: NotificationColors.INFO,
      children: TEXTS.LINK_WAS_COPIED,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  const onDownloadClick = () => {
    if (!isMobile) {
      FileUtils.downloadExternalFile(appLink);
      notification.showNotification({
        type: NotificationColors.INFO,
        children: TEXTS.APP_WAS_DOWNLOADED,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    } else {
      notification.showNotification({
        type: NotificationColors.INFO,
        children: TEXTS.APP_WAS_NOT_DOWNLOADED,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }

    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_DESKTOP_DOWNLOAD_CLICKED, {
      [StreamBindFlowParamNameAMP.FROM_MOBILE]: isMobile,
    });

    onNext?.();
  };

  const handleCancel = () => {
    if (onConfirmClose) {
      onConfirmClose();
    } else {
      close();
    }
  };

  return (
    <ModalScreen
      titlePlacement="left"
      onClose={handleCancel}
      className={styles.DesktopPlayerFlowModal}
      contentClassName={styles.DesktopPlayerFlowModalContent}
      title={TEXTS.MODAL_TITLE}
      actionButtons={[
        <Button
          key="button_desktop_player_modal_download_success"
          elementId="button_desktop_player_modal_download_success"
          type={ButtonType.PRIMARY}
          onClick={onDownloadClick}>
          {isMobile ? TEXTS.SUCCESS_BUTTON_MOBILE : TEXTS.SUCCESS_BUTTON}
        </Button>,
        <Button
          key="button_desktop_player_modal_download_cancel"
          elementId="button_desktop_player_modal_download_cancel"
          type={ButtonType.SECONDARY}
          onClick={() => {
            onPrevious?.();
          }}>
          {TEXTS.CANCEL_BUTTON}
        </Button>,
      ]}>
      <Button
        type={ButtonType.PLAIN}
        className={classNames(styles.DesktopPlayerFlowModalExpandButton, styles.DesktopPlayerFlowModalButton)}
        onClick={() => {
          setIsRequirementsExtanded(!isRequirementsExtanded);
        }}
        elementId="button_desktop_player_modal_expand_button">
        <span>{TEXTS.INSTALLATION_GUIDE_EXPAND_BUTTON}</span>
        <div className={styles.DesktopPlayerFlowModalExpandButtonIcon}>
          {isRequirementsExtanded ? <IconChevronDown /> : <IconChevronRight />}
        </div>
      </Button>

      {isRequirementsExtanded && (
        <ul data-testid="desktop_player_modal_requirements_list">
          <li>{TEXTS.REQUIREMENTS_ITEM_ONE}</li>
          <li>{TEXTS.REQUIREMENTS_ITEM_TWO}</li>
          <li>{TEXTS.REQUIREMENTS_ITEM_THREE}</li>
          <li>{TEXTS.REQUIREMENTS_ITEM_FOUR}</li>
          <li>{TEXTS.REQUIREMENTS_ITEM_FIVE}</li>
          <li>{TEXTS.REQUIREMENTS_ITEM_SIX}</li>
        </ul>
      )}

      <Button
        type={ButtonType.PLAIN}
        className={classNames(styles.DesktopPlayerFlowModalCopyButton, styles.DesktopPlayerFlowModalButton)}
        onClick={onClickCopy}
        elementId="button_desktop_player_modal_copy_link">
        <div className={styles.DesktopPlayerFlowModalCopyButtonIcon}>
          <IconLink />
        </div>
        <span>{TEXTS.COPY_APP_LINK}</span>
      </Button>
    </ModalScreen>
  );
};

export default DownloadDesktopModal;
