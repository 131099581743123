import React, { FC } from 'react';

import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

export type PointSetAdsConfirmModalProps = {
  isLoading?: boolean;
  onSubmit: () => void;
};

const PointSetAdsConfirmModal: FC<ModalProps[ModalTypes.POINT_SET_ADS_CONFIRM]> = ({
  isLoading = false,
  onSubmit,
  close,
}) => (
  <ModalScreen
    title={TEXTS.POINT_SET_ADS_MODAL_TITLE}
    hideCloseButton
    onClose={close}
    actionButtons={[
      <Button
        key="button_confirm_point_set_ads_modal"
        elementId="button_confirm_point_set_ads_modal"
        type={ButtonType.PRIMARY}
        isLoading={isLoading}
        onClick={onSubmit}>
        {TEXTS.POINT_SET_ADS_BUTTON_TEXT}
      </Button>,
      <Button
        key="button_cancel_point_set_ads_modal"
        elementId="button_cancel_point_set_ads_modal"
        type={ButtonType.SECONDARY}
        isLoading={isLoading}
        onClick={close}>
        {TEXTS.BUTTON_POINT_SET_ADS_CANCEL_TITLE}
      </Button>,
    ]}>
    <div className={styles.PointSetAdsConfirmModalDesc}>{TEXTS.POINT_SET_ALL_ADS_MODAL_DESCRIPTION}</div>
  </ModalScreen>
);

export default PointSetAdsConfirmModal;
