import React from 'react';

import AnalyticsService from 'application/services/AnalyticsService';
import { RouteConfig } from 'application/Main/configs/routes';

import RouterProvider, {
  createBrowserRouter,
  Outlet,
  AnonymousRoute,
  PrivateRoute,
  RouterErrorBoundary,
  RedirectRoute,
} from 'application/providers/RouterProvider';
import { CompanyTagsProvider } from 'application/components/common';
import GeneratedReportsWrapper from 'application/components/GeneratedReportsModal/GeneratedReportsWrapper';
import GeneratedReportsProvider from 'application/providers/GeneratedReportsProvider';
import MenuItemsProvider from 'application/providers/MenuItemsProvider';
import ModalProvider from 'application/providers/ModalProvider';
import OverlayProvider from 'application/providers/OverlayProvider';
import ProfileProvider from 'application/providers/ProfileProvider';
import WebPlayerProvider from 'application/providers/WebPlayerProvider';
import FilesUploadProvider from 'application/providers/FilesUploadProvider';

import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

import { routerConfig } from './configs';
import useActivationFlow from './useActivationFlow';
import useAppHeightCSSVar from './useAppHeightCSSVar';
import useMenusPathnames from './useMenusPathnames';
import useUserDataChecker from './useUserDataChecker';
import useRedirect from './useRedirect';

import './index.scss';

AnalyticsService.init();

const Application = () => {
  useAppHeightCSSVar();
  useMenusPathnames();
  useActivationFlow();
  useRedirect();

  return <Outlet />;
};

const Root = React.memo(() => (
  <FilesUploadProvider>
    <ModalProvider>
      <OverlayProvider>
        <CompanyTagsProvider>
          <ProfileProvider>
            <WebPlayerProvider>
              <GeneratedReportsProvider>
                <GeneratedReportsWrapper>
                  <MenuItemsProvider>
                    <Application />
                  </MenuItemsProvider>
                </GeneratedReportsWrapper>
              </GeneratedReportsProvider>
            </WebPlayerProvider>
          </ProfileProvider>
        </CompanyTagsProvider>
      </OverlayProvider>
    </ModalProvider>
  </FilesUploadProvider>
));

const allRoutes: RouteConfig[] = [
  {
    path: '/',
    element: <Root />,
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        element: <RedirectRoute />,
        children: [
          {
            element: <PrivateRoute />,
            children: routerConfig.privateRoutes,
          },
          {
            element: <AnonymousRoute />,
            children: routerConfig.anonymousRoutes,
          },
          ...routerConfig.commonRoutes,
        ],
      },
    ],
  },
];

const router = createBrowserRouter(allRoutes, {
  basename: '/',
  future: {
    v7_relativeSplatPath: true,
  },
});

const ApplicationRouter = () => {
  const isReady = useUserDataChecker();

  if (!isReady) {
    return <LoadingScreen />;
  }

  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  );
};

export default ApplicationRouter;
