import React, { useMemo } from 'react';
import classNames from 'classnames';

import { TopBannerState } from 'application/components/widgets/TopBanner/useTopBannerState';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import { BILLING_PAGE_PATH, StateParamValues } from 'application/pages/BillingPage';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import IconX from '@zvuk-b2b/react-uikit/ui/icons/X';
import DeclensionUtils from 'application/utils/DeclensionUtils';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import { Country } from 'domain/api/graphql/generated';
import { WHATSAPP_KZ_URL } from 'application/utils/CommunicationUtils';
import styles from '../index.module.scss';
import { TEXTS } from '../texts';

const BUTTON_BACKGROUND_IMAGE_PATH = '/assets/img/banner/billing_button_background.png';

type TrialBannerProps = {
  className?: string;
  banner: TopBannerState;
  onClose: () => void;
  country: Country;
};

const TrialBanner = ({ banner, onClose, className, country }: TrialBannerProps) => {
  const navigate = useNavigate();
  const { isDesktop } = useMedia();

  const billingPageLinkTo = RouterUtils.generatePath(BILLING_PAGE_PATH, { state: StateParamValues.INFO });

  const onPayClick = () => {
    if (country === Country.Kz) {
      const link = `${WHATSAPP_KZ_URL}/?text=${TEXTS.BANNER_TRIAL_KZ_MESSAGE_TEXT}`;

      window.open(link, '_blank');
    } else {
      navigate(billingPageLinkTo);
    }
  };

  const closeButton = useMemo(
    () => (
      <Button
        elementId="button-billing-banner-close"
        className={styles.TopBannerCloseButton}
        onClick={onClose}
        type={ButtonType.PLAIN}>
        <IconX className={styles.TopBannerCloseButtonIcon} />
      </Button>
    ),
    []
  );

  return (
    <div
      className={classNames(styles.TopBanner, className)}
      data-type={banner.type}>
      <div className={styles.TopBannerDescription}>
        {`${TEXTS.BANNER_TRIAL_TEXT}`}{' '}
        <div
          className={
            styles.TopBannerDescriptionDays
          }>{`${DeclensionUtils.getFormattedDay(banner.trialDaysLeft || 0)}`}</div>
      </div>

      <div className={styles.TopBannerButtonTrialWrapper}>
        {isDesktop && (
          <img
            src={BUTTON_BACKGROUND_IMAGE_PATH}
            alt=""
            loading="lazy"
            className={styles.TopBannerButtonTrialImage}
          />
        )}

        <Button
          elementId="trial_period_banner_button_go_to_billing"
          type={ButtonType.PLAIN}
          className={classNames(styles.TopBannerButton)}
          onClick={onPayClick}>
          {TEXTS.BUTTON_GO_TO_BILLING_VARIANT_TITLE_TRIAL}
        </Button>
      </div>
      {closeButton}
    </div>
  );
};

export default TrialBanner;
