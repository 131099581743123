import { NetworkStatus } from '@apollo/client';
import {
  SmartLkLibraryStreamQuerySort,
  useLibraryCollectionWithPaginationLazyQuery,
} from 'domain/api/graphql/generated';

const PAGE_SIZE = 20;

const useSubCollectionStreams = () => {
  const [getStreams, streamsResult] = useLibraryCollectionWithPaginationLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const isStreamSearchFetching = streamsResult.networkStatus === NetworkStatus.fetchMore;
  const streamPagination = streamsResult.data?.result.paginationInfo;

  const getInitialStreams = (subcollectionId: string, companyId: string) => {
    getStreams({
      variables: {
        filters: {
          companyId,
          subcollectionId,
        },
        pagination: { page: 0, pageSize: PAGE_SIZE },
        sort: SmartLkLibraryStreamQuerySort.IdDesc,
      },
    });
  };

  const loadMore = (subcollectionId: string, companyId: string) => {
    if (isStreamSearchFetching) return;

    streamsResult.fetchMore({
      variables: {
        filters: { companyId, subcollectionId },
        pagination: {
          page: (streamPagination?.page ?? 0) + 1,
          pageSize: PAGE_SIZE,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        result: {
          paginationInfo: {
            ...prev.result.paginationInfo,
            ...fetchMoreResult.result.paginationInfo,
          },
          result: [...(prev.result.result || []), ...(fetchMoreResult.result.result || [])],
        },
      }),
    });
  };

  const subCollectionStreamsResult = streamsResult.data?.result;
  const isLoading = streamsResult.loading;

  return {
    loadMore,
    getInitialStreams,
    subCollectionStreamsResult,
    isLoading,
  };
};

export default useSubCollectionStreams;
