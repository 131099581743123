import React, { useEffect, useState } from 'react';
import { PaginationInfoType } from 'domain/api/graphql/generated';
import InView from 'react-intersection-observer';
import ItemsListSkeleton from 'application/components/ItemsListSkeleton';
import ChooseItemListItem, { SelectorType } from './ChooseItemListItem';
import PointItemContent from '../../PointItemContent';
import { PointsListCheckboxGroupPointType } from '../../PointItemContent/types';
import styles from '../index.module.scss';

export type ChooseItemListType = {
  onChange: (value: any, currentItem: PointsListCheckboxGroupPointType) => void;
  itemList: Array<PointsListCheckboxGroupPointType>;
  selectedItems: Array<PointsListCheckboxGroupPointType>;
  selectorType: SelectorType;
  isLoading: boolean;
  isReloading?: boolean;
  paginationSettings?: PaginationInfoType;
  loadMore?: () => void;
  showStreamData?: boolean;
  loadedItemsCount?: number;
};

const ChooseItemList = ({
  itemList,
  onChange,
  selectorType,
  isLoading,
  selectedItems,
  loadMore,
  paginationSettings,
  showStreamData,
  isReloading,
  loadedItemsCount = 0,
}: ChooseItemListType) => {
  const [showSkeleton, setShowSkeleton] = useState(isLoading);
  const totalCount = paginationSettings?.totalCount || 0;

  useEffect(() => {
    if (!isLoading && !isReloading) {
      setTimeout(() => {
        // for smooth disappearance
        setShowSkeleton(false);
      }, 1000);
    }
  }, [isLoading, isReloading, paginationSettings]);

  return (
    <>
      {itemList.map((currentItem: PointsListCheckboxGroupPointType) => (
        <ChooseItemListItem
          // key={currentItem.id} // TODO: with keys items keep previous items, needs to investigate it
          isChecked={selectedItems.map((selectedItem) => selectedItem.id).includes(currentItem.id)}
          currentItem={currentItem}
          onChange={(value: boolean) => onChange(value, currentItem)}
          selectorType={selectorType}
          isLoading={isLoading || isReloading}
          isDisabled={isReloading || isLoading}>
          <PointItemContent
            showStreamData={showStreamData}
            isLoading={isLoading}
            currentItem={currentItem}
          />
        </ChooseItemListItem>
      ))}

      {paginationSettings?.hasNextPage && (
        <InView
          onChange={(inView) => inView && loadMore?.()}
          key="infinite_medialist_hidden_loading_zone"
        />
      )}

      {showSkeleton && (
        <ItemsListSkeleton
          isHidden={totalCount <= loadedItemsCount}
          totalCount={totalCount || 0}
          hasNextPage={paginationSettings?.hasNextPage}
          items={itemList || []}
          className={styles.SearchItems}>
          <ChooseItemListItem
            isChecked={false}
            currentItem={{} as PointsListCheckboxGroupPointType}
            onChange={() => {}}
            selectorType={selectorType}
            isDisabled>
            <PointItemContent
              showStreamData={showStreamData}
              isLoading={false}
              currentItem={{} as PointsListCheckboxGroupPointType}
            />
          </ChooseItemListItem>
        </ItemsListSkeleton>
      )}
    </>
  );
};

export default ChooseItemList;
