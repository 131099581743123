import React from 'react';

import IconRenderer, { IconProps } from 'ui/icons/IconRenderer';

import icon from './volume-1.svg';

const IconVolume1 = ({ className, style }: IconProps) => (
  <IconRenderer
    iconHtml={icon}
    className={className}
    style={style}
  />
);

export default IconVolume1;
