import React, { useEffect } from 'react';
import { useForm, SubmitHandler, FieldValues, Path } from 'react-hook-form';
import classNames from 'classnames';

import Button, { ButtonType } from 'ui/Button';

import { FormProps } from './types';
import { TEXTS } from './texts';

import { TextField, TextAreaField, ImageUploaderField, CheckboxField } from './fields';

import styles from './index.module.scss';

const Form = <T extends FieldValues>({
  elementId,
  className,
  submitButtonClassName,
  cancelButtonClassName,
  submitButtonContent = TEXTS.SUBMIT_BUTTON_DEFAULT_TEXT,
  cancelButtonContent = TEXTS.CANCEL_BUTTON_DEFAULT_TEXT,
  submitButtonType = ButtonType.PRIMARY,
  cancelButtonType = ButtonType.SECONDARY,
  isLoading,
  withDirty = true,
  disabled,
  mode = 'all',
  defaultValues,
  values,
  onSubmit,
  onCancel,
  handleFieldDirtyChange,
  children,
  onChange,
}: FormProps<T>) => {
  const { handleSubmit, control, formState, clearErrors, resetField, setValue, getValues } = useForm<T>({
    defaultValues,
    mode,
    values,
  });

  const handleFormSubmit: SubmitHandler<T> = (data) => onSubmit(data);

  useEffect(() => {
    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        if (value === undefined) {
          resetField(key as Path<T>);
        }
      });
    }
  }, [values]);

  const onFieldChange = (field: object) => {
    onChange?.(setValue, getValues, field);
  };

  const onHandleFieldDirtyChange = (field: keyof T, value: boolean) => {
    handleFieldDirtyChange?.(field, value, getValues);
  };

  return (
    <form
      id={`form_${elementId}`}
      data-testid={`form_${elementId}`}
      className={classNames(styles.Form, className)}
      onSubmit={handleSubmit(handleFormSubmit)}>
      {React.Children.map(children, (child) =>
        child.type !== 'div'
          ? React.cloneElement(child, {
              control,
              isLoading,
              clearErrors,
              handleFieldDirtyChange: onHandleFieldDirtyChange,
              onFieldChange,
            })
          : child
      )}

      <div>
        <Button
          elementId={`button_submit_form_${elementId}`}
          className={classNames(styles.FormSubmitButton, submitButtonClassName)}
          type={submitButtonType}
          isDisabled={isLoading || disabled || !formState.isValid || (withDirty && !formState.isDirty)}
          isLoading={isLoading}
          onClick={handleSubmit(handleFormSubmit)}>
          {submitButtonContent}
        </Button>

        {onCancel && (
          <Button
            elementId={`button_cancel_form_${elementId}`}
            className={classNames(styles.FormCancelButton, cancelButtonClassName)}
            type={cancelButtonType}
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onCancel}>
            {cancelButtonContent}
          </Button>
        )}
      </div>
    </form>
  );
};

Form.TextField = TextField;
Form.TextAreaField = TextAreaField;
Form.ImageUploaderField = ImageUploaderField;
Form.CheckboxField = CheckboxField;

export default Form;
