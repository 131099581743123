import React from 'react';

import IconRenderer, { IconProps } from 'ui/icons/IconRenderer';

import icon from './grid.svg';

const IconGrid = ({ className, style }: IconProps) => (
  <IconRenderer
    iconHtml={icon}
    className={className}
    style={style}
  />
);

export default IconGrid;
