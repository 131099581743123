import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import { MONTHS } from '@zvuk-b2b/react-uikit/toolkit/constants';

import { AdLogsDate } from './types';

class AdLogsModalUtils {
  static getDates = (): AdLogsDate[] => {
    const result = [];
    for (let i = 0; i < 12; i++) {
      const m = DateUtils.subMonths(DateUtils.NOW, i);
      const start = DateUtils.getStartOfMonth(m);
      const end = DateUtils.getEndOfMonth(m);
      const value = `${start.getMonth()}`;
      const label = `${MONTHS[start.getMonth()]} ${start.getFullYear()}`;
      result[i] = {
        start,
        end,
        label,
        value,
      };
    }
    return result;
  };
}

export default AdLogsModalUtils;
