import React from 'react';

import CleanLayout from 'application/layout/CleanLayout';
import ActivationPageWrapper from 'application/pages/ActivationPage';
import { ACTIVATION_PAGE_PATH } from 'application/consts';

import { RouteConfig } from '..';

export const activationRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: ACTIVATION_PAGE_PATH,
        element: <ActivationPageWrapper />,
      },
    ],
  },
];
