import React from 'react';

import { TEXTS } from '../texts';
import {
  BUSINESS_PROFILE_INFORMATION_AGREEMENT_LINK,
  BUSINESS_PROFILE_PERSONAL_DATA_AGREEMENT_LINK,
  BUSINESS_PROFILE_SERVICE_AGREEMENT_LINK,
} from '../consts';

import styles from './index.module.scss';

const Agreement = () => (
  <div className={styles.AgreementContainer}>
    <span>{TEXTS.BUSINESS_PROFILE_FORM_CONFIRM_TEXT}</span>
    <a
      href={BUSINESS_PROFILE_SERVICE_AGREEMENT_LINK}
      target="_blank"
      rel="noopener noreferrer">
      {TEXTS.BUSINESS_PROFILE_FORM_TERM_SERVICE}
    </a>
    <a
      href={BUSINESS_PROFILE_PERSONAL_DATA_AGREEMENT_LINK}
      target="_blank"
      rel="noopener noreferrer">
      {TEXTS.BUSINESS_PROFILE_FORM_PERSONAL_DATA}
    </a>
    <span>{TEXTS.BUSINESS_PROFILE_FORM_AND}</span>
    <a
      href={BUSINESS_PROFILE_INFORMATION_AGREEMENT_LINK}
      target="_blank"
      rel="noopener noreferrer">
      {TEXTS.BUSINESS_PROFILE_FORM_INFO}
    </a>
  </div>
);

export default Agreement;
