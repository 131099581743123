import { AdCampaignPureCreateMutationInput, useMediaAdCampaignCreateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import TimezoneUtils from '@zvuk-b2b/react-uikit/toolkit/TimezoneUtils';

import {
  UseAdCampaignCreateResultType,
  UseAdCampaignCreateType,
  AdCampaignType,
  AdCampaignTypeEnum,
  WeekDaysValues,
} from './types';

const days = [
  WeekDaysValues.Monday,
  WeekDaysValues.Tuesday,
  WeekDaysValues.Wednesday,
  WeekDaysValues.Thursday,
  WeekDaysValues.Friday,
  WeekDaysValues.Saturday,
  WeekDaysValues.Sunday,
];

const getInitialCampaignInput = (name: string, promoIds: string[], companyId: string) => ({
  companyId,
  title: name,
  type: AdCampaignTypeEnum.Interval,
  interval: 30,
  period: 30,
  startCompany: TimezoneUtils.getDateTimeWithoutTimezone(DateUtils.getStartOfDay(DateUtils.NOW)),
  endCompany: TimezoneUtils.getDateTimeWithoutTimezone(DateUtils.getEndOfDay(DateUtils.NOW)),
  startDayTime: '00:00',
  endDayTime: '23:59',
  days,
  pointIds: [],
  prevMediafileRequired: false,
  promos: promoIds.map((promoId) => ({
    promoId,
    promoAdId: '',
  })),
});

const useAdCampaignCreate = (): UseAdCampaignCreateType => {
  const [createMutation, createMutationResult] = useMediaAdCampaignCreateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (
    name: string,
    promoIds: string[],
    companyId: string,
    overrideValues: Partial<AdCampaignPureCreateMutationInput> = {}
  ) => {
    const newAdCampaign = await createMutation({
      variables: {
        input: {
          ...getInitialCampaignInput(name, promoIds, companyId),
          ...overrideValues,
        },
      },
      refetchQueries: ['mediaAdCampaignsPaginationQuery'],
    });

    return newAdCampaign.data?.result?.adCompany as AdCampaignType;
  };

  const result: UseAdCampaignCreateResultType = {
    error: createMutationResult.error,
    loading: createMutationResult.loading,
    called: createMutationResult.called,
    data: {
      adCampaign: createMutationResult.data?.result?.adCompany as AdCampaignType,
    },
  };

  return [request, result];
};

export default useAdCampaignCreate;
