import {
  ACTIVATION_PAGE_PATH,
  ACTIVATION_SUCCESS_PAGE_PATH,
  ACTIVATION_TRIAL_WARNING_PAGE_PATH,
  STREAMS_STREAM_CATEGORY_BY_ID_PATH,
  INVITE_PAGE_PATH,
  POINTS_PAGE_PATH,
  COMPANY_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  BILLING_PAGE_PATH,
  ADS_PAGE_PATH,
  CONTRACT_MIGRATION_PAGE_PATH,
  REVIEW_STREAM_PAGE_PATH,
} from 'application/consts';

export const AVAILABLE_TO_REDIRECT_ACTIVATION_FLOW_PATHS: string[] = [
  ACTIVATION_PAGE_PATH,
  ACTIVATION_SUCCESS_PAGE_PATH,
  ACTIVATION_TRIAL_WARNING_PAGE_PATH,
];

export const AVAILABLE_TO_REDIRECT_PATHS: string[] = [
  STREAMS_STREAM_CATEGORY_BY_ID_PATH,
  INVITE_PAGE_PATH,
  POINTS_PAGE_PATH,
  COMPANY_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  BILLING_PAGE_PATH,
  ADS_PAGE_PATH,
  CONTRACT_MIGRATION_PAGE_PATH,
  REVIEW_STREAM_PAGE_PATH,
];
