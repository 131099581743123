import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import PrivacyPolicy, { PrivacyPolicyMode } from 'application/components/PrivacyPolicy';
import { useAccountContext } from 'application/providers/AccountProvider';
import { PHONE_NUMBER_COUNTRY_CODES } from 'application/pages/constants';
import Delimiter from '@zvuk-b2b/react-uikit/ui/Delimiter';

import { useFeatureFlagsContext } from 'application/providers/FeatureFlagsProvider';
import useCaptchaSettings from 'domain/auth/useCaptchaSettings';
import CodeInputForm, { CodeInputFormErrors } from './CodeInputForm';
import PhoneInputForm, { PhoneInputFormErrors } from './PhoneInputForm';
import LegacyAuthForm, { LegacyAuthFormErrors } from './LegacyAuthForm';
import AuthMethodList from './AuthMethodList';
import useAuthMethods from './useAuthMethods';
import useAuthForm from './useAuthForm';
import { TEXTS } from './texts';

import { AuthFormProps, AuthFormStep, AuthFormMode } from './types';

import styles from './index.module.scss';
import { YandexCaptcha } from '../YandexCaptcha';

const getPolicyMode = (countryCode: string) => {
  if (countryCode === PHONE_NUMBER_COUNTRY_CODES.BY) {
    return PrivacyPolicyMode.SHORT;
  }

  if (countryCode === PHONE_NUMBER_COUNTRY_CODES.KZ) {
    return PrivacyPolicyMode.SIGN_UP_KZ;
  }

  return PrivacyPolicyMode.SIGN_UP;
};

const AuthForm = (props: AuthFormProps) => {
  const { className, isSBBIDAvailable, mode } = props;
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [resetCaptchaKey, setResetCaptchaKey] = useState(0);

  const { captchaSettings, request } = useCaptchaSettings();

  useEffect(() => {
    request();
  }, []);

  const resetCaptcha = () => {
    if (!isCaptchaVisible) {
      setResetCaptchaKey((prev) => prev + 1);
    }
  };

  useEffect(() => {
    resetCaptcha();
  }, [isCaptchaVisible]);

  const supportCaptchaCountriesArray = captchaSettings?.countries.map((country) => `${country}`) || [];

  const { showCaptcha } = useFeatureFlagsContext();

  const authForm = useAuthForm(props);
  const { currentCompany } = useAccountContext();

  const availableAuthMethods = useAuthMethods(authForm.mode, authForm.step, !!isSBBIDAvailable);
  const isAuthMethodListVisible = availableAuthMethods.length > 0;

  const policyMode = getPolicyMode(authForm.phone.country as string);

  const getCaptchStatus = useCallback(
    (country: string) => showCaptcha && captchaSettings?.enabled && supportCaptchaCountriesArray?.includes(country),
    [showCaptcha, captchaSettings?.enabled, supportCaptchaCountriesArray]
  );

  const onPhoneInputFormSubmit = (phone: string, country: string) => {
    const isCaptchEnabled = getCaptchStatus(country);

    if (isCaptchEnabled) {
      authForm.validatePhone(phone, () => {
        setIsCaptchaVisible(true);
        authForm.onSetPhone(phone);
      });
    } else {
      authForm.onPhoneSubmit(phone, country);
    }
  };

  const onSuccessCaptcha = (token?: string) => {
    if (authForm.phone.country && authForm.step === AuthFormStep.PHONE_INPUT) {
      authForm.onPhoneSubmit(authForm.phone.value, authForm.phone.country, token);
    }

    if (authForm.step === AuthFormStep.CODE_INPUT) {
      authForm.onCodeRequest(authForm.phone.value, token);
    }

    setIsCaptchaVisible(false);
  };

  const onCodeRequestWithCaptcha = () => {
    const isCaptchEnabled = getCaptchStatus(authForm.phone.country || '');

    if (isCaptchEnabled) {
      setIsCaptchaVisible(true);
    } else {
      authForm.onCodeRequest(authForm.phone.value);
    }
  };

  return (
    <div className={classNames(styles.AuthForm, className)}>
      {authForm.step === AuthFormStep.PHONE_INPUT && (
        <PhoneInputForm
          className={styles.AuthFormPhoneInputForm}
          isLoading={authForm.isCodeLoading || authForm.isLoading}
          initData={authForm.initData}
          errors={authForm.errors as PhoneInputFormErrors}
          inputLabel={TEXTS.INPUT_PHONE_TITLE}
          inputPlaceholder={TEXTS.INPUT_PHONE_PLACEHOLDER}
          buttonContent={TEXTS.BUTTON_CONTINUE_TITLE}
          onSubmit={onPhoneInputFormSubmit}
        />
      )}

      {authForm.step === AuthFormStep.CODE_INPUT && (
        <CodeInputForm
          className={styles.AuthFormCodeInputForm}
          isLoading={authForm.isLoading}
          isCodeLoading={authForm.isCodeLoading}
          isCodeResendDisabled={!!authForm.codeCountdown}
          errors={authForm.errors as CodeInputFormErrors}
          inputLabel={TEXTS.INPUT_CODE_TITLE}
          inputPlaceholder={TEXTS.INPUT_CODE_PLACEHOLDER}
          submitButtonContent={mode === AuthFormMode.REGISTER ? TEXTS.BUTTON_REGISTER_TITLE : TEXTS.BUTTON_LOGIN_TITLE}
          requestCodeButtonContent={
            authForm.codeCountdown
              ? TEXTS.BUTTON_RESEND_SEC_CODE_TITLE(authForm.codeCountdown)
              : TEXTS.BUTTON_RESEND_CODE_TITLE
          }
          onSubmit={authForm.onCodeSubmit}
          onRequestCode={onCodeRequestWithCaptcha}
        />
      )}

      {authForm.step === AuthFormStep.LEGACY_AUTH && (
        <LegacyAuthForm
          className={styles.AuthFormLegacyAuthForm}
          isLoading={authForm.isLoading}
          initData={authForm.initData}
          errors={authForm.errors as LegacyAuthFormErrors}
          isPhoneAvailable={mode === AuthFormMode.REGISTER && !authForm.phone.value}
          isRegisterMode={mode === AuthFormMode.REGISTER}
          inputEmailLabel={TEXTS.INPUT_EMAIL_TITLE}
          inputEmailPlaceholder={TEXTS.INPUT_EMAIL_PLACEHOLDER}
          inputPasswordLabel={TEXTS.INPUT_PASSWORD_TITLE}
          inputPasswordPlaceholder={TEXTS.INPUT_PASSWORD_PLACEHOLDER}
          inputPhoneLabel={TEXTS.INPUT_PHONE_TITLE}
          inputPhonePlaceholder={TEXTS.INPUT_PHONE_PLACEHOLDER}
          buttonContent={mode === AuthFormMode.REGISTER ? TEXTS.BUTTON_REGISTER_TITLE : TEXTS.BUTTON_LOGIN_TITLE}
          forgotLinkLabel={mode === AuthFormMode.LOGIN ? TEXTS.BUTTON_FORGOT_TITLE : undefined}
          onSubmit={authForm.onLegacyFormSubmit}
        />
      )}

      {isAuthMethodListVisible && (
        <>
          <Delimiter
            className={styles.AuthFormHR}
            title={mode === AuthFormMode.LOGIN ? TEXTS.DELIMITER_LOGIN_TITLE : TEXTS.DELIMITER_REGISTER_TITLE}
          />
          <AuthMethodList
            mode={mode || AuthFormMode.REGISTER}
            methods={availableAuthMethods}
            onSelect={authForm.onChangeAuthMethod}
          />
        </>
      )}

      {mode === AuthFormMode.REGISTER && authForm.step !== AuthFormStep.PHONE_INPUT && (
        <PrivacyPolicy
          mode={policyMode}
          country={currentCompany?.country}
        />
      )}

      <YandexCaptcha
        isVisible={isCaptchaVisible}
        setIsVisible={setIsCaptchaVisible}
        successCallback={onSuccessCaptcha}
        yandexErrorCallback={onSuccessCaptcha}
        captchaKey={resetCaptchaKey}
        resetCaptcha={resetCaptcha}
      />
    </div>
  );
};

export default AuthForm;
