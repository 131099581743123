import React from 'react';

import SBBIDButton from 'application/components/SBBIDButton';
import { AuthFormMode } from 'application/components/AuthForm/types';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import IconPhone from '@zvuk-b2b/react-uikit/ui/icons/Phone';
import IconMail from '@zvuk-b2b/react-uikit/ui/icons/Mail';

import classNames from 'classnames';
import styles from './index.module.scss';

import { TEXTS } from './texts';

export enum AuthMethodType {
  SBBID = 'SBBID',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

const getAuthMethod = (type: AuthMethodType, mode: AuthFormMode, onClick: (type: AuthMethodType) => void) => {
  switch (type) {
    case AuthMethodType.SBBID:
      return (
        <SBBIDButton
          elementId="button_sbbid"
          key="button_sbbid"
          withModal={mode === AuthFormMode.REGISTER}
          className={styles.AuthMethodListSberIdButton}
          title={TEXTS.BUTTON_SBBID_TITLE}
        />
      );
    case AuthMethodType.EMAIL:
      return (
        <Button
          elementId="button_method_email"
          key="button_method_email"
          className={styles.AuthMethodListExpandButton}
          type={ButtonType.SECONDARY}
          onClick={() => onClick(AuthMethodType.EMAIL)}>
          <IconMail className={styles.AuthMethodListExpandButtonIcon} />
          {TEXTS.BUTTON_EMAIL_TITLE}
        </Button>
      );
    case AuthMethodType.PHONE:
    default:
      return (
        <Button
          elementId="button_method_phone"
          key="button_method_phone"
          className={styles.AuthMethodListExpandButton}
          type={ButtonType.SECONDARY}
          onClick={() => onClick(AuthMethodType.PHONE)}>
          <IconPhone className={styles.AuthMethodListExpandButtonIcon} />
          {TEXTS.BUTTON_PHONE_TITLE}
        </Button>
      );
  }
};

export type AuthMethodListProps = {
  methods: AuthMethodType[];
  mode: AuthFormMode;
  className?: string;
  onSelect: (type: AuthMethodType) => void;
};

const AuthMethodList = (props: AuthMethodListProps) => {
  const { mode, methods, className, onSelect } = props;

  return (
    <div className={classNames(styles.AuthMethodList, className)}>
      {methods.map((method) => getAuthMethod(method, mode, onSelect))}
    </div>
  );
};

export default AuthMethodList;
