import React, { FC, useMemo } from 'react';

import { TEXTS } from 'application/pages/AdsPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Input from '@zvuk-b2b/react-uikit/ui/Input';

import AdLogsDateSelect from './AdLogsDateSelect';
import useAdLogsModalController from './useAdLogsModalController';
import useAdLogsModalAdapter from './useAdLogsModalAdapter';

import styles from './index.module.scss';

const AdLogsModal: FC<ModalProps[ModalTypes.AD_CAMPAIGN_LOGS]> = ({ id, close }) => {
  const { adCampaignCreateReport, adCampaignCreateReportLoading, adCampaignCreateReportErrors } =
    useAdLogsModalAdapter();

  const {
    value,
    setValue,
    dates,
    recipientEmail,
    recipientEmailError,
    onSubmitButtonClick,
    onChangeRecipientEmail,
    onFocusRecipientEmail,
  } = useAdLogsModalController({ id, close, adCampaignCreateReport, adCampaignCreateReportErrors });

  const items = useMemo(
    () =>
      dates.map((item) => ({
        content: (
          <span
            className={styles.AdLogsModalItem}
            data-selected={value === item.value}>
            {item.label}
          </span>
        ),
        key: `select_ad_type_${item.label}`,
        onClick: () => {
          setValue(item.value);
        },
      })),
    [dates]
  );

  return (
    <ModalScreen
      title={TEXTS.MODAL_AD_REPORT_CREATE_TITLE}
      disableScroll={false}
      titlePlacement="left"
      onClose={close}
      actionButtons={[
        <Button
          key="button_ad_campaign_create_report_modal"
          elementId="button_ad_campaign_create_report_modal"
          type={ButtonType.PRIMARY}
          onClick={onSubmitButtonClick}
          isLoading={adCampaignCreateReportLoading}>
          {TEXTS.MODAL_AD_REPORT_CREATE_BUTTON}
        </Button>,
      ]}>
      <AdLogsDateSelect
        containerClassName={styles.AdLogsModalInput}
        items={items}
        value={dates.find((item) => item.value === value)?.label || ''}
      />

      <Input
        elementId="recipient_email_create_report_modal"
        label={TEXTS.MODAL_AD_REPORT_CREATE_RECIPIENT_EMAIL_LABEL}
        required
        placeholder={TEXTS.MODAL_AD_REPORT_CREATE_RECIPIENT_EMAIL_PLACEHOLDER}
        error={recipientEmailError}
        value={recipientEmail}
        onChange={onChangeRecipientEmail}
        onFocus={onFocusRecipientEmail}
      />
    </ModalScreen>
  );
};

export default AdLogsModal;
