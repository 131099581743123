import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useRef } from 'react';

const useDisableBodyScroll = () => {
  const previousScrollPosition = useRef(0);

  return {
    disableBodyScroll: (node: HTMLElement) => {
      disableBodyScroll(node, {
        allowTouchMove: (_el) => true,
      });
    },
    enableBodyScroll: (node: HTMLElement) => {
      if (node) {
        enableBodyScroll(node);
      }
      clearAllBodyScrollLocks();
    },
    saveScrollPosition: () => {
      previousScrollPosition.current = document.documentElement.scrollTop || document.body.scrollTop;
    },
    applyPreviousScroll: () => {
      document.documentElement.scrollTop = previousScrollPosition.current;
    },
  };
};

export default useDisableBodyScroll;
