import { useMemo } from 'react';

import { useCompanyUpdateMutation, UserCompanyUpdateMutationInput } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { CompanyUpdateResultType, UseCompanyUpdateType } from './types';

const useCompanyUpdate = (): UseCompanyUpdateType => {
  const [mutation, mutationResult] = useCompanyUpdateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: UserCompanyUpdateMutationInput) => {
    const mutationResult = await mutation({
      variables: { input },
    });

    return {
      ok: !!mutationResult.data?.userCompanyUpdate?.ok,
      company: mutationResult.data?.userCompanyUpdate?.company!,
      errors: mutationResult.data?.userCompanyUpdate?.errors,
    };
  };

  const clearErrors = () => {
    if (mutationResult.data && mutationResult.data.userCompanyUpdate) {
      mutationResult.data.userCompanyUpdate.errors = undefined;
    }
  };

  const responseData = mutationResult.data?.userCompanyUpdate;
  const resultData = useMemo(() => {
    const result: CompanyUpdateResultType['data'] = { ok: responseData?.ok };

    const hasErrors = !!responseData?.errors;

    if (hasErrors) {
      result.errors = GraphQLApiUtils.errorsArrayToObject(responseData?.errors);
    }

    return result;
  }, [responseData?.ok, responseData?.errors]);

  const result: CompanyUpdateResultType = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    data: resultData,
  };

  return {
    request,
    result,
    clearErrors,
  };
};

export default useCompanyUpdate;
