import { useAuthBusinessSpheresLazyQuery, BusinessSpheresQuerySort } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useBusinessSpheres = () => {
  const [query, queryResult] = useAuthBusinessSpheresLazyQuery({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async () => {
    await query({
      variables: {
        pagination: {
          page: 0,
          pageSize: 100,
        },
        sort: BusinessSpheresQuerySort.TitleAsc,
      },
    });
  };

  return {
    request,
    error: queryResult.error,
    loading: queryResult.loading,
    businessSpheres: queryResult.data?.result?.result,
  };
};

export default useBusinessSpheres;
