export const TEXTS = {
  BUSINESS_PROFILE_ADD_PLAYLIST_MODAL_TITLE: 'Добавить плейлист в бизнес-профиль',

  BUSINESS_PROFILE_ADD_PLAYLIST_FROM: 'Откуда плейлист?',
  BUSINESS_PROFILE_ADD_PLAYLIST_FROM_ZVUK_BUSINESS: 'Звук Бизнес',
  BUSINESS_PROFILE_ADD_PLAYLIST_FROM_ZVUK: 'Звук',

  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_LABEL: 'Выберите плейлист',
  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_PLACEHOLDER: 'Начните вводить название плейлиста',
  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_PLAYING_TEXT: 'Сейчас играет',
  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_FALLBACK:
    'Пока нет прослушанных волн в Звук Бизнесе. Запустите нужный плейлист в разделе «Волны», а затем снова начните добавлять плейлист в бизнес-профиле, и сможете выбрать прослушанный.',

  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_LABEL: 'Ссылка на плейлист из Звука',
  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_PLACEHOLDER: 'Вставьте ссылку...',
  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_HINT: 'Где найти ссылку?',
  BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_ERROR: 'Ссылка недействительна',

  BUSINESS_PROFILE_ADD_PLAYLIST_BUTTON_TEXT: 'Продолжить',
  BUSINESS_PROFILE_ADD_PLAYLIST_BUTTON_FALLBACK_TEXT: 'Перейти в Волны',
  BUSINESS_PROFILE_CANCEL_BUTTON_TEXT: 'Отменить',

  BUSINESS_PROFILE_ADD_PLAYLIST_SUCCESS_TEXT: 'Плейлист добавлен',
};
