import { TEXTS } from 'application/pages/BillingPage/renderer/texts';
import { useModalContext } from 'application/providers/ModalProvider';
import { useNotificationContext } from 'application/providers/NotificationProvider';

const useCardInformationController = ({ removeCard }: { removeCard: () => Promise<boolean> }) => {
  const { closeModal } = useModalContext();
  const notification = useNotificationContext();

  const removeCardSubmit = async () => {
    try {
      const isOk = await removeCard();

      if (isOk) {
        notification.showInfoNotification({
          children: TEXTS.BILLING_REMOVE_CARD_NOTIFICATION_MESSAGE,
        });

        closeModal();
      }
    } catch (e) {
      notification.showErrorNotification({
        children: TEXTS.BILLING_REMOVE_CARD_NOTIFICATION_ERROR_MESSAGE,
      });
    }
  };

  return { removeCardSubmit };
};

export default useCardInformationController;
