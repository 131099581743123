import React from 'react';

import { DOWNLOAD_APP_PAGE_PATH } from 'application/consts';
import DownloadAppPage from 'application/pages/DownloadAppPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const downloadAppRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: DOWNLOAD_APP_PAGE_PATH,
        element: <DownloadAppPage />,
      },
    ],
  },
];
