import {
  AuthCurrentUserDocument,
  useAuthCodeCreateMutation,
  useAuthCodeTokenMutation,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { AuthTokenType, UserDataType } from './types';

const usePhoneLogin = () => {
  const [mutationCode, mutationCodeResult] = useAuthCodeCreateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const [mutationAuth, mutationAuthResult] = useAuthCodeTokenMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const requestCode = async (phone: string, captchaToken?: string | null) => {
    const additionalHeaders = captchaToken
      ? {
          headers: {
            'X-Captcha-Token': captchaToken,
          },
        }
      : undefined;

    const mutationResult = await mutationCode({
      variables: { input: { phone } },
      context: additionalHeaders,
    });

    return {
      ok: mutationResult.data?.authorizationCodeCreate?.ok,
    };
  };

  const requestToken = async (username: string, code: string) => {
    const mutationResult = await mutationAuth({
      variables: { input: { username, code } },
      update: (cache, result) => {
        cache.writeQuery({
          id: `UserExtendPureType:${result.data?.jwtTokenAuthWithCode?.user.id}`,
          query: AuthCurrentUserDocument,
          data: {
            result,
          },
        });
      },
    });

    return {
      ok: !!mutationResult.data?.jwtTokenAuthWithCode?.ok,
      token: mutationResult.data?.jwtTokenAuthWithCode?.token.value as AuthTokenType,
      user: mutationResult.data?.jwtTokenAuthWithCode?.user as UserDataType,
    };
  };

  return {
    code: {
      loading: mutationCodeResult.loading,
      error: mutationCodeResult.error,
      data: {
        codeData: mutationCodeResult.data?.authorizationCodeCreate?.codeData,
      },
      requestCode,
    },
    token: {
      loading: mutationAuthResult.loading,
      error: mutationAuthResult.error,
      requestToken,
    },
  };
};

export default usePhoneLogin;
