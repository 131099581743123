import {
  useBusinessProfilePlaylistUpdateMutation,
  ZvukPlaylistUpdateMutationInput,
  ZvukProfileListDocument,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { BusinessProfileUpdatePlaylistRequestResultType, UseBusinessProfilePlaylistUpdateType } from './types';

const useBusinessProfilePlaylistUpdate = (): UseBusinessProfilePlaylistUpdateType => {
  const [mutation, mutationResult] = useBusinessProfilePlaylistUpdateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukPlaylistUpdateMutationInput) => {
    const mutationData = await mutation({
      variables: {
        input,
      },
      refetchQueries: [ZvukProfileListDocument],
    });

    return {
      isOk: !!mutationData.data?.zvukPlaylistUpdate?.ok,
      playlist: mutationData.data?.zvukPlaylistUpdate?.playlist,
    };
  };

  const zvukProfilePlaylistUpdateData = mutationResult.data?.zvukPlaylistUpdate;
  const hasErrors = !!zvukProfilePlaylistUpdateData?.errors;
  const zvukProfilePlaylistUpdateResultData = {
    ok: zvukProfilePlaylistUpdateData?.ok,
    errors: hasErrors ? GraphQLApiUtils.errorsArrayToObject(zvukProfilePlaylistUpdateData?.errors) : undefined,
  };

  const result: BusinessProfileUpdatePlaylistRequestResultType = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    data: zvukProfilePlaylistUpdateResultData,
  };

  return [request, result];
};

export default useBusinessProfilePlaylistUpdate;
