import { ADS_PAGE_PATH_ALIAS } from 'application/pages/AdsPage';
import { useNotificationContext } from 'application/providers/NotificationProvider';
import { useNavigate } from 'application/providers/RouterProvider';

import { NOTIFICATIONS } from '../consts';
import { UseAdRemoveModalControllerProps } from './types';

const useAdRemoveModalController = ({
  id,
  close,
  removeAdCampaign,
  clearAdCampaigns,
}: UseAdRemoveModalControllerProps) => {
  const notification = useNotificationContext();
  const navigate = useNavigate();

  const onAdCampaignRemove = async () => {
    try {
      const okRemove = await removeAdCampaign(id);

      if (!okRemove) {
        throw new Error();
      }

      navigate(ADS_PAGE_PATH_ALIAS);

      notification.showNotification(NOTIFICATIONS.REMOVE_SUCCESS);

      clearAdCampaigns();
      close();
    } catch (e) {
      notification.showNotification(NOTIFICATIONS.ERROR);
    }
  };

  return {
    onAdCampaignRemove,
  };
};

export default useAdRemoveModalController;
