import React from 'react';
import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import PointItemContent from 'application/components/PointItemContent';

import { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';
import PointListCheckboxItem from './PointListCheckboxItem';

export type PointListCheckboxProps = React.PropsWithChildren<{
  onChange: (value: any, currentItem: PointsListCheckboxGroupPointType) => void;
  itemList: Array<PointsListCheckboxGroupPointType>;
  selectedItems: Array<PointsListCheckboxGroupPointType>;
  isLoading: boolean;
  showStreamData?: boolean;
  labelPlacement?: LabelPlacement;
  className?: string;
  enableItemHint?: React.ReactNode;
  parentRef?: React.RefObject<HTMLElement>;
}>;

const PointListCheckbox: React.FC<PointListCheckboxProps> = ({
  itemList,
  onChange,
  isLoading,
  selectedItems,
  showStreamData,
  children,
  labelPlacement = LabelPlacement.LEFT,
  className,
  enableItemHint,
  parentRef,
}) => (
  <>
    {itemList.map((currentItem: PointsListCheckboxGroupPointType) => (
      <PointListCheckboxItem
        key={currentItem.id}
        isChecked={selectedItems.map((selectedItem) => selectedItem.id).includes(currentItem.id)}
        currentItem={currentItem}
        onChange={(value: boolean) => onChange(value, currentItem)}
        isLoading={isLoading}
        disabled={isLoading}
        labelPlacement={labelPlacement}>
        <PointItemContent
          className={className}
          showStreamData={showStreamData}
          isLoading={isLoading}
          currentItem={currentItem}
          disabled={currentItem.isDisabled}
          enableItemHint={enableItemHint}
          parentRef={parentRef}
        />
      </PointListCheckboxItem>
    ))}

    {children}
  </>
);

export default PointListCheckbox;
