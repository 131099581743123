import React, { useEffect, useState, useRef } from 'react';
import { useDebounceFn } from 'ahooks';

import { useAccountContext } from 'application/providers/AccountProvider';
import InteractiveErrorToaster from 'application/components/InteractiveErrorToaster';
import { ItemShortType } from 'application/components/SelectableList/types';
import { STREAMS_CATEGORY_PATH, StreamsCategoryParamValues } from 'application/pages/StreamPage';
import { NotificationColors, useNotificationContext } from 'application/providers/NotificationProvider';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import AnalyticsService, {
  EventNameGTM,
  ParamNameAMP,
  ParamNameGTM,
  StreamBindFlowEventNameAMP,
} from 'application/services/AnalyticsService';
import CommunicationUtils from 'application/utils/CommunicationUtils';
import { FN_OPTIONS } from 'application/pages/constants';
import { TEXTS } from 'application/pages/texts';
import { PointWithDevice } from 'application/presenters/point/types';

import { PointPureChangeStreamMutationInput, PointShortTypeFragment, UserFeatures } from 'domain/api/graphql/generated';

import { StreamBindModalFilterType, UseStreamBindModalControllerProps } from './types';

const useStreamBindModalController = ({
  points,
  targetStreamId,
  collectionId,
  isTemplateMode,
  pointFromLocation,
  onFilterExecute,
  copyTemplateStream,
  changeStream,
  getSavedStreams,
  getStream,
  onboardingPassedRequest,
  refetchFeaturesOnboarding,
  setCurrentPoint,
  readCurrentPoint,
  readStreamWithThematic,
}: UseStreamBindModalControllerProps) => {
  const { currentCompany, companyIds } = useAccountContext();

  const notifications = useNotificationContext();

  const [selectedPoints, setSelectedPoints] = useState<Map<string, ItemShortType>>(new Map());
  const [disabledPoints, setDisabledPoints] = useState<Map<string, ItemShortType>>(new Map());
  const [searchText, setSearchText] = useState<string>('');
  const [isBindLoading, setIsBindLoading] = useState<boolean>(false);

  const pointIdAsSingleResultInSearch = useRef<string | undefined>();

  const navigate = useNavigate();

  const isStreamBindButtonDisabled = !points.find(
    (point) => selectedPoints.has(point.id) && !disabledPoints.has(point.id)
  );

  const onExecute = async (filters: StreamBindModalFilterType) => {
    await onFilterExecute?.(filters);
  };

  const { run: debouncedSearchRequest } = useDebounceFn((value: string) => {
    if (pointIdAsSingleResultInSearch.current) {
      const newSelectedPoints: Map<string, ItemShortType> = new Map(selectedPoints);
      newSelectedPoints.delete(pointIdAsSingleResultInSearch.current);
      setSelectedPoints(newSelectedPoints);
    }

    onExecute?.({
      query: value,
    });
  }, FN_OPTIONS);

  useEffect(() => {
    const newSelectedPoints: Map<string, ItemShortType> = new Map(selectedPoints);
    const newDisabledPoints: Map<string, ItemShortType> = new Map(disabledPoints);
    const pointsLength = points.length;

    if (pointsLength === 1) {
      pointIdAsSingleResultInSearch.current = points[0].id;
    }

    points.forEach((point) => {
      const isEqualCurrentStream = point.stream?.id === targetStreamId;
      const isSelected =
        point.id === pointFromLocation?.pointId ||
        isEqualCurrentStream ||
        selectedPoints.has(point.id) ||
        pointsLength === 1;
      const isDisabled = isEqualCurrentStream || disabledPoints.has(point.id);

      if (isSelected) {
        newSelectedPoints.set(point.id, { id: point.id });
      } else {
        newSelectedPoints.delete(point.id);
      }

      if (isDisabled) {
        newDisabledPoints.set(point.id, { id: point.id });
      } else {
        newDisabledPoints.delete(point.id);
      }
    });

    setSelectedPoints(newSelectedPoints);
    setDisabledPoints(newDisabledPoints);
  }, [points, targetStreamId, pointFromLocation]);

  const onInitialSearchChange = async (value: string) => {
    setSearchText(value);
    await onExecute({ query: pointFromLocation?.pointId });
  };

  const onSearchChange = (value: string) => {
    if (value.length === 0 && pointFromLocation?.pointId) {
      navigate('.', { state: null, replace: true });
    }

    setSearchText(value);
    debouncedSearchRequest(value);
  };

  const showCopyStreamWithThematicError = () => {
    notifications.showErrorNotification({ children: TEXTS.CUSTOM_CONTENT_MODAL_STREAMS_NOTIFICATION_ERROR });
  };

  const showCopyStreamWithThematicSuccess = () => {
    notifications.showInfoNotification({ children: TEXTS.CUSTOM_CONTENT_MODAL_STREAMS_NOTIFICATION_SUCCESS });
  };

  const showCopyStreamError = () => {
    notifications.showNotification({
      type: NotificationColors.ERROR,
      children: (
        <InteractiveErrorToaster
          onSubmit={() => {
            CommunicationUtils.connectWithManager(currentCompany?.country);
            notifications.hideNotification();
          }}
          onClose={() => {
            notifications.hideNotification();
          }}
          title={TEXTS.SET_STREAM_ERROR_TITLE}
          submitButtonTitle={TEXTS.SET_STREAM_ERROR_ACTION}
          closeButtonTitle={TEXTS.SET_STREAM_ERROR_CLOSE}
          elementId="set_stream_error"
        />
      ),
      closeAfterClick: false,
    });
  };

  const onPointChange = (value: boolean, point: PointShortTypeFragment) => {
    const newSelectedPoints = new Map(selectedPoints);

    if (value) {
      newSelectedPoints.set(point.id, { id: point.id });
    } else {
      newSelectedPoints.delete(point.id);
    }

    setSelectedPoints(newSelectedPoints);
    pointIdAsSingleResultInSearch.current = undefined;
  };

  const handleChangeStream = async (streamId: string) => {
    const withThematic = readStreamWithThematic();
    const requestInputs: PointPureChangeStreamMutationInput[] = [];

    points.forEach((point) => {
      if (selectedPoints.has(point.id)) {
        requestInputs.push({
          newStreamId: streamId!,
          pointId: point.id,
          withThematic,
        });
      }
    });

    await changeStream(requestInputs);
  };

  const onBindPointsClick = async () => {
    let streamId = targetStreamId;
    const withThematic = readStreamWithThematic();

    if (isTemplateMode) {
      try {
        streamId = (await copyTemplateStream(streamId!, currentCompany!.id, withThematic))!;

        if (withThematic) {
          showCopyStreamWithThematicSuccess();
        }
      } catch (error) {
        if (withThematic) {
          showCopyStreamWithThematicError();
          return;
        }

        showCopyStreamError();
        return;
      }
    }

    await handleChangeStream(streamId!);
    await getSavedStreams(companyIds);

    if (isTemplateMode) {
      navigate(
        RouterUtils.generatePath(STREAMS_CATEGORY_PATH, {
          category: StreamsCategoryParamValues.SAVED,
          collectionId: 0,
          id: streamId!,
        })
      );
    }

    AnalyticsService.eventGTM(EventNameGTM.STREAM_START, {
      [ParamNameGTM.ACCOUNT_CREATED_DATE]: currentCompany?.created,
    });

    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_BOUND, {
      [ParamNameAMP.STREAM_ID]: streamId,
      [ParamNameAMP.COLLECTION_ID]: collectionId,
    });

    if (targetStreamId) {
      await getStream(targetStreamId);
    }

    const oldPoint = readCurrentPoint();
    setCurrentPoint({ ...oldPoint, stream: { id: streamId || '' } } as PointWithDevice);
  };

  const handleClick = async () => {
    setIsBindLoading(true);

    await onBindPointsClick();

    const isOk = await onboardingPassedRequest({ feature: UserFeatures.WarningStreamFirstStart });

    if (!isOk) {
      throw new Error();
    }

    await refetchFeaturesOnboarding();

    setIsBindLoading(false);
  };

  return {
    selectedPoints,
    disabledPoints,
    searchText,
    isStreamBindButtonDisabled,
    isStreamBindButtonLoading: isBindLoading,
    onInitialSearchChange,
    onSearchChange,
    handleClick,
    onPointChange,
  };
};

export default useStreamBindModalController;
