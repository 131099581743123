/* eslint-disable no-console */
import { ZVUK_ANALYTIC_ENV } from 'application/consts';

import { RegistrationSource } from 'domain/api/graphql/generated';
import { GOAL_NAME_ENUM, GTMWindow, YMWindow } from './types';
import {
  EventNameAMPType,
  EventNameAMP,
  ParamNameAMP,
  BusinessProfileEventNameAMP,
  BusinessProfileParamNameAMP,
  PointEventNameAMP,
  PointParamNameAMP,
  AdCampaignEventNameAMP,
  AdCampaignParamNameAMP,
  StreamBindFlowParamNameAMP,
  StreamBindFlowEventNameAMP,
  BillingEventNameAMP,
  BroadcastForecastEventNameAMP,
  BroadcastForecastParamNameAMP,
  BroadcastForecastStatus,
  YandexCaptchaEventNameAMP,
  ThematicEventNameAMP,
  PointQuality,
} from './ampTypes';

import { EventNameGTM, ParamNameGTM } from './eventTypes/gtm';

export {
  EventNameAMP,
  ParamNameAMP,
  EventNameGTM,
  ParamNameGTM,
  BusinessProfileEventNameAMP,
  BusinessProfileParamNameAMP,
  PointEventNameAMP,
  PointParamNameAMP,
  AdCampaignEventNameAMP,
  AdCampaignParamNameAMP,
  StreamBindFlowParamNameAMP,
  StreamBindFlowEventNameAMP,
  BillingEventNameAMP,
  BroadcastForecastEventNameAMP,
  BroadcastForecastParamNameAMP,
  BroadcastForecastStatus,
  YandexCaptchaEventNameAMP,
  ThematicEventNameAMP,
  PointQuality,
};

export enum PathValues {
  SIGNUP = 'signup',
  LOGIN = 'login',
}

export enum StreamTypeValues {
  TEMPLATE = 'template',
  SAVED = 'saved',
}

export enum LoginPathValues {
  DEFAULT = 'default',
  SBBID = 'sbbid',
  WELCOME_EMAIL = 'welcome_email',
}

export enum SignUpSourceValues {
  INVITE = 'invite',
  SBBOL = 'sbbol',
  SBBID = 'sbbid',
  DEFAULT = 'default',
}

export const enum PlatformValues {
  ANDROID = 'android',
  IOS = 'ios',
  DESKTOP = 'desktop',
  UNKNOWN = 'unknown',
}

export const enum PlayerView {
  WIDGET = 'widget',
  PAGE = 'page',
}

export const RegistrationSourceValueMap = {
  [RegistrationSource.SbBusinessId]: SignUpSourceValues.SBBID,
  [RegistrationSource.SbOnline]: SignUpSourceValues.SBBOL,
};

export enum SourceValues {
  YCLIENTS = 'yclients',
  SITE = 'site',
  SITE_IFRAME = 'site_iframe',
  RKEEPER = 'rkeeper',
  MYBEAST = 'mybeast',
  MOBILE = 'mobile',
  TTS_BOT = 'tts_bot',
  ALICE = 'alice',
}

export enum CalledFromValues {
  NAV = 'nav',
  TRIAL_BANNER = 'trial_banner',
  STREAM_BC_SUCCESS = 'stream_bc_success',
  POINT_PAGE = 'point_page',
  STREAM_PAGE = 'stream_page',
  BILLING_PAGE = 'billing_page',
  OFERTA_ACCEPTING = 'oferta_accepting',
  COMPANY_PAGE = 'company_page',
  USER_MENU = 'user_menu',
  SIGN_UP_PAGE = 'sign_up_page',
}

export enum PageNames {
  POINT = 'address',
  AD_CAMPAIGN = 'ad campaign',
}

export enum OperationStatuses {
  SUCCESS = 'success',
  ERROR = 'error',
}

class AnalyticsService {
  static yaId: string = '89756199';

  static ym?: YMWindow['ym'];

  static userId: string | null = null;

  static isEnabled: boolean = ZVUK_ANALYTIC_ENV !== 'development';

  private static withYMEventListener(callback: () => void) {
    if (!AnalyticsService.ym) {
      document.addEventListener(`yacounter${this.yaId}inited`, callback, { once: true });
      return;
    }

    callback();
  }

  static init() {
    const assignYmFunction = () => {
      AnalyticsService.ym = (window as YMWindow).ym;
    };

    AnalyticsService.withYMEventListener(assignYmFunction);
  }

  static setUserId(value?: string, isTest?: boolean) {
    if (!AnalyticsService.isEnabled) {
      console.log('AnalyticsService.setUserId', { value });
      return;
    }

    if (value) {
      const setYMUserId = () => AnalyticsService.ym?.(this.yaId, GOAL_NAME_ENUM.SET_USER_ID, value);

      AnalyticsService.withYMEventListener(setYMUserId);

      (window as GTMWindow).dataLayer?.push({
        event: EventNameGTM.USER_ID_RECEIVED,
        [ParamNameGTM.USER_ID]: value,
        [ParamNameGTM.IS_TEST]: isTest,
      });
    }
  }

  static setUserProperties(data?: Record<string, any>) {
    if (!AnalyticsService.isEnabled) {
      console.log('AnalyticsService.userParams', { data });
      return;
    }

    if (data) {
      const setYMUserProps = () => AnalyticsService.ym?.(this.yaId, GOAL_NAME_ENUM.SET_USER_PARAMS, data);

      AnalyticsService.withYMEventListener(setYMUserProps);
    }
  }

  static event(eventName: EventNameAMPType, data: Record<string, any> = {}) {
    if (!AnalyticsService.isEnabled) {
      console.log('AnalyticsService.event', { eventName, data });
      return;
    }
    try {
      const sendYMEvent = () => AnalyticsService.ym?.(this.yaId, GOAL_NAME_ENUM.REACH_GOAL, eventName, data);

      AnalyticsService.withYMEventListener(sendYMEvent);
    } catch (e) {
      console.log(e);
    }
  }

  static eventGTM(event: EventNameGTM, data?: Record<string, any>) {
    if (!AnalyticsService.isEnabled) {
      console.log('AnalyticsService.eventGTM', { event, data });
      return;
    }

    (window as GTMWindow).dataLayer?.push({ event, ...data });
  }
}

export default AnalyticsService;
