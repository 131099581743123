import { ZVUK_ALICE_AUTH_SERVER_URI } from 'application/consts';
import { RouterUtils } from 'application/providers/RouterProvider';
import RequestService from 'application/services/RequestService';

export enum ActivationTypes {
  MOBILE = 'MOBILE',
  ALICE = 'ALICE',
}

export enum MobileSourceTypes {
  APK = 'apk',
  EVOTOR = 'evotor',
  HUAWEI = 'huawei',
  IOS = 'ios',
  GOOGLE = 'google',
}

type AliceParams = {
  state: string | null;
  redirectUri: string | null;
  responseType: string | null;
  clientId: string | null;
  scope: string | null;
};

type ActivationServiceContext = {
  activationType: ActivationTypes;
  fullRedirectUri?: string;
  bindingCode?: string;
  fromSource?: MobileSourceTypes;
  activatedPointAddress?: string;
  aliceParams?: AliceParams;
};

type AuthYandexParams = {
  deviceToken: string;
  deviceType: number;
  deviceUuid: string;
};

type AuthYandexResult = {
  fullRedirectUri: string;
};

const CONTEXT_KEY = 'activationContext';

class ActivationService {
  static async authYandex(data: AuthYandexParams): Promise<AuthYandexResult | null> {
    const { aliceParams } = this.getContext();

    if (!aliceParams?.redirectUri || !ZVUK_ALICE_AUTH_SERVER_URI) return null;

    try {
      const authRequestUrl = RouterUtils.generatePath(ZVUK_ALICE_AUTH_SERVER_URI, undefined, {
        client_id: aliceParams?.clientId || '',
        redirect_uri: aliceParams?.redirectUri || '',
        device_token: data.deviceToken || '',
        device_type: data.deviceType || '',
        device_uuid: data.deviceUuid || '',
      });

      const authRequestResult = await RequestService.get(authRequestUrl, {
        method: 'GET',
        redirect: 'follow',
      });

      const authToken = await authRequestResult.text();

      const fullRedirectUri = RouterUtils.generatePath(aliceParams.redirectUri, undefined, {
        code: authToken,
        state: aliceParams.state || '',
        client_id: aliceParams.clientId || '',
        scope: aliceParams.scope || '',
      });

      this.saveAliceFullRedirectUri(fullRedirectUri);

      return {
        fullRedirectUri,
      };
    } catch (e) {
      return null;
    }
  }

  static saveAliceFullRedirectUri(fullRedirectUri: string) {
    const context = this.getContext();

    const newContext = {
      ...context,
      fullRedirectUri,
    };

    sessionStorage.setItem(CONTEXT_KEY, JSON.stringify(newContext));
  }

  static getAliceFullRedirectUri(): string | undefined {
    return this.getContext().fullRedirectUri;
  }

  static enableActivationFlow(bindingCode: string | null, fromSource: string | null, aliceParams?: AliceParams) {
    const context: ActivationServiceContext = {
      bindingCode: bindingCode || '',
      fromSource: (fromSource as MobileSourceTypes) ?? undefined,
      activationType: bindingCode ? ActivationTypes.MOBILE : ActivationTypes.ALICE,
    };

    if (aliceParams) {
      context.aliceParams = aliceParams;
    }

    this.saveContext(context);
  }

  static isActivationFlow(): Boolean {
    const context = this.getContext();

    return !!context.activationType;
  }

  static saveBindingCode(bindingCode: string): void {
    const activationContext = this.getContext();

    const newContext = {
      ...activationContext,
      bindingCode,
    };

    sessionStorage.setItem(CONTEXT_KEY, JSON.stringify(newContext));
  }

  static resetContext(): void {
    sessionStorage.setItem(CONTEXT_KEY, JSON.stringify({}));
  }

  static saveActivatedPointAddress(activatedPointAddress: string): void {
    const activationContext = this.getContext();

    const newContext = {
      ...activationContext,
      activatedPointAddress,
    };

    sessionStorage.setItem(CONTEXT_KEY, JSON.stringify(newContext));
  }

  static getActivatedPointAddress(): string | undefined {
    return this.getContext().activatedPointAddress;
  }

  static getActivationType(): ActivationTypes {
    return this.getContext().activationType;
  }

  static getBindingCode(): string | undefined {
    return this.getContext().bindingCode;
  }

  static getFromSource(): MobileSourceTypes | undefined {
    return this.getContext().fromSource;
  }

  private static saveContext(newContext: ActivationServiceContext): void {
    sessionStorage.setItem(CONTEXT_KEY, JSON.stringify(newContext));
  }

  private static getContext(): ActivationServiceContext {
    const context = sessionStorage.getItem(CONTEXT_KEY);
    return context ? JSON.parse(context) : {};
  }

  static closeBindingFlow(): void {
    sessionStorage.removeItem(CONTEXT_KEY);
  }
}

export default ActivationService;
