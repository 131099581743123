import React from 'react';

import { REVIEW_STREAM_PAGE_PATH } from 'application/consts';
import ReviewStreamPageWrapper from 'application/pages/ReviewStreamPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const reviewRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: REVIEW_STREAM_PAGE_PATH,
        element: <ReviewStreamPageWrapper />,
      },
    ],
  },
];
