import React from 'react';
import { HELP_LINK_CERTIFICATE } from 'application/pages/constants';

export const TEXTS = {
  CHOOSE_PONTS_DESCRIPTION: `Сертификат подтверждает наличие лицензии на прослушивание 
  музыкальных произведений по выбранным адресам организации.`,
  CHOOSE_PONTS_HELP_DESCRIPTION: (
    <>
      Сформированный cертификат можно направить в РАО <span>и/или</span> ВОИС.
      <a
        href={`${HELP_LINK_CERTIFICATE}`}
        target="_blank"
        rel="noopener noreferrer">
        {' '}
        Подробнее
      </a>
    </>
  ),
  CHOOSE_PONTS_COLLAPSE_BUTTON: 'Свернуть',
  CHOOSE_PONTS_MOBILE_DESCRIPTION: `Сертификат подтверждает наличие лицензии на прослушивание 
    музыкальных произведений... `,
  CHOOSE_POINTS_EXPAND_BUTTON: 'Дальше',
};
