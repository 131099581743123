export const TEXTS = {
  MODAL_TITLE: 'Привязка приложения',
  TITLE_DESCRIPTION: 'Свяжите адрес и ваше устройство — откройте приложение Windows «Звук Бизнес» и введите код. ',

  CODE_BIND_TITLE: 'Код из приложения',
  CODE_BIND_PLACEHOLDER: '4 символа',

  INSTALLATION_GUIDE_EXPAND_BUTTON: 'Установка и активация',
  INSTALLATION_GUIDE_ITEM_ONE:
    'Запустите скачанный файл и дождитесь окончания установки. Окно плеера откроется автоматически с кодом привязки.',
  INSTALLATION_GUIDE_ITEM_TWO: 'Введите код привязки указанный в плеере в это модальное окно.',
  INSTALLATION_GUIDE_ITEM_THREE:
    'После активации начнется скачивание треков, это может занять некоторое время. Чтобы увидеть прогресс скачивания треков — нажмите на значок ⚙️ в верхней части экрана плеера.',
  INSTALLATION_GUIDE_ITEM_FOUR: 'Музыка заиграет автоматически, когда все треки будут загружены.',

  SUCCESS_BUTTON: 'Привязать устройство',
  CANCEL_BUTTON: 'Назад',
};
