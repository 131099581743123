import { useMemo } from 'react';

import { useStreamLibraryLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useGetStreamLibrary = () => {
  const [query, queryResult] = useStreamLibraryLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });
  const request = async (companyId: string, libraryId: string) => {
    await query({
      variables: {
        companyId,
        libraryId,
      },
    });
  };

  const streamLibrary = queryResult?.data?.result || undefined;

  return useMemo(
    () => ({
      loading: queryResult?.loading,
      called: queryResult?.called,
      error: queryResult?.error,
      request,
      streamLibrary,
    }),
    [streamLibrary, queryResult?.loading, queryResult?.error]
  );
};

export default useGetStreamLibrary;
