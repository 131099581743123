import {
  useBusinessProfileZvukPlaylistInfoMutation,
  ZvukPlaylistInfoMutationInput,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { BusinessProfileZvukPlaylistInfoRequestResultType, UseBusinessProfileZvukPlaylistInfoType } from './types';

const useBusinessProfileZvulPlaylistInfo = (): UseBusinessProfileZvukPlaylistInfoType => {
  const [zvukPlaylistInfoMutation, zvukPlaylistInfoMutationResult] = useBusinessProfileZvukPlaylistInfoMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukPlaylistInfoMutationInput) => {
    const zvukPLaylistInfoResult = await zvukPlaylistInfoMutation({
      variables: {
        input,
      },
    });

    return {
      isOk: !!zvukPLaylistInfoResult.data?.zvukPlaylistInfo?.ok,
      playlist: zvukPLaylistInfoResult.data?.zvukPlaylistInfo?.playlist,
    };
  };

  const zvukPlaylistInfoData = zvukPlaylistInfoMutationResult.data?.zvukPlaylistInfo;
  const hasErrors = !!zvukPlaylistInfoData?.errors;
  const zvukPPlaylistInfoResultData = {
    ok: zvukPlaylistInfoData?.ok,
    errors: hasErrors ? GraphQLApiUtils.errorsArrayToObject(zvukPlaylistInfoData?.errors) : undefined,
  };

  const result: BusinessProfileZvukPlaylistInfoRequestResultType = {
    error: zvukPlaylistInfoMutationResult.error,
    loading: zvukPlaylistInfoMutationResult.loading,
    called: zvukPlaylistInfoMutationResult.called,
    data: zvukPPlaylistInfoResultData,
  };

  return [request, result];
};

export default useBusinessProfileZvulPlaylistInfo;
