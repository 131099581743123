import { useEffect } from 'react';

const useScript = (url: string, isAsync = false, onLoadCallback?: () => void) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = isAsync;

    document.body.appendChild(script);

    if (onLoadCallback) {
      script.onload = onLoadCallback;
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
