import React from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { useAuthContext } from 'application/providers/AuthProvider';
import { ZVUK_CAPCHA_API_KEY } from 'application/consts';
import { YandexCaptchaProps } from './types';
import useYandexCaptchaController from './useYandexCaptchaController';

export const YandexCaptcha = ({
  isVisible,
  setIsVisible,
  setCaptchaStatus,
  successCallback,
  yandexErrorCallback,
  captchaKey,
  resetCaptcha,
}: YandexCaptchaProps) => {
  const clientKey = ZVUK_CAPCHA_API_KEY;

  const { setCaptchaToken } = useAuthContext();

  const {
    handleChallengeHidden,
    handleChallengeVisible,
    handleJavaScriptError,
    handleNetworkError,
    handleSuccess,
    handleTokenExpired,
  } = useYandexCaptchaController({
    setCaptchaToken,
    setIsVisible,
    setCaptchaStatus,
    successCallback,
    yandexErrorCallback,
    resetCaptcha,
  });

  if (!clientKey) {
    return null;
  }

  return (
    <InvisibleSmartCaptcha
      sitekey={clientKey}
      onSuccess={handleSuccess}
      onChallengeHidden={handleChallengeHidden}
      visible={isVisible}
      onChallengeVisible={handleChallengeVisible}
      onNetworkError={handleNetworkError}
      onJavascriptError={handleJavaScriptError}
      onTokenExpired={handleTokenExpired}
      hideShield
      key={captchaKey}
    />
  );
};
