import React from 'react';

import { CONTRACT_MIGRATION_PAGE_PATH } from 'application/consts';
import ContractMigrationPageWrapper from 'application/pages/ContractMigrationPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const contractMigrationRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: CONTRACT_MIGRATION_PAGE_PATH,
        element: <ContractMigrationPageWrapper />,
      },
    ],
  },
];
