import React from 'react';

import ProvidersComposer from './ProvidersComposer';
import { ProvidersType, ProviderPropsType } from './types';

const withProviders =
  <T extends object>(
    WrappedComponent: React.ElementType,
    providers: ProvidersType,
    providerProps: ProviderPropsType = {}
  ) =>
  (props: T) => (
    <ProvidersComposer
      providers={providers}
      {...providerProps}>
      <WrappedComponent {...props} />
    </ProvidersComposer>
  );

export default withProviders;
