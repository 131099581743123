export enum ThematicEventNameAMP {
  LISTEN_NEW_YEAR_TRACKS_PRESSED = 'listen new year tracks pressed',
  ADD_THEMATIC_CONTENT_PRESSED = 'add thematic content pressed',
  SET_NEW_YEAR_PERIOD_PRESSED = 'set new year period pressed',
  THEMATIC_CONTENT_ADDED = 'thematic content added',
  THEMATIC_CONTENT_REMOVED = 'thematic content removed',
  WAVES_WITH_ADDRESS_CHECKBOX_CLICKED = 'waves with address checkbox clicked',
  WAVES_WITHOUT_ADDRESS_CHECKBOX_CLICKED = 'waves without address checkbox clicked',
  THEMATIC_ADD_ALL_CLICKED = 'add all clicked',
  THEMATIC_REMOVE_ALL_CLICKED = 'remove all clicked',
  CHANGE_SETTINGS_THEMATIC_CONTENT_PRESSED = 'change settings thematic content pressed',
  THEMATIC_CONTENT_REMOVED_CLICKED = 'thematic content removed clicked',
  SKIP_THEMATIC_CONTENT_PRESSED = 'skip thematic content pressed',
}

export enum NY_BANNER_TYPES_ENUM {
  BANNER = 'banner',
  MODAL_NEW_YEAR_WAVES = 'modal new year waves',
  MODAL_ONE_WAVE = 'modal one wave',
  MODAL_NEW_YEAR_TRACKS = 'modal new year tracks',
}
