import React, { FC } from 'react';

import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

const PointCreateConfirmModal: FC<ModalProps[ModalTypes.POINT_CREATE_CONFIRM]> = ({ cost, onSubmit, close }) => (
  <ModalScreen
    title={TEXTS.POINT_CREATE_CONFIRM_MODAL_TITLE}
    hideCloseButton
    onClose={close}
    actionButtons={[
      <Button
        key="button_create_point_confirm_submit"
        elementId="button_create_point_confirm_submit"
        type={ButtonType.PRIMARY}
        onClick={onSubmit}>
        {TEXTS.POINT_CREATE_CONFIRM_MODAL_BUTTON_SUBMIT(cost)}
      </Button>,
      <Button
        key="button_create_point_confirm_cancel"
        elementId="button_create_point_confirm_cancel"
        type={ButtonType.SECONDARY}
        onClick={close}>
        {TEXTS.POINT_CREATE_CONFIRM_MODAL_BUTTON_CANCEL}
      </Button>,
    ]}>
    <div className={styles.PointCreateConfirmModalDesc}>{TEXTS.POINT_CREATE_CONFIRM_MODAL_DESCRIPTION}</div>
  </ModalScreen>
);

export default PointCreateConfirmModal;
