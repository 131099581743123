import React, { useMemo, FC } from 'react';

import { TEXTS } from 'application/pages/AdsPage/renderer/texts';
import { ModalTypes, ModalProps } from 'application/providers/ModalProvider';

import { AdCampaignType, AdCampaignTypeEnum } from 'domain/media/types';

import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Info, { InfoTypes } from '@zvuk-b2b/react-uikit/ui/Info';

import styles from './index.module.scss';

export type AdUpdateConfirmModalProps = {
  adCampaign: AdCampaignType;
  isAdCampaignInstant: boolean;
  isAdHasOneTurnedOnPoint: boolean;
  onSubmit: () => void;
};

const AdUpdateConfirmModal: FC<ModalProps[ModalTypes.AD_CAMPAIGN_CONFIRM_UPDATE]> = ({
  adCampaign,
  isAdCampaignInstant,
  isAdHasOneTurnedOnPoint,
  onSubmit,
  close,
}) => {
  const warningBlock = useMemo(() => {
    if (!adCampaign.timingSettings.endDate || adCampaign.type === AdCampaignTypeEnum.Instant) {
      return null;
    }

    if (DateUtils.isDateBefore(new Date(adCampaign.timingSettings.endDate), DateUtils.NOW)) {
      return TEXTS.SAVE_AD_MODAL_WARNING_BEFORE_TODAY(
        DateUtils.getFormatDateFrom(new Date(adCampaign.timingSettings.endDate), 'dd.MM.yyyy')
      );
    }

    return null;
  }, [adCampaign.timingSettings]);

  const onSubmitClick = () => {
    onSubmit();
    close();
  };

  return (
    <ModalScreen
      title={TEXTS.SAVE_AD_MODAL_TITLE}
      onClose={close}
      className={styles.AdUpdateConfirmModal}
      actionButtons={[
        <Button
          key="button_confirm_ad_campaign_create_modal"
          elementId="button_confirm_ad_campaign_create_modal"
          type={ButtonType.PRIMARY}
          onClick={onSubmitClick}>
          {TEXTS.BUTTON_SAVE_AD_TITLE}
        </Button>,
        <Button
          key="button_cancel_ad_campaign_create_modal"
          elementId="button_cancel_ad_campaign_create_modal"
          type={ButtonType.SECONDARY}
          onClick={close}>
          {TEXTS.MODAL_AD_REMOVE_BUTTON_CANCEL}
        </Button>,
      ]}>
      <div className={styles.AdUpdateConfirmModalDesc}>
        {isAdCampaignInstant && isAdHasOneTurnedOnPoint ? (
          TEXTS.SAVE_INSTANT_AD_MODAL_DESCRIPTION
        ) : (
          <>
            {TEXTS.SAVE_AD_MODAL_DESCRIPTION}
            {warningBlock && (
              <Info
                type={InfoTypes.ERROR}
                className={styles.AdUpdateConfirmModalWarningInfo}>
                {warningBlock}
              </Info>
            )}
          </>
        )}
      </div>
    </ModalScreen>
  );
};

export default AdUpdateConfirmModal;
