export enum EventNameGTM {
  USER_ID_RECEIVED = 'smart_lk.gtm_event_user_id_received',
  UI_ACTION = 'smart_lk.gtm_event_ui_action',
  COMPANY_DETAILS_FILLED = 'smart_lk.gtm_event_company_details_filled',
  INN_SUBMITTED = 'smart_lk.gtm_event_inn_submitted',
  EMAIL_SUBMITTED = 'smart_lk.gtm_event_email_submitted',
  ADDRESS_ADDED = 'smart_lk.gtm_event_address_added',
  WAVE_INITIATION_VIEWED = 'smart_lk.gtm_event_wave_initiation_viewed',
  STREAM_START = 'smart_lk.lk_stream_start',
  AUTH_SUCCESS = 'smart_lk.gtm_event_account_auth_success',
}

export enum ParamNameGTM {
  USER_ID = 'smart_lk.gtm_param_user_id',
  UI_ACTION_KEY = 'smart_lk.gtm_param_ui_action_key',
  UI_ACTION_VALUE = 'smart_lk.gtm_param_ui_action_value',
  IS_TEST = 'smart_lk.gtm_param_is_test',
  ACCOUNT_CREATED_DATE = 'smart_lk.account_create_date',
}
