import { useCallback, useState, useEffect, useRef, useMemo } from 'react';

import { DEFAULT_BUSINESS_PROFILE_FORM_DATA } from 'application/pages/BusinessProfilePage/renderer/BusinessProfileForm/consts';
import { BusinessProfileFormData } from 'application/pages/BusinessProfilePage/renderer/BusinessProfileForm/types';

import { BusinessProfileModalMode, UseBusinessProfileUpsertModalControllerType } from './types';
import { TEXTS } from './texts';
import useBusinessProfileUpsertModalInfo from './useBusinessProfileUpsertModalInfo';

const useBusinessProfileUpsertModalController = ({
  targetBusinessProfileId,
  targetBusinessProfileUnpublished,
  mode,
  onSubmit,
  close,
  getBusinessProfileInitialFormData,
  onCreateBusinessProfile,
  businessProfileCreateLoading,
  businessProfileCreateError,
  businessProfileCreateData,
  onUpdateBusinessProfile,
  businessProfileUpdateLoading,
  businessProfileUpdateError,
  businessProfileUpdateData,
}: UseBusinessProfileUpsertModalControllerType) => {
  const {
    trackProfileUpdate,
    showSuccessNotification,
    showFieldErrorsNotification,
    showErrorNotification,
    trackFormVisit,
  } = useBusinessProfileUpsertModalInfo({ mode });

  const modalTopRef = useRef<HTMLDivElement>(null);

  const isCreateMode = mode === BusinessProfileModalMode.CREATE;

  const modalTitle = useMemo(() => {
    if (isCreateMode) return TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_CREATE_TITLE;

    return TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_UPDATE_TITLE;
  }, [isCreateMode]);

  const submitButtonContent = useMemo(() => {
    if (isCreateMode) return TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_BUTTON_CREATE_TITLE;

    if (targetBusinessProfileUnpublished) {
      return TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_BUTTON_PUBLISH_TITLE;
    }

    return TEXTS.BUSINESS_PROFILE_UPSERT_MODAL_BUTTON_UPDATE_TITLE;
  }, [isCreateMode, targetBusinessProfileUnpublished]);

  const [initialFormData, setInitialFormData] = useState<BusinessProfileFormData | undefined>({
    ...DEFAULT_BUSINESS_PROFILE_FORM_DATA,
    acceptedPrivatePolicy: !isCreateMode,
  });

  const [isInitialDataLoading, setIsInitialDataLoading] = useState<boolean>(false);

  const initFormData = async () => {
    if (targetBusinessProfileId) {
      setIsInitialDataLoading(true);
      const data = await getBusinessProfileInitialFormData(targetBusinessProfileId);
      setInitialFormData(data);
      setIsInitialDataLoading(false);
    }
  };

  useEffect(() => {
    initFormData();
  }, [targetBusinessProfileId]);

  useEffect(() => {
    trackFormVisit();
  }, []);

  const handleSubmit = useCallback(
    async (formData: BusinessProfileFormData) => {
      try {
        let result;

        if (isCreateMode) {
          result = await onCreateBusinessProfile(formData);
        } else {
          result = await onUpdateBusinessProfile(targetBusinessProfileId!, formData);
        }

        if (result.isOk) {
          close?.();
          onSubmit?.(formData);

          showSuccessNotification();
          trackProfileUpdate({ profileFormData: formData });
        } else {
          showFieldErrorsNotification();
          modalTopRef.current?.scrollIntoView();
        }
      } catch (e) {
        const error = e as Error;

        showErrorNotification({
          error,
          businessProfileCreateError,
          businessProfileUpdateError,
        });
      }
    },
    [onSubmit, isCreateMode]
  );

  const isLoading = businessProfileCreateLoading || businessProfileUpdateLoading || isInitialDataLoading;
  const errors = businessProfileCreateData.errors || businessProfileUpdateData.errors;

  return {
    handleSubmit,
    initialFormData,
    isCreateMode,
    isLoading,
    errors,
    modalTopRef,
    modalTitle,
    submitButtonContent,
  };
};

export default useBusinessProfileUpsertModalController;
