import { StreamSearchTagsQueryQuery } from 'domain/api/graphql/generated';
import { ActiveTagType } from './types';

export const getTagIds = (tags: ActiveTagType[]) => tags.map((el) => el.id);

export const getFlattenTags = (tags?: StreamSearchTagsQueryQuery['result']['tags']): ActiveTagType[] => {
  const activeTags: ActiveTagType[] = [];

  tags?.forEach((tag) => {
    activeTags.push({ id: tag.id, title: tag.title });

    tag.children.forEach((child) => {
      if (child !== null) {
        activeTags.push({ id: child.id, title: child.title });
      }
    });
  });

  return activeTags;
};
