import { ModalTypes } from 'application/providers/ModalProvider';

export interface IVideoMediafile {
  id?: string | null;
  title?: string | null;
  previewUrl?: string | null;
  playDuration?: number | null;
  duration?: number | null;
  uploading?: boolean;

  mediafile?: {
    id: string;
  };
}

export interface IVideoStreamShort {
  id?: string;
  title?: string | null;
  description?: string | null;
  videoMediafiles?: IVideoMediafile[];
}

export type VideoStreamBindModalProps = {
  selectedPoints: PointsListDataItem[];
  isButtonLoading: boolean;
  bindVideoStream: (selectedPoints: PointsListDataItem[]) => Promise<void>;
  openSuccessModal: (selectedPoints: PointsListDataItem[]) => void;
  openModal: (modalType: ModalTypes, props: {}) => void;
  isDenyCreatePoint: boolean;
};

export type PointsListDataItem = {
  id: string;
};

export interface IDraggableVideoMediafile extends IVideoMediafile {
  id: string;
}

export enum VideoStreamFlow {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}
