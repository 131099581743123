import React from 'react';

import LicenseStatusLabel from 'application/components/LicenseStatusLabel';
import { LicensePaymentState, LicenseInfoTypeFragment } from 'domain/billing/types';
import Checkbox from '@zvuk-b2b/react-uikit/ui/Checkbox';

import styles from './index.module.scss';

export type LicenseModalProps = {
  item: LicenseInfoTypeFragment;
  isChecked: boolean;
  onChange: (value: boolean) => void;
};

const LicensesListItem = (props: LicenseModalProps) => {
  const { item, isChecked, onChange } = props;

  const isGraceSubtitleVisible = item.paymentState === LicensePaymentState.Grace;
  const isWaitPaymentSubtitleVisible = item.paymentState === LicensePaymentState.WaitPayment;

  return (
    <div className={styles.LicensesListItem}>
      <div className={styles.LicensesListItemAddressContainer}>
        <span className={styles.LicensesListItemAddressContainerTitle}>{item.point?.address}</span>

        <LicenseStatusLabel
          paymentState={item.paymentState}
          autoRenewal={item.autoRenewal}
          expiresAt={item.expiresAt}
        />
      </div>

      <Checkbox
        elementId={`checkbox_license_${item.id}`}
        inputName={item.point?.address}
        isChecked={isChecked}
        isDisabled={isGraceSubtitleVisible || isWaitPaymentSubtitleVisible}
        onChange={onChange}
      />
    </div>
  );
};

export default LicensesListItem;
