import React, { FC } from 'react';

import { TEXTS } from 'application/pages/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

export type StopBindingFlowWarningModalProps = {
  onSubmit: () => void;
  address: string;
};

const StopBindingFlowWarningModal: FC<ModalProps[ModalTypes.STREAM_STOP_BINDING_FLOW_WARNING]> = ({
  address,
  onSubmit,
  close,
}) => (
  <ModalScreen
    title={TEXTS.MODAL_STOP_BINDING_FLOW_WARNING_TITLE}
    titlePlacement="left"
    onClose={close}
    actionButtons={[
      <Button
        key="button_confirm_stop_binding_flow_warning_modal"
        elementId="button_confirm_stop_binding_flow_warning_modal"
        type={ButtonType.PRIMARY}
        onClick={onSubmit}>
        {TEXTS.MODAL_STOP_BINDING_FLOW_WARNING_BUTTON_CONFIRM}
      </Button>,
      <Button
        key="button_cancel_stop_binding_flow_warning_modal"
        elementId="button_cancel_stop_binding_flow_warning_modal"
        type={ButtonType.SECONDARY}
        onClick={close}>
        {TEXTS.MODAL_STOP_BINDING_FLOW_WARNING_BUTTON_CANCEL}
      </Button>,
    ]}>
    <div className={styles.StopBindingFlowWarningModalDesc}>
      <span>{TEXTS.MODAL_STOP_BINDING_FLOW_WARNING_UPDATED_DESCRIPTION(address)}</span>
    </div>
  </ModalScreen>
);

export default StopBindingFlowWarningModal;
