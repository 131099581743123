import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { ModalProps, ModalTypes, useModalContext } from 'application/providers/ModalProvider';
import React, { FC } from 'react';
import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import styles from './index.module.scss';
import HelpModalContent from './HelpModalContent';

const CutMusicHelpModal: FC<ModalProps[ModalTypes.MUSIC_CUT_HELP_MODAL]> = ({ close }) => {
  const { isModalOpen } = useModalContext();

  return (
    <ModalScreen
      title={TEXTS.CUT_MUSIC_HELP_MODAL_TITLE}
      hideCloseButton
      onClose={close}
      disableScroll={isModalOpen}
      containerClassName={styles.HelpModalContainer}
      actionButtons={[
        <Button
          elementId="button_cut_music_help_modal"
          type={ButtonType.PRIMARY}
          onClick={close}>
          {TEXTS.CUT_MUSIC_HELP_MODAL_BUTTON}
        </Button>,
      ]}>
      <HelpModalContent />
    </ModalScreen>
  );
};

export default CutMusicHelpModal;
