import React from 'react';

import {
  useNotificationContext,
  NotificationColors,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NOTIFICATION_DEFAULT_DELAY,
} from 'application/providers/NotificationProvider';

import ToasterSMSTrouble, { ToasterSMSTroubleMode } from './ToasterSMSTrouble';
import { TEXTS } from './texts';

const useAuthNotifications = () => {
  const notification = useNotificationContext();

  return {
    userAlreadyRegisteredByPhone: () =>
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: TEXTS.ERROR_USER_ALREADY_REGISTERED_BY_PHONE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      }),
    userRegisteredByEmail: () =>
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: TEXTS.ERROR_USER_REGISTERED_BY_EMAIL,
        delay: NOTIFICATION_DEFAULT_DELAY,
      }),
    userNotFound: () =>
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: TEXTS.ERROR_USER_NOT_FOUND,
        delay: NOTIFICATION_DEFAULT_DELAY,
      }),
    userAlereadyRegisteredByEmail: () =>
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: TEXTS.ERROR_USER_ALREADY_REGISTERED_BY_EMAIl,
        delay: NOTIFICATION_DEFAULT_DELAY,
      }),
    unexpectedError: () =>
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      }),
    registerSMSTrouble: (onSubmit: () => void) =>
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: (
          <ToasterSMSTrouble
            mode={ToasterSMSTroubleMode.REGISTER}
            onSubmit={onSubmit}
            onClose={notification.hideNotification}
          />
        ),
      }),
    loginSMSTrouble: (onSubmit: () => void) =>
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: (
          <ToasterSMSTrouble
            mode={ToasterSMSTroubleMode.LOGIN}
            onSubmit={onSubmit}
            onClose={notification.hideNotification}
          />
        ),
      }),
  };
};

export default useAuthNotifications;
