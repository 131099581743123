import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import IconMapPin from '@zvuk-b2b/react-uikit/ui/icons/MapPin';
import { CommonModalProps } from 'application/providers/ModalProvider/modalsProps';
import { AdCampaignPureType, PointPureType } from 'domain/api/graphql/generated';
import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { ScheduleUpdateModalModalProps } from './types';
import { TEXTS } from '../texts';

const ScheduleUpdateModal = ({ drafts, onUpdate, close }: ScheduleUpdateModalModalProps & CommonModalProps) => {
  const affectedPoints = useMemo(
    () =>
      (Object.values(drafts) as AdCampaignPureType[]).reduce(
        (acc, draft) => {
          const points = { ...acc };
          for (const point of draft?.points) {
            if (!acc[point.id]) {
              points[point.id] = point;
            }
          }
          return points;
        },
        {} as Record<string, PointPureType>
      ),
    [drafts]
  );

  return (
    <ModalScreen
      containerClassName={styles.UpdateModal}
      title={TEXTS.UPDATE_SCHEDULE}
      titlePlacement="left"
      actionsPlacement="left"
      onClose={close}
      actionButtons={[
        <Button
          key="filter_tags_apply"
          elementId="filter_tags_apply"
          type={ButtonType.PRIMARY}
          onClick={() => {
            onUpdate();
            close();
          }}>
          {TEXTS.UPDATE_MODAL_SUBMIT}
        </Button>,
        <Button
          key="filter_tags_clear"
          elementId="filter_tags_clear"
          type={ButtonType.SECONDARY}
          onClick={close}>
          {TEXTS.UPDATE_MODAL_CANCEL}
        </Button>,
      ]}>
      {TEXTS.UPDATE_MODAL_WARNING}
      <div className={styles.UpdateModalAddresses}>
        {Object.values(affectedPoints).map((point) => (
          <div className={styles.UpdateModalAddress}>
            <IconMapPin />
            {point.address}
          </div>
        ))}
      </div>
    </ModalScreen>
  );
};

export default ScheduleUpdateModal;
