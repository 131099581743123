import { Accept } from '../types';

export const ACCEPT_IMAGE_FILES: Accept = {
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/gif': ['.gif'],
  'image/bmp': ['.bmp'],
  'image/tiff': ['.tif', '.tiff'],
  'image/apng': ['.apng'],
  'image/avif': ['.avif'],
  'image/vnd.microsoft.icon': ['.ico'],
  'image/webp': ['.webp'],
};

export const MIN_ACCEPT_IMAGE_FILES: Accept = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
};

export const DEFAULT_IMAGE_MIN_WIDTH = 50; // px
export const DEFAULT_IMAGE_WIDTH = 1024; // px
export const DEFAULT_IMAGE_MAX_WIDTH = 1720; // px

export const DEFAULT_IMAGE_MIN_HEIGHT = 50; // px
export const DEFAULT_IMAGE_HEIGHT = 720; // px
export const DEFAULT_IMAGE_MAX_HEIGHT = 500; // px
