import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import React, { FC } from 'react';
import IconRefreshCcw from '@zvuk-b2b/react-uikit/ui/icons/RefreshCcw';
import { RETRY_ADDS } from '../../MediaListItem/texts';

interface MediaListItemWrapperErrorProps {
  id?: string;
  onRetry?: () => void;
}

const MediaListItemWrapperError: FC<MediaListItemWrapperErrorProps> = (props) => {
  const { id, onRetry } = props;

  return (
    <IconButton
      elementId={`${id}_button_promo_retry`}
      onClick={onRetry}
      hint={RETRY_ADDS}>
      <IconRefreshCcw />
    </IconButton>
  );
};

export default MediaListItemWrapperError;
