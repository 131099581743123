/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { InView } from 'react-intersection-observer';

import styles from './index.module.scss';

export type InfiniteItemListItemType = {
  id?: string | number;
};

export type InfiniteItemListProps<T, K> = {
  elementId?: string;
  items: Array<InfiniteItemListItemType & T>;
  totalItemsCount: number;
  hasNextPage?: boolean;
  isLoading?: boolean;
  loadMore: () => void;
  itemListRenderer: React.ElementType;
  itemListRendererProps: K;
  itemListSkeleton: React.ReactNode;
  shouldScrollAfterTotalCountChanged?: boolean;
};

const InfiniteItemList = <T extends unknown, K extends object>({
  elementId = 'infinite_item_list_id',
  items,
  loadMore,
  hasNextPage,
  isLoading = false,
  totalItemsCount = items.length,
  itemListRenderer: ItemListRenderer,
  itemListRendererProps,
  itemListSkeleton,
  shouldScrollAfterTotalCountChanged = false,
}: InfiniteItemListProps<T, K>) => {
  const onLoadMore = (inView: boolean) => {
    if (inView && hasNextPage) {
      loadMore();
    }
  };

  useEffect(() => {
    if (totalItemsCount !== 0 && shouldScrollAfterTotalCountChanged) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, [totalItemsCount]);

  return (
    <div
      className={styles.InfiniteItemList}
      id={elementId}
      data-testid={elementId}>
      <ItemListRenderer {...itemListRendererProps}>
        {hasNextPage && (
          <div className={styles.InfiniteItemListHiddenLoadingZone}>
            <div className={styles.InfiniteItemListHiddenLoadingZoneItem}>
              <InView
                onChange={onLoadMore}
                key="infinite_item_list_hidden_loading_zone"
              />
            </div>
          </div>
        )}

        {hasNextPage &&
          totalItemsCount !== 0 &&
          totalItemsCount > items.length &&
          !isLoading &&
          Array.from(Array(totalItemsCount - items.length).keys()).map((item) => (
            <InView
              onChange={onLoadMore}
              key={`infinite_item_list_skeleton_item_${item}_${items[items.length - 1]?.id}`}>
              {itemListSkeleton}
            </InView>
          ))}
      </ItemListRenderer>
    </div>
  );
};

export default InfiniteItemList;
