class DiContainer {
  private registry = {} as Record<symbol, unknown>;

  public register<R>(key: symbol, dependency: R) {
    this.registry[key] = dependency;

    return this;
  }

  public get<R>(key: symbol): R {
    const dependency = this.registry[key] as R;

    if (!dependency) {
      throw new Error(`No dependency found for symbol ${String(key)}`);
    }

    return dependency;
  }

  public clear() {
    this.registry = {};
  }
}

export default new DiContainer();
