import { useSubscriptionCostPresenter } from 'application/presenters';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useBillingRepositoryInjector } from 'application/providers/DIContainerProvider';

const useLicensesUnpaidModalPresenter = () => {
  const { getBillingInfo, watchBillingInfo } = useBillingRepositoryInjector();

  const { licenseCostLoading, getSubscriptionCost } = useSubscriptionCostPresenter();

  const { currentCompany } = useAccountContext();

  const billingInfo = watchBillingInfo(currentCompany!.id);

  const { totalLicenses } = billingInfo;

  const totalLicensesUnpaid = totalLicenses.filter((license) => !license.point?.isEnabled);

  return {
    totalLicenses: totalLicensesUnpaid,
    licenseCostLoading,
    getSubscriptionCost,
    billingInfoRequest: getBillingInfo,
    billingInfo,
  };
};

export default useLicensesUnpaidModalPresenter;
