import { AdCampaignEventNameAMP, AdCampaignParamNameAMP } from './eventTypes/adcampaign';
import { BillingEventNameAMP } from './eventTypes/billing';
import {
  BroadcastForecastEventNameAMP,
  BroadcastForecastParamNameAMP,
  BroadcastForecastStatus,
} from './eventTypes/broadcastForecast';
import { BusinessProfileEventNameAMP, BusinessProfileParamNameAMP } from './eventTypes/businessProfile';
import { PointEventNameAMP, PointParamNameAMP, PointQuality } from './eventTypes/point';
import { StreamBindFlowParamNameAMP, StreamBindFlowEventNameAMP } from './eventTypes/streamBindFlow';
import { ThematicEventNameAMP } from './eventTypes/thematic';
import { YandexCaptchaEventNameAMP } from './eventTypes/yandexCaptcha';

export enum EventNameAMP {
  SIGNUP_PAGE_SHOWN = 'signup page shown',
  SIGNUP_FORM_SUBMITTED = 'signup form submitted',
  USER_LOGGED_IN = 'user logged in',
  LOGIN_PAGE_SHOWN = 'log in page shown',
  USER_LOGGED_OUT = 'user logged out',
  USER_SIGNED_UP = 'user signed up',
  USER_SIGN_UP_FAILED = 'user sign up failed',
  FORGOT_PAGE_SHOWN = 'forgot page shown',
  LEFT_COLLECTION = 'left collection',
  SELECTED_COLLECTION = 'selected collection',
  STREAM_GROUP_OPEN = 'stream group open',
  STREAM_LIST_SHOWN = 'stream list shown',
  STREAM_PAGE_SHOWN = 'stream page shown',
  PLAY_TRACK_PRESSED = 'play track pressed',
  COMPANY_PAGE_SHOWN = 'company page shown',
  PLAYER_PAGE_SHOWN = 'player page shown',
  OPEN_WEBPLAYER_PRESSED = 'open web player pressed',
  REQUEST_INVOICE_PRESSED = 'request invoice pressed',
  BIND_CARD_PRESSED = 'bind card pressed',
  INVITE_SENT = 'invite sent',
  SLEEP_MODE_CHANGED = 'sleep mode changed',
  AD_CAMPAIGN_CREATED = 'ad campaign created',
  AD_CAMPAIGN_UPDATED = 'ad campaign updated',
  AD_CAMPAIGN_REPORT_REQUESTED = 'ad campaign report requested',
  AD_CAMPAIGN_TYPE_SELECTED = 'ad campaign type selected',
  AD_CAMPAIGN_INSTANT_LAUNCH_CLICKED = 'ad campaign instant launch clicked',
  AD_CAMPAIGN_INSTANT_SUCCESS_LAUNCHED = 'ad campaign instant success launched',
  AD_CAMPAIGN_INSTANT_ERROR_LAUNCHED = 'ad campaign instant error launched',
  COMPANY_INFO_SUBMITTED = 'company info submited',
  ADCAMPAIGN_LIST_SHOWN = 'adcampaign list shown',
  ADCAMPAIGN_PAGE_SHOWN = 'adcampaign page shown',
  SUBSCRIPTION_PAGE_SHOWN = 'subscription page shown',
  SHOW_OFERTA_PRESSED = 'show oferta pressed',
  OFERTA_ACCEPTING_PAGE_SHOWN = 'oferta accepting page shown ',
  OFERTA_ACCEPTED = 'oferta accepted',
  HIFI_STREAM_WARNING_SHOWN = 'hifi stream warning shown',
  HIFI_STREAM_WARNING_ANSWER = 'hifi stream warning answer',
  PLAYER_ACTIVATED = 'player activated',
  RETURNED_TO_APP_PRESSED = 'returned to app pressed',
  NO_ADDRESS_PAGE_SHOWN = 'no adresses page shown',
  BUSINESS_TYPES_PAGE_SHOWN = 'business types page shown',
  COMPANY_INFO_PAGE_SHOWN = 'company info page shown',
  EMAIL_PAGE_SHOWN = 'email page shown',
  EMAIL_SUBMITTED = 'email submitted',
  USER_PASSWORD_CHANGE_CLICKED = 'user password change clicked',
  SUPPORT_REQUESTED = 'support requested',
  CONTENT_PAGE_SHOWN = 'content page shown',
  REPORTS_HELP_LINK_CLICKED = 'reports help link clicked',
  REPORTS_HELP_ICON_CLICKED = 'reports help icon clicked',
  REPORT_DOWNLOAD_CLICKED = 'reports download clicked',
  REPORT_NOTIFICATION_LINK_CLICKED = 'reports notification link clicked',
  REPORT_NOTIFICATION_BUTTON_CLICKED = 'report notification button click',
  MEDIAFILE_PROGRESS_TOUCHED = 'mediafile progress bar pressed',
  REPORTS_PAGE_SHOWN = 'reports page shown',
  COMPANY_TAG_CREATED = 'company tag created',
  COMPANY_TAG_LINK_CLICKED = 'company tag link clicked',
  COMPANY_TAG_UNLINK_CLICKED = 'company tag unlink clicked',
  COMPANY_TAG_FILTER_ADDED = 'company tag filter added',
  COMPANY_TAG_FILTER_REMOVED = 'company tag filter removed',
  STREAMS_GO_BACK_CLICKED = 'streams go back clicked',
  ADDRESS_STREAM_CHANGE_CLICKED = 'address stream change clicked',
  ADDRESS_STREAM_CLICKED = 'address stream clicked',
  ADDRESS_STREAM_ADD_CLICKED = 'address stream add clicked',
  HISTORY_DATE_PICKER_CLICKED = 'history date picker clicked',
  HISTORY_DOWNLOAD_REPORT_CLICKED = 'history download report clicked',
  CERTIFICATE_MENU_ITEM_CLICKED = 'certificate menu item clicked',
  CERTIFICATE_ADDRESS_SELECTED = 'certificate address selected',
  CERTIFICATE_CREATE_BUTTON_CLICKED = 'certificate create button clicked',
  CERTIFICATE_DOWNLOADED = 'certificate downloaded',
  PLAYER_PLAYBACK_STARTED = 'player playback started',
  PLAYER_LOADED = 'player loaded',
  INSTALL_APP_PRESSED = 'install app pressed',
  INSTALL_APP_SHOWN = 'install app shown',
  FILTER_OPEN_PRESSED = 'stream filter open pressed',
  FILTER_CLOSE_PRESSED = 'stream filter close pressed',
  FILTER_SHOW_RESULTS_PRESSED = 'stream filter show results pressed',
  FILTER_CLEAR_PRESSED = 'stream filter clear pressed',
  SEARCH_PAGE_SHOW = 'search page shown',
  STREAM_REVIEW_PAGE_SHOWN = 'stream review page shown',
  LIKE_TRACK_PRESSED = 'like track pressed',
  LIKE_TRACK_UNPRESSED = 'like track unpressed',
  DISLIKE_TRACK_PRESSED = 'dislike track pressed',
  DISLIKE_TRACK_UNPRESSED = 'dislike track unpressed',
  BILLING_POINT_PAY_CLICKED = 'billing point pay clicked',
  BILLING_UNPAID_LICENSES_PAY_CLICKED = 'billing unpaid licenses pay clicked',
  BILLING_LICENSES_PAY_CONFIRM_CLICKED = 'billing licenses pay confirm clicked',
  STREAM_SUBCOLLECTION_OPEN = 'stream subcollection open',
  REDIRECT_BUTTON_CLICKED = 'redirect button clicked',
  STREAM_SUBCOLLECTION_CARD_CLICKED = 'stream subcollection card clicked',
}

export enum ParamNameAMP {
  STREAM_ID = 'stream_id',
  STREAM_TYPE = 'stream_type',
  STREAM_TITLE = 'stream_title',
  COLLECTION_ID = 'collection_id',
  COLLECTION_TITLE = 'collection_title',
  GROUP_TITLE = 'group_title',
  GROUP_ID = 'group_id',
  BUSINESS_SPHERE_MATCH = 'business_sphere_match',
  IS_OPEN = 'is_open',
  TRACK_ID = 'track_id',
  COMPANY_ID = 'company_id',
  INVITED_EMAIL = 'invited_email',
  BOOLEAN = 'boolean',
  AD_CAMPAIGN_ID = 'adcampaign_id',
  AD_CAMPAIGN_TYPE = 'adcampaign_type',
  LOG_IN_PATH = 'log_in_path',
  SIGN_UP_SOURCE = 'sign_up_source',
  CALLED_FROM = 'called_from',
  THEMATIC_EVENT_TITLE = 'thematic_event_title',
  IS_TEST = 'is_test',
  IS_HELPED = 'is_helped',
  SOURCE = 'source',
  PATH = 'path',
  EMAIL = 'email',
  PHONE_NUM_COUNTRY = 'phone_num_country',
  COMPANY_INN = 'company_inn',
  COMPANY_COUNTRY = 'company_country',
  COMPANY_BUSINESS_SPHERE = 'company_business_sphere',
  PHONE_NUM = 'phone_num',
  ANSWER = 'answer',
  REFERRAL = 'referral',
  REFERRAL_SOURCE = 'referral_source',
  TAB_ID = 'tab_id',
  IS_DEMO = 'is_demo',
  IS_PROMO = 'is_promo',
  CREATING_TAG_RESULT = 'creating_tag_result',
  LINKING_TAG_SOURCE = 'linking_tag_source',
  FILTER_BY_TAG_SOURCE = 'filter_by_tag_source',
  NOTIFICATION_TYPE = 'notification_type',
  TYPE = 'type',
  VALUE = 'value',
  UNIQUE_PER_DAY = 'unique_per_day',
  IS_ANDROID = 'is_android',
  ANDROID_VERSION = 'android_version',
  PLATFORM = 'platform',
  PLAYER_VIEW = 'player_view',
  HAS_ERROR = 'has_error',
  FILTER_TYPE = 'type',
  TITLE = 'title',
  FIRST_PLAYER_START = 'first_player_start',
  PLAYER_START_TIME = 'player_start_time',
  FROM = 'from',
  TO = 'to',
  SUBCOLLECTION_ID = 'subcollection_id',
  SUBCOLLECTION_TITLE = 'subcollection_title',
}

export type EventNameAMPType =
  | EventNameAMP
  | StreamBindFlowEventNameAMP
  | BusinessProfileEventNameAMP
  | PointEventNameAMP
  | AdCampaignEventNameAMP
  | BillingEventNameAMP
  | BroadcastForecastEventNameAMP
  | YandexCaptchaEventNameAMP
  | ThematicEventNameAMP;

export {
  BusinessProfileEventNameAMP,
  BusinessProfileParamNameAMP,
  PointEventNameAMP,
  PointParamNameAMP,
  AdCampaignEventNameAMP,
  AdCampaignParamNameAMP,
  StreamBindFlowParamNameAMP,
  StreamBindFlowEventNameAMP,
  BillingEventNameAMP,
  BroadcastForecastEventNameAMP,
  BroadcastForecastParamNameAMP,
  BroadcastForecastStatus,
  YandexCaptchaEventNameAMP,
  ThematicEventNameAMP,
  PointQuality,
};
