import { useBusinessProfileRemoveMutation, ZvukProfileRemoveMutationInput } from 'domain/api/graphql/generated';
import { BaseMutationResultType, FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseBusinessProfileRemoveType } from './types';

const useBusinessProfileRemove = (): UseBusinessProfileRemoveType => {
  const [removeMutation, removeMutationResult] = useBusinessProfileRemoveMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukProfileRemoveMutationInput) => {
    const removeMutationData = await removeMutation({
      variables: {
        input,
      },
      refetchQueries: ['zvukProfileList'],
    });

    return {
      isOk: !!removeMutationData.data?.zvukProfileRemove?.ok,
      businessProfile: removeMutationData.data?.zvukProfileRemove?.profile,
    };
  };

  const result: BaseMutationResultType = {
    error: removeMutationResult.error,
    loading: removeMutationResult.loading,
    called: removeMutationResult.called,
  };

  return [request, result];
};

export default useBusinessProfileRemove;
