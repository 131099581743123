import { useBillingLicensesSendResumeAutoRenewalMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useLicensesResumeAutoRenewal = () => {
  const [mutation, mutationResult] = useBillingLicensesSendResumeAutoRenewalMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (companyId: string, licensesIds: string[]) => {
    try {
      const mutationResult = await mutation({
        variables: {
          input: { licensesIds, companyId },
        },
      });

      return !!mutationResult.data?.result?.ok;
    } catch (e) {
      return false;
    }
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
  };

  return {
    request,
    result,
  };
};

export default useLicensesResumeAutoRenewal;
