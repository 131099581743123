import React from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import { TEXTS } from './texts';

interface IWebPlayerSkipModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  content: string;
}

const WebPlayerSkipModal: React.FC<IWebPlayerSkipModalProps> = ({ onCancel, onSubmit, content }) => (
  <ModalScreen
    title={TEXTS.SKIP_TRACK_TITLE}
    titlePlacement="center"
    onClose={onCancel}
    actionButtons={[
      <Button
        key="button_confirm_web_player_skip_modal"
        elementId="button_confirm_web_player_skip_modal"
        type={ButtonType.PRIMARY}
        onClick={onSubmit}>
        {TEXTS.SKIP_TRACK}
      </Button>,
      <Button
        key="button_cancel_web_player_skip_modal"
        elementId="button_cancel_web_player_skip_modal"
        type={ButtonType.SECONDARY}
        onClick={onCancel}>
        {TEXTS.CANCEL}
      </Button>,
    ]}>
    <div>{content}</div>
  </ModalScreen>
);

export default WebPlayerSkipModal;
