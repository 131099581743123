const DELAY = 250;

type TimerType = ReturnType<typeof setTimeout>;

class AuthProviderUtils {
  static async waitForAuthTokenUpdated(callback: () => any): Promise<void> {
    return new Promise((resolve) => {
      callback();
      setTimeout(() => {
        resolve();
      }, DELAY);
    });
  }

  static setAuthTokenUpdateTimeout(callback: () => any): TimerType {
    return setTimeout(callback, DELAY);
  }
}

export default AuthProviderUtils;
