import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import { TEXTS } from './texts';

import styles from './index.module.scss';

const ZVUK_LINK = 'https://zvuk.com';
const INSTRUCTION_LINK = 'https://help.zvuk-b2b.com/bp';
const IMAGE_LINK = '/assets/img/business_profile/playlists/zvuk_playlist_hint.png';

const BusinessProfileZvukPlaylistHintModal: FC<ModalProps[ModalTypes.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT]> = ({
  close,
}) => (
  <ModalScreen
    title={TEXTS.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_MODAL_TITLE}
    titlePlacement="left"
    onClose={close}
    contentClassName={styles.BusinessProfileZvukPlaylistHintModalContent}
    actionButtons={[
      <Button
        key="business_profile_zvuk_playlist_hint_modal_button"
        elementId="business_profile_zvuk_playlist_hint_modal_button"
        type={ButtonType.PRIMARY}
        onClick={close}>
        {TEXTS.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_OK_BUTTON}
      </Button>,
    ]}>
    <div>
      <span>{TEXTS.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_REGISTER}</span>
      <a
        href={ZVUK_LINK}
        target="_blank"
        rel="noreferrer">
        {TEXTS.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_REGISTER_LINK}
      </a>
      <span>{TEXTS.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_INSTRUCTION}</span>
      <a
        href={INSTRUCTION_LINK}
        target="_blank"
        rel="noreferrer">
        {TEXTS.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_INSTRUCTION_LINK}
      </a>
    </div>

    <img
      src={IMAGE_LINK}
      alt=""
      loading="lazy"
      className={styles.BusinessProfileZvukPlaylistHintModalImage}
    />

    <div>{TEXTS.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT_MANAGER_HELP}</div>
  </ModalScreen>
);

export default BusinessProfileZvukPlaylistHintModal;
