import React from 'react';

import IconMove2 from '@zvuk-b2b/react-uikit/ui/icons/Move2';
import classNames from 'classnames';
import styles from './index.module.scss';

export const DraggableListItem: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.icon}>
      <IconMove2 className={classNames(styles.icon, 'draggable')} />
    </div>
    <div className={styles.childrenWrapper}>{children}</div>
  </div>
);
