import React, { FC } from 'react';

import { TEXTS } from 'application/pages/AdsPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Input, { InputType } from '@zvuk-b2b/react-uikit/ui/Input';

import useAdRenameModalAdapter from './useAdRenameModalAdapter';
import useAdRenameModalController from './useAdRenameModalController';

import styles from './index.module.scss';

const AdRenameModal: FC<ModalProps[ModalTypes.AD_CAMPAIGN_RENAME]> = ({ id, close }) => {
  const { readAdCampaign, updateAdCampaign, adCampaignUpdateLoading } = useAdRenameModalAdapter();

  const { name, setName, isRenameButtonDisabled, onAdCampaignRename } = useAdRenameModalController({
    id,
    readAdCampaign,
    updateAdCampaign,
    adCampaignUpdateLoading,
    close,
  });

  return (
    <ModalScreen
      title={TEXTS.MODAL_AD_RENAME_TITLE}
      titlePlacement="left"
      onClose={close}
      actionButtons={[
        <Button
          key="button_rename_ad_campaign_modal"
          elementId="button_rename_ad_campaign_modal"
          type={ButtonType.PRIMARY}
          isDisabled={isRenameButtonDisabled}
          onClick={onAdCampaignRename}
          isLoading={adCampaignUpdateLoading}>
          {TEXTS.MODAL_AD_RENAME_BUTTON_CONFIRM}
        </Button>,
      ]}>
      <Input
        elementId="input_rename-campaign"
        containerClassName={styles.AdRenameModalInput}
        label={TEXTS.MODAL_AD_RENAME_INPUT_LABEL}
        isDisabled={adCampaignUpdateLoading}
        type={InputType.TEXT}
        value={name}
        onChange={setName}
      />
    </ModalScreen>
  );
};

export default AdRenameModal;
