import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputSearch, { InputSize } from 'ui/InputSearch';
import Checkbox from 'ui/Checkbox';

import DropdownItem from './DropdownItem';
import { MAX_VISIBLE_OPTIONS_COUNT } from './consts';
import { DropdownOrientation, DropdownProps, DropdownPlacement } from './types';
import useDropdownController from './useDropdownController';

import styles from './index.module.scss';

export type { DropdownItemDataType } from './types';
export { DropdownOrientation, DropdownPlacement } from './types';

const Dropdown = ({
  className,
  style: initialStyle,
  itemClassName,
  itemContentClassName,
  anchorNode,
  parentNode,
  elementId,
  items,
  maxVisibleOptions = MAX_VISIBLE_OPTIONS_COUNT,
  orientation = DropdownOrientation.LEFT,
  placement = DropdownPlacement.AUTO,
  onClose,
  isFullMode,
  isMulti = false,
  isSearchLoading = false,
  hasSearch,
  menuMargin,
  isNeedCloseWhileParentScrolling = true,
  onSelect,
  onSearchChange,
  withoutWrapper = false,
  hasSearchIcon = true,
  searchInputPlaceholder,
  searchInputSize = InputSize.MEDIUM,
}: DropdownProps) => {
  const { ref, filteredItems, search, style, handleSearchChange, handleDropdownItemClick } = useDropdownController({
    initialStyle,
    anchorNode,
    parentNode,
    items,
    maxVisibleOptions,
    orientation,
    placement,
    isFullMode,
    isMulti,
    menuMargin,
    isNeedCloseWhileParentScrolling,
    onClose,
    onSearchChange,
    withoutWrapper,
  });

  const [initialSearchFocus, setInitialSearchFocus] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setInitialSearchFocus(true);
    }, 0);
  }, []);

  return (
    <ul
      data-testid={elementId}
      id={elementId}
      role="menu"
      style={style}
      ref={ref}
      className={classNames(
        isFullMode ? styles.DropdownFullMode : '',
        styles.Dropdown,
        isFullMode && withoutWrapper ? styles.DropdownFullModeWithoutWrapper : null,
        className
      )}>
      {hasSearch && (
        <InputSearch
          value={search}
          elementId="search_input_items_list"
          onChange={handleSearchChange}
          hasSearchIcon={hasSearchIcon}
          hasInitialFocus={initialSearchFocus}
          isLoading={isSearchLoading}
          size={searchInputSize}
          placeholder={searchInputPlaceholder}
        />
      )}

      {filteredItems.map((item) => (
        <DropdownItem
          key={item.key}
          elementId={item.key}
          className={itemClassName}
          contentClassName={itemContentClassName}
          onMouseDown={(event) => {
            if (!item.disabled) {
              handleDropdownItemClick(item, event);

              if (onSelect) {
                onSelect(item);
              }
            }
          }}
          selected={item.selected}
          disabled={item.disabled}
          adornmentLeft={
            isMulti &&
            item.isMulti && (
              <Checkbox
                key={`dropdown_adornmnet_left_${item.key}`}
                elementId={`dropdown_adornmnet_left_${item.key}`}
                buttonClassName={styles.DropdownCheckboxButton}
                isChecked={item.selected}
                ignoreInternalState
              />
            )
          }>
          {item.content}
        </DropdownItem>
      ))}
    </ul>
  );
};

export default Dropdown;
