import React, { FC } from 'react';
import { ActiveTagType } from 'application/providers/StreamSearchProvider/types';
import Chip from '@zvuk-b2b/react-uikit/ui/Chip';
import classNames from 'classnames';
import styles from '../index.module.scss';

interface StreamMainToolBarFiltersModalChipProps {
  id: string;
  title: string;
  selectedTags: Map<string, ActiveTagType>;
  setTagActive: (params: { id: string; title: string }) => void;
  setTagInactive: (tagId: string) => void;
}

export const StreamMainToolBarFiltersModalChip: FC<StreamMainToolBarFiltersModalChipProps> = (props) => {
  const { id, title, selectedTags, setTagActive, setTagInactive } = props;
  const isActive = selectedTags.has(id);

  const handleTag = () => {
    if (isActive) {
      setTagInactive(id);
      return;
    }
    setTagActive({ id, title });
  };

  return (
    <Chip
      key={`filter_tags_section_tag_${id}`}
      className={styles.Chip}
      elementId={`filter_tags_section_tag_${id}`}
      contentClassName={classNames(styles.StreamToolBarModalListItemChipsItem, {
        [styles.StreamToolBarModalListItemChipsItemActive]: !!isActive,
      })}
      isClickable
      onClick={handleTag}>
      {title}
    </Chip>
  );
};
