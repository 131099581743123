import { UserCompanyPureType } from 'domain/api/graphql/generated';
import { UserDataType } from 'domain/auth/types';

class RootFrontendUtils {
  static isReadyToUseLK(user?: UserDataType, company?: UserCompanyPureType): boolean {
    if (!user?.email) {
      return false;
    }

    if (!company) {
      return false;
    }

    if (!company.requisite?.emailForDocuments) {
      return false;
    }

    if (!company.businessSphereId) {
      return false;
    }

    return company.hasMinimumData;
  }
}

export default RootFrontendUtils;
