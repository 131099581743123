import React, { Suspense } from 'react';
import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export enum CategoryParamValues {
  SUBCOLLECTION = 'subcollection',
}

export type StreamSubCollectionPageParamsType = {
  id?: string;
  subcollectionId: string;
};

export const STREAM_SUB_COLLECTIONS_PAGE_PATH_ALIAS = `/streams/${CategoryParamValues.SUBCOLLECTION}/`;
export const STREAM_SUB_COLLECTIONS_PAGE_PATH = `${STREAM_SUB_COLLECTIONS_PAGE_PATH_ALIAS}:subcollectionId/:id?/`;

const Renderer = React.lazy(() => import('./renderer'));

const StreamSubCollectionPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

StreamSubCollectionPageWrapper.config = {
  routes: [
    {
      path: STREAM_SUB_COLLECTIONS_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default StreamSubCollectionPageWrapper;
