import React from 'react';

import { LOGOUT_PAGE_PATH } from 'application/consts';
import LogoutPage from 'application/pages/LogoutPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const logoutRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: LOGOUT_PAGE_PATH,
        element: <LogoutPage />,
      },
    ],
  },
];
