import React, { useMemo, FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { TEXTS } from 'application/pages/PointsPage/renderer/texts';

import { DeviceTypes } from 'domain/api/graphql/generated';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

export type PlayerChangeSuccessModalProps = {
  toDeviceType: DeviceTypes;
  onClose?: () => void;
};

const PlayerChangeSuccessModal: FC<ModalProps[ModalTypes.POINT_PLAYER_CHANGE_SUCCESS]> = ({
  onClose,
  close,
  toDeviceType,
}) => {
  const description = useMemo(() => {
    if (['ANDROID', 'IOS'].includes(toDeviceType)) {
      return TEXTS.PLAYER_CHANGE_DEVICE_SUCCESS_MOBILE;
    }

    if (toDeviceType === 'WEBPLAYER') {
      return TEXTS.PLAYER_CHANGE_DEVICE_SUCCESS_WEBPLAYER;
    }

    return TEXTS.PLAYER_CHANGE_DEVICE_SUCCESS_DESKTOP;
  }, [toDeviceType]);

  const handleClose = () => {
    close?.();
    onClose?.();
  };

  return (
    <ModalScreen
      title={TEXTS.PLAYER_CHANGE_DEVICE_SUCCESS_TITLE}
      titlePlacement="left"
      onClose={handleClose}
      hideCloseButton
      actionButtons={[
        <Button
          elementId="button_player_change_success_modal"
          type={ButtonType.PRIMARY}
          onClick={handleClose}>
          {TEXTS.PLAYER_CHANGE_DEVICE_SUCCESS_OK_BUTTON}
        </Button>,
      ]}>
      <span className={styles.PlayerSuccessDesc}>{description}</span>
    </ModalScreen>
  );
};

export default PlayerChangeSuccessModal;
