import React from 'react';

import { REGISTER_PAGE_PATH, REGISTER_PAGE_PATH_ALIAS } from 'application/consts';
import RegisterPageWrapper from 'application/pages/RegisterPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const registerRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: REGISTER_PAGE_PATH,
        element: <RegisterPageWrapper />,
      },
      {
        path: REGISTER_PAGE_PATH_ALIAS,
        element: <RegisterPageWrapper />,
      },
    ],
  },
];
