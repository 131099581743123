import {
  AuthCurrentUserDocument,
  useAuthUserPureSignUpByPhoneMutation,
  useAuthUserSignUpMutation,
  UserPureSignUpByPhoneMutationInput,
  UserPureSignUpMutationInput,
  UtmMarkKeyType,
  UtmMarksInput,
} from 'domain/api/graphql/generated';
import { useURLSearchParams } from 'application/providers/RouterProvider';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';
// TODO: этого тут не должно быть
import ActivationService, { ActivationTypes } from 'application/services/ActivationService';

import AuthUtils, { ROISTAT_VISIT_COOKIE_NAME, SBER_AGENT_ID_COOKIE_NAME } from './utils';
import {
  AuthTokenType,
  RegisterFormInputType,
  RegisterPhoneFormInputType,
  RegisterQueryKeys,
  UserDataType,
  YandexMetricaParams,
} from './types';

const MOBILE_UTM_SOURCE = 'mobile';

const useRegister = () => {
  const [mutationEmailReg, mutationEmailRegResult] = useAuthUserSignUpMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const [mutationPhoneReg, mutationPhoneRegResult] = useAuthUserPureSignUpByPhoneMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  // TODO: этого тут не должно быть
  const [promocode, utmSource, registrationUrl, promoCreateBot, crmLeadId, tariff, platform, skipRequisitesStep] =
    useURLSearchParams([
      RegisterQueryKeys.PROMOCODE,
      RegisterQueryKeys.UTM_SOURCE,
      RegisterQueryKeys.REGISTRATION_URL,
      RegisterQueryKeys.PROMO_CREATE_BOT,
      RegisterQueryKeys.CRM_KEY,
      RegisterQueryKeys.TARIFF,
      RegisterQueryKeys.PLATFORM,
      RegisterQueryKeys.SKIP_REQUISITES_STEP,
    ]);

  const prepareUTMMarks = () => {
    const utmMarks: UtmMarksInput[] = [];
    const ymId = localStorage.getItem(YandexMetricaParams.ID);

    const roistatVisitCookieValue = AuthUtils.getCookieByKey(ROISTAT_VISIT_COOKIE_NAME);
    if (roistatVisitCookieValue) {
      utmMarks.push({ key: UtmMarkKeyType.Roistat, value: roistatVisitCookieValue });
    }

    const sberAgentIdCookieValue = AuthUtils.getCookieByKey(SBER_AGENT_ID_COOKIE_NAME);
    if (sberAgentIdCookieValue) {
      utmMarks.push({ key: UtmMarkKeyType.SberAgentId, value: sberAgentIdCookieValue });
    }

    const fromYandexSkill = ActivationService.getActivationType() === ActivationTypes.ALICE;

    if (fromYandexSkill) {
      utmMarks.push({ key: UtmMarkKeyType.Alice, value: 'Alice' });
    }

    if (promocode) {
      utmMarks.push({ key: UtmMarkKeyType.Promocode, value: promocode });
    }

    if (utmSource) {
      if (utmSource === MOBILE_UTM_SOURCE) {
        utmMarks.push({ key: UtmMarkKeyType.Mobile, value: 'Mobile' });
      }

      utmMarks.push({ key: UtmMarkKeyType.UtmSource, value: utmSource });
    }

    if (promoCreateBot) {
      utmMarks.push({ key: UtmMarkKeyType.PromoCreateBot, value: 'PromoCreateBot' });
    }

    if (tariff) {
      utmMarks.push({ key: UtmMarkKeyType.Tariff, value: tariff });
    }

    if (platform) {
      utmMarks.push({ key: UtmMarkKeyType.Platform, value: platform });
    }

    if (ymId) {
      utmMarks.push({ key: UtmMarkKeyType.YmId, value: ymId });
    }

    return utmMarks;
  };

  const requestEmail = async (input: RegisterFormInputType) => {
    const variablesInput: UserPureSignUpMutationInput = {
      ...input,
      utmMarks: prepareUTMMarks(),
      crmLeadId,
      registrationUrl,
    };

    if (skipRequisitesStep) {
      variablesInput.skipRequisitesStep = skipRequisitesStep === 'true';
    }

    const result = await mutationEmailReg({
      variables: {
        input: variablesInput,
      },
      refetchQueries: [AuthCurrentUserDocument],
    });

    return !!result.data?.auth?.ok;
  };

  const requestPhone = async (input: RegisterPhoneFormInputType) => {
    const variablesInput: UserPureSignUpByPhoneMutationInput = {
      ...input,
      crmLeadId,
      utmMarks: prepareUTMMarks(),
    };

    if (skipRequisitesStep) {
      variablesInput.skipRequisitesStep = skipRequisitesStep === 'true';
    }

    const result = await mutationPhoneReg({
      variables: {
        input: variablesInput,
      },
      update: (cache, result) => {
        cache.writeQuery({
          id: `UserExtendPureType:${result.data?.userPureSignUpByPhone?.user.id}`,
          query: AuthCurrentUserDocument,
          data: {
            result,
          },
        });
      },
    });

    return {
      ok: !!result.data?.userPureSignUpByPhone?.ok,
      token: result.data?.userPureSignUpByPhone?.token.value as AuthTokenType,
      user: result.data?.userPureSignUpByPhone?.user as UserDataType,
    };
  };

  const signUpEmailData = mutationEmailRegResult.data?.auth;

  return {
    email: {
      error: mutationEmailRegResult.error,
      loading: mutationEmailRegResult.loading,
      called: mutationEmailRegResult.called,
      data: {
        errors: signUpEmailData?.errors ? GraphQLApiUtils.errorsArrayToObject(signUpEmailData?.errors) : undefined,
        ok: signUpEmailData?.ok,
      },
      requestEmail,
    },
    phone: {
      error: mutationPhoneRegResult.error,
      loading: mutationPhoneRegResult.loading,
      called: mutationPhoneRegResult.called,
      requestPhone,
    },
  };
};

export default useRegister;
