import React, { useEffect, FC } from 'react';

import { useCompanyTagsContext } from 'application/components/common';
import { ModalProps, ModalTypes, useModalContext } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Autocomplete from '@zvuk-b2b/react-uikit/ui/Autocomplete';
import { InputSize } from '@zvuk-b2b/react-uikit/ui/InputSearch';
import IconChevronDown from '@zvuk-b2b/react-uikit/ui/icons/ChevronDown';
import { DropdownPlacement } from '@zvuk-b2b/react-uikit/ui/Dropdown';

import { TEXTS } from './texts';
import withProviders from './withProviders';

import styles from './index.module.scss';

const LinkedTagsModal: FC<ModalProps[ModalTypes.LINKED_TAGS]> = ({ initialTags, onSubmit, close }) => {
  const {
    value,
    options,
    maxVisibleOptions,
    menuMargin,
    extensions,
    inputRef,
    modalContentRef,
    isLoading,
    isAddTagLoading,
    isSetLoading,
    isDesktop,
    isTagsSubmitDisabled,
    autocompletePlaceholder,
    initTags,
    onChange,
    onBackspacePressed,
    onEnterPressed,
    onSetTagsSubmit,
  } = useCompanyTagsContext();

  const { isModalOpen } = useModalContext();

  useEffect(() => {
    initTags(initialTags);
    onChange('');

    return () => onChange('');
  }, []);

  return (
    <ModalScreen
      title={initialTags.length !== 0 ? TEXTS.MODAL_CHANGE_TITLE : TEXTS.MODAL_ADD_TITLE}
      onClose={close}
      className={styles.LinkedTagsModal}
      disableScroll={isModalOpen}
      actionButtons={[
        <Button
          key="button_confirm_link_tags"
          elementId="button_confirm_link_tags"
          type={ButtonType.PRIMARY}
          className={styles.LinkedTagsModalActionButton}
          isDisabled={isTagsSubmitDisabled}
          isLoading={isSetLoading}
          onClick={() => onSetTagsSubmit(onSubmit)}>
          {TEXTS.MODAL_CONFIRM_BUTTON}
        </Button>,
      ]}>
      <div
        className={styles.LinkedTagsModalContent}
        ref={modalContentRef}>
        <div className={styles.LinkedTagsModalDescription}>{TEXTS.MODAL_TEXT}</div>

        <Autocomplete
          elementId="linked_tags_modal_autocomplete"
          autoComplete="off"
          value={value}
          options={options}
          inputRef={inputRef}
          error={false}
          isLoading={isLoading || isSetLoading}
          onChange={onChange}
          onBackspacePressed={onBackspacePressed}
          onEnterPressed={onEnterPressed}
          label={TEXTS.MODAL_AUTOCOMPLETE_LABEL}
          placeholder={extensions.length === 0 ? autocompletePlaceholder : ''}
          containerClassName={styles.LinkedTagsModalAutocomplete}
          dropdownClassName={styles.LinkedTagsModalAutocompleteDropdownWithKeyboard}
          inputClassName={styles.LinkedTagsModalAutocompleteInput}
          clearIconClassName={styles.LinkedTagsModalAutocompleteClearIcon}
          useLargeOptionsContainer
          dropDownWidth="430px"
          size={InputSize.MEDIUM}
          useClearButton
          isMulti
          isFocused={isDesktop}
          isDisabled={isAddTagLoading || isSetLoading}
          extensions={extensions}
          maxVisibleOptions={maxVisibleOptions}
          maxOptions={options.length}
          menuMargin={menuMargin}
          isNeedCloseWhileParentScrolling={false}
          adornmentRight={<IconChevronDown className={styles.LinkedTagsModalAutocompleteChevronDownIcon} />}
          dropdownPlacement={DropdownPlacement.BOTTOM}
        />
      </div>
    </ModalScreen>
  );
};

export default withProviders(LinkedTagsModal);
