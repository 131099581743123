import { ErrorsMapType, BaseMutationResultType, BaseQueryResultType } from 'domain/api/graphql/types';
import {
  UserExtendPureType,
  UserPureSignUpMutationInput,
  JwtTokenAuthPureMutationInput,
  SendEmailPureRepeatMutationInput,
  PasswordResetConfirmationMutationInput,
  PasswordPureSetNewMutationInput,
  UserInvitationType,
  PaginationInfoType,
  UserCompanyUpdateMutationInput,
  ContractAcceptMigrationMutationInput,
  UserPureSignUpYClientsMutationInput,
  UserPureSignUpByPhoneMutationInput,
  UserCompanyShortTypeFragment,
  ErrorType,
} from 'domain/api/graphql/generated';

export enum SMSStatus {
  QUEUED = 'queued',
  SENT = 'sent',
  DELIVERED = 'delivered',
  EXPIRED = 'expired',
  NOT_DELIVERED = 'not_delivered',
  UNKNOWN = 'unknown',
  REJECTED = 'rejected',
  READ = 'read',
  ERROR = 'error',
}

/* useLogin Types */
export type AuthTokenType = string;

export type UserDataType = Pick<
  UserExtendPureType,
  | 'id'
  | 'email'
  | 'firstName'
  | 'smartLkAccess'
  | 'registrationSource'
  | 'mainCompany'
  | 'pointsExists'
  | 'companies'
  | 'phone'
  | 'isTest'
  | 'personalAccountAccess'
>;

export type LoginInputType = Pick<JwtTokenAuthPureMutationInput, 'password' | 'username'>;

// TODO: убрать от сюда
export enum LoginQueryKeys {
  EMAIL = 'email',
  PHONE = 'phone',
  BINDING_CODE = 'binding_code',
  FROM_SOURCE = 'from_source',
}

// TODO: убрать от сюда
export enum AliceQueryKeys {
  STATE = 'state',
  REDIRECT_URI = 'redirect_uri',
  RESPONSE_TYPE = 'response_type',
  CLIENT_ID = 'client_id',
  SCOPE = 'scope',
}

/* useLogout Types */
export type LogoutResultType = BaseMutationResultType & {
  data: {
    deleted?: boolean;
    revoked?: number;
  };
};

export type UseLogoutType = [request: () => Promise<LogoutResultType['data']>, result: LogoutResultType];

/* useRegister Types */
export type RegisterFormInputType = Pick<
  UserPureSignUpMutationInput,
  'password' | 'email' | 'phone' | 'country' | 'referralUuid'
>;
export type RegisterPhoneFormInputType = Pick<
  UserPureSignUpByPhoneMutationInput,
  'phone' | 'code' | 'country' | 'referralUuid'
>;

export enum RegisterQueryKeys {
  PROMOCODE = 'promocode',
  SBER_AGENT_ID = 'employee_id',
  EMAIL = 'email',
  PHONE = 'phone',
  SALON_ID = 'salon_id',
  USER_ID = 'user_id',
  UTM_SOURCE = 'utm_source',
  FROM_SOURCE = 'from_source',
  REGISTRATION_URL = 'registration_url',
  BINDING_CODE = 'binding_code',
  PROMO_CREATE_BOT = 'promo_create_bot',
  CRM_KEY = 'crm_key',
  REF = 'ref',
  TARIFF = 'tariff',
  FROM = 'from',
  PLATFORM = 'platform',
  SKIP_REQUISITES_STEP = 'skip_requisites_step',
}

/* useSpecialRegister Types */
export type YClientsRegisterInputType = Pick<
  UserPureSignUpYClientsMutationInput,
  'password' | 'email' | 'phone' | 'utmMarks' | 'salonId' | 'userId'
>;
export type YClientsRegisterFormInputType = Pick<
  YClientsRegisterInputType,
  'password' | 'email' | 'phone' | 'salonId' | 'userId'
>;

export type SpecialRegisterResultType = BaseMutationResultType & {
  data: {
    ok?: boolean | null;
    errors?: ErrorsMapType;
  };
};

export type UseYClientsRegisterType = [
  requestYClients: (input: YClientsRegisterFormInputType) => Promise<boolean>,
  result: SpecialRegisterResultType,
];

/* usePasswordReset Types */
export type RegisterResendEmailSendInputType = Pick<SendEmailPureRepeatMutationInput, 'email'>;
export type ForgotPasswordEmailSendInputType = RegisterResendEmailSendInputType;

export type RegisterResendEmailSendRequestType = (input: RegisterResendEmailSendInputType) => Promise<boolean>;
export type ForgotPasswordEmailSendRequestType = RegisterResendEmailSendRequestType;

export type RegisterResendEmailSendResultType = BaseMutationResultType & {
  data: {
    ok?: boolean | null;
    errors?: ErrorsMapType;
  };
};

export type ForgotPasswordEmailSendResultType = RegisterResendEmailSendResultType;

export type UseEmailSendType = [
  request: [RegisterResendEmailSendRequestType, ForgotPasswordEmailSendRequestType],
  result: [RegisterResendEmailSendResultType, ForgotPasswordEmailSendResultType],
];

/* usePasswordReset Types */
export type PasswordResetInputType = Pick<PasswordResetConfirmationMutationInput, 'newPassword1' | 'token' | 'uid'>;

export type PasswordResetResultType = BaseMutationResultType & {
  data: {
    ok?: boolean | null;
    errors?: ErrorsMapType;
  };
};

export type UsePasswordResetType = [
  request: (input: PasswordResetInputType) => Promise<boolean>,
  result: PasswordResetResultType,
];

/* usePasswordChange Types */
export type PasswordChangeInputType = Pick<PasswordPureSetNewMutationInput, 'currentPassword' | 'newPassword'>;

export type PasswordChangeResultType = BaseMutationResultType & {
  data: {
    ok?: boolean | null;
    errors?: ErrorsMapType;
  };
};

export type UsePasswordChangeType = [
  request: (input: PasswordChangeInputType) => Promise<boolean>,
  result: PasswordChangeResultType,
];

/* useCurrentUser Types */
export type CurrentUserResultType = BaseQueryResultType & {
  data: {
    user?: UserDataType;
  };
  refetching: boolean;
};

export type UseCurrentUserConfig = {
  onCompleted?: (data: UserDataType) => void;
};

export type UseCurrentUserType = [request: () => Promise<UserDataType>, result: CurrentUserResultType];

/* useUtmMarks Types */
export type UseUTMMarksType = [request: () => Promise<boolean>];

/* useCurrentUser Types */
export type InvitationErrorsType = {
  phone?: string;
  password?: string | string[];
};

export type InvitationFormErrorsType = InvitationErrorsType & {
  graphQL?: string;
};

export type InvitationResultDataType = {
  ok: boolean;
  invitation?: UserInvitationType | undefined;
  errors?: InvitationErrorsType | undefined;
};

export type InvitationResultType = BaseMutationResultType & {
  data: {
    create: InvitationResultDataType;
    send: InvitationResultDataType;
    deactivate: InvitationResultDataType;
    accept: InvitationResultDataType;
    delete: InvitationResultDataType;
  };
};

export type UseInvitationType = [
  request: {
    create: (email: string, companyId: string) => Promise<UserInvitationType>;
    send: (invitationId: string, returnUrl: string) => Promise<UserInvitationType>;
    deactivate: (invitationId: string) => Promise<UserInvitationType>;
    accept: (token: string, phone: string, password: string) => Promise<boolean>;
    delete: (userId: string, companyId: string) => Promise<boolean>;
  },
  result: InvitationResultType,
];

/* useCurrentUser Types */
export type InvitationsResultType = BaseMutationResultType & {
  data: {
    items: UserInvitationType[];
    paginationInfo?: PaginationInfoType;
  };
};

export type UseInvitationsType = [request: (companyId: string) => Promise<void>, result: InvitationsResultType];

/* useCompanyUpdate Types */
export type CompanyUpdateInputType = UserCompanyUpdateMutationInput;

export type CompanyUpdateResultType = BaseMutationResultType & {
  data: {
    ok?: boolean | null;
    errors?: ErrorsMapType;
  };
};

export type UseCompanyUpdateDataType = {
  ok: boolean;
  company: Partial<UserCompanyShortTypeFragment>;
  errors?: (ErrorType | null | undefined)[] | null | undefined;
};

export type UseCompanyUpdateType = {
  request: (input: CompanyUpdateInputType) => Promise<UseCompanyUpdateDataType>;
  result: CompanyUpdateResultType;
  clearErrors: () => void;
};

/* usePasswordReset Types */
export type ContractAcceptMigrationInputType = Pick<ContractAcceptMigrationMutationInput, 'companyId'>;

export type ContractAcceptMigrationResultType = BaseMutationResultType & {
  data: {
    ok?: boolean | null;
  };
};

export type UseContractAcceptMigrationType = [
  request: (input: ContractAcceptMigrationInputType) => Promise<boolean>,
  result: ContractAcceptMigrationResultType,
];

export enum YandexMetricaParams {
  ID = '_ym_uid',
}
