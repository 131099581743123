import React from 'react';

import classNames from 'classnames';
import styles from './index.module.scss';

const SelectableListItemSkeleton = () => (
  <div
    className={styles.SelectableListItemSkeleton}
    data-testid="selectable-list-item-skeleton">
    <div className={styles.SelectableListItemSkeletonTitle}>
      <div className={classNames(styles.SelectableListItemSkeletonLine, styles.SelectableListItemSkeletonLongLine)} />
      <div className={styles.SelectableListItemSkeletonLine} />
      <div className={styles.SelectableListItemSkeletonLine} />
    </div>
    <div className={styles.SelectableListItemSkeletonSelector} />
  </div>
);

export default SelectableListItemSkeleton;
