import React, { Suspense } from 'react';
import PlayerProvider from 'application/providers/PlayerProvider';
import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export const VIDEO_STREAMS_PAGE_PATH = '/video-streams/:id';
export const VIDEO_STREAMS_MENU_PATH = '/video-streams/all';

export enum VideoStreamsPageParamsValues {
  ALL = 'all',
}

export type VideoStreamsPageParamsType = {
  id?: string | VideoStreamsPageParamsValues;
};

const Renderer = React.lazy(() => import('./renderer'));

const VideoStreamPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <PlayerProvider>
      <Renderer />
    </PlayerProvider>
  </Suspense>
);

VideoStreamPageWrapper.config = {
  routes: [
    {
      path: VIDEO_STREAMS_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default VideoStreamPageWrapper;
