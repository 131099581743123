import { useLayoutEffect } from 'react';

const CSS_VAR_APP_HEIGHT = '--app-height';

const setCssVar = (visualViewport?: VisualViewport) => {
  document.documentElement.style.setProperty(CSS_VAR_APP_HEIGHT, `${visualViewport?.height || window.innerHeight}px`);
};

function appVisualViewportResizeHandler(this: VisualViewport, _ev: Event) {
  setCssVar(this);
}

const useAppHeightCSSVar = () => {
  useLayoutEffect(() => {
    setCssVar();
    window.visualViewport?.addEventListener('resize', appVisualViewportResizeHandler);

    return () => {
      window.visualViewport?.removeEventListener('resize', appVisualViewportResizeHandler);
    };
  }, []);
};

export default useAppHeightCSSVar;
