import {
  AuthCurrentUserDocument,
  CompanyCreateDeviceRequestPureMutationInput,
  useCompanyDeviceRequestMutation,
} from 'domain/api/graphql/generated';
import { BaseMutationResultType } from 'domain/api/graphql/types';

const useCompanyRequestDevice = () => {
  const [requestDeviceMutation, companyCreateDeviceRequestResult] = useCompanyDeviceRequestMutation();

  const request = async (input: CompanyCreateDeviceRequestPureMutationInput) => {
    const response = await requestDeviceMutation({
      variables: {
        input,
      },
      refetchQueries: [AuthCurrentUserDocument],
    });

    return {
      ok: !!response.data?.companyCreateDeviceRequest?.ok,
      errors: response.data?.companyCreateDeviceRequest?.errors,
    };
  };

  const result: BaseMutationResultType = {
    error: companyCreateDeviceRequestResult.error,
    loading: companyCreateDeviceRequestResult.loading,
    called: companyCreateDeviceRequestResult.called,
  };

  return { request, result };
};

export default useCompanyRequestDevice;
