import useCompanyMediafiles from 'domain/company/useCompanyMediafiles';
import { GetCompanyMediafiles } from '../company/types';

const useVideoStreamRepository = () => {
  const [requestCompanyMediafiels, companyMediafilesResult] = useCompanyMediafiles();

  const getCompanyMediafiles = async ({ pagination, filters }: GetCompanyMediafiles) => {
    await requestCompanyMediafiels(filters, pagination);
  };

  const getMoreCompanyMediafiles = async (params: GetCompanyMediafiles) => {
    await companyMediafilesResult.loadMore(params.filters, params.pagination);
  };

  const refetchCompanyMediafiles = async (params: GetCompanyMediafiles) => {
    await companyMediafilesResult.refetch(params);
  };

  return {
    getCompanyMediafiles,
    getMoreCompanyMediafiles,
    refetchCompanyMediafiles,
    companyMediafiles: companyMediafilesResult?.data?.mediafiles,
    pagination: companyMediafilesResult?.data?.paginationInfo,
    companyMediafilesCalled: companyMediafilesResult.called,
    companyMediafilesLoading: companyMediafilesResult.loading,
    companyMediafilesError: companyMediafilesResult.error,
    companyMediafilesNetworkStatus: companyMediafilesResult.networkStatus,
  };
};

export default useVideoStreamRepository;
