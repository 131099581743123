import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import React from 'react';
import CollapsibleSection from '../CollapsibleSection';
import styles from './index.module.scss';

const examplesSrc = [
  '/assets/img/cut_music_examples/example-1.svg',
  '/assets/img/cut_music_examples/example-2.svg',
  '/assets/img/cut_music_examples/example-3.svg',
  '/assets/img/cut_music_examples/example-4.svg',
  '/assets/img/cut_music_examples/example-5.svg',
];

const HelpModalContent = () => (
  <div className={styles.HelpModalInner}>
    <div className={styles.HelpModalText}>{TEXTS.CUT_MUSIC_HELP_MODAL_DESCRIPTION_1}</div>
    <CollapsibleSection title={TEXTS.CUT_MUSIC_HELP_MODAL_EXAMPLES}>
      <div className={styles.HelpModalExamples}>
        <ul className={styles.HelpModalExamplesList}>
          <li className={styles.HelpModalExamplesListItem}>
            {TEXTS.CUT_MUSIC_HELP_MODAL_EXAMPLE_1}
            <div className={styles.HelpModalSvgContainer}>
              <img
                className={styles.HelpModalExampleSvg}
                alt=""
                src={examplesSrc[0]}
              />
            </div>
          </li>
          <li className={styles.HelpModalExamplesListItem}>
            {TEXTS.CUT_MUSIC_HELP_MODAL_EXAMPLE_2}
            <div className={styles.HelpModalSvgContainer}>
              <img
                className={styles.HelpModalExampleSvg}
                alt=""
                src={examplesSrc[1]}
              />
              <img
                className={styles.HelpModalExampleSvg}
                alt=""
                src={examplesSrc[2]}
              />
            </div>
          </li>
        </ul>
      </div>
    </CollapsibleSection>
    <div className={styles.HelpModalText}>{TEXTS.CUT_MUSIC_HELP_MODAL_DESCRIPTION_2}</div>
    <div className={styles.HelpModalText}>{TEXTS.CUT_MUSIC_HELP_MODAL_DESCRIPTION_3}</div>
    <ul className={styles.HelpModalExamplesList}>
      <li className={styles.HelpModalExamplesListItem}>
        <div className={styles.HelpModalExamplesListHeading}>{TEXTS.CUT_MUSIC_HELP_MODAL_HEADING_1}</div>
        {TEXTS.CUT_MUSIC_HELP_MODAL_DESCRIPTION_4}
        <div className={styles.HelpModalSvgContainer}>
          <img
            className={styles.HelpModalExampleSvg}
            alt=""
            src={examplesSrc[3]}
          />
        </div>
      </li>
      <li className={styles.HelpModalExamplesListItem}>
        <div className={styles.HelpModalExamplesListHeading}>{TEXTS.CUT_MUSIC_HELP_MODAL_HEADING_2}</div>
        {TEXTS.CUT_MUSIC_HELP_MODAL_DESCRIPTION_5}
        <div className={styles.HelpModalSvgContainer}>
          <img
            className={styles.HelpModalExampleSvg}
            alt=""
            src={examplesSrc[4]}
          />
        </div>
      </li>
    </ul>
  </div>
);

export default HelpModalContent;
