import React, { FC } from 'react';

import { TEXTS } from 'application/pages/BillingPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import useCardRemoveModalPresenter from './useCardRemoveModalPresenter';
import useCardInformationController from './useRemoveCardModalController';

import styles from './index.module.scss';

const CardRemoveModal: FC<ModalProps[ModalTypes.BILLING_REMOVE_CARD]> = ({ close }) => {
  const { removeCard, removeCardLoading } = useCardRemoveModalPresenter();

  const { removeCardSubmit } = useCardInformationController({ removeCard });

  return (
    <ModalScreen
      title={TEXTS.BILLING_REMOVE_CARD_MODAL_TITLE}
      titlePlacement="left"
      hideCloseButton
      onClose={close}
      actionButtons={[
        <Button
          key="button_confirm_remove_card_modal"
          elementId="button_confirm_remove_card_modal"
          isLoading={removeCardLoading}
          type={ButtonType.PRIMARY}
          onClick={removeCardSubmit}>
          {TEXTS.BILLING_REMOVE_CARD_MODAL_CONFIRM}
        </Button>,
        <Button
          key="button_cancel_remove_card_modal"
          elementId="button_cancel_remove_card_modal"
          type={ButtonType.SECONDARY}
          isLoading={removeCardLoading}
          onClick={close}>
          {TEXTS.BILLING_BIND_CARD_MODAL_CANCEL}
        </Button>,
      ]}>
      <div className={styles.CardRemoveModalDesc}>{TEXTS.BILLING_REMOVE_CARD_MODAL_DESCRIPTION}</div>
    </ModalScreen>
  );
};

export default CardRemoveModal;
