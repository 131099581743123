import PlayRounded from '@zvuk-b2b/react-uikit/ui/icons/PlayRounded';
import React, { FC } from 'react';
import TrackIcon from '@zvuk-b2b/react-uikit/ui/icons/Track';
import PauseRounded from '@zvuk-b2b/react-uikit/ui/icons/PauseRounded';
import classnames from 'classnames';
import { Colors } from '@zvuk-b2b/react-uikit/ui/types';
import Spinner from '@zvuk-b2b/react-uikit/ui/Spinner';
import styles from './index.module.scss';

interface PlayButtonProps {
  isMediafilePlaying: boolean;
  isMediaFilePaused: boolean;
  isLoading?: boolean;
  isDemo?: boolean;
  className?: string;
  trackIconClassName?: string;
}

interface PlayButtonIconProps {
  isMediafilePlaying: PlayButtonProps['isMediafilePlaying'];
  isMediaFilePaused: PlayButtonProps['isMediaFilePaused'];
  isLoading?: PlayButtonProps['isLoading'];
  className?: PlayButtonProps['className'];
}

const PlayButtonIcon: FC<PlayButtonIconProps> = (props) => {
  const { isLoading, isMediafilePlaying, isMediaFilePaused, className } = props;

  if (isLoading) {
    return (
      <Spinner
        color={Colors.COLOR_WHITE}
        className={styles.PlayButtonSpiner}
      />
    );
  }

  if (isMediafilePlaying && !isMediaFilePaused) {
    return <PauseRounded className={classnames(styles.PlayButtonIcon, styles.PlayButtonIconPause)} />;
  }

  return (
    <PlayRounded
      className={classnames(styles.PlayButtonIcon, className, {
        [styles.PlayButtonIconVisible]: isMediafilePlaying && isMediaFilePaused,
      })}
    />
  );
};

export const PlayButton: FC<PlayButtonProps> = (props) => {
  const { isMediafilePlaying, isMediaFilePaused, isLoading, isDemo, className, trackIconClassName } = props;

  return (
    <div className={styles.PlayButton}>
      <TrackIcon
        className={classnames(styles.PlayButtonTrackIcon, trackIconClassName, {
          [styles.PlayButtonTrackIconPlaying]: isMediafilePlaying && !isDemo,
          [styles.PlayButtonTrackIconDemo]: isDemo,
          [styles.PlayButtonTrackIconDefault]: !isDemo,
        })}
      />
      <PlayButtonIcon
        isMediaFilePaused={isMediaFilePaused}
        isMediafilePlaying={isMediafilePlaying}
        isLoading={isLoading}
        className={className}
      />
    </div>
  );
};
