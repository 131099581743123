import { useApolloClient } from 'application/providers/DataProvider';

import useCompanyTags from 'domain/company/useCompanyTags';
import {
  CompanyTagQueryPaginationType,
  CompanyUserCompanyTagsDocument,
  CompanyUserCompanyTagsQuery,
} from 'domain/api/graphql/generated';

import { GetCompanyTagsType, UseCompanyTagsRepositoryType } from './types';

const useCompanyRepository = (): UseCompanyTagsRepositoryType => {
  const client = useApolloClient();

  const [requestTags, companyTagsResult] = useCompanyTags();

  const getCompanyTags = async ({ filters, pagination }: GetCompanyTagsType) => {
    await requestTags(filters, pagination);
  };

  const getMoreCompanyTags = async ({ filters, pagination }: GetCompanyTagsType) => {
    await companyTagsResult.loadMore(filters, pagination);
  };

  const refetchCompanyTags = async (params?: GetCompanyTagsType) => {
    await companyTagsResult.refetch(params);
  };

  const readCompanyTags = ({ filters, pagination }: GetCompanyTagsType) => {
    const result = client.readQuery<CompanyUserCompanyTagsQuery>({
      query: CompanyUserCompanyTagsDocument,
      variables: { filters, pagination },
    });

    return (result?.companyTagPaginationQuery as CompanyTagQueryPaginationType) || null;
  };

  return {
    getCompanyTags,
    readCompanyTags,
    getMoreCompanyTags,
    refetchCompanyTags,
    companyTagsCalled: companyTagsResult.called,
    companyTagsLoading: companyTagsResult.loading,
    companyTagsError: companyTagsResult.error,
    companyTagsNetworkStatus: companyTagsResult.networkStatus,
  };
};

export default useCompanyRepository;
