export enum KeyboardEventCode {
  ENTER = 'Enter',
  TAB = 'Tab',
  BACKSPACE = 'Backspace',
}

export type TextWidthType = {
  width: number;
  formattedWidth: string;
};

export enum BrowserEvents {
  CLICK = 'click',
  MOUSE_MOVE = 'mousemove',
}

export type SafariWindow = Window & { safari: object };
