import {
  useBillingExtendSubscriptionRequestMutation,
  ExtendSubscriptionRequestMutationInput,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useExtendSubscriptionRequest = () => {
  const [mutation, mutationResult] = useBillingExtendSubscriptionRequestMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ExtendSubscriptionRequestMutationInput) => {
    const mutationResult = await mutation({
      variables: {
        input,
      },
    });

    return !!mutationResult.data?.result?.ok;
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
  };

  return {
    request,
    result,
  };
};

export default useExtendSubscriptionRequest;
