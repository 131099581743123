export const TEXTS = {
  LISTEN_TRACK: 'Послушать трек',
  CANCEL: 'Отменить',
  CONTENT: `У вас играет музыка в веб-плеере.
  Если вы запустите трек, то веб-плеер будет поставлен на паузу.`,
  STOP_WEB_PLAYER_TITLE: 'Остановить играющую музыку?',

  WIDGET_CONTENT: 'У вас в веб-плеере играет музыка, еси вы его закроете, музыка будет остановлена',
  WIDGET_SUBMIT: 'Закрыть веб плеер',
};
