import React from 'react';

import { VIDEO_STREAMS_PAGE_PATH } from 'application/consts';
import SmartLayout from 'application/layout/SmartLayout';
import VideoStreamPageWrapper from 'application/pages/VideoStreamsPage';
import { RouteConfig } from '..';

export const videoStreamsRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: VIDEO_STREAMS_PAGE_PATH,
        element: <VideoStreamPageWrapper />,
      },
    ],
  },
];
