import { useApolloClient } from 'application/providers/DataProvider';

import { PointWithDevice } from 'application/presenters/point/types';

import usePointList from 'domain/point/usePointList';
import useTimezones from 'domain/point/useTimezones';
import usePoint from 'domain/point/usePoint';
import usePointCreate from 'domain/point/usePointCreate';
import usePointUpdate from 'domain/point/usePointUpdate';
import usePointRemove from 'domain/point/usePointRemove';
import usePointSetAds from 'domain/point/usePointSetAds';
import usePointSetCompanyTags from 'domain/point/usePointSetCompanyTags';
import usePointSleepSettings from 'domain/point/usePointSleepSettings';
import usePointChangeDevice from 'domain/point/usePointChangeDevice';
import usePointBroadcastHistory from 'domain/point/usePointBroadcastHistory';
import usePointBroadcastHistoryReportCreate from 'domain/point/usePointBroadcastHistoryReportCreate';
import usePointTimezone from 'domain/point/usePointTimezone';
import usePointsMetadata from 'domain/point/usePointsMetadata';

import { PointCreateRequestResultType, PointType } from 'domain/point/types';
import {
  CertificatePointsQueryPaginationType,
  PointCerttificateListDocument,
  PointCerttificateListQuery,
  PointListDocument,
  PointListMetadataDocument,
  PointListMetadataQuery,
  PointListQuery,
  PointPureSetAdCampaignsMutationInput,
  PointPureSleepSettingsMutationInput,
  PointPureUpdateMutationInput,
  PointShortTypeFragment,
  PointsQueryPaginationType,
  PointSwitchDeviceMutationInput,
  SmartlkPointCreateMutationInput,
  StandardizedAddressPureType,
} from 'domain/api/graphql/generated';
import usePointsCertificate from 'domain/point/usePointsCertificate';
import { GetCertificatePointsType, GetPointsType, UsePointRepositoryResultType } from './types';
import { POINT_SET_CURRENT_IN_ONBOARDING_QUERY } from './queries';

const usePointRepository = (): UsePointRepositoryResultType => {
  const client = useApolloClient();
  const [pointsRequest, pointsResult] = usePointList();
  const [pointsCertificateRequest, pointsCertificateResult] = usePointsCertificate();
  const [pointsMetadataRequest, pointsMetadataResult] = usePointsMetadata();

  const { timezones, request: requestTimezones } = useTimezones();
  const { loading: pointLoading, point, request: requestPoint } = usePoint();
  const { request: pointWithTimezoneRequest, loading: getStandardizedPointsLoading } = usePointTimezone();

  const pointCreate = usePointCreate();
  const pointUpdate = usePointUpdate();
  const pointRemove = usePointRemove();
  const { request: updatePointAds, error: pointAdsUpdateError, loading: pointAdsUpdateLoading } = usePointSetAds();
  const [setTagsRequest] = usePointSetCompanyTags();
  const { request: updatePointSleepSettings, loading: updatePointSleepSettingsLoading } = usePointSleepSettings();
  const {
    request: updatePointDevice,
    loading: updateDeviceLoading,
    error: updateDeviceError,
    point: updateDevicePoint,
  } = usePointChangeDevice();
  const pointBroadcastHistory = usePointBroadcastHistory();
  const pointBroadcastHistoryReportCreate = usePointBroadcastHistoryReportCreate();

  const getPoints = async ({ filters, pagination, sort }: GetPointsType) => {
    await pointsRequest(filters, pagination, sort);
  };

  const readPoints = ({ filters, pagination, sort }: GetPointsType) => {
    const result = client.readQuery<PointListQuery>({
      query: PointListDocument,
      variables: { filters, pagination, sort },
    });

    return (result?.pointsPaginationQuery as PointsQueryPaginationType) || null;
  };

  const refetchPoints = async (params?: GetPointsType) => {
    const result = await pointsResult.refetch(params);

    return result.data?.pointsPaginationQuery.result as PointShortTypeFragment[];
  };

  const getCertificatePoints = async ({ filters, pagination }: GetCertificatePointsType) => {
    await pointsCertificateRequest(filters, pagination);
  };

  const readCertificatePoints = ({ filters, pagination }: GetCertificatePointsType) => {
    const result = client.readQuery<PointCerttificateListQuery>({
      query: PointCerttificateListDocument,
      variables: { filters, pagination },
    });

    return (result?.certificatePointsQuery as CertificatePointsQueryPaginationType) || null;
  };

  const getMorePoints = async ({ filters, pagination, sort }: GetPointsType) => {
    await pointsResult.loadMore(filters, pagination, sort);
  };

  const getMoreCertificatePoints = async ({ filters, pagination }: GetCertificatePointsType) => {
    const result = await pointsCertificateResult.loadMore(filters, pagination);

    return result.data.certificatePointsQuery.result as PointShortTypeFragment[];
  };

  const getPointsMetadata = async ({ filters, pagination, sort }: GetPointsType) => {
    await pointsMetadataRequest(filters, pagination, sort);
  };

  const getMorePointsMetadata = async ({ filters, pagination, sort }: GetPointsType) => {
    await pointsMetadataResult.loadMore(filters, pagination, sort);
  };

  const readPointsMetadata = ({ filters, pagination, sort }: GetPointsType): PointsQueryPaginationType => {
    const result = client.readQuery<PointListMetadataQuery>({
      query: PointListMetadataDocument,
      variables: { filters, pagination, sort },
    });

    return (result?.pointsPaginationQuery as PointsQueryPaginationType) || null;
  };

  const getTimezones = async () => {
    await requestTimezones();
  };

  const getPoint = async (id: string) => {
    const result = await requestPoint(id);
    return result.data?.pointPure as PointType;
  };

  const getStandardizedPoints = async (queryParams: string) => {
    const result = await pointWithTimezoneRequest(queryParams);
    return result.data?.standardizedAddressPureQuery.result as StandardizedAddressPureType[];
  };

  const createPoint = async (input: SmartlkPointCreateMutationInput): Promise<PointCreateRequestResultType> => {
    const result = await pointCreate.request(input);
    return result;
  };

  const updatePoint = async (input: PointPureUpdateMutationInput) => {
    const result = await pointUpdate.request(input);

    await getPoint(input.pointId);

    return result;
  };

  const removePoint = async (id: string): Promise<boolean> => {
    const result = await pointRemove.request(id);
    return result;
  };

  const pointSetAdCampaigns = async (input: PointPureSetAdCampaignsMutationInput) => {
    const result = await updatePointAds(input);
    return result;
  };

  const pointSetCompanyTags = async (pointId: string, companyTagIds: string[]) => {
    const result = await setTagsRequest({ pointId, companyTagIds });
    return result;
  };

  const pointSetSleepSettings = async (input: PointPureSleepSettingsMutationInput) => {
    const result = await updatePointSleepSettings(input);
    return result;
  };

  const pointSetDevice = async (input: PointSwitchDeviceMutationInput) => {
    const result = await updatePointDevice(input);
    return result;
  };

  const getPointHistory = async (pointId: string, dateFrom: string, dateTo: string) => {
    const result = await pointBroadcastHistory.request(pointId, dateFrom, dateTo);

    return result;
  };

  const getMorePointHistory = async (pointId: string, dateFrom: string, dateTo: string) => {
    const result = await pointBroadcastHistory.loadMore(pointId, dateFrom, dateTo);

    return result;
  };

  const downloadPointHistory = async (pointId: string, reportDate: string) => {
    const result = await pointBroadcastHistoryReportCreate.request(pointId, reportDate);

    return result;
  };

  const readCurrentPoint = () => {
    const point = client.readQuery({
      query: POINT_SET_CURRENT_IN_ONBOARDING_QUERY,
    });

    return point?.currentPoint as PointWithDevice;
  };

  const clearCurrentPoint = () => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'currentPoint',
    });

    client.cache.gc();
  };

  const setCurrentPoint = <T extends Partial<PointWithDevice> = PointWithDevice>(currentPoint: T) => {
    const newCurrentPoint = currentPoint;

    // replace it with adding updateQuery function in future
    clearCurrentPoint();

    const point = client.writeQuery({
      query: POINT_SET_CURRENT_IN_ONBOARDING_QUERY,
      data: {
        currentPoint: newCurrentPoint,
      },
    });

    return !!point;
  };

  const watchCurrentPoint = () => {
    const observableQuery = client.watchQuery({
      query: POINT_SET_CURRENT_IN_ONBOARDING_QUERY,
    });

    const result = observableQuery.getCurrentResult();

    return result.data?.currentPoint as PointWithDevice;
  };

  return {
    getPoints,
    readPoints,
    getMorePoints,
    refetchPoints,

    getPointsMetadata,
    getMorePointsMetadata,
    pointsMetadataCalled: pointsMetadataResult.called,
    pointsMetadataLoading: pointsMetadataResult.loading,
    pointsMetadataError: pointsMetadataResult.error,
    pointsMetadataNetworkStatus: pointsMetadataResult.networkStatus,
    readPointsMetadata,

    getTimezones,
    getPoint,
    getStandardizedPoints,
    getStandardizedPointsLoading,
    getMoreCertificatePoints,
    getCertificatePoints,
    readCertificatePoints,
    createPoint,
    updatePoint,
    removePoint,
    pointSetAdCampaigns,
    pointSetCompanyTags,
    pointSetSleepSettings,
    pointSetDevice,
    getPointHistory,
    getMorePointHistory,
    downloadPointHistory,
    pointsResult,
    pointsCertificateResult,
    point,
    pointLoading,
    pointHistory: pointBroadcastHistory.data.history,
    pointHistoryPaginationInfo: pointBroadcastHistory.data.paginationInfo,
    timezones,
    createPointLoading: pointCreate.loading,
    createPointError: pointCreate.error,
    updatePointLoading: pointUpdate.loading,
    updatePointError: pointUpdate.error,
    removePointLoading: pointRemove.loading,
    removePointError: pointRemove.error,
    updatePointSleepSettingsLoading,
    updateDeviceLoading,
    updateDeviceError,
    updatedPointDevice: updateDevicePoint?.device || undefined,
    pointHistoryLoading: pointBroadcastHistory.loading,
    pointHistoryReportCreateLoading: pointBroadcastHistoryReportCreate.loading,
    pointHistoryReportCreateError: pointBroadcastHistoryReportCreate.error,
    pointAdsUpdateError,
    pointAdsUpdateLoading,
    readCurrentPoint,
    setCurrentPoint,
    watchCurrentPoint,
    clearCurrentPoint,
  };
};

export default usePointRepository;
