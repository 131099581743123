import React, { Suspense } from 'react';
import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

enum CategoryParamValues {
  GROUP = 'group',
}

export type StreamGroupsGroupPageParamsType = {
  id?: string;
};

export const STREAMS_PAGE_PATH_GROUP_ALIAS = `/streams/${CategoryParamValues.GROUP}/`;
export const STREAM_GROUPS_GROUP_PAGE_PATH = `${STREAMS_PAGE_PATH_GROUP_ALIAS}:id?/`;

const Renderer = React.lazy(() => import('./renderer'));

const StreamGroupsGroupPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

StreamGroupsGroupPageWrapper.config = {
  routes: [
    {
      path: STREAM_GROUPS_GROUP_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default StreamGroupsGroupPageWrapper;
