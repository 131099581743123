import { useWebPlayerContext } from 'application/providers/WebPlayerProvider';
import React from 'react';
import IconMapPin from '@zvuk-b2b/react-uikit/ui/icons/MapPin';
import Text from '@zvuk-b2b/react-uikit/ui/Text';
import Popper, { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import styles from './styles.module.scss';

const Info = () => {
  const { mediaFile, pointData } = useWebPlayerContext();

  return (
    <div className={styles.wrapper}>
      <div
        id="address_wrapper"
        className={styles.iconPinWrapper}>
        <Popper
          isShow
          offset={[-10, 10]}
          placement={HintPlacement.PLACEMENT_TOP_END}
          stopHoverPropagation={false}
          triggerNode={
            <span>
              <IconMapPin />
            </span>
          }>
          {pointData?.address}
        </Popper>
      </div>
      <div className={styles.textsBlock}>
        <Text
          className={styles.trackTitle}
          elementId="web_player_widget_track_title">
          {mediaFile?.title}
        </Text>
        <Text
          className={styles.streamTitle}
          elementId="web_player_widget_track_title">
          {pointData?.streamTitle}
        </Text>
      </div>
    </div>
  );
};

export default Info;
