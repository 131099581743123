import React, { Suspense } from 'react';
import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';
import { useFeatureFlagsContext } from 'application/providers/FeatureFlagsProvider';

export enum CollectionCategoryParamValues {
  COLLECTION = 'collection',
}

export type StreamGroupsCollectionPageParamsType = {
  collectionId: string;
  id?: string;
};

export const STREAMS_PAGE_PATH_COLLECTIONS_ALIAS = `/streams/${CollectionCategoryParamValues.COLLECTION}/`;
export const STREAM_GROUPS_COLLECTION_PAGE_PATH = `${STREAMS_PAGE_PATH_COLLECTIONS_ALIAS}:collectionId/:id?/`;

const Renderer = React.lazy(() => import('./renderer'));
const RendererNew = React.lazy(() => import('./renderer_new'));

const StreamGroupsCollectionPageWrapper: IApplicationPage = () => {
  const { isFeatureSubCollections } = useFeatureFlagsContext();

  const CurrentRenderer = isFeatureSubCollections ? RendererNew : Renderer;

  return (
    <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
      <CurrentRenderer />
    </Suspense>
  );
};

StreamGroupsCollectionPageWrapper.config = {
  routes: [
    {
      path: STREAM_GROUPS_COLLECTION_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default StreamGroupsCollectionPageWrapper;
