import {
  usePointSetAdCampaignsMutation,
  PointPureSetAdCampaignsMutationInput,
  PointDocument,
} from 'domain/api/graphql/generated';

import { FetchPolicyKeys } from 'domain/api/graphql/types';

const usePointSetAds = () => {
  const [mutation, mutationResult] = usePointSetAdCampaignsMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: PointPureSetAdCampaignsMutationInput) => {
    const mutationResult = await mutation({
      variables: { input },
      refetchQueries: [PointDocument],
    });
    return !!mutationResult.data?.pointPureSetAdcampaigns?.ok;
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    point: mutationResult.data?.pointPureSetAdcampaigns?.point || undefined,
    request,
  };
};

export default usePointSetAds;
