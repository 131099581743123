import { RouteObject } from 'application/providers/RouterProvider';

import { loginRoutes } from './login/config';
import { logoutRoutes } from './logout/config';
import { streamsRoutes } from './streams/config';
import { pointsRoutes } from './points/config';
import { welcomeRoutes } from './welcome/config';
import { webPlayerRoutes } from './webPlayer/config';
import { specialRegisterRoutes } from './specialRegister/config';
import { shelfRoutes } from './shelf/config';
import { reviewRoutes } from './review/config';
import { reportsRoutes } from './reports/config';
import { registerRoutes } from './register/config';
import { pricePlanRoutes } from './pricePlan/config';
import { passwordResetRoutes } from './passwordReset/config';
import { lockRoutes } from './lock/config';
import { letterSentRoutes } from './letterSent/config';
import { inviteRoutes } from './invite/config';
import { emailSendRoutes } from './emailSend/config';
import { downloadAppRoutes } from './downloadApp/config';
import { debugRoutes } from './debug/config';
import { contractMigrationRoutes } from './contractMigration/config';
import { companyRoutes } from './company/config';
import { businessProfileRoutes } from './businessProfile/config';
import { broadcastScheduleRoutes } from './broadcastSchedule/config';
import { billingRoutes } from './billing/config';
import { adsRoutes } from './ads/config';
import { activationRoutes } from './activation/config';
import { activationSuccessRoutes } from './activationSuccess/config';
import { activationTrialWarningRoutes } from './activationTrialWarning/config';
import { redirectRoutes } from './redirect/config';
import { videoStreamsRoutes } from './videoStreams/config';

export type RouteConfig = RouteObject & {
  children?: RouteConfig[];
};

export const commonRoutes: RouteObject[] = [...debugRoutes, ...downloadAppRoutes, ...inviteRoutes, ...webPlayerRoutes];

export const anonymousRoutes: RouteObject[] = [
  ...emailSendRoutes,
  ...letterSentRoutes,
  ...loginRoutes,
  ...passwordResetRoutes,
  ...redirectRoutes,
  ...registerRoutes,
  ...specialRegisterRoutes,
];

export const privateRoutes: RouteObject[] = [
  ...activationRoutes,
  ...activationSuccessRoutes,
  ...activationTrialWarningRoutes,
  ...adsRoutes,
  ...billingRoutes,
  ...broadcastScheduleRoutes,
  ...businessProfileRoutes,
  ...companyRoutes,
  ...contractMigrationRoutes,
  ...lockRoutes,
  ...logoutRoutes,
  ...pointsRoutes,
  ...pricePlanRoutes,
  ...reportsRoutes,
  ...reviewRoutes,
  ...shelfRoutes,
  ...streamsRoutes,
  ...welcomeRoutes,
  ...videoStreamsRoutes,
];
