import React from 'react';

import { BUSINESS_PROFILE_PAGE_PATH } from 'application/consts';
import BusinessProfilePageWrapper from 'application/pages/BusinessProfilePage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const businessProfileRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: BUSINESS_PROFILE_PAGE_PATH,
        element: <BusinessProfilePageWrapper />,
      },
    ],
  },
];
