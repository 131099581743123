import { useMemo, useEffect } from 'react';

import useBusinessSpheres from 'domain/auth/useBusinessSpheres';
import { useAccountContext } from 'application/providers/AccountProvider';

const useGetCurrentBusinessSphere = () => {
  const { currentUser, currentCompany, isReadyToUseLK } = useAccountContext();
  const { request, businessSpheres } = useBusinessSpheres();

  const currentBusinessSphere = useMemo(
    () => (businessSpheres || []).find((item) => item.id === currentCompany?.businessSphereId),
    [businessSpheres, currentCompany?.businessSphereId]
  );

  useEffect(() => {
    if (currentUser?.id && currentCompany?.id && isReadyToUseLK) {
      request();
    }
  }, [currentUser?.id, currentCompany?.id, isReadyToUseLK]);

  return {
    request,
    businessSpheres,
    currentBusinessSphere,
  };
};

export default useGetCurrentBusinessSphere;
