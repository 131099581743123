import React, { FC } from 'react';

import { TEXTS } from 'application/pages/BillingPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Popper, { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import Info from '@zvuk-b2b/react-uikit/ui/Info';

import LicensesList from '../LicenseModal/LicensesList';
import useLicensesUnpaidModalPresenter from './useLicensesUnpaidModalPresenter';
import useLicensesUnpaidModalController from './useLicensesUnpaidModalController';

import styles from './index.module.scss';

const LicensesUnpaidModal: FC<ModalProps[ModalTypes.LICENSES_UNPAID]> = ({
  onSubmit,
  onClose,
  close,
  payFrequencyMonth = 1,
}) => {
  const { totalLicenses, licenseCostLoading, getSubscriptionCost, billingInfoRequest, billingInfo } =
    useLicensesUnpaidModalPresenter();

  const {
    toEnableAutorenewal,
    isSubmitButtonDisabled,
    modalLicenseItems,
    onChange,
    handleSubmit,
    licensesSubscriptionCost,
  } = useLicensesUnpaidModalController({
    totalLicenses,
    billingInfoRequest,
    billingInfo,
    onSubmit,
    getSubscriptionCost,
  });

  return (
    <ModalScreen
      title={TEXTS.BILLING_LICENSES_UNPAID_MODAL_TITLE}
      titlePlacement="left"
      onClose={() => {
        close();
        onClose();
      }}
      containerClassName={styles.LicensesUnpaidModalContainer}
      contentClassName={styles.LicensesUnpaidModalContent}
      containerActionButtonsClassName={styles.LicensesUnpaidModalActionButtons}
      actionButtons={[
        <Popper
          key="button_submit_licenses_unpaid"
          triggerNode={
            <div>
              <Button
                elementId="button_submit_licenses_unpaid"
                type={ButtonType.PRIMARY}
                isDisabled={isSubmitButtonDisabled}
                isLoading={licenseCostLoading}
                onClick={handleSubmit}>
                {(toEnableAutorenewal.length === 0 || licensesSubscriptionCost === 0) &&
                  TEXTS.BILLING_LICENSES_SUBMIT_BUTTON_TEXT}
                {toEnableAutorenewal.length > 0 &&
                  licensesSubscriptionCost !== 0 &&
                  TEXTS.BILLING_LICENSES_AMOUNT_BUTTON_TITLE(licensesSubscriptionCost || 0)}
              </Button>
            </div>
          }
          placement={HintPlacement.PLACEMENT_TOP}
          isShow={isSubmitButtonDisabled}
          popperClassName={styles.LicensesUnpaidModalPopper}
          containerClassName={styles.LicensesUnpaidModalPopperContent}>
          {TEXTS.BILLING_LICENSES_LIST_BUTTON_TOOLTIP}
        </Popper>,
      ]}>
      <LicensesList
        licenses={modalLicenseItems}
        onChange={onChange}
      />

      <Info className={styles.LicensesUnpaidModalHintInfo}>
        {TEXTS.BILLING_LICENSES_UNPAID_MODAL_INFO(payFrequencyMonth === 1)}
      </Info>
    </ModalScreen>
  );
};

export default LicensesUnpaidModal;
