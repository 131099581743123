export const TEXTS = {
  ADDRESS_TURNED_OFF: 'Ваш адрес вещания отключен',
  NO_STREAM: 'К адресу не привязана волна',
  LINK_IS_NOT_VALID: 'Ссылка недействительна',

  ADDRESS_TURNED_OFF_CONTENT: `Скорее всего, срок вашей подписки истёк.
  Вы можете оплатить её в личном кабинете или через менеджера`,

  NO_STREAM_CONTENT: `Чтобы музыка играла,
  нужно выбрать волну в нашей музыкальной библиотеке и запустить её на этом адресе`,

  NO_POINT_CONTENT: `Вероятно, эта ссылка устарела или не привязана к адресу.
  Если вы хотите запустить музыку,
  вам нужно войти в личный кабинет или связаться с менеджером`,

  ADDRESS_TURNED_OFF_BUTTON: 'Перейти к оплате',
  ADDRESS_TURNED_OFF_BUTTON_NO_BILLING: 'Продлить подписку',
  NO_STREAM_BUTTON: 'Выбрать волну',
  NO_POINT_BUTTON: 'Перейти к адресам',

  POINT_DISABLED_CONTENT: 'Сейчас вы не авторизованы, ваш адрес вещания отключен. Войдите в аккаунт, чтобы продолжить.',
  NOT_VALID_LINK_CONTENT: 'Ссылка недействительна. Войдите в аккаунт, чтобы продолжить.',
  NO_STREAM_MODAL_CONTENT:
    'Выбор волны доступен только авторизованным пользователям. Войдите в аккаунт, чтобы продолжить.',
  LOGIN_REQUIRED_CONTENT:
    'Сейчас вы не авторизованы, и мы не можем сохранить ваши действия. Войдите в аккаунт и отмечайте треки, которые вам не нравятся.',
};
