import React from 'react';

import { useAuthContext } from 'application/providers/AuthProvider';
import { RouterUtils, Navigate, Outlet } from 'application/providers/RouterProvider';
import { StreamsCategoryParamValues } from 'application/pages/StreamPage';
import { STREAMS_STREAM_CATEGORY_BY_ID_PATH } from 'application/consts';

const defaultPage = RouterUtils.generatePath(STREAMS_STREAM_CATEGORY_BY_ID_PATH, {
  category: StreamsCategoryParamValues.RECOMMENDED,
});

const AnonymousRoute = () => {
  const { hasAccess } = useAuthContext();

  return hasAccess ? (
    <Navigate
      to={defaultPage}
      replace
    />
  ) : (
    <Outlet />
  );
};

export default AnonymousRoute;
