import { useAdCampaignRepositoryInjector } from 'application/providers/DIContainerProvider';

const useAdRemoveModalAdapter = () => {
  const { removeAdCampaign, adCampaignRemoveLoading, clearAdCampaigns } = useAdCampaignRepositoryInjector();

  return {
    removeAdCampaign,
    adCampaignRemoveLoading,
    clearAdCampaigns,
  };
};

export default useAdRemoveModalAdapter;
