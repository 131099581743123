import usePointChangeStream from 'domain/point/usePointChangeStream';
import useStreamCopyTemplate from 'domain/media/streams/useStreamCopyTemplate';
import useStreamsSaved from 'domain/media/streams/useStreamsSaved';
import useStream from 'domain/media/streams/useStream';
import useStreamRemove from 'domain/media/streams/useStreamRemove';
import useStreamSearch from 'domain/media/streams/useStreamSearch';
import useStreamTags from 'domain/media/streams/useStreamsTags';

import { PointPureChangeStreamMutationInput, StreamShortTypeFragment } from 'domain/api/graphql/generated';

import useMediaLikeAndUnLike from 'domain/media/useMediaLikeAndUnLike';
import useExcludeMediafile from 'domain/media/useExcludeMediafile';
import useExcludeMediafileRestore from 'domain/media/useExcludeMediafileRestore';
import useDislikedMediafiles from 'domain/media/useDislikedMediafiles';
import useRandomMediafiles from 'domain/media/useRandomMediafiles';
import useMediafiles from 'domain/media/useMediafiles';
import useLibraryCollection from 'domain/media/useLibraryCollection';
import useSubCollectionStreams from 'domain/media/useSubCollectionStreams';
import useStreamsRecommendedSubCollections from 'domain/media/streams/useStreamsRecommendedCollections';
import useGetSubCollectionTitle from 'domain/media/streams/useGetSubCollectionTitle';
import { useApolloClient } from 'application/providers/DataProvider';
import { UseStreamRepositoryResultType, SearchStreamsParamsType } from './types';
import { STREAM_SET_WITH_THEMATIC_CONTENT_QUERY } from './queries';

const useStreamRepository = (): UseStreamRepositoryResultType => {
  const client = useApolloClient();

  const { request: pointChangeStreamRequest, loading: isChangeStreamLoading } = usePointChangeStream();
  const streamCopyTemplate = useStreamCopyTemplate();
  const savedStreams = useStreamsSaved();
  const targetStreamData = useStream();
  const streamRemove = useStreamRemove();
  const streamSearch = useStreamSearch();
  const streamTags = useStreamTags();
  const mediaLikeAndUnlike = useMediaLikeAndUnLike();
  const mediaExcludeMediafile = useExcludeMediafile();
  const mediaExcludeMediafileRestore = useExcludeMediafileRestore();
  const dislikedMediafiles = useDislikedMediafiles();
  const streamRandomMediafiles = useRandomMediafiles();
  const streamMediafiles = useMediafiles();
  const libraryCollection = useLibraryCollection();
  const subCollectionStreams = useSubCollectionStreams();
  const recommendedSubCollections = useStreamsRecommendedSubCollections();
  const subCollection = useGetSubCollectionTitle();

  const changeStream = async (inputs: PointPureChangeStreamMutationInput[]): Promise<boolean> => {
    const result = await pointChangeStreamRequest(inputs);
    return result;
  };

  const removeStream = async (targetStreamId: number) => {
    const isOk = await streamRemove.request(targetStreamId);

    return isOk;
  };

  const copyTemplateStream = async (
    templateId: string,
    companyId: string,
    withThematic: boolean = false
  ): Promise<string | undefined> => {
    const result = await streamCopyTemplate.request(templateId, companyId, withThematic);
    return result;
  };

  const getSavedStreams = async (companyIds: string[]) => {
    const result = await savedStreams.request({ companyIds });

    return result;
  };

  const getStream = async (id: string) => {
    const result = await targetStreamData.request(id);

    return result;
  };

  const searchStreams = async (params: SearchStreamsParamsType) => {
    const { textQuery, companyId, filters } = params;
    await streamSearch.request({ companyId, textQuery, searchTagsIds: filters });
  };

  const loadMoreStreams = (params: SearchStreamsParamsType) => {
    const { textQuery, companyId, filters } = params;
    return streamSearch.loadMoreStreams({ companyId, textQuery, searchTagsIds: filters });
  };

  const loadMoreCollections = (companyId: string, textQuery: string) =>
    streamSearch.loadMoreCollections({ companyId, textQuery });

  const getStreamTags = () => streamTags.request();

  const likeMediaFile = async (streamId: string, mediafileId: string) => {
    const result = await mediaLikeAndUnlike.likeMediaFile(streamId, mediafileId);

    return Boolean(result.data?.result?.ok);
  };

  const unLikeMediaFile = async (streamId: string, mediafileId: string) => {
    const result = await mediaLikeAndUnlike.unLikeMediaFile(streamId, mediafileId);

    return Boolean(result.data?.result?.ok);
  };

  const dislikeMediaFile = async (streamId: number, mediafileId: number) => {
    const result = await mediaExcludeMediafile.request({
      id: streamId,
      mediafileId,
    });

    return Boolean(result);
  };

  const dislikeMediaFileRestore = async (streamId: number, mediafileId: number) => {
    const result = await mediaExcludeMediafileRestore.request({
      id: streamId,
      mediafileId,
    });

    return Boolean(result);
  };

  const getSubCollections = (collectionId: string, companyId: string) =>
    libraryCollection.getSubCollections(collectionId, companyId);

  const getSubCollectionStreams = (subcollectionId: string, companyId: string) =>
    libraryCollection.getStreams(subcollectionId, companyId);

  const getInitialSubCollectionStreams = (subcollectionId: string, companyId: string) => {
    subCollectionStreams.getInitialStreams(subcollectionId, companyId);
  };

  const loadMoreSubCollectionStreams = (subcollectionId: string, companyId: string) =>
    subCollectionStreams.loadMore(subcollectionId, companyId);

  const getRecommendedSubCollections = (companyId: string, libraryId: string) =>
    recommendedSubCollections.getRecommendedSubCollections(companyId, libraryId);

  const loadMoreRecommendedSubCollections = (companyId: string, libraryId: string) =>
    recommendedSubCollections.loadMore(companyId, libraryId);

  const getSubCollectionTitle = (subcollectionId: string, companyId: string) => {
    subCollection.request(subcollectionId, companyId);
  };

  const readStreamWithThematic = () => {
    const withThematic = client.readQuery<{ withThematic: boolean }>({
      query: STREAM_SET_WITH_THEMATIC_CONTENT_QUERY,
    });

    return withThematic?.withThematic ?? false;
  };

  const clearStreamWithThematic = () => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'withThematic',
    });

    client.cache.gc();
  };

  const setStreamWithThematic = (withThematic: boolean) => {
    // replace it with adding updateQuery function in future
    clearStreamWithThematic();

    const thematic = client.writeQuery({
      query: STREAM_SET_WITH_THEMATIC_CONTENT_QUERY,
      data: {
        withThematic,
      },
    });

    return !!thematic;
  };

  return {
    dislikedMediafiles,
    streamRandomMediafiles,
    streamMediafiles,
    savedStreams: savedStreams.streams as StreamShortTypeFragment[],
    isSavedStreamsLoading: savedStreams.loading,
    isSavedStreamsCalled: savedStreams.called,
    isChangeStreamLoading,
    isCopyStreamTemplateLoading: streamCopyTemplate.loading,
    isStreamRemoveLoading: streamRemove.loading,
    streamTags: streamTags.streamTagsResult,
    isStreamTagsLoading: streamTags.isStreamTagsLoading,
    streamsSearchData: streamSearch.streamsSearchData,
    collectionSearchData: streamSearch.collectionSearchData,
    isSearchLoading: streamSearch.isLoading,
    isStreamSearchFetching: streamSearch.isStreamSearchFetching,
    isCollectionsSearchFetching: streamSearch.isCollectionsSearchFetching,
    subCollectionStreams: subCollectionStreams.subCollectionStreamsResult,
    subCollectionStreamsIsLoading: subCollectionStreams.isLoading,
    isRecommendedCollectionsFetching: recommendedSubCollections.isFetching,
    recommendedSubCollectionsResponse: recommendedSubCollections.responseData,
    subCollectionTitle: subCollection.title,
    loadMoreRecommendedSubCollections,
    getRecommendedSubCollections,
    getSubCollections,
    getSubCollectionStreams,
    loadMoreSubCollectionStreams,
    getInitialSubCollectionStreams,
    changeStream,
    removeStream,
    copyTemplateStream,
    getSavedStreams,
    getStream,
    searchStreams,
    loadMoreStreams,
    loadMoreCollections,
    getStreamTags,
    likeMediaFile,
    unLikeMediaFile,
    dislikeMediaFile,
    dislikeMediaFileRestore,
    getSubCollectionTitle,
    readStreamWithThematic,
    setStreamWithThematic,
    clearStreamWithThematic,
  };
};

export default useStreamRepository;
