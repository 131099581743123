import React from 'react';

import { useNavigate } from 'application/providers/RouterProvider';
import ActivationService from 'application/services/ActivationService';
import { LOGOUT_PAGE_PATH } from 'application/consts';

import Button, { ButtonType, ButtonSize } from '@zvuk-b2b/react-uikit/ui/Button';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import IconLogOut from '@zvuk-b2b/react-uikit/ui/icons/LogOut';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';

const LOGOUT_BUTTON_TEXT = 'Выйти';

const LogoutSmallButton = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  const { isMobile } = useMedia();

  const handleLogoutButtonClick = () => {
    if (ActivationService.isActivationFlow()) {
      ActivationService.closeBindingFlow();
    }

    navigate(LOGOUT_PAGE_PATH);
  };

  if (isMobile) {
    return (
      <IconButton
        key="button_logout"
        elementId="button_logout"
        className={className}
        onClick={handleLogoutButtonClick}>
        <IconLogOut />
      </IconButton>
    );
  }

  return (
    <Button
      key="button_logout"
      type={ButtonType.SECONDARY}
      size={ButtonSize.SMALL}
      elementId="button_logout"
      className={className}
      onClick={handleLogoutButtonClick}>
      {LOGOUT_BUTTON_TEXT}
    </Button>
  );
};

export default LogoutSmallButton;
