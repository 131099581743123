import React from 'react';

import { POINTS_PAGE_PATH } from 'application/consts';
import PointsPageWrapper from 'application/pages/PointsPage';
import SmartLayout from 'application/layout/SmartLayout';

import { RouteConfig } from '..';

export const pointsRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        path: POINTS_PAGE_PATH,
        element: <PointsPageWrapper />,
      },
    ],
  },
];
