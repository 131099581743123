import React from 'react';
import classNames from 'classnames';

import Form from '@zvuk-b2b/react-uikit/ui/Form';
import { MIN_ACCEPT_IMAGE_FILES } from '@zvuk-b2b/react-uikit/ui/FileUploader/ImageUploader/consts';
import { PreviewDescriptionPlacement } from '@zvuk-b2b/react-uikit/ui/FileUploader/types';
import IconUpload from '@zvuk-b2b/react-uikit/ui/icons/Upload';

import { BusinessProfilePlaylistFormData, BusinessProfilePlaylistFormProps } from './types';
import { TEXTS } from './texts';
import {
  DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA,
  POSTER_HEIGHT,
  POSTER_WIDTH,
  POSTER_MAX_SIZE,
  BUSINESS_PROFILE_PLAYLIST_TITLE_MAX_LENGTH,
  BUSINESS_PROFILE_PLAYLIST_DESCRIPTION_MAX_LENGTH,
} from './consts';
import useBusinessProfilePlaylistFormController from './useBusinessProfilePlaylistFormController';

import styles from './index.module.scss';

const BusinessProfilePlaylistForm = ({
  containerClassName,
  initialFormData,
  isLoading,
  withDirty = false,
  errors,
  submitButtonContent = TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_BUTTON_DEFAULT_TEXT,
  footerContent,
  onSubmit,
  onCancel,
}: BusinessProfilePlaylistFormProps) => {
  const { initialValues, externalErrors, getUploadedImageFileDescription, handleFieldDirtyChange, handleSubmit } =
    useBusinessProfilePlaylistFormController({
      initialFormData,
      errors,
      onSubmit,
    });

  return (
    <div className={classNames(styles.BusinessProfileFormContainer, containerClassName)}>
      <Form<BusinessProfilePlaylistFormData>
        elementId="business_profile_playlist_form"
        defaultValues={DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA}
        values={initialValues}
        submitButtonContent={submitButtonContent}
        isLoading={isLoading}
        withDirty={withDirty}
        onSubmit={handleSubmit}
        handleFieldDirtyChange={handleFieldDirtyChange}
        onCancel={onCancel}>
        <Form.ImageUploaderField
          elementId="business_profile_playlist_form_company_poster"
          name="poster"
          uploadButtonContent={TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_POSTER}
          previewImageClassName={styles.BusinessProfilePlaylistFormPosterPreview}
          description={TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION(POSTER_WIDTH, POSTER_HEIGHT)}
          previewDescription={
            <div className={styles.BusinessProfilePlaylistFormPosterDescription}>
              <div>{TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_SIZES(POSTER_WIDTH, POSTER_HEIGHT)}</div>
              <div>{TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_EXTENSIONS}</div>
              <div>{TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_POSTER_DESCRIPTION_BRAND_WARNING}</div>
            </div>
          }
          previewDescriptionPlacement={PreviewDescriptionPlacement.LEFT}
          maxSize={POSTER_MAX_SIZE}
          accept={MIN_ACCEPT_IMAGE_FILES}
          multiple={false}
          withPreviewImages
          uploadButtonAdornmentLeft={<IconUpload />}
          error={externalErrors.get('poster')}
          getUploadedImageFileDescription={getUploadedImageFileDescription}
        />

        <Form.TextField
          elementId="business_profile_playlist_form_title"
          name="title"
          rules={{
            maxLength: {
              value: BUSINESS_PROFILE_PLAYLIST_TITLE_MAX_LENGTH,
              message: TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_TITLE_LENGTH_ERROR(
                BUSINESS_PROFILE_PLAYLIST_TITLE_MAX_LENGTH
              ),
            },
          }}
          label={TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_TITLE}
          maxLength={BUSINESS_PROFILE_PLAYLIST_TITLE_MAX_LENGTH}
          error={externalErrors.get('title')}
        />

        <Form.TextAreaField
          elementId="business_profile_playlist_form_description"
          name="description"
          cols={30}
          rules={{
            maxLength: {
              value: BUSINESS_PROFILE_PLAYLIST_DESCRIPTION_MAX_LENGTH,
              message: TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_DESCRIPTION_LENGTH_ERROR(
                BUSINESS_PROFILE_PLAYLIST_DESCRIPTION_MAX_LENGTH
              ),
            },
          }}
          label={TEXTS.BUSINESS_PROFILE_PLAYLIST_FORM_DESCRIPTION}
          maxLength={BUSINESS_PROFILE_PLAYLIST_DESCRIPTION_MAX_LENGTH}
          error={externalErrors.get('description')}
        />

        <div>{footerContent}</div>
      </Form>
    </div>
  );
};

export default BusinessProfilePlaylistForm;
