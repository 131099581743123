import React, { useEffect, useRef } from 'react';

import Chip from '@zvuk-b2b/react-uikit/ui/Chip';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import IconX from '@zvuk-b2b/react-uikit/ui/icons/X';
import Popper, { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import BrowserUtils from '@zvuk-b2b/react-uikit/toolkit/BrowserUtils';

import classNames from 'classnames';
import styles from './index.module.scss';

export const VISIBLE_TITLE_LENGTH = 15;
export const VISIBLE_TITLE_LENGTH_MOBILE = VISIBLE_TITLE_LENGTH + 20;
export const VISIBLE_TITLE_WIDTH = 325;
export const MIN_VISIBLE_RATIO = 40;
export const MAX_VISIBLE_RATIO = 42;

export interface CompanyTagChipProps {
  id: string;
  title: string;
  visibleTitleLength?: number;
  onRemoveClick?: (id: string) => void;
  chipClassName?: string;
  isDisabled?: boolean;
}

const CompanyTagChip = ({
  id,
  title,
  visibleTitleLength,
  onRemoveClick,
  chipClassName,
  isDisabled,
}: CompanyTagChipProps) => {
  const chipContainerRef = useRef<HTMLDivElement>(null);
  const textWidthRef = useRef(BrowserUtils.getTextWidth(title));

  useEffect(() => {
    if (visibleTitleLength !== VISIBLE_TITLE_LENGTH && chipContainerRef.current) {
      chipContainerRef.current.style.setProperty('--max-width-ratio', `${visibleTitleLength}`);

      if (!visibleTitleLength) {
        const ratio = textWidthRef.current.width < VISIBLE_TITLE_WIDTH ? MAX_VISIBLE_RATIO : MIN_VISIBLE_RATIO;
        chipContainerRef.current.style.setProperty('--max-width-ratio', `${ratio}`);
      }
    }
  }, [visibleTitleLength]);

  return (
    <div
      className={styles.CompanyTagChip}
      ref={chipContainerRef}>
      <Popper
        triggerNode={
          <Chip
            elementId={id}
            className={classNames(styles.CompanyTagChip, chipClassName)}
            contentClassName={styles.CompanyTagChipContent}
            isClickable={false}
            adornmentLeft={
              onRemoveClick && (
                <IconButton
                  key={`button_remove_tag_${id}`}
                  elementId={`button_remove_tag_${id}`}
                  className={styles.CompanyTagChipButton}
                  isDisabled={isDisabled}
                  onClick={() => onRemoveClick(id)}>
                  <IconX className={styles.CompanyTagChipButtonIcon} />
                </IconButton>
              )
            }>
            {title}
          </Chip>
        }
        isShow={
          visibleTitleLength ? title.length >= visibleTitleLength : textWidthRef.current.width >= VISIBLE_TITLE_WIDTH
        }
        placement={HintPlacement.PLACEMENT_TOP}
        popperClassName={styles.CompanyTagChipHint}
        stopHoverPropagation={false}
        portalNode={document.body}>
        {title}
      </Popper>
    </div>
  );
};

export default CompanyTagChip;
