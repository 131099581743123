import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

import LogoIcon from './logo.svg';

export enum LogoMode {
  FULL = 'full',
  SHORT = 'short',
  HIDE = 'hide',
}

export type LogoProps = {
  className?: string;
  mode?: LogoMode;
};

const Logo = ({ className, mode = LogoMode.FULL }: LogoProps) => (
  <div
    className={classNames(styles.LogoContainer, className)}
    data-mode={mode}>
    <div
      className={styles.LogoContent}
      data-mode={mode}>
      <LogoIcon />
    </div>
  </div>
);

export default Logo;
