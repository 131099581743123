import React, { FC } from 'react';

import { TEXTS } from 'application/pages/BillingPage/renderer/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import AnalyticsService from 'application/services/AnalyticsService';
import { BillingEventNameAMP } from 'application/services/AnalyticsService/ampTypes';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Popper, { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import Info from '@zvuk-b2b/react-uikit/ui/Info';

import LicensesList from './LicensesList';
import useLicensesModalPresenter from './useLicensesModalPresenter';
import useLicensesModalController from './useLicensesModalController';

import styles from './index.module.scss';

const LicensesModal: FC<ModalProps[ModalTypes.LICENSES]> = ({ onSubmit, onClose, close, payFrequencyMonth = 1 }) => {
  const { hasInactiveLicenses, totalLicenses, unpaidLicensesIds, licenseCostLoading, getSubscriptionCost } =
    useLicensesModalPresenter();

  const {
    toSuspendAutorenewal,
    toEnableAutorenewal,
    isSubmitButtonDisabled,
    modalLicenseItems,
    onChange,
    licensesSubscriptionCost,
  } = useLicensesModalController({
    totalLicenses,
    unpaidLicensesIds,
    getSubscriptionCost,
  });

  const isNeedPayment = toEnableAutorenewal.length > 0 && licensesSubscriptionCost !== 0;

  const onSubmitClick = () => {
    const submitEventName = isNeedPayment
      ? BillingEventNameAMP.PAYMENT_MODAL_SUBMIT_PAYMENT
      : BillingEventNameAMP.PAYMENT_MODAL_SUBMIT_CONFIRM;

    AnalyticsService.event(submitEventName);
    onSubmit(toSuspendAutorenewal, toEnableAutorenewal);
  };

  return (
    <ModalScreen
      title={
        hasInactiveLicenses
          ? TEXTS.BILLING_LICENSES_RESUME_MODAL_TITLE(payFrequencyMonth === 1)
          : TEXTS.BILLING_LICENSES_MODAL_TITLE
      }
      titlePlacement="left"
      onClose={() => {
        close();
        onClose();
      }}
      containerClassName={styles.LicenseModalContainer}
      contentClassName={styles.LicenseModalContent}
      containerActionButtonsClassName={styles.LicenseModalActionButtons}
      actionButtons={[
        <Popper
          key="button_submit_licenses"
          triggerNode={
            <div>
              <Button
                elementId="button_submit_licenses"
                type={ButtonType.PRIMARY}
                isDisabled={isSubmitButtonDisabled}
                isLoading={licenseCostLoading}
                onClick={onSubmitClick}>
                {isNeedPayment
                  ? TEXTS.BILLING_LICENSES_AMOUNT_BUTTON_TITLE(licensesSubscriptionCost || 0)
                  : TEXTS.BILLING_LICENSES_SUBMIT_BUTTON_TEXT}
              </Button>
            </div>
          }
          placement={HintPlacement.PLACEMENT_TOP}
          isShow={isSubmitButtonDisabled}
          popperClassName={styles.LicenseModalPopper}
          containerClassName={styles.LicenseModalPopperContent}>
          {TEXTS.BILLING_LICENSES_LIST_BUTTON_TOOLTIP}
        </Popper>,
      ]}>
      <LicensesList
        licenses={modalLicenseItems}
        onChange={onChange}
      />

      <Info className={styles.LicenseModalHintInfo}>
        {TEXTS.BILLING_LICENSES_UNPAID_MODAL_INFO(payFrequencyMonth === 1)}
      </Info>
    </ModalScreen>
  );
};

export default LicensesModal;
