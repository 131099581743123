import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';

import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

const Renderer = React.lazy(() => import('./renderer'));

export const BUSINESS_PROFILE_PAGE_PATH = '/business-profile/:id';

export enum BusinessProfilePageIdParamValues {
  ALL = 'all',
}

export type BusinessProfilePageParamsType = {
  id?: string | BusinessProfilePageIdParamValues;
};

const BusinessProfilePageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

BusinessProfilePageWrapper.config = {
  routes: [
    {
      path: BUSINESS_PROFILE_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default BusinessProfilePageWrapper;
