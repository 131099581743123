import React, { useMemo } from 'react';

import { useAccountContext } from 'application/providers/AccountProvider';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import { RegistrationSource } from 'domain/api/graphql/generated';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import IconLogOut from '@zvuk-b2b/react-uikit/ui/icons/LogOut';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import { TEXTS } from '../texts';
import ProfileInfo from '../ProfileInfo';

import styles from './index.module.scss';

export type ProfileInfoModalProps = {
  onChangePasswordClick: () => void;
  onLogoutClick: () => void;
};

const DENIED_SOURCES = [RegistrationSource.Phone, RegistrationSource.SbBusinessId, RegistrationSource.SbOnline];

const ProfileInfoModal: React.FC<ModalProps[ModalTypes.PROFILE_INFO]> = ({
  close,
  onChangePasswordClick,
  onLogoutClick,
}) => {
  const { currentUser } = useAccountContext();

  const actionButtons = useMemo(() => {
    const result = [
      <Button
        key="profile_logout_button"
        elementId="profile_logout_button"
        type={ButtonType.SECONDARY}
        className={styles.ProfileContentButton}
        onClick={onLogoutClick}>
        <div className={styles.ProfileContentButtonContent}>
          <IconLogOut className={styles.ProfileContentButtonIcon} />
          <span>{TEXTS.PROFILE_LOGOUT_BUTTON}</span>
        </div>
      </Button>,
    ];

    if (currentUser && !DENIED_SOURCES.includes(currentUser.registrationSource)) {
      result.unshift(
        <Button
          key="profile_change_password_modal_show_button"
          elementId="profile_change_password_modal_show_button"
          type={ButtonType.SECONDARY}
          className={styles.ProfileContentButton}
          onClick={onChangePasswordClick}>
          {TEXTS.PROFILE_CHANGE_PASSWORD_BUTTON}
        </Button>
      );
    }

    return result;
  }, [currentUser, currentUser?.registrationSource]);

  return (
    <ModalScreen
      className={styles.ProfileInfoModal}
      onClose={close}
      actionButtons={actionButtons}>
      <ProfileInfo />
    </ModalScreen>
  );
};

export default ProfileInfoModal;
