import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Button, { ButtonProps, ButtonType } from 'ui/Button';
import Dropdown, { DropdownItemDataType } from 'ui/Dropdown';
import IconChevronRight from 'ui/icons/ChevronRight';
import IconChevronDown from 'ui/icons/ChevronDown';
import ToolkitUtils from 'toolkit/utils';
import IconButton from 'ui/IconButton';
import IconX from 'ui/icons/X';
import { mobileQueryList, useMediaQueryChangeListener } from 'toolkit/useMedia';

import styles from './index.module.scss';

export type SelectProps = ButtonProps & {
  label?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  error?: string | boolean;
  options?: DropdownItemDataType[];
  buttonClassName?: string;
  dropdownItemClassName?: string;
  dropdownItemContentClassName?: string;
  adornmentLeft?: React.ReactNode;
  adornmentRight?: React.ReactNode;
  useFullMode?: boolean;
  hasSearch?: boolean;
};

const Select = (props: SelectProps) => {
  const {
    label,
    required = false,
    value,
    placeholder,
    error,
    options = [],
    isDisabled = false,
    isLoading = false,
    className,
    buttonClassName,
    dropdownItemClassName,
    dropdownItemContentClassName,
    adornmentLeft,
    adornmentRight,
    elementId,
    onClick,
    useFullMode = true,
    hasSearch = false,
  } = props;

  const [localError, setLocalError] = useState<string | boolean | undefined>(error);
  useEffect(() => setLocalError(error), [error]);

  const hideError = () => setLocalError(undefined);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isFullMode, setIsFullMode] = useState(false);

  const isMobile = useMediaQueryChangeListener(mobileQueryList);

  const onClickWrapper = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isFullMode) {
      setIsOptionsVisible(!isOptionsVisible);
      onClick?.(event);
      hideError();
      setIsFullMode(true);
    }
  };

  const onDropdownClose = () => {
    setIsOptionsVisible(false);
    setIsFullMode(false);
  };

  const wrapperRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const fullModeSelectIcon = isFullMode ? (
    <></>
  ) : (
    <IconChevronRight className={styles.SelectContainerButtonDefaultAdornmentRight} />
  );

  const selectIcon = useFullMode ? (
    fullModeSelectIcon
  ) : (
    <IconChevronDown className={styles.SelectContainerButtonDefaultAdornmentRight} />
  );

  return (
    <section
      className={classNames(
        isFullMode && useFullMode ? styles.SelectContainerFullMode : styles.SelectContainer,
        className
      )}
      ref={wrapperRef}>
      <label
        htmlFor={elementId}
        className={styles.SelectContainerLabelForButton}
        data-disabled={isDisabled}>
        {required && <span data-required>*</span>}
        {label || ''}

        {isFullMode && isMobile && useFullMode && (
          <IconButton
            elementId={`${elementId}_button__close`}
            className={styles.SelectContainerButtonClose}
            onClick={(event) => {
              onDropdownClose();
              event.preventDefault();
            }}>
            <IconX />
          </IconButton>
        )}
      </label>

      <Button
        className={classNames(
          styles.SelectContainerButton,
          isOptionsVisible && styles.SelectContainerButtonOpen,
          localError && styles.SelectContainerButtonError,
          isDisabled && styles.SelectContainerButtonDisabled,
          useFullMode && styles.SelectContainerButtonFullMode,
          buttonClassName
        )}
        ref={anchorRef}
        type={ButtonType.PLAIN}
        elementId={elementId}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onClickWrapper}>
        {adornmentLeft && <span className={styles.SelectContainerButtonAdornmentLeft}>{adornmentLeft}</span>}

        {value && (
          <span
            data-testid={`${elementId}_value`}
            className={styles.SelectContainerButtonValue}>
            {value}
          </span>
        )}

        {!value && (
          <span
            data-testid={`${elementId}_placeholder`}
            className={styles.SelectContainerPlaceholder}>
            {placeholder}
          </span>
        )}

        {adornmentRight ? (
          <span className={styles.SelectContainerButtonAdornmentRight}>{adornmentRight}</span>
        ) : (
          selectIcon
        )}
      </Button>

      {isOptionsVisible && (
        <Dropdown
          elementId={`${elementId}_select_dropdown`}
          items={options}
          anchorNode={anchorRef.current!}
          onClose={onDropdownClose}
          style={{ width: ToolkitUtils.getWidth(anchorRef.current!) }}
          isFullMode={useFullMode && isFullMode && isMobile}
          parentNode={wrapperRef.current!}
          hasSearch={hasSearch}
          itemClassName={dropdownItemClassName}
          itemContentClassName={dropdownItemContentClassName}
        />
      )}

      {localError && typeof localError === 'string' && (
        <span
          id={`${elementId}_error_message`}
          data-testid={`${elementId}_error_message`}
          className={styles.SelectContainerError}>
          {localError}
        </span>
      )}
    </section>
  );
};

export default Select;
