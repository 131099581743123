import React, { useState, useMemo, useEffect } from 'react';
import { useDebounceFn } from 'ahooks';

import { FN_OPTIONS } from 'application/consts';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useModalContext } from 'application/providers/ModalProvider';
import SuggestionUtils from 'application/utils/SuggestionUtils';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import { STREAMS_CATEGORY_PATH, StreamsCategoryParamValues } from 'application/pages/StreamPage';
import { BusinessProfileSelectedPlaylistType } from 'application/repositories/businessProfile/types';
import AnalyticsService from 'application/services/AnalyticsService';
import { BusinessProfileEventNameAMP } from 'application/services/AnalyticsService/ampTypes';

import { PlayingStatusCode } from 'domain/media/types';
import { BusinessProfileCompanySavedStreamsType } from 'domain/businessProfiles/types';
import { ZvukPlaylistSource, ZvukPlaylistType } from 'domain/api/graphql/generated';

import { UseBusinessProfileAddPlaylistModalControllerProps } from './types';
import { TEXTS } from './texts';
import { POSTER_DEFAULT_FILENAME, POSTER_DEFAULT_URL } from './consts';

const useBusinessProfileAddPlaylistModalController = ({
  savedStreams,
  isSavedStreamsReady,
  streamOptionContentClassName,
  getSavedStreams,
  setSelectedBusinessProfilePlaylist,
  onOpenUpsertPlaylist,
  getZvukPlaylistInfo,
}: UseBusinessProfileAddPlaylistModalControllerProps) => {
  const { currentCompany } = useAccountContext();
  const navigate = useNavigate();
  const { closeModal } = useModalContext();

  const [playlistFrom, setPlaylistFrom] = useState(ZvukPlaylistSource.ZvukB2BStream);

  const [businessProfilePlaylistTitle, setBusinessProfilePlaylistTitle] = useState('');
  const [selectedZvukBusinessPlaylist, setSelectedZvukBusinessPlaylist] = useState<
    BusinessProfileSelectedPlaylistType | undefined
  >();

  const [zvukPlaylistLink, setZvukPlaylistLink] = useState('');
  const [zvukPlaylistLinkError, setZvukPlaylistLinkError] = useState('');

  const { run: debouncedRequest } = useDebounceFn(
    (query: string) => getSavedStreams([currentCompany!.id], query),
    FN_OPTIONS
  );

  const isSavedStreamsQueryEmpty = !businessProfilePlaylistTitle;
  const isEmpty = isSavedStreamsReady && savedStreams?.length === 0 && isSavedStreamsQueryEmpty;

  const isSubmitButtonDisabled =
    (playlistFrom === ZvukPlaylistSource.ZvukB2BStream && !isEmpty && businessProfilePlaylistTitle.length === 0) ||
    (playlistFrom === ZvukPlaylistSource.ZvukPlaylist &&
      (zvukPlaylistLink.length === 0 || zvukPlaylistLinkError.length !== 0));

  const isSubmitButtonHasLoadText =
    (!isEmpty && playlistFrom === ZvukPlaylistSource.ZvukB2BStream) || playlistFrom === ZvukPlaylistSource.ZvukPlaylist;

  const setSelectedPlaylist = (item: BusinessProfileCompanySavedStreamsType) => {
    setSelectedZvukBusinessPlaylist({
      id: item.id,
      title: item.title,
      description: item.description,
      poster: item.poster
        ? {
            id: item.poster.id,
            filename: item.poster.miniatureFilename || POSTER_DEFAULT_FILENAME,
            url: item.poster.miniatureUri || POSTER_DEFAULT_URL,
          }
        : undefined,
      tracksCount: item.mediafilesCount,
      remotePlaylistLink: zvukPlaylistLink,
      source: item.source,
    });
  };

  const initSavedStreams = async () => {
    const savedStreams = await getSavedStreams([currentCompany!.id]);

    if (savedStreams && savedStreams.length !== 0) {
      const item = savedStreams[0];
      setSelectedPlaylist(item);
      setBusinessProfilePlaylistTitle(item.title);
    }
  };

  useEffect(() => {
    initSavedStreams();
  }, []);

  useEffect(() => {
    if (playlistFrom === ZvukPlaylistSource.ZvukB2BStream && savedStreams && savedStreams.length !== 0) {
      const item = savedStreams[0];
      setSelectedPlaylist(item);
      setBusinessProfilePlaylistTitle(item.title);
    }
  }, [playlistFrom]);

  const playlistsOptions = useMemo(
    () =>
      (savedStreams || []).map((item: BusinessProfileCompanySavedStreamsType) => {
        const isNowPlayingTextVisible =
          item.points.length !== 0 &&
          item.points.some((point) => point.playingStatus?.code === PlayingStatusCode.PLAYING);

        return {
          key: item.id,
          onClick: () => {
            setSelectedPlaylist(item);
            setBusinessProfilePlaylistTitle(item.title);
          },
          content: (
            <div className={streamOptionContentClassName}>
              <span>{SuggestionUtils.createSuggestionPreview(item.title, businessProfilePlaylistTitle, 'mark')}</span>
              <span>{isNowPlayingTextVisible && TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_BUSINESS_PLAYING_TEXT}</span>
            </div>
          ),
          value: item.title,
        };
      }),
    [savedStreams, businessProfilePlaylistTitle]
  );

  const onPlaylistFromChange = (value: ZvukPlaylistSource) => {
    setPlaylistFrom(value);
    setZvukPlaylistLinkError('');
  };

  const onZvukBusinessPlaylistTitleChange = (value: string) => {
    setBusinessProfilePlaylistTitle(value);
    debouncedRequest(value);
    AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_CHOOSE);
  };

  const onZvukPlaylistLinkChange = (value: string) => {
    setZvukPlaylistLink(value);
    setZvukPlaylistLinkError('');
    AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_INSERT_LINK);
  };

  const getPlaylistFromZvuk = async () => {
    try {
      const result = await getZvukPlaylistInfo({ companyId: currentCompany!.id, playlistLink: zvukPlaylistLink });

      if (result.isOk && result.playlist) {
        const { id, name, description, imageUrl, trackCount } = result.playlist;

        const selectedPlaylist = {
          id,
          title: name,
          description: description || '',
          poster: {
            id: '',
            filename: POSTER_DEFAULT_FILENAME,
            miniatureUri: imageUrl || POSTER_DEFAULT_URL,
          },
          mediafilesCount: trackCount,
          points: [],
          source: playlistFrom,
        };

        setSelectedPlaylist(selectedPlaylist);

        const isOk = setSelectedBusinessProfilePlaylist({
          ...selectedPlaylist,
          tracksCount: selectedPlaylist.mediafilesCount,
          poster: {
            id: selectedPlaylist.poster.id,
            filename: selectedPlaylist.poster.filename,
            url: selectedPlaylist.poster.miniatureUri,
          },
          remotePlaylistLink: zvukPlaylistLink,
          source: playlistFrom,
        });

        if (isOk) {
          onOpenUpsertPlaylist(selectedPlaylist as unknown as ZvukPlaylistType);
        }
      }
    } catch (e) {
      setZvukPlaylistLinkError(TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_ZVUK_ERROR);
    }
  };

  const onAddPlaylist = async () => {
    AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_LOAD_BUTTON_CLICK);

    if (playlistFrom === ZvukPlaylistSource.ZvukB2BStream && isEmpty) {
      closeModal();

      navigate(RouterUtils.generatePath(STREAMS_CATEGORY_PATH, { category: StreamsCategoryParamValues.ALL }));

      return;
    }

    if (playlistFrom === ZvukPlaylistSource.ZvukPlaylist) {
      await getPlaylistFromZvuk();

      return;
    }

    if (selectedZvukBusinessPlaylist) {
      const businessProfilePlaylist = {
        ...selectedZvukBusinessPlaylist,
        source: playlistFrom,
      };

      const isOk = setSelectedBusinessProfilePlaylist(businessProfilePlaylist);

      if (isOk) {
        onOpenUpsertPlaylist(businessProfilePlaylist as unknown as ZvukPlaylistType);
      }
    }
  };

  return {
    isEmpty,
    isSubmitButtonDisabled,
    isSubmitButtonHasLoadText,
    playlistFrom,
    businessProfilePlaylistTitle,
    playlistsOptions,
    zvukPlaylistLink,
    zvukPlaylistLinkError,
    onZvukBusinessPlaylistTitleChange,
    onZvukPlaylistLinkChange,
    onPlaylistFromChange,
    onAddPlaylist,
  };
};

export default useBusinessProfileAddPlaylistModalController;
