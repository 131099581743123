import React, { Suspense } from 'react';
import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export enum StreamsCategoryParamValues {
  RECOMMENDED = 'recommended',
  ALL = 'all',
  SAVED = 'saved',
}

export enum InitialStateValues {
  NY = 'ny',
}

export type StreamGroupsPageParamsType = {
  category?: StreamsCategoryParamValues;
  id?: string;
};

export const STREAMS_PAGE_PATH = '/streams';
export const STREAMS_CATEGORY_PATH = `${STREAMS_PAGE_PATH}/:category`;
export const STREAMS_STREAM_BY_ID_PATH = `${STREAMS_CATEGORY_PATH}/:id?`;
export const getStreamsCategoryPath = (category: StreamsCategoryParamValues) => `${STREAMS_PAGE_PATH}/${category}`;
export const STREAMS_CATEGORY_ALL_PATH = `${getStreamsCategoryPath(StreamsCategoryParamValues.ALL)}/:id?`;
// eslint-disable-next-line max-len
export const STREAMS_CATEGORY_RECOMMENDED_PATH = `${getStreamsCategoryPath(StreamsCategoryParamValues.RECOMMENDED)}/:id?`;

const Renderer = React.lazy(() => import('./renderer'));

const StreamGroupsPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

StreamGroupsPageWrapper.config = {
  routes: [
    {
      path: STREAMS_STREAM_BY_ID_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default StreamGroupsPageWrapper;
