import { AuthUserGetSberOauthUriQuery, useAuthUserGetSberOauthUriQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

export type useSBBIDType = {
  redirectPath: string;
  onCompleted?: (data?: AuthUserGetSberOauthUriQuery) => void;
};

const useSBBID = ({ redirectPath, onCompleted }: useSBBIDType) => {
  const query = useAuthUserGetSberOauthUriQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    variables: { redirect: redirectPath },
    onCompleted,
  });

  return {
    error: query.error,
    loading: query.loading,
    data: {
      uri: query.data?.userGetSberOauthUri,
    },
  };
};

export default useSBBID;
