import { NetworkStatus } from '@apollo/client';
import { useLibraryRecommendedSubCollectionsLazyQuery } from 'domain/api/graphql/generated';

const PAGINATION = {
  page: 0,
  size: 5,
};

const useStreamsRecommendedSubCollections = () => {
  const [request, response] = useLibraryRecommendedSubCollectionsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const isLoading = response.loading;
  const isFetching = response.networkStatus === NetworkStatus.fetchMore;
  const hasNextPage = response.data?.result.paginationInfo.hasNextPage;
  const responseData = response.data;

  const getRecommendedSubCollections = (companyId: string, libraryId: string) =>
    request({
      variables: {
        filters: { companyId, libraryId },
        pagination: { page: PAGINATION.page, pageSize: PAGINATION.size },
      },
    });

  const loadMore = (companyId: string, libraryId: string) => {
    if (!hasNextPage || isLoading || isFetching) {
      return Promise.resolve(null);
    }

    const prepareRequestParams = {
      variables: {
        filters: { companyId, libraryId },
        pagination: {
          page: (response.data?.result.paginationInfo.page ?? 0) + 1,
          pageSize: PAGINATION.size,
        },
      },
    };

    return response.fetchMore({
      ...prepareRequestParams,
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        result: {
          paginationInfo: {
            ...prev.result.paginationInfo,
            ...fetchMoreResult.result.paginationInfo,
          },
          result: [...(prev.result.result || []), ...(fetchMoreResult.result.result || [])],
        },
      }),
    });
  };

  return { responseData, isFetching, getRecommendedSubCollections, loadMore };
};

export default useStreamsRecommendedSubCollections;
