import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { NOTIFICATION_DEFAULT_DELAY, useNotificationContext } from 'application/providers/NotificationProvider';
import { DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA } from 'application/pages/BusinessProfilePage/renderer/BusinessProfilePlaylistForm/consts';
import { BusinessProfilePlaylistFormData } from 'application/pages/BusinessProfilePage/renderer/BusinessProfilePlaylistForm/types';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useModalContext, ModalTypes } from 'application/providers/ModalProvider';

import {
  BusinessPlaylistTypeFragment,
  BusinessProfileTypeFragment,
  ZvukPlaylistSource,
} from 'domain/api/graphql/generated';

import AnalyticsService from 'application/services/AnalyticsService';
import {
  BusinessProfileEventNameAMP,
  BusinessProfileParamNameAMP,
} from 'application/services/AnalyticsService/ampTypes';
import { BusinessProfilePlaylistWithParamValue } from 'application/services/AnalyticsService/eventTypes/businessProfile';
import { BusinessProfilePlaylistModalMode, UseBusinessProfileUpsertPlaylistModalControllerProps } from './types';
import { TEXTS } from './texts';
import SuccessNotification from './SuccessNotification';
import useBusinessProfileStatusHelpers from '../useBusinessProfileHelpers';

const useBusinessProfileUpsertPlaylistModalController = ({
  mode,
  playlist,
  targetBusinessProfileId,
  onSubmit,
  close,
  getBusinessProfile,
  refetchBusinessProfiles,
  removeSelectedBusinessProfilePlaylist,
  getBusinessProfilePlaylistInitialFormData,
  onCreateBusinessProfilePlaylistFromStream,
  createPlaylistFromStreamError,
  onCreateBusinessProfilePlaylistFromZvuk,
  createPlaylistFromZvukError,
  onUpdateBusinessProfilePlaylist,
  updatePlaylistError,
  hidePlaylist,
  hidePlaylistError,
  removePlaylist,
  removePlaylistError,
}: UseBusinessProfileUpsertPlaylistModalControllerProps) => {
  const { currentCompany } = useAccountContext();
  const notification = useNotificationContext();
  const { openModal } = useModalContext();

  const { isUnpublishedProfile } = useBusinessProfileStatusHelpers();

  const [initialFormData, setInitialFormData] = useState<BusinessProfilePlaylistFormData>(
    DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA
  );
  const [currentBusinessProfile, setCurrentBusinessProfile] = useState<BusinessProfileTypeFragment | null>(null);
  const [isInitialDataLoading, setIsInitialDataLoading] = useState<boolean>(false);

  const isCreateMode = mode === BusinessProfilePlaylistModalMode.CREATE;
  const isSourceZvukBusiness = playlist.source === ZvukPlaylistSource.ZvukB2BStream;

  const submitButtonContent = useMemo(() => {
    if (isCreateMode) {
      return TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_CREATE_TITLE;
    }

    if (!isUnpublishedProfile(playlist)) {
      return TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_UPDATE_TITLE;
    }

    return TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_PUBLISH_TITLE;
  }, [isCreateMode, playlist]);

  const initFormData = async () => {
    if (playlist && playlist.id) {
      setIsInitialDataLoading(true);
      const data = await getBusinessProfilePlaylistInitialFormData(playlist.id);
      setInitialFormData(data);
      setIsInitialDataLoading(false);
    }
  };

  const initBusinessProfile = () => {
    if (targetBusinessProfileId) {
      const businessProfile = getBusinessProfile(targetBusinessProfileId);
      setCurrentBusinessProfile(businessProfile);
    }
  };

  useEffect(() => {
    initFormData();
  }, [playlist]);

  useEffect(() => {
    initBusinessProfile();
  }, [targetBusinessProfileId]);

  useEffect(
    () => () => {
      if (mode === BusinessProfilePlaylistModalMode.CREATE) {
        removeSelectedBusinessProfilePlaylist(playlist.id);
      }
    },
    []
  );

  const showCreatePlaylistSuccessNotification = useCallback(
    (playlist: BusinessPlaylistTypeFragment) => {
      const playlistTitle = playlist.title || '';
      const profileName = currentBusinessProfile?.companyName || '';
      const playlistIsPublished = playlist.isPublished;

      notification.showInfoNotification({
        children: (
          <SuccessNotification
            playlistTitle={playlistTitle}
            profileName={profileName}
            isPublished={playlistIsPublished}
          />
        ),
        delay: NOTIFICATION_DEFAULT_DELAY * 2,
      });
    },
    [currentBusinessProfile, notification.showInfoNotification, NOTIFICATION_DEFAULT_DELAY]
  );

  const getApmPlaylistWith = (playlist?: BusinessPlaylistTypeFragment) => {
    if (!playlist) {
      return [];
    }

    const apmPlaylistWith = [];

    if (playlist.poster) {
      apmPlaylistWith.push(BusinessProfilePlaylistWithParamValue.POSTER);
    }

    if (playlist.description) {
      apmPlaylistWith.push(BusinessProfilePlaylistWithParamValue.DESCRIPTION);
    }

    return apmPlaylistWith;
  };

  const handleCreatePlaylistFromStream = useCallback(
    async (formData: BusinessProfilePlaylistFormData) => {
      try {
        const result = await onCreateBusinessProfilePlaylistFromStream(targetBusinessProfileId!, playlist.id, formData);

        if (result.isOk && result.playlist) {
          onSubmit?.(formData);
          showCreatePlaylistSuccessNotification(result.playlist);
          await refetchBusinessProfiles(currentCompany!.id);
          close?.();

          const apmPlaylistWith = getApmPlaylistWith(result.playlist);

          AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_CREATE, {
            [BusinessProfileParamNameAMP.PLAYLIST_FROM]: ZvukPlaylistSource.ZvukB2BStream,
            [BusinessProfileParamNameAMP.PLAYLIST_WITH]: apmPlaylistWith,
          });
        }
      } catch (e) {
        const error = e as Error;

        notification.showErrorNotification({
          children:
            error.message ||
            createPlaylistFromStreamError?.message ||
            TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_CREATE_ERROR_TEXT,
        });

        AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_CREATE_ERROR, {
          [BusinessProfileParamNameAMP.PLAYLIST_FROM]: ZvukPlaylistSource.ZvukB2BStream,
        });
      }
    },
    [onSubmit, onCreateBusinessProfilePlaylistFromStream]
  );

  const handleCreatePlaylistFromZvuk = useCallback(
    async (formData: BusinessProfilePlaylistFormData) => {
      try {
        const result = await onCreateBusinessProfilePlaylistFromZvuk(targetBusinessProfileId!, formData);

        if (result.isOk && result.playlist) {
          onSubmit?.(formData);
          showCreatePlaylistSuccessNotification(result.playlist);
          await refetchBusinessProfiles(currentCompany!.id);
          close?.();

          const apmPlaylistWith = getApmPlaylistWith(result.playlist);

          AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_CREATE, {
            [BusinessProfileParamNameAMP.PLAYLIST_FROM]: ZvukPlaylistSource.ZvukPlaylist,
            [BusinessProfileParamNameAMP.PLAYLIST_WITH]: apmPlaylistWith,
          });
        }
      } catch (e) {
        const error = e as Error;

        notification.showErrorNotification({
          children:
            error.message ||
            createPlaylistFromZvukError?.message ||
            TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_CREATE_ERROR_TEXT,
        });

        AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_CREATE_ERROR, {
          [BusinessProfileParamNameAMP.PLAYLIST_FROM]: ZvukPlaylistSource.ZvukPlaylist,
        });
      }
    },
    [onSubmit, onCreateBusinessProfilePlaylistFromZvuk]
  );

  const handleUpdatePlaylist = useCallback(
    async (formData: BusinessProfilePlaylistFormData) => {
      try {
        const result = await onUpdateBusinessProfilePlaylist(playlist.id, formData);

        if (result.isOk && result.playlist) {
          onSubmit?.(formData);
          showCreatePlaylistSuccessNotification(result.playlist);
          close?.();

          AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_UPDATE);
        }
      } catch (e) {
        const error = e as Error;

        notification.showErrorNotification({
          children:
            error.message ||
            updatePlaylistError?.message ||
            TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_UPDATE_ERROR_TEXT,
        });
      }
    },
    [onSubmit, currentBusinessProfile, onUpdateBusinessProfilePlaylist]
  );

  const handleHidePlaylist = useCallback(async () => {
    try {
      const result = await hidePlaylist({
        playlistId: playlist.id,
        companyId: currentCompany!.id,
      });

      if (result.isOk) {
        notification.showInfoNotification({
          children: TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_HIDE_SUCCESS_TEXT,
        });

        AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_HIDE);

        close?.();
      }
    } catch (e) {
      const error = e as Error;

      notification.showErrorNotification({
        children:
          error.message ||
          hidePlaylistError?.message ||
          TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_HIDE_ERROR_TEXT,
      });
    }
  }, [hidePlaylist, currentCompany]);

  const handleRemovePlaylist = useCallback(async () => {
    try {
      const result = await removePlaylist({
        playlistId: playlist.id,
        companyId: currentCompany!.id,
      });

      if (result.isOk) {
        notification.showInfoNotification({
          children: TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_REMOVE_SUCCESS_TEXT,
        });

        AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_REMOVE);

        close?.();
      }
    } catch (e) {
      const error = e as Error;

      notification.showErrorNotification({
        children:
          error.message ||
          removePlaylistError?.message ||
          TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_REMOVE_ERROR_TEXT,
      });
    }
  }, [removePlaylist, currentCompany]);

  const handleConfirmRemovePlaylist = useCallback(() => {
    openModal(ModalTypes.CONFIRM_OPERATION, {
      title: TEXTS.BUSINESS_PROFILE_PLAYLIST_UPSERT_MODAL_BUTTON_REMOVE_TITLE,
      description: TEXTS.BUSINESS_PROFILE_PLAYLIST_REMOVE_MODAL_DESCRIPTION(initialFormData.title),
      submitButtonContent: TEXTS.BUSINESS_PROFILE_PLAYLIST_REMOVE_MODAL_SUBMIT_BUTTON,
      cancelButtonContent: TEXTS.BUSINESS_PROFILE_PLAYLIST_REMOVE_MODAL_CANCEL_BUTTON,
      onSubmit: handleRemovePlaylist,
    });
  }, [initialFormData]);

  const handleSubmitPlaylist = useCallback(
    async (formData: BusinessProfilePlaylistFormData) => {
      AnalyticsService.event(BusinessProfileEventNameAMP.BUSINESS_PROFILE_PLAYLIST_SAVE_BUTTON_CLICK);

      if (isCreateMode && playlist.source === ZvukPlaylistSource.ZvukB2BStream) {
        await handleCreatePlaylistFromStream(formData);

        return;
      }

      if (isCreateMode && playlist.source === ZvukPlaylistSource.ZvukPlaylist) {
        await handleCreatePlaylistFromZvuk(formData);

        return;
      }

      await handleUpdatePlaylist(formData);
    },
    [isCreateMode, playlist.source, handleCreatePlaylistFromStream, handleCreatePlaylistFromZvuk, handleUpdatePlaylist]
  );

  return {
    initialFormData,
    isCreateMode,
    isSourceZvukBusiness,
    isInitialDataLoading,
    isUnpublishedProfile,
    submitButtonContent,
    handleSubmitPlaylist,
    handleHidePlaylist,
    handleConfirmRemovePlaylist,
  };
};

export default useBusinessProfileUpsertPlaylistModalController;
