import { CertLetterForPointMutationInput, useCreateCertificateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useCreateCertificate = () => {
  const [createMutation, createMutationResult] = useCreateCertificateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: CertLetterForPointMutationInput) => {
    const certificate = await createMutation({
      variables: {
        input,
      },
    });

    return {
      data: certificate.data?.certLetterForPointMutation?.downloadLink,
    };
  };

  const result = {
    error: createMutationResult.data?.certLetterForPointMutation?.errors,
    loading: createMutationResult.loading,
    called: createMutationResult.called,
    certificateLink: createMutationResult.data?.certLetterForPointMutation?.downloadLink,
  };

  return { request, result };
};

export default useCreateCertificate;
