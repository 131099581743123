import { ApolloError } from '@apollo/client';
import {
  BusinessProfileFormData,
  BusinessProfileFormImagesHintType,
} from 'application/pages/BusinessProfilePage/renderer/BusinessProfileForm/types';

import { CommonDataType } from 'domain/api/graphql/types';
import { BusinessProfileMutationRequestResult } from 'domain/businessProfiles/types';

export enum BusinessProfileModalMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export type BusinessProfileUpsertModalProps = {
  mode?: BusinessProfileModalMode;
  targetBusinessProfileId?: string;
  targetBusinessProfileUnpublished?: boolean;
  onOpenImagesHint?: (type: BusinessProfileFormImagesHintType) => void;
  onSubmit?: (formData: BusinessProfileFormData) => void;
};

export type UseBusinessProfileUpsertModalControllerType = {
  targetBusinessProfileId: BusinessProfileUpsertModalProps['targetBusinessProfileId'];
  targetBusinessProfileUnpublished: BusinessProfileUpsertModalProps['targetBusinessProfileUnpublished'];
  mode: BusinessProfileModalMode;
  onSubmit?: BusinessProfileUpsertModalProps['onSubmit'];
  close: () => void;
  getBusinessProfileInitialFormData: (targetBusinessProfileId: string) => Promise<BusinessProfileFormData>;

  onCreateBusinessProfile: (formData: BusinessProfileFormData) => Promise<BusinessProfileMutationRequestResult>;
  businessProfileCreateLoading: boolean;
  businessProfileCreateError?: ApolloError | null;
  businessProfileCreateData: CommonDataType;

  onUpdateBusinessProfile: (
    profileId: string,
    formData: BusinessProfileFormData
  ) => Promise<BusinessProfileMutationRequestResult>;
  businessProfileUpdateLoading: boolean;
  businessProfileUpdateError?: ApolloError | null;
  businessProfileUpdateData: CommonDataType;
};
