import { useEffect } from 'react';

import { useAuthContext, LocalAccessRoles } from 'application/providers/AuthProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import { IApplicationPage } from 'application/pages/types';
import AnalyticsService, { EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';
import { useWebPlayerActionsContext } from 'application/providers/WebPlayerProvider';
import { LOGOUT_PAGE_PATH } from 'application/consts';

import useLogout from 'domain/auth/useLogout';

const LogoutPage: IApplicationPage = () => {
  const authContext = useAuthContext();
  const { currentCompany } = useAccountContext();
  const [request] = useLogout();

  const { stopAndClosePlayer } = useWebPlayerActionsContext();

  useEffect(() => {
    (async () => {
      try {
        await request();
      } finally {
        authContext.setAuthData({ authToken: undefined, userData: undefined });
        AnalyticsService.event(EventNameAMP.USER_LOGGED_OUT, {
          [ParamNameAMP.COMPANY_ID]: currentCompany?.id,
        });

        stopAndClosePlayer();
      }
    })();
  }, []);

  return null;
};

LogoutPage.config = {
  routes: [
    {
      path: LOGOUT_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default LogoutPage;
