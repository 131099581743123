import { useBillingExtendSubscriptionMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useExtendSubscription = () => {
  const [mutation, mutationResult] = useBillingExtendSubscriptionMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (companyId: string, licensesIds: string[]) => {
    const mutationResult = await mutation({
      variables: {
        input: { companyId, licensesIds },
      },
    });

    return !!mutationResult.data?.extendSubscription?.ok;
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
  };

  return {
    request,
    result,
  };
};

export default useExtendSubscription;
