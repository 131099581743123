import { usePointRemoveMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const usePointRemove = () => {
  const [mutation, mutationResult] = usePointRemoveMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (pointId: string) => {
    const mutationResult = await mutation({ variables: { input: { pointId } }, refetchQueries: ['pointList'] });
    return !!mutationResult.data?.pointPureRemove?.ok;
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    request,
  };
};

export default usePointRemove;
