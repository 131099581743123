import React, { FC } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import { POINTS_MENU_PATH } from 'application/consts';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import PointAddressesList from './PointAddressesList';

import styles from './index.module.scss';
import { TEXTS } from '../../texts';

export type VideoStreamBindSuccessModalProps = {
  selectedPoints: PointsListCheckboxGroupPointType[];
};

const VideoStreamBindSuccessModal: FC<ModalProps[ModalTypes.VIDEO_STREAM_BIND_SUCCESS]> = ({
  close,
  selectedPoints = [],
}) => {
  const navigate = useNavigate();

  const onBindPointSuccessClick = () => {
    navigate(RouterUtils.generatePath(POINTS_MENU_PATH));

    close();
  };

  return (
    <ModalScreen
      title={TEXTS.MODAL_BIND_SUCCESS_TITLE}
      titlePlacement="left"
      containerClassName={styles.StreamBindSuccessModalContainer}
      onClose={close}
      actionButtons={[
        <Button
          key="button_open_points_bind_success_modal"
          elementId="button_open_points_bind_success_modal"
          type={ButtonType.PRIMARY}
          onClick={onBindPointSuccessClick}>
          {TEXTS.MODAL_BIND_SUCCESS_TO_POINTS_TEXT}
        </Button>,

        <Button
          key="button_cancel_bind_success_modal"
          elementId="button_cancel_bind_success_modal"
          type={ButtonType.SECONDARY}
          onClick={close}>
          {TEXTS.MODAL_BIND_SUCCESS_BUTTON_CANCEL_TEXT}
        </Button>,
      ]}>
      {selectedPoints.length > 0 && (
        <div className={styles.StreamBindSuccessModalAddresses}>
          <PointAddressesList selectedPoints={selectedPoints} />
        </div>
      )}
    </ModalScreen>
  );
};

export default VideoStreamBindSuccessModal;
