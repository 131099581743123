import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import useKeyDownCallback from 'toolkit/useKeyDownCallback';
import { KeyboardEventCode } from 'toolkit/types';

import styles from './index.module.scss';

export type TextAreaProps = {
  rows?: number;
  cols?: number;
  label?: React.ReactNode;
  labelIcon?: React.ReactNode;
  value?: string;
  error?: string | boolean | string[];
  required?: boolean;
  maxLength?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  textAreaClassName?: string;
  containerClassName?: string;
  placeholder?: string;
  elementId: string;
  textAreaRef?: React.RefObject<HTMLTextAreaElement>;
  autoComplete?: string;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onChange?: (value: string, event: React.SyntheticEvent) => void;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isFullMode?: boolean;
};

const DEFAULT_ROWS = 5;
const DEFAULT_COLS = 25;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props: TextAreaProps, ref) => {
  const {
    label,
    error,
    textAreaRef,
    value: propValue = '',
    required = false,
    maxLength = Number.MAX_SAFE_INTEGER,
    isDisabled = false,
    isLoading = false,
    rows = DEFAULT_ROWS,
    cols = DEFAULT_COLS,
    containerClassName,
    textAreaClassName,
    placeholder,
    elementId,
    autoComplete = 'on',
    onChange,
    onFocus,
    onBlur,
    onMouseOver,
    onMouseOut,
    onEnterPressed,
    labelIcon,
    isFullMode = false,
  } = props;

  const [value, setValue] = useState<string>(propValue);

  const [localError, setLocalError] = useState<string | boolean | string[] | undefined>(error);

  useEffect(() => setLocalError(error), [error]);

  useEffect(() => setValue(propValue), [propValue]);

  const hideError = () => setLocalError(undefined);

  const onFocusWrapper = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    hideError();
    onFocus?.(event);
  };

  const onChangeWrapper = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    hideError();

    const newValue = event.target.value.substr(0, maxLength);
    onChange?.(newValue, event);
    setValue(newValue);
  };

  const onKeyDown = useKeyDownCallback<React.KeyboardEvent<HTMLTextAreaElement>>((event) => onEnterPressed?.(event), {
    include: [KeyboardEventCode.ENTER],
  });

  return (
    <div className={classNames(styles.TextAreaContainer, containerClassName)}>
      {(label || isFullMode) && (
        <label
          htmlFor={elementId}
          className={classNames(styles.TextAreaContainerLabel, isFullMode && styles.TextAreaContainerLabelFullMode)}>
          {required && <span data-required>*</span>}
          {label || ''}
          {labelIcon}
        </label>
      )}

      {isLoading ? (
        <div className={styles.TextAreaSkeleton} />
      ) : (
        <div className={styles.TextAreaWrapperContainer}>
          <textarea
            rows={rows}
            cols={cols}
            value={value}
            id={elementId}
            data-testid={elementId}
            ref={textAreaRef || ref}
            disabled={isDisabled}
            placeholder={placeholder}
            aria-errormessage={localError ? `${elementId}_errormessage` : undefined}
            autoComplete={autoComplete}
            className={classNames(textAreaClassName)}
            onChange={onChangeWrapper}
            onFocus={onFocusWrapper}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          />
        </div>
      )}

      {localError && typeof localError === 'string' && (
        <span
          id={`${elementId}_error_message`}
          data-testid={`${elementId}_error_message`}
          className={styles.TextAreaContainerError}>
          {localError}
        </span>
      )}

      {localError && Array.isArray(localError) && (
        <div
          id={`${elementId}_error_messages`}
          data-testid={`${elementId}_error_messages`}
          className={styles.TextAreaContainerErrors}>
          {localError.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </div>
      )}
    </div>
  );
});

export default TextArea;
