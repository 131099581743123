import React from 'react';

import { TPointMetadata } from '@player-core/use-player-frame';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import Text from '@zvuk-b2b/react-uikit/ui/Text';
import IconMapPin from '@zvuk-b2b/react-uikit/ui/icons/MapPin';

import { useAccountContext } from 'application/providers/AccountProvider';
import { useModalContext, ModalTypes } from 'application/providers/ModalProvider';
import { TPointData } from 'application/providers/WebPlayerProvider/types';
import { KEYS, UseBillingRepositoryType, useInjection } from 'application/providers/DIContainerProvider';

import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';

import { useNavigate } from 'application/providers/RouterProvider';
import styles from './index.module.scss';
import {
  ErrorButtonTextByErrorType,
  ErrorContentByErrorType,
  ErrorTitleByErrorType,
  ErrorTypes,
  LoginRequiredModalContentByErrorType,
  RedirectPathByErrorType,
} from './consts';

const getErrorType = (pointMetadata: TPointMetadata, isBillingEnabled?: boolean) => {
  if (pointMetadata.isNoPoint) {
    return ErrorTypes.NO_POINT;
  }

  if (pointMetadata.pointHasNoStream) {
    return ErrorTypes.NO_STREAM;
  }

  if (!isBillingEnabled) {
    return ErrorTypes.PAYMENT_REQUEST;
  }

  return ErrorTypes.PAYMENT;
};

interface IErrorMessageProps {
  showTitle?: boolean;
  appearance?: 'page' | 'modal';
  pointMetadata: TPointMetadata;
  pointData: TPointData | null;
  onUnAuthorizedClick: (modalContent: string) => void;
  setRedirect: (path: string) => void;
  setErrorModalClose: () => void;
}

const ErrorMessage: React.FC<IErrorMessageProps> = ({
  showTitle = true,
  appearance,
  pointMetadata,
  pointData,
  onUnAuthorizedClick,
  setRedirect,
  setErrorModalClose,
}) => {
  const useBillingRepository = useInjection<UseBillingRepositoryType>(KEYS.BILLING_REPOSITORY);

  const { isAuthorized, currentCompany } = useAccountContext();
  const { requestExtendSubscription, extendSubscriptionPromptLoading, extendSubscriptionPromptError } =
    useBillingRepository();
  const notification = useNotificationContext();
  const { openModal } = useModalContext();

  const errorType = getErrorType(pointMetadata, currentCompany?.isBillingEnabled);

  const title = ErrorTitleByErrorType[errorType];

  const content = ErrorContentByErrorType[errorType];

  const buttonText = ErrorButtonTextByErrorType[errorType];

  const navigate = useNavigate();

  const requestSubscriptionHandler = async () => {
    try {
      const isOk = await requestExtendSubscription(String(pointData!.id));

      if (!isOk) {
        throw Error();
      }

      openModal(ModalTypes.WEB_PLAYER_REQUEST_EXTEND_SUBSCRIPTION_SUCCESS, {});
    } catch (e) {
      const error = e as Error;
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: extendSubscriptionPromptError?.message || error.message || NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const onClick = async () => {
    if (!isAuthorized) {
      onUnAuthorizedClick(LoginRequiredModalContentByErrorType[errorType]);
      setRedirect(RedirectPathByErrorType[errorType]);
    } else if (errorType === ErrorTypes.PAYMENT_REQUEST) {
      await requestSubscriptionHandler();
    } else {
      navigate(RedirectPathByErrorType[errorType]);
    }

    setErrorModalClose();
  };

  return (
    <div className={`${styles.content} ${appearance === 'page' && styles.page}`}>
      {showTitle && <div className={styles.title}>{title}</div>}
      {pointData?.address && (
        <div className={styles.pointWrapper}>
          <IconMapPin />
          <Text
            elementId="player_point_address"
            className={styles.text}>
            {pointData?.address}
          </Text>
        </div>
      )}

      <div className={styles.message}>{content}</div>
      <Button
        key="button_web_player_error"
        elementId="button_web_player_error"
        type={ButtonType.PRIMARY}
        className={styles.confirm}
        isLoading={extendSubscriptionPromptLoading}
        onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  );
};

export default ErrorMessage;
