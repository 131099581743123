import { useSubscriptionCostPresenter } from 'application/presenters';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useBillingRepositoryInjector } from 'application/providers/DIContainerProvider';

const useExtendSubscriptionModalPresenter = () => {
  const { currentCompany } = useAccountContext();

  const { getSubscriptionCost } = useSubscriptionCostPresenter();

  const {
    getBillingInfo,
    watchBillingInfo,
    licenseSubscriptionLoading,
    extendSubscription: extendSubscriptionRequest,
    launchSubscription: launchSubscriptionRequest,
  } = useBillingRepositoryInjector();

  const billingInfo = watchBillingInfo(currentCompany!.id);

  const { hasInactiveLicenses } = billingInfo;

  const extendSubscription = async (toExtendSubscriptionLicenseIds: string[]) => {
    const result = await extendSubscriptionRequest(currentCompany!.id, toExtendSubscriptionLicenseIds);

    return result;
  };

  const launchSubscription = async (toExtendSubscriptionLicenseIds: string[]) => {
    const result = await launchSubscriptionRequest(currentCompany!.id, toExtendSubscriptionLicenseIds);

    return result;
  };

  const billingInfoRequest = async () => {
    await getBillingInfo(currentCompany!.id);
  };

  return {
    hasInactiveLicenses,
    billingInfoRequest,
    getSubscriptionCost,
    licenseSubscriptionLoading,
    extendSubscription,
    launchSubscription,
  };
};

export default useExtendSubscriptionModalPresenter;
