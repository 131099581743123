export const TEXTS = {
  MODAL_TITLE: 'Плеер «Звук Бизнес»',

  SUCCESS_DISABLED_BUTTON: 'Запрос отправлен',
  SUCCESS_BUTTON: 'Отправить запрос',
  CANCEL_BUTTON: 'Назад',

  HAVE_REQUEST_NOTE: 'Менеджер свяжется в течение рабочего дня',

  FEATURES_INTERNET: 'Нестабильный интернет',
  FEATURES_DEVICES: 'Нет устройств воспроизведения (ноутбука, телефона)',
  FEATURES_TIME: 'Нет времени на настройку',
  FEATURES_VIDEO: 'Не только аудио-, но и видео-волны, если подключить к телевизору через разъём HDMI',

  DESCRIPTION_FIRST_BLOCK: 'Выбирайте плеер от «Звук Бизнес» — все вам настроим и установим.',
  DESCRIPTION_SECOND_BLOCK:
    'Подключите устройство к сети и к аудиоколонке. И получите доступ к миллиону легальных треков.',

  DISABLED_BUTTON_BINDED_HINT: 'Этот тип устройства сейчас привязан',
};
