import { usePointHistoryReportCreateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UsePointBroadcastHistoryReportCreateType } from './types';

const usePointBroadcastHistoryReportCreate = (): UsePointBroadcastHistoryReportCreateType => {
  const [mutation, mutationResult] = usePointHistoryReportCreateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (pointId: string, reportDate: string) => {
    const mutationResult = await mutation({ variables: { input: { pointId, reportDate } } });
    return {
      ok: !!mutationResult.data?.pointPlaybackReportCreateMutation?.ok,
      downloadLink: mutationResult.data?.pointPlaybackReportCreateMutation?.downloadLink,
    };
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    request,
  };
};

export default usePointBroadcastHistoryReportCreate;
