import React, { useEffect, useMemo } from 'react';

import { useNavigate } from 'application/providers/RouterProvider';
import AnalyticsService, { EventNameAMP } from 'application/services/AnalyticsService';
import { LOGOUT_PAGE_PATH } from 'application/consts';

import { ModalTypes, useModalContext } from '../ModalProvider';
import { ProfileContext } from './ProfileContext';

const ProfileProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext();

  const onChangePasswordClick = () => {
    AnalyticsService.event(EventNameAMP.USER_PASSWORD_CHANGE_CLICKED);

    openModal(ModalTypes.PROFILE_CHANGE_PASSWORD, {});
  };

  const onLogoutClick = () => {
    closeModal();
    navigate(LOGOUT_PAGE_PATH);
  };

  const contextValue = useMemo(
    () => ({
      showProfile: () => {
        openModal(ModalTypes.PROFILE_INFO, {
          onChangePasswordClick,
          onLogoutClick,
        });
      },
      hideProfile: closeModal,
    }),
    [openModal, closeModal]
  );

  useEffect(() => () => closeModal?.(), []);

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
