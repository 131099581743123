import React, { useCallback, useEffect, useState } from 'react';

import { UseFileUploaderControllerProps } from './types';
import FileUploaderUtils from './utils';

const useFileUploaderController = ({
  elementId,
  inputRef,
  onOpenFileUploader,
  onChange,
  onCancel,
}: UseFileUploaderControllerProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const openFileUploader = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    } else {
      const inputFallback = document.getElementById(`${elementId}_input`);

      inputFallback?.click();
    }

    onOpenFileUploader?.();
  }, [inputRef, onOpenFileUploader]);

  const handleFilesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = FileUploaderUtils.getFilesFromChangeEvent(event);
      setSelectedFiles(files);
      onChange(files, event);
    },
    [onChange]
  );

  useEffect(() => {
    const handleCancel = () => {
      setSelectedFiles([]);
      onCancel?.();
    };

    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener('cancel', handleCancel);
    } else {
      const inputFallback = document.getElementById(`${elementId}_input`);

      inputFallback?.addEventListener('cancel', handleCancel);
    }

    return () => {
      if (inputRef && inputRef.current) {
        inputRef.current.removeEventListener('cancel', handleCancel);
      } else {
        const inputFallback = document.getElementById(`${elementId}_input`);

        inputFallback?.removeEventListener('cancel', handleCancel);
      }
    };
  }, [inputRef]);

  return {
    selectedFiles,
    openFileUploader,
    handleFilesChange,
  };
};

export default useFileUploaderController;
