import { useGetNotificationsLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

export const useGetNotifications = () => {
  const [query, { data, refetch, loading }] = useGetNotificationsLazyQuery({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return undefined;
  }

  return {
    data,
    query,
    refetch,
  };
};
