export const KEYS = {
  POINT_REPOSITORY: Symbol.for('POINT_REPOSITORY'),
  AD_CAMPAIGN_REPOSITORY: Symbol.for('AD_CAMPAIGN_REPOSITORY'),
  STREAM_REPOSITORY: Symbol.for('STREAM_REPOSITORY'),
  BILLING_REPOSITORY: Symbol.for('BILLING_REPOSITORY'),
  PAYMENT_METHOD_REPOSITORY: Symbol.for('PAYMENT_METHOD_REPOSITORY'),
  PRICE_PLAN_REPOSITORY: Symbol.for('PRICE_PLAN_REPOSITORY'),
  ACCOUNT_REPOSITORY: Symbol.for('ACCOUNT_REPOSITORY'),
  BUSINESS_PROFILE_REPOSITORY: Symbol.for('BUSINESS_PROFILE_REPOSITORY'),
  COMPANY_REPOSITORY: Symbol.for('COMPANY_REPOSITORY'),
  COMPANY_TAGS_REPOSITORY: Symbol.for('COMPANY_TAGS_REPOSITORY'),
};
