import { AuthTokenType, UserDataType } from 'domain/auth/types';

import { CodeInputFormErrors } from './CodeInputForm';
import { PhoneInputFormErrors, PhoneInputFormInitData } from './PhoneInputForm';
import { LegacyAuthFormErrors, LegacyAuthFormInitData } from './LegacyAuthForm';

export type AuthFormErrors = CodeInputFormErrors | LegacyAuthFormErrors | PhoneInputFormErrors;

export type AuthFormInitData = LegacyAuthFormInitData & PhoneInputFormInitData;

export enum AuthFormStep {
  PHONE_INPUT = 'phone',
  CODE_INPUT = 'code',
  LEGACY_AUTH = 'email',
}

export enum AuthFormMode {
  REGISTER,
  LOGIN,
}

export type AuthFormProps = {
  className?: string;
  isSBBIDAvailable?: boolean;
  step?: AuthFormStep;
  mode?: AuthFormMode;
  initData?: AuthFormInitData;
  onStepChange: (step: AuthFormStep, phone?: string) => void;
  onModeChange?: (mode: AuthFormMode, step: AuthFormStep, phone: string) => void;
  onSuccess: (token: AuthTokenType, userData: UserDataType) => void;
  onPhoneChange?: (phone: string, countryCode: string | undefined) => void;
  onError?: () => void;
};
