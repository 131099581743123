import { useAuthActivationCodeSmsStatusLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { SMSStatus } from './types';

const useActivationCodeStatus = () => {
  const [query, queryResult] = useAuthActivationCodeSmsStatusLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (phone: string) => {
    const result = await query({
      variables: { phone },
    });

    return result.data?.result;
  };

  return {
    request,
    status: queryResult.data?.result as SMSStatus | undefined,
    loading: queryResult.loading,
    called: queryResult.called,
    error: queryResult.error,
  };
};

export default useActivationCodeStatus;
