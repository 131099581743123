import { ZVUK_CDN_URL } from 'application/consts';

import { SourcefileFormatType, SourcefileType } from 'domain/api/graphql/generated';

class FileUtils {
  static download(data: any, filename: string) {
    const file = new Blob([data]);

    const a = document.createElement('a');
    const url = URL.createObjectURL(file);

    a.href = url;
    a.download = filename;
    document.body.appendChild(a);

    a.click();

    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  static downloadExternalFile = (downloadLink: string) => {
    const link = document.createElement('a');
    link.href = downloadLink;
    link.setAttribute('download', '');

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  static getFileFromFileUrl = async (url: string, fileName?: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], fileName || blob.name, { type: blob.type });
    return file;
  };

  static generateMediafileURL(filename: string): string {
    return `${ZVUK_CDN_URL}${filename}`;
  }

  static getMediafileUrl(sourcefiles: Partial<SourcefileType>[]): string {
    const source =
      sourcefiles?.find((el) => el.mainFormat === SourcefileFormatType.Loudnorm_320) ??
      sourcefiles?.find((el) => el.mainFormat === SourcefileFormatType.Loudnorm) ??
      sourcefiles?.find((el) => el.mainFormat === SourcefileFormatType.Original);

    return source?.url ?? '';
  }
}

export default FileUtils;
