import DateUtils from 'toolkit/DateUtils';

import ToolkitUtils from 'toolkit/utils';

import {
  CookieAttributes,
  CookieAttributesKeys,
  CookieAttributesKeysEnum,
  BaseAttributesValues,
  AttributesValues,
} from './types';
import {
  replaceLeftParen,
  replaceRightParen,
  specialCharactersInNamePattern,
  specialCharactersInValuePattern,
} from './consts';

class CookieUtils {
  static stringifyAttribute = (name: string, value: BaseAttributesValues): string => {
    if (!value) {
      return '';
    }

    const stringified = `; ${name}`;

    if (value === true) {
      return stringified;
    }

    return `${stringified}=${value}`;
  };

  static stringifyExpiresAttribute = (expires: number | Date) => {
    let stringifiedExpires = expires;

    if (typeof stringifiedExpires === 'number') {
      const expiresDate = new Date();
      expiresDate.setMilliseconds(expiresDate.getMilliseconds() + stringifiedExpires * DateUtils.MILLISECONDS_IN_DAY);
      stringifiedExpires = expiresDate;
    }

    return `; Expires=${stringifiedExpires.toUTCString()}`;
  };

  static stringifyAttributes = (attributes: CookieAttributes): string =>
    Object.entries(attributes).reduce((acc, [key, value]) => {
      const currentKey = key as CookieAttributesKeys;

      if (currentKey === CookieAttributesKeysEnum.expires) {
        return `${acc}${CookieUtils.stringifyExpiresAttribute(value as number | Date)}`;
      }

      const capitalizedFirstLetterKey = ToolkitUtils.capitalizedFirstLetter(key);

      return `${acc}${CookieUtils.stringifyAttribute(capitalizedFirstLetterKey, value as BaseAttributesValues)}`;
    }, '');

  static encode = (name: string, value: string, attributes: CookieAttributes): string => {
    let encodedName = encodeURIComponent(name);
    encodedName = encodedName.replace(specialCharactersInNamePattern, decodeURIComponent);
    encodedName = replaceLeftParen(encodedName);
    encodedName = replaceRightParen(encodedName);

    let encodedValue = encodeURIComponent(value);
    encodedValue = encodedValue.replace(specialCharactersInValuePattern, decodeURIComponent);

    const encodedAttributes = CookieUtils.stringifyAttributes(attributes);

    return `${encodedName}=${encodedValue}${encodedAttributes}`;
  };

  static parse = (cookieString: string): Record<string, AttributesValues> => {
    const result: { [name: string]: string } = {};
    const cookies = cookieString ? cookieString.split('; ') : [];

    for (const cookie of cookies) {
      const parts = cookie.split('=');
      let value = parts.slice(1).join('=');

      if (value[0] === '"') {
        value = value.slice(1, -1);
      }

      try {
        const name = decodeURIComponent(parts[0]);
        result[name] = value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
      } catch (e) {
        const error = e as Error;
        console.error(`An error has occurred while parsing cookie string ${cookieString}: ${error.message}`);
      }
    }

    return result as CookieAttributes;
  };

  static getAll = (): Record<string, AttributesValues> => CookieUtils.parse(document.cookie);

  static get = (name: CookieAttributesKeys | string): AttributesValues => CookieUtils.getAll()[name];

  static set = (name: string, value: string, attributes?: CookieAttributes): void => {
    document.cookie = CookieUtils.encode(name, value, { path: '/', ...attributes });
  };

  static remove = (name: string, attributes?: CookieAttributes): void => {
    CookieUtils.set(name, '', { ...attributes, expires: -1 });
  };
}

export default CookieUtils;

export type { CookieAttributes } from './types';
