import AdInfoModal from 'application/pages/AdsPage/renderer/AdInfoModal';
import AdCreateModal from 'application/pages/AdsPage/renderer/AdCreateModal';
import AdRenameModal from 'application/pages/AdsPage/renderer/AdRenameModal';
import AdRemoveModal from 'application/pages/AdsPage/renderer/AdRemoveModal';
import AdLogsModal from 'application/pages/AdsPage/renderer/AdLogsModal';
import AdUpdateConfirmModal from 'application/pages/AdsPage/renderer/AdsDrawer/AdUpdateConfirmModal';
import AdUpdateSuccessModal from 'application/pages/AdsPage/renderer/AdsDrawer/AdUpdateSuccessModal';

import LicensesModal from 'application/pages/BillingPage/renderer/LicenseModal';
import LicensesUnpaidModal from 'application/pages/BillingPage/renderer/LicensesUnpaidModal';
import CardRemoveModal from 'application/pages/BillingPage/renderer/CardRemoveModal';
import CardBindModal from 'application/pages/BillingPage/renderer/CardBindModal';
import ExtendSubscriptionModal from 'application/pages/BillingPage/renderer/ExtendSubscriptionModal';

import BusinessProfileUpsertModal from 'application/pages/BusinessProfilePage/renderer/BusinessProfileUpsertModal';
import BusinessProfileAddPlaylistModal from 'application/pages/BusinessProfilePage/renderer/BusinessProfileListPage/BusinessProfileDrawer/BusinessProfileAddPlaylistModal';
import BusinessProfileZvukPlaylistHintModal from 'application/pages/BusinessProfilePage/renderer/BusinessProfileListPage/BusinessProfileDrawer/BusinessProfileZvukPlaylistHintModal';
import BusinessProfileImagesHintModal from 'application/pages/BusinessProfilePage/renderer/BusinessProfileImagesHintModal';
import BusinessProfilePlaylistUpsertModal from 'application/pages/BusinessProfilePage/renderer/BusinessProfileUpsertPlaylistModal';

import { PointSetAdsConfirmModal } from 'application/pages/PointsPage/renderer/components';
import PlayerAppLinksModal from 'application/pages/PointsPage/renderer/PointsDrawer/PointsDrawerContent/PlayerAppsLinksModal';
import PlayerChangeConfirmationModal from 'application/pages/PointsPage/renderer/PointsDrawer/PointsDrawerContent/PlayerChangeConfirmationModal';
import PointUpdateConfirmModal from 'application/pages/PointsPage/renderer/PointsDrawer/PointUpdateConfirmModal';
import PlayerChangeSuccessModal from 'application/pages/PointsPage/renderer/PointsDrawer/PointsDrawerContent/PlayerChangeSuccessModal';

import ExtendSubscriptionSuccessModal from 'application/components/WebPlayer/ExtendSubscriptionSuccessModal';

import {
  PointCreateModal,
  PointCreateHintModal,
  PointCreateConfirmModal,
  OfertaModal,
  CertificateModal,
  LinkedTagsModal,
  ConfirmOperationModal,
  CustomModal,
} from 'application/components/modals';

import SelectPlayerModal from 'application/components/common/SelectPlayerFlow/SelectPlayerModal';
import StreamBindModal from 'application/components/StreamGroupsDrawer/StreamDrawerModals/StreamBindModal';
import StreamBindSuccessModal from 'application/components/common/SelectPlayerFlow/StreamBindSuccessModal';
import StreamRemoveModal from 'application/components/StreamGroupsDrawer/StreamDrawerModals/StreamRemoveModal';
import DenyOperationModal from 'application/components/common/SelectPlayerFlow/DenyOperationModal';
import StopBindingFlowWarningModal from 'application/components/common/SelectPlayerFlow/StopBindingFlowWarningModal';
import SberBoomModal from 'application/components/common/SelectPlayerFlow/RequestDevicesFlow/SberBoomModal';
import SberBussinessPlayerModal from 'application/components/common/SelectPlayerFlow/RequestDevicesFlow/SberBussinessPlayerModal';
import DeviceRequestedModal from 'application/components/common/SelectPlayerFlow/RequestDevicesFlow/DeviceRequestedModal';
import DownloadAppWarningModal from 'application/pages/DownloadAppPage/DownloadAppWarningModal';
import BindDesktopModal from 'application/components/common/SelectPlayerFlow/DesktopPlayerFlow/BindDesktopModal';
import DownloadDesktopModal from 'application/components/common/SelectPlayerFlow/DesktopPlayerFlow/DownloadDesktopModal';
import BindMobileModal from 'application/components/common/SelectPlayerFlow/MobilePlayerFlow/BindMobileModal';
import DownloadMobileModal from 'application/components/common/SelectPlayerFlow/MobilePlayerFlow/DowloadMobileModal';

import CutMusicHelpModal from 'application/pages/PointsPage/renderer/PointsDrawer/PointsDrawerContent/CutMusic/HelpModal';
import StreamMainToolBarFiltersModal from 'application/pages/StreamPage/renderer/StreamMainToolBar/StreamMainToolBarFiltersModal';

import ScheduleUpdateModal from 'application/pages/BroadcastSchedulePage/renderer/ScheduleUpdateModal/ScheduleUpdateModal';
import ScheduleSaveModal from 'application/pages/BroadcastSchedulePage/renderer/ScheduleSaveModal/ScheduleSaveModal';
import ScheduleAdCampaignsModal from 'application/pages/BroadcastSchedulePage/renderer/ScheduleAdCampaignsModal/ScheduleAdCampaignsModal';
import ScheduleHelpModal from 'application/pages/BroadcastSchedulePage/renderer/ScheduleHelpModal';
import ChangeDeviceWarningModal from 'application/components/common/SelectPlayerFlow/ChangeDeviceWarningModal';
import PaymentRequirementModal from 'application/components/common/SelectPlayerFlow/PaymentRequirementModal';
import AddNewYearContentModal from 'application/components/modals/AddNewYearContentModal';
import MultiroomInfoModal from 'application/components/modals/MultiroomInfoModal';
import ChooseVideoPlayerModal from 'application/components/common/SelectPlayerFlow/VideoPlayerFlow/ChooseVideoPlayerModal';
import DownloadTVAppModal from 'application/components/common/SelectPlayerFlow/VideoPlayerFlow/DowloadTVAPPModal';
import BindVideoModal from 'application/components/common/SelectPlayerFlow/VideoPlayerFlow/BindVideoModal';
import VideoStreamBindModal from 'application/pages/VideoStreamsPage/renderer/VideoStreamDrawer/VideoStreamBindModal';
import VideoStreamBindSuccessModal from 'application/pages/VideoStreamsPage/renderer/VideoStreamDrawer/StreamBindSuccessModal';
import SelectVideoMediafilesModal from 'application/pages/VideoStreamsPage/renderer/SelectVideoMediafilesModal';
import CreateVideoStreamModal from 'application/pages/VideoStreamsPage/renderer/CreateVideoStreamModal';
import ProfileInfoModal from '../ProfileProvider/ProfileInfoModal';
import ChangePasswordModal from '../ProfileProvider/ChangePasswordModal';

import { ModalTypes } from './consts';
import { ModalsMapType } from './types';

export const ModalsMap: ModalsMapType = {
  [ModalTypes.AD_CAMPAIGN_CONFIRM_UPDATE]: AdUpdateConfirmModal,
  [ModalTypes.AD_CAMPAIGN_SUCCESS_UPDATE]: AdUpdateSuccessModal,
  [ModalTypes.AD_CAMPAIGN_TYPE_EXPLANATION]: AdInfoModal,
  [ModalTypes.AD_CAMPAIGN_CREATE]: AdCreateModal,
  [ModalTypes.AD_CAMPAIGN_RENAME]: AdRenameModal,
  [ModalTypes.AD_CAMPAIGN_REMOVE]: AdRemoveModal,
  [ModalTypes.AD_CAMPAIGN_LOGS]: AdLogsModal,

  [ModalTypes.BILLING_REMOVE_CARD]: CardRemoveModal,
  [ModalTypes.BILLING_BIND_CARD]: CardBindModal,
  [ModalTypes.BILLING_EXTEND_SUBSCRIPTION]: ExtendSubscriptionModal,

  [ModalTypes.BUSINESS_PROFILE_UPSERT]: BusinessProfileUpsertModal,
  [ModalTypes.BUSINESS_PROFILE_ADD_PLAYLIST]: BusinessProfileAddPlaylistModal,
  [ModalTypes.BUSINESS_PROFILE_ZVUK_PLAYLIST_HINT]: BusinessProfileZvukPlaylistHintModal,
  [ModalTypes.BUSINESS_PROFILE_IMAGES_HINT]: BusinessProfileImagesHintModal,
  [ModalTypes.BUSINESS_PROFILE_PLAYLIST_UPSERT]: BusinessProfilePlaylistUpsertModal,

  [ModalTypes.CERTIFICATE]: CertificateModal,

  [ModalTypes.CHANGE_DEVICE_WARNING]: ChangeDeviceWarningModal,

  [ModalTypes.CONFIRM_OPERATION]: ConfirmOperationModal,
  [ModalTypes.CUSTOM]: CustomModal,
  [ModalTypes.DENY_OPERATION]: DenyOperationModal,

  [ModalTypes.DESKTOP_PLAYER_BIND]: BindDesktopModal,
  [ModalTypes.DESKTOP_PLAYER_DOWNLOAD]: DownloadDesktopModal,

  [ModalTypes.CHOOSE_VIDEO_PLAYER]: ChooseVideoPlayerModal,

  [ModalTypes.DEVICE_REQUESTED]: DeviceRequestedModal,

  [ModalTypes.DOWNLOAD_APP_WARNING_MODAL]: DownloadAppWarningModal,

  [ModalTypes.LINKED_TAGS]: LinkedTagsModal,

  [ModalTypes.LICENSES]: LicensesModal,
  [ModalTypes.LICENSES_UNPAID]: LicensesUnpaidModal,

  [ModalTypes.MOBILE_PLAYER_BIND]: BindMobileModal,
  [ModalTypes.MOBILE_PLAYER_DOWNLOAD]: DownloadMobileModal,

  [ModalTypes.VIDEO_PLAYER_BIND]: BindVideoModal,

  [ModalTypes.DOWNLOAD_TV_APP]: DownloadTVAppModal,

  [ModalTypes.OFFERTA]: OfertaModal,

  [ModalTypes.PAYMENT_REQUIREMENT]: PaymentRequirementModal,

  [ModalTypes.POINT_APP_LINKS]: PlayerAppLinksModal,
  [ModalTypes.POINT_CREATE]: PointCreateModal,
  [ModalTypes.POINT_CREATE_HINT]: PointCreateHintModal,
  [ModalTypes.POINT_CREATE_CONFIRM]: PointCreateConfirmModal,
  [ModalTypes.POINT_PLAYER_CHANGE_CONFIRMATION]: PlayerChangeConfirmationModal,
  [ModalTypes.POINT_UPDATE_CONFIRM]: PointUpdateConfirmModal,
  [ModalTypes.POINT_SET_ADS_CONFIRM]: PointSetAdsConfirmModal,
  [ModalTypes.POINT_PLAYER_CHANGE_SUCCESS]: PlayerChangeSuccessModal,

  [ModalTypes.PROFILE_CHANGE_PASSWORD]: ChangePasswordModal,
  [ModalTypes.PROFILE_INFO]: ProfileInfoModal,

  [ModalTypes.SBERBOOM_REQUEST]: SberBoomModal,
  [ModalTypes.SBERBUSSINESS_REQUEST]: SberBussinessPlayerModal,

  [ModalTypes.SELECT_PLAYER_MODAL]: SelectPlayerModal,

  [ModalTypes.STREAM_BIND]: StreamBindModal,
  [ModalTypes.STREAM_BIND_SUCCESS]: StreamBindSuccessModal,
  [ModalTypes.STREAM_REMOVE]: StreamRemoveModal,
  [ModalTypes.STREAM_STOP_BINDING_FLOW_WARNING]: StopBindingFlowWarningModal,

  [ModalTypes.VIDEO_STREAM_BIND]: VideoStreamBindModal,
  [ModalTypes.VIDEO_STREAM_BIND_SUCCESS]: VideoStreamBindSuccessModal,

  [ModalTypes.SELECT_VIDEO_MEDIAFILES]: SelectVideoMediafilesModal,

  [ModalTypes.CREATE_VIDEO_STREAM]: CreateVideoStreamModal,

  [ModalTypes.WEB_PLAYER_REQUEST_EXTEND_SUBSCRIPTION_SUCCESS]: ExtendSubscriptionSuccessModal,

  [ModalTypes.MUSIC_CUT_HELP_MODAL]: CutMusicHelpModal,

  [ModalTypes.STREAM_MAIN_TOOL_BAR_FILTERS]: StreamMainToolBarFiltersModal,

  [ModalTypes.UPDATE_BROADCAST_SCHEDULE]: ScheduleUpdateModal,
  [ModalTypes.SAVE_BROADCAST_SCHEDULE_CHANGES]: ScheduleSaveModal,
  [ModalTypes.BROADCAST_SCHEDULE_AD_CAMPAIGNS]: ScheduleAdCampaignsModal,
  [ModalTypes.BROADCAST_SCHEDULE_HELP_MODAL]: ScheduleHelpModal,

  [ModalTypes.ADD_NEW_YEAR_CONTENT_MODAL]: AddNewYearContentModal,

  [ModalTypes.MULTIROOM_INFO_MODAL]: MultiroomInfoModal,
};
