import { ViewNotificationMutationInput, useSetNotificationSetViewedMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useSetNotificationViewed = () => {
  const [mutation, mutationResult] = useSetNotificationSetViewedMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ViewNotificationMutationInput) => {
    const mutationResult = await mutation({
      variables: {
        input,
      },
      refetchQueries: ['StreamLibrary'],
      fetchPolicy: FetchPolicyKeys.NO_CACHE,
    });

    return mutationResult?.data?.result?.ok || undefined;
  };

  const result = {
    error: mutationResult.error,
    loading: mutationResult.loading,
  };

  return { request, result };
};

export default useSetNotificationViewed;
