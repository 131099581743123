import { useRef, useState } from 'react';

import { PromoUploadStatusType } from 'domain/media/types';
import { AdPromoPureType } from 'domain/api/graphql/generated';
import { useAdCampaignRepositoryInjector } from 'application/providers/DIContainerProvider';

const usePromoState = () => {
  const [statuses, setStatuses] = useState<PromoUploadStatusType[]>([]);
  const refStatuses = useRef<PromoUploadStatusType[]>([]);

  const { promoUpload } = useAdCampaignRepositoryInjector();

  const set = (adPromos: AdPromoPureType[]) => {
    const newStatuses = adPromos.map((item) => ({
      id: item.id?.indexOf('local_') === 0 ? item.id : `local_${(Math.random() * 1000).toString(16)}`,
      promoId: item.promoId,
      // @ts-ignore
      promoAdId: item.promoAdId || (item.id?.indexOf('local_') === -1 ? item.id : undefined),
      filename: item.filename,
      title: item.title,
      time: item.time,
      isLoading: false,
      duration: item.duration,
      sourcefiles: item.promo.sourcefiles,
    }));

    refStatuses.current = newStatuses;
    setStatuses(newStatuses);
  };

  const clear = () => {
    refStatuses.current = [];
    setStatuses([]);
  };

  const remove = (id: string) => {
    refStatuses.current = refStatuses.current.filter((status) => status.id !== id);
    setStatuses(refStatuses.current);
  };

  const uploadPromo = (file: File, id: string) => {
    promoUpload
      .uploadPromo(file)
      .then(async (result) => {
        const index = refStatuses.current.findIndex((status) => status.id === id);
        const newStatuses = [...refStatuses.current];

        const promo = result.data?.result?.promo;
        const fileId = promo?.id;

        if (fileId) {
          newStatuses[index] = {
            ...newStatuses[index],
            file: undefined,
            promoId: fileId,
            promoAdId: undefined,
            error: undefined,
            filename: promo?.filename!,
            isLoading: false,
            duration: promo?.duration,
            sourcefiles: promo.sourcefiles,
          };
        } else {
          throw result.errors?.[0];
        }

        refStatuses.current = newStatuses;
        setStatuses(newStatuses);
      })
      .catch((e: Error | undefined) => {
        const index = refStatuses.current.findIndex((status) => status.id === id);
        const newStatuses = [...refStatuses.current];

        newStatuses[index] = {
          ...newStatuses[index],
          error: e || new Error(),
          isLoading: false,
        };

        refStatuses.current = newStatuses;
        setStatuses(newStatuses);
      });
  };

  const retry = (id: string) => {
    const promoStatusIndex = refStatuses.current.findIndex((status) => status.id === id);

    const promoStatus = refStatuses.current[promoStatusIndex];
    refStatuses.current = [...refStatuses.current];
    refStatuses.current[promoStatusIndex] = {
      ...promoStatus,
      isLoading: true,
      error: undefined,
    };

    setStatuses(refStatuses.current);

    if (promoStatus?.id) {
      uploadPromo(promoStatus.file!, promoStatus.id);
    }
  };

  const upload = async (files: File[]) => {
    const appendStatuses = files.map((file) => ({
      file,
      id: `local_${(Math.random() * 1000).toString(16)}`,
      promoId: undefined,
      promoAdId: undefined,
      title: file.name,
      filename: file.name,
      isLoading: true,
      sourcefiles: [],
    }));

    const filteredStatuses = refStatuses.current.filter(
      (status) => !!appendStatuses.find((appendStatus) => status.file !== appendStatus.file)
    );

    refStatuses.current = [...appendStatuses, ...filteredStatuses];
    setStatuses(refStatuses.current);

    for (let i = 0; i < appendStatuses.length; i++) {
      uploadPromo(appendStatuses[i].file, appendStatuses[i].id);
    }
  };

  return {
    set,
    upload,
    remove,
    retry,
    clear,
    error: promoUpload.result.error,
    loading: promoUpload.result.loading,
    called: promoUpload.result.called,
    data: {
      statuses: statuses.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.error ? -1 : b.error ? 1 : 0
      ),
    },
  };
};

export default usePromoState;
