import { useMediaAdCampaignReportCreateMutation } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import GraphQLApiUtils from 'domain/api/graphql/utils';

import { UseAdCampaignReportCreateResultType, UseAdCampaignReportCreateType } from './types';

const useAdCampaignReportCreate = (): UseAdCampaignReportCreateType => {
  const [reportCreateMutation, reportCreateMutationResult] = useMediaAdCampaignReportCreateMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (adCampaignIds: string[], periodStart: string, periodEnd: string, recipientEmail?: string) => {
    const adCampaign = await reportCreateMutation({
      variables: {
        input: {
          adcampaignIds: adCampaignIds,
          periodStart,
          periodEnd,
          recipientEmail,
        },
      },
    });

    return !!adCampaign.data?.result?.ok;
  };

  const reportCreateData = reportCreateMutationResult.data?.result;
  const hasErrors = !!reportCreateData?.errors;
  const updateResultData: UseAdCampaignReportCreateResultType['data'] = { ok: reportCreateData?.ok };

  if (hasErrors) {
    updateResultData.errors = GraphQLApiUtils.errorsArrayToObject(reportCreateData?.errors);
  }

  const result: UseAdCampaignReportCreateResultType = {
    error: reportCreateMutationResult.error,
    loading: reportCreateMutationResult.loading,
    called: reportCreateMutationResult.called,
    data: updateResultData,
  };

  return [request, result];
};

export default useAdCampaignReportCreate;
