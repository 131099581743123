import React, { FC, useMemo, useEffect } from 'react';

import { TEXTS } from 'application/pages/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import { RouterUtils, useNavigate } from 'application/providers/RouterProvider';
import AnalyticsService, {
  CalledFromValues,
  EventNameAMP,
  EventNameGTM,
  ParamNameAMP,
} from 'application/services/AnalyticsService';
import {
  PointParamNameAMP,
  StreamBindFlowEventNameAMP,
  StreamBindFlowParamNameAMP,
} from 'application/services/AnalyticsService/ampTypes';
import { SelectPlayerFlowType } from 'application/components/common/SelectPlayerFlow/types';
import { POINTS_MENU_PATH } from 'application/consts';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import PointAddressesList from './PointAddressesList';

import { PointFromLocationType, BREAK_EDGE_VALUE, StreamBindSuccessModalType } from './const';
import useStreamBindSuccessModalController from './useStreamBindSuccessModalController';
import useStreamBindSuccessModalAdapter from './useStreamBindSuccessModalAdapter';

import styles from './index.module.scss';

export type StreamBindSuccessModalProps = {
  selectedPoints: PointsListCheckboxGroupPointType[];
  pointFromLocation?: PointFromLocationType;
  successCallback?: () => void;
  flowType?: SelectPlayerFlowType;
  updatePointsList?: () => Promise<void>;
  openPaymentModal?: () => void;
  isNeedPayment: boolean;
};

const StreamBindSuccessModal: FC<ModalProps[ModalTypes.STREAM_BIND_SUCCESS]> = ({
  close,
  selectedPoints = [],
  pointFromLocation,
  successCallback,
  flowType,
  updatePointsList,
  openPaymentModal,
  isNeedPayment,
}) => {
  const navigate = useNavigate();

  const { watchCurrentPoint, clearCurrentPoint } = useStreamBindSuccessModalAdapter();
  const currentPoint = watchCurrentPoint();

  const {
    showExpandButton,
    selectedPointsAddresses,
    setIsAllPointsVisible,
    onChangeStream,
    isPointPage,
    showStreamsButton,
    isChangeStreamFlow,
    showWebPlayerButton,
    isSettingsFinished,
  } = useStreamBindSuccessModalController({ selectedPoints, flowType });

  const onBindPointSuccessClick = () => {
    if (!showWebPlayerButton) {
      navigate(RouterUtils.generatePath(POINTS_MENU_PATH));
    }

    if (showWebPlayerButton) {
      AnalyticsService.event(EventNameAMP.OPEN_WEBPLAYER_PRESSED, {
        [PointParamNameAMP.POINT_ID]: pointFromLocation?.pointId!,
        [ParamNameAMP.CALLED_FROM]: CalledFromValues.STREAM_BC_SUCCESS,
      });

      if (isNeedPayment) {
        openPaymentModal?.();
      } else {
        setTimeout(() => {
          window.open(selectedPoints[0].device?.requiredVersionUrl!);
        }, 100);
      }
    }

    successCallback?.();
    close();
    updatePointsList?.();
  };

  const onClose = () => {
    clearCurrentPoint();
    successCallback?.();
    close();
    updatePointsList?.();
  };

  const onChangeStreamClick = () => {
    AnalyticsService.event(EventNameAMP.ADDRESS_STREAM_ADD_CLICKED, {
      [ParamNameAMP.TYPE]: 'success bind device modal',
    });

    onChangeStream({ pointId: currentPoint?.id, pointAddress: currentPoint?.address });
    close();
  };

  useEffect(() => {
    let successModalType = StreamBindSuccessModalType.COMPLETED;

    if (showWebPlayerButton) {
      successModalType = StreamBindSuccessModalType.OPEN_WEBPLAYER;
    }

    if (showStreamsButton) {
      successModalType = StreamBindSuccessModalType.SELECT_WAVE;
    }

    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_BIND_SUCCESS_MODAL_SHOWED, {
      [StreamBindFlowParamNameAMP.SUCCESS_MODAL_TYPE]: successModalType,
    });

    if (successModalType !== StreamBindSuccessModalType.SELECT_WAVE) {
      AnalyticsService.eventGTM(EventNameGTM.WAVE_INITIATION_VIEWED);
    }
  }, []);

  const SuccessActionButton = useMemo(() => {
    if (showStreamsButton) {
      return (
        <Button
          key="button_open_streams_success_modal"
          elementId="button_open_streams_success_modal"
          type={ButtonType.PRIMARY}
          onClick={onChangeStreamClick}>
          {TEXTS.MODAL_BIND_SUCCESS_BUTTON_OPEN_STREAMS_TEXT}
        </Button>
      );
    }

    if (showWebPlayerButton) {
      return (
        <Button
          key="button_open_web_player_bind_success_modal"
          elementId="button_open_web_player_bind_success_modal"
          type={ButtonType.PRIMARY}
          onClick={onBindPointSuccessClick}>
          {TEXTS.MODAL_BIND_SUCCESS_BUTTON_OPEN_WEB_PLAYER_TEXT}
        </Button>
      );
    }

    if (isChangeStreamFlow) {
      return (
        <Button
          key="button_open_web_player_bind_success_modal"
          elementId="button_change_device_success_modal"
          type={ButtonType.PRIMARY}
          onClick={close}>
          {TEXTS.MODAL_BIND_SUCCESS_BUTTON_SUCCESS_TEXT}
        </Button>
      );
    }

    return (
      <Button
        key="button_open_points_bind_success_modal"
        elementId="button_open_points_bind_success_modal"
        type={ButtonType.PRIMARY}
        onClick={onBindPointSuccessClick}>
        {TEXTS.MODAL_BIND_SUCCESS_BUTTON_OPEN_POINTS_TEXT}
      </Button>
    );
  }, [showWebPlayerButton, isChangeStreamFlow, showStreamsButton, currentPoint.stream?.id]);

  useEffect(
    () => () => {
      if (flowType === SelectPlayerFlowType.BIND_STREAM) {
        clearCurrentPoint();
      }
    },
    []
  );

  const successBindTitle = isNeedPayment ? TEXTS.MODAL_BIND_SUCCESS_NON_ACTIVE_TITLE : TEXTS.MODAL_BIND_SUCCESS_TITLE;
  const successBindDescription = isNeedPayment
    ? TEXTS.MODAL_BIND_SUCCESS_NON_ACTIVE_DESC
    : TEXTS.MODAL_BIND_SUCCESS_DESC;

  return (
    <ModalScreen
      title={isPointPage ? TEXTS.MODAL_BIND_SUCCESS_POINT_CREATE_TITLE : successBindTitle}
      titlePlacement="left"
      containerClassName={styles.StreamBindSuccessModalContainer}
      onClose={onClose}
      actionButtons={[
        SuccessActionButton,
        !isPointPage && (
          <Button
            key="button_cancel_bind_success_modal"
            elementId="button_cancel_bind_success_modal"
            type={ButtonType.SECONDARY}
            onClick={onClose}>
            {TEXTS.MODAL_BIND_SUCCESS_BUTTON_CANCEL_TEXT}
          </Button>
        ),
      ]}>
      <div className={styles.StreamBindSuccessModalDesc}>
        {isSettingsFinished ? successBindDescription : TEXTS.MODAL_BIND_SUCCESS_POINT_CREATE_DESC}
      </div>

      {selectedPointsAddresses.length > 0 && (
        <div className={styles.StreamBindSuccessModalAddresses}>
          <PointAddressesList selectedPoints={selectedPointsAddresses} />

          {showExpandButton && (
            <Button
              elementId="stream_bind_success_modal_button_show_all"
              className={styles.StreamBindSuccessModalButton}
              type={ButtonType.PLAIN}
              onClick={() => setIsAllPointsVisible(true)}>
              {TEXTS.MODAL_BIND_SUCCESS_MORE_ADDRESS(selectedPoints.length - BREAK_EDGE_VALUE)}
            </Button>
          )}
        </div>
      )}
    </ModalScreen>
  );
};

export default StreamBindSuccessModal;
