import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';

import { UseFilesActionsHelperProps, AllFileErrorCodeType, FileRejection } from './types';

const useFilesActionsHelper = <T extends File, K extends AllFileErrorCodeType>({
  value,
  onChange,
  getFileRejection,
  getFilesFromEvent,
}: UseFilesActionsHelperProps<T, K>) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [previewFiles, setPreviewFiles] = useState<Map<string, T>>(new Map());
  const [fileRejections, setFileRejections] = useState<Map<string, FileRejection<K>>>(new Map());
  const [currentFileForEditing, setCurrentFileForEditing] = useState<T>();

  const isUploadButtonVisible = useMemo(
    () => previewFiles.size === 0 || fileRejections.size !== 0,
    [previewFiles, fileRejections]
  );

  useEffect(() => {
    if (value) {
      const acceptedFiles: Map<string, T> = new Map(previewFiles);

      for (let i = 0; i < value.files.length; i += 1) {
        acceptedFiles.set(value.files[i].name, value.files[i] as T);
      }

      setPreviewFiles(acceptedFiles);
    }
  }, [value]);

  const handleFileEdit = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = await getFilesFromEvent(event);
      const file = files[0];

      const fileRejection = getFileRejection(file);

      const acceptedFiles: Map<string, T> = new Map(previewFiles);
      const newFileRejections: Map<string, FileRejection<K>> = new Map(fileRejections);

      if (currentFileForEditing) {
        acceptedFiles.delete(currentFileForEditing.name);
      }

      if (fileRejection) {
        newFileRejections.set(file.name, fileRejection);
      } else {
        acceptedFiles.set(file.name, file);
      }

      setPreviewFiles(acceptedFiles);
      setFileRejections(newFileRejections);
      setCurrentFileForEditing(undefined);

      onChange?.({
        files: [...acceptedFiles.values()],
        fileRejections: [...newFileRejections.values()],
        event,
      });
    },
    [previewFiles, fileRejections, currentFileForEditing, setPreviewFiles, setFileRejections, setCurrentFileForEditing]
  );

  const handleFilesChange = useCallback(
    async (_: unknown, event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) return;

      if (currentFileForEditing) {
        await handleFileEdit(event);

        return;
      }

      const files = await getFilesFromEvent(event);

      const acceptedFiles: Map<string, T> = new Map();
      const newFileRejections: Map<string, FileRejection<K>> = new Map();

      files.forEach((file: T) => {
        const fileRejection = getFileRejection(file);

        if (fileRejection) {
          newFileRejections.set(file.name, fileRejection);
        } else {
          acceptedFiles.set(file.name, file);
        }
      });

      setPreviewFiles(acceptedFiles);
      setFileRejections(newFileRejections);

      onChange?.({
        files: [...acceptedFiles.values()],
        fileRejections: [...newFileRejections.values()],
        event,
      });
    },
    [onChange, handleFileEdit]
  );

  const handleFileUploaderOpen = useCallback(
    (file: T) => {
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.click();
      }

      setCurrentFileForEditing(file);
    },
    [fileInputRef, setCurrentFileForEditing]
  );

  const handleFileUploaderCancel = useCallback(() => {
    setCurrentFileForEditing(undefined);
  }, [setCurrentFileForEditing]);

  const handleFileRemove = useCallback(
    (filename: string) => {
      const acceptedFiles: Map<string, T> = new Map(previewFiles);

      acceptedFiles.delete(filename);

      setPreviewFiles(acceptedFiles);

      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      onChange?.({
        files: [...acceptedFiles.values()],
        fileRejections: [...fileRejections.values()],
      });
    },
    [previewFiles, setPreviewFiles]
  );

  const handleRejectedFileRemove = useCallback(
    (filename: string) => {
      const rejections: Map<string, FileRejection<K>> = new Map(fileRejections);

      rejections.delete(filename);

      setFileRejections(rejections);

      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      onChange?.({
        files: [...previewFiles.values()],
        fileRejections: [...rejections.values()],
      });
    },
    [fileRejections, setFileRejections]
  );

  return {
    fileInputRef,
    previewFiles,
    fileRejections,
    currentFileForEditing,
    isUploadButtonVisible,
    handleFilesChange,
    handleFileUploaderOpen,
    handleFileUploaderCancel,
    handleFileRemove,
    handleRejectedFileRemove,
  };
};

export default useFilesActionsHelper;
