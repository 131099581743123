import React, { FC } from 'react';

import { TEXTS } from 'application/pages/AdsPage/renderer/texts';
import { ModalTypes, ModalProps } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import styles from './index.module.scss';

export type AdUpdateSuccessModalProps = {
  onSubmit: () => void;
};

const AdUpdateSuccessModal: FC<ModalProps[ModalTypes.AD_CAMPAIGN_SUCCESS_UPDATE]> = ({ onSubmit, close }) => {
  const onSubmitClick = () => {
    onSubmit();
    close();
  };

  return (
    <ModalScreen
      title={TEXTS.SAVE_INSTANT_AD_MODAL_SUCCESS_TITLE}
      onClose={close}
      className={styles.AdUpdateSuccessModal}
      actionButtons={[
        <Button
          key="button_ok_ad_campaign_update_success_modal"
          elementId="button_ok_ad_campaign_update_success_modal"
          type={ButtonType.PRIMARY}
          onClick={onSubmitClick}>
          {TEXTS.SAVE_INSTANT_AD_MODAL_SUCCESS_OK_BUTTON_TEXT}
        </Button>,
        <Button
          key="button_cancel_ad_campaign_update_success_modal"
          elementId="button_cancel_ad_campaign_update_success_modal"
          type={ButtonType.SECONDARY}
          onClick={close}>
          {TEXTS.SAVE_INSTANT_AD_MODAL_SUCCESS_CANCEL_BUTTON_TEXT}
        </Button>,
      ]}>
      <div className={styles.AdUpdateSuccessModalDesc}>{TEXTS.SAVE_INSTANT_AD_MODAL_SUCCESS_DESCRIPTION}</div>
    </ModalScreen>
  );
};

export default AdUpdateSuccessModal;
