import React from 'react';

import StreamGroupsPageWrapper from 'application/pages/StreamPage';
import StreamSearchPageWrapper from 'application/pages/StreamSearchPage';
import StreamSubCollectionPageWrapper from 'application/pages/StreamSubCollectionPage';
import StreamGroupsGroupPageWrapper from 'application/pages/StreamGroupPage';
import StreamGroupsCollectionPageWrapper from 'application/pages/StreamCollectionPage';
import StreamSearchProvider from 'application/providers/StreamSearchProvider';
import { Outlet } from 'application/providers/RouterProvider';
import SmartLayout from 'application/layout/SmartLayout';
import StreamsGridProvider from 'application/providers/StreamsGridProvider';
import PlayerProvider from 'application/providers/PlayerProvider';
import PointModelProvider from 'application/pages/PointsPage/providers/PointModelProvider';
import StreamLibrariesProvider from 'application/providers/StreamLibrariesProvider';
import {
  STREAM_GROUPS_COLLECTION_PAGE_PATH,
  STREAM_GROUPS_GROUP_PAGE_PATH,
  STREAM_SEARCH_PAGE_PATH,
  STREAM_SUB_COLLECTIONS_PAGE_PATH,
  STREAMS_STREAM_CATEGORY_BY_ID_PATH,
} from 'application/consts';

import { RouteConfig } from '..';

export const streamsRoutes: RouteConfig[] = [
  {
    element: <SmartLayout />,
    children: [
      {
        element: (
          // TODO refactor and remove this later
          <StreamLibrariesProvider>
            <StreamSearchProvider>
              <PlayerProvider>
                <PointModelProvider pageSize={30}>
                  <Outlet />
                </PointModelProvider>
              </PlayerProvider>
            </StreamSearchProvider>
          </StreamLibrariesProvider>
        ),
        children: [
          {
            // TODO refactor and remove this later
            element: (
              <StreamsGridProvider>
                <Outlet />
              </StreamsGridProvider>
            ),
            children: [
              {
                path: STREAMS_STREAM_CATEGORY_BY_ID_PATH,
                element: <StreamGroupsPageWrapper />,
              },
            ],
          },
          {
            path: STREAM_SEARCH_PAGE_PATH,
            element: <StreamSearchPageWrapper />,
          },
          {
            path: STREAM_SUB_COLLECTIONS_PAGE_PATH,
            element: <StreamSubCollectionPageWrapper />,
          },
          {
            path: STREAM_GROUPS_GROUP_PAGE_PATH,
            element: <StreamGroupsGroupPageWrapper />,
          },
          {
            path: STREAM_GROUPS_COLLECTION_PAGE_PATH,
            element: <StreamGroupsCollectionPageWrapper />,
          },
        ],
      },
    ],
  },
];
