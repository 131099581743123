import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDebounceFn } from 'ahooks';

import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';
import { useAccountContext } from 'application/providers/AccountProvider';
import { useNavigate, useLocation } from 'application/providers/RouterProvider';
import { LocationStatePointsType } from 'application/pages/types';
import { StreamBindPointsProviderFilterType } from 'application/pages/StreamPage/providers/StreamBindPointsProvider/types';
import { FN_OPTIONS } from 'application/consts';

import {
  CertificatePointsQueryFilter,
  Country,
  PaginationInfoType,
  PointQueryFilter,
  PointQueryPagination,
  PointShortTypeFragment,
} from 'domain/api/graphql/generated';

import AnalyticsService, { EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { TEXTS } from './texts';
import { OnCertSubmitType } from '.';
import { CertificatePointsType } from './types';

export const BILLING_PAGE_PATH = '/billing/info';
export const PRICE_PLAN_PAGE_PATH = '/price-plan';

type UseCertificateControllerProps = {
  onSubmit: (input: OnCertSubmitType) => void;
  initialPoints: CertificatePointsType[];
  pointsLoading: boolean;
  allPointsCount: number | null;
  paginationInfo: PaginationInfoType;
  requestPoints: (filters?: PointQueryFilter, pagination?: PointQueryPagination) => void;
  loadMorePoints: () => Promise<PointShortTypeFragment[] | undefined>;
  certificatePointsCount: number;
  filters: CertificatePointsQueryFilter;
  setIsAllSelected: React.Dispatch<SetStateAction<boolean>>;
  pointLoading: boolean;
};

const useCertificateController = ({
  onSubmit,
  initialPoints,
  pointsLoading,
  allPointsCount,
  paginationInfo,
  requestPoints,
  filters,
  setIsAllSelected,
  pointLoading,
}: UseCertificateControllerProps) => {
  const navigate = useNavigate();
  const location = useLocation<LocationStatePointsType>();

  const [pointFromLocation] = location.state?.pointsFromLocation || [];

  const { currentCompany } = useAccountContext();
  const notification = useNotificationContext();

  const [points, setPoints] = useState<PointsListCheckboxGroupPointType[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [chosenPointsIds, setChosenPointsIds] = useState<string[]>([]);
  const [chosenPoints, setChosenPoints] = useState<PointsListCheckboxGroupPointType[]>([]);

  const filtersRef = useRef(filters);

  const onFilterExecute = async (filters: StreamBindPointsProviderFilterType) => {
    const preparedFilters: CertificatePointsQueryFilter = {
      companyId: currentCompany?.id || '',
    };

    const { query } = filters;

    if (query !== undefined) {
      preparedFilters.query = query;
      filtersRef.current.query = query;
    }

    if (!preparedFilters.query && filtersRef.current.query) {
      preparedFilters.query = filtersRef.current.query;
    }

    await requestPoints(preparedFilters);
  };

  const onExecute = async (filters: StreamBindPointsProviderFilterType) => {
    await onFilterExecute?.(filters);
  };

  const { run: debouncedSearchRequest } = useDebounceFn(
    (value: string) =>
      onExecute?.({
        query: value,
      }),
    FN_OPTIONS
  );

  const onSearchChange = (value: string) => {
    if (value.length === 0 && pointFromLocation?.pointId) {
      navigate('.', { state: null, replace: true });
    }

    setSearchText(value);
    setPoints([]); // reset points in state before new filters request
    debouncedSearchRequest(value);
  };

  useEffect(() => {
    if (!initialPoints || initialPoints.length === 0) {
      return;
    }

    const newPointsLastElement = initialPoints[initialPoints.length - 1];
    const currentPointsLastElement = points[points.length - 1];

    if (newPointsLastElement.id !== currentPointsLastElement?.id) {
      const newPoints = initialPoints.map((point) => ({
        id: point.id,
        title: point.address,
        isDisabled: !point.certificateIsAvailable,
      }));

      setPoints([...points, ...newPoints]);
    }
  }, [initialPoints]);

  useEffect(() => {
    requestPoints();
  }, []);

  useEffect(() => {
    const updatedChosenPoints = points.map((point) => ({
      ...point,
      isSelected: chosenPointsIds.includes(point.id),
    }));

    setChosenPoints(updatedChosenPoints);

    if (updatedChosenPoints.length === allPointsCount && allPointsCount > 0) {
      const formattedPointsIds = [] as string[];

      updatedChosenPoints.forEach((point) => {
        if (!point.isDisabled) {
          formattedPointsIds.push(point.id);
        }
      });
    }
  }, [points, pointsLoading, paginationInfo, allPointsCount]);

  const showBillingModal = !pointLoading && initialPoints.length > 0 && allPointsCount === 0;
  const showEmptyPointsModal = !pointLoading && (initialPoints.length || 0) <= 0 && allPointsCount === 0;

  const isTrialPeriod =
    currentCompany && currentCompany.trialPeriod
      ? !currentCompany.trialPeriod.isExpired && !currentCompany.trialPeriod.isFinished
      : false;

  const showEmptyPointsTrialModal = showEmptyPointsModal && isTrialPeriod && currentCompany?.country !== Country.Ru;
  const showPointsModal = !showBillingModal && !showEmptyPointsModal && !showEmptyPointsTrialModal;

  const billingPath = currentCompany?.isBillingEnabled ? BILLING_PAGE_PATH : PRICE_PLAN_PAGE_PATH;

  const onCreateCertificate = (createForAll = false) => {
    try {
      AnalyticsService.event(EventNameAMP.CERTIFICATE_CREATE_BUTTON_CLICKED);

      if (createForAll) {
        onSubmit({ createForAll });
      } else {
        onSubmit({ pointIds: chosenPointsIds });
      }

      notification.showNotification({
        type: NotificationColors.INFO,
        children: TEXTS.DOWNLOAD_SUCCESS_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });

      AnalyticsService.event(EventNameAMP.CERTIFICATE_DOWNLOADED);
    } catch (error) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: TEXTS.DOWNLOAD_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const onSelect = (value: boolean, currentPoint: PointsListCheckboxGroupPointType) => {
    AnalyticsService.event(EventNameAMP.CERTIFICATE_ADDRESS_SELECTED, {
      [ParamNameAMP.VALUE]: value,
    });

    const isAlreadyChosen = chosenPointsIds.includes(currentPoint.id);

    if (value && !isAlreadyChosen) {
      setChosenPointsIds([...chosenPointsIds, currentPoint.id]);
    }

    if (!value && isAlreadyChosen) {
      const index = chosenPointsIds.indexOf(currentPoint.id);
      const updatedChosenPoints = [...chosenPointsIds];

      if (index > -1) {
        updatedChosenPoints.splice(index, 1);
      }
      setChosenPointsIds(updatedChosenPoints);
    }
  };

  const onSelectAll = (value: boolean) => {
    AnalyticsService.event(EventNameAMP.CERTIFICATE_ADDRESS_SELECTED, {
      [ParamNameAMP.VALUE]: value,
    });

    setIsAllSelected(value);
  };

  return {
    showBillingModal,
    showEmptyPointsModal,
    showEmptyPointsTrialModal,
    showPointsModal,
    chosenPointsIds,
    chosenPoints,
    setPoints,
    searchText,
    onSearchChange,
    billingPath,
    isBillingEnabled: currentCompany?.isBillingEnabled,
    currentCompanyCountry: currentCompany?.country,
    onCreateCertificate,
    onSelect,
    onSelectAll,
  };
};

export default useCertificateController;
