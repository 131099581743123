import React from 'react';
import { PointsListDataItem } from '../../../types';
import { useIsPointsListChanged } from '../../hooks/useIsPointsListChanged';

interface VideoStreamsPointsControllerProps {
  initialSelectedPoints: PointsListDataItem[];
  points: PointsListDataItem[];
}

export const useVideoStreamsPointsController = ({
  initialSelectedPoints,
  points,
}: VideoStreamsPointsControllerProps) => {
  const [selectedPoints, setSelectedPoints] = React.useState<PointsListDataItem[]>(initialSelectedPoints);

  const onPointsChange = (pointsIds: PointsListDataItem[]) => {
    const pointsSet = new Set(pointsIds.map(({ id }) => id));
    setSelectedPoints(points.filter((point) => pointsSet.has(point.id)));
  };

  const isButtonDisabled = useIsPointsListChanged(selectedPoints, initialSelectedPoints);

  return {
    onPointsChange,
    selectedPoints,
    isButtonDisabled,
  };
};
