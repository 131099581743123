import React, { useState, useMemo, useLayoutEffect } from 'react';

import PhoneUtils from 'application/utils/PhoneUtils';
import AnalyticsService, { ParamNameAMP, RegistrationSourceValueMap } from 'application/services/AnalyticsService';
import CompanyUtils from 'application/utils/CompanyUtils';
import { TOP_BANNER_TYPE_CLOSED } from 'application/consts';

import AuthUtils from 'domain/auth/utils';
import { RegistrationSource } from 'domain/api/graphql/generated';

import { CAPTCHA_TOKEN_KEY } from './consts';
import { AuthDataInputType, AuthMessageKeys, LocalAccessRoles } from './types';
import { AuthContext } from './AuthContext';

const getInitialAuthData = () => {
  const authToken = AuthUtils.getTokenCookie();

  if (!authToken) {
    return {
      authToken: undefined,
      userData: undefined,
    };
  }

  const userData = AuthUtils.readCachedUser();

  return {
    authToken,
    userData,
  };
};

const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [authData, setAuthDataState] = useState<AuthDataInputType>(getInitialAuthData());

  const setCaptchaToken = (token: string | null) => {
    if (token) {
      localStorage.setItem(CAPTCHA_TOKEN_KEY, token);
    } else {
      localStorage.removeItem(CAPTCHA_TOKEN_KEY);
    }
  };

  const getCaptchaToken = () => localStorage.getItem(CAPTCHA_TOKEN_KEY);

  const authToken = authData.authToken;
  const userData = authData.userData;
  const accessRole = authToken ? LocalAccessRoles.AUTHORIZED : LocalAccessRoles.ANONYMOUS;

  const setAuthData = (value: AuthDataInputType) => {
    const newAuthDataState = { ...authData, ...value };

    if (newAuthDataState.authToken) {
      AuthUtils.setTokenCookie(newAuthDataState.authToken);
    } else {
      AuthUtils.removeTokenCookie();
      AuthUtils.clearCachedUser();
      localStorage.removeItem(TOP_BANNER_TYPE_CLOSED);
    }

    if (newAuthDataState.authToken && newAuthDataState.userData) {
      AuthUtils.cacheUser(newAuthDataState.userData);
    } else {
      AuthUtils.clearCachedUser();
    }

    setAuthDataState(newAuthDataState);
  };

  useLayoutEffect(() => {
    if (authToken) {
      window.parent.postMessage(AuthMessageKeys.LOGGED_IN, '*');
    } else {
      window.parent.postMessage(AuthMessageKeys.LOGGED_OUT, '*');
    }
  }, [authToken]);

  useLayoutEffect(() => {
    AnalyticsService.setUserId(userData?.id, userData?.isTest);

    try {
      AnalyticsService.setUserProperties({
        [ParamNameAMP.COMPANY_ID]: userData?.mainCompany?.id,
        [ParamNameAMP.IS_TEST]: userData?.isTest,
        [ParamNameAMP.EMAIL]: userData?.email,
        [ParamNameAMP.PHONE_NUM]: userData?.phone,
        [ParamNameAMP.PHONE_NUM_COUNTRY]: userData?.phone
          ? PhoneUtils.parsePhoneNumberFromString(userData?.phone)?.country
          : undefined,
        [ParamNameAMP.COMPANY_BUSINESS_SPHERE]: userData?.mainCompany?.businessSphereId,
        [ParamNameAMP.COMPANY_COUNTRY]: userData?.mainCompany?.country,
        [ParamNameAMP.COMPANY_INN]: CompanyUtils.getCompanyINNByCountry(userData?.mainCompany),
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const registrationSource = userData?.registrationSource;
    const registrationSourceFromSB =
      registrationSource === RegistrationSource.SbBusinessId || registrationSource === RegistrationSource.SbOnline;

    if (registrationSourceFromSB) {
      AnalyticsService.setUserProperties({
        [ParamNameAMP.SIGN_UP_SOURCE]: RegistrationSourceValueMap[registrationSource],
      });
    }
  }, [userData?.id, userData?.smartLkAccess]);

  const contextValue = useMemo(
    () => ({
      accessRole,
      authToken,
      userData,
      setAuthData,
      hasAccess: accessRole === LocalAccessRoles.AUTHORIZED,
      hasPersonalAccountAccess: userData?.personalAccountAccess || false,
      getCaptchaToken,
      setCaptchaToken,
    }),
    [authToken, userData]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
