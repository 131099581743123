import React, { Suspense } from 'react';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import { IApplicationPage } from '../types';
import PAGES_CONST from '../constants';

export const SHELF_PAGE_PATH = '/shelf';

const Renderer = React.lazy(() => import('./renderer'));

const ShelftPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

ShelftPageWrapper.config = {
  routes: [
    {
      path: SHELF_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default ShelftPageWrapper;
