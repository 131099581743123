import { NetworkStatus } from '@apollo/client';

import { useAuthCurrentUserLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { CurrentUserResultType, UseCurrentUserType, UserDataType, UseCurrentUserConfig } from './types';

const useCurrentUser = (config?: UseCurrentUserConfig): UseCurrentUserType => {
  const [query, queryResult] = useAuthCurrentUserLazyQuery({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const user = data?.currentUserPureQuery as UserDataType;

      if (user && config?.onCompleted) {
        config?.onCompleted(user);
      }
    },
  });

  const request = async () => {
    const result = await query();

    return result.data?.currentUserPureQuery as UserDataType;
  };

  const result: CurrentUserResultType = {
    error: queryResult.error,
    loading: queryResult.loading,
    refetching: queryResult.networkStatus === NetworkStatus.refetch,
    data: {
      user: queryResult?.data?.currentUserPureQuery as UserDataType,
    },
  };

  return [request, result];
};

export default useCurrentUser;
