import { useEffect } from 'react';

const useScrollOutsideCallback = (target: HTMLElement, callback: () => void) => {
  useEffect(() => {
    const onScroll = (event: any) => {
      if (target !== event.target) {
        callback();
      }
    };

    window.addEventListener('scroll', onScroll, true);

    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [target, callback]);
};

export default useScrollOutsideCallback;
