import { useAccountContext } from 'application/providers/AccountProvider';
import { usePaymentMethodRepositoryInjector } from 'application/providers/DIContainerProvider';

const useCardBindModalPresenter = () => {
  const { currentCompany } = useAccountContext();

  const { getLinkForBindingCard: getLinkForBindingCardRepository, bindCardLoading } =
    usePaymentMethodRepositoryInjector();

  const getLinkForBindingCard = async (pathname: string) => {
    const link = await getLinkForBindingCardRepository(pathname, currentCompany!.id);

    return link;
  };

  return {
    getLinkForBindingCard,
    bindCardLoading,
  };
};

export default useCardBindModalPresenter;
