import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { CommonModalProps } from 'application/providers/ModalProvider/modalsProps';
import React, { useState } from 'react';
import { TEXTS } from '../texts';
import { ScheduleSaveModalModalProps } from './types';

const ScheduleSaveModal = ({ onSave, onCancel, close, afterClose }: ScheduleSaveModalModalProps & CommonModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    setIsLoading(true);

    onSave().then(() => {
      setIsLoading(false);
      close();
    });
  };

  const handleRemove = () => {
    onCancel();
    close();
  };

  const handleClose = () => {
    close();
    afterClose?.();
  };

  return (
    <ModalScreen
      title={TEXTS.CHANGES_NOT_SAVED}
      titlePlacement="left"
      actionsPlacement="left"
      onClose={handleClose}
      actionButtons={[
        <Button
          key="filter_tags_apply"
          elementId="filter_tags_apply"
          type={ButtonType.PRIMARY}
          isLoading={isLoading}
          onClick={handleSave}>
          {TEXTS.KEEP_CHANGES}
        </Button>,
        <Button
          key="filter_tags_clear"
          elementId="filter_tags_clear"
          type={ButtonType.SECONDARY}
          onClick={handleRemove}>
          {TEXTS.DELETE_CHANGES}
        </Button>,
      ]}>
      {TEXTS.SAVE_CHANGES_WARNING}
    </ModalScreen>
  );
};

export default ScheduleSaveModal;
