import React from 'react';

import DeclensionUtils from 'application/utils/DeclensionUtils';

export const TEXTS = {
  DOC_TITLE: 'Библиотека волн – Звук Бизнес',
  PAGE_TITLE: 'Волны',
  TAB_ALL_TITLE: 'Библиотека',
  TAB_RECOMMENDED: 'Рекомендовано',
  TAB_SAVED_TITLE: 'Мои',
  TAB_STREAM_MEDIAFILES: 'Треки',
  TAB_STREAM_POINTS: 'Адреса',
  LIST_STREAM_GENRES_TITLE: 'По жанрам',
  LIST_STREAM_SPHERES_TITLE: 'По атмосфере',
  LIST_STREAM_BUSINESS_TITLE: 'По типам бизнеса',
  BUTTON_MORE_TITLE: 'Показать ещё',
  BUTTON_WAVE_NO_SOUND: 'Волна пока нигде не звучит',
  STREAM_DISLIKED_MEDIAFILES_LIST_EMPTY: 'Исключенных треков пока нет',
  OVERLAY_EMPTY_DESC:
    'Выберите любую волну из списка, чтобы оценить её звучание и запустить вещание у себя в заведении.',
  STREAM_HINT_MODAL_BUTTON_OK: 'Понятно',
  BUTTON_STREAM_TITLE: 'Запустить волну',
  MODAL_POINT_STREAM_PLAYING: (title: string) => `Звучит: ${title}`,
  MODAL_POINT_STREAM_ATTACHED: (title: string) => `Привязана, но не звучит: ${title}`,
  MODAL_POINT_STREAM_EMPTY: 'Волна не привязана',
  MODAL_BIND_TITLE: 'Запуск волны',
  MODAL_BIND_DESC: 'Выберите адреса, на которых хотите запустить волну',
  MODAL_BIND_STREAM_NOT_BIND: 'Отсутствует',
  MODAL_BIND_STREAM_BIND: 'Волна',
  MODAL_BIND_BUTTON_SUBMIT_TEXT: 'Запустить волну',
  MODAL_BIND_ADD_NEW_POINT_TEXT: 'Добавить новый адрес',
  MODAL_BIND_BUTTON_CANCEL_TEXT: 'Отменить',
  MODAL_BIND_SEARCH_PLACEHOLDER: 'Поиск...',
  MODAL_BIND_POINTS_LIST_EMPTY: 'Адресов нет',
  MODAL_BIND_POINTS_FILTERED_LIST_EMPTY: 'По данному запросу адресов не найдено',
  MODAL_BIND_SUCCESS_BUTTON_OPEN_WEB_PLAYER_TEXT: 'Открыть веб-плеер',
  MODAL_BIND_SUCCESS_BUTTON_OPEN_POINTS_TEXT: 'Перейти к адресам',
  MODAL_BIND_SUCCESS_BUTTON_SUCCESS_TEXT: 'Хорошо',
  MODAL_BIND_SUCCESS_BUTTON_OPEN_STREAMS_TEXT: 'Выбрать волну',
  MODAL_BIND_SUCCESS_BUTTON_CANCEL_TEXT: 'Вернуться к волнам',
  MODAL_BIND_SUCCESS_TITLE: 'Волна запущена!',
  MODAL_BIND_SUCCESS_NON_ACTIVE_TITLE: 'Волна привязана!',
  MODAL_BIND_SUCCESS_POINT_CREATE_TITLE: 'Устройство привязано!',
  MODAL_BIND_SUCCESS_DESC: 'Треки загружаются. Музыка заиграет в вашем плеере в течение минуты.',
  MODAL_BIND_SUCCESS_NON_ACTIVE_DESC: 'Волна привязана к адресам. Для запуска необходимо активировать подписку',
  MODAL_BIND_SUCCESS_POINT_CREATE_DESC: 'Выберите музыку для своего заведения',
  MODAL_BIND_SUCCESS_MORE_ADDRESS: (count: number) => `Ещё ${count} адрес${DeclensionUtils.pluralEnding(count)}`,
  MODAL_BIND_NO_DEVICE: 'Не привязано',
  MODAL_BIND_WEB_PLAYER: 'Веб-плеер',
  MODAL_BIND_PLAYER_APPLICATION: 'Приложение',
  MODAL_BIND_HIFI_MESSAGE: 'Волна в HiFi-качестве доступна на всех типах плееров, кроме Веб-плеера',
  MODAL_BIND_DEVICE_TYPE: 'Тип устройства',
  EMPTY_LIST_TITLE: 'У вас нет сохранённых волн. Запустите волну в библиотеке, и она автоматически добавится сюда',
  POINT_CREATE_TITLE: 'Укажите адрес, где будет играть волна',
  POINT_CREATE_BUTTON_TEXT: 'Запустить волну по адресу',
  POINT_CREATE_ADDRESS_PLACEHOLDER_RU: 'Москва, ул. Новый Арбат д.13',
  POINT_CREATE_ADDRESS_PLACEHOLDER_KZ: 'Астана, ул. Космонавтов д.10',
  POINT_CREATE_ADDRESS_PLACEHOLDER_BY: 'Минск, ул. Ленина д.10',
  POINT_CREATE_HINT_MODAL_TITLE: 'Почему важно указывать полный и корректный адрес?',
  CUSTOM_CONTENT_TITLE: 'Новогодняя музыка',
  CUSTOM_CONTENT_DESC: 'Добавьте в свои волны новогоднее настроение',
  CUSTOM_CONTENT_ENABLED_DESC: (start: string, end: string) => `Будет играть в выбранных волнах с ${start} по ${end}`,
  CUSTOM_CONTENT_SUBMIT_BUTTON: 'Добавить в волны',
  CUSTOM_CONTENT_SUBMIT_OK: (start: string, end: string) =>
    `Новогодняя музыка будет играть в выбранных волнах с ${start} по ${end}`,
  CUSTOM_CONTENT_SUBMIT_ERROR: 'Не удалось добавить новогоднюю музыку в выбранные волны. Обратитесь к менеджеру',
  CUSTOM_CONTENT_CHANGE_BUTTON: 'Изменить настройки',
  CUSTOM_CONTENT_CHANGE_OK: 'Новогодняя музыка будет играть с',
  CUSTOM_CONTENT_CHANGE_ERROR: 'Не удалось изменить настройки подмешивания новогодней музыки. Обратитесь к менеджеру',
  CUSTOM_CONTENT_REMOVE_BUTTON: 'Удалить из волн',
  CUSTOM_CONTENT_REMOVE_OK: 'Новогодняя музыка удалена из всех ваших волн',
  CUSTOM_CONTENT_REMOVE_ERROR: 'Не удалось удалить новогоднюю музыку из ваших волн. Обратитесь к менеджеру',
  CUSTOM_CONTENT_LISTEN_BUTTON: 'Послушать треки',
  CUSTOM_CONTENT_REQUEST_MODAL_TITLE: 'Настройка периода новогодней музыки',
  CUSTOM_CONTENT_REQUEST_MODAL_CHANGE_TITLE: 'Изменение периода',

  CUSTOM_CONTENT_REQUEST_MODAL_DESC: 'Соотношение обычных треков к новогодним будет ',
  CUSTOM_CONTENT_REQUEST_MODAL_DESC_BOLD: '50/50.',
  CUSTOM_CONTENT_REQUEST_MODAL_SUB_DESC: 'Укажите период, когда новогодняя музыка должна играть на ваших адресах',
  CUSTOM_CONTENT_REQUEST_MODAL_INFO_DEFAULT:
    'На ваших адресах включена HIFI волна — может быть слышна разница в качестве звука с новогодними треками стандартного качества',
  CUSTOM_CONTENT_REQUEST_MODAL_INFO_FIRST_PART: 'В ваши волны ',
  CUSTOM_CONTENT_REQUEST_MODAL_INFO_SECOND_BOLD: 'подмешаны новогодние треки. ',
  CUSTOM_CONTENT_REQUEST_MODAL_INFO_SECOND_PART:
    'Может быть слышна разница в качестве звука новогодних треков в HIFI-волне',
  CUSTOM_CONTENT_REQUEST_MODAL_BUTTON_SUBMIT: 'Далее',
  CUSTOM_CONTENT_REQUEST_MODAL_BUTTON_CHANGE: 'Изменить',
  CUSTOM_CONTENT_REMOVE_MODAL_TITLE: 'Удаление новогодней музыки?',
  CUSTOM_CONTENT_REMOVE_MODAL_DESC: 'Новогодние треки будут удалены из всех ваших волн',
  CUSTOM_CONTENT_REMOVE_MODAL_BUTTON_SUBMIT: 'Удалить',
  CUSTOM_CONTENT_REMOVE_MODAL_BUTTON_CANCEL: 'Отменить',
  CUSTOM_CONTENT_BIND_CARD_MODAL_TITLE: 'Привяжите карту',
  CUSTOM_CONTENT_BIND_CARD_MODAL_DESC: 'Функция доступна только клиентам с привязанной картой',
  CUSTOM_CONTENT_BIND_CARD_MODAL_BUTTON_SUBMIT: 'Привязать карту',
  CUSTOM_CONTENT_BIND_CARD_MODAL_BUTTON_CANCEL: 'Позже',
  CUSTOM_CONTENT_PREVIEW_MODAL_TITLE: 'Новогодняя музыка',
  CUSTOM_CONTENT_PREVIEW_MODAL_BUTTON_APPLY: 'Добавить',
  STREAM_MEDIA_LIST_PICKER_ACTIVE: 'Активные',
  STREAM_MEDIA_LIST_PICKER_DISLIKED: 'Исключенные',
  STREAM_MENU_ITEM_REMOVE_STREAM: 'Удалить',
  MODAL_STREAM_REMOVE_TITLE: 'Удалить волну?',
  MODAL_STREAM_REMOVE_BUTTON_CONFIRM: 'Удалить',
  MODAL_STREAM_REMOVE_BUTTON_CANCEL: 'Отменить',
  MODAL_STREAM_REMOVE_DESCRIPTION: 'У вас не будет возможности восстановить волну',
  MODAL_STREAM_REMOVE_SUCCESS_MESSAGE: 'Волна удалена',
  SET_STREAM_ERROR_TITLE: 'Не удалось запустить волну',
  SET_STREAM_ERROR_ACTION: 'Написать менеджеру',
  SET_STREAM_ERROR_CLOSE: 'Закрыть',
  MODAL_STREAM_ADD_POINT_PROBLEM_TITLE: 'Запуск вещания',
  MODAL_STREAM_ADD_POINT_PROBLEM_DESCRIPTION: 'Для запуска вещания обратитесь к менеджеру',
  MODAL_STREAM_ADD_POINT_PROBLEM_TITLE_FALLBACK: 'Добавление адреса',
  MODAL_STREAM_ADD_POINT_PROBLEM_DESCRIPTION_FALLBACK: 'Для добавления адреса обратитесь к менеджеру',
  MODAL_STREAM_ADD_POINT_WAIT_PAYMENT_DESCRIPTION: 'Создание адреса невозможно, так как оплата в статусе ожидания',
  MODAL_STREAM_ADD_POINT_PROBLEM_BUTTON_OK: 'Связаться с менеджером',
  SHOW_MORE: 'Показать больше',
  COLLAPSE: 'Свернуть',
  MODAL_INFO: {
    BOLD_TEXT: 'Обращаем Ваше внимание,',
    MAIN_TEXT: `что вы на тарифе “Популярный”.
    Для того, чтобы легально воспроизводить музыку у вас должны быть подписаны договоры с РАО и ВОИС.`,
    LINK_TEXT: 'Подробнее',
  },
  BUTTON_MEDIAFILES_MORE: 'Показать ещё',
  DRAWER_HOURS: 'ч',
  DRAWER_MINUTES: 'мин',
  CERTIFICAT_MODAL_SELECT_ALL_POINTS: 'Все оплаченные адреса',

  MODAL_STOP_BINDING_FLOW_WARNING_TITLE: 'Прервать привязку устройства?',
  MODAL_STOP_BINDING_FLOW_WARNING_UPDATED_DESCRIPTION: (address: string) =>
    `Не будет установлен плеер на адресе ${address}. Привязать устройство можно будет в разделе Адреса.`,
  MODAL_STOP_BINDING_FLOW_WARNING_BUTTON_CONFIRM: 'Да, прервать',
  MODAL_STOP_BINDING_FLOW_WARNING_BUTTON_CANCEL: 'Отменить',
  NOT_FOUND_STREAMS: 'Упс... волны не найдены',
  STREAMS_FOUND_BY_SEARCH: 'Найдено по волнам',
  COLLECTIONS_FOUND_BY_SEARCH: 'Найдено по коллекциям',
  FILTERS: 'Фильтры',
  SHOW_STREAMS: 'Показать волны',
  CLEAR: 'Очистить',
  MORE: 'Ещё',
  HIDE: 'Скрыть',
  RECOMMENDED_TAB_TITLE: 'Не нашли подходящую волну?',
  RECOMMENDED_TAB_DESCRIPTION: 'Посмотрите библиотеку, чтобы подобрать музыку',
  RECOMMENDED_TAB_BUTTON: 'Перейти в библиотеку',
  STREAMS_GROUP_ALT: 'Группа стримов',
  CUSTOM_CONTENT_MODAL_STREAMS_SELECT_TITLE: 'Выбор волн',
  CUSTOM_CONTENT_MODAL_STREAMS_SELECT_DESCRIPTION: (
    <>
      Новогодние треки будут добавлены <strong>в выбранные волны</strong>. Настройки можно изменить в любой момент в
      баннере «Новогодняя музыка»
    </>
  ),
  CUSTOM_CONTENT_MODAL_STREAMS_SELECT_ACTION_BUTTON: 'Сохранить',
  CUSTOM_CONTENT_MODAL_STREAMS_SELECT_SECTION_FIRST: 'Волны на адресах',
  CUSTOM_CONTENT_MODAL_STREAMS_SELECT_SECTION_SECOND: 'Волны без адресов',
  CUSTOM_CONTENT_MODAL_STREAMS_SELECT_ACTION_SELECT: 'Выбрать все',
  CUSTOM_CONTENT_MODAL_STREAMS_SELECT_ACTION_DESELECT: 'Сбросить все',
  CUSTOM_CONTENT_STREAM_PREVIEW_TEXT: 'Добавлена новогодняя музыка',
  CUSTOM_CONTENT_MODAL_STREAMS_NOTIFICATION_SUCCESS: 'Новогодняя музыка будет играть в этой волне',
  CUSTOM_CONTENT_MODAL_STREAMS_NOTIFICATION_ERROR:
    'Не удалось добавить новогоднюю музыку в эту волну. Обратитесь к менеджеру',
};
