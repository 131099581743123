import React, { useEffect } from 'react';

import { useAccountContext } from 'application/providers/AccountProvider';
import useStreamTemplateGroups from 'domain/media/streams/useStreamTemplateGroups';
import useStreamsSaved from 'domain/media/streams/useStreamsSaved';
import { PaginationInfoType, StreamShortTypeFragment } from 'domain/api/graphql/generated';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';

export type StreamsGridType = {
  saved?: StreamShortTypeFragment[];
  isSavedLoading: boolean;
  savedPaginationInfo?: PaginationInfoType;
  fetchMoreSaved: () => void;
  requestSaved: () => void;
  genres?: StreamShortTypeFragment[];
  isGenresLoading: boolean;
};

const StreamsGrid = React.createContext({} as StreamsGridType);

export const useStreamsGridContext = () => React.useContext(StreamsGrid);

const StreamsGridProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const notification = useNotificationContext();
  const { currentCompany, companyIds, streamLibraryId, isReadyToUseLK } = useAccountContext();

  const savedStreams = useStreamsSaved();
  const streamTemplateGroups = useStreamTemplateGroups();

  const initialLoad = async () => {
    try {
      if (currentCompany && streamLibraryId) {
        await Promise.all([savedStreams.request({ companyIds }), streamTemplateGroups.request()]);
      }
    } catch (e) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const fetchMoreSaved = () => {
    savedStreams.fetchMore(companyIds);
  };

  const requestSaved = () => {
    savedStreams.request({ companyIds });
  };

  useEffect(() => {
    initialLoad();
  }, [currentCompany?.id, streamLibraryId, isReadyToUseLK]);

  return (
    <StreamsGrid.Provider
      value={{
        saved: savedStreams.streams,
        isSavedLoading: savedStreams.loading,
        savedPaginationInfo: savedStreams.paginationInfo,
        fetchMoreSaved,
        requestSaved,
        genres: streamTemplateGroups.streams?.[0].streams,
        isGenresLoading: streamTemplateGroups.loading,
      }}>
      {children}
    </StreamsGrid.Provider>
  );
};

export default StreamsGridProvider;
