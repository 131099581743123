import {
  useGetCompanyMediafilesLazyQuery,
  SmartlkCompanyMediafilesQueryFilter,
  SmartlkCompanyMediafilesPagination,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { CompanyMediafilesResultType, UseCompanyMediafilesType } from './types';

const useCompanyMediafiles = (): UseCompanyMediafilesType => {
  const [query, { data, called, loading, networkStatus, fetchMore, refetch }] = useGetCompanyMediafilesLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (
    filters: SmartlkCompanyMediafilesQueryFilter,
    pagination: SmartlkCompanyMediafilesPagination
  ) => {
    const result = await query({
      variables: {
        filters,
        pagination,
      },
    });

    return result;
  };

  const loadMore = async (
    filters: SmartlkCompanyMediafilesQueryFilter,
    pagination: SmartlkCompanyMediafilesPagination
  ) => {
    await fetchMore({
      variables: {
        filters,
        pagination,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const data = {
          ...prev,
          smartlkCompanyMediafilesQuery: {
            ...prev?.smartlkCompanyMediafilesQuery,
            ...fetchMoreResult?.smartlkCompanyMediafilesQuery,
            result: [
              ...(prev?.smartlkCompanyMediafilesQuery?.result || []),
              ...(fetchMoreResult?.smartlkCompanyMediafilesQuery.result || []),
            ],
          },
        };

        return data;
      },
    });
  };

  const result: CompanyMediafilesResultType = {
    loading,
    called,
    networkStatus,
    data: {
      mediafiles: data?.smartlkCompanyMediafilesQuery.result,
      paginationInfo: data?.smartlkCompanyMediafilesQuery.paginationInfo,
    },

    loadMore,
    refetch,
  };

  return [request, result];
};

export default useCompanyMediafiles;
