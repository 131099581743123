import React from 'react';
import classNames from 'classnames';

import FileUploader from 'ui/FileUploader';

import { ImageFile, ImageFileErrorCode, ImageUploaderProps } from './types';
import {
  ACCEPT_IMAGE_FILES,
  DEFAULT_IMAGE_HEIGHT,
  DEFAULT_IMAGE_MAX_HEIGHT,
  DEFAULT_IMAGE_MAX_WIDTH,
  DEFAULT_IMAGE_MIN_HEIGHT,
  DEFAULT_IMAGE_MIN_WIDTH,
  DEFAULT_IMAGE_WIDTH,
} from './consts';
import { DEFAULT_FILE_MAX_SIZE, DEFAULT_FILE_MIN_SIZE, DEFAULT_FILE_SIZE } from '../consts';
import RejectedFileList from '../RejectedFileList';
import PreviewImageList from './PreviewImageList';
import { TEXTS } from '../texts';
import useImageUploaderController from './useImageUploaderController';

import styles from './index.module.scss';

const ImageUploader = React.forwardRef(
  (
    {
      elementId,
      className,
      previewImagesContainerClassName,
      previewImagesContainerWithSelectedImagesClassName,
      previewImageClassName,
      previewToolboxClassName,
      previewDescription,
      previewDescriptionPlacement,
      value,
      accept = ACCEPT_IMAGE_FILES,
      multiple = true,
      isLoading,
      hasError,
      uploadButtonContent,
      uploadButtonType,
      uploadButtonAdornmentLeft,
      uploadButtonAdornmentRight,
      label,
      description,
      withPreviewImages,
      withPreviewImagesFilename,
      withEditButton,
      withRemoveButton,
      width,
      minWidth,
      maxWidth,
      height,
      minHeight,
      maxHeight,
      widthErrorText = TEXTS.DEFAULT_IMAGE_FILE_WIDTH_ERROR_TEXT(width || DEFAULT_IMAGE_WIDTH),
      minWidthErrorText = TEXTS.DEFAULT_IMAGE_FILE_MIN_WIDTH_ERROR_TEXT(minWidth || DEFAULT_IMAGE_MIN_WIDTH),
      maxWidthErrorText = TEXTS.DEFAULT_IMAGE_FILE_MAX_WIDTH_ERROR_TEXT(maxWidth || DEFAULT_IMAGE_MAX_WIDTH),
      heightErrorText = TEXTS.DEFAULT_IMAGE_FILE_HEIGHT_ERROR_TEXT(height || DEFAULT_IMAGE_HEIGHT),
      minHeightErrorText = TEXTS.DEFAULT_IMAGE_FILE_MIN_HEIGHT_ERROR_TEXT(minHeight || DEFAULT_IMAGE_MIN_HEIGHT),
      maxHeightErrorText = TEXTS.DEFAULT_IMAGE_FILE_MAX_HEIGHT_ERROR_TEXT(maxHeight || DEFAULT_IMAGE_MAX_HEIGHT),
      size,
      minSize,
      maxSize,
      sizeErrorText = TEXTS.DEFAULT_FILE_SIZE_ERROR_TEXT(size || DEFAULT_FILE_SIZE),
      minSizeErrorText = TEXTS.DEFAULT_FILE_MIN_SIZE_ERROR_TEXT(minSize || DEFAULT_FILE_MIN_SIZE),
      maxSizeErrorText = TEXTS.DEFAULT_FILE_MAX_SIZE_ERROR_TEXT(maxSize || DEFAULT_FILE_MAX_SIZE),
      getUploadedImageFileDescription = TEXTS.DEFAULT_UPLOADED_IMAGE_FILE_DESCRIPTION,
      onChange,
    }: ImageUploaderProps,
    ref: React.Ref<HTMLElement> | undefined
  ) => {
    const {
      imageFileInputRef,
      previewImages,
      isUploadButtonVisible,
      imageFileRejections,
      handleImageFilesChange,
      handleImageFileUploaderOpen,
      handleImageFileUploaderCancel,
      handleFileRemove,
      handleRejectedFileRemove,
    } = useImageUploaderController({
      value,
      width,
      minWidth,
      maxWidth,
      height,
      minHeight,
      maxHeight,
      widthErrorText,
      minWidthErrorText,
      maxWidthErrorText,
      heightErrorText,
      minHeightErrorText,
      maxHeightErrorText,
      size,
      minSize,
      maxSize,
      sizeErrorText,
      minSizeErrorText,
      maxSizeErrorText,
      onChange,
    });

    return (
      <div
        ref={ref as React.Ref<HTMLDivElement>}
        className={classNames(styles.ImageUploader, className)}>
        <FileUploader
          inputRef={imageFileInputRef}
          elementId={elementId}
          label={label}
          description={description}
          onChange={handleImageFilesChange}
          onCancel={handleImageFileUploaderCancel}
          uploadButtonContent={uploadButtonContent}
          uploadButtonType={uploadButtonType}
          uploadButtonAdornmentLeft={uploadButtonAdornmentLeft}
          uploadButtonAdornmentRight={uploadButtonAdornmentRight}
          isUploadButtonVisible={isUploadButtonVisible}
          isLoading={isLoading}
          accept={accept}
          multiple={multiple}
        />

        <RejectedFileList<ImageFile, ImageFileErrorCode>
          fileRejections={imageFileRejections}
          getUploadedFileDescription={getUploadedImageFileDescription}
          handleRejectedFileRemove={handleRejectedFileRemove}
          elementId={elementId}
        />

        {withPreviewImages && previewImages.size !== 0 && (
          <PreviewImageList
            elementId={`${elementId}_preview_images`}
            previewImages={previewImages}
            previewImagesContainerClassName={previewImagesContainerClassName}
            previewImagesContainerWithSelectedImagesClassName={previewImagesContainerWithSelectedImagesClassName}
            previewImageClassName={previewImageClassName}
            previewToolboxClassName={previewToolboxClassName}
            previewDescription={previewDescription}
            previewDescriptionPlacement={previewDescriptionPlacement}
            withImageFilename={withPreviewImagesFilename}
            withEditButton={withEditButton}
            withRemoveButton={withRemoveButton}
            isUploadButtonVisible={isUploadButtonVisible}
            handleImageFileUploaderOpen={handleImageFileUploaderOpen}
            handleFileRemove={handleFileRemove}
          />
        )}

        {!isUploadButtonVisible && hasError && <div className={styles.ImageUploaderDescription}>{description}</div>}
      </div>
    );
  }
);

export default ImageUploader;
