import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import Input, { InputProps, InputSize, InputType } from 'ui/Input';
import IconButton from 'ui/IconButton';
import IconSearch from 'ui/icons/Search';
import IconXCircle from 'ui/icons/XCircle';
import Spinner from 'ui/Spinner';
import { Colors } from 'ui/types';

import styles from './index.module.scss';

export type InputSearchProps = InputProps & {
  isSearchLoading?: boolean;
  hasSearchIcon?: boolean;
  hasInitialFocus?: boolean;
};

export { InputSize, InputType };

const InputSearch = ({
  elementId,
  value: propValue,
  error,
  isSearchLoading,
  size = InputSize.MEDIUM,
  type = InputType.TEXT,
  label,
  labelIcon,
  placeholder,
  required,
  maxLength,
  isDisabled,
  isLoading,
  isFullMode,
  inputClassName,
  containerClassName,
  autoComplete,
  onChange,
  onEnterPressed,
  hasSearchIcon = true,
  hasInitialFocus = false,
}: InputSearchProps) => {
  const [value, setValue] = useState(propValue);
  const [inFocus, setInFocus] = useState(false);
  const [hovered, setHovered] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasInitialFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasInitialFocus]);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const adornmentLeft = useMemo(
    () =>
      hasSearchIcon && (
        <IconSearch
          id={`${elementId}_search_icon`}
          data-testid={`${elementId}_search_icon`}
          className={classNames(styles.InputSearchIconSearch, {
            [styles.InputSearchIconSearchFocused]: inFocus && !isSearchLoading && !isDisabled,
            [styles.InputSearchIconSearchHover]: hovered && !isSearchLoading && !isDisabled,
            [styles.InputSearchIconSearchSmall]: size === InputSize.SMALL,
            [styles.InputSearchIconSearchMedium]: size === InputSize.MEDIUM,
          })}
        />
      ),
    [hasSearchIcon, elementId, inFocus, hovered, isSearchLoading, isDisabled, size]
  );

  const handleChange = useCallback(
    (value: string, event: React.SyntheticEvent<Element, Event>) => {
      setValue(value);
      onChange?.(value, event);
    },
    [setValue, onChange]
  );

  const handleClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setValue('');
      onChange?.('', e);
    },
    [onChange]
  );

  const adornmentRight = useMemo(
    () =>
      isSearchLoading ? (
        <Spinner
          elementId={`${elementId}_search_spinner`}
          color={Colors.COLOR_ACCENT_500}
        />
      ) : (
        !!value?.length && (
          <IconButton
            elementId={`${elementId}_clear_button`}
            className={classNames({
              [styles.InputSearchButtonClearSmall]: size === InputSize.SMALL,
              [styles.InputSearchButtonClearMedium]: size === InputSize.MEDIUM,
            })}
            onClick={handleClear}>
            <IconXCircle />
          </IconButton>
        )
      ),
    [isSearchLoading, value, size, elementId, handleClear]
  );

  return (
    <Input
      elementId={elementId}
      data-testid={elementId}
      value={value}
      error={error}
      size={size}
      type={type}
      label={label}
      labelIcon={labelIcon}
      placeholder={placeholder}
      required={required}
      maxLength={maxLength}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isFullMode={isFullMode}
      autoComplete={autoComplete}
      containerClassName={containerClassName}
      inputClassName={classNames(inputClassName, {
        [styles.InputSearchSmall]: size === InputSize.SMALL && hasSearchIcon,
        [styles.InputSearchMedium]: size === InputSize.MEDIUM && hasSearchIcon,
      })}
      inputRef={inputRef}
      adornmentLeft={adornmentLeft}
      adornmentRight={adornmentRight}
      onChange={handleChange}
      onEnterPressed={onEnterPressed}
      onFocus={() => setInFocus(true)}
      onBlur={() => setInFocus(false)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    />
  );
};

export default InputSearch;
