import React from 'react';

import { TPointData } from 'application/providers/WebPlayerProvider/types';

import { TPointMetadata } from '@player-core/use-player-frame';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import ErrorMessage from '../WebPlayer/ErrorMessage';
import { TEXTS } from './text';

interface IWebPlayerErrorModalProps {
  onClose: () => void;
  pointMetadata: TPointMetadata;
  pointData: TPointData | null;
  onUnAuthorizedClick: (modalContent: string) => void;
  setRedirect: (path: string) => void;
  setErrorModalClose: () => void;
}

const WebPlayerErrorModal: React.FC<IWebPlayerErrorModalProps> = ({
  onClose,
  pointMetadata,
  pointData,
  onUnAuthorizedClick,
  setRedirect,
  setErrorModalClose,
}) => (
  <ModalScreen
    onClose={onClose}
    titlePlacement="center"
    title={TEXTS.POINT_DISABLED}>
    <ErrorMessage
      showTitle={false}
      pointMetadata={pointMetadata}
      pointData={pointData}
      onUnAuthorizedClick={onUnAuthorizedClick}
      setRedirect={setRedirect}
      setErrorModalClose={setErrorModalClose}
    />
  </ModalScreen>
);

export default WebPlayerErrorModal;
