export const DEFAULT_BUSINESS_PROFILE_PLAYLIST_FORM_DATA = {
  title: '',
  description: '',
  poster: null,
};

export const BUSINESS_PROFILE_PLAYLIST_TITLE_MAX_LENGTH = 50;

export const BUSINESS_PROFILE_PLAYLIST_DESCRIPTION_MAX_LENGTH = 100;

export const POSTER_WIDTH = 530;
export const POSTER_HEIGHT = 530;
export const POSTER_MAX_SIZE = 10 * 1024 * 1024;
