import React from 'react';

import { Accept, FileError, FileErrorCode, FileUploaderSizeValidationType } from './types';

class FileUploaderUtils {
  static KB = 1024;

  static MB = 1024 * 1024;

  static isMIMEType = (v: string) =>
    v === 'audio/*' || v === 'video/*' || v === 'image/*' || v === 'text/*' || /\w+\/[-+.\w]+/g.test(v);

  static isExt = (v: string) => /^.*\.[\w]+$/.test(v);

  static acceptPropAsAcceptAttr(accept?: Accept) {
    if (accept !== undefined && accept !== null) {
      return Object.entries(accept)
        .reduce((a, [mimeType, ext]) => [...a, mimeType, ...ext], [] as string[])
        .filter((v) => FileUploaderUtils.isMIMEType(v) || FileUploaderUtils.isExt(v))
        .join(',');
    }

    return undefined;
  }

  static getFilesFromChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): File[] => {
    const files: File[] = [];

    const eventTarget = event.target as HTMLInputElement;

    if (eventTarget && eventTarget.files) {
      for (let index = 0; index < eventTarget.files.length; index++) {
        files.push(eventTarget.files[index]);
      }
    }

    return files;
  };

  static getFileSizeWithUnits = (sizeInBytes: number) => {
    if (sizeInBytes <= FileUploaderUtils.KB) {
      return `${sizeInBytes}B`;
    }

    if (sizeInBytes > FileUploaderUtils.KB && sizeInBytes <= FileUploaderUtils.MB) {
      const KB = (sizeInBytes / FileUploaderUtils.KB).toFixed(2);
      return `${KB}KB`;
    }

    const MB = (sizeInBytes / FileUploaderUtils.MB).toFixed(2);

    return `${MB}MB`;
  };

  static getFileSizeRejection = <T extends File, K extends FileError<K>>(
    imageFile: T,
    errors: FileError<K>[],
    { size, minSize, maxSize, sizeErrorText, minSizeErrorText, maxSizeErrorText }: FileUploaderSizeValidationType
  ) => {
    if (size) {
      if (imageFile.size !== size) {
        errors.push({
          code: FileErrorCode.FileMustBe,
          message: sizeErrorText,
        });
      }
    } else {
      if (minSize && imageFile.size < minSize) {
        errors.push({
          code: FileErrorCode.FileTooSmall,
          message: minSizeErrorText,
        });
      }

      if (maxSize && imageFile.size > maxSize) {
        errors.push({
          code: FileErrorCode.FileTooLarge,
          message: maxSizeErrorText,
        });
      }
    }

    return errors;
  };
}

export default FileUploaderUtils;
