import React from 'react';

import IconWindowsStore from '@zvuk-b2b/react-uikit/ui/icons/WindowsStore';
import { useDesktopDownloadLinkQuery } from 'domain/api/graphql/generated';
import { TEXTS } from 'application/pages/PointsPage/renderer/texts';
import LinkContainer from 'application/components/LinkContainer';
import IconPlayMarket from '@zvuk-b2b/react-uikit/ui/icons/PlayMarket';
import IconAppStore from 'ui/icons/AppleAppStore';
import styles from './index.module.scss';

const ApplicationLinks = () => {
  const { data } = useDesktopDownloadLinkQuery();

  return (
    <div className={styles.AppLinksContainer}>
      <div className={styles.AppLinksContainerLinkGroup}>
        <h5 className={styles.AppLinksContainerLinkGroupTitle}>{TEXTS.PLAYER_APP_LINKS_MOBILE_PLAYERS}</h5>
        <div className={styles.AppLinksContainerLinkGroupLinks}>
          <LinkContainer
            icon={({ className }) => <IconAppStore className={className} />}
            title={TEXTS.PLAYER_APP_LINKS_MOBILE_DOWNLOAD}
            subTitle={TEXTS.PLAYER_APP_LINKS_APP_STORE}
            link={TEXTS.PLAYER_APP_LINKS_APP_STORE_DOWNLOAD}
            target="_blank"
          />
          <LinkContainer
            icon={({ className }) => <IconPlayMarket className={className} />}
            title={TEXTS.PLAYER_APP_LINKS_MOBILE_DOWNLOAD}
            subTitle={TEXTS.PLAYER_APP_LINKS_GOOGLE_PLAY}
            link={TEXTS.PLAYER_APP_LINKS_GOOGLE_PLAY_DOWNLOAD}
            target="_blank"
          />
        </div>
      </div>
      <div className={styles.AppLinksContainerLinkGroup}>
        <h5 className={styles.AppLinksContainerLinkGroupTitle}>{TEXTS.PLAYER_APP_LINKS_DESKTOP_PLAYERS}</h5>
        <LinkContainer
          icon={({ className }) => <IconWindowsStore className={className} />}
          link={data?.link}
          title={TEXTS.PLAYER_APP_LINKS_DESKTOP_DOWNLOAD}
          subTitle={TEXTS.PLAYER_APP_LINKS_WINDOWS}
        />
      </div>
    </div>
  );
};

export default ApplicationLinks;
