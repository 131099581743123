import { useState } from 'react';

import {
  usePointListQuery,
  PointQuerySort,
  PointQueryFilter,
  PointQueryPagination,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { PointListQueryState, UsePointListResultType, UsePointsType } from './types';

const usePointList = (): UsePointsType => {
  const [queryState, setQueryState] = useState<PointListQueryState>({
    query: '',
    companyIds: [],
    companyTagIds: [],
    playingStatuses: [],
    page: 0,
    pageSize: 0,
    sort: PointQuerySort.IdDesc,
    skip: true,
  });

  const { data, called, loading, networkStatus, fetchMore, refetch } = usePointListQuery({
    variables: {
      filters: {
        query: queryState.query,
        companyIds: queryState.companyIds,
        companyTagIds: queryState.companyTagIds as string[],
        playingStatuses: queryState.playingStatuses,
      },
      pagination: { page: queryState.page, pageSize: queryState.pageSize },
      sort: queryState.sort,
    },
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
    skip: queryState.skip,
  });

  const request = (filters: PointQueryFilter, pagination: PointQueryPagination, sort: PointQuerySort) => {
    setQueryState({
      query: filters.query,
      companyIds: filters.companyIds || [],
      companyTagIds: filters.companyTagIds || [],
      playingStatuses: filters.playingStatuses || [],
      page: pagination.page,
      pageSize: pagination.pageSize,
      sort,
      skip: false,
    });
  };

  async function loadMore(filters: PointQueryFilter, pagination: PointQueryPagination, sort: PointQuerySort) {
    const result = await fetchMore({
      variables: {
        filters,
        pagination,
        sort,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const data = {
          ...prev,
          pointsPaginationQuery: {
            ...prev?.pointsPaginationQuery,
            ...fetchMoreResult?.pointsPaginationQuery,
            result: [
              ...(prev?.pointsPaginationQuery?.result || []),
              ...(fetchMoreResult?.pointsPaginationQuery.result || []),
            ],
          },
        };

        return data;
      },
    });

    return result;
  }

  const result: UsePointListResultType = {
    loading,
    called,
    networkStatus,
    data: {
      points: data?.pointsPaginationQuery.result,
      statusesInfo: data?.pointsPaginationQuery.statusesInfo,
      paginationInfo: data?.pointsPaginationQuery.paginationInfo,
    },
    loadMore,
    refetch,
  };

  return [request, result];
};

export default usePointList;
