import React from 'react';

export const TEXTS = {
  DOC_TITLE: 'Адреса – Звук Бизнес',
  PAGE_TITLE: 'Адреса',
  POINT_MENU_ITEM_CHOOSE_WAVE: 'Выбрать волну',
  POINT_MENU_ITEM_CHANGE_WAVE: 'Сменить волну',
  POINT_MENU_ITEM_DELETE_POINT: 'Удалить адрес',
  TEST_PERIOD_ENDED: 'Тестовый период окончен. Адреса вещания заблокированы. ',
  LIST_POINTS_ADD: 'Добавить адрес',
  LIST_POINTS_SEARCH_PLACEHOLDER: 'Поиск...',
  OVERLAY_EMPTY_DESC: `Выберите любой адрес из списка слева, чтобы просмотреть подробную информацию о нем
      и выполнить различные действия.`,
  TAB_SETTINGS_TITLE: 'Настройки',
  TAB_HISTORY_TITLE: 'История вещания',
  TAB_ADS_TITLE: 'Реклама',
  POINT_COMMENT_FALLBACK: 'Вы можете изменить название для этой точки',
  POINT_STREAM_FALLBACK: 'Волна не привязана',
  POINT_CHANGE_ADDRESS: 'Для изменения адреса вещания обратитесь к менеджеру',
  POINT_CHANGE_DEVICE: 'Обратитесь к менеджеру, чтобы сменить устройство',
  POINT_REMOVE: 'Удалить адрес вещания',
  POINT_REMOVE_CONFIRM_MODAL_TITLE: 'Удалить адрес вещания?',
  POINT_REMOVE_CONFIRM_MODAL_DESCRIPTION: 'Все данные об адресе будут стёрты. Восстановить его будет невозможно',
  POINT_REMOVE_CONFIRM_MODAL_BUTTON_OK: 'Удалить',
  POINT_REMOVE_CONFIRM_MODAL_BUTTON_CANCEL: 'Отменить',
  POINT_REMOVE_CONFIRM_MODAL_SUCCESS_TEXT: 'Адрес удалён',
  POINT_REMOVE_BUTTON_HINT: 'Удалить адрес',
  POINT_CHANGE_NAME_ACTION: 'Изменить название',
  DEVICE_INFO_TITLE: 'Устройство',
  PLAYER_EDIT_BUTTON: 'Изменить',
  PLAYER_EDIT_BUTTON_HINT: 'Смена устройства для данного типа невозможна, так как вещание музыки будет остановлено',
  PLAYER_CHANGE_DEVICE_TYPE_APPLICATION: 'Приложение',
  PLAYER_CHANGE_DEVICE_TYPE_WEB: 'Веб-плеер',
  PLAYER_CHANGE_DEVICE_MODAL_TITLE: 'Изменение плеера',
  PLAYER_CHANGE_DEVICE_MODAL_BUTTON_OK: 'Изменить',
  PLAYER_CHANGE_DEVICE_MODAL_TYPE_SELECTOR: 'Тип плеера',
  PLAYER_CHANGE_DEVICE_MODAL_BINDING_CODE_INPUT: 'Код привязки',
  PLAYER_CHANGE_DEVICE_MODAL_BINDING_CODE_INPUT_PLACEHOLDER: '4 символа',
  PLAYER_CHANGE_DEVICE_MODAL_BINDING_CODE_HELP: 'Код указан у вас в приложении',
  PLAYER_CHANGE_DEVICE_MODAL_APP_LINKS_BUTTON: 'У меня нет приложения',
  PLAYER_CHANGE_DEVICE_MODAL_NO_DEVICE_ERROR: 'Плеер не найден. Возможно, код указан неверно',
  PLAYER_CHANGE_DEVICE_MODAL_ALREADY_BOUND_ERROR: 'Этот плеер уже привязан к другому адресу',
  PLAYER_CHANGE_DEVICE_MODAL_UNKNOWN_ERROR: 'Ошибка. Обратитесь в поддержку',
  PLAYER_CHANGE_DEVICE_BACKEND_NO_DEVICE_ERROR: 'does not exist',
  PLAYER_CHANGE_DEVICE_BACKEND_ALREADY_BOUND_ERROR: 'already bounded to point',
  PLAYER_CHANGE_DEVICE_CONFIRM_TITLE: 'Изменить плеер?',
  PLAYER_CHANGE_DEVICE_CONFIRM_OK: 'Изменить',
  PLAYER_CHANGE_DEVICE_CONFIRM_DECLINE: 'Отменить',
  PLAYER_CHANGE_DEVICE_CONFIRM_DESCRIPTION: 'Текущий плеер будет отвязан от адреса. Вещание на нём прекратится',
  PLAYER_CHANGE_DEVICE_SUCCESS_MOBILE: 'Чтобы запустить музыку, нажмите кнопку play в мобильном приложении',
  PLAYER_CHANGE_DEVICE_SUCCESS_WEBPLAYER: 'Чтобы запустить музыку, откройте веб-плеер',
  PLAYER_CHANGE_DEVICE_SUCCESS_DESKTOP:
    'Волна уже загружается на привязанное устройство. После завершения скачивания, музыка начнет играть автоматически',
  PLAYER_CHANGE_DEVICE_SUCCESS_TITLE: 'Плеер изменен',
  PLAYER_CHANGE_DEVICE_SUCCESS_OK_BUTTON: 'Отлично',
  PLAYER_APP_LINKS_MOBILE_PLAYERS: 'Мобильное приложение',
  PLAYER_APP_LINKS_DESKTOP_PLAYERS: 'Desktop-приложение',
  PLAYER_APP_LINKS_ANDROID_BOX_PLAYERS: 'Android-приставка',
  PLAYER_APP_LINKS_DESKTOP_BOX_PLAYERS: 'RPI',
  PLAYER_APP_LINKS_ANDROID_BOX_PLAYERS_ONBOARDING: 'Плеер «Звук Бизнес»',
  PLAYER_APP_LINKS_ALICE_PLAYERS: 'Яндекс.Алиса',
  PLAYER_APP_LINKS_SBERBOOM_PLAYERS: 'SberBoom',
  PLAYER_APP_LINKS_SBERBOOM_PLAYERS_ONBOARDING: 'Умная колонка СберБум',
  PLAYER_APP_LINKS_MOBILE_DOWNLOAD: 'Скачать в',
  PLAYER_APP_LINKS_DESKTOP_DOWNLOAD: 'Скачать для',
  PLAYER_APP_LINKS_APP_STORE: 'App Store',
  PLAYER_APP_LINKS_APP_STORE_DOWNLOAD:
    'https://apps.apple.com/ru/app/%D0%B7%D0%B2%D1%83%D0%BA-%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81-%D0%B0%D1%83%D0%B4%D0%B8%D0%BE%D0%BC%D0%B0%D1%80%D0%BA%D0%B5%D1%82%D0%B8%D0%BD%D0%B3/id1673420335',
  PLAYER_APP_LINKS_GOOGLE_PLAY: 'Google Play',
  PLAYER_APP_LINKS_GOOGLE_PLAY_DOWNLOAD: 'https://play.google.com/store/apps/details?id=com.zvukb2b.player',
  PLAYER_APP_LINKS_WINDOWS: 'Windows',
  PLAYER_APP_LINKS_BACK: 'Назад',
  PLAYER_INFO_CHANGE: 'Сменить устройство',
  PLAYER_INFO_AD_INSTANT_LAUNCH: 'Запустить моментальный ролик',
  SLEEP_SETTINGS_TITLE: 'Рабочие часы',
  SLEEP_SETTINGS_TIME_TITLE: 'Время:',
  SLEEP_SETTINGS_EDIT_BUTTON: 'Изменить',
  SLEEP_SETTINGS_AROUND_THE_CLOCK: 'Круглосуточное вещание',
  SLEEP_SETTINGS_DIFFERENT_TIME: 'Настроить по дням недели',
  SLEEP_SETTINGS_WEEK: 'Понедельник — Воскресенье',
  SLEEP_SETTINGS_EDIT_MODAL_TITLE: 'Настройки вещания',
  SLEEP_SETTINGS_EDIT_MODAL_START_LABEL: 'Начало',
  SLEEP_SETTINGS_EDIT_MODAL_END_LABEL: 'Окончание',
  SLEEP_SETTINGS_BUTTON_SAVE: 'Сохранить',
  SLEEP_SETTINGS_SAVE_SUCCESS_TEXT: 'Время вещания изменено',
  SLEEP_SETTINGS_SAVE_ERROR_TEXT: 'Ошибка, проверьте корректность введенных данных',
  SLEEP_SETTINGS_DAYS_MONDAY: 'Понедельник',
  SLEEP_SETTINGS_DAYS_TUESDAY: 'Вторник',
  SLEEP_SETTINGS_DAYS_WEDNESDAY: 'Среда',
  SLEEP_SETTINGS_DAYS_THURSDAY: 'Четверг',
  SLEEP_SETTINGS_DAYS_FRIDAY: 'Пятница',
  SLEEP_SETTINGS_DAYS_SATURDAY: 'Суббота',
  SLEEP_SETTINGS_DAYS_SUNDAY: 'Воскресенье',
  SLEEP_SETTINGS_DAYS: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
  SLEEP_SETTINGS_IOS_INFO:
    'iOS-плеер поддерживает только круглосуточное вещание. Если вы хотите настроить вещание по дням недели — измените плеер',
  TIMEZONE_TITLE: 'Часовой пояс адреса',
  TIMEZONE_SELECT_PLACEHOLDER: 'Выберите часовой пояс',
  TIMEZONE_SAVE_SUCCESS_TEXT: 'Часовой пояс изменён',
  VOLUME_SETTINGS_TITLE: 'Громкость',
  VOLUME_GENERAL_TITLE: 'Общая громкость устройства',
  VOLUME_AMPLIFY_MEDIAFILE_TITLE: 'Волны',
  VOLUME_AMPLIFY_PROMO_TITLE: 'Рекламные ролики',
  VOLUME_AMPLIFY_INSTANT_TITLE: 'Моментальные ролики',
  VOLUME_SETTINGS_TRACK: 'Музыка',
  VOLUME_SETTINGS_PROMO: 'Реклама',
  VOLUME_SETTINGS_SAVE_TEXT: 'Громкость изменится через 1 минуту, если плеер онлайн',
  VOLUME_SETTINGS_SAVE_ERROR_TEXT: 'Ошибка, попробуйте еще раз или обратитесь к менеджеру',
  VOLUME_SETTINGS_UNAVAILABLE: 'Ваш плеер не поддерживает удалённое управление громкостью.',
  VOLUME_AMPLIFY_SETTINGS_UNAVAILABLE: 'Ваш плеер не поддерживает расширенные настройки громкости.',
  VOLUME_AMPLIFY_SETTINGS: 'Расширенные настройки',
  VOLUME_AMPLIFY_HINT: (value: number) => `${value}% от максимальной громкости`,
  WEB_PLAYER_TITLE: 'Веб-плеер',
  STREAM_INFO_TITLE: 'Волна',
  VIDEO_STREAM_INFO_TITLE: 'Видео-волна',
  STREAM_INFO_CHANGE: 'Сменить',
  STREAM_INFO_FALLBACK: (
    <>
      Тут тихо...
      <br />
      Даже слишком. Не хватает музыки!
    </>
  ),
  VIDEO_STREAM_INFO_FALLBACK: (
    <>
      Тут пусто...
      <br />
      Даже слишком. Не хватает видео!
    </>
  ),
  STREAM_INFO_BUTTON_CREATE_STREAM: 'Выбрать волну',
  POINT_CREATE_TITLE: 'Добавление адреса',
  POINT_CREATE_HINT_MODAL_TITLE: 'Почему важно указывать полный и корректный адрес?',
  POINT_CREATE_HINT_COST_MODAL_TITLE: 'При добавлении адреса стоимость подписки изменится',
  POINT_CREATE_BUTTON_TEXT: 'Добавить адрес',
  POINT_CREATE_WITH_PAYMENT_BUTTON_TEXT: (cost: number = 0) => `Добавить адрес за ${cost} ₽`,
  POINT_CREATE_CONFIRM_MODAL_TITLE: 'Добавить новый адрес?',
  POINT_CREATE_CONFIRM_MODAL_DESCRIPTION: 'Мы автоматически спишем нужную сумму с вашей карты',
  POINT_CREATE_CONFIRM_MODAL_BUTTON_SUBMIT: (cost: number = 0) => `Оплатить ${cost} ₽`,
  POINT_CREATE_CONFIRM_MODAL_BUTTON_CANCEL: 'Отменить',
  POINT_CREATE_CONFIRM_MODAL_WITH_PAYMENT_SUCCESS_TEXT: 'Адрес добавлен в подписку!',
  POINT_CREATE_ADDRESS: 'Адрес',
  POINT_CREATE_ADDRESS_PLACEHOLDER: 'Например, Москва, ул. Ленина 1',
  BROADCAST_HISTORY_TITLE: 'История вещания',
  BROADCAST_HISTORY_FALLBACK: 'Музыка не играла',
  BROADCAST_HISTORY_DOWNLOAD: 'Выгрузить',
  BROADCAST_HISTORY_DOWNLOAD_ERROR: 'Ошибка при получении отчета. Попробуйте еще раз',
  BROADCAST_HISTORY_EMPTY: 'История вещания появится после привязки потока к данному адресу',
  TODAY: 'Сегодня',
  YESTERDAY: 'Вчера',
  DAY_BEFORE: 'Позавчера',
  TEXT_POINTS_EMPTY: 'Добавьте свой первый адрес, чтобы запустить на нём музыку',
  TEXT_POINTS_SEARCH_EMPTY: 'Мы не нашли адресов по такому запросу',

  TAB_ALL_TITLE: 'Все',
  TAB_PLAYING_TITLE: 'Играет',
  TAB_NOT_PLAYING_TITLE: 'Не играет',
  TAB_PLAYER_DISABLED_TITLE: 'Плеер отключен',
  TAB_NOT_GENERATION_TITLE: 'Не задан поток',
  TAB_STOP_TITLE: 'Остановлена',
  TAB_SLEEP_TITLE: 'На паузе',
  TAB_MB_NO_CONNECTION: 'Нет связи',

  POINT_UPDATE_TITLE: 'Обновление адреса',
  POINT_UPDATE_BUTTON_TEXT: 'Обновить',
  POINT_CANCEL_BUTTON_TEXT: 'Отмена',
  POINT_UPDATE_COMMENT: 'Начните вводить адрес, мы подскажем',
  POINT_ADS_SUBMIT_BUTTON: 'Сохранить',
  POINT_ADS_SUBMIT_MESSAGE: 'Список рекламных кампаний обновлен',

  BUTTON_SAVE_POINT_TITLE: 'Сохранить',
  BUTTON_CANCEL_POINT_TITLE: 'Отменить',
  SAVE_POINT_MODAL_TITLE: 'Сохранить настройки',
  SAVE_POINT_MODAL_DESCRIPTION: 'Изменения вступят в силу в течение нескольких минут',

  POINT_ADS_SEARCH_TEXT: 'Поиск...',
  POINT_SET_ADS_BUTTON_TEXT: 'Сохранить',
  POINT_ADS_EMPTY_TEXT: 'Рекламных кампаний нет',
  POINT_ADS_FILTERED_EMPTY_TEXT: 'По данному запросу рекламных кампаний не найдено',
  BUTTON_POINT_SET_ADS_CANCEL_TITLE: 'Отменить',
  POINT_SET_ADS_MODAL_TITLE: 'Сохранить настройки',
  POINT_SET_ALL_ADS_MODAL_DESCRIPTION: 'Изменения вступят в силу в течение нескольких минут',
  POINT_SET_ADS_MODAL_TITLE_WARNING: 'Есть несохраненные изменения',
  POINT_SET_ALL_ADS_MODAL_DESCRIPTION_WARNING_1: 'Вы точно хотите закрыть страницу?',
  POINT_SET_ALL_ADS_MODAL_DESCRIPTION_WARNING_2: 'Несохраненные изменения будут потеряны',
  BUTTON_POINT_SET_ADS_CLOSE_TITLE: 'Закрыть',
  POINT_SET_ALL_ADS_MODAL_DESCRIPTION_WARNING_3: 'Вы точно хотите изменить фильтры?',
  POINT_SET_ALL_ADS_MODAL_DESCRIPTION_WARNING_4: 'Изменение фильтров автоматически сохранит текущий выбор',
  BUTTON_POINT_SET_ADS_CLOSE_TITLE_2: 'Изменить с сохранением',
  POINT_ADS_NO_STREAM_TEXT: 'Для управления рекламными кампаниями добавьте на адрес волну.',
  POINT_ADS_FILTERS_TAGS_TEXT: 'Фильтр по тегам',

  POINT_LINKED_TAGS_TITLE: 'Теги',
  POINT_LINKED_TAGS_ADD_TITLE: 'Добавить',
  POINT_LINKED_TAGS_CHANGE_TITLE: 'Изменить',
  POINT_LINKED_TAGS_SET_SUCCESS: 'Теги адреса изменены',
  POINT_LIST_EMPTY_STATE: 'Нет волны',
  POINT_LIST_DISABLED_POINT_PAY_BUTTON: 'Оплатить',

  POINT_AD_INSTANT_LAUNCH_MODAL_TITLE: 'Запуск моментального ролика',
  POINT_AD_INSTANT_LAUNCH_MODAL_DESCRIPTION_SINGLE: 'Ролик заиграет, как только нажмете кнопку «Запустить».',
  POINT_AD_INSTANT_LAUNCH_MODAL_DESCRIPTION_MULTI:
    'Ролик заиграет, как только нажмете кнопку «Запустить». Выберите ролик и запустите его',
  POINT_AD_INSTANT_LAUNCH_MODAL_OK_BUTTON: 'Запустить',
  POINT_AD_INSTANT_LAUNCH_MODAL_CANCEL_BUTTON: 'Отменить',
  POINT_AD_INSTANT_LAUNCH_SUCCESS_TEXT: 'Ожидайте воспроизведения ролика',
  POINT_AD_INSTANT_LAUNCH_ERROR_TEXT: 'Ошибка. Попробуйте запустить через минуту',
  POINT_AD_INSTANT_NO_PROMO_MAIN_TEXT: 'Нет моментального ролика или он не привязан к выбранному адресу.',
  POINT_AD_INSTANT_NO_PROMO_SETTINGS_TEXT: 'Для настройки ',
  POINT_AD_INSTANT_NO_PROMO_LINK_TEXT: 'перейдите в Рекламу',
  POINT_AD_INSTANT_DISABLED_MAIN_TEXT: 'Адрес не оплачен. ',
  POINT_AD_INSTANT_DISABLED_MANAGER_LINK_TEXT: 'Обратитесь к менеджеру ',
  POINT_AD_INSTANT_DISABLED_OR_TEXT: 'или ',
  POINT_AD_INSTANT_DISABLED_BILLING_LINK_TEXT: 'перейдите в раздел «Оплата»',

  BROADCAST_SCHEDULE_TITLE: 'Прогноз вещания',
  BROADCAST_SCHEDULE_BUTTON: 'Открыть',
  CUT_MUSIC_SWITCH_LABEL: 'Обрезать треки',
  CUT_MUSIC_RADIO_GROUP_LABEL: 'Перед обрезкой играть трек не менее:',
  CUT_MUSIC_RADIO_ITEM_LABEL: 'сек',
  CUT_MUSIC_HELP_MODAL_TITLE: 'Обрезка треков',
  CUT_MUSIC_HELP_MODAL_BUTTON: 'Понятно',
  CUT_MUSIC_HELP_MODAL_EXAMPLES: 'Примеры',
  CUT_MUSIC_HELP_MODAL_DESCRIPTION_1:
    'Обрезка треков нужна, чтобы точнее выходили рекламные ролики. Можно установить строгую обрезку 0 сек или мягкую — спустя 30 или 60 сек проигрывания трека. Чем строже обрезка, тем точнее время выхода рекламы, но менее гармоничны переходы между музыкой и рекламой.',
  CUT_MUSIC_HELP_MODAL_DESCRIPTION_2: (
    <>
      Иногда обрезка 0 сек может звучать не гармонично. <br /> Каждый выбирает для себя что важнее — точность выхода или
      обрезка трека 30 или 60 сек и соответственно смещение рекламы.
    </>
  ),
  CUT_MUSIC_HELP_MODAL_DESCRIPTION_3: 'Обрезка музыки также влияет на выбрасывание одной из реклам при наложении.',
  CUT_MUSIC_HELP_MODAL_DESCRIPTION_4:
    'Смещается начало выхода рекламы. Если рекламные ролики накладываются по времени, то проигрываются все ролики друг за другом со смещением.',
  CUT_MUSIC_HELP_MODAL_DESCRIPTION_5:
    'Повышается точность выхода рекламного ролика. При наложении рекламных роликов проигрывается первый начавшийся ролик, а остальные пропускаются.',
  CUT_MUSIC_HELP_MODAL_EXAMPLE_1: 'Трек на 3 мин начался в 11:59. Чтобы реклама вышла в 12:00, нужно обрезать трек.',
  CUT_MUSIC_HELP_MODAL_EXAMPLE_2: (
    <>
      Трек на 3 мин начался в 11:59:55. Чтобы реклама вышла в 12:00:00, нужно обрезать трек. <br /> Если обрезка 0 сек —
      реклама выйдет в 12:00:00. <br /> Если обрезка 30 сек — реклама выйдет в 12:00:25. <br /> Если обрезка 60 сек —
      реклама выйдет в 12:00:55.
    </>
  ),
  CUT_MUSIC_HELP_MODAL_HEADING_1: 'Без обрезания',
  CUT_MUSIC_HELP_MODAL_HEADING_2: 'С обрезанием',

  SCHEDULES_LINK_TEXT: 'Расписание вещания',
  BROADCAST_QUALITY_SWITCH_LABEL: 'Высокое качество вещания',
};
