import React from 'react';

import WebPlayerPageWrapper from 'application/pages/WebPlayer';
import { WEB_PLAYER_PAGE_PATH } from 'application/consts';
import SwitchLayout from 'application/layout/SwitchLyout';

import { RouteConfig } from '..';

export const webPlayerRoutes: RouteConfig[] = [
  {
    element: <SwitchLayout />,
    children: [
      {
        path: WEB_PLAYER_PAGE_PATH,
        element: <WebPlayerPageWrapper />,
      },
    ],
  },
];
