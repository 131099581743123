import React, { Suspense, useEffect } from 'react';

import { AuthQueryKeys, IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import {
  matchPath,
  RouterUtils,
  useNavigate,
  useLocation,
  useURLSearchParams,
} from 'application/providers/RouterProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export const REGISTER_PAGE_PATH = '/register';
export const REGISTER_PAGE_PATH_ALIAS = '/error-callback'; // TODO: в дальнейшем необходимо оформить как отдельную страницу

const Renderer = React.lazy(() => import('./renderer'));

const SEARCH_PARAMS = [AuthQueryKeys.SBBID_ERROR];

const RegisterPageWrapper: IApplicationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [SBBIDError] = useURLSearchParams(SEARCH_PARAMS);

  const isNeedRedirect = !!matchPath({ path: REGISTER_PAGE_PATH_ALIAS, end: false }, location.pathname);

  useEffect(() => {
    if (isNeedRedirect) {
      navigate(
        RouterUtils.generatePath(REGISTER_PAGE_PATH, undefined, {
          [AuthQueryKeys.SBBID_ERROR]: SBBIDError || '',
        }),
        { replace: true }
      );
    }
  }, [isNeedRedirect]);

  return (
    <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
      <Renderer />
    </Suspense>
  );
};

RegisterPageWrapper.config = {
  routes: [
    {
      path: REGISTER_PAGE_PATH,
    },
    {
      path: REGISTER_PAGE_PATH_ALIAS,
    },
  ],
  access: [LocalAccessRoles.ANONYMOUS],
};

export default RegisterPageWrapper;
