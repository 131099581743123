import { ZVUK_API_GRAPHQL_URL } from 'application/consts';
import { GraphQLFormattedError } from 'graphql';

const JWT_ERROR_MESSAGE = 'Signature has expired';

class DataProviderUtils {
  static async tokenRefresh(): Promise<string | undefined> {
    try {
      const result = await fetch(ZVUK_API_GRAPHQL_URL, {
        headers: { 'content-type': 'application/json' },

        body: '{"query":"mutation tokenPureRefresh($input: TokenPureRefreshMutationInput!) {\\n  tokenPureRefresh(input: $input) {\\n    token\\n    refreshExpiresIn\\n    payload\\n  }\\n}","variables":{"input":{"refreshToken":""}},"operationName":"tokenPureRefresh"}',
        method: 'POST',
        mode: 'cors',
      });

      const response = await result.json();
      return response?.data?.tokenPureRefresh?.token;
    } catch (_e) {
      return undefined;
    }
  }

  static checkIsJWTExpiredError(errors: readonly GraphQLFormattedError[]): boolean {
    return !!errors.find(({ message }) => message === JWT_ERROR_MESSAGE);
  }
}

export default DataProviderUtils;
