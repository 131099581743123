// TODO: import it from scss vars
export const DESKTOP_S_WIDTH = 860;
export const DESKTOP_XL_WIDTH = 1720;
export const DESKTOP_L_WIDTH = 1340;
export const DESKTOP_M_WIDTH = 1120;
export const MOBILE_L_WIDTH = 580;
export const MOBILE_M_WIDTH = 480;
export const MOBILE_S_WIDTH = 380;

const COURSE = '(pointer: coarse)';

export const MEDIA_MOBILE_S = `${COURSE} and (max-width: ${MOBILE_S_WIDTH}px)`;
export const MEDIA_MOBILE_M = `${COURSE} and (min-width: ${MOBILE_S_WIDTH}px) and (max-width: ${MOBILE_M_WIDTH - 1}px)`;
export const MEDIA_MOBILE_L = `${COURSE} and (min-width: ${MOBILE_M_WIDTH}px) and (max-width: ${MOBILE_L_WIDTH - 1}px)`;
export const MEDIA_MOBILE = `${COURSE} and (max-width: ${DESKTOP_S_WIDTH - 1}px)`;
export const MEDIA_MOBILE_XL = `${COURSE} and (max-width: ${DESKTOP_L_WIDTH}px) and (min-width: ${MOBILE_L_WIDTH}px)`;

export const MEDIA_DESKTOP = `(pointer: fine), (min-width: ${DESKTOP_S_WIDTH}px)`;
export const MEDIA_DESKTOP_S = `(min-width: ${DESKTOP_S_WIDTH}px) and (max-width: ${DESKTOP_M_WIDTH - 1}px)`;
export const MEDIA_DESKTOP_M = `(min-width: ${DESKTOP_M_WIDTH}px) and (max-width: ${DESKTOP_L_WIDTH - 1}px)`;
export const MEDIA_DESKTOP_L = `(min-width: ${DESKTOP_L_WIDTH}px)`;
export const MEDIA_DESKTOP_XL = `(min-width: ${DESKTOP_XL_WIDTH}px)`;

export const mobileSQueryList = window.matchMedia(MEDIA_MOBILE_S);
export const mobileMQueryList = window.matchMedia(MEDIA_MOBILE_M);
export const mobileLQueryList = window.matchMedia(MEDIA_MOBILE_L);
export const mobileQueryList = window.matchMedia(MEDIA_MOBILE);
export const mobileXlQueryList = window.matchMedia(MEDIA_MOBILE_XL);
export const desktopQueryList = window.matchMedia(MEDIA_DESKTOP);
export const desktopSQueryList = window.matchMedia(MEDIA_DESKTOP_S);
export const desktopMQueryList = window.matchMedia(MEDIA_DESKTOP_M);
export const desktopLQueryList = window.matchMedia(MEDIA_DESKTOP_L);
export const desktopXLQueryList = window.matchMedia(MEDIA_DESKTOP_XL);

export const queriesList = [
  mobileSQueryList,
  mobileMQueryList,
  mobileLQueryList,
  mobileQueryList,
  mobileXlQueryList,
  desktopQueryList,
  desktopSQueryList,
  desktopMQueryList,
  desktopLQueryList,
  desktopXLQueryList,
];
