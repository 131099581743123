import React, { useEffect, useMemo, useRef, useState } from 'react';

import usePlayer, { EFrameStatus, EPlayState } from '@player-core/use-player-frame';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import LoginRequiredModal from 'application/components/LoginRequiredModal';
import { LoginModal } from 'application/components/LoginModal';
import AppInstallModal from 'application/components/AppInstallModal';
import StopWebPlayerModal, { StopWebPlayerModalType } from 'application/components/StopWebPlayerModal';

import usePlayerFrameReposition from 'application/hooks/usePlayerFrameReposition';
import { useAccountContext } from 'application/providers/AccountProvider';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import WebPlayerErrorModal from 'application/components/WebPlayerErrorModal';
import Popper from '@zvuk-b2b/react-uikit/ui/Popper';
import useWebPlayerState from 'application/hooks/useWebPlayerState';
import { MediaFileTypes } from 'application/pages/types';
import WebPlayerSkipModal from 'application/components/WebPlayerSkipModal';
import { useWebPlayerPlayAmplitude } from 'application/hooks/useWebPlayerPlayAmplitude';
import { ZVUK_API_GRAPHQL_URL } from 'application/consts';

import { useNavigate } from 'application/providers/RouterProvider';
import { TMediaFile } from './types';
import { FRAME_HEIGHT, FRAME_STYLES, FRAME_WIDTH, PLAYER_STYLES } from './consts';
import { TEXTS } from './texts';
import { WebPlayerActionContext } from './WebPlayerActionContext';
import { WebPlayerContext } from './WebPlayerContext';

const SKIP_INTERVAL = 30 * 1000;

const silentAudioSrc = '/assets/audio/silence.mp3';

const WebPlayerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [iframeWrapper, setIframeRef] = useState<HTMLDivElement | null>(null);

  const { isAuthorized } = useAccountContext();

  const navigate = useNavigate();

  const notification = useNotificationContext();

  const { frameStatus, setup, actions, state } = usePlayer({});

  const playState = state?.playState;

  const [bindingCode, setBindingCode] = useState('');

  const [isWidgetActive, setIsWidgetActive] = useState(false);
  const [isPlayerHide, setIsPlayerHide] = useState(true);

  const [stopWebPlayerModalVisible, setStopWebPlayerModalVisible] = React.useState(false);

  const [redirect, setRedirect] = React.useState('');

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loginRequiredModalOpen, setLoginRequiredModalOpen] = useState(false);
  const [appInstallModalOpen, setAppInstallModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [loginRequiredModalContent, setLoginRequiredModalContent] = useState<string>('');
  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const [skipModalContent, setSkipModalContent] = useState<string>('');
  const [skipIntervalFinished, setSkipIntervalFinished] = useState(true);
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const [startLoadingTime, setStartLoadingTime] = useState<number | null>(null);

  const tracks = useMemo(
    () => state.playlist.map(({ mediafile, ...other }) => ({ ...mediafile, ...other }) as TMediaFile),
    [state.history, state.playlist]
  );

  const history = useMemo(
    () => state.history.map(({ mediafile, ...other }) => ({ ...mediafile, ...other }) as TMediaFile),
    [state.history]
  );

  const { isMobile } = useMedia();

  const stopPlayer = () => {
    actions.changePlayState(EPlayState.STOP);
  };

  const onCloseWidget = () => {
    stopPlayer();
    setIsWidgetActive(false);
    setIsPlayerHide(true);
  };

  const {
    streamId,
    pointData,
    imageUrl,
    currentRotationId,
    mediaFile,
    pointMetadata,
    sleepNotification,
    disliked,
    isConnected,
    setDisliked,
    fileLoading,
    withCaching,
    firstStart,
    firstGenerationCompletedTime,
  } = useWebPlayerState(state, onCloseWidget, TEXTS.WEB_PLAYER_START);

  useEffect(() => {
    if (container && bindingCode) {
      setup(
        container as HTMLElement,
        bindingCode,
        ZVUK_API_GRAPHQL_URL,
        PLAYER_STYLES,
        FRAME_STYLES,
        undefined,
        undefined,
        undefined,
        silentAudioSrc
      );
    }
  }, [bindingCode]);

  useEffect(() => {
    if (state.sleeping && sleepNotification) {
      notification.showNotification({
        type: NotificationColors.INFO,
        children: sleepNotification,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  }, [state.sleeping, sleepNotification]);

  const visibilityChangeHandlerRef = useRef<() => void>();

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setAppInstallModalOpen(true);
    }
  };

  useEffect(() => {
    if (visibilityChangeHandlerRef.current) {
      document.removeEventListener('visibilitychange', visibilityChangeHandlerRef.current);
      visibilityChangeHandlerRef.current = undefined;
    }

    if (isMobile && !isPlayerHide) {
      setAppInstallModalOpen(true);

      document.addEventListener('visibilitychange', onVisibilityChange);
      visibilityChangeHandlerRef.current = onVisibilityChange;
    }
  }, [isMobile, isPlayerHide]);

  const { top, left } = usePlayerFrameReposition(iframeWrapper, isWidgetActive);

  useEffect(() => {
    if (redirect && isAuthorized) {
      navigate(redirect);
      setRedirect('');
    }
  }, [redirect, isAuthorized]);

  useEffect(() => {
    if (
      state?.startPlaybackAttempt &&
      !state?.startPlaybackAttempt?.success &&
      state?.startPlaybackAttempt?.failureReason === 'POINT_DISABLED'
    ) {
      setErrorModalOpen(true);
    }
  }, [
    state?.startPlaybackAttempt?.time,
    state?.startPlaybackAttempt?.failureReason,
    state?.startPlaybackAttempt?.success,
  ]);

  const onLoginClick = () => {
    setLoginModalOpen(true);
  };

  const onLoginRequiredSubmit = () => {
    setLoginRequiredModalOpen(false);
    setLoginModalOpen(true);
  };

  const onUnAuthorizedClick = (modalContent: string) => {
    setLoginRequiredModalContent(modalContent);
    setLoginRequiredModalOpen(true);
  };

  const onLoginModalClose = () => {
    setLoginModalOpen(false);
  };

  const onLoginRequiredModalClose = () => {
    setLoginRequiredModalOpen(false);
  };

  const startPlayer = () => {
    actions.changePlayState(EPlayState.PLAY);
  };

  const onAppInstallModalCancel = () => {
    setAppInstallModalOpen(false);
  };

  const onStopWebPlayer = () => {
    if (playState === EPlayState.PLAY) {
      setStopWebPlayerModalVisible(true);
    } else {
      onCloseWidget();
    }
  };

  const onModalClose = () => {
    setStopWebPlayerModalVisible(false);
  };

  const onModalSubmit = () => {
    onCloseWidget();
    setStopWebPlayerModalVisible(false);
  };

  const onErrorModalCancel = () => {
    setErrorModalOpen(false);
  };

  const tracksHasPromo = useMemo(() => tracks.some((track) => track.type === MediaFileTypes.PROMO), [tracks]);

  const nextTrackIsPromo = tracks?.length && tracks[1]?.type === MediaFileTypes.PROMO;

  const loading = frameStatus !== EFrameStatus.ENABLED || !pointData || !mediaFile?.title;

  const actionButtonsDisabled = playState !== EPlayState.PLAY || !state.safeSwitchZone || !state.isConnected;

  const setSkippingInterval = () => {
    setSkipIntervalFinished(false);

    setTimeout(() => {
      setSkipIntervalFinished(true);
    }, SKIP_INTERVAL);
  };

  const onSkipClick = () => {
    if ((tracksHasPromo && !skipIntervalFinished) || nextTrackIsPromo) {
      const modalContent = nextTrackIsPromo ? TEXTS.NEXT_TRACK_PROMO : TEXTS.FREQUENTLY_SKIPPING;
      setSkipModalContent(modalContent);
      setSkipModalOpen(true);
    } else {
      actions.nextTrack();
      setSkippingInterval();
    }
  };

  const onSkipModalClose = () => {
    setSkipModalOpen(false);
  };

  const onSkipModalSubmit = () => {
    actions.nextTrack();
    setSkipModalOpen(false);
    setSkippingInterval();
  };

  const stopAndClosePlayer = () => {
    stopPlayer();
    setIsWidgetActive(false);
    setIsPlayerHide(true);
    setLoginRequiredModalOpen(false);
    setLoginModalOpen(false);
  };

  const value = {
    imageUrl,
    mediaFile,
    streamId,
    pointData,
    actions,
    frameStatus,
    playState,
    volume: state?.volume || 0,
    loading,
    actionButtonsDisabled,
    playerProcessing: !state.safeSwitchZone,
    isWidgetActive,
    bindingCode,
    tracks,
    currentRotationId,
    disliked,
    history,
    pointMetadata,
    redirect,
    fileLoading: fileLoading && state.playState !== EPlayState.STOP,
    isConnected,
    withCaching,
  };

  const actionsValue = {
    setIframeRef,
    setBindingCode,
    setIsWidgetActive,
    onLoginClick,
    onUnAuthorizedClick,
    stopPlayer,
    setIsPlayerHide,
    setDisliked,
    startPlayer,
    onStopWebPlayer,
    setRedirect,
    setErrorModalClose: onErrorModalCancel,
    onSkip: onSkipClick,
    stopAndClosePlayer,
  };

  const widgetZIndex = loading ? -1 : 100000 + 2;
  const pageZIndex = 100;

  useEffect(() => {
    if (container) {
      container.style.position = isWidgetActive ? 'fixed' : 'absolute';
      container.style.top = `${top}px`;
      container.style.left = `${left}px`;
      container.style.zIndex = `${isWidgetActive ? widgetZIndex : pageZIndex}`;
      container.style.display = isPlayerHide ? 'none' : 'block';
    }
  }, [isWidgetActive, top, left, isPlayerHide]);

  useEffect(() => {
    const playerContainer = document.getElementById('web-player');
    if (playerContainer) {
      setContainer(playerContainer);
    }
  }, []);

  useWebPlayerPlayAmplitude({
    pointId: pointData?.id,
    streamId,
    playState,
    isWidgetActive,
    firstStart,
    firstGenerationCompletedTime,
    startLoadingTime,
    playlistLength: state.playlist?.length,
  });

  useEffect(() => {
    setStartLoadingTime(Date.now());
  }, []);

  return (
    <WebPlayerActionContext.Provider value={actionsValue}>
      <WebPlayerContext.Provider value={value}>
        {sleepNotification && state.sleeping && (
          <Popper
            triggerNode={
              <div
                style={{
                  position: isWidgetActive ? 'fixed' : 'absolute',
                  top: `${top}px`,
                  left: `${left}px`,
                  zIndex: `${isWidgetActive ? widgetZIndex + 1 : pageZIndex + 1}`,
                  display: isPlayerHide ? 'none' : 'block',
                  width: `${FRAME_WIDTH}px`,
                  height: `${FRAME_HEIGHT}px`,
                  background: 'transparent',
                }}
              />
            }
            isShow
            offset={[10, 10]}>
            {sleepNotification}
          </Popper>
        )}

        {children}
        {loginModalOpen && <LoginModal onModalClose={onLoginModalClose} />}
        {loginRequiredModalOpen && (
          <LoginRequiredModal
            onCancel={onLoginRequiredModalClose}
            onSubmit={onLoginRequiredSubmit}
            content={loginRequiredModalContent}
          />
        )}
        {appInstallModalOpen && <AppInstallModal onCancel={onAppInstallModalCancel} />}
        {stopWebPlayerModalVisible && (
          <StopWebPlayerModal
            mode={StopWebPlayerModalType.WIDGET}
            onCancel={onModalClose}
            onSubmit={onModalSubmit}
          />
        )}
        {errorModalOpen && (
          <WebPlayerErrorModal
            onClose={onErrorModalCancel}
            pointMetadata={pointMetadata}
            pointData={pointData}
            onUnAuthorizedClick={onUnAuthorizedClick}
            setRedirect={setRedirect}
            setErrorModalClose={onErrorModalCancel}
          />
        )}
        {skipModalOpen && (
          <WebPlayerSkipModal
            content={skipModalContent}
            onCancel={onSkipModalClose}
            onSubmit={onSkipModalSubmit}
          />
        )}
      </WebPlayerContext.Provider>
    </WebPlayerActionContext.Provider>
  );
};

export default WebPlayerProvider;
