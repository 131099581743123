import { ZvukPlaylistSource } from 'domain/api/graphql/generated';
import { TEXTS } from './texts';

export const PLAYLIST_FROM_ITEMS = [
  {
    value: ZvukPlaylistSource.ZvukB2BStream,
    label: TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_FROM_ZVUK_BUSINESS,
  },
  {
    value: ZvukPlaylistSource.ZvukPlaylist,
    label: TEXTS.BUSINESS_PROFILE_ADD_PLAYLIST_FROM_ZVUK,
  },
];

export const POSTER_DEFAULT_FILENAME = 'poster.png';
export const POSTER_DEFAULT_URL = '/assets/img/business_profile/playlists/default_poster.png';
