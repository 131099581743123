import { useMemo } from 'react';
import DateUtils from '@zvuk-b2b/react-uikit/toolkit/DateUtils';
import { DeviceBindStatus, PointWithDevice } from 'application/presenters/point/types';
import AnalyticsService, {
  StreamBindFlowEventNameAMP,
  StreamBindFlowParamNameAMP,
} from 'application/services/AnalyticsService';
import { CompanyDeviceRequestPureType, DeviceTypes, UserCompanyPureType } from 'domain/api/graphql/generated';
import { PointParamNameAMP } from 'application/services/AnalyticsService/ampTypes';

type UseRequestDevicesControllerType = {
  point: PointWithDevice;
  modalDeviceType: DeviceTypes;
  currentCompany?: UserCompanyPureType;
};

const useRequestDevicesController = ({ point, modalDeviceType, currentCompany }: UseRequestDevicesControllerType) => {
  const requstedDevices = useMemo(() => {
    const requstedDevicesMap = new Map<DeviceTypes, CompanyDeviceRequestPureType>();

    currentCompany?.deviceRequests?.forEach((request) => {
      if (!request.pointId || !(request.pointId === point?.id)) {
        return;
      }

      if (!requstedDevicesMap.has(request.deviceType)) {
        requstedDevicesMap.set(request.deviceType, request);
      }
    });

    return requstedDevicesMap;
  }, [currentCompany]);

  const onSuccessCallback = (deviceType: DeviceTypes) => {
    AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_PLAYER_TYPE_BOUND, {
      [StreamBindFlowParamNameAMP.PLAYER_TYPE]: deviceType,
      [PointParamNameAMP.POINT_ID]: point.id,
    });
  };

  const requstedDevice = requstedDevices.get(modalDeviceType);
  const hasRecentRequest =
    requstedDevice && requstedDevice.deviceType === modalDeviceType
      ? !DateUtils.isMoreThenDay(new Date(), new Date(requstedDevice.lastRequestDate))
      : false;

  const alreadyBinded = point.device?.bindStatus === DeviceBindStatus.BINDED && point.device.type === modalDeviceType;
  const isFirstRequest = !(!!point.device?.lastRequestTime && point.device.type === modalDeviceType);

  const hasThisDeviceType = point.device?.actualType === modalDeviceType;
  const cantRequestDevice = hasThisDeviceType || alreadyBinded;

  return {
    onSuccessCallback,
    hasRecentRequest,
    cantRequestDevice,
    isFirstRequest,
  };
};

export default useRequestDevicesController;
