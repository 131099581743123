import React, { FC, useMemo } from 'react';

import { TEXTS } from 'application/pages/texts';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import { Country } from 'domain/api/graphql/generated';
import ConnectWithManagerButton from 'application/components/ConnectWithManagerButton';
import styles from './index.module.scss';

export type DenyOperationModalProps = {
  country?: Country;
  isWaitPayment?: boolean;
  isCalledFromStreamPage?: boolean;
};

const DenyOperationModal: FC<ModalProps[ModalTypes.DENY_OPERATION]> = ({
  close,
  country,
  isWaitPayment,
  isCalledFromStreamPage = false,
}) => {
  const description = useMemo(() => {
    if (isWaitPayment) {
      return TEXTS.MODAL_STREAM_ADD_POINT_WAIT_PAYMENT_DESCRIPTION;
    }

    if (!isCalledFromStreamPage) {
      return TEXTS.MODAL_STREAM_ADD_POINT_PROBLEM_DESCRIPTION_FALLBACK;
    }

    return TEXTS.MODAL_STREAM_ADD_POINT_PROBLEM_DESCRIPTION;
  }, [isCalledFromStreamPage, isWaitPayment]);

  return (
    <ModalScreen
      title={
        isCalledFromStreamPage
          ? TEXTS.MODAL_STREAM_ADD_POINT_PROBLEM_TITLE
          : TEXTS.MODAL_STREAM_ADD_POINT_PROBLEM_TITLE_FALLBACK
      }
      titlePlacement="left"
      onClose={close}
      actionButtons={[<ConnectWithManagerButton country={country} />]}>
      <div className={styles.DenyOperationModalDesc}>{description}</div>
    </ModalScreen>
  );
};

export default DenyOperationModal;
