import { useAuthLeadUtmMarksMutation, UtmMarkKeyType } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import AuthUtils, { PROMOCODE_COOKIE_NAME, ROISTAT_VISIT_COOKIE_NAME, SBER_AGENT_ID_COOKIE_NAME } from './utils';
import { UseUTMMarksType } from './types';

const useUtmMarks = (): UseUTMMarksType => {
  const [mutation] = useAuthLeadUtmMarksMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async () => {
    const utmMarks = [];

    const roistatVisitCookieValue = AuthUtils.getCookieByKey(ROISTAT_VISIT_COOKIE_NAME);
    if (roistatVisitCookieValue) {
      utmMarks.push({ key: UtmMarkKeyType.Roistat, value: roistatVisitCookieValue });
    }

    const promocodeCookieValue = AuthUtils.getCookieByKey(PROMOCODE_COOKIE_NAME);
    if (promocodeCookieValue) {
      utmMarks.push({ key: UtmMarkKeyType.Promocode, value: promocodeCookieValue });
    }

    const sberAgentIdCookieValue = AuthUtils.getCookieByKey(SBER_AGENT_ID_COOKIE_NAME);
    if (sberAgentIdCookieValue) {
      utmMarks.push({ key: UtmMarkKeyType.SberAgentId, value: sberAgentIdCookieValue });
    }

    const mutationResult = await mutation({
      variables: { input: { utmMarks } },
    });

    return !!mutationResult.data?.utmMarksSendToAmoLead?.ok;
  };

  return [request];
};

export default useUtmMarks;
