import React from 'react';

import CommunicationUtils from 'application/utils/CommunicationUtils';

import Info from '@zvuk-b2b/react-uikit/ui/Info';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import { Country } from 'domain/api/graphql/generated';
import { TEXTS } from '../texts';

import styles from './index.module.scss';

type AboutBrandInfoProps = {
  country?: Country;
};

const AboutBrandInfo = ({ country }: AboutBrandInfoProps) => (
  <Info className={styles.AboutBrandInfo}>
    <span>{TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_1}</span>
    <strong>{TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_2}</strong>
    <span>{TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_3}</span>
    <Button
      elementId="about_brand_info_connect_manager_button"
      type={ButtonType.PLAIN}
      className={styles.AboutBrandInfoButton}
      onClick={() => CommunicationUtils.connectWithManager(country)}>
      {TEXTS.BUSINESS_PROFILE_UPDATE_PLAYLIST_MODAL_WARNING_4}
    </Button>
  </Info>
);

export default AboutBrandInfo;
