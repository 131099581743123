import React from 'react';

import { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import { DropdownOrientation } from '@zvuk-b2b/react-uikit/ui/Dropdown';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';
import IconMaximize2 from '@zvuk-b2b/react-uikit/ui/icons/Maximize2';
import VolumeSlider from 'application/components/VolumeSlider';
import { useWebPlayerActionsContext, useWebPlayerContext } from 'application/providers/WebPlayerProvider';

import DropdownMenu, { ExpandIconType } from 'ui/Dropdown/DropdownMenu';
import IconShare from 'ui/icons/Share';
import IconX from 'ui/icons/X';
import { Link } from 'application/providers/RouterProvider';
import { TEXTS } from '../texts';
import styles from './index.module.scss';
import { useWebPlayerSettingsController } from './webPlayerSettingsController';

const WebPlayerSettingsBlock = () => {
  const { bindingCode, volume, loading, actions } = useWebPlayerContext();

  const { onStopWebPlayer } = useWebPlayerActionsContext();
  const { onCopyClick } = useWebPlayerSettingsController({ bindingCode });

  const items = [
    {
      content: (
        <div className={styles.menuItemWrapper}>
          <IconShare className={styles.maximizeButton} />
          {TEXTS.SHARE_LINK}
        </div>
      ),
      key: 'share_web_player_link',
      onClick: onCopyClick,
    },
    {
      content: (
        <div className={styles.menuItemWrapper}>
          <IconX className={styles.maximizeButton} />
          {TEXTS.CLOSE_AND_STOP}
        </div>
      ),
      onClick: onStopWebPlayer,
      key: 'close_web_player_widget',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.sliderWrapper}>
        <VolumeSlider
          elementId="volume_slider_track_volume"
          initialVolume={Math.round(volume)}
          isDisabled={false}
          isLoading={loading}
          withDigits={false}
          onChange={(volume) => {
            actions.changeVolume(volume);
          }}
        />
      </div>
      <DropdownMenu
        buttonProps={{
          text: '',
          iconType: ExpandIconType.MORE_VERTICAL,
        }}
        orientation={DropdownOrientation.RIGHT}
        items={items}
        elementId="web_player_menu"
      />
      <Link to={`/web-player/${bindingCode}`}>
        <IconButton
          className={styles.maximizeButton}
          type={ButtonType.PLAIN}
          elementId="player_maximize_button">
          <IconMaximize2 />
        </IconButton>
      </Link>
    </div>
  );
};

export default WebPlayerSettingsBlock;
