export const TEXTS = {
  MODAL_TITLE: 'Мобильное приложение',
  TITLE_DESCRIPTION: 'Свяжите адрес и ваше устройство — откройте мобильное приложение «Звук Бизнес» и введите код.',

  CODE_BIND_TITLE: 'Код из приложения',
  CODE_BIND_PLACEHOLDER: '4 символа',

  SUCCESS_BUTTON: 'Привязать устройство',
  CANCEL_BUTTON: 'Назад',
};
