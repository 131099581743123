export const DEFAULT_BUSINESS_PROFILE_FORM_DATA = {
  companyName: '',
  description: '',
  site: '',
  poster: null,
  avatar: null,
  bannerLink: '',
  bannerWeb: null,
  bannerMobile: null,
  acceptedPrivatePolicy: false,
};

export const BUSINESS_PROFILE_SERVICE_AGREEMENT_LINK = 'https://zvuk.com/gm/docs/terms';
export const BUSINESS_PROFILE_PERSONAL_DATA_AGREEMENT_LINK = 'https://zvuk.com/gm/docs/personal_policy';
export const BUSINESS_PROFILE_INFORMATION_AGREEMENT_LINK = 'https://zvuk.com/gm/docs/information-consent';

export const BUSINESS_PROFILE_NAME_MAX_LENGTH = 50;

export const BUSINESS_PROFILE_DESCRIPTION_MAX_LENGTH = 250;

export const POSTER_WIDTH = 1520;
export const POSTER_HEIGHT = 200;
export const POSTER_MAX_SIZE = 10 * 1024 * 1024;

export const AVATAR_WIDTH = 200;
export const AVATAR_HEIGHT = 200;
export const AVATAR_MAX_SIZE = 10 * 1024 * 1024;

export const BANNER_WEB_WIDTH = 1520;
export const BANNER_WEB_HEIGHT = 160;
export const BANNER_WEB_MIN_SIZE = 100;
export const BANNER_WEB_MAX_SIZE = 5 * 1024 * 1024;

export const BANNER_MOBILE_MAX_WIDTH = 3600;
export const BANNER_MOBILE_MAX_HEIGHT = 2300;
export const BANNER_MOBILE_MIN_WIDTH = 180;
export const BANNER_MOBILE_MIN_HEIGHT = 115;
export const BANNER_MOBILE_MAX_SIZE = 5 * 1024 * 1024;
