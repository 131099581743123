import {
  useBusinessProfilePlaylistRemoveMutation,
  ZvukPlaylistRemoveMutationInput,
} from 'domain/api/graphql/generated';
import { BaseMutationResultType, FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseBusinessProfilePlaylistRemoveType } from './types';

const useBusinessProfilePlaylistRemove = (): UseBusinessProfilePlaylistRemoveType => {
  const [removeMutation, removeMutationResult] = useBusinessProfilePlaylistRemoveMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: ZvukPlaylistRemoveMutationInput) => {
    const removeMutationData = await removeMutation({
      variables: {
        input,
      },
      refetchQueries: ['zvukProfileList'],
    });

    return {
      isOk: !!removeMutationData.data?.zvukPlaylistRemove?.ok,
      playlist: removeMutationData.data?.zvukPlaylistRemove?.playlist,
    };
  };

  const result: BaseMutationResultType = {
    error: removeMutationResult.error,
    loading: removeMutationResult.loading,
    called: removeMutationResult.called,
  };

  return [request, result];
};

export default useBusinessProfilePlaylistRemove;
