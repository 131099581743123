import React from 'react';
import { ChangeEventArgsType, FileErrorCode, FileUploaderProps, PreviewDescriptionPlacement } from '../types';

export type ImageUploaderProps = ImageUploaderValidationType &
  Omit<FileUploaderProps, 'onChange'> & {
    description?: React.ReactNode;
    withPreviewImages?: boolean;
    withPreviewImagesFilename?: boolean;
    withEditButton?: boolean;
    withRemoveButton?: boolean;
    previewImagesContainerClassName?: string;
    previewImagesContainerWithSelectedImagesClassName?: string;
    previewImageClassName?: string;
    previewToolboxClassName?: string;
    previewDescription?: React.ReactNode;
    previewDescriptionPlacement?: PreviewDescriptionPlacement;
    value?: ChangeEventArgsType<ImageFileErrorCode> | null;
    getUploadedImageFileDescription?: (file: ImageFile) => string;
    onChange?: (args: ChangeEventArgsType<ImageFileErrorCode>) => void;
  };

export type GetImageRejectionProps = Required<
  Omit<
    ImageUploaderValidationType,
    'width' | 'height' | 'size' | 'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight' | 'minSize' | 'maxSize'
  >
> &
  ImageFileStrictValuesType & {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    minSize?: number;
    maxSize?: number;
  };

export type UseImageUploaderControllerProps = GetImageRejectionProps & {
  value: ImageUploaderProps['value'];
  onChange: ImageUploaderProps['onChange'];
};

export enum ImageFileErrorCode {
  FileInvalidType = FileErrorCode.FileInvalidType,
  FileTooLarge = FileErrorCode.FileTooLarge,
  FileTooSmall = FileErrorCode.FileTooSmall,
  FileMustBe = FileErrorCode.FileMustBe,
  TooManyFiles = FileErrorCode.TooManyFiles,
  WidthTooSmall = 'width-too-small',
  WidthTooLarge = 'width-too-large',
  HeightTooSmall = 'height-too-small',
  HeightTooLarge = 'height-too-large',
  WidthMustBe = 'width-must-be',
  HeightMustBe = 'height-must-be',
}

export interface ImageFileError {
  message: string;
  code: ImageFileErrorCode | string;
}

export type ImageUploaderValidationType = {
  width?: number;
  height?: number;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  widthErrorText?: string;
  heightErrorText?: string;
  minWidthErrorText?: string;
  maxWidthErrorText?: string;
  minHeightErrorText?: string;
  maxHeightErrorText?: string;
  size?: number;
  minSize?: number;
  maxSize?: number;
  sizeErrorText?: string;
  minSizeErrorText?: string;
  maxSizeErrorText?: string;
};

export type ImageFileStrictValuesType = Pick<ImageUploaderValidationType, 'width' | 'height' | 'size'>;

export type ImageUploaderWidthValidationType = Required<
  Pick<ImageUploaderValidationType, 'widthErrorText' | 'minWidthErrorText' | 'maxWidthErrorText'>
> &
  Pick<ImageFileStrictValuesType, 'width'> & {
    minWidth?: number;
    maxWidth?: number;
  };

export type ImageUploaderHeightValidationType = Required<
  Pick<ImageUploaderValidationType, 'heightErrorText' | 'minHeightErrorText' | 'maxHeightErrorText'>
> &
  Pick<ImageFileStrictValuesType, 'height'> & {
    minHeight?: number;
    maxHeight?: number;
  };

export type ImageFile = File & {
  width: number;
  height: number;
};

export type PreviewImageListProps = {
  elementId: string;
  previewImages: Map<string, ImageFile>;
  previewImagesContainerClassName?: ImageUploaderProps['previewImagesContainerClassName'];
  previewImagesContainerWithSelectedImagesClassName?: ImageUploaderProps['previewImagesContainerWithSelectedImagesClassName'];
  previewImageClassName?: ImageUploaderProps['previewImageClassName'];
  previewToolboxClassName?: ImageUploaderProps['previewToolboxClassName'];
  previewDescription?: ImageUploaderProps['previewDescription'];
  previewDescriptionPlacement?: ImageUploaderProps['previewDescriptionPlacement'];
  withImageFilename?: boolean;
  withEditButton?: boolean;
  withRemoveButton?: boolean;
  isUploadButtonVisible: boolean;
  handleImageFileUploaderOpen: (currentFile: ImageFile) => void;
  handleFileRemove: (filename: string) => void;
};
