import { useApolloClient } from 'application/providers/DataProvider';

import useAvailablePricePlans from 'domain/billing/useAvailablePricePlans';
import useCurrentPricePlan from 'domain/billing/useCurrentPricePlan';
import {
  BillingOrganizationCurrentPricePlanDocument,
  BillingOrganizationCurrentPricePlanQuery,
  OrganizationPricePlanPureType,
  PricePlanPureType,
  BillingPricePlansDocument,
  BillingPricePlansQuery,
} from 'domain/api/graphql/generated';

import { UsePricePlanRepositoryResultType } from './types';

const usePricePlanRepository = (): UsePricePlanRepositoryResultType => {
  const client = useApolloClient();

  const {
    request: requestAvailablePricePlans,
    loading: availablePricePlansLoading,
    error: availablePricePlansError,
  } = useAvailablePricePlans();

  const {
    request: requestCurrentPricePlan,
    loading: currentPricePlanLoading,
    error: currentPricePlanError,
  } = useCurrentPricePlan();

  const getAvailablePricePlans = async (companyId: string) => {
    const result = await requestAvailablePricePlans(companyId);

    return result.data?.organizationPricePlansCanMigrateToQuery as PricePlanPureType[];
  };

  const readAvailablePricePlans = (organizationId: string) => {
    const result = client.readQuery<BillingPricePlansQuery>({
      query: BillingPricePlansDocument,
      variables: {
        organizationId,
      },
    });

    return (result?.organizationPricePlansCanMigrateToQuery || []) as PricePlanPureType[];
  };

  const getCurrentPricePlan = async (companyId: string) => {
    const result = await requestCurrentPricePlan(companyId);

    return result.data?.result as OrganizationPricePlanPureType;
  };

  const readCurrentPricePlan = (organizationId: string) => {
    const result = client.readQuery<BillingOrganizationCurrentPricePlanQuery>({
      query: BillingOrganizationCurrentPricePlanDocument,
      variables: {
        organizationId,
      },
    });

    return (result?.result || {}) as OrganizationPricePlanPureType;
  };

  return {
    getAvailablePricePlans,
    availablePricePlansLoading,
    availablePricePlansError,
    readAvailablePricePlans,
    getCurrentPricePlan,
    currentPricePlanLoading,
    currentPricePlanError,
    readCurrentPricePlan,
  };
};

export default usePricePlanRepository;
