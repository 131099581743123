import { TimezoneObjectType, Currencies, AdCampaignTypeEnum, DeviceTypes } from 'domain/api/graphql/generated';
import TimezoneUtils from '@zvuk-b2b/react-uikit/toolkit/TimezoneUtils';

export enum PHONE_NUMBER_COUNTRY_CODES {
  RU = 'RU',
  BY = 'BY',
  KZ = 'KZ',
}

export enum Platform {
  IOS = 'ios',
  ANDROID = 'android',
}

const PAGES_CONST = {
  LOADER_SCREEN_DELAY: 1000,
};

export const NEW_OFERTA_START_DATE = '2023-10-17';

const GENERAL_LINK = 'https://zvuk-b2b.com/';

export const LAW_CONST = {
  PRIVACY_POLICY_URL_BY: 'https://zvuk-b2b.by/conf',
  PRIVACY_POLICY_URL_RU: 'https://zvuk-b2b.com/conf',
  PUBLIC_OFFER_URL_RU: 'https://zvuk-b2b.com/oferta',
  PUBLIC_NEW_OFFER_URL_RU: 'https://zvuk-b2b.com/oferta',
  PUBLIC_OFFER_URL_POPULAR: 'https://zvuk-b2b.com/oferta-popular',
  PERS_DATA_URL_RU: 'https://zvuk-b2b.com/pers_data',
  PRIVACY_POLICY_URL_KZ: 'https://zvuk-b2b.kz/conf',
  PUBLIC_OFFER_URL_KZ: 'https://zvuk-b2b.kz/oferta',
  PERS_DATA_URL_KZ: 'https://zvuk-b2b.kz/pers_data',
  REFERRAL_URL_RU: 'https://zvuk-b2b.com/referral',
  MARKETING_EMAIL_AGREEMENT: 'https://zvuk-b2b.com/marketing-email-agreement',
};

export const HELP_LINK = 'https://help.zvuk-b2b.com/';
export const HELP_LINK_CERTIFICATE = `${HELP_LINK}certificate`;
export const HELP_LINK_BROADCASTING = `${HELP_LINK}broadcasting#way`;

export const PRIME_OFFER_LINK = `${GENERAL_LINK}sbbol_promo_offer`;

export const pointDefaultSleepSettings = [
  {
    day: 1,
    start: '09:00',
    end: '23:00',
  },
  {
    day: 2,
    start: '09:00',
    end: '23:00',
  },
  {
    day: 3,
    start: '09:00',
    end: '23:00',
  },
  {
    day: 4,
    start: '09:00',
    end: '23:00',
  },
  {
    day: 5,
    start: '09:00',
    end: '23:00',
  },
  {
    day: 6,
    start: '09:00',
    end: '23:00',
  },
  {
    day: 7,
    start: '09:00',
    end: '23:00',
  },
];

export type TimezoneType = TimezoneObjectType;

export const ACCEPT_FILETYPES = {
  'audio/wav': ['.wav'],
  'audio/mpeg': ['.mp3'],
  'audio/x-aac': ['.aac'],
  'audio/x-flac': ['.flac'],
};

export const HELP_CENTER_REPORTS_LINK = 'https://help.zvuk-b2b.com/reports';

export const HELP_CENTER_CONTRACTS_LINK = 'https://help.zvuk-b2b.com/contracts';

export const POLKA_APPS_LINK = 'https://help.zvuk-b2b.com/polka-lk';

export const DEFAULT_POINT_SETTINGS = {
  comment: '',
  amplifyPromo: 1,
  amplifyTrack: 1,
  amplifyInstant: 1,
  volume: 100,
  sleepMode: false,
  specialGeneration: false,
  ctype: 'aac',
  webDeviceAutocreate: true,
  sleepSettings: pointDefaultSleepSettings,
  timezone: TimezoneUtils.getCurrentTimezone(),
};

export enum TARIFFS {
  POPULAR = 'popular',
  COMFORT = 'comfort',
  COMFORT_2023 = 'comfort_2023',
  COMFORT_051023 = 'comfort_051023',
  COMFORT_100924 = 'comfort_100924',
  MSK2200 = 'msk2200',
}

export const OfferLinkByTariff = {
  [TARIFFS.COMFORT]: LAW_CONST.PUBLIC_OFFER_URL_RU,
  [TARIFFS.COMFORT_2023]: LAW_CONST.PUBLIC_NEW_OFFER_URL_RU,
  [TARIFFS.POPULAR]: LAW_CONST.PUBLIC_OFFER_URL_POPULAR,
};

export const CURRENCIES_SIGNS = {
  [Currencies.Rub]: '₽',
  [Currencies.Gbp]: '£',
  [Currencies.Eur]: '€',
  [Currencies.Usd]: '$',
};

export const INSTALL_APP_LINK =
  'https://redirect.appmetrica.yandex.com/serve/100160811351211931?source_type=web-player';

export default PAGES_CONST;

export const SEARCH_DELAY = 500;
export const FN_OPTIONS = { wait: SEARCH_DELAY };

export const PERIODICAL_AD_CAMPAIGNS = [
  AdCampaignTypeEnum.Interval,
  AdCampaignTypeEnum.Period,
  AdCampaignTypeEnum.ExactTime,
  AdCampaignTypeEnum.Groups,
];

export const DEFAULT_AD_CAMPAIGNS_TYPES = [
  AdCampaignTypeEnum.Interval,
  AdCampaignTypeEnum.Period,
  AdCampaignTypeEnum.ExactTime,
  AdCampaignTypeEnum.Instant,
  AdCampaignTypeEnum.Groups,
];

export const PLAYER_DESKTOP_TYPES = [DeviceTypes.Desktop, DeviceTypes.Desktopbox];

export const PLAYER_REAL_DEVICE_TYPES = [DeviceTypes.Androidbox, DeviceTypes.BoomSpeaker];

export const DEFAULT_PAGINATION = {
  page: 0,
  pageSize: 100,
};

export const DEFAULT_MEDIUM_PAGINATION = {
  page: 0,
  pageSize: 50,
};

export const DEFAULT_SHORT_PAGINATION = {
  page: 0,
  pageSize: 30,
};

export const DEFAULT_PAGINATION_INFO = {
  ...DEFAULT_PAGINATION,
  totalCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  totalPages: 0,
};

export enum PlayerSupportedFeatures {
  AdInstant = 'ad_instant',
  VolumeControl = 'volume_control',
}
