import React, { useMemo } from 'react';

import styles from './index.module.scss';

export type RichInputExtensionsProps = {
  elementId: string;
  extensions: React.ReactNode | React.ReactNode[];
  onEnterPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBackspacePressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const RichInputExtensions = ({
  elementId,
  extensions,
  onEnterPressed,
  onBackspacePressed,
}: RichInputExtensionsProps) => {
  const innerExtensions = useMemo(() => {
    if (Array.isArray(extensions)) {
      return extensions.map((extension: React.ReactNode) => (
        <span
          key={`${elementId}_${extension?.toString()}`}
          id={`${elementId}_${extension?.toString()}`}>
          {extension}
        </span>
      ));
    }

    return <span id={`${elementId}_${extensions?.toString()}`}>{extensions}</span>;
  }, [extensions]);

  return (
    <>
      {onEnterPressed && onBackspacePressed ? (
        extensions
      ) : (
        <div
          id={elementId}
          data-testid={elementId}
          className={styles.RichInputExtensions}>
          {innerExtensions}
        </div>
      )}
    </>
  );
};

export default RichInputExtensions;
