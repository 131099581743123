import React from 'react';

import { LicenseInfoTypeFragment } from 'domain/billing/types';

import LicensesListItem from './LicensesListItem';
import styles from '../index.module.scss';

export type ModalLicenseType = LicenseInfoTypeFragment & {
  isChecked: boolean;
};

export type LicensesListProps = {
  licenses: ModalLicenseType[];
  onChange: (item: ModalLicenseType, value: boolean) => void;
};

const LicensesList = ({ licenses, onChange }: LicensesListProps) => (
  <div className={styles.LicenseModalLicenseList}>
    {licenses?.map((item) => (
      <LicensesListItem
        item={item}
        key={`licenses_list_item_${item.id}`}
        isChecked={item.isChecked}
        onChange={(value) => onChange(item, value)}
      />
    ))}
  </div>
);

export default LicensesList;
