import { useBillingCompanyBillingInfoLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { BillingInfoType } from './types';

const useUserBillingInfo = () => {
  const [query, { loading, error }] = useBillingCompanyBillingInfoLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (companyId: string) => {
    const result = await query({
      variables: {
        companyId,
      },
    });

    return result.data?.result as BillingInfoType;
  };

  const result = {
    error,
    loading,
  };

  return {
    request,
    result,
  };
};

export default useUserBillingInfo;
