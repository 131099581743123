import React, { useLayoutEffect, useState } from 'react';

import Spinner from 'ui/Spinner';

import styles from './index.module.scss';

type TimerType = ReturnType<typeof setTimeout>;

export type LoadingScreenProps = React.PropsWithChildren<{
  header?: React.ReactElement;
  title?: string;
  description?: string;
  delay?: number;
  elementId?: string;
}>;

const LoadingScreen = (props: LoadingScreenProps) => {
  const { header, title, description, delay = 0, children, elementId = 'loading_screen' } = props;

  const [isHidden, setIsHidden] = useState<boolean>(delay > 0);

  useLayoutEffect(() => {
    let timeoutId: TimerType;

    if (delay > 0) {
      setIsHidden(true);
      timeoutId = setTimeout(() => setIsHidden(false), delay);
    }

    return () => clearTimeout(timeoutId);
  }, [delay]);

  if (isHidden) {
    return null;
  }

  return (
    <div
      data-testid={elementId}
      className={styles.LoadingScreen}>
      {header}
      {title && <h3>{title}</h3>}
      {description && <span>{description}</span>}
      {children || <Spinner className={styles.LoadingScreenSpinner} />}
    </div>
  );
};

export default LoadingScreen;
