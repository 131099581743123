import { ErrorsMapType, ErrorTypes } from './types';

class GraphQLApiUtils {
  static errorsArrayToObject(errors: ErrorTypes | null | undefined): ErrorsMapType {
    const result: ErrorsMapType = {};

    const errorsLength = errors?.length || 0;

    for (let i = 0; i < errorsLength; i++) {
      const error = errors?.[i];
      if (error) {
        result[error.field] = error.messages;
      }
    }

    return result;
  }
}

export default GraphQLApiUtils;
