import { useMemo } from 'react';

import { useMediaAdCampaignLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { AdCampaignType, UseAdCampaignResultType, UseAdCampaignType } from './types';

const getFormattedTime = (time: string) => {
  if (!time) return undefined;

  const [mm, hh] = time.split(':');
  return `${mm}:${hh}`;
};

const useAdCampaign = (): UseAdCampaignType => {
  const [query, queryResult] = useMediaAdCampaignLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (adCampaignId: string) =>
    query({
      variables: {
        adCampaignId,
      },
    });

  const adCampaign = useMemo(() => {
    if (queryResult.data?.adCampaignPure) {
      const adPromos = [...(queryResult.data?.adCampaignPure?.adPromos || [])]
        .reverse()
        .sort((a, b) => (b.time ? a.time?.localeCompare(b.time) : 1))
        .map((item) => ({
          ...item,
          time: item.time ? getFormattedTime(item.time) : item.time,
        }));

      return {
        ...(queryResult.data?.adCampaignPure as AdCampaignType),
        adPromos,
      };
    }

    return undefined;
  }, [queryResult.data?.adCampaignPure]);

  const result: UseAdCampaignResultType = {
    error: queryResult.error,
    loading: queryResult.loading,
    refetch: queryResult.refetch,
    data: {
      // TODO GERMAN fixe types
      // @ts-ignore-next-line
      adCampaign,
    },
  };

  return [request, result];
};

export default useAdCampaign;
