import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import PlayerProvider from 'application/providers/PlayerProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

import ReviewStreamModelProvider from './providers/ReviewStreamModelProvider';

export type ReviewStreamPageParamsType = {
  streamId: string;
};

export const REVIEW_STREAM_PAGE_PATH = '/review/:streamId';

const Renderer = React.lazy(() => import('./renderer'));

const ReviewStreamPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <PlayerProvider>
      <ReviewStreamModelProvider>
        <Renderer />
      </ReviewStreamModelProvider>
    </PlayerProvider>
  </Suspense>
);

ReviewStreamPageWrapper.config = {
  routes: [
    {
      path: REVIEW_STREAM_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default ReviewStreamPageWrapper;
