import { useAccountContext } from 'application/providers/AccountProvider';
import { usePaymentMethodRepositoryInjector } from 'application/providers/DIContainerProvider';

const useCardRemoveModalPresenter = () => {
  const { currentCompany } = useAccountContext();

  const { removeCard: removeCardRepository, removeCardLoading } = usePaymentMethodRepositoryInjector();

  const removeCard = async () => {
    const result = await removeCardRepository(currentCompany!.id);

    return result;
  };

  return {
    removeCard,
    removeCardLoading,
  };
};

export default useCardRemoveModalPresenter;
