import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import PointModelProvider from 'application/pages/PointsPage/providers/PointModelProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export const BILLING_PAGE_PATH = '/billing/:state';
export const BILLING_PAGE_PATH_ALIAS = '/billing/info';

export type LocationStateFromType = {
  from: string;
  pointId?: string;
};

export enum StateParamValues {
  INFO = 'info',
}

export type BillingPageParamsType = {
  state?: string | StateParamValues;
};

const Renderer = React.lazy(() => import('./renderer'));

const BillingPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <PointModelProvider>
      <Renderer />
    </PointModelProvider>
  </Suspense>
);

BillingPageWrapper.config = {
  routes: [{ path: BILLING_PAGE_PATH }],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default BillingPageWrapper;
