import React, { useEffect, useMemo, useState } from 'react';
import { EPlayState } from '@player-core/use-player-frame';

import PlayerUtils from 'application/utils/PlayerUtils';
import StopWebPlayerModal from 'application/components/StopWebPlayerModal';
import StopTrackPlayingModal from 'application/components/StopTrackPlayingModal';

import { useWebPlayerActionsContext, useWebPlayerContext } from '../WebPlayerProvider';
import { PlayerContextType } from './types';
import { PlayerContext } from './PlayerContext';

const PlayerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const audio = useMemo(() => new Audio(), []);

  const { playState } = useWebPlayerContext();
  const { stopPlayer, startPlayer } = useWebPlayerActionsContext();

  const [progress, setProgress] = useState<PlayerContextType['progress']>(0);
  const [isPaused, setIsPaused] = useState<PlayerContextType['isPaused']>(false);
  const [isLoading, setIsLoading] = useState<PlayerContextType['isLoading']>(false);
  const [currentFilename, setCurrentFilename] = useState<PlayerContextType['fileName']>();
  const [currentFileUuid, setCurrentFileUuid] = useState<PlayerContextType['fileUuid']>();
  const [stopWebPlayerModalOpen, setStopWebPlayerModalOpen] = useState(false);
  const [stopTrackPlayingModalOpen, setStopTrackPlayingModalOpen] = useState(false);

  const startPlayback = async () => {
    stopPlayer?.();
    setStopWebPlayerModalOpen(false);
    await audio.play();
    setIsLoading(false);
  };

  const play = async (values: Parameters<PlayerContextType['play']>[0]) => {
    audio.pause();

    const { fileUuid, fileName, fileUrl } = values;

    setProgress(0);
    setCurrentFilename(fileName);
    setCurrentFileUuid(fileUuid);
    setIsPaused(false);

    if (fileUrl) {
      audio.src = fileUrl;
      setIsLoading(true);

      if (playState === EPlayState.PLAY) {
        setStopWebPlayerModalOpen(true);

        return;
      }
      startPlayback();
    }
  };

  const pause = () => {
    setIsPaused(true);
    audio.pause();
  };

  const resume = () => {
    setIsPaused(false);
    audio.play();
  };

  const stop = () => {
    audio.pause();
    audio.currentTime = 0;

    setProgress(0);
    setCurrentFilename(undefined);
    setCurrentFileUuid(undefined);
    setIsPaused(false);
  };

  const onStartWebPlayer = () => {
    stop();
    startPlayer();
    setStopTrackPlayingModalOpen(false);
  };

  const onStopTrackPlayingCancel = () => {
    setStopTrackPlayingModalOpen(false);
  };

  const seek = (delta: number) => {
    audio.currentTime = PlayerUtils.clamp(audio.currentTime + delta, 0, audio.duration);
    setProgress(PlayerUtils.getProgress(audio));
  };

  const changeProgress = (value: number) => {
    const currentTimeToChange = audio.duration * value;
    audio.currentTime = PlayerUtils.clamp(currentTimeToChange, 0, audio.duration);
    setProgress(PlayerUtils.getProgress(audio));
  };

  const onCancel = () => {
    setStopWebPlayerModalOpen(false);
    setCurrentFilename('');
  };

  useEffect(() => {
    const iid = setInterval(() => {
      const currentProgress = PlayerUtils.getProgress(audio);
      setProgress(currentProgress);

      if (currentProgress >= 1) {
        stop();
      }
    }, 250);

    return () => {
      clearInterval(iid);
      stop();
    };
  }, []);

  const contextValue: PlayerContextType = useMemo(
    () => ({
      fileUuid: currentFileUuid,
      fileName: currentFilename,
      progress,
      isPlaying: !!currentFilename,
      isLoading,
      isPaused,
      play,
      stop,
      seek,
      changeProgress,
      pause,
      resume,
    }),
    [currentFileUuid, currentFilename, progress, isLoading, isPaused, play, stop, seek, changeProgress, pause, resume]
  );

  useEffect(() => {
    if (currentFilename && playState === EPlayState.PLAY && progress > 0) {
      stopPlayer?.();
      setStopTrackPlayingModalOpen(true);
    }
  }, [currentFilename, playState]);

  return (
    <PlayerContext.Provider value={contextValue}>
      {children}
      {stopWebPlayerModalOpen && (
        <StopWebPlayerModal
          onSubmit={startPlayback}
          onCancel={onCancel}
        />
      )}
      {stopTrackPlayingModalOpen && (
        <StopTrackPlayingModal
          onSubmit={onStartWebPlayer}
          onCancel={onStopTrackPlayingCancel}
        />
      )}
    </PlayerContext.Provider>
  );
};

export default PlayerProvider;
