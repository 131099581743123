import { useInjection, UsePointRepositoryType, KEYS } from 'application/providers/DIContainerProvider';

const useCurrentPointAdapter = () => {
  const usePointRepository = useInjection<UsePointRepositoryType>(KEYS.POINT_REPOSITORY);

  const { readCurrentPoint, setCurrentPoint, watchCurrentPoint, clearCurrentPoint } = usePointRepository();

  return {
    readCurrentPoint,
    setCurrentPoint,
    watchCurrentPoint,
    clearCurrentPoint,
  };
};

export default useCurrentPointAdapter;
