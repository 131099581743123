import ruRuLocale from 'date-fns/locale/ru';

import DateUtils from './DateUtils';

class TimezoneUtils {
  static getCurrentTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static getDateWithTimezone(timezone: string, dateStr?: string) {
    const date = dateStr ? new Date(dateStr) : new Date();
    return date.toLocaleString(ruRuLocale.code, { timeZone: timezone });
  }

  static getDateTimeWithoutTimezone = (
    date: string | Date | null,
    startOrEndDayTransformer?: (date: Date) => Date,
    formatString = "yyyy-MM-dd'T'HH:mm:ss"
  ): string => {
    if (!date) return '';

    const parsedDate = typeof date === 'string' ? DateUtils.parse(date, 'dd.MM.yyyy') : date;
    const parsedStartOrEndDate = startOrEndDayTransformer ? startOrEndDayTransformer(parsedDate) : parsedDate;
    return DateUtils.getFormatDateFrom(parsedStartOrEndDate, formatString);
  };

  static getDateWithoutTimezoneOffset = (dateStr: string) => {
    const dateWithTimezone = new Date(dateStr);
    const userTimezoneOffset = dateWithTimezone.getTimezoneOffset() * 60000;
    return new Date(dateWithTimezone.getTime() - Math.abs(userTimezoneOffset));
  };

  static getEndDateWithTimezone = (timezone: string) => {
    const dateWithTimezone = TimezoneUtils.getDateWithTimezone(timezone);
    const parsedDate = DateUtils.parse(dateWithTimezone, 'dd.MM.yyyy, HH:mm:ss');
    return DateUtils.getEndOfDay(parsedDate);
  };
}

export default TimezoneUtils;
