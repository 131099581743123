import { useScript } from 'application/hooks';
import { useAccountContext } from 'application/providers/AccountProvider';
import { Country } from 'domain/api/graphql/generated';
import './index.scss';

export type UseDeskMessengerType = {
  toggle: (value: boolean) => void;
};
export interface UseDeskWindow extends Window {
  usedeskMessenger?: UseDeskMessengerType;
}

export const windowUseDesk: UseDeskWindow = window;

const SUPPORT_WIDGET_URL = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_167020_56071.js';
const SUPPORT_WIDGET_URL_KZ = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_167020_59658.js';

const SupportChat = () => {
  const { currentUser } = useAccountContext();
  const widgetURL = currentUser?.mainCompany?.country === Country.Kz ? SUPPORT_WIDGET_URL_KZ : SUPPORT_WIDGET_URL;

  useScript(widgetURL, true, () => {
    const windowUseDesk: UseDeskWindow = window;
    windowUseDesk.usedeskMessenger?.toggle(false);
  });
  return null;
};

export default SupportChat;
