import {
  useMediaAdCampaignSetPointsMutation,
  AdCampaignPureSetPointsMutationInput,
  MediaAdCampaignDocument,
} from 'domain/api/graphql/generated';

import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useAdSetPoints = () => {
  const [mutation, mutationResult] = useMediaAdCampaignSetPointsMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: AdCampaignPureSetPointsMutationInput) => {
    const mutationResult = await mutation({ variables: { input }, refetchQueries: [MediaAdCampaignDocument] });
    return !!mutationResult.data?.adcampaignSetPointsMutation?.ok;
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    request,
  };
};

export default useAdSetPoints;
