import React from 'react';

import { FORGOT_PAGE_PATH } from 'application/consts';
import EmailSendPageWrapper from 'application/pages/EmailSendPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const emailSendRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: FORGOT_PAGE_PATH,
        element: <EmailSendPageWrapper />,
      },
    ],
  },
];
