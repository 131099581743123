import React, { FC, useEffect, useCallback } from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import usePlayerChangeController from 'application/hooks/usePlayerChangeController';

import { DeviceTypes } from 'domain/api/graphql/generated';

import Input from '@zvuk-b2b/react-uikit/ui/Input';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import AnalyticsService, {
  StreamBindFlowEventNameAMP,
  StreamBindFlowParamNameAMP,
} from 'application/services/AnalyticsService';

import { PointParamNameAMP } from 'application/services/AnalyticsService/ampTypes';
import { TEXTS } from './texts';

import styles from '../index.module.scss';

export type BindMobileModalProps = {
  pointId: string;
};

const DEVICE_IMAGE_PATH = '/assets/img/app/bindMobile.png';

const BindMobileModal: FC<ModalProps[ModalTypes.MOBILE_PLAYER_BIND]> = ({
  onPrevious,
  onConfirmClose,
  pointId,
  onNext,
  close,
}) => {
  const onSuccessCallback = useCallback(
    (deviceType: DeviceTypes) => {
      AnalyticsService.event(StreamBindFlowEventNameAMP.STREAM_PLAYER_TYPE_BOUND, {
        [StreamBindFlowParamNameAMP.PLAYER_TYPE]: deviceType,
        [PointParamNameAMP.POINT_ID]: pointId,
      });

      onNext?.({ deviceType });
    },
    [onNext]
  );

  const { bindingCode, setBindingCode, bindingCodeError, handleUpdateClick, updateDeviceLoading, setToPlayerType } =
    usePlayerChangeController({
      pointId,
      fromPlayerType: DeviceTypes.Ios, // TODO: don't matter which mobile type will be passed
      onSuccessCallback,
    });

  useEffect(() => {
    setToPlayerType('APP');
  }, []);

  const handleCancel = () => {
    if (onConfirmClose) {
      onConfirmClose();
    } else {
      close();
    }
  };

  return (
    <ModalScreen
      titlePlacement="left"
      onClose={handleCancel}
      title={TEXTS.MODAL_TITLE}
      className={styles.MobilePlayerFlowModal}
      contentClassName={styles.MobilePlayerFlowModalContent}
      containerActionButtonsClassName={styles.MobilePlayerFlowModalActionButtons}
      actionButtons={[
        <Button
          key="button_mobile_player_modal_bind_success"
          elementId="button_mobile_player_modal_bind_success"
          type={ButtonType.PRIMARY}
          isDisabled={bindingCode.length < 4}
          isLoading={updateDeviceLoading}
          onClick={handleUpdateClick}>
          {TEXTS.SUCCESS_BUTTON}
        </Button>,
        <Button
          key="button_mobile_player_modal_bind_cancel"
          elementId="button_mobile_player_modal_bind_cancel"
          type={ButtonType.SECONDARY}
          isLoading={updateDeviceLoading}
          onClick={onPrevious}>
          {TEXTS.CANCEL_BUTTON}
        </Button>,
      ]}>
      <div className={styles.MobilePlayerFlowModalDescription}>{TEXTS.TITLE_DESCRIPTION}</div>

      <div className={styles.MobilePlayerFlowModalDownloadContent}>
        <Input
          elementId="button_mobile_player_modal_bind_code_input"
          onChange={setBindingCode}
          value={bindingCode}
          placeholder={TEXTS.CODE_BIND_PLACEHOLDER}
          maxLength={4}
          label={TEXTS.CODE_BIND_TITLE}
          error={bindingCodeError}
          containerClassName={styles.MobilePlayerFlowModalInput}
        />

        <img
          src={DEVICE_IMAGE_PATH}
          alt=""
          loading="lazy"
          className={styles.MobilePlayerFlowModalImageBind}
        />
      </div>
    </ModalScreen>
  );
};

export default BindMobileModal;
