import React from 'react';

import { TEXTS } from '../texts';

import styles from './index.module.scss';

const BANNER_WEB_1_PATH = '/assets/img/business_profile/banner_web_hints/banner_web_1.png';
const BANNER_WEB_2_PATH = '/assets/img/business_profile/banner_web_hints/banner_web_2.png';

const BannerWeb = () => (
  <div className={styles.BannerWebContainer}>
    <div>
      <span>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_DESCRIPTION_1}</span>
      <strong>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_SIZE_TEXT}</strong>
    </div>
    <div>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_DESCRIPTION_2}</div>

    <div className={styles.ImagesContainer}>
      <div className={styles.BannerSizesContainer}>{TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_SIZE_BASE}</div>
      <img
        src={BANNER_WEB_1_PATH}
        alt=""
        loading="lazy"
        className={styles.BannerWeb}
        data-testid="business_profile_hint_banner_web_base"
      />
    </div>

    <div className={styles.ImagesContainer}>
      <div className={styles.BannerSizesContainerMedium}>
        {TEXTS.BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_SIZE_MEDIUM}
      </div>
      <img
        src={BANNER_WEB_2_PATH}
        alt=""
        loading="lazy"
        className={styles.BannerWeb}
        data-testid="business_profile_hint_banner_web_medium"
      />
    </div>
  </div>
);

export default BannerWeb;
