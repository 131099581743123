import { useInjection, KEYS, UseBusinessProfileRepositoryType } from 'application/providers/DIContainerProvider';

const useBusinessProfileAddPlaylistModalPresenter = () => {
  const useBusinessProfileRepository = useInjection<UseBusinessProfileRepositoryType>(KEYS.BUSINESS_PROFILE_REPOSITORY);

  const {
    savedStreams,
    isSavedStreamsLoading,
    isSavedStreamsCalled,
    getSavedStreams,
    setSelectedBusinessProfilePlaylist,
    getZvukPlaylistInfo,
    getZvukPlaylistInfoLoading,
  } = useBusinessProfileRepository();

  const isSavedStreamsReady = isSavedStreamsCalled && (!isSavedStreamsLoading || savedStreams?.length !== 0);

  return {
    isSavedStreamsReady,
    savedStreams,
    isSavedStreamsLoading,
    getSavedStreams,
    setSelectedBusinessProfilePlaylist,
    getZvukPlaylistInfo,
    getZvukPlaylistInfoLoading,
  };
};

export default useBusinessProfileAddPlaylistModalPresenter;
