import React, { useCallback, useMemo, useRef, useState } from 'react';

import { NotificationColors, NotificationDataByType, NotificationDataType } from './types';
import { NotificationContext } from './NotificationContext';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NOTIFICATION_DEFAULT_INFO_MESSAGE,
  NOTIFICATION_DEFAULT_SUCCESS_MESSAGE,
  NOTIFICATION_DEFAULT_WARNING_MESSAGE,
  SWAP_DELAY,
} from './consts';

const NotificationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationDataType | undefined>();

  const ref = useRef<ReturnType<typeof setTimeout>>();

  const showNotification = useCallback(
    (data: NotificationDataType) => {
      if (ref.current) {
        clearTimeout(ref.current);
        setIsOpen(false);
        setTimeout(() => setIsOpen(true), SWAP_DELAY);
      } else {
        setIsOpen(true);
      }

      setNotification({
        ...data,
        closeAfterClick: data.closeAfterClick ?? true,
      });

      if (data.delay) {
        ref.current = setTimeout(() => {
          setIsOpen(false);
          clearTimeout(ref.current!);
        }, data.delay);
      }
    },
    [ref.current, setIsOpen, setNotification]
  );

  const showInfoNotification = useCallback(
    (data: NotificationDataByType) => {
      showNotification({
        type: NotificationColors.INFO,
        children: data.children || NOTIFICATION_DEFAULT_INFO_MESSAGE,
        closeAfterClick: data.closeAfterClick || true,
        delay: data.delay || NOTIFICATION_DEFAULT_DELAY,
      });
    },
    [showNotification]
  );

  const showSuccessNotification = useCallback(
    (data: NotificationDataByType) => {
      showNotification({
        type: NotificationColors.SUCCESS,
        children: data.children || NOTIFICATION_DEFAULT_SUCCESS_MESSAGE,
        closeAfterClick: data.closeAfterClick || true,
        delay: data.delay || NOTIFICATION_DEFAULT_DELAY,
      });
    },
    [showNotification]
  );

  const showErrorNotification = useCallback(
    (data: NotificationDataByType) => {
      showNotification({
        type: NotificationColors.ERROR,
        children: data.children || NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        closeAfterClick: data.closeAfterClick || true,
        delay: data.delay || NOTIFICATION_DEFAULT_DELAY,
      });
    },
    [showNotification]
  );

  const showWarningNotification = useCallback(
    (data: NotificationDataByType) => {
      showNotification({
        type: NotificationColors.WARNING,
        children: data.children || NOTIFICATION_DEFAULT_WARNING_MESSAGE,
        closeAfterClick: data.closeAfterClick || true,
        delay: data.delay || NOTIFICATION_DEFAULT_DELAY,
      });
    },
    [showNotification]
  );

  const hideNotification = useCallback(() => {
    setIsOpen(false);
    setNotification(undefined);
  }, [setIsOpen, setNotification]);

  const contextValue = useMemo(
    () => ({
      isOpen,
      data: notification,
      showNotification,
      showInfoNotification,
      showSuccessNotification,
      showErrorNotification,
      showWarningNotification,
      hideNotification,
    }),
    [
      isOpen,
      notification,
      showNotification,
      showInfoNotification,
      showSuccessNotification,
      showErrorNotification,
      showWarningNotification,
      hideNotification,
    ]
  );

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
