import DislikeButton from 'application/components/DislikeButton';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { useWebPlayerActionsContext, useWebPlayerContext } from 'application/providers/WebPlayerProvider';
import React from 'react';
import { useAccountContext } from 'application/providers/AccountProvider';
import { PERMISSION_REGEXP } from '../consts';
import { TEXTS } from '../texts';
import styles from '../index.module.scss';

interface IWebPlayerPureDislikeButtonProps {
  id?: string | number;
  isDisabled?: boolean;
  streamId: string;
  onDislike?: () => void;
  hint?: string;
}

const WebPlayerPureDislikeButton: React.VFC<IWebPlayerPureDislikeButtonProps> = ({
  hint,
  onDislike,
  streamId,
  isDisabled,
  id,
}) => {
  const { disliked, mediaFile, actions } = useWebPlayerContext();
  const { onUnAuthorizedClick, setDisliked } = useWebPlayerActionsContext();

  const dislikedMapKey = `${id}/${streamId}`;

  const { isAuthorized } = useAccountContext();

  const notification = useNotificationContext();

  const onDislikeClick = () => {
    onDislike?.();

    if (!onDislike && mediaFile?.id === id) {
      actions.nextTrack();
    }

    notification.showNotification({
      type: NotificationColors.INFO,
      children: TEXTS.TRACK_REMOVED,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  const onSetDislike = (value: boolean) => {
    setDisliked({
      ...disliked,
      [dislikedMapKey]: value,
    });
  };

  const dislikeErrorHandler = (error: unknown) => {
    const dislikeError = error as Error;
    const isPermissionMessage = dislikeError.message && PERMISSION_REGEXP.test(dislikeError.message);
    const message = isPermissionMessage ? TEXTS.NO_DISLIKE_PERMISSION : NOTIFICATION_DEFAULT_ERROR_MESSAGE;

    notification.showNotification({
      type: NotificationColors.ERROR,
      children: message,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  const onUnAuthorized = () => {
    onUnAuthorizedClick(TEXTS.LOGIN_REQUIRED_DISLIKE_CONTENT);
  };

  return (
    <DislikeButton
      id={id}
      streamId={streamId}
      onDislike={onDislikeClick}
      isDisabled={isDisabled}
      isDisliked={disliked[dislikedMapKey]}
      hint={hint}
      isAuthorized={isAuthorized}
      onUnAuthorizedClick={onUnAuthorized}
      errorHandler={dislikeErrorHandler}
      buttonClassName={styles.autoEvents}
      setIsDisliked={onSetDislike}
    />
  );
};

export default WebPlayerPureDislikeButton;
