import React from 'react';

import { DEFAULT_POINT_STATUSES } from 'application/pages/PointsPage/renderer/PointsToolbar/consts';
import { DefaultFiltersType } from 'application/pages/PointsPage/providers/PointModelProvider/types';

import {
  PointQueryFilter,
  PointQueryPagination,
  PlayerDevicePureType,
  PointInfoTypeFragment,
  PaginationInfoType,
  DeviceTypes,
  PointPureType,
  PointsQueryPaginationType,
} from 'domain/api/graphql/generated';
import { GetPointsType } from 'application/repositories/point/types';

/** usePointsPaginationPresenter Types */
export const POINTS_PAGINATION_DEFAULT_FILTERS: DefaultFiltersType = {
  companyIds: [],
  query: '',
  playingStatuses: DEFAULT_POINT_STATUSES,
  companyTagIds: [],
};

export type UsePointsPaginationPresenterProps = {
  getPoints: (args: GetPointsType) => Promise<void>;
  getMorePoints: (args: GetPointsType) => Promise<void>;
  readPoints: ({ filters, pagination, sort }: GetPointsType) => PointsQueryPaginationType | null;
};

export type UsePointsPaginationPresenter = {
  points: PointPureType[];
  paginationInfo: PaginationInfoType;
  allPointsCount: number | null;
  filtersRef: React.MutableRefObject<PointQueryFilter>;
  requestPoints: (filters?: PointQueryFilter, pagination?: PointQueryPagination) => Promise<void>;
  loadMorePoints: () => Promise<void>;
  onFilterExecute: (newFilters: PointQueryFilter) => Promise<void>;
  resetFilters: () => void;
};

/** usePointPresenter Types */
export enum DeviceBindStatus {
  BINDED = 'binded',
  REQUESTED = 'requested',
}

export type PointDeviceType = PlayerDevicePureType & {
  bindStatus?: DeviceBindStatus;
  lastRequestTime?: string;
  actualType?: DeviceTypes;
};

export type PointWithDevice = Omit<PointInfoTypeFragment, 'device'> & {
  device?: PointDeviceType;
  isNew?: boolean;
};
