import {
  LAW_CONST,
  OfferLinkByTariff,
  PHONE_NUMBER_COUNTRY_CODES,
  TARIFFS,
  NEW_OFERTA_START_DATE,
} from 'application/pages/constants';
import {
  CompanyContractPaymentTermsTypeInfoTypeFragment,
  CompanyContractInfoTypeFragment,
  CompanyContractKind,
  CompanyContractMigrationType,
  CompanyContractPureType,
  CompanyContractStatusEnum,
  ContractMigrationStatusEnum,
  Country,
  OrganizationType,
  UserCompanyPureType,
  RequisiteRuPureType,
  RequisiteByPureType,
  RequisiteKzPureType,
} from 'domain/api/graphql/generated';
import { IS_OFERTA_CONFIRMED } from 'application/consts';

import { INN_LENGTH_LEGAL } from './ValidationUtils/ValidationUtils';
import PhoneUtils from './PhoneUtils';

export enum RequisiteKzIdentificatorFieldname {
  IIN = 'iin',
  BIN = 'bin',
}

class CompanyUtils {
  static getKZIdentificatorFieldnameByValue(iinBin: string) {
    if (Number(iinBin[4]) < 4) {
      return RequisiteKzIdentificatorFieldname.IIN;
    }

    return RequisiteKzIdentificatorFieldname.BIN;
  }

  static getActiveContractMigration(
    company: UserCompanyPureType,
    contractType: CompanyContractKind
  ): CompanyContractMigrationType | undefined {
    return company.contractMigrations?.find(
      (migration) =>
        migration.status === ContractMigrationStatusEnum.Initiated && migration.contractType === contractType
    );
  }

  static checkIsOffertaAvailable(company: UserCompanyPureType) {
    const hasActiveOfferta = !!CompanyUtils.getCompanyActiveContractByType(company, CompanyContractKind.Offerta);
    return hasActiveOfferta && (company.country === Country.Ru || company.country === Country.Kz);
  }

  static getOfferByUserCompany(company: UserCompanyPureType, actualContract: CompanyContractPureType) {
    const tariff = company?.pricePlan?.alias || TARIFFS.COMFORT_2023;
    const isOfertaConfirmed = localStorage.getItem(IS_OFERTA_CONFIRMED);

    if (isOfertaConfirmed === 'true') {
      return OfferLinkByTariff[tariff as keyof typeof OfferLinkByTariff] || OfferLinkByTariff.comfort;
    }

    if (actualContract.typeContract === CompanyContractKind.Offerta && tariff !== TARIFFS.POPULAR) {
      const startDateTime = new Date(NEW_OFERTA_START_DATE).getTime();
      const ofertaDateTime = new Date(actualContract.date).getTime();

      return startDateTime <= ofertaDateTime ? OfferLinkByTariff.comfort_2023 : OfferLinkByTariff.comfort;
    }

    return OfferLinkByTariff[tariff as keyof typeof OfferLinkByTariff] || OfferLinkByTariff.comfort;
  }

  static getOffertaByCompanyCountry(company: UserCompanyPureType, actualContract: CompanyContractPureType) {
    if (company.country === Country.Ru) {
      return this.getOfferByUserCompany(company, actualContract);
    }

    if (company.country === Country.Kz) {
      return LAW_CONST.PUBLIC_OFFER_URL_KZ;
    }

    return null;
  }

  static openOffertaByCompanyCountry(company: UserCompanyPureType, actualContract: CompanyContractPureType) {
    const offerLink = this.getOffertaByCompanyCountry(company, actualContract);

    if (offerLink) {
      window.open(offerLink, '_blank');
    }
  }

  static getCompanyActiveContractByType(
    company: UserCompanyPureType,
    contractType: CompanyContractKind
  ): CompanyContractPureType | undefined {
    return company.contracts.find(
      (contract) => contract.status === CompanyContractStatusEnum.Actual && contract.typeContract === contractType
    );
  }

  static getPricePlanContractPaymentTerms(
    actualContract: CompanyContractInfoTypeFragment
  ): CompanyContractPaymentTermsTypeInfoTypeFragment | undefined {
    return actualContract?.paymentTerms || undefined;
  }

  static getCompanyWithActiveContractMigration(
    companies: UserCompanyPureType[],
    contractType: CompanyContractKind
  ): UserCompanyPureType | undefined {
    for (let i = 0; i < companies.length; i++) {
      if (CompanyUtils.getActiveContractMigration(companies[i], contractType)) {
        return companies[i];
      }
    }

    return undefined;
  }

  static getOrganizationTypeByINN(inn: string): OrganizationType {
    if (inn.length <= INN_LENGTH_LEGAL) {
      return OrganizationType.Legal;
    }

    return OrganizationType.Individual;
  }

  static phoneCountryToOrganizationCountry(value: string): Country {
    const phoneCountry = PhoneUtils.parsePhoneNumberFromString(value)?.country;

    if (phoneCountry === PHONE_NUMBER_COUNTRY_CODES.RU) {
      return Country.Ru;
    }

    if (phoneCountry === PHONE_NUMBER_COUNTRY_CODES.BY) {
      return Country.By;
    }

    if (phoneCountry === PHONE_NUMBER_COUNTRY_CODES.KZ) {
      return Country.Kz;
    }

    return Country.Ru;
  }

  static compareTariff(currentTariff: string, comparableValue: string): boolean {
    if (currentTariff === comparableValue) {
      return true;
    }

    return false;
  }

  static getCompanyINNByCountry(company?: UserCompanyPureType | null): string {
    if (!company) return '';

    if (company.country === Country.By) {
      return (company.requisite as RequisiteByPureType).unp;
    }

    if (company.country === Country.Kz) {
      const requisitesKZ = company.requisite as RequisiteKzPureType;
      return requisitesKZ.iin || requisitesKZ.bin;
    }

    return (company.requisite as RequisiteRuPureType).inn;
  }

  static isDenyCreatePoint = (
    canRealizeBillingOperation?: boolean | null,
    company?: UserCompanyPureType | null
  ): boolean => {
    const isTrialPeriod =
      company && company.trialPeriod ? !company.trialPeriod.isExpired && !company.trialPeriod.isFinished : false;

    let isDenyCreatePoint = !canRealizeBillingOperation;

    if (company?.country !== Country.Ru) {
      isDenyCreatePoint = !isTrialPeriod;
    }

    return isDenyCreatePoint;
  };
}

export default CompanyUtils;
