export type AllStatusType = 'all';

export type PointPlayingStatusesValues =
  | PointStatuses.playerDisabled
  | PointStatuses.notGeneration
  | PointStatuses.playing
  | PointStatuses.notPlaying
  | PointStatuses.stop
  | PointStatuses.sleep
  | PointStatuses.mbNoConnection
  | PointStatuses.mbPlaying;

export type PointPlayingStatusesFilterValues =
  | PointStatusesMap.playerDisabled
  | PointStatusesMap.notGeneration
  | PointStatusesMap.playing
  | PointStatusesMap.notPlaying
  | PointStatusesMap.stop
  | PointStatusesMap.sleep
  | PointStatusesMap.mbNoConnection
  | PointStatusesMap.mbPlaying;

export type PointPlayingStatusesVisibleValues =
  | PointStatusesMap.mbNoConnection
  | PointStatusesMap.playing
  | PointStatusesMap.notPlaying;

export enum PointPlayingStatuses {
  playerDisabled = 'Плеер отключен',
  notGeneration = 'Не задан поток',
  playing = 'Играет',
  notPlaying = 'Не играет',
  stop = 'Остановлена',
  sleep = 'На паузе',
  mbNoConnection = 'Нет связи',
  mbPlaying = 'Играет',
  all = 'all',
}

export type PointStatusesType = AllStatusType | PointPlayingStatusesVisibleValues;

export enum PointStatuses {
  playerDisabled = 'playerDisabled',
  notGeneration = 'notGeneration',
  playing = 'playing',
  notPlaying = 'notPlaying',
  stop = 'stop',
  sleep = 'sleep',
  mbNoConnection = 'mbNoConnection',
  mbPlaying = 'mbPlaying',
}

export enum PointStatusesMap {
  playerDisabled = 'player_disabled',
  notGeneration = 'not_generation',
  playing = 'playing',
  notPlaying = 'not_playing',
  stop = 'stop',
  sleep = 'sleep',
  mbNoConnection = 'mb_no_connection',
  mbPlaying = 'mb_playing',
}

export type PointStatusesInfoType = {
  all: number;
  [PointStatusesMap.playing]?: number;
  [PointStatusesMap.notPlaying]?: number;
  [PointStatusesMap.mbNoConnection]?: number;
};
