import React from 'react';

import IconRenderer, { IconProps } from 'ui/icons/IconRenderer';

import icon from './trackIcon.svg';

const IconTrack = ({ className, style }: IconProps) => (
  <IconRenderer
    iconHtml={icon}
    className={className}
    style={style}
  />
);

export default IconTrack;
