import React, { useEffect, useMemo } from 'react';

import { useAuthContext } from 'application/providers/AuthProvider';
import RootFrontendUtils from 'application/utils/RootFrontendUtils';

import { CompanyContractStatusEnum } from 'domain/api/graphql/generated';
import useCompanyUpdate from 'domain/auth/useCompanyUpdate';
import useCurrentUser from 'domain/auth/useCurrentUser';
import useCompanyRequestDevice from 'domain/company/useCompanyRequestDevice';

import { AccountContext } from './AccountContext';

const AccountProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const authContext = useAuthContext();
  const [userRequest, userResult] = useCurrentUser();

  const { request: companyUpdateRequest, result: companyUpdateResult, clearErrors } = useCompanyUpdate();
  const { request: deviceRequest, result: deviceRequestResult } = useCompanyRequestDevice();

  useEffect(() => {
    if (userResult.data.user) {
      authContext.setAuthData({
        userData: userResult.data.user,
      });
    }
  }, [userResult.data.user]);

  const currentCompany = authContext.userData?.mainCompany!;
  const currentRequisite = currentCompany?.requisite || undefined;
  const streamLibraryId = currentCompany?.streamLibrariesIds?.length
    ? String(currentCompany.streamLibrariesIds[0])
    : undefined;
  const userContactName = authContext.userData?.email || authContext.userData?.phone;

  const isReadyToUseLK = RootFrontendUtils.isReadyToUseLK(authContext.userData, currentCompany);

  const isAuthorized = Boolean(authContext?.userData?.id);

  const companyIds = useMemo(
    () => authContext.userData?.companies.map((item) => item.id) || [],
    [authContext.userData?.companies]
  );

  const actualContract = useMemo(
    () => currentCompany?.contracts.find((contract) => contract.status === CompanyContractStatusEnum.Actual),
    [currentCompany?.contracts]
  );

  useEffect(() => {
    if (authContext.userData?.id) {
      userRequest();
    }
  }, [authContext.userData?.id, currentCompany?.id, isReadyToUseLK]);

  const contextValue = useMemo(
    () => ({
      currentUser: authContext.userData,
      currentCompany,
      currentRequisite,
      companyIds,
      actualContract,
      streamLibraryId,
      userContactName,
      companyUpdateRequest,
      companyUpdateResult,
      deviceRequest,
      clearErrors,
      isReadyToUseLK,
      isAuthorized,
      userRequest,
      deviceRequestLoading: deviceRequestResult.loading,
      userRefetching: userResult.refetching,
    }),
    [
      authContext.userData,
      currentCompany,
      currentRequisite,
      companyIds,
      actualContract,
      streamLibraryId,
      userContactName,
      companyUpdateRequest,
      companyUpdateResult,
      clearErrors,
      isReadyToUseLK,
      isAuthorized,
      userResult.refetching,
      deviceRequestResult,
    ]
  );

  return <AccountContext.Provider value={contextValue}>{children}</AccountContext.Provider>;
};

export default AccountProvider;
