import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useDebounceFn } from 'ahooks';
import { InView } from 'react-intersection-observer';

import { useAccountContext } from 'application/providers/AccountProvider';
import SuggestionUtils from 'application/utils/SuggestionUtils';
import AnalyticsService, { EventNameAMP, ParamNameAMP, OperationStatuses } from 'application/services/AnalyticsService';
import useCompanyTags from 'domain/company/useCompanyTags';
import useCompanyCreateTag from 'domain/company/useCompanyCreateTag';
import { PaginationInfoType } from 'domain/api/graphql/generated';
import { DropdownItemDataType } from '@zvuk-b2b/react-uikit/ui/Dropdown';
import Spinner from '@zvuk-b2b/react-uikit/ui/Spinner';
import { Colors } from '@zvuk-b2b/react-uikit/ui/types';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import CompanyTagChip, {
  VISIBLE_TITLE_LENGTH,
  VISIBLE_TITLE_LENGTH_MOBILE,
} from 'application/components/CompanyTagChip';
import {
  useNotificationContext,
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
} from 'application/providers/NotificationProvider';

import { CompanyTagType } from 'domain/company/types';
import { CompanyTagWithoutCompanyIdType } from './types';
import { TEXTS } from './texts';
import LinkedTagsModalUtils from './utils';
import {
  OPTIONS,
  TAG_ALREADY_EXISTS_REGEXP,
  TAG_VALUE_TOO_LONG_REGEXP,
  AUTOCOMPLETE_MAX_VISIBLE_OPTIONS,
  AUTOCOMPLETE_TAGS_PAGE_SIZE,
  AUTOCOMPLETE_DROPDOWN_MENU_MARGIN,
} from './consts';

import styles from './index.module.scss';
import { CompanyTagsContext } from './CompanyTagsContext';

const CompanyTagsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { companyIds } = useAccountContext();
  const { isDesktop } = useMedia();
  const notification = useNotificationContext();
  const [requestTags, result] = useCompanyTags();
  const [createTagRequest, createTagResult] = useCompanyCreateTag();

  const [value, setValue] = useState<string>('');
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [filterSelectedTags, setFilterSelectedTags] = useState<DropdownItemDataType[]>([]);
  const [isSetLoading, setIsSetLoading] = useState<boolean>(false);

  const tagsMapRef = useRef<Map<string, string>>(new Map());
  const pageRef = useRef<number>(0);
  const paginationInfoRef = useRef<PaginationInfoType | undefined>(result.data.paginationInfo);
  const inputRef = useRef<HTMLInputElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const initTags = (initialTags: CompanyTagWithoutCompanyIdType[]) => {
    const newTagIds: string[] = [];

    initialTags.forEach((tag) => {
      newTagIds.push(tag.id);
      tagsMapRef.current.set(tag.id, tag.title);
    });

    setTagIds(newTagIds);
  };

  const request = async (searchQuery: string) => {
    pageRef.current = 0;

    await requestTags(
      {
        companyId: companyIds[0],
        title: searchQuery,
      },
      { page: pageRef.current, pageSize: AUTOCOMPLETE_TAGS_PAGE_SIZE }
    );
  };

  const loadMore = async (searchQuery: string) => {
    const page = (paginationInfoRef.current?.page || 0) + 1;

    if (page <= pageRef.current) return;

    pageRef.current = page;

    await result.loadMore(
      {
        companyId: companyIds[0],
        title: searchQuery,
      },
      { page, pageSize: AUTOCOMPLETE_TAGS_PAGE_SIZE }
    );
  };

  const { run: debouncedRequest } = useDebounceFn((query: string) => request(query), OPTIONS);

  useEffect(() => {
    if (result.data.tags && result.data.tags.length !== 0) {
      result.data.tags.forEach((tag) => tagsMapRef.current.set(tag.id, tag.title));
    }

    if (result.data.paginationInfo) {
      paginationInfoRef.current = result.data.paginationInfo;
    }
  }, [result.data.tags]);

  useEffect(() => {
    const onResize = (event: Event) => {
      if (!isDesktop) {
        const visualViewport = event.target as VisualViewport;
        if (modalContentRef.current) {
          modalContentRef.current.style.setProperty(
            '--dropdown-margin-bottom',
            `${window.innerHeight - visualViewport.height}px`
          );
        }
      }
    };

    window.visualViewport?.addEventListener('resize', onResize);

    return () => window.visualViewport?.removeEventListener('resize', onResize);
  }, []);

  const resetTags = async () => {
    setValue('');

    pageRef.current = 0;
    await result.refetch({
      filters: { companyId: companyIds[0], title: '' },
      pagination: { page: pageRef.current, pageSize: AUTOCOMPLETE_TAGS_PAGE_SIZE },
    });
  };

  const handleTagAlreadyExists = async (errorMessage: string) => {
    let existingTag = result.data.tags?.find((tag) => tag.title.toLowerCase() === value.trim().toLowerCase());

    if (!existingTag) {
      const tagsResult = await requestTags(
        {
          companyId: companyIds[0],
          title: value.trim().toLowerCase(),
        },
        { page: pageRef.current, pageSize: AUTOCOMPLETE_TAGS_PAGE_SIZE }
      );

      existingTag = tagsResult.data?.companyTagPaginationQuery.result?.[0];
    }

    if (!existingTag) {
      existingTag = LinkedTagsModalUtils.getExistingTagByTitle(errorMessage, tagsMapRef.current, companyIds[0]);
    }

    tagsMapRef.current.set(existingTag.id, existingTag.title);

    if (!tagIds.includes(existingTag.id)) {
      const newTagIds = [...tagIds, existingTag.id];
      setTagIds(newTagIds);
      setIsChanged(true);
      await resetTags();
    } else {
      setValue('');
      await resetTags();
    }
  };

  const onAddTagSubmit = async () => {
    try {
      const createTagResult = await createTagRequest({
        companyId: companyIds[0],
        title: value.trim(),
      });

      if (createTagResult.isOk) {
        AnalyticsService.event(EventNameAMP.COMPANY_TAG_CREATED, {
          [ParamNameAMP.CREATING_TAG_RESULT]: OperationStatuses.SUCCESS,
        });

        tagsMapRef.current.set(createTagResult.tag?.id || '', createTagResult.tag?.title || '');
        const newTagIds = [...tagIds, createTagResult.tag?.id || ''];
        setTagIds(newTagIds);
        setIsChanged(true);
        await resetTags();
      }
    } catch (e) {
      AnalyticsService.event(EventNameAMP.COMPANY_TAG_CREATED, {
        [ParamNameAMP.CREATING_TAG_RESULT]: OperationStatuses.ERROR,
      });

      const error = e as Error;
      if (TAG_ALREADY_EXISTS_REGEXP.test(error.message)) {
        await handleTagAlreadyExists(error.message);
      }

      if (TAG_VALUE_TOO_LONG_REGEXP.test(error.message)) {
        notification.showNotification({
          type: NotificationColors.ERROR,
          children: TEXTS.MODAL_AUTOCOMPLETE_ADD_TOO_LONG_VALUE_FOR_TAG,
          delay: NOTIFICATION_DEFAULT_DELAY,
        });
      }
    } finally {
      inputRef?.current?.focus();
    }
  };

  const onAddTagClick = async () => {
    if (value.trim() === '') {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: TEXTS.MODAL_TAG_TITLE_CONTAIN_WHITE_SPACES_TEXT,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });

      setValue('');

      return;
    }

    await request(value);
    onAddTagSubmit();
  };

  const onLoadMore = (inView: boolean) => {
    if (inView) {
      loadMore(value);
    }
  };

  const onOptionClick = (tagId: string) => {
    setValue('');
    setIsChanged(true);

    const currentTagIdIndex = tagIds.indexOf(tagId);
    if (currentTagIdIndex !== -1) {
      const newTagIds = [...tagIds];
      newTagIds.splice(currentTagIdIndex, 1);
      setTagIds(newTagIds);
    } else {
      setTagIds([...tagIds, tagId]);
    }
  };

  const optionAddTag = useMemo(
    () => ({
      key: 'linked_tags_modal_add_tag',
      isMulti: false,
      onClick: onAddTagClick,
      content: <span className={styles.CompanyTagsAutocompleteAddTag}>{TEXTS.MODAL_AUTOCOMPLETE_ADD_TAG_OPTION}</span>,
    }),
    [tagIds, value]
  );

  const optionLoadMoreTags = useMemo(
    () => ({
      key: 'linked_tags_modal_load_more_tags',
      isMulti: false,
      onClick: () => {},
      content: (
        <div className={styles.CompanyTagsAutocompleteLoadMoreTags}>
          <InView
            onChange={onLoadMore}
            key="linked_tags_modal_load_more_in_view">
            <Spinner color={Colors.COLOR_ACCENT_500} />
          </InView>
        </div>
      ),
    }),
    []
  );

  const options: DropdownItemDataType[] = useMemo(() => {
    const currentTags = (result.data.tags || []).map((item: CompanyTagType) => ({
      key: item.id,
      selected: tagIds.includes(item.id),
      isMulti: true,
      onClick: () => onOptionClick(item.id),
      content: SuggestionUtils.createSuggestionPreview(item.title, value, 'mark'),
      value: item.title,
    }));

    if (value.length === 0) {
      const res: DropdownItemDataType[] = [...currentTags];

      if (result.data.paginationInfo?.hasNextPage) {
        res.push(optionLoadMoreTags);
      }

      return res;
    }

    return [optionAddTag, ...currentTags];
  }, [result.data.tags, tagIds]);

  const getFilterOptions = (filterFn: (tag: CompanyTagType) => boolean = () => true) => {
    const opts: DropdownItemDataType[] = (result.data.tags || []).reduce<DropdownItemDataType[]>((options, item) => {
      if (filterFn(item)) {
        options.push({
          key: item.id,
          selected: filterSelectedTags.some((tag) => tag.key === item.id),
          isMulti: true,
          onClick: () => {},
          content: SuggestionUtils.createSuggestionPreview(item.title, value, 'mark'),
          value: item.title,
        });
      }
      return options;
    }, []);

    if (result.data.paginationInfo?.hasNextPage) {
      opts.push(optionLoadMoreTags);
    }

    return opts;
  };

  const autocompletePlaceholder = useMemo(
    () => (isDesktop ? TEXTS.MODAL_AUTOCOMPLETE_PLACEHOLDER : TEXTS.MODAL_AUTOCOMPLETE_PLACEHOLDER_SHORT),
    [isDesktop]
  );

  const onChange = (value: string) => {
    setValue(value.length === 1 ? value.toUpperCase() : value);
    debouncedRequest(value);
  };

  const onRemoveTag = (currentTagId: string) => {
    const currentTagIdIndex = tagIds.indexOf(currentTagId);
    if (currentTagIdIndex !== -1) {
      const newTagIds = [...tagIds];
      newTagIds.splice(currentTagIdIndex, 1);
      setTagIds(newTagIds);
      setIsChanged(true);
    }
  };

  const onBackspacePressed = () => {
    if (value.length === 0) {
      const newTags = [...tagIds];
      newTags.pop();

      setTagIds(newTags);
      setIsChanged(true);
    }
  };

  const onEnterPressed = () => {
    if (value.length !== 0) {
      onAddTagClick();
    }
  };

  const onSetTagsSubmit = async (onSubmit: (companyTagIds: string[]) => Promise<void>) => {
    setIsSetLoading(true);
    await onSubmit(tagIds);
    await resetTags();
    setIsSetLoading(false);
  };

  const extensions = useMemo(
    () =>
      tagIds.map((tagId: string) => (
        <CompanyTagChip
          key={tagId}
          id={tagId}
          title={tagsMapRef.current.get(tagId) || ''}
          visibleTitleLength={isDesktop ? VISIBLE_TITLE_LENGTH : VISIBLE_TITLE_LENGTH_MOBILE}
          isDisabled={isSetLoading}
          onRemoveClick={() => onRemoveTag(tagId)}
        />
      )),
    [tagIds, isSetLoading]
  );

  return (
    <CompanyTagsContext.Provider
      value={{
        value,
        options,
        getFilterOptions,
        filterSelectedTags,
        setFilterSelectedTags,
        maxVisibleOptions: AUTOCOMPLETE_MAX_VISIBLE_OPTIONS,
        menuMargin: !isDesktop ? AUTOCOMPLETE_DROPDOWN_MENU_MARGIN : undefined,
        extensions,
        inputRef,
        modalContentRef,
        isLoading: result.loading,
        isAddTagLoading: createTagResult.loading,
        isSetLoading,
        isDesktop,
        isTagsSubmitDisabled: !isChanged,
        autocompletePlaceholder,
        initTags,
        onChange,
        onRemoveTag,
        onBackspacePressed,
        onEnterPressed,
        onSetTagsSubmit,
        tagsRequest: debouncedRequest,
      }}>
      {children}
    </CompanyTagsContext.Provider>
  );
};

export default CompanyTagsProvider;
