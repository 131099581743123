import { useEffect } from 'react';

import { useURLSearchParams } from 'application/providers/RouterProvider';
import ActivationService from 'application/services/ActivationService';
import { AliceQueryKeys, LoginQueryKeys } from 'domain/auth/types';

const useActivationFlow = () => {
  const [bindingCode, fromSource, state, redirectUri, responseType, clientId, scope] = useURLSearchParams([
    LoginQueryKeys.BINDING_CODE,
    LoginQueryKeys.FROM_SOURCE,
    AliceQueryKeys.STATE,
    AliceQueryKeys.REDIRECT_URI,
    AliceQueryKeys.RESPONSE_TYPE,
    AliceQueryKeys.CLIENT_ID,
    AliceQueryKeys.SCOPE,
  ]);

  useEffect(() => {
    if (bindingCode || clientId) {
      ActivationService.enableActivationFlow(bindingCode, fromSource, {
        state,
        redirectUri,
        responseType,
        clientId,
        scope,
      });
    }
  }, []);
};

export default useActivationFlow;
