import React, { useLayoutEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import useKeyDownCallback from '@zvuk-b2b/react-uikit/toolkit/useKeyDownCallback';
import { KeyboardEventCode } from '@zvuk-b2b/react-uikit/toolkit/types';

import { OverlayOptionsType } from './types';
import { DEFAULT_OPTS } from './consts';
import { OverlayContext } from './OverlayContext';

const OverlayProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [options, setOptions] = useState<OverlayOptionsType>({} as OverlayOptionsType);
  const [content, setContent] = useState<React.ReactNode>();
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

  const rootNode = useMemo(() => document.getElementById('root'), [])!;
  const portalNode = useMemo(() => document.getElementById('portal'), [])!;

  useLayoutEffect(() => {
    const onKeyDown = useKeyDownCallback<KeyboardEvent>((event) => event.preventDefault(), {
      include: [KeyboardEventCode.ENTER, KeyboardEventCode.TAB],
    });

    if (isDisplayed && options.enterLock) {
      rootNode.onkeydown = (event: KeyboardEvent) => onKeyDown(event);
    } else {
      rootNode.onkeydown = null;
    }
  }, [isDisplayed, options]);

  const contextValue = useMemo(
    () => ({
      isDisplayed,
      showOverlay: (content?: React.ReactNode, options: OverlayOptionsType = DEFAULT_OPTS) => {
        setContent(content);
        setIsDisplayed(true);
        setOptions(options);
      },
      hideOverlay: () => {
        setContent(undefined);
        setIsDisplayed(false);
        setOptions({} as OverlayOptionsType);
      },
    }),
    [isDisplayed, setContent, setIsDisplayed]
  );

  const portal = isDisplayed ? ReactDOM.createPortal(content, portalNode) : null;

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      {portal}
    </OverlayContext.Provider>
  );
};

export default OverlayProvider;
