import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

export const TRIAL_WARNING_PAGE_PATH = '/activation/trial';

const Renderer = React.lazy(() => import('./renderer'));

const TrialWarningPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

TrialWarningPageWrapper.config = {
  routes: [
    {
      path: TRIAL_WARNING_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default TrialWarningPageWrapper;
