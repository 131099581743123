import { useMemo } from 'react';
import {
  useAutocompleteSuggestionAddressLazyQuery,
  SuggestionAddressQueryPureType,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import {
  AutocompleteSuggestionAddressResultType,
  UseAutocompleteSuggestionAddressType,
  SuggestionAddressType,
} from './types';

const useAutocompleteSuggestionAddress = (): UseAutocompleteSuggestionAddressType => {
  const [query, queryResult] = useAutocompleteSuggestionAddressLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_FIRST,
  });

  const request = async (input: SuggestionAddressQueryPureType) => {
    await query({
      variables: { input },
    });
  };

  const userPureSuggestionAddressResult = queryResult.data?.userPureSuggestionAddress.result;

  const company: SuggestionAddressType[] = useMemo(
    () => (userPureSuggestionAddressResult || []).map((item) => item.value),
    [userPureSuggestionAddressResult]
  );

  const result: AutocompleteSuggestionAddressResultType = {
    error: queryResult.error,
    loading: queryResult.loading,
    data: { result: company },
  };

  return [request, result];
};

export default useAutocompleteSuggestionAddress;
