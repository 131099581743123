import React, { FC } from 'react';

import classnames from 'classnames';

import { MediaFileTypes } from 'application/pages/types';
import { PlayButton } from 'application/components/PlayButton';
import Explicit from '@zvuk-b2b/react-uikit/ui/icons/Explicit';
import styles from './index.module.scss';

import MediaListItemProgressSlider from './MediaListItemProgressSlider';
import { MediaListItemProps } from '../types';
import useMedialistItemController from './useMediaLitsItemController';

export const MediaListItem: FC<MediaListItemProps> = (props) => {
  const {
    isLoading,
    title,
    performerTitle,
    isMediafilePlaying,
    progress,
    isDisliked,
    children,
    isDemo,
    type,
    className,
    titleClassName,
    isPaused,
    explicit,
    onClickPlay,
    onClickPause,
    onClickResume,
    onChangeProgress,
  } = props;

  const { currentClickHandler } = useMedialistItemController({
    isPaused,
    progress,
    onClickPause,
    onClickPlay,
    onClickResume,
  });

  return (
    <button
      type="button"
      className={classnames(styles.MediaListItem, className, {
        [styles.MediaListItemPlaying]: isMediafilePlaying,
        [styles.MediaListItemDemo]: isDemo,
      })}
      id={isDemo ? 'stream_list_demo_item' : undefined}
      onClick={() => currentClickHandler()}>
      <PlayButton
        isLoading={isLoading}
        isMediafilePlaying={isMediafilePlaying}
        isMediaFilePaused={isPaused}
        isDemo={isDemo}
        className={classnames(styles.MediaListItemPlayButtonHover, {
          [styles.MediaListItemPlayButton]: isMediafilePlaying && isPaused,
        })}
        trackIconClassName={isDemo ? styles.MediaListItemDemoTrackIconHover : styles.MediaListItemTrackIconHover}
      />

      <div className={styles.MediaListItemText}>
        <h5
          className={classnames(
            styles.MediaListItemTextTitle,
            isDisliked ? styles.MediaListItemTextTitleFaded : '',
            titleClassName
          )}>
          {title}
        </h5>
        {performerTitle && (
          <div className={styles.MediaListItemTextPerformerTitleContainer}>
            {explicit && <Explicit />}
            <h5
              className={classnames(
                styles.MediaListItemTextPerformerTitle,
                isDisliked ? styles.MediaListItemTextPerformerTitleFaded : ''
              )}>
              {performerTitle}
            </h5>
          </div>
        )}
      </div>

      <div className={styles.MediaListItemTags}>{children}</div>

      {isMediafilePlaying && (
        <MediaListItemProgressSlider
          elementId=""
          onChange={onChangeProgress}
          progress={progress ?? 0}
          isDemo={isDemo}
          isPromo={type === MediaFileTypes.PROMO}
        />
      )}
    </button>
  );
};
