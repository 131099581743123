import React, { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';

import PhoneUtils from 'application/utils/PhoneUtils';
import PhoneInput from '@zvuk-b2b/react-uikit/ui/PhoneInput';
import Button from '@zvuk-b2b/react-uikit/ui/Button';

import styles from './index.module.scss';

export type PhoneInputFormErrors = {
  phone?: string;
};

export type PhoneInputFormInitData = {
  phone?: string;
};

export type PhoneInputFormProps = {
  className?: string;
  isLoading: boolean;
  inputLabel: string;
  inputPlaceholder: string;
  buttonContent: React.ReactNode;
  errors?: PhoneInputFormErrors;
  initData?: PhoneInputFormInitData;
  onSubmit: (phone: string, country: string) => void;
};

const PhoneInputForm = (props: PhoneInputFormProps) => {
  const { className, initData, isLoading, inputLabel, inputPlaceholder, buttonContent, errors, onSubmit } = props;
  const [phone, setPhone] = useState<string>(initData?.phone || '');

  const onSubmitWrapper = (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      const parsedValue = PhoneUtils.parsePhoneNumberFromString(phone);
      onSubmit(parsedValue?.number || phone, parsedValue?.country || '');
    } catch (e) {
      onSubmit(phone, '');
    }
  };

  return (
    <form
      className={classNames(styles.PhoneInputForm, className)}
      onSubmit={onSubmitWrapper}>
      <PhoneInput
        elementId="input_phone"
        className={styles.PhoneInputFormInput}
        initialValue={phone}
        onChange={(value) => setPhone(value as string)}
        error={errors?.phone}
        isDisabled={isLoading}
        label={inputLabel}
        placeholder={inputPlaceholder}
      />

      <Button
        elementId="button_phone_submit"
        className={styles.PhoneInputFormSubmitButton}
        isDisabled={isLoading}
        isLoading={isLoading}
        onClick={onSubmitWrapper}>
        {buttonContent}
      </Button>
    </form>
  );
};

export default PhoneInputForm;
