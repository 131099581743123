import React, { useEffect, useRef } from 'react';

import { useWebPlayerActionsContext, useWebPlayerContext } from 'application/providers/WebPlayerProvider';
import useMedia from '@zvuk-b2b/react-uikit/toolkit/useMedia';
import Spinner from '@zvuk-b2b/react-uikit/ui/Spinner';
import { Colors } from '@zvuk-b2b/react-uikit/ui/types';
import styles from './index.module.scss';
import WebPlayerDislikeButton from '../DislikeButton';
import WebPlayerSkipButton from '../SkipButton';

const WebPlayerActionsBlock = () => {
  const iframeWrapperRef = useRef<HTMLDivElement>(null);

  const { setIframeRef } = useWebPlayerActionsContext();

  const { loading, isWidgetActive, fileLoading } = useWebPlayerContext();

  const { isDesktop, isMobileXl } = useMedia();

  useEffect(() => {
    if (isWidgetActive) {
      setIframeRef(iframeWrapperRef.current);
    }
  }, [isWidgetActive, iframeWrapperRef.current]);

  if (!isDesktop && !isMobileXl) {
    return (
      <div
        ref={iframeWrapperRef}
        className={styles.frameContainer}>
        {loading && (
          <Spinner
            className={styles.spinner}
            color={Colors.COLOR_NEUTRAL_700}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.actionsWrapper}>
      <WebPlayerDislikeButton />
      <div
        ref={iframeWrapperRef}
        className={styles.frameContainer}>
        {(loading || fileLoading) && (
          <Spinner
            className={styles.spinner}
            color={Colors.COLOR_NEUTRAL_400}
          />
        )}
      </div>
      <WebPlayerSkipButton />
    </div>
  );
};

export default WebPlayerActionsBlock;
