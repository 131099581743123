import React, { FC, useState } from 'react';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import Radio from '@zvuk-b2b/react-uikit/ui/Radio';
import { TEXTS } from './texts';
import styles from './index.module.scss';

enum VideoPlayer {
  ANDROID_TV = 'ANDROID_TV',
  OTHER = 'OTHER',
}

const ChooseVideoPlayerModal: FC<ModalProps[ModalTypes.CHOOSE_VIDEO_PLAYER]> = ({ close, nextModalCallback }) => {
  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>(VideoPlayer.ANDROID_TV);

  const handleNext = () => {
    switch (videoPlayer) {
      case VideoPlayer.OTHER: {
        nextModalCallback?.(ModalTypes.SBERBUSSINESS_REQUEST);
        break;
      }
      case VideoPlayer.ANDROID_TV: {
        nextModalCallback?.(ModalTypes.DOWNLOAD_TV_APP);
        break;
      }

      default: {
        nextModalCallback?.(ModalTypes.DOWNLOAD_TV_APP);
      }
    }
  };

  return (
    <ModalScreen
      onClose={close}
      title={TEXTS.MODAL_TITLE}
      actionButtons={[
        <Button
          key="choose_video_player_modal_next"
          elementId="choose_video_player_modal_next"
          type={ButtonType.PRIMARY}
          onClick={handleNext}>
          {TEXTS.NEXT}
        </Button>,
      ]}>
      <div className={styles.ChooseVideoModalContent}>{TEXTS.MODAL_CONTENT}</div>
      <Radio
        className={styles.ChooseVideoModalRadio}
        isChecked={videoPlayer === VideoPlayer.ANDROID_TV}
        onChange={(value) => value && setVideoPlayer(VideoPlayer.ANDROID_TV)}
        elementId="radio_video_player_modal_tv"
        key={VideoPlayer.ANDROID_TV}>
        {TEXTS.ANDROID_TV}
      </Radio>
      <Radio
        isChecked={videoPlayer === VideoPlayer.OTHER}
        className={styles.ChooseVideoModalRadio}
        onChange={(value) => value && setVideoPlayer(VideoPlayer.OTHER)}
        elementId="radio_video_player_modal_other"
        key={VideoPlayer.OTHER}>
        {TEXTS.OTHER}
      </Radio>
    </ModalScreen>
  );
};

export default ChooseVideoPlayerModal;
