import { KEYS, useInjection, UseBusinessProfileRepositoryType } from 'application/providers/DIContainerProvider';
import { BusinessProfilePlaylistFormData } from 'application/pages/BusinessProfilePage/renderer/BusinessProfilePlaylistForm/types';
import { useAccountContext } from 'application/providers/AccountProvider';
import FileUtils from 'application/utils/FileUtils';

import {
  UserCompanyPureType,
  ZvukPlaylistCreateFromStreamMutationInput,
  ZvukPlaylistCreateFromZvukMutationInput,
  ZvukPlaylistUpdateMutationInput,
} from 'domain/api/graphql/generated';
import { BusinessProfilePlaylistPosterType } from 'application/repositories/businessProfile/types';

const DEFAULT_POSTER_URL = '/assets/img/business_profile/playlists/default_poster.png';

const getBusinessProfilePlaylistCreateFromStreamInput = (
  currentCompany: UserCompanyPureType,
  profileId: string,
  streamId: string,
  formData: BusinessProfilePlaylistFormData
): ZvukPlaylistCreateFromStreamMutationInput => ({
  companyId: currentCompany!.id,
  profileId,
  streamId,
  title: formData.title,
  description: formData.description,
  poster: formData.poster!.files[0],
});

const getBusinessProfilePlaylistCreateFromZvukInput = (
  currentCompany: UserCompanyPureType,
  profileId: string,
  playlistLink: string,
  formData: BusinessProfilePlaylistFormData
): ZvukPlaylistCreateFromZvukMutationInput => ({
  companyId: currentCompany!.id,
  profileId,
  playlistLink,
  title: formData.title,
  description: formData.description,
  poster: formData.poster!.files[0],
});

const getBusinessProfilePlaylistUpdateInput = (
  currentCompany: UserCompanyPureType,
  playlistId: string,
  formData: BusinessProfilePlaylistFormData
): ZvukPlaylistUpdateMutationInput => ({
  companyId: currentCompany!.id,
  playlistId,
  title: formData.title,
  description: formData.description,
  poster: formData.poster!.files[0],
});

const useBusinessProfileUpsertPlaylistModalPresenter = () => {
  const useBusinessProfileRepository = useInjection<UseBusinessProfileRepositoryType>(KEYS.BUSINESS_PROFILE_REPOSITORY);

  const {
    getBusinessProfile,
    refetchBusinessProfiles,
    getBusinessProfilePlaylist,
    removeSelectedBusinessProfilePlaylist,
    createPlaylistFromStream,
    createPlaylistFromStreamLoading,
    createPlaylistFromStreamError,
    createPlaylistFromStreamData,
    createPlaylistFromZvuk,
    createPlaylistFromZvukLoading,
    createPlaylistFromZvukError,
    createPlaylistFromZvukData,
    updatePlaylist,
    updatePlaylistLoading,
    updatePlaylistError,
    updatePlaylistData,
    hidePlaylist,
    hidePlaylistLoading,
    hidePlaylistError,
    removePlaylist,
    removePlaylistLoading,
    removePlaylistError,
    publishPlaylist,
    publishPlaylistLoading,
    publishPlaylistError,
  } = useBusinessProfileRepository();

  const { currentCompany } = useAccountContext();

  const getBusinessProfilePlaylistInitialFormData = async (
    targetBusinessProfilePlaylistId: string
  ): Promise<BusinessProfilePlaylistFormData> => {
    const data = getBusinessProfilePlaylist(targetBusinessProfilePlaylistId);

    const posterFiles = [];

    if (data && data.poster) {
      let posterFile;

      if (typeof data.poster === 'string') {
        posterFile = await FileUtils.getFileFromFileUrl(data.poster, 'poster.png');
      } else {
        const posterObject = data.poster as BusinessProfilePlaylistPosterType;
        posterFile = await FileUtils.getFileFromFileUrl(posterObject.url, posterObject.filename);
      }

      posterFiles.push(posterFile);
    } else {
      const posterFile = await FileUtils.getFileFromFileUrl(DEFAULT_POSTER_URL, 'poster.png');
      posterFiles.push(posterFile);
    }

    const defaultFormData: BusinessProfilePlaylistFormData = {
      title: data?.title || '',
      description: data?.description || '',
      poster: {
        files: posterFiles,
        fileRejections: [],
      },
      tracksCount: data?.tracksCount,
      playlistLink: data?.remotePlaylistLink,
    };

    return defaultFormData;
  };

  const onCreateBusinessProfilePlaylistFromStream = async (
    profileId: string,
    streamId: string,
    formData: BusinessProfilePlaylistFormData
  ) => {
    const input = getBusinessProfilePlaylistCreateFromStreamInput(currentCompany!, profileId, streamId, formData);

    const result = await createPlaylistFromStream(input);

    return result;
  };

  const onCreateBusinessProfilePlaylistFromZvuk = async (
    profileId: string,
    formData: BusinessProfilePlaylistFormData
  ) => {
    const input = getBusinessProfilePlaylistCreateFromZvukInput(
      currentCompany!,
      profileId,
      formData.playlistLink || '',
      formData
    );

    const result = await createPlaylistFromZvuk(input);

    return result;
  };

  const onUpdateBusinessProfilePlaylist = async (playlistId: string, formData: BusinessProfilePlaylistFormData) => {
    const input = getBusinessProfilePlaylistUpdateInput(currentCompany!, playlistId, formData);

    const result = await updatePlaylist(input);

    return result;
  };

  return {
    getBusinessProfilePlaylistInitialFormData,
    getBusinessProfile,
    refetchBusinessProfiles,
    removeSelectedBusinessProfilePlaylist,
    onCreateBusinessProfilePlaylistFromStream,
    createPlaylistFromStreamLoading,
    createPlaylistFromStreamError,
    createPlaylistFromStreamData,
    onCreateBusinessProfilePlaylistFromZvuk,
    createPlaylistFromZvukLoading,
    createPlaylistFromZvukError,
    createPlaylistFromZvukData,
    onUpdateBusinessProfilePlaylist,
    updatePlaylistLoading,
    updatePlaylistError,
    updatePlaylistData,
    hidePlaylist,
    hidePlaylistLoading,
    hidePlaylistError,
    removePlaylist,
    removePlaylistLoading,
    removePlaylistError,
    publishPlaylist,
    publishPlaylistLoading,
    publishPlaylistError,
    currentCompany,
  };
};

export default useBusinessProfileUpsertPlaylistModalPresenter;
