import React from 'react';

import { InputSize } from 'ui/Input';

export enum DropdownOrientation {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum DropdownPlacement {
  AUTO = 'auto',
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export type DropdownItemDataType = {
  content: React.ReactNode;
  key: React.Key;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  value?: string;
  isMulti?: boolean;
};

export type DropdownProps = {
  items: Array<DropdownItemDataType>;
  anchorNode: HTMLElement;
  parentNode?: HTMLElement;
  orientation?: DropdownOrientation;
  placement?: DropdownPlacement;
  elementId: string;
  className?: string;
  style?: React.CSSProperties;
  itemClassName?: string;
  itemContentClassName?: string;
  onClose: () => void;
  maxVisibleOptions?: number;
  menuMargin?: number;
  isFullMode?: boolean;
  isMulti?: boolean;
  isSearchLoading?: boolean;
  hasSearch?: boolean;
  searchInputSize?: InputSize;
  searchInputPlaceholder?: string;
  isNeedCloseWhileParentScrolling?: boolean;
  onSearchChange?: (value: string) => void;
  onSelect?: (item: DropdownItemDataType) => void;
  withoutWrapper?: boolean;
  hasSearchIcon?: boolean;
};

export type UseDropdownControllerProps = {
  initialStyle: DropdownProps['style'];
  anchorNode: DropdownProps['anchorNode'];
  parentNode: DropdownProps['parentNode'];
  items: DropdownProps['items'];
  orientation: DropdownProps['orientation'];
  placement: DropdownProps['placement'];
  menuMargin: DropdownProps['menuMargin'];
  isFullMode: DropdownProps['isFullMode'];
  onClose: DropdownProps['onClose'];
  onSearchChange: DropdownProps['onSearchChange'];
  maxVisibleOptions: number;
  isMulti: boolean;
  isNeedCloseWhileParentScrolling: boolean;
  withoutWrapper?: boolean;
};
