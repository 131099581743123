import { useStreamSearchTagsQueryLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

const useStreamTags = () => {
  const [request, streamTagsResult] = useStreamSearchTagsQueryLazyQuery({ fetchPolicy: FetchPolicyKeys.CACHE_FIRST });

  const streamTagsData = streamTagsResult.data?.result.tags;

  return {
    streamTagsResult: streamTagsData,
    isStreamTagsLoading: streamTagsResult.loading,
    request,
  };
};

export default useStreamTags;
