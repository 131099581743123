import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import CompanyTagChip from 'application/components/CompanyTagChip';
import { AdCampaignTypeEnum } from 'domain/media/types';
import React from 'react';
import ScheduleAdTypeMarker from '../../ScheduleAdTypeMarker';
import styles from '../index.module.scss';
import { AdCampaignsRadioGroupProps } from './types';

const AdCampaignsGroup = ({ title, adCampaigns, onSelect }: AdCampaignsRadioGroupProps) =>
  adCampaigns.length ? (
    <div>
      <div className={styles.AdCampaignsGroupTitle}>{title}</div>
      {adCampaigns.map((adCampaign) => (
        <Button
          elementId={`ad_campaign_button-${adCampaign.id}`}
          type={ButtonType.PLAIN}
          className={styles.AdCampaignsButton}
          onClick={() => onSelect(adCampaign)}>
          <ScheduleAdTypeMarker
            type={adCampaign.type === AdCampaignTypeEnum.ExactTime ? 'exact' : 'interval'}
            className={styles.AdCampaignsMarker}
          />
          <div className={styles.AdCampaignsInfo}>
            {adCampaign.title}
            {adCampaign.tags?.map((tag) => (
              <div className={styles.AdCampaignsChip}>
                <CompanyTagChip
                  id={tag.id}
                  title={tag.title}
                />
              </div>
            ))}
          </div>
        </Button>
      ))}
    </div>
  ) : null;

export default AdCampaignsGroup;
