export const TEXTS = {
  ADD_BUTTON: 'Добавить',
  SKIP_BUTTON: 'Пропустить',
  TITLE: 'Добавление новогодней музыки',
  DESCRIPTION_PART_ONE: 'У вас настроено подмешивание новогодних треков с ',
  DESCRIPTION_PART_TWO: ' до ',
  DESCRIPTION_PART_THREE: ' года.',
  DESCRIPTION_PART_FOUR: ' Соотношение обычных треков к новогодним будет ',
  DESCRIPTION_PART_FIVE: ' 50/50.',
  SUB_DESCRIPTION_TEXT: 'Добавить новогоднюю музыку и в эту волну?',
};
