/* eslint-disable no-console */
import { useCallback, useEffect, useRef, useState } from 'react';

const REPOSITION_DELAY = 150;

const usePlayerFrameReposition = (iframeWrapper: HTMLDivElement | null, isWidgetActive: boolean) => {
  const [top, setTop] = useState<number | undefined>(0);
  const [left, setLeft] = useState<number | undefined>(0);
  const repositionCallbackRef = useRef<() => void>();
  const repositionWithTimerRef = useRef<() => void>();
  const repositionTimerRef = useRef<ReturnType<typeof setTimeout> | null>();

  const reposition = useCallback(() => {
    if (iframeWrapper) {
      try {
        const rect = iframeWrapper.getBoundingClientRect();
        const newTop = isWidgetActive ? rect.top : rect.top + window.scrollY;
        const newLeft = rect.left;
        if (newTop) {
          setTop(newTop);
        }
        if (newLeft) {
          setLeft(newLeft);
        }
      } catch (e) {
        console.error(`${e}`);
      }
    }
  }, [iframeWrapper, isWidgetActive]);

  const repositionWithTimer = useCallback(() => {
    if (repositionTimerRef.current) {
      clearTimeout(repositionTimerRef.current);
    }

    repositionTimerRef.current = setTimeout(() => {
      reposition();
      repositionTimerRef.current = null;
    }, REPOSITION_DELAY);
  }, [reposition, repositionTimerRef.current]);

  useEffect(() => {
    reposition();
  });

  useEffect(() => {
    try {
      if (repositionCallbackRef.current) {
        window.removeEventListener('resize', repositionCallbackRef.current);
      }

      if (repositionWithTimerRef.current) {
        window.removeEventListener('scroll', repositionWithTimerRef.current);
      }

      window.addEventListener('resize', reposition);
      window.addEventListener('scroll', repositionWithTimer);
      repositionCallbackRef.current = reposition;
      repositionWithTimerRef.current = repositionWithTimer;

      reposition();
    } catch (e) {
      console.error(`${e}`);
    }

    return () => {
      if (repositionCallbackRef.current) {
        window.removeEventListener('resize', repositionCallbackRef.current);
      }

      if (repositionWithTimerRef.current) {
        window.removeEventListener('scroll', repositionWithTimerRef.current);
      }
    };
  }, [reposition, repositionWithTimer]);

  return {
    top,
    left,
  };
};

export default usePlayerFrameReposition;
