import React, { SyntheticEvent, useState } from 'react';
import classNames from 'classnames';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import Input, { InputType } from '@zvuk-b2b/react-uikit/ui/Input';

import styles from './index.module.scss';

export type CodeInputFormErrors = {
  code?: string;
};

export type CodeInputFormProps = {
  className?: string;
  isLoading: boolean;
  isCodeLoading: boolean;
  isCodeResendDisabled: boolean;
  inputLabel: string;
  inputPlaceholder: string;
  submitButtonContent: React.ReactNode;
  requestCodeButtonContent: React.ReactNode;
  errors?: CodeInputFormErrors;
  onSubmit: (code: string) => void;
  onRequestCode: () => void;
};

const CodeInputForm = (props: CodeInputFormProps) => {
  const {
    className,
    isLoading,
    isCodeLoading,
    isCodeResendDisabled,
    inputLabel,
    inputPlaceholder,
    submitButtonContent,
    requestCodeButtonContent,
    errors,
    onSubmit,
    onRequestCode,
  } = props;
  const [code, setCode] = useState<string>('');

  const onSubmitWrapper = (e: SyntheticEvent) => {
    e.preventDefault();
    onSubmit(code);
  };

  return (
    <form
      className={classNames(styles.CodeInputForm, className)}
      onSubmit={onSubmitWrapper}>
      <Input
        elementId="input_code"
        type={InputType.TEL}
        containerClassName={styles.CodeInputFormInput}
        maxLength={6}
        value={code}
        error={errors?.code}
        isDisabled={isLoading}
        label={inputLabel}
        placeholder={inputPlaceholder}
        onChange={setCode}
      />

      <Button
        elementId="button_submit_code"
        className={styles.CodeInputFormSubmitButton}
        isDisabled={isLoading}
        isLoading={isLoading}
        onClick={onSubmitWrapper}>
        {submitButtonContent}
      </Button>

      <Button
        elementId="button_resend_code"
        className={styles.CodeInputFormResendButton}
        type={ButtonType.SECONDARY}
        isLoading={isCodeLoading}
        isDisabled={isLoading || isCodeResendDisabled || isCodeLoading}
        onClick={() => onRequestCode()}>
        {requestCodeButtonContent}
      </Button>
    </form>
  );
};

export default CodeInputForm;
