import React from 'react';

import { SPECIAL_REGISTER_PAGE_PATH } from 'application/consts';
import SpecialRegisterPageWrapper from 'application/pages/SpecialRegisterPage';
import CleanLayout from 'application/layout/CleanLayout';

import { RouteConfig } from '..';

export const specialRegisterRoutes: RouteConfig[] = [
  {
    element: <CleanLayout />,
    children: [
      {
        path: SPECIAL_REGISTER_PAGE_PATH,
        element: <SpecialRegisterPageWrapper />,
      },
    ],
  },
];
