import React, { useCallback } from 'react';

import IconHelpCircle from '@zvuk-b2b/react-uikit/ui/icons/HelpCircle';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';

import { BusinessProfileFormImagesHintType } from '../types';

import styles from './index.module.scss';

export type ImageUploaderFieldDescriptionProps = {
  elementId: string;
  text: string;
  type: BusinessProfileFormImagesHintType;
  onOpenHint?: (type: BusinessProfileFormImagesHintType) => void;
};

const ImageUploaderFieldDescription = ({ elementId, type, text, onOpenHint }: ImageUploaderFieldDescriptionProps) => {
  const handleOpenHint = useCallback(() => {
    onOpenHint?.(type);
  }, [onOpenHint, type]);

  return (
    <div
      key={elementId}
      className={styles.ImageUploaderFieldDescription}>
      <span>{text}</span>
      <IconButton
        elementId={elementId}
        onClick={handleOpenHint}>
        <IconHelpCircle />
      </IconButton>
    </div>
  );
};

export default ImageUploaderFieldDescription;
