import React, { Suspense } from 'react';

import { IApplicationPage } from 'application/pages/types';
import PAGES_CONST from 'application/pages/constants';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';

const Renderer = React.lazy(() => import('./renderer'));

export const PASSWORD_RESET_PAGE_PATH = '/password-reset';
export const PASSWORD_RESET_PAGE_PATH_ALIAS = '/restore-password/set-new-password';

export enum PasswordResetQueryKeys {
  UID = 'uid',
  TOKEN = 'token',
}

const PasswordResetPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

PasswordResetPageWrapper.config = {
  routes: [
    {
      path: PASSWORD_RESET_PAGE_PATH,
    },
    {
      path: PASSWORD_RESET_PAGE_PATH_ALIAS,
    },
  ],
  access: [LocalAccessRoles.ANONYMOUS],
};

export default PasswordResetPageWrapper;
