import { useMemo, useState } from 'react';
import { useDebounceFn } from 'ahooks';

import SuggestionUtils from 'application/utils/SuggestionUtils';
import useAutocompleteSuggestionAddress from 'domain/autocomplete/useAutocompleteSuggestionAddress';
import { SuggestionAddressType } from 'domain/autocomplete/types';
import { DropdownItemDataType } from '@zvuk-b2b/react-uikit/ui/Dropdown';
import { Country, SuggestionAddressQueryPureType } from 'domain/api/graphql/generated';

const REQUEST_DELAY = 500;

const OPTIONS = { wait: REQUEST_DELAY };

export type UseDebouncedSuggestionAddressType = {
  value: string;
  loading: boolean;
  options: DropdownItemDataType[];
  onChange: (value: string) => void;
};

type UseAddressStateProps = {
  callback?: (item: string) => void;
  location?: Country;
};

function useAddressState({ callback, location }: UseAddressStateProps): UseDebouncedSuggestionAddressType {
  const [value, setValue] = useState<string>('');

  const [request, result] = useAutocompleteSuggestionAddress();
  const items = result.data.result;

  const { run: debouncedRequest } = useDebounceFn((input: SuggestionAddressQueryPureType) => request(input), OPTIONS);

  const options: DropdownItemDataType[] = useMemo(
    () =>
      Array.from(new Set(items), (item: SuggestionAddressType) => ({
        key: item,
        onClick: () => {
          setValue(item);
          callback?.(item);
        },
        content: SuggestionUtils.createSuggestionPreview(item, value, 'mark'),
      })),
    [items, callback]
  );

  const onChange = (value: string) => {
    setValue(value);

    if (value) {
      const input: SuggestionAddressQueryPureType = {
        query: value,
      };

      if (location === Country.Ru || location === Country.Kz) {
        input.country = location;
      }

      debouncedRequest(input);
    }
  };

  return {
    value,
    options,
    onChange,
    loading: result.loading,
  };
}

export default useAddressState;
