import React from 'react';

import { Country } from 'domain/api/graphql/generated';
import styles from '../index.module.scss';
import { TEXTS } from './texts';

interface CertificateNoAvailableHintProps {
  showEmptyPointsModal: boolean;
  isBillingEnabled?: boolean;
  showBillingModal: boolean;
  showEmptyPointsTrialModal: boolean;
  currentCompanyCountry?: Country;
}

const CertificateNoAvailableHint = ({
  showEmptyPointsModal,
  currentCompanyCountry,
  isBillingEnabled,
  showBillingModal,
  showEmptyPointsTrialModal,
}: CertificateNoAvailableHintProps) => (
  <>
    {showEmptyPointsModal && (
      <div className={styles.CertificateModalEmptyStateContainer}>
        <div>
          {showEmptyPointsTrialModal ? TEXTS.EMPTY_POINTS_TRIAL_NO_RU_DESCRIPTION : TEXTS.EMPTY_POINTS_DESCRIPTION}
        </div>
        {!isBillingEnabled && !showEmptyPointsTrialModal && (
          <div>{TEXTS.EMPTY_POINTS_DESCRIPTION_CONNECT_WITH_MANAGER}</div>
        )}
      </div>
    )}

    {showBillingModal && (
      <div>
        {currentCompanyCountry === Country.Ru
          ? TEXTS.EMPTY_PAID_POINTS_DESCRIPTION_RU
          : TEXTS.EMPTY_PAID_PONTS_DESCRIPTION_OTHER}
      </div>
    )}
  </>
);

export default CertificateNoAvailableHint;
