import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type ChipProps = React.PropsWithChildren<{
  elementId: string;
  dataTestId?: string;
  className?: string;
  adornmentClassName?: string;
  contentClassName?: string;
  isActive?: boolean;
  isClickable?: boolean;
  isDisabled?: boolean;
  adornmentLeft?: React.ReactNode;
  adornmentRight?: React.ReactNode;
  onClick?: (value: boolean) => void;
}>;

const Chip = forwardRef<HTMLSpanElement, ChipProps>(
  (
    {
      elementId,
      className,
      adornmentClassName,
      contentClassName,
      isActive: isActiveProp = false,
      isClickable = false,
      isDisabled = false,
      adornmentLeft,
      adornmentRight,
      onClick,
      children,
      dataTestId = elementId,
    }: ChipProps,
    ref
  ) => {
    const [isActive, setIsActive] = useState<boolean>(isActiveProp);

    const handleClick = () => {
      if (isClickable && !isDisabled) {
        onClick?.(!isActive);
      }
    };

    useEffect(() => {
      setIsActive(isActiveProp);
    }, [isActiveProp]);

    return (
      <span
        ref={ref}
        role="none"
        id={elementId}
        key={elementId}
        data-testid={dataTestId}
        className={classNames(styles.Chip, className, {
          [styles.ChipActive]: isActive,
          [styles.ChipClickable]: isClickable,
        })}
        onClick={handleClick}
        data-disabled={isDisabled}>
        {adornmentLeft && (
          <div className={classNames(styles.ChipAdornment, styles.ChipAdornmentL, adornmentClassName)}>
            {adornmentLeft}
          </div>
        )}

        <span
          className={classNames(styles.ChipContent, contentClassName)}
          data-adornment-left={!!adornmentLeft}
          data-adornment-right={!!adornmentRight}
          data-testid={`${elementId}-chip-content`}>
          {children}
        </span>

        {adornmentRight && (
          <div className={classNames(styles.ChipAdornment, styles.ChipAdornmentR, adornmentClassName)}>
            {adornmentRight}
          </div>
        )}
      </span>
    );
  }
);

export default Chip;
