export const TEXTS = {
  MODAL_ADD_TITLE: 'Добавление тега',
  MODAL_CHANGE_TITLE: 'Изменение тега',
  MODAL_TEXT:
    'Отмечайте тегом музыкальную зону, ответственного или город, чтобы быстро фильтровать адреса или рекламные кампании.',
  MODAL_AUTOCOMPLETE_LABEL: 'Тег',
  MODAL_AUTOCOMPLETE_PLACEHOLDER: 'Выберите или напишите новый тег',
  MODAL_AUTOCOMPLETE_PLACEHOLDER_SHORT: 'Выберите или напишите тег',
  MODAL_AUTOCOMPLETE_ADD_TAG_OPTION: 'Добавить тег',
  MODAL_AUTOCOMPLETE_ADD_TOO_LONG_VALUE_FOR_TAG: 'Максимальная длина в названии тега - 50',
  MODAL_CONFIRM_BUTTON: 'Сохранить',
  MODAL_TAG_TITLE_CONTAIN_WHITE_SPACES_TEXT: 'Тег не содержит символов',
};
