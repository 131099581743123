import Input, { InputType } from '@zvuk-b2b/react-uikit/ui/Input';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import FiltersTags from 'application/components/MainToolbar/FiltersTool/FiltersTags';
import CompanyTagsProvider from 'application/components/common/CompanyTagsProvider';
import { withProviders } from 'application/helpers';
import { CommonModalProps } from 'application/providers/ModalProvider/modalsProps';
import React from 'react';
import { TEXTS } from '../texts';
import AdCampaignsGroup from './AdCampaignsGroup';
import useAdCampaignsModalController, { AdCampaignsGroupName } from './adCampaignsModalController';
import styles from './index.module.scss';
import { ScheduleAdCampaignsModalProps } from './types';

const ScheduleAdCampaignsModal = ({ adCampaigns, onShow, close }: ScheduleAdCampaignsModalProps & CommonModalProps) => {
  const { adCampaignGroups, adCampaignGroupTitles, filterAdCampaignTags, onSearchChange, onTagFilterChange } =
    useAdCampaignsModalController({
      adCampaigns,
    });

  return (
    <ModalScreen
      title={TEXTS.ALL_AD_CAMPAIGNS_TITLE}
      titlePlacement="left"
      onClose={close}
      containerClassName={styles.AdCampaignsModal}
      contentClassName={styles.AdCampaigns}
      containerActionButtonsClassName={styles.AdCampaignsActionButtons}>
      <div className={styles.AdCampaignsTopBar}>
        <Input
          placeholder={TEXTS.AD_CAMPAIGNS_MODAL_SEARCH}
          elementId="ad_campaigns_search"
          type={InputType.TEXT}
          onChange={onSearchChange}
        />
        <FiltersTags
          pageType="ad_campaign"
          onChange={onTagFilterChange}
          filterTagsFn={filterAdCampaignTags}
        />
      </div>
      <div className={styles.AdCampaignsList}>
        {Object.keys(adCampaignGroups).map((groupKey) => (
          <AdCampaignsGroup
            title={adCampaignGroupTitles[groupKey as AdCampaignsGroupName]}
            adCampaigns={adCampaignGroups[groupKey as AdCampaignsGroupName]}
            onSelect={onShow}
          />
        ))}
      </div>
    </ModalScreen>
  );
};

export default withProviders(ScheduleAdCampaignsModal, [CompanyTagsProvider]);
