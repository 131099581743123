import React from 'react';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import { ModalProps } from 'application/providers/ModalProvider/modalsProps';
import { ModalTypes } from 'application/providers/ModalProvider';
import { TEXTS } from './texts';
import styles from './index.module.scss';

export interface MultiroomInfoModalProps {
  masterBindingCode?: string;
  slaveBindingCodes?: string[];
}

const MultiroomInfoModal: React.FC<ModalProps[ModalTypes.MULTIROOM_INFO_MODAL]> = ({
  close,
  masterBindingCode,
  slaveBindingCodes = [],
}) => (
  <ModalScreen
    title={`${TEXTS.MODAL_INFO_TITLE}: ${slaveBindingCodes.length + 1}`}
    hideCloseButton
    className={styles.MultiroomInfoModal}
    actionButtons={[
      <Button
        key="button_close_multiroom_info_modal"
        elementId="button_close_multiroom_info_modal"
        type={ButtonType.PRIMARY}
        onClick={close}>
        {TEXTS.MODAL_CLOSE_BUTTON_TEXT}
      </Button>,
    ]}>
    {TEXTS.MODAL_DESCRIPTION}
    <ul data-testid="desktop_player_modal_installation_guide_list">
      <li>
        {TEXTS.SBERBOOM}
        <span className={styles.MultiroomInfoModalBindingCode}>{masterBindingCode}</span>
      </li>
      {slaveBindingCodes.map((bindingCode) => (
        <li key={bindingCode}>
          {TEXTS.SBERBOOM}
          <span className={styles.MultiroomInfoModalBindingCode}>{bindingCode}</span>
        </li>
      ))}
    </ul>
  </ModalScreen>
);

export default MultiroomInfoModal;
