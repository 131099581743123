import React, { FC } from 'react';

import { ModalTypes, ModalProps } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import Info, { InfoTypes } from '@zvuk-b2b/react-uikit/ui/Info';
import { DeviceBindStatus } from 'application/presenters/point/types';
import useSelectPlayerModalAdapter from './useSelectPlayerModalAdapter';
import useSelectPlayerModalController from './useSelectPlayerModalController';
import { TEXTS } from './texts';

import styles from './index.module.scss';
import { SelectPlayerFlowType } from '../types';
import { DeviceTypesInFlow } from './types';
import PlayerTypeRadio from './PlayerTypeRadio';

const SelectPlayerModal: FC<ModalProps[ModalTypes.SELECT_PLAYER_MODAL]> = ({
  targetStreamId,
  isTemplateMode,
  onPrevious,
  onConfirmClose,
  onNext,
  point,
  flowType,
}) => {
  const {
    isChangeStreamLoading,
    isCopyStreamTemplateLoading,
    isUserFeaturesOnboardingPassLoading,
    onboardingPassedRequest,
    refetchFeaturesOnboarding,
    country,
  } = useSelectPlayerModalAdapter();

  const { selectedPlayer, playersData, confirmSelectedPlayerLoading, onPlayerChange, handleClick } =
    useSelectPlayerModalController({
      targetStreamId,
      isTemplateMode,
      onboardingPassedRequest,
      refetchFeaturesOnboarding,
      onNext,
      country,
    });

  const pointDevice = point?.device?.type as DeviceTypesInFlow | undefined;
  const pointDeviceBinded = (point?.device?.bindStatus === DeviceBindStatus.BINDED && pointDevice) || undefined;
  const haveBindedDevice =
    flowType === SelectPlayerFlowType.CHANGE_DEVICE && !!point?.stream?.id && !!pointDeviceBinded;
  const isLoading =
    isChangeStreamLoading ||
    isCopyStreamTemplateLoading ||
    isUserFeaturesOnboardingPassLoading ||
    confirmSelectedPlayerLoading;

  const modalTitle = haveBindedDevice ? TEXTS.MODAL_WARNING_TITLE : TEXTS.MODAL_UPDATED_TITLE;

  return (
    <ModalScreen
      title={modalTitle}
      titlePlacement="left"
      onClose={() => onConfirmClose?.()}
      actionButtons={[
        <Button
          key="button_select_player_success"
          elementId="button_select_player_success"
          type={ButtonType.PRIMARY}
          isLoading={isLoading}
          onClick={handleClick}>
          {haveBindedDevice ? TEXTS.SUCCESS_CHANGE_BUTTON : TEXTS.SUCCESS_BUTTON}
        </Button>,
        <Button
          key="button_select_player_cancel"
          elementId="button_select_player_cancel"
          type={ButtonType.SECONDARY}
          isLoading={isLoading}
          onClick={onPrevious}>
          {TEXTS.CANCEL_BUTTON}
        </Button>,
      ]}>
      {point?.address && <span>{TEXTS.MODAL_ADDRESS_TITLE(point.address)}</span>}

      {haveBindedDevice && (
        <Info
          className={styles.SelectPlayerModalWarning}
          type={InfoTypes.ERROR}>
          {TEXTS.CHANGE_DEVICE_TYPE_WARNING}
        </Info>
      )}

      {playersData.map((player) => (
        <PlayerTypeRadio
          player={player}
          selectedPlayer={selectedPlayer}
          pointDevice={pointDeviceBinded}
          onPlayerChange={onPlayerChange}
          haveBindedDevice={haveBindedDevice}
          key={player.type}
        />
      ))}
    </ModalScreen>
  );
};

export default SelectPlayerModal;
