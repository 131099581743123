import { ZvukProfileType, useZvukProfileListLazyQuery } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { UseBusinessProfilesResult } from './types';

const POLLING_INTERVAL = 10000;

const useBusinessProfiles = (): UseBusinessProfilesResult => {
  const [query, queryResult] = useZvukProfileListLazyQuery({
    fetchPolicy: FetchPolicyKeys.CACHE_AND_NETWORK,
  });

  const request = async (companyId: string) => {
    const result = await query({
      variables: {
        companyId,
      },
    });

    return (result.data?.zvukProfileList || []) as ZvukProfileType[];
  };

  return {
    request,
    error: queryResult.error,
    isLoading: queryResult.loading,
    profiles: (queryResult.data?.zvukProfileList || []) as ZvukProfileType[],
    startPolling: queryResult.startPolling,
    stopPolling: queryResult.stopPolling,
    defaultPollingInterval: POLLING_INTERVAL,
    refetchBusinessProfiles: queryResult.refetch,
  };
};

export default useBusinessProfiles;
