import React from 'react';

import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { useWebPlayerContext } from 'application/providers/WebPlayerProvider';

import { EPlayState } from '@player-core/use-player-frame';
import { SILENT_MEDIAFILE_ID } from 'application/consts';
import { TEXTS } from '../texts';
import WebPlayerPureDislikeButton from '../PureDisikeButton';

const getDislikeButtonHint = (isDisabled: boolean, playState: EPlayState, isConnected: boolean) => {
  if (isDisabled) {
    if (playState !== EPlayState.PLAY) {
      return TEXTS.PLAY_FOR_REMOVE;
    }
    if (!isConnected) {
      return TEXTS.REMOVE_DISABLED_OFFILE;
    }
    return TEXTS.PLAYER_PROCESSING;
  }
  return undefined;
};

const WebPlayerDislikeButton: React.VFC = () => {
  const { mediaFile, streamId, actions, actionButtonsDisabled, playState, isConnected } = useWebPlayerContext();

  const notification = useNotificationContext();

  const onDislikeClick = () => {
    actions?.dislike();
    notification.showNotification({
      type: NotificationColors.INFO,
      children: TEXTS.TRACK_REMOVED,
      delay: NOTIFICATION_DEFAULT_DELAY,
    });
  };

  const isSilentTrack = mediaFile?.id?.toString() === SILENT_MEDIAFILE_ID.toString();

  return (
    <WebPlayerPureDislikeButton
      id={mediaFile?.id}
      streamId={streamId}
      onDislike={onDislikeClick}
      isDisabled={actionButtonsDisabled || isSilentTrack}
      hint={getDislikeButtonHint(actionButtonsDisabled, playState, isConnected)}
    />
  );
};

export default WebPlayerDislikeButton;
