import React, { useState } from 'react';
import classNames from 'classnames';

import PrivacyPolicy, { PrivacyPolicyMode } from 'application/components/PrivacyPolicy';
import { useAccountContext } from 'application/providers/AccountProvider';
import { RouterUtils } from 'application/providers/RouterProvider';
import { LOGIN_PAGE_PATH, REDIRECT_PAGE_PATH } from 'application/consts';
import useSBBID from 'domain/auth/useSBBID';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import IconRenderer from '@zvuk-b2b/react-uikit/ui/icons/IconRenderer';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';

import icon from './sber.svg';

import styles from './index.module.scss';

const TEXTS = {
  SBBID_ANAVAILABLE_ERROR: 'Авторизация через СберБизнес ID временно недоступна',
  SBBID_MODAL_TITLE: 'Регистрация через СберБизнес ID',
  SBBID_MODAL_BUTTON: 'Зарегистрироваться',
};

export type ButtonWrapperProps = {
  elementId: string;
  title: string;
  isDisabled: boolean;
  onClick?: () => void;
};

const ButtonWrapper = ({ elementId, title, isDisabled, onClick }: ButtonWrapperProps) => (
  <Button
    elementId={`button_${elementId}`}
    isDisabled={isDisabled}
    className={styles.SBBIDContainerButton}
    type={ButtonType.SECONDARY}
    onClick={onClick}>
    <IconRenderer
      iconHtml={icon}
      className={styles.SBBIDContainerButtonIcon}
    />
    {title}
  </Button>
);

export type SBBIDButtonProps = {
  className?: string;
  elementId: string;
  title: string;
  error?: string;
  withModal: boolean;
  onClick?: () => void;
};

const SBBIDButton = (props: SBBIDButtonProps) => {
  const { title, error, onClick, withModal, className, elementId } = props;

  const { currentCompany } = useAccountContext();

  const redirectPagePath = RouterUtils.generatePath(REDIRECT_PAGE_PATH, { from: LOGIN_PAGE_PATH });
  const SBBIDResult = useSBBID({
    redirectPath: `${window.location.hostname}${redirectPagePath}`,
  });

  const SBBIDErrorText = SBBIDResult.error?.message ? TEXTS.SBBID_ANAVAILABLE_ERROR : error;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickSBBID = () => {
    onClick?.();
    window.location.href = SBBIDResult.data.uri!;
  };

  const onModalOpen = () => {
    if (!SBBIDResult.data.uri) return;

    if (withModal) {
      setIsModalOpen(true);
    } else {
      onClickSBBID();
    }
  };

  return (
    <div className={classNames(styles.SBBIDContainer, className)}>
      <ButtonWrapper
        elementId={`${elementId}_main_button`}
        isDisabled={!!SBBIDResult.error}
        title={title}
        onClick={onModalOpen}
      />

      {SBBIDErrorText && (
        <span
          id={`${elementId}_errormessage`}
          className={styles.SBBIDContainerError}>
          {SBBIDErrorText}
        </span>
      )}

      {isModalOpen && (
        <ModalScreen
          title={TEXTS.SBBID_MODAL_TITLE}
          onClose={() => setIsModalOpen(false)}
          actionButtons={[
            <Button
              type={ButtonType.PRIMARY}
              elementId={`${elementId}_modal_button`}
              isDisabled={!!SBBIDResult.error}
              className={styles.SBBIDContainerButton}
              onClick={onClickSBBID}>
              {TEXTS.SBBID_MODAL_BUTTON}
            </Button>,
          ]}>
          <PrivacyPolicy
            className={styles.SBBIDContainerPrivacyPolicy}
            mode={PrivacyPolicyMode.SIGN_UP}
            country={currentCompany?.country}
          />
        </ModalScreen>
      )}
    </div>
  );
};

export default SBBIDButton;
