import React, { useState, useMemo, FC, useEffect } from 'react';
import { useAccountContext } from 'application/providers/AccountProvider';
import useDebounceFn from 'ahooks/lib/useDebounceFn';
import { getStreamsCategoryPath, StreamsCategoryParamValues } from 'application/pages/StreamPage';
import { FN_OPTIONS } from 'application/consts';
import queryString from 'query-string';
import {
  ActiveTagType,
  SearchQueryParamsType,
  SearchRequestType,
  StreamSearchContextProps,
  StreamSearchContextValueType,
} from './types';
import { useNavigate, useLocation } from '../RouterProvider';
import { KEYS, UseStreamRepositoryType, useInjection } from '../DIContainerProvider';
import { getFlattenTags, getTagIds } from './utils';
import { StreamSearchContext } from './StreamSearchContext';

const StreamSearchProvider: FC<StreamSearchContextProps> = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { currentCompany, isReadyToUseLK } = useAccountContext();
  const useStreamRepository = useInjection<UseStreamRepositoryType>(KEYS.STREAM_REPOSITORY);
  const {
    streamTags,
    isStreamTagsLoading,
    streamsSearchData,
    collectionSearchData,
    isStreamSearchFetching,
    isCollectionsSearchFetching,
    isSearchLoading,
    searchStreams,
    loadMoreStreams,
    loadMoreCollections,
    getStreamTags,
  } = useStreamRepository();

  const [searchText, setSearchText] = useState<string>('');
  const [localIsLoading, setLocalIsLoading] = useState(false);
  const [activeTags, setActiveTags] = useState<Map<string, ActiveTagType>>(new Map());

  const companyId = currentCompany?.id;

  const { run: debouncedSearchRequest } = useDebounceFn(async (value: string, filters?: string[]) => {
    await searchStreams({ companyId: companyId!, textQuery: value, filters });
    setLocalIsLoading(false);
  }, FN_OPTIONS);

  const setQuery = (value?: string, tags?: string[]) =>
    navigate(
      {
        pathname: getStreamsCategoryPath(StreamsCategoryParamValues.ALL),
        search: queryString.stringify({ search: value || undefined, tag: tags }),
      },
      { replace: true }
    );

  const request = async ({ search, tags }: SearchRequestType) => {
    setLocalIsLoading(true);
    const tagIds = getTagIds(tags);

    setQuery(search, tagIds);

    if (!search && !tagIds.length) {
      setLocalIsLoading(false);
      return;
    }

    debouncedSearchRequest(search, tagIds);
  };

  const onSearchChange = (value: string) => {
    const tags = Array.from(activeTags.values());
    setSearchText(value);
    request({ search: value, tags });
  };

  const setTags = (tags: Map<string, ActiveTagType>) => {
    const tagsArray = Array.from(tags.values());

    setActiveTags(tags);
    request({ search: searchText, tags: tagsArray });
  };

  const removeTag = (tag: ActiveTagType) => {
    const newTagsMap = new Map(activeTags);
    newTagsMap.delete(tag.id);
    const tags = Array.from(newTagsMap.values());

    setActiveTags(newTagsMap);
    request({ search: searchText, tags });
  };

  const clearTags = () => {
    const newMap = new Map();

    setActiveTags(newMap);
    request({ search: searchText, tags: [] });
  };

  const loadMoreSearchStreamsHandler = () => {
    if (isStreamSearchFetching) return;

    const { search = '' }: SearchQueryParamsType = queryString.parse(location.search);
    const tags = Array.from(activeTags.values());
    const ids = getTagIds(tags);
    const filters = ids.length ? ids : undefined;

    loadMoreStreams({ companyId: companyId!, textQuery: search, filters });
  };

  const loadMoreSearchCollectionsHandler = () => {
    if (isCollectionsSearchFetching) return;

    const { search = '' }: SearchQueryParamsType = queryString.parse(location.search);

    loadMoreCollections(companyId!, search);
  };

  const allStreamTags = useMemo(() => getFlattenTags(streamTags), [streamTags]);

  useEffect(() => {
    if (isReadyToUseLK && !streamTags?.length) {
      getStreamTags();
    }
  }, [isReadyToUseLK, streamTags]);

  useEffect(() => {
    if (!allStreamTags?.length) return;

    const { search = '', tag }: SearchQueryParamsType = queryString.parse(location.search);
    const queryStringTags = new Set(([] as string[]).concat(tag || []));
    const filteredTags = allStreamTags.filter((tag) => queryStringTags.has(tag.id));

    const newMap = new Map();
    filteredTags.forEach((tag) => newMap.set(tag.id, tag));

    if ((filteredTags.length || search) && !isSearchLoading) {
      setSearchText(search);
      setActiveTags(newMap);
      request({ search, tags: filteredTags });
    }
  }, [allStreamTags, location.search]);

  useEffect(() => {
    const locationState = location.state as { search: string };

    // state from navbar button
    const isResetAction = locationState?.search === 'reset';
    const isSearchTextOrActiveTags = searchText.length || activeTags.size;

    if (isResetAction && isSearchTextOrActiveTags) {
      setSearchText('');
      setActiveTags(new Map());
    }
  }, [location.state]);

  const streamModel = useMemo(
    (): StreamSearchContextValueType => ({
      activeTags,
      streamTags,
      isStreamTagsLoading,
      streamsSearchData,
      collectionSearchData,
      isStreamSearchFetching,
      isCollectionsSearchFetching,
      isSearchLoading: isSearchLoading || localIsLoading,
      searchText,
      setTags,
      removeTag,
      onSearchChange,
      loadMoreStreams: loadMoreSearchStreamsHandler,
      loadMoreCollections: loadMoreSearchCollectionsHandler,
      clearTags,
    }),
    [
      activeTags,
      streamTags,
      isStreamTagsLoading,
      streamsSearchData,
      collectionSearchData,
      isStreamSearchFetching,
      isCollectionsSearchFetching,
      isSearchLoading,
      localIsLoading,
      searchText,
      onSearchChange,
      loadMoreSearchStreamsHandler,
      loadMoreSearchCollectionsHandler,
      setTags,
      removeTag,
      clearTags,
    ]
  );

  return <StreamSearchContext.Provider value={streamModel}>{children}</StreamSearchContext.Provider>;
};

export default StreamSearchProvider;
