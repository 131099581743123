import React from 'react';
import classNames from 'classnames';

import Radio from '@zvuk-b2b/react-uikit/ui/Radio';
import styles from './index.module.scss';

import { DEVICE_TO_PLAYER_TYPE, DeviceTypesInFlow, PlayerRadioType, PlayerTypesInFlow } from '../types';
import { TEXTS } from '../texts';

type PlayerTypeRadioProps = {
  player: PlayerRadioType;
  selectedPlayer: PlayerTypesInFlow;
  pointDevice: DeviceTypesInFlow | undefined;
  onPlayerChange: (playerType: PlayerTypesInFlow) => void;
  haveBindedDevice: boolean;
};

const PlayerTypeRadio = ({
  player,
  selectedPlayer,
  pointDevice,
  onPlayerChange,
  haveBindedDevice,
}: PlayerTypeRadioProps) => {
  const isBindedType = pointDevice && player.type === DEVICE_TO_PLAYER_TYPE[pointDevice];
  const showBindedNote = haveBindedDevice && isBindedType;

  return (
    <Radio
      onChange={(_value, _event) => {
        onPlayerChange(player.type);
      }}
      key={`stream_bind_select_player_${player.type}`}
      elementId={`stream_bind_select_player_${player.type}`}
      isChecked={player.type === selectedPlayer}
      className={styles.PlayerTypeRadioItem}>
      <div className={styles.PlayerTypeRadioItemTitle}>{player.title}</div>
      <div
        className={classNames(
          styles.PlayerTypeRadioItemDescription,
          showBindedNote && styles.PlayerTypeRadioItemDescriptionBinded
        )}>
        {showBindedNote ? TEXTS.CHOSEN_DEVICE_TYPE : player.description}
      </div>
    </Radio>
  );
};

export default PlayerTypeRadio;
