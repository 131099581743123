import React from 'react';

import { PointsListCheckboxGroupPointType } from 'application/components/PointItemContent/types';

import IconMapPin from '@zvuk-b2b/react-uikit/ui/icons/MapPin';

import styles from './index.module.scss';

export type PointAddressesListProps = {
  point: PointsListCheckboxGroupPointType;
};

const PointAddressItem = ({ point }: PointAddressesListProps) => (
  <div
    key={point.id}
    className={styles.PointAddressesListRow}>
    <IconMapPin className={styles.PointAddressesListIcon} />
    <span>{point.address || point.title}</span>
  </div>
);

export default PointAddressItem;
