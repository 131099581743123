import React, { useEffect, useMemo, useRef, useState } from 'react';

import LayoutToaster from 'application/layout/LayoutToaster';
import TopBanner from 'application/components/widgets/TopBanner';
import useTopBannerState from 'application/components/widgets/TopBanner/useTopBannerState';
import { BILLING_PAGE_PATH_ALIAS } from 'application/pages/BillingPage';
import WebPlayerWidget from 'application/components/widgets/WebPlayer';
import { useWebPlayerContext } from 'application/providers/WebPlayerProvider';
import { useLocation, Outlet } from 'application/providers/RouterProvider';

import SmartMenu from './SmartMenu';

import styles from './index.module.scss';

const CSS_VAR_BANNER_HEIGHT = '--banner-height';

type SmartLayoutState = {
  isMenuOpen: boolean;
  bannerHeight: number;
  showMenu: () => void;
  hideMenu: () => void;
};

const SmartLayoutContext = React.createContext({} as SmartLayoutState);

export const useSmartLayoutContext = () => React.useContext(SmartLayoutContext);

const SmartLayout = () => {
  const topBannerState = useTopBannerState();
  const location = useLocation();

  const ref = useRef(null);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);

  const contextValue = useMemo(
    () => ({
      isMenuOpen,
      bannerHeight,
      showMenu: () => setMenuOpen(true),
      hideMenu: () => setMenuOpen(false),
    }),
    [isMenuOpen, bannerHeight]
  );

  useEffect(() => {
    let res: ResizeObserver | undefined;
    const headerRefCurrent = ref.current;

    if (headerRefCurrent) {
      res = new ResizeObserver((entries) => {
        const height = entries[0]?.target.clientHeight || 0;
        document.documentElement.style.setProperty(CSS_VAR_BANNER_HEIGHT, `${height}px`);
        setBannerHeight(entries[0]?.target.clientHeight || 0);
      });

      res.observe(headerRefCurrent);
    }

    return () => {
      if (res && headerRefCurrent) {
        res.unobserve(headerRefCurrent);
      }

      if (res) {
        res.disconnect();
      }
    };
  }, []);

  const { isWidgetActive } = useWebPlayerContext();

  return (
    <SmartLayoutContext.Provider value={contextValue}>
      <section className={styles.SmartLayoutMenu}>
        <div className={styles.SmartLayoutMenuRelative}>
          <div className={`${styles.SmartLayoutMenuContainer} ${isWidgetActive && styles.SmartLayoutMenuWithPlayer}`}>
            <SmartMenu
              isMenuOpen={isMenuOpen}
              showMenu={() => setMenuOpen(true)}
              hideMenu={() => setMenuOpen(false)}
            />
          </div>
        </div>
      </section>

      <header
        ref={ref}
        className={styles.SmartLayoutHeader}>
        <div
          className={styles.SmartLayoutHeaderBannerContainer}
          data-expanded={topBannerState.isVisible}>
          {topBannerState.banner && (
            <TopBanner
              banner={topBannerState.banner}
              onClose={topBannerState.onClose}
              isBillingPage={location.pathname.includes(BILLING_PAGE_PATH_ALIAS)}
              country={topBannerState.country}
            />
          )}
        </div>
      </header>

      <div className={styles.SmartLayout}>
        <LayoutToaster />

        <Outlet />
      </div>
      {isWidgetActive && <WebPlayerWidget />}
    </SmartLayoutContext.Provider>
  );
};

export default SmartLayout;
