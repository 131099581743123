import React from 'react';
import classNames from 'classnames';

import { PreviewDescriptionPlacement } from 'ui/FileUploader/types';

import { PreviewImageListProps } from '../types';
import ImageToolbox from './ImageToolbox';

import styles from './index.module.scss';

const PreviewImageList = ({
  elementId,
  previewImages,
  previewImagesContainerClassName,
  previewImagesContainerWithSelectedImagesClassName,
  previewImageClassName,
  previewToolboxClassName,
  previewDescription,
  previewDescriptionPlacement = PreviewDescriptionPlacement.BOTTOM,
  withImageFilename = false,
  withEditButton,
  withRemoveButton,
  isUploadButtonVisible,
  handleImageFileUploaderOpen,
  handleFileRemove,
}: PreviewImageListProps) => (
  <div
    id={elementId}
    data-testid={elementId}
    className={classNames(
      styles.PreviewImageListContainer,
      `${!isUploadButtonVisible ? styles.PreviewImageListContainerWithoutUploadButton : undefined}`,
      `${previewImages.size !== 0 ? previewImagesContainerWithSelectedImagesClassName : undefined}`,
      previewImagesContainerClassName
    )}>
    {[...previewImages.values()].map((file) => {
      const src = URL.createObjectURL(file);

      return (
        <div
          key={`${file.name}_of_image_with_${elementId}`}
          className={styles.PreviewImageListContainerWithDescription}
          data-placement={previewDescriptionPlacement}>
          <ImageToolbox
            elementId={`${file.name}_of_image_with_${elementId}`}
            previewImageClassName={previewImageClassName}
            previewToolboxClassName={previewToolboxClassName}
            withImageFilename={withImageFilename}
            withEditButton={withEditButton}
            withRemoveButton={withRemoveButton}
            imageFile={file}
            imageSrc={src}
            handleImageFileUploaderOpen={handleImageFileUploaderOpen}
            handleFileRemove={handleFileRemove}
          />
          <div>{previewDescription}</div>
        </div>
      );
    })}
  </div>
);

export default PreviewImageList;
