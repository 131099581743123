import React, { Suspense } from 'react';
import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';
import { LocalAccessRoles } from 'application/providers/AuthProvider';
import { IApplicationPage } from '../types';
import PAGES_CONST from '../constants';

export const REPORTS_PAGE_PATH = '/reports';

const Renderer = React.lazy(() => import('./renderer'));

const ReportsPageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <Renderer />
  </Suspense>
);

ReportsPageWrapper.config = {
  routes: [
    {
      path: REPORTS_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default ReportsPageWrapper;
