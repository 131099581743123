import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export type IconProps = React.HTMLAttributes<HTMLElement>;

export type IconRendererProps = IconProps & {
  iconHtml:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string;
          titleId?: string;
          desc?: string;
          descId?: string;
        }
      >
    | string;
};

const IconRenderer = ({ className, style, iconHtml: IconHtml }: IconRendererProps) => (
  <div
    style={style}
    className={classNames(styles.IconRenderer, className)}>
    {typeof IconHtml === 'string' ? IconHtml : <IconHtml />}
  </div>
);

export default IconRenderer;
