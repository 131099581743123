import { useEffect, useRef } from 'react';

import {
  useInjection,
  KEYS,
  UseStreamRepositoryType,
  UseBillingRepositoryType,
  UsePointRepositoryType,
} from 'application/providers/DIContainerProvider';
import { useAccountContext } from 'application/providers/AccountProvider';
import { DEFAULT_PAGINATION_INFO, TARIFFS } from 'application/pages/constants';
import CompanyUtils from 'application/utils/CompanyUtils';
import { StreamBindPointsProviderFilterType } from 'application/pages/StreamPage/providers/StreamBindPointsProvider/types';

import useUserFeaturesOnboardingPass from 'domain/media/useUserFeaturesOnboardingPass';
import useUserFeaturesOnboarding from 'domain/media/useUserFeaturesOnboarding';
import { PointQueryFilter, UserFeatures } from 'domain/api/graphql/generated';
import { usePointModelContext } from 'application/pages/PointsPage/providers/PointModelProvider';

const useStreamBindModalPresenter = () => {
  const useStreamRepository = useInjection<UseStreamRepositoryType>(KEYS.STREAM_REPOSITORY);
  const useBillingRepository = useInjection<UseBillingRepositoryType>(KEYS.BILLING_REPOSITORY);
  const usePointRepository = useInjection<UsePointRepositoryType>(KEYS.POINT_REPOSITORY);

  const { currentCompany, companyIds } = useAccountContext();

  const {
    isChangeStreamLoading,
    isCopyStreamTemplateLoading,
    changeStream,
    copyTemplateStream,
    getSavedStreams,
    getStream,
    readStreamWithThematic,
    clearStreamWithThematic,
  } = useStreamRepository();

  const { getBillingInfo, watchBillingInfo } = useBillingRepository();

  const billingInfo = watchBillingInfo(currentCompany!.id);

  const { setCurrentPoint, readCurrentPoint } = usePointRepository();

  const { request: onboardingPassedRequest, result: onboardingPassedResult } = useUserFeaturesOnboardingPass();
  const {
    items: userOnboardingFeatures,
    refetch: refetchFeaturesOnboarding,
    loading: userOnboardingFeaturesLoading,
  } = useUserFeaturesOnboarding({
    features: [UserFeatures.WarningStreamFirstStart],
  });

  const {
    points,
    pointsLoading,
    pointsCalled,
    paginationInfo,
    filters,
    allPointsCount,
    requestPoints,
    loadMorePoints,
    resetFilters,
  } = usePointModelContext();

  const filtersRef = useRef<PointQueryFilter>(filters);

  const onFilterExecute = async (filters: StreamBindPointsProviderFilterType) => {
    const preparedFilters: PointQueryFilter = {
      companyIds,
    };

    const { query } = filters;

    if (query !== undefined) {
      preparedFilters.query = query;
      filtersRef.current.query = query;
    }

    if (!preparedFilters.query && filtersRef.current.query) {
      preparedFilters.query = filtersRef.current.query;
    }

    await requestPoints(preparedFilters);
  };

  const isLoading =
    isChangeStreamLoading ||
    isCopyStreamTemplateLoading ||
    onboardingPassedResult.loading ||
    userOnboardingFeaturesLoading ||
    pointsLoading;

  const isPopularTariff = CompanyUtils.compareTariff(currentCompany?.pricePlan?.alias ?? '', TARIFFS.POPULAR);
  const isCustomInfoShouldShow = !userOnboardingFeatures[0]?.isOnboarded && isPopularTariff;
  const isBillingEnabled = currentCompany?.isBillingEnabled;

  const isReady = pointsCalled && (!pointsLoading || points.length !== 0);
  const isFilterQueryEmpty = !filters.query;
  const isEmpty = isReady && points.length === 0 && isFilterQueryEmpty;
  const isEmptyFiltered = isReady && points.length === 0 && !isFilterQueryEmpty;

  useEffect(() => {
    if (isBillingEnabled) {
      getBillingInfo(currentCompany?.id!);
    }

    return () => resetFilters();
  }, []);

  useEffect(() => {
    if (filters) {
      filtersRef.current = filters;
    }
  }, [filters]);

  return {
    isReady,
    isEmpty,
    isEmptyFiltered,
    points,
    pointsLoading,
    paginationInfo: paginationInfo || DEFAULT_PAGINATION_INFO,
    allPointsCount,
    isCustomInfoShouldShow,
    isLoading,
    userOnboardingFeaturesLoading,
    isDenyCreatePoint: CompanyUtils.isDenyCreatePoint(billingInfo.canRealizeBillingOperation, currentCompany),
    onFilterExecute,
    requestPoints,
    loadMorePoints,
    changeStream,
    copyTemplateStream,
    getSavedStreams,
    getStream,
    onboardingPassedRequest,
    refetchFeaturesOnboarding,
    setCurrentPoint,
    readCurrentPoint,
    readStreamWithThematic,
    clearStreamWithThematic,
  };
};

export default useStreamBindModalPresenter;
