import { AuthTokenType, UserDataType } from 'domain/auth/types';

export enum AuthMessageKeys {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}
export enum LocalAccessRoles {
  AUTHORIZED = 'AUTHORIZED',
  ANONYMOUS = 'ANONYMOUS',
}

export type AuthDataInputType = {
  authToken?: AuthTokenType | null;
  userData?: UserDataType;
};

export type AuthContextType = {
  accessRole: LocalAccessRoles;
  authToken: AuthTokenType | undefined | null;
  userData: UserDataType | undefined;
  setAuthData: (value: AuthDataInputType) => void;
  hasAccess: boolean;
  hasPersonalAccountAccess: boolean;
  getCaptchaToken: () => string | null;
  setCaptchaToken: (token: string | null) => void;
};
