import { useRef, useState } from 'react';

import {
  MediafileEventTypes,
  StreamMediafilesEventsSort,
  useMediaDislikedMediafilesPaginationLazyQuery,
} from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';
import { MediafileType } from 'application/components/MediaList/types';
import { NetworkStatus } from '@apollo/client';

const PAGE_SIZE = 50;

const useDislikedMediafiles = () => {
  const [isReloading, setIsReloading] = useState(true);

  const [requestDislikedMediafiles, { data, error, loading, called, fetchMore, networkStatus }] =
    useMediaDislikedMediafilesPaginationLazyQuery({
      fetchPolicy: FetchPolicyKeys.CACHE_FIRST,
      notifyOnNetworkStatusChange: true,
    });

  const ref = useRef(0);
  const isFetching = networkStatus === NetworkStatus.fetchMore;
  const dislikedMediafiles = data?.streamDislikedMediafiles;
  const uniqueDislikes = new Set<string>();
  const mediafiles = [] as MediafileType[];

  const request = async (streamId: string) => {
    ref.current = 0;
    setIsReloading(true);

    await requestDislikedMediafiles({
      variables: {
        filters: { streamId, eventType: MediafileEventTypes.Dislike },
        sort: StreamMediafilesEventsSort.CreatedDesc,
        pagination: {
          pageSize: PAGE_SIZE,
          page: 0,
        },
      },
    });

    setIsReloading(false);
  };

  async function loadMore(streamId: string, pageSize = PAGE_SIZE) {
    if (loading || isFetching || !dislikedMediafiles?.paginationInfo) return;

    await fetchMore({
      variables: {
        filters: { streamId },
        pagination: {
          page: (dislikedMediafiles.paginationInfo.page ?? 0) + 1,
          pageSize,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        streamDislikedMediafiles: {
          ...prev?.streamDislikedMediafiles,
          paginationInfo: fetchMoreResult.streamDislikedMediafiles.paginationInfo,
          result: [
            ...(prev.streamDislikedMediafiles.result || []),
            ...(fetchMoreResult?.streamDislikedMediafiles.result || []),
          ],
        },
      }),
    });
  }

  dislikedMediafiles?.result.forEach((dislakedMedia) => {
    if (!uniqueDislikes.has(dislakedMedia.mediafile.id)) {
      uniqueDislikes.add(dislakedMedia.mediafile.id);
      mediafiles.push({
        ...dislakedMedia.mediafile,
        mediafileIsDisliked: true,
      });
    }
  });

  return {
    error,
    loading,
    isReloading,
    request,
    called,
    loadMore,
    data: {
      mediafiles: mediafiles || undefined,
      paginationInfo: dislikedMediafiles?.paginationInfo,
    },
  };
};

export default useDislikedMediafiles;
