import { useMemo, useRef, useState } from 'react';

import { usePointHistoryLazyQuery, PointHistoryQuerySort, PointPureHistoryItem } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { NetworkStatus } from '@apollo/client';
import { PointHistoryItem, UsePointBroadcastHistoryType } from './types';

const PAGE_SIZE = 50;
const POLLING_INTERVAL = 180000;

const usePointBroadcastHistory = (): UsePointBroadcastHistoryType => {
  const [isReloading, setIsReloading] = useState(true);
  const ref = useRef(0);

  const [requestHistory, { data, error, loading, fetchMore, networkStatus }] = usePointHistoryLazyQuery({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
    pollInterval: POLLING_INTERVAL,
    notifyOnNetworkStatusChange: true,
  });

  const isFetching = networkStatus === NetworkStatus.fetchMore;
  const hasNextPage = data?.pointHistoryPaginationQuery.paginationInfo.hasNextPage;

  const request = async (pointId: string, dateFrom: string, dateTo: string) => {
    ref.current = 0;
    setIsReloading(true);

    await requestHistory({
      variables: {
        filters: { pointId, onAirRange: { dateFrom, dateTo } },
        pagination: { page: 0, pageSize: PAGE_SIZE },
        sort: PointHistoryQuerySort.OnAirDesc,
      },
    });

    setIsReloading(false);
  };

  const loadMore = async (pointId: string, dateFrom: string, dateTo: string) => {
    if (isFetching || loading || !hasNextPage) {
      return;
    }

    const page = (data?.pointHistoryPaginationQuery.paginationInfo.page || 0) + 1;

    await fetchMore({
      variables: {
        filters: { pointId, onAirRange: { dateFrom, dateTo } },
        pagination: { page, pageSize: PAGE_SIZE },
        sort: PointHistoryQuerySort.OnAirDesc,
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        pointHistoryPaginationQuery: {
          paginationInfo: {
            ...prev.pointHistoryPaginationQuery.paginationInfo,
            ...fetchMoreResult.pointHistoryPaginationQuery.paginationInfo,
          },
          result: [
            ...(prev.pointHistoryPaginationQuery.result || []),
            ...(fetchMoreResult.pointHistoryPaginationQuery.result || []),
          ],
        },
      }),
    });
  };

  const history: PointHistoryItem[] = useMemo(() => {
    if (data?.pointHistoryPaginationQuery.result) {
      return data?.pointHistoryPaginationQuery.result.map((item) => {
        if (item.mediafile) {
          const { explicit, filename, id, title, type, duration, sourcefiles } = item.mediafile;

          const performerTitle = type === 0 ? item.mediafile.performerTitle : '';

          return {
            id: item.id,
            mediafileId: id,
            filename,
            title,
            performerTitle,
            type,
            playtime: item.playtime,
            onAir: item.onAir,
            onAirWithLocalTimezone: item.onAirWithLocalTimezone,
            mediafileIsDisliked: item.mediafileIsDisliked,
            isLiked: item.mediafileIsLiked,
            explicit,
            duration,
            streamId: item.streamId,
            sourcefiles,
          };
        }

        // TODO refactor this
        const performerTitle = item.mediafileType === 0 ? item.mediafilePerformerTitle : '';
        const sourcefiles = (item as PointPureHistoryItem).mediafile?.sourcefiles;

        return {
          id: item.id,
          mediafileId: item.mediafileId,
          filename: item.mediafileFilename,
          title: item.mediafileTitle,
          performerTitle,
          type: item.mediafileType,
          playtime: item.playtime,
          onAir: item.onAir,
          onAirWithLocalTimezone: item.onAirWithLocalTimezone,
          mediafileIsDisliked: item.mediafileIsDisliked,
          isLiked: item.mediafileIsLiked,
          streamId: item.streamId,
          sourcefiles,
        };
      });
    }

    return [];
  }, [data?.pointHistoryPaginationQuery.result]);

  return {
    request,
    loadMore,
    isReloading,
    error,
    loading,
    data: {
      history,
      paginationInfo: data?.pointHistoryPaginationQuery.paginationInfo,
    },
  };
};

export default usePointBroadcastHistory;
