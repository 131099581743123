import React, { Suspense } from 'react';

import PAGES_CONST from 'application/pages/constants';
import { IApplicationPage } from 'application/pages/types';
import { LocalAccessRoles } from 'application/providers/AuthProvider';

import LoadingScreen from '@zvuk-b2b/react-uikit/ui/LoadingScreen';
import PlayerProvider from 'application/providers/PlayerProvider';
import AdCampaignModelProvider from '../AdsPage/providers/AdCampaignModelProvider';
import PointModelProvider from '../PointsPage/providers/PointModelProvider';

const Renderer = React.lazy(() => import('./renderer'));

export const BROADCAST_SCHEDULE_PAGE_PATH = '/broadcast-schedule/:id/:adCampaignId?';

export type BroadcastSchedulePageParamsType = {
  id?: string;
  adCampaignId?: string;
};

const BroadcastSchedulePageWrapper: IApplicationPage = () => (
  <Suspense fallback={<LoadingScreen delay={PAGES_CONST.LOADER_SCREEN_DELAY} />}>
    <PlayerProvider>
      <AdCampaignModelProvider>
        <PointModelProvider>
          <Renderer />
        </PointModelProvider>
      </AdCampaignModelProvider>
    </PlayerProvider>
  </Suspense>
);

BroadcastSchedulePageWrapper.config = {
  routes: [
    {
      path: BROADCAST_SCHEDULE_PAGE_PATH,
    },
  ],
  access: [LocalAccessRoles.AUTHORIZED],
};

export default BroadcastSchedulePageWrapper;
