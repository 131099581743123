import { useInjection, KEYS, UseStreamRepositoryType } from 'application/providers/DIContainerProvider';

const useStreamRemoveModalAdapter = () => {
  const useStreamRepository = useInjection<UseStreamRepositoryType>(KEYS.STREAM_REPOSITORY);

  const { isStreamRemoveLoading, removeStream, getSavedStreams } = useStreamRepository();

  return {
    isStreamRemoveLoading,
    removeStream,
    getSavedStreams,
  };
};

export default useStreamRemoveModalAdapter;
