import { LOGIN_PAGE_PATH } from 'application/consts';
import { matchPath, useLocation, useNavigate } from 'application/providers/RouterProvider';

const useRedirect = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const isRoot = matchPath({ path: '/', end: true }, location.pathname);

  if (isRoot) {
    navigate(LOGIN_PAGE_PATH, { replace: true });
  }
};

export default useRedirect;
