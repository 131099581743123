export const TEXTS = {
  BUSINESS_PROFILE_IMAGES_HINT_MODAL_POSTER_TITLE: 'Размеры обложки',
  BUSINESS_PROFILE_IMAGES_HINT_MODAL_BANNER_WEB_TITLE: 'Размеры баннера для ПК',
  BUSINESS_PROFILE_IMAGES_HINT_MODAL_BANNER_MOBILE_TITLE: 'Размеры баннера для телефонов',

  BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_TEXT: 'Размер обложки 1520x200px. ',
  BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_BASE: '1520x200px',
  BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_MEDIUM: '1366x200px',
  BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_IOS: 'iOS',
  BUSINESS_PROFILE_IMAGES_HINT_POSTER_SIZE_ANDROID: 'Android',

  BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_SIZE_TEXT: '1520x160px',
  BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_SIZE_BASE: '1520x160px',
  BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_SIZE_MEDIUM: '1366x160px',

  BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_SIZE_TEXT: '3600x2300px. ',

  BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_1:
    'Чтобы обложка хорошо смотрелась, учитывайте, как она будет обрезана на меньших размерах экрана.',
  BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_2:
    'На ПК высота всегда сохраняется 200px, а ширина обрезается по краям в зависимости от ширины экрана.',
  BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_3:
    'На мобильных экранах показывается центральная часть обложки: 204х80px на iOS и 208х56px на Android. Логотип смещается по центру.',
  BUSINESS_PROFILE_IMAGES_HINT_POSTER_DESCRIPTION_4: 'Советуем делать обложку без надписей и мелких деталей.',

  BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_DESCRIPTION_1: 'Размер баннера для больших экранов компьютеров ',
  BUSINESS_PROFILE_IMAGES_HINT_BANNER_WEB_DESCRIPTION_2:
    'Высота всегда сохраняется 160px, а ширина — обрезается по краям в зависимости от ширины экрана.',

  BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_DESCRIPTION_1: 'Размер баннера для мобильных телефонов ',
  BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_DESCRIPTION_2:
    'Можно меньше, но сохраняя пропорции, — минимальный размер 180х115px.',
  BUSINESS_PROFILE_IMAGES_HINT_BANNER_MOBILE_DESCRIPTION_3:
    'На Android баннер обрезается по высоте 3600х2000px (180x100px):',

  BUSINESS_PROFILE_IMAGES_HINT_MODAL_BUTTON_TEXT: 'Понятно',
};
