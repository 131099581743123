import React from 'react';

import { ButtonType } from 'ui/Button';
import { ImageFileErrorCode } from './ImageUploader/types';
import { AudioFileErrorCode } from './AudioUploader/types';

export type Accept = {
  [key: string]: string[];
};

export enum FileErrorCode {
  FileInvalidType = 'file-invalid-type',
  FileTooLarge = 'file-too-large',
  FileTooSmall = 'file-too-small',
  FileMustBe = 'file-must-be',
  TooManyFiles = 'too-many-files',
}

export type FileError<T> = {
  message: string;
  code: T | string;
};

export type FileRejection<T> = {
  file: File;
  errors: FileError<T>[];
};

export type FileUploaderSizeValidationType = {
  size?: number;
  minSize?: number;
  maxSize?: number;
  sizeErrorText: string;
  minSizeErrorText: string;
  maxSizeErrorText: string;
};

export type FileUploaderProps = {
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  elementId: string;
  className?: string;
  uploadButtonClassName?: string;
  accept?: Accept;
  multiple?: boolean;
  label?: React.ReactNode;
  description?: React.ReactNode;
  isLoading?: boolean;
  uploadButtonType?: ButtonType;
  uploadButtonContent?: React.ReactNode;
  uploadButtonAdornmentLeft?: React.ReactNode;
  uploadButtonAdornmentRight?: React.ReactNode;
  isUploadButtonVisible?: boolean;
  hasError?: boolean;
  onOpenFileUploader?: () => void;
  onChange: (files: File[], event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel?: () => void;
};

export type UseFileUploaderControllerProps = {
  elementId: FileUploaderProps['elementId'];
  inputRef?: FileUploaderProps['inputRef'];
  onOpenFileUploader?: FileUploaderProps['onOpenFileUploader'];
  onChange: FileUploaderProps['onChange'];
  onCancel?: FileUploaderProps['onCancel'];
};

export type ChangeEventArgsType<T> = {
  files: File[];
  fileRejections: FileRejection<T>[];
  event?: React.ChangeEvent<HTMLInputElement>;
};

export enum PreviewDescriptionPlacement {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export type AllFileErrorCodeType = FileErrorCode | ImageFileErrorCode | AudioFileErrorCode;

export type RejectedFileListProps<T extends File, K extends AllFileErrorCodeType = FileErrorCode> = {
  fileRejections: Map<string, FileRejection<K>>;
  withRemoveButton?: boolean;
  getUploadedFileDescription: (file: T) => string;
  handleRejectedFileRemove?: (filename: string) => void;
  elementId: string;
};

export type UseFilesActionsHelperProps<T extends File, K extends AllFileErrorCodeType = FileErrorCode> = {
  value?: ChangeEventArgsType<K> | null;
  onChange?: (args: ChangeEventArgsType<K>) => void;
  getFileRejection: (file: T) => FileRejection<K> | null;
  getFilesFromEvent: (event: React.ChangeEvent<HTMLInputElement>) => Promise<T[]>;
};
