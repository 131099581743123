import { useAuthTokenRetrieveMutation, AuthCurrentUserDocument } from 'domain/api/graphql/generated';
import { FetchPolicyKeys } from 'domain/api/graphql/types';

import { AuthTokenType, LoginInputType, UserDataType } from './types';

const useLogin = () => {
  const [mutation, mutationResult] = useAuthTokenRetrieveMutation({
    fetchPolicy: FetchPolicyKeys.NETWORK_ONLY,
  });

  const request = async (input: LoginInputType) => {
    const mutationResult = await mutation({
      variables: { input },
      update: (cache, result) => {
        cache.writeQuery({
          id: `UserExtendPureType:${result.data?.auth?.user.id}`,
          query: AuthCurrentUserDocument,
          data: {
            result,
          },
        });
      },
    });

    return {
      token: mutationResult.data?.auth?.token.value as AuthTokenType,
      user: mutationResult.data?.auth?.user as UserDataType,
    };
  };

  return {
    error: mutationResult.error,
    loading: mutationResult.loading,
    called: mutationResult.called,
    request,
  };
};

export default useLogin;
