import {
  FieldValue,
  FieldValues,
  RegisterOptions,
  ValidationRule,
  ValidationValue,
  ValidationValueMessage,
} from 'react-hook-form';

import { TEXTS } from '../texts';
import { FieldRulesType } from './types';

class FieldUtils {
  static getMaxLengthError = (maxLength: ValidationRule<number>, value: FieldValue<FieldValues>) => {
    const maxLengthValidation = maxLength as ValidationValueMessage<ValidationValue>;

    if (
      maxLengthValidation.value !== undefined &&
      maxLengthValidation.message !== undefined &&
      value.length > maxLengthValidation.value
    ) {
      return maxLengthValidation.message;
    }

    const maxLengthValue = maxLength as ValidationValue;

    return String(maxLengthValue);
  };

  static formatError = (
    invalid: boolean,
    value: FieldValue<FieldValues>,
    rules?: FieldRulesType,
    error?: string | boolean
  ) => {
    if (error) return error;

    if (invalid && rules && rules.maxLength) {
      const errors: string[] = [];

      if (rules.maxLength && value.length !== 0) {
        const err = FieldUtils.getMaxLengthError(rules.maxLength, value);
        errors.push(err);
      }

      if (rules.required && errors.length === 0) {
        const err = typeof rules.required === 'string' ? rules.required : TEXTS.TEXT_FIELD_INVALID_TEXT;
        errors.push(err);
      }

      return errors;
    }

    if (invalid) return TEXTS.TEXT_FIELD_INVALID_TEXT;

    return undefined;
  };

  static getRequiredRule = (message?: string): RegisterOptions => {
    if (message) {
      return { required: message };
    }

    return { required: true };
  };
}

export default FieldUtils;
