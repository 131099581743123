import { ApolloError } from '@apollo/client';
import { BusinessProfilePlaylistFormData } from 'application/pages/BusinessProfilePage/renderer/BusinessProfilePlaylistForm/types';

import {
  BusinessProfileTypeFragment,
  ZvukPlaylistHideMutationInput,
  ZvukPlaylistPublishMutationInput,
  ZvukPlaylistRemoveMutationInput,
  ZvukPlaylistType,
  ZvukProfileType,
} from 'domain/api/graphql/generated';
import { BusinessProfilePlaylistMutationRequestResult } from 'domain/businessProfiles/types';

export enum BusinessProfilePlaylistModalMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export type BusinessProfileUpsertPlaylistModalProps = {
  mode?: BusinessProfilePlaylistModalMode;
  playlist: ZvukPlaylistType;
  targetBusinessProfileId?: string;
  onSubmit?: (formData: BusinessProfilePlaylistFormData) => void;
};

export type UseBusinessProfileUpsertPlaylistModalControllerProps = {
  mode: BusinessProfileUpsertPlaylistModalProps['mode'];
  playlist: BusinessProfileUpsertPlaylistModalProps['playlist'];
  targetBusinessProfileId?: BusinessProfileUpsertPlaylistModalProps['targetBusinessProfileId'];
  onSubmit?: BusinessProfileUpsertPlaylistModalProps['onSubmit'];
  close: () => void;

  getBusinessProfile: (id: string) => BusinessProfileTypeFragment | null;
  refetchBusinessProfiles: (companyId: string) => Promise<ZvukProfileType[]>;
  removeSelectedBusinessProfilePlaylist: (playlistId: string) => void;
  getBusinessProfilePlaylistInitialFormData: (
    targetBusinessProfilePlaylistId: string
  ) => Promise<BusinessProfilePlaylistFormData>;

  onCreateBusinessProfilePlaylistFromStream: (
    profileId: string,
    streamId: string,
    formData: BusinessProfilePlaylistFormData
  ) => Promise<BusinessProfilePlaylistMutationRequestResult>;
  createPlaylistFromStreamError?: ApolloError | null;

  onCreateBusinessProfilePlaylistFromZvuk: (
    profileId: string,
    formData: BusinessProfilePlaylistFormData
  ) => Promise<BusinessProfilePlaylistMutationRequestResult>;
  createPlaylistFromZvukError?: ApolloError | null;

  onUpdateBusinessProfilePlaylist: (
    playlistId: string,
    formData: BusinessProfilePlaylistFormData
  ) => Promise<BusinessProfilePlaylistMutationRequestResult>;
  updatePlaylistError?: ApolloError | null;

  hidePlaylist: (input: ZvukPlaylistHideMutationInput) => Promise<BusinessProfilePlaylistMutationRequestResult>;
  hidePlaylistError?: ApolloError | null;

  removePlaylist: (input: ZvukPlaylistRemoveMutationInput) => Promise<BusinessProfilePlaylistMutationRequestResult>;
  removePlaylistError?: ApolloError | null;

  publishPlaylist: (input: ZvukPlaylistPublishMutationInput) => Promise<BusinessProfilePlaylistMutationRequestResult>;
  publishPlaylistError?: ApolloError | null;
};
