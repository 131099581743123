import React from 'react';

import {
  useNotificationContext,
  NotificationColors,
  NOTIFICATION_DEFAULT_DELAY,
  NOTIFICATION_DEFAULT_ERROR_MESSAGE,
} from 'application/providers/NotificationProvider';
import IconButton from '@zvuk-b2b/react-uikit/ui/IconButton';

import { HintPlacement } from '@zvuk-b2b/react-uikit/ui/Popper';
import IconSlash from '@zvuk-b2b/react-uikit/ui/icons/Slash';
import useExcludeMediafile from 'domain/media/useExcludeMediafile';
import useExcludeMediafileRestore from 'domain/media/useExcludeMediafileRestore';
import AnalyticsService, { EventNameAMP, ParamNameAMP } from 'application/services/AnalyticsService';
import styles from './index.module.scss';
import { TEXTS } from './texts';

export type DislikeButtonProps = {
  id?: string | number;
  streamId?: string;
  className?: string;
  isDisliked?: boolean;
  setIsDisliked?: (vlue: boolean) => void;
  isShow?: boolean;
  onDislike?: () => void;
  isDisabled?: boolean;
  isAuthorized?: boolean;
  onUnAuthorizedClick?: () => void;
  errorHandler?: (e: unknown) => void;
  hint?: string;
  buttonClassName?: string;
  title?: string;
  isLiked?: boolean;
};

const DislikeButton = ({
  id,
  streamId,
  className,
  isDisliked,
  setIsDisliked,
  onDislike,
  isDisabled,
  isShow = true,
  isAuthorized = true,
  onUnAuthorizedClick,
  errorHandler,
  hint,
  buttonClassName,
  title,
  isLiked,
}: DislikeButtonProps) => {
  const notification = useNotificationContext();

  const { request: requestDislike, result: resultDislike } = useExcludeMediafile();
  const { request: requestDislikeRestore, result: resultDislikeRestore } = useExcludeMediafileRestore();

  const handleDislike = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (resultDislike.loading || !id) {
      return;
    }

    if (!isAuthorized) {
      onUnAuthorizedClick?.();

      return;
    }

    try {
      const isOk = await requestDislike({ id: streamId ? +streamId : 0, mediafileId: +id });

      if (isOk) {
        setIsDisliked?.(true);
        onDislike?.();

        AnalyticsService.event(EventNameAMP.DISLIKE_TRACK_PRESSED, {
          [ParamNameAMP.TITLE]: title,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      if (errorHandler) {
        errorHandler?.(e);
        return;
      }

      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  const handleDislikeRestore = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (resultDislikeRestore.loading || !id) {
      return;
    }

    if (!isAuthorized) {
      onUnAuthorizedClick?.();

      return;
    }

    try {
      const isOk = await requestDislikeRestore({ id: streamId ? +streamId : 0, mediafileId: +id });

      if (isOk) {
        setIsDisliked?.(false);
        AnalyticsService.event(EventNameAMP.DISLIKE_TRACK_UNPRESSED, {
          [ParamNameAMP.TITLE]: title,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: NOTIFICATION_DEFAULT_ERROR_MESSAGE,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }
  };

  if (!isShow) {
    return null;
  }

  return (
    <div className={className}>
      {isDisliked && !isLiked ? (
        <IconButton
          elementId="button_media_list_item_dislike_restore"
          className={`${styles.DislikeButtonDisliked} ${buttonClassName}`}
          onClick={handleDislikeRestore}
          hint={hint || TEXTS.MEDIA_LIST_ITEM_RECOVER_BUTTON_HINT}
          isLoading={resultDislikeRestore.loading}
          hintPlacement={HintPlacement.PLACEMENT_BOTTOM_START}
          isDisabled={isDisabled}>
          <IconSlash />
        </IconButton>
      ) : (
        <IconButton
          elementId="button_media_list_item_dislike"
          className={`${styles.DislikeButton} ${buttonClassName}`}
          onClick={handleDislike}
          hint={hint || TEXTS.MEDIA_LIST_ITEM_DISLIKE_BUTTON_HINT}
          isLoading={resultDislike.loading}
          hintPlacement={HintPlacement.PLACEMENT_BOTTOM_START}
          isDisabled={isDisabled}>
          <IconSlash />
        </IconButton>
      )}
    </div>
  );
};

export default DislikeButton;
