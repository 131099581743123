import { useMemo } from 'react';
import { ZVUK_FEATURE_PHONE_AUTH_AVAILABLE } from 'application/consts';
import { AuthMethodType } from './AuthMethodList';
import { AuthFormMode, AuthFormStep } from './types';

export const isPhoneAuthAvailable = String(ZVUK_FEATURE_PHONE_AUTH_AVAILABLE) === 'true';

const useAuthMethods = (mode: AuthFormMode, step: AuthFormStep, isSBBIDAvailable: boolean): AuthMethodType[] =>
  useMemo(() => {
    if (mode === AuthFormMode.REGISTER && step === AuthFormStep.PHONE_INPUT && isSBBIDAvailable) {
      return [AuthMethodType.SBBID];
    }

    if (mode === AuthFormMode.LOGIN && step !== AuthFormStep.CODE_INPUT) {
      return [
        step === AuthFormStep.PHONE_INPUT && AuthMethodType.EMAIL,
        step === AuthFormStep.LEGACY_AUTH && isPhoneAuthAvailable && AuthMethodType.PHONE,
        isSBBIDAvailable && AuthMethodType.SBBID,
      ].filter(Boolean) as AuthMethodType[];
    }

    return [];
  }, [mode, step, isSBBIDAvailable]);

export default useAuthMethods;
