import React from 'react';

import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';

import { TEXTS } from '../texts';

import styles from './index.module.scss';

const ExtendSubscriptionSuccessModal: React.FC<
  ModalProps[ModalTypes.WEB_PLAYER_REQUEST_EXTEND_SUBSCRIPTION_SUCCESS]
> = ({ close }) => (
  <ModalScreen
    onClose={close}
    titlePlacement="left"
    title={TEXTS.REQUEST_EXTEND_SUBSCRIPTION_MODAL_TITLE}
    actionButtons={[
      <Button
        key="extend_subscription_success_ok_button"
        elementId="extend_subscription_success_ok_button"
        type={ButtonType.PRIMARY}
        onClick={close}>
        {TEXTS.REQUEST_EXTEND_SUBSCRIPTION_MODAL_BUTTON_OK}
      </Button>,
    ]}>
    <div className={styles.ExtendSubscriptionSuccessModalDesc}>
      <div>{TEXTS.REQUEST_EXTEND_SUBSCRIPTION_MODAL_DESC}</div>
      <span>{TEXTS.REQUEST_EXTEND_SUBSCRIPTION_MODAL_CONTACTS}</span>
      <span>
        <a href={TEXTS.REQUEST_EXTEND_SUBSCRIPTION_MODAL_MANAGER_MAIL_URL}>
          {TEXTS.REQUEST_EXTEND_SUBSCRIPTION_MODAL_MANAGER_MAIL_TEXT}
        </a>
      </span>
    </div>
  </ModalScreen>
);

export default ExtendSubscriptionSuccessModal;
