export enum GOAL_NAME_ENUM {
  REACH_GOAL = 'reachGoal',
  SET_USER_ID = 'setUserID',
  SET_USER_PARAMS = 'userParams',
}

export interface GTMWindow extends Window {
  dataLayer?: Array<any>;
}

type YMParams = Record<string, unknown>;

export interface YMWindow extends Window {
  ym?: {
    (yaId: string, goalName: GOAL_NAME_ENUM.REACH_GOAL, eventName: string, params: YMParams): void;
    (yaId: string, goalName: GOAL_NAME_ENUM.SET_USER_ID, value: string): void;
    (yaId: string, goalName: GOAL_NAME_ENUM.SET_USER_PARAMS, params: YMParams): void;
  };
}
